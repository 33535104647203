import { AiOutlineClose } from "react-icons/ai"
import { useNavigate } from "react-router-dom"
import withLocationForm from "./withLocationForm";

const LocationButtonComponent: React.FC = () => {
    return <input type={"submit"} value={"Add Location"} className="inline-block bg-sjOrange text-white rounded shadow py-2 px-5 text-sm" />
}

const LocationCreateComponent: React.FC = () => {
    const navigate = useNavigate()

    return (
        <>
        <div className="flex flex-row">
            <div className="grow"><span className="text-2xl font-bold text-[#242731]">Add Location</span></div>
            <div
                onClick={() => navigate('/locations')}
                className="cursor-pointer grow-0"
            >
                <AiOutlineClose
                    className="text-gray-500 hover:text-gray-700"
                    style={{ width: '25px', height: '25px' }}
                />
            </div>
        </div>
        <div className="flex pt-5"><span className="text-base text-[#575F6E] font-light">Add your branch location by completing the details below.</span></div>
        </>
    )
}
export default withLocationForm(LocationCreateComponent, LocationButtonComponent)