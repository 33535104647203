import { call, put, takeLatest } from "@redux-saga/core/effects";
import { apiHttpClient, getAuthHeaderConfig } from "../../../lib";
import { Either } from "monet";
import { CheckedError, ServiceError } from "../../../types/ServiceError";
import {
    AuthState, FetchAllPatientsForScreenerLinkPayload, IAllPatientsForScreenerLink,
    IPatientMaster,
    IPatientMasters,
    IPatientWithSummary,
    IPotentialReferralPatientBasicDetails,
    IPotentialReferralPatientsResponse, IScreenerUserForScreeningLink, ISendScreenerLinkToPatientResponse,
    PatientMasterIndexRequest, SendScreenerLinkToPatientPayload,
    // TransferIntakeIndexRequest,
} from "../../../models";
import { AxiosError, AxiosResponse } from "axios";
import { callFinished, callInProgress } from "../../actions/loading.action";
import {
    FETCH_PATIENT_LATEST_SCREENING_SUMMARY,
    FETCH_POTENTIAL_REFERRAL_PATIENTS
} from "../../actions/care-coordinator";
import {
    FETCH_ALL_PATIENTS_FOR_SCREENER_LINK,
    FETCH_PATIENT_BASIC_INFORMATION,
    FETCH_PATIENT_MASTER_LIST,
    fetchedAllPatientsForScreenerLink,
    fetchedPatientBasicInformation,
    fetchedPatientLatestScreeningSummary,
    fetchedPatientLatestScreeningSummaryBySessionId,
    fetchedPatientMasterList,
    fetchedPotentialReferralPatients,
    fetchingAllPatientsForScreenerLinkFailed,
    fetchingPatientLatestScreeningSummaryFailed,
    fetchingPatientMasterFailed,
    fetchingPotentialReferralPatientsFailed,
    fetchPotentialReferralPatientsInProgress,
    SEND_SCREENER_LINK_TO_PATIENT,
    sendingScreenerLinkToPatientFailed,
    sentScreenerLinkToPatient,
    TRANSFER_INTAKE,
    transferedIntake,
    transferingIntakeFailed
} from "../../actions/care-coordinator/patients.action";
import { Filters } from "../../../screens/prospects/care-coordinator/PotentialPatientsScreen";
import { createQueryString } from "../../../lib/api-helpers";

const apiFetchPotentialReferralPatients = (action: {
    type: string,
    payload: { currentPage: number, recordsPerPage: number, searchText: string, filters?: { status: string[] }, advancedFilters?: Filters },
    authState: AuthState
}) => {
    const searchParams = new URLSearchParams()
    const filters = action.payload.advancedFilters
    if (filters) {
        for (const [key, value] of Object.entries(filters)) {
            if (value) {
                searchParams.append(key, value)
            }
        }
    }
    const url = `/care-coordinator/${action.authState.accountId}/patients/potential-referrals?searchText=${action.payload.searchText}&pageNumber=${action.payload.currentPage - 1}&recordsPerPage=${action.payload.recordsPerPage}&${searchParams.toString()}`;
    return apiHttpClient.get<Either<CheckedError, IPotentialReferralPatientsResponse>>(url, { ...getAuthHeaderConfig(action.authState), params: { filters: action.payload.filters } })
        .then((response: AxiosResponse) => {
            return Either.right(response.data as IPotentialReferralPatientsResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* fetchPatients(action: {
    type: string,
    payload: { currentPage: number, recordsPerPage: number, searchText: string, filters?: { status: string[] }, advancedFilters?: Filters },
    authState: AuthState
}) {
    try {
        console.log(`CareCoordinator:PatientsSaga:fetchPatients`)
        yield put(callInProgress())
        yield put(fetchPotentialReferralPatientsInProgress())
        const apiFetchPatientsResponse: Either<CheckedError, IPotentialReferralPatientsResponse> = yield call(apiFetchPotentialReferralPatients, action)
        if (apiFetchPatientsResponse.isLeft()) {
            const error = apiFetchPatientsResponse.left()
            if (error.isChecked) {
                console.log("CareCoordinator:PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingPotentialReferralPatientsFailed({ error: error.message }));
            } else {
                yield put(fetchingPotentialReferralPatientsFailed({ error: error.message }));
            }
        } else {
            const { patients, count, statusCount } = apiFetchPatientsResponse.right();
            yield put(fetchedPotentialReferralPatients(patients, count, statusCount));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPotentialReferralPatientsFailed({ error: "Error fetching patients!" }));
    } finally {
        yield put(callFinished())
    }
}

const apiFetchPatientLatestScreeningSummary = (action: {
    type: string,
    payload: { patientId: string, practiceId: string, sessionId?: string },
    authState: AuthState
}) => {
    const url = `/${action.authState.accountId}/prospects/${action.payload.patientId}/screening-summary?practiceId=${action.payload.practiceId}&sessionId=${action.payload.sessionId}`;
    return apiHttpClient.get<Either<CheckedError, IPatientWithSummary>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response?.data?.data as IPatientWithSummary)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* fetchPatientLatestScreeningSummary(action: {
    type: string,
    payload: { patientId: string, practiceId: string, sessionId?: string },
    authState: AuthState
}) {
    try {
        console.log(`CareCoordinator:PatientsSaga:fetchPatientLatestScreeningSummary`)
        // yield put(callInProgress())
        const apiFetchPatientsResponse: Either<CheckedError, IPatientWithSummary> = yield call(apiFetchPatientLatestScreeningSummary, action)
        if (apiFetchPatientsResponse.isLeft()) {
            const error = apiFetchPatientsResponse.left()
            if (error.isChecked) {
                console.log("CareCoordinator:PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingPatientLatestScreeningSummaryFailed({ error: error.message }));
            } else {
                yield put(fetchingPatientLatestScreeningSummaryFailed({ error: error.message }));
            }
        } else {
            const response = apiFetchPatientsResponse.right();
            const fn = !action.payload.sessionId ? fetchedPatientLatestScreeningSummary : fetchedPatientLatestScreeningSummaryBySessionId;
            yield put(fn(response));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPotentialReferralPatientsFailed({ error: "Error fetching patient latest screening summary!" }));
    } finally {
        // yield put(callFinished())
    }
}

const apiFetchPatientBasicInformation = (action: {
    type: string,
    payload: { patientId: string },
    authState: AuthState
}) => {
    const url = `/care-coordinator/${action.authState.accountId}/patients/${action.payload.patientId}`;
    return apiHttpClient.get<Either<CheckedError, IPotentialReferralPatientBasicDetails>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response?.data?.patient as IPotentialReferralPatientBasicDetails)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* fetchPatientBasicInformation(action: {
    type: string,
    payload: { patientId: string },
    authState: AuthState
}) {
    try {
        console.log(`CareCoordinator:PatientsSaga:fetchPatientBasicInformation`)
        yield put(callInProgress())
        const apiFetchPatientsResponse: Either<CheckedError, IPotentialReferralPatientBasicDetails> = yield call(apiFetchPatientBasicInformation, action)
        if (apiFetchPatientsResponse.isLeft()) {
            const error = apiFetchPatientsResponse.left()
            if (error.isChecked) {
                console.log("CareCoordinator:PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingPatientLatestScreeningSummaryFailed({ error: error.message }));
            } else {
                yield put(fetchingPatientLatestScreeningSummaryFailed({ error: error.message }));
            }
        } else {
            const response = apiFetchPatientsResponse.right();
            yield put(fetchedPatientBasicInformation(response));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPotentialReferralPatientsFailed({ error: "Error fetching patient basic information!" }));
    } finally {
        yield put(callFinished())
    }
}

// apiFetchPatientMasterList
const apiFetchPatientMasterList = (action: { type: string, payload: { request: PatientMasterIndexRequest }, authState: AuthState }) => {
    const searchParams = new URLSearchParams()
    const filters = { practiceIds: action.payload.request.practiceIds, socialWorkerIds: action.payload.request.socialWorkerIds, searchText: action.payload.request.searchText || '' }
    if (filters) {
        for (const [key, value] of Object.entries(filters)) {
            if (value) {
                searchParams.append(key, value.toString());
            }
        }
    }
    const url = `/${action.authState.accountId}/prospects/search/patient-master?${searchParams.toString()}&pageNumber=${action.payload.request.pageNumber}&recordsPerPage=${action.payload.request.recordsPerPage}`;
    return apiHttpClient.get<Either<CheckedError, IPatientMasters>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data?.data as IPatientMasters)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* fetchPatientMasterList(action: { type: string, payload: { request: PatientMasterIndexRequest }, authState: AuthState }) {
    try {
        yield put(callInProgress())
        const apiFetchUserResponse: Either<CheckedError, IPatientMasters> = yield call(apiFetchPatientMasterList, action)
        console.log(apiFetchUserResponse)
        if (apiFetchUserResponse.isLeft()) {
            const error = apiFetchUserResponse.left()
            if (error.isChecked) {
                console.log("patients: Encountered a Checked Error", error.message)
                yield put(fetchingPatientMasterFailed({ error: error.message }));
            } else {
                yield put(fetchingPatientMasterFailed({ error: error.message }));
            }
        } else {
            const { prospects, count } = apiFetchUserResponse.right();
            yield put(fetchedPatientMasterList(prospects, count));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPatientMasterFailed({ error: "Error fetching patient master list!" }));
    } finally {
        yield put(callFinished())
    }
}

const apiTransferPatient = (action: { type: string, payload: { sourcePatientId: string, therapistId: string | null, patientStatus: string }, authState: AuthState }) => {
    if (!action.authState) {
        throw new Error('authState is undefined');
    }
    const url = `/${action.authState.accountId}/prospects/${action.payload.sourcePatientId}/socialWorker/${action.payload.therapistId}/intake-transfer?patientStatus=${action.payload.patientStatus}`;
    console.log(url);
    return apiHttpClient.put<Either<CheckedError, string>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* transferIntake(action: { type: string, payload: { sourcePatientId: string, therapistId: string | null, patientStatus: string }, authState: AuthState }) {
    console.log(action.authState);
    try {
        yield put(callInProgress())
        const apiSaveUserResponse: Either<CheckedError, string> = yield call(apiTransferPatient, action)
        if (apiSaveUserResponse.isLeft()) {
            const error = apiSaveUserResponse.left()
            if (error.isChecked) {
                yield put(transferingIntakeFailed({ error: error.message }));
            } else {
                yield put(transferingIntakeFailed({ error: error.message }));
            }
        } else {
            const noteId = apiSaveUserResponse.right();
            yield put(transferedIntake(noteId));
        }
    } catch (e) {
        console.log(e)
        yield put(transferingIntakeFailed({ error: "Error transfering intake!" }));
    } finally {
        yield put(callFinished())
    }
}

const apiFetchAllPatientsForScreenerLink = (action: { type: string, payload: FetchAllPatientsForScreenerLinkPayload , authState: AuthState }) => {
    console.log(action.payload);
    const searchParams = new URLSearchParams();
    const filters = {  search: action.payload.searchText || '' };
    if (filters) {
        for (const [key, value] of Object.entries(filters)) {
            if (value) {
                searchParams.append(key, value.toString());
            }
        }
    }
    const url = `/care-coordinator/${action.authState.accountId}/patients?${searchParams.toString()}&pageNumber=${action.payload.pageNumber}&recordsPerPage=${action.payload.recordsPerPage}`;
    return apiHttpClient.get<Either<CheckedError, IScreenerUserForScreeningLink[]>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response?.data?.data as IScreenerUserForScreeningLink[])
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* fetchAllPatientsForScreenerLink(action: { type: string, payload: FetchAllPatientsForScreenerLinkPayload, authState: AuthState }) {
    try {
        const apiFetchUserResponse: Either<CheckedError, IScreenerUserForScreeningLink[]> = yield call(apiFetchAllPatientsForScreenerLink, action)
        if (apiFetchUserResponse.isLeft()) {
            const error = apiFetchUserResponse.left()
            if (error.isChecked) {
                console.log("patients: Encountered a Checked Error", error.message)
                yield put(fetchingAllPatientsForScreenerLinkFailed({ error: error.message }));
            } else {
                yield put(fetchingAllPatientsForScreenerLinkFailed({ error: error.message }));
            }
        } else {
            const  patients = apiFetchUserResponse.right();
            yield put(fetchedAllPatientsForScreenerLink(patients));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingAllPatientsForScreenerLinkFailed({ error: "Error fetching patient master list!" }));
    } finally {
        yield put(callFinished())
    }
}

const apiSendScreenerLinkToPatient = (action: { type: string, payload: SendScreenerLinkToPatientPayload , authState: AuthState }) => {
    const {
        patientId,
        practiceId
    } = action.payload
    const url = `/care-coordinator/${action.authState.accountId}/patients/${patientId}/messages/screener-link`;
    return apiHttpClient.post<Either<CheckedError, ISendScreenerLinkToPatientResponse>>(url,{
        practiceId
    }, getAuthHeaderConfig(action.authState), )
        .then((response: AxiosResponse) => {
            return Either.right(response?.data as ISendScreenerLinkToPatientResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* sendScreenerLinkToPatient(action: { type: string, payload: SendScreenerLinkToPatientPayload, authState: AuthState }) {
    try {
        const apiFetchUserResponse: Either<CheckedError, ISendScreenerLinkToPatientResponse> = yield call(apiSendScreenerLinkToPatient, action)
        if (apiFetchUserResponse.isLeft()) {
            const error = apiFetchUserResponse.left()
            if (error.isChecked) {
                console.log("patients: Encountered a Checked Error", error.message)
                yield put(sendingScreenerLinkToPatientFailed({ error: error.message }));
            } else {
                yield put(sendingScreenerLinkToPatientFailed({ error: error.message }));
            }
        } else {
            yield put(sentScreenerLinkToPatient());
        }
    } catch (e) {
        console.log(e)
        yield put(sendingScreenerLinkToPatientFailed({ error: "Error sending screener link to patient!" }));
    }
}


export default function* patientsSaga() {
    yield takeLatest(FETCH_POTENTIAL_REFERRAL_PATIENTS, fetchPatients)
    yield takeLatest(FETCH_PATIENT_LATEST_SCREENING_SUMMARY, fetchPatientLatestScreeningSummary)
    yield takeLatest(FETCH_PATIENT_BASIC_INFORMATION, fetchPatientBasicInformation)
    yield takeLatest(FETCH_PATIENT_MASTER_LIST, fetchPatientMasterList)
    yield takeLatest(TRANSFER_INTAKE, transferIntake)
    yield takeLatest(FETCH_ALL_PATIENTS_FOR_SCREENER_LINK, fetchAllPatientsForScreenerLink)
    yield takeLatest(SEND_SCREENER_LINK_TO_PATIENT, sendScreenerLinkToPatient)
}
