import { Checkbox, Radio, Textarea } from "@material-tailwind/react"
import { FieldErrorsImpl, UseFormRegister, UseFormTrigger, UseFormWatch } from "react-hook-form"
import mentalStatusExam from "../../../assets/data/mentalStatusExam"
import { Inputs } from "./index"
import React, { useEffect } from "react";

const MentalExam: React.FC<{
    register: UseFormRegister<Inputs>, watch: UseFormWatch<Inputs>, errors: Partial<FieldErrorsImpl<Inputs>>, 
    renderErrorMessage: (message: string | null | undefined) => "" | JSX.Element | null | undefined, canEdit: boolean,
}> = ({ register, watch, errors, renderErrorMessage, canEdit }) => {

    return (
        <fieldset className={'divide-y-4'} disabled={!canEdit}>
            <div className="mt-3">
                <label className="text-lg uppercase" data-testid="mentalStatusExam">MENTAL STATUS EXAM:</label>
                <label className="block text-lg mt-3 text-sjOrange uppercase">
                    General Appearance & Behavior:
                </label>
                <div className="mt-3">
                    <label className="font-semibold" htmlFor="hygiene0">Hygiene</label>
                    <div className="flex grid grid-flow-row grid-cols-4 ">
                        {mentalStatusExam[0].generalApearanceAndBehavior[0].hygiene.map((x, i) =>
                            <Radio id={'hygiene'+i} {...register("hygiene")} label={x.label} value={x.value} />
                        )}
                    </div>
                </div>
                <div className="mt-3">
                    <label className="font-semibold">Grooming</label>
                    <div className="grid grid-flow-row grid-cols-4 gap-3">
                        {mentalStatusExam[0].generalApearanceAndBehavior[0].grooming.map((x, i) =>
                            <Radio id={'grooming'+i} {...register("grooming")} label={x.label} value={x.value} />
                        )}
                    </div>
                </div>
                <div className="mt-3">
                    <label className="font-semibold">Eye Contact</label>
                    <div className="grid grid-flow-row grid-cols-4 gap-3">
                        {mentalStatusExam[0].generalApearanceAndBehavior[0].eyeContact.map((x, i) =>
                            <Radio id={'eyeContact'+i} {...register("eyeContact")} label={x.label} value={x.value} />
                        )}
                    </div>
                </div>
                <div className="mt-3">
                    <label className="font-semibold">Behavior</label>
                    <div>{errors.behavior?.selected && renderErrorMessage(errors.behavior.selected.message)}</div>
                    <div className="grid grid-flow-row grid-cols-4 mt-2">
                        {mentalStatusExam[0].generalApearanceAndBehavior[0].behavior.map((x) =>
                            <div className="flex items-center">
                                <Checkbox label={x.label} key={x.value} id={x.value} {...register('behavior.selected')}
                                    value={x.value} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <label className="block text-lg mt-3 text-sjOrange uppercase">Motor Activity:</label>
                <div>{errors.motorActivity?.selected && renderErrorMessage(errors.motorActivity.selected.message)}</div>
                <div className="grid grid-flow-row grid-cols-4 mt-2">
                    {mentalStatusExam[0].motorActivity.map((x) =>
                        <div className="flex items-center">
                            <Checkbox label={x.label} key={x.value} {...register('motorActivity.selected')}
                                value={x.value} id={x.value} />
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-3">
                <label className="block text-lg mt-3 text-sjOrange uppercase">Affect:</label>
                <div>{errors.affect?.selected && renderErrorMessage(errors.affect.selected.message)}</div>
                <div className="grid grid-flow-row grid-cols-4 mt-2">
                    {mentalStatusExam[0].affect.map((x) =>
                        <div className="flex items-center">
                            <Checkbox label={x.label} key={x.value} {...register('affect.selected')}
                                value={x.value} id={x.value} />
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-3">
                <label className="block text-lg mt-3 text-sjOrange uppercase">Speech:</label>
                <div className="mt-3">
                    <label className="font-semibold">Rate:</label>
                    <div>{errors.rate?.selected && renderErrorMessage(errors.rate.selected.message)}</div>
                    <div className="grid grid-flow-row grid-cols-4 gap-3 mt-2">
                        {mentalStatusExam[0].speech[0].rate.map((x) =>
                            <div className="flex items-center">
                                <Checkbox label={x.label} key={x.value} {...register('rate.selected')}
                                    value={x.value} id={x.value} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="mt-3">
                    <label className="font-semibold">Fluency/Rhythm:</label>
                    <div>{errors.fluencyRhythm?.selected && renderErrorMessage(errors.fluencyRhythm.selected.message)}</div>
                    <div className="grid grid-flow-row grid-cols-4 mt-2">
                        {mentalStatusExam[0].speech[0].fluencyOrRhythm.map((x) =>
                            <div className="flex items-center">
                                <Checkbox label={x.label} key={x.value} {...register('fluencyRhythm.selected')}
                                    value={x.value} id={x.value} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="mt-3">
                    <label className="font-semibold">Relevance:</label>
                    <div>{errors.relevance?.selected && renderErrorMessage(errors.relevance.selected.message)}</div>
                    <div className="grid grid-flow-row grid-cols-4 mt-2">
                        {mentalStatusExam[0].speech[0].relevance.map((x) =>
                            <div className="flex items-center">
                                <Checkbox label={x.label} key={x.value} {...register('relevance.selected')}
                                    value={x.value} id={x.value} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <label className="block text-lg mt-3 text-sjOrange uppercase">Thought Process:</label>
                <div>{errors.thoughtProcess?.selected && renderErrorMessage(errors.thoughtProcess.selected.message)}</div>
                <div className="grid grid-flow-row grid-cols-4 mt-2">
                    {mentalStatusExam[0].thoughtProcess.map((x) =>
                        <div className="flex items-center">
                            <Checkbox label={x.label} key={x.value} {...register('thoughtProcess.selected')}
                                value={x.value} id={x.value} />
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-3">
                <label className="block text-lg mt-3 text-sjOrange uppercase">Thought Content:</label>
                <div className="mt-3">
                    <label className="font-semibold">Delusions:</label>
                    <div>{errors.delusion?.selected && renderErrorMessage(errors.delusion.selected.message)}</div>
                    <div className="grid grid-flow-row grid-cols-4 mt-2">
                        {mentalStatusExam[0].thoughtContent[0].delusions.map((x) =>
                            <div className="flex items-center">
                                <Checkbox label={x.label} key={x.value} {...register('delusion.selected')}
                                    value={x.value} id={x.value} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="mt-3">
                    <label className="font-semibold">Suicidal thought:</label>
                    <div>{errors.suicidalThought?.selected && renderErrorMessage(errors.suicidalThought.selected.message)}</div>
                    <div className="grid grid-flow-row grid-cols-4 mt-2">
                        {mentalStatusExam[0].thoughtContent[0].suicidalThought.map((x) =>
                            <div className="flex items-center">
                                <Checkbox label={x.label} key={x.value} {...register('suicidalThought.selected')}
                                    value={x.value} id={'suicidalThought'+x.value} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="mt-3">
                    <label className="font-semibold">Homicidal thought:</label>
                    <div>{errors.homicidalThought?.selected && renderErrorMessage(errors.homicidalThought.selected.message)}</div>
                    <div className="grid grid-flow-row grid-cols-4 mt-2">
                        {mentalStatusExam[0].thoughtContent[0].homicidalThought.map((x) =>
                            <div className="flex items-center">
                                <Checkbox label={x.label} key={x.value} {...register('homicidalThought.selected')}
                                    value={x.value} id={'homicidalThought'+x.value}/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <label className="block text-lg mt-3 text-sjOrange uppercase">Other</label>
                <Textarea {...register("mentalStatusOther")} className="mt-3 outline-none rounded-md" />
            </div>
        </fieldset>
    )
}

export default React.memo(MentalExam)