import React from "react";
import { Bars3BottomLeftIcon } from '@heroicons/react/24/outline';
import { RoutePaths } from "../../../shared/Utils";
import ProspectsButton from "../common/PropsectsButton";


const MedicalAssistantSideBar: React.FC = () => {
    return (
        <div className="flex flex-col">
            <ProspectsButton 
                component="prospects" 
                navigateTo={`${RoutePaths.medicalAssistance.prospects}?page=1`} 
                Icon={Bars3BottomLeftIcon} 
            />
        </div>
    );
}

export default MedicalAssistantSideBar;
