import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import {RoutePaths} from "../../../shared/Utils";
import _ from "lodash";

const MedicalAssistantSideBarFooter : React.FC = () => {
    const { firstName, lastName, email } = useSelector((state: StateParams) => state.account)



    const renderFooter = () => {
        return <div className={"flex justify-center items-center bg-[#FFFAF1]"}>
                <div className="p-2 pb-4 flex flex-col text-left">
                    <p className="mt-1 text-left text-sm text-[#1D1B20] ellipsis-text">{`${firstName} ${lastName}`}</p>
                    <p className="mt-1 text-left text-xs text-[#5D7285] ellipsis-text">{`${email}`}</p>
            
                    <Link to={RoutePaths.medicalAssistance.maProfile} className='text-sjLink underline mt-1 text-left text-xs'>
                        View Profile
                    </Link>
                </div>
            </div>
    }

    return renderFooter()
}

export default MedicalAssistantSideBarFooter
