import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {StateParams} from "../store/reducers";
import {useNavigate} from "react-router";
import {HOME_CONTENT_READY} from "../store/actions/counsellor/navigation.actions";
import {RoutePaths} from "../shared/Utils";

const NavigationPathsByRole : {[key: string]: {[key: string] : string }} = {
    "Admin": {
        [HOME_CONTENT_READY] : `${RoutePaths.collaborativeCare.prospects.root}?page=1`
    },
    "Counsellor": {
        [HOME_CONTENT_READY] : `${RoutePaths.collaborativeCare.prospects.root}?page=1`
    },
    "Psychiatrist": {
        [HOME_CONTENT_READY] : `${RoutePaths.collaborativeCare.prospects.root}?page=1`
    },
    "Physician": {
        [HOME_CONTENT_READY] : `${RoutePaths.physicianCare.prospects}?page=1`
    },
    "Care_Coordinator": {
        [HOME_CONTENT_READY] : `${RoutePaths.careCoordinator.potentialPatients.root}?page=1`
    },
    "Medical_Assistant": {
        [HOME_CONTENT_READY] : `${RoutePaths.medicalAssistance.prospects}?page=1`
    }

}

const RoleBasedNavigator : React.FC = () => {
    const navigate = useNavigate()
    const readyWithContent = useSelector((state: StateParams) => state.navigation.readyWithContent);
    const role = useSelector((state: StateParams) => state.account.role);

    const navigateByRole  = (role: string, readyWithContent: string) => {
        const path = NavigationPathsByRole[role][readyWithContent]
        navigate(path)
    }

    useEffect(() => {
        if(role && readyWithContent) {
            navigateByRole(role, readyWithContent)
        }
    }, [role, readyWithContent])

    return <></>
}

export default RoleBasedNavigator
