import React from 'react'
import { Roles } from '../../models';

export interface Tab {
    label: string;
    value: string;
}

interface StatusTab {
    tabs: Tab[]
    handleSelectedFilters: (status: string) => void
    getTabClassName: (value: string) => string;
    tabCount?: Record<string, number>
}

const StatusTabs: React.FC<StatusTab> = ({ tabs, handleSelectedFilters, getTabClassName, tabCount }) => {
    return (
        <>
            {tabs.map((tab, tabIndex) => (
                <a key={tab.value} href="#" aria-current="page"
                    onClick={() => handleSelectedFilters(tab.value)}
                    className={`${getTabClassName(tab.value)} px-4 py-2 text-sm font-medium border border-gray-200
                        ${tabIndex === 0 ? 'rounded-s-lg' : ''}
                        ${tabIndex === tabs.length - 1 ? 'rounded-e-lg' : ''}`}>
                    { tabCount ? `${tab.label} (${tabCount[tab.value] || 0})` : tab.label}
                </a>
            ))}
        </>
    )
}

export default StatusTabs
