import React from "react";

const Checkbox: React.FC<{ value: string, checked: boolean, onChange: any, classNames: string, textClassNames?: string, labelClassNames?: string }> = ({ value, checked, onChange, classNames, textClassNames, labelClassNames }) => {
    return (
        <label className={labelClassNames ? labelClassNames : "text-sm"}>
            <input type="checkbox" checked={checked} onChange={onChange} className={checked ? classNames : ''} />
            <span className={textClassNames ? `${textClassNames}` : `ml-1`}>{value}</span>
        </label>
    );
};

export default Checkbox