import React, {useMemo} from "react";
import {IPatient, IPatientIndexItem, IPatientListItem, LanguageCodeDescription} from "../../../models";
import * as _ from 'lodash'
import {YesNoPill} from "../../generic/YesNoPill";
import {DateTime} from "luxon";
import {ReferButton} from "./refer/ReferButton";
import {RoutePaths} from "../../../shared/Utils";
import {DetailsLinkButton} from "../../generic/DetailsLinkButton";
import TablePaginationV2 from "../../generic/TablePaginationV2";
import {MixpanelContext} from "../../../analytics/Mixpanel";
import {REFER_PATIENT_FROM_LIST} from "../../../analytics/Events";
import { useSelector } from "react-redux";
import { StateParams } from "../../../store/reducers";

interface PatientsProps {
    patients: IPatientListItem[],
    searchInProgress: boolean | undefined
}

const Prospects : React.FC<PatientsProps> = ({patients, searchInProgress}) => {
    const analytics = React.useContext(MixpanelContext);
    const { role, canMARefer } = useSelector((state: StateParams) => state.account)
 

    const data = patients.map((patient) => {
        const sessionCreatedAtISO = patient.latestSessionCreatedAt
            ?  DateTime.fromISO(patient.latestSessionCreatedAt, {zone: 'utc'}).toFormat('MMM-dd-yyyy')
            : "Pending Screening";
        return {
            id: patient.id,
            link: `${RoutePaths.physicianCare.prospectProfile.replace(":patientId", patient.id)}`,
            name: `${patient.lastName}, ${patient.firstName}`,
            dateOfBirth: DateTime.fromISO(patient.dob, {zone: 'utc'}).toFormat('MMM-dd-yyyy'),
            zipCode: patient.zipCode,
            createdAt: sessionCreatedAtISO,
            locationName: patient.locationName.charAt(0).toUpperCase() + patient.locationName.slice(1),
            atRisk: patient.atRisk ? "Yes" : "No",
            refer: {
                url: `${RoutePaths.physicianCare.referPatient.replace(":patientId", patient.id)}`,
                patientStatus: patient.patientStatus,
                canBeReferred: patient.canBeReferred,
                analyticsAction: () => {
                    analytics?.track(REFER_PATIENT_FROM_LIST, {patientId: patient.id})
                }
            },
            referringPhysician: patient.referringPhysician ? `Dr. ${patient.referringPhysician}` : 'N/A'
        }
    })

    const columns = useMemo(
        () => [
            { header: "Patient Name", accessorKey: "name" },
            { header: "Date of Birth", accessorKey: "dateOfBirth" },
            { header: "Latest Visit", accessorKey: "createdAt" },
            { header: "Location Screened", accessorKey: "locationName" },
            { header: "Suicidal Ideation", accessorKey: "atRisk", cell: (props:any) => <YesNoPill value={props.getValue()} /> },
            { header: "Details", accessorKey: "link", cell: (props:any) => <DetailsLinkButton value={props.getValue()} />},
            { header: "Refer to CoCM", accessorKey: "refer", cell: (props:any) =>
                <ReferButton value={props.getValue()} />
                
        },
            { header: "Referring Physician", accessorKey: "referringPhysician" },
        ],
        []
    );

    return <div>
            <TablePaginationV2 columns={columns} data={data} searchInProgress={searchInProgress}/>
        </div>
}

export default Prospects
