import {ICoCMPatient, ITherapistPatient} from "../../../models";
import {DateTime} from "luxon";
import {ProfileViewV2} from "../../generic/ProfileView";
import {PatientsDataView} from "../common/PatientsDataView";
import {RoutePaths} from "../../../shared/Utils";

export class PhysicianPatientsDataView {
    private cocmPatients: ICoCMPatient[];

    constructor() {
        this.cocmPatients = []
    }

    setPatients(cocmPatients: ICoCMPatient[]) {
        this.cocmPatients = cocmPatients
    }

    getColumns(): any {
        return () => [
            { header: "Patient Name", accessorKey: "name" },
            { header: "Date of birth", accessorKey: "dateOfBirth" },
            { header: "Referring Physician", accessorKey: "referringPhysician" },
            { header: "Profile", accessorKey: "profile", cell: ProfileViewV2 }
        ]
    }

    getData(): any {
        return this.cocmPatients.map((cocmPatient) => {
            const dob = DateTime.fromISO(cocmPatient.dob.toString(), {zone: 'utc'}).toFormat('MM/dd/yyyy')
            const provider = cocmPatient?.patientStatuses?.[0]?.provider
            const referringPhysician = provider ? `Dr. ${provider.firstName} ${provider.lastName}` : 'N/A'
            return {
                id: cocmPatient.id,
                name: `${cocmPatient.lastName}, ${cocmPatient.firstName}`,
                dateOfBirth: dob,
                referringPhysician: `${referringPhysician}`,
                profile: `${RoutePaths.physicianCare.patientJourney.replace(":patientId", cocmPatient.id)}`
            }
        })
    }

}
