import { useSelector } from "react-redux";
import { StateParams } from "../../store/reducers";
import { useEffect } from "react";
import ENV from "../../constants/ENV";
import { useFlags } from "flagsmith/react";
import { useLocation } from "react-router-dom";
import { Roles } from "../../models";

const HelpWidgetComponent = () => {

    const {
        token
    } = useSelector((state: StateParams) => state.account);
    const isJiraServiceDeskEnabled = useFlags(['help_widget'])?.help_widget?.enabled;
    const isHomePage = useLocation().pathname.startsWith('/home');
    const role = useSelector((state: StateParams) => state.account.role);
    const { defaultLocation } = useSelector((state: StateParams) => state.physicianAccount);

    console.log(defaultLocation);


    useEffect(() => {
        if (isJiraServiceDeskEnabled) {
            if (ENV.JIRA_SERVICE_DESK_API_KEY) {
                if (token && !isHomePage) {
                    const script = document.createElement('script');
                    script.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
                    script.id = 'jiraWidget';
                    script.setAttribute('async', "true");
                    script.setAttribute('data-jsd-embedded', '');
                    script.setAttribute('data-key', ENV.JIRA_SERVICE_DESK_API_KEY);
                    script.setAttribute('data-base-url', "https://jsd-widget.atlassian.com");

                    document.body.appendChild(script);


                    script.addEventListener('load', (event) => {
                        window.document.dispatchEvent(new Event("DOMContentLoaded", {
                            bubbles: true,
                            cancelable: true
                        }));

                        const widget = document.getElementById('jsd-widget');

                        if (widget) {
                            console.log(role, Roles)
                            if (role === Roles.Physician) {
                                if (!defaultLocation) {
                                    widget.style.bottom = '150px';
                                } else {
                                    widget.style.bottom = '200px';
                                }
                            } else if (role === Roles.Medical_Assistant) {
                                widget.style.bottom = '150px';
                            }
                            else {
                                widget.style.bottom = '120px';
                            }
                        }

                    }
                    )
                    return () => {
                        document.body.removeChild(script);
                    }
                } else {
                    const script = document.getElementById('jiraWidget');
                    if (script) {
                        document.body.removeChild(script);
                    }
                    const iframe = document.getElementById('jsd-widget');

                    if (iframe) {
                        document.body.removeChild(iframe);
                    }
                }
            } else {
                console.error("***********JIRA Service Desk Variable JIRA_SERVICE_DESK_API_KEY is not configured***********");
            }
        } else {
            console.error("***********Help widget is not enabled***********");
        }
    }, [token, isJiraServiceDeskEnabled, isHomePage, defaultLocation]);

    return <></>;
}

export default HelpWidgetComponent;

