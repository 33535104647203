import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { saveCatchupNotes, savePsychiatristNotes, saveTreatmentNotes, setTreamentPlanScreeners } from "../../../store/actions/counsellor/patients.action";
import { StateParams } from "../../../store/reducers";
import _ from "lodash";
import { DateTime } from "luxon";
import { NotesStatus, SessionTypes } from "../../../models";
import { useStatusChange } from "../../../hooks/useStatusChange";
import { toast } from "react-toastify";
import { canAccessPsychiatristNotes, canAddPsychiatristNotes, isCounsellorNotesLocked, isNotesLocked, isPsychiatrist } from "../accessControl";
import Addendum from "../Addendum";
import { Textarea } from "@material-tailwind/react";
import { updateSessionStatus } from "../../../store/actions/counsellor/appointments.action";
import CreatableSelect from "../../generic/CreatableSelect";
import icdCodes from "../../../assets/data/icd-codes.json";
import medications from "../../../assets/data/medications";
import ProposedMedication from "./ProposedMedication";
import RadioWithTextComponent from "../intake/RadioWithTextComponent";

interface Props {
    patientId: string
    canEdit: boolean
    notesId: string | null
    isAddendumAdded: boolean
    setIsAddendumAdded: (value: boolean) => void
    hasSaved: boolean
    setHasSaved: (value: boolean) => void
    hasSubmitted: boolean
    setHasSubmitted: (value: boolean) => void
}

const keyMappings: { [K in keyof Partial<Inputs>]: string } = {
    newAddendum: 'newAddendum',
    proposedMedication: 'proposedMedication'
}
const determineKey = (name: string): keyof typeof keyMappings => {
    return keyMappings[name as keyof typeof keyMappings] as keyof typeof keyMappings || name as keyof typeof keyMappings;
}

export type Inputs = {
    presentChiefComplaint: string
    historyPresentProblem: string
    socialDeterminants: any
    mentalBehavioral: any
    followupDiscussion: string
    fromCurrentPhysician: any
    patientMedicationsHistory: any
    medicationsText: string
    medicationsRadio: string
    proposedMedication: { medicine: string, type: string, dosage: string, frequency: string, details: string}[]
    proposedPlan: string
    proposedLabs: string
    consultationTime: string
    addendums: { addendum: string, by: string, on: string | null }[]
    newAddendum: { addendum: string, by: string, on: string | null }[]
}

const IndividualPsychiatristNotes: React.FC<Props> = ({ patientId, canEdit, notesId, isAddendumAdded, setIsAddendumAdded, hasSaved, hasSubmitted }) => {

    const dispatch = useDispatch()
    const { psychiatristNotes, notesStatus, sessionNotesId } = useSelector((state: StateParams) => state.patients)
    const { firstName, lastName } = useSelector((state: StateParams) => state.account)
    const { sessionId } = useSelector((state: StateParams) => state.appointments)
    const role = useSelector((state: StateParams) => state.account.role);
    const { register, control, watch, getValues, reset, setValue } = useForm<Inputs>()
    const dispatchNotesStatusChange = useStatusChange(SessionTypes.PsychiatristNotes)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [icdCodesSocial, setIcdCodesSocial] = useState<any>()
    const [icdCodesMentalBehavior, setIcdCodesMentalBehavior] = useState<any>()
    const [fromCurrentPhysician, setFromCurrentPhysician] = useState<any>()
    const [fromPriorPhysician, setFromPriorPhysician] = useState<any>()

    const { fields: newAddendums, append, remove } = useFieldArray({
        control,
        name: "newAddendum"
    })

    const saveNotes = useCallback(
        _.debounce((key, answer) => dispatch(savePsychiatristNotes(patientId, { psychiatristNotes: { [key]: answer } }, notesId)), 500), []
    )

    useEffect(() => {
        if (isAddendumAdded) {
            setIsAddendumAdded(false)
            const createdBy = `${firstName} ${lastName}`
            if (newAddendums.length === 0) {
                append({ addendum: '', by: createdBy, on: DateTime.now().toISO() })
            }
        }
    }, [isAddendumAdded])

    useEffect(() => {
        if(hasSaved) {
            toast("Notes has been saved successfully.")
        }
    }, [hasSaved])

    useEffect(() => {
        if (hasSubmitted) {
            batch(() => {
                handleStatusChange(NotesStatus.LOCKED)
                if(sessionId) {
                    dispatch(updateSessionStatus({ sessionId, status: 'finished' }))
                }
            })
        }
    }, [hasSubmitted])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name && type === 'change') {
                const extendedName = name.split('.').length > 0 ? name.split('.')[0] : name
                const key = determineKey(extendedName)
                const answer = value[key]
                saveNotes(key, answer)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    useEffect(() => {
        const notes = psychiatristNotes?.psychiatristNotes
        if (notes) {
            const mentalBehavior = notes?.mentalBehavioral
            const socialDeterminants = notes?.socialDeterminants
            const current = (notes?.fromCurrentPhysician as any)?.selected as string[]
            const prior = (notes?.patientMedicationsHistory as any)?.selected as string[]

            const socialDeterminantsCreated = socialDeterminants && (socialDeterminants as string[] || []).map(saved => ({
                label: saved, value: saved
            })) || []
            const mentalBehaviorCreated = mentalBehavior && (mentalBehavior as string[] || []).map(saved => ({
                label: saved, value: saved
            })) || []
            const completeResultSetofIcdCodes = [...icdCodes.fSeries, ...icdCodes.zSeries, ...socialDeterminantsCreated]
            const completeResultSetofIcdCodesMentalBehavior = [...icdCodes.fSeries, ...icdCodes.zSeries, ...mentalBehaviorCreated]
            setIcdCodesSocial(completeResultSetofIcdCodes)
            setIcdCodesMentalBehavior(completeResultSetofIcdCodesMentalBehavior)

            const fromCurrentPhysicianCreated = current && (current as string[] || []).map(saved => ({
                label: saved, value: saved
            })) || []
            const fromPriorPhysicianCreated = prior && (prior as string[] || []).map(saved => ({
                label: saved, value: saved
            })) || []
            const completeResultSetOfFromCurrentPhysician = [...medications, ...fromCurrentPhysicianCreated]
            const completeResultSetOfFromPriorPhysician = [...medications, ...fromPriorPhysicianCreated]
            setFromCurrentPhysician(completeResultSetOfFromCurrentPhysician)
            setFromPriorPhysician(completeResultSetOfFromPriorPhysician)

            setIsLoading(false)
            reset(notes)
        }
    }, [psychiatristNotes])

    function handleStatusChange(status: NotesStatus) {
        const id = patientId
        const documentId = notesId || sessionNotesId
        if (id && documentId) {
            batch(() => {
                dispatchNotesStatusChange(status, documentId, id)
            })
        }
    }

    function renderIcdCodes() {
        return <div id="icd-codes" className="my-3">
            <label className="block text-lg mt-6 uppercase">DIAGNOSIS</label>
            <div className="mt-2 mb-2">
                <label className="block text-lg mt-2 text-sjOrange uppercase">
                    Mental, Behavioral and Neurodevelopmental disorders F01-F99
                </label>
                {!isLoading && <Controller
                    control={control}
                    name='mentalBehavioral'
                    render={({ field }) => (
                        <CreatableSelect list={icdCodesMentalBehavior} field={field} disabled={canAddPsychiatristNotes(notesStatus, role) ? false : true} />
                    )}
                />}
            </div>
            <div className="mt-2 mb-2">
                <label className="block text-lg mt-6 text-sjOrange uppercase">
                    Social Determinants of Health
                </label>
                {!isLoading && <Controller
                    control={control}
                    name='socialDeterminants'
                    render={({ field }) => (
                        <CreatableSelect list={icdCodesSocial} field={field} disabled={canAddPsychiatristNotes(notesStatus, role) ? false : true} />
                    )}
                />}
            </div>
        </div>
    }

    return (
        <>
            <section style={{ backgroundColor: '#FFFFFF' }}
                className={"w-full intake-section mt-4 p-10  min-h-[25vh] rounded-lg shadow-lg"}>
                <fieldset disabled={!canEdit}>
                    <div className="py-4">
                        <label className="text-lg mt-3">PRESENTING PROBLEM / CHIEF COMPLAINT:</label>
                        <Textarea className="outline-none rounded-md" placeholder="Describe the problem / complaint of the patient in detail"
                            {...register('presentChiefComplaint')} />
                    </div>
                    {renderIcdCodes()}
                    <div className="py-4">
                        <label className="text-lg uppercase">Diagnosis details: : </label>
                        <div>
                            <label className="text-lg text-sjOrange uppercase">BH Manager and Psychiatrist for followup Discussion:</label>
                            <div className="mt-2">
                                <Textarea className="outline-none rounded-md" {...register('followupDiscussion')}
                                    placeholder="Freeflow textbox for additional info" />
                            </div>
                        </div>
                    </div>
                    <div className="pb-4 pt-4">
                        <label className="text-lg text-sjOrange uppercase">Psychotropic Meds: </label>
                        <div className="grid">
                            <label className="block text-md mt-4">From Physician (current): </label>
                            {!isLoading && <Controller
                                control={control}
                                name='fromCurrentPhysician'
                                render={({ field }) => (
                                    <CreatableSelect list={fromCurrentPhysician} field={field} disabled={!canEdit} />
                                )}
                            />}
                        </div>
                        <div className="mt-8">
                            <label className="block text-md">From Physician (prior): </label>
                            {!isLoading && <Controller
                                control={control}
                                name='patientMedicationsHistory'
                                render={({ field }) => (
                                    <CreatableSelect list={fromPriorPhysician} field={field} disabled={!canEdit} />
                                )}
                            />}
                        </div>
                        {<RadioWithTextComponent {...{ register, watch }} question={''}
                            input={'medicationsRadio' as any} inputText={'medicationsText' as any} data={[{ label: 'None', value: 'none' }, { label: 'Current', value: 'current' }, { label: 'Prior', value: 'prior' }]}
                            hasToDisableOn="none" textPlaceholder="Add additional medication(s) and dosage discussed in the session"
                        />}
                    </div>
                    <ProposedMedication {...{control, reset, register, getValues, watch, setValue }} saveNotes={saveNotes}/>
                    <div className="py-4">
                        <label className="text-lg">Proposed Plan:</label>
                        <div className="mt-2">
                            <Textarea className="outline-none rounded-md" {...register('proposedPlan')}
                                placeholder="Type answer" />
                        </div>
                    </div>
                    <div className="py-4">
                        <label className="text-lg">Proposed Labs:</label>
                        <div className="mt-2">
                            <Textarea className="outline-none rounded-md" {...register('proposedLabs')}
                                placeholder="Type answer" />
                        </div>
                    </div>
                </fieldset>
            </section>
            {isNotesLocked(notesStatus)
                && <section style={{ backgroundColor: '#FFFFFF' }}
                    className={`w-full intake-section mt-6 p-10  min-h-[25vh] rounded-lg shadow-lg`} >
                    <Addendum {...{ register, control, addendums: newAddendums, remove, getValues }} saveNotes={saveNotes} />
                </section>}
        </>
    )
}

export default IndividualPsychiatristNotes
