import {apiHttpClient} from "../../../lib";
import {AxiosResponse} from "axios";
import {AuthState} from "../../../models";
import {Either} from "monet";
import {CheckedError} from "../../../types/ServiceError";
import {catchErrorMessage} from "../../../lib/api-helpers";
import {call, put, takeLatest} from "@redux-saga/core/effects";
import {callFinished, callInProgress} from "../../actions/loading.action";
import {callInitiated, callInitiationFailed, CARE_COORDINATOR_INITIATE_CALL, INITIATE_CALL} from "../../actions/counsellor/calls.action";

interface TokenResponse {
    token: string
}

const apiGetToken = (action: { type: string, authState: AuthState, payload: { patientId: string } }) => {
    const url = `/${action.authState.accountId}/comms/patients/${action.payload.patientId}`;
    return apiHttpClient.get<Either<CheckedError, TokenResponse>>(url, { headers: { "x-auth-token": action.authState.token } })
        .then((response: AxiosResponse) => {
            return Either.right(response.data as TokenResponse)
        }).catch(e => catchErrorMessage(e));
}

const apiGetCareCoordinatorToken = (action: { type: string, authState: AuthState, payload: { practiceId: string, patientId: string } }) => {
    console.log(action.authState.accountId);
    
    const url = `/${action.authState.accountId}/comms/practice/${action.payload.practiceId}/patient/${action.payload.patientId}`;
    return apiHttpClient.get<Either<CheckedError, TokenResponse>>(url, { headers: { "x-auth-token": action.authState.token } })
        .then((response: AxiosResponse) => {
            return Either.right(response.data as TokenResponse)
        }).catch(e => catchErrorMessage(e));
}

function* fetchToken(action: { type: string, authState: AuthState, payload: { patientId: string } }) {
    try {
        console.log(`CallsSaga:fetchToken`)
        yield put(callInProgress())
        const apiTokenResponse: Either<CheckedError, TokenResponse> = yield call(apiGetToken, action)
        if (apiTokenResponse.isLeft()) {
            const error = apiTokenResponse.left()
            if (error.isChecked) {
                console.log("CallsSaga:fetchToken Encountered a Checked Error", error.message)
                yield put(callInitiationFailed());
            } else {
                yield put(callInitiationFailed());
            }
        } else {
            const response = apiTokenResponse.right();
            yield put(callInitiated(response));
        }
    } catch (e) {
        console.log(e)
        yield put(callInitiationFailed());
    } finally {
        yield put(callFinished())
    }
}

function* fetchCareCoordinatorCallToken(action: { type: string, authState: AuthState, payload: { practiceId: string, patientId: string } }) {
    console.log(action.authState);
    
    try {
        console.log(`CallsSaga:fetchCareCoordinatorCallToken`)
        yield put(callInProgress())
        const apiTokenResponse: Either<CheckedError, TokenResponse> = yield call(apiGetCareCoordinatorToken, action)
        if (apiTokenResponse.isLeft()) {
            const error = apiTokenResponse.left()
            if (error.isChecked) {
                console.log("CallsSaga:fetchCareCoordinatorCallToken Encountered a Checked Error", error.message)
                yield put(callInitiationFailed());
            } else {
                yield put(callInitiationFailed());
            }
        } else {
            const response = apiTokenResponse.right();
            yield put(callInitiated(response));
        }
    } catch (e) {
        console.log(e)
        yield put(callInitiationFailed());
    } finally {
        yield put(callFinished())
    }
}

export default function* callsSaga() {
    yield takeLatest(INITIATE_CALL, fetchToken)
    yield takeLatest(CARE_COORDINATOR_INITIATE_CALL, fetchCareCoordinatorCallToken)
}
