import {classNames} from "../../shared/Utils";
import React from "react";

interface IPositiveNegative {
    text: "Positive" | "Negative";
    level?: any;
    style?: "text" | "filled";
}

export const PositiveNegative: React.FC<IPositiveNegative> = (props: IPositiveNegative) => {

    const { text, level, style="filled"} = props;
    const status = level && level !=='null' ? level : text;
    const negativeStatusValues = ["Negative", "Low Risk", "Minimal", "No Risk", "Mild", "Normal"];

    return (
        <span
            className={classNames(
                "py-1 font-semibold text-sm rounded-2xl text-black screener-interpretation-label",
                style,
                style === "text" ? "px-2" : "px-4",
                negativeStatusValues.some(value => status.includes(value)) ? "screener-interpretation-negative bg-sjBorderGreen" : "screener-interpretation-positive bg-sjPositive",
            )}
            dangerouslySetInnerHTML={{__html: status}}
        />
    );
}