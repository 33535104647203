import React, {PropsWithChildren} from "react";
import {classNames} from "../../shared/Utils";

export const PlainButton: React.FC<PropsWithChildren<any>> = ({ children, className, ...rest }) => {
    return (
        <button
            type="button"
            className={classNames(
                className
            )}
            {...rest}
        >
            {children}
        </button>
    );
}

export const Button: React.FC<PropsWithChildren<any>> = ({ children, className, ...rest }) => {
    return (
        <button
            type="button"
            className={classNames(
                "relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md text-gray-700 bg-white",
                className
            )}
            {...rest}
        >
            {children}
        </button>
    );
}

export const PageButton: React.FC<PropsWithChildren<any>> = ({ children, className, ...rest }) => {
    return (
        <button
            type="button"
            className={classNames(
                "relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50",
                className
            )}
            {...rest}
        >
            {children}
        </button>
    );
}

export const PageButtonV2: React.FC<PropsWithChildren<any>> = ({ children, className, ...rest }) => {
    return (
        <button
            type="button"
            className={classNames(
                "relative inline-flex items-center px-2 py-2 rounded-md",
                className
            )}
            {...rest}
        >
            {children}
        </button>
    );
}
export const SearchButton: React.FC<PropsWithChildren<any>> = ({ children, className, ...rest }) => {
    return (
        <button
            type="button"
            className={classNames(
                "relative inline-flex items-center px-3 py-3 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50",
                className
            )}
            {...rest}
        >
            {children}
        </button>
    );
}
