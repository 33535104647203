import React from "react";
import {IPatient, IReportReference} from "../../../../models";
import PatientProfile from "../PatientProfile";
import ReferDetails from "./ReferDetails";

interface PatientProps {
    patient: IPatient
    physicianReportReference: IReportReference
}

const ReferNotes : React.FC<PatientProps> = ({patient, physicianReportReference}) => {

    return <>
        <PatientProfile/>
        <ReferDetails patient={patient} physicianReportReference={physicianReportReference}/>
    </>
}

export default ReferNotes
