import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import {
    resetScreenerLinkToPatient,
    sendScreenerLinkToPatient
} from "../../store/actions/care-coordinator/patients.action";
import {toast} from "react-toastify";

interface IScreenerUserScreeningSMSActionProps {
    value: {
        patientId: string;
        practiceId: string;
        contactPhoneNumber: string;
    },
}

export const ScreenerUserScreeningSMSAction = (props: IScreenerUserScreeningSMSActionProps) => {

    const {value} = props;
    const {patientId, practiceId, contactPhoneNumber} = value;
    const {
        patientToSendScreenerLink,
        sendScreenerLinkToPatientInProgress,
        sendScreeningLinkSuccess,
        sendScreenerLinkToPatientError
    } = useSelector((state: StateParams) => state.coordinatorPatients);
    const dispatch = useDispatch();

    const handleSendScreenerLink = useCallback(() => {
        console.log("ScreenerUserScreeningSMSAction :: handleSendScreenerLink :: patientId, practiceId", patientId, practiceId);
        dispatch(sendScreenerLinkToPatient(patientId, practiceId));
    }, [patientId, practiceId]);

    useEffect(() => {
        console.log(patientToSendScreenerLink, patientId, sendScreenerLinkToPatientError, sendScreeningLinkSuccess);
        if (patientToSendScreenerLink?.patientId === patientId) {
            if (sendScreenerLinkToPatientError) {
                console.error("sendScreenerLinkToPatientError", sendScreenerLinkToPatientError, patientId, practiceId);
                toast("Error sending screening link");
                dispatch(resetScreenerLinkToPatient());
            }
            if (sendScreeningLinkSuccess) {
                toast(`Screening link sent successfully to ${contactPhoneNumber}`);
                dispatch(resetScreenerLinkToPatient());
            }
        }
    }, [sendScreeningLinkSuccess, patientToSendScreenerLink, sendScreenerLinkToPatientError, dispatch]);

    return (
        <button
            data-test="login-proceed"
            className={`text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${sendScreenerLinkToPatientInProgress ? 'cursor-progress bg-sj2' : 'bg-sjOrange'}`}
            type="button"
            disabled={sendScreenerLinkToPatientInProgress}
            onClick={handleSendScreenerLink}
        >
            {(sendScreenerLinkToPatientInProgress && patientToSendScreenerLink?.patientId === patientId) ? 'Sending...' : 'Send Link'}
        </button>
    );
}
