import {
    Control,
    FieldErrorsImpl,
    useFieldArray,
    UseFormGetValues,
    UseFormRegister,
    UseFormWatch
} from "react-hook-form";
import {Inputs} from "./SafetyPlanNotes";
import {Input} from "@material-tailwind/react";

const ContactInformation: React.FC<{
    control: Control<Inputs, any>, register: UseFormRegister<Inputs>,
    setValue: any, getValues: UseFormGetValues<Inputs>, watch: UseFormWatch<Inputs>, errors: Partial<FieldErrorsImpl<Inputs>>,
    saveNotes: (key: keyof Inputs, answer: any) => void

}> = ({ control, register, setValue, getValues, watch, errors, saveNotes }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: "contacts"
    });

    const handleAppend = (value: any) => {
        append(value)
        saveNotes('contacts', getValues().contacts)
    };

    const handleDelete = (index: number) => {
        remove(index)
        saveNotes('contacts', getValues().contacts)
    }

    return (
        <>
            <div className="">
                {fields.map((item, index) => {
                    return (
                        <>
                            <div className="flex justify-between mt-3">
                                <label className="block text-lg text-sjOrange uppercase"
                                    key={`label-${index}`}>Person {index + 1}:</label>
                            </div>
                            <div key={item.id} className="flex flex-row gap-3">
                                <div className="flex items-center justify-center space-x-14">
                                    <div>
                                        <label className="text-sm">Name</label>
                                        <Input type={'text'} {...register(`contacts.${index}.name`)} key={`statement-${index}`}
                                            className="outline-none rounded-md" size="md" placeholder="Type here"/>
                                    </div>
                                    <div>
                                        <label className="text-sm">Relationship</label>
                                        <Input type={'text'} {...register(`contacts.${index}.relationship`)} key={`statement-${index}`}
                                            className="outline-none rounded-md" size="md" placeholder="Type here"/>
                                    </div>
                                    <div>
                                        <label className="text-sm">Contact Number</label>
                                        <Input type={'text'} {...register(`contacts.${index}.contactNumber`)} key={`statement-${index}`}
                                            className="outline-none rounded-md" size="md" placeholder="Type here"/>
                                    </div>
                                    <div className="">
                                        <button className={`pl-2.5 pr-2.5 pt-1 pb-1 text-sm bg-sjWhite 
                                rounded-md text-sjOrange border-2 border-solid border-sjOrange`}
                                            onClick={() => handleDelete(index)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })}
            </div>
            <section>
                <button className={"p-2.5 mt-3 text-sm bg-sjOrange rounded-md text-sjWhite border-solid border-l-sjOrange"}
                    onClick={() => handleAppend({ name: '', relationship: '', contactNumber: '' })}
                >
                    Add more
                </button>
            </section>
        </>
    )
};

export default ContactInformation