import React, {useEffect, useState} from "react";
import {Outlet, useLocation} from "react-router-dom";
import AppointmentsComponent from "../../components/appointments/AppointmentsComponent";

const AppointmentsScreen = () => {
    const [showCreateSessionForm, setShowCreateSessionForm] = useState<boolean>(false)
    const location = useLocation();
    useEffect(() => {
        if (location.pathname.includes("create")) {
            setShowCreateSessionForm(true)
        } else {
            setShowCreateSessionForm(false)
        }
    }, [location.pathname])

    const renderCreateSessionForm = () => {
        return (
            <div className={"absolute top-0 right-0 w-[45%] bg-white z-[2000]"}>
                <Outlet />
            </div>
        )
    }

    const renderAppointments = () => {
        return <>
            <div className={showCreateSessionForm ? "foreground" : ""} />
            <div className={showCreateSessionForm ? "uneditable" : ""}>
                <div className="relative">
                    <AppointmentsComponent  />
                </div>
            </div>
        </>;
    }

    return (
        <div className={"w-full h-full  bg-[#F7F8FA]"}>
            {renderCreateSessionForm()}
            {renderAppointments()}
        </div>
    )
}

export default AppointmentsScreen
