import {CALL_FINISHED, CALL_IN_PROGRESS} from "../../actions/loading.action";
import {REHYDRATE} from "redux-persist";

export interface LoadingParams {
    loading: boolean
    rehydrate: boolean
}
const initialState: LoadingParams = {
    loading: false,
    rehydrate: false
}

const LoadingReducer = (state: LoadingParams = initialState, action: any) : LoadingParams => {
    switch (action.type) {
        case REHYDRATE:
            state = {...state, rehydrate: true}
            return state
        case CALL_IN_PROGRESS:
            state = {...state, loading: true}
            return state
        case CALL_FINISHED:
            state = {...state, loading: false}
            return state
        default:
            return state;
    }
}

export default LoadingReducer