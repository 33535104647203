import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import CallNotificationBar from "./CallNotificationBar";
import {CallStatus, isCallActive} from "../../models";
import {requestEndCall} from "../../store/actions/counsellor/calls.action";

const NotificationsBar: React.FC = () => {
    const {callStatus} = useSelector((state: StateParams )=> state.calls)
    const { role } = useSelector((state: StateParams) => state.account)
    const dispatch = useDispatch()

    useEffect(() => {
        if(!role && isCallActive(callStatus)) {
            dispatch(requestEndCall())
        }
    }, [role, callStatus]);

    return <CallNotificationBar />
}

export default NotificationsBar
