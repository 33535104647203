import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { IProspect } from "../../models";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import ProspectProfile from "../prospects/ProspectProfile";
import * as _ from "lodash";
import BackIcon from "../../assets/images/common/back.svg"

interface Props {
    title: string;
    patient: IProspect | undefined
    actionComponent?: React.ReactNode
    children: React.ReactNode;
    isModalOpen?: boolean
    notesSessionId?: string
}
const ClinicalNotesComponent: React.FC<Props> = ({ title, patient, actionComponent, children, isModalOpen, notesSessionId }) => {
    const navigate = useNavigate()
    const renderPatientProfile = () => <>{patient && <ProspectProfile prospect={patient} />}</>
    return (
        <main className="relative min-h-screen mx-auto flex flex-col" style={{ backgroundColor: '#EEF7FA' }}>
            <div className={`p-3 ${!isModalOpen ? 'floating-section' : ''}`}>
                <div className={"flex flex-row justify-between"}>
                    <div id={"link-section"} className={"flex flex-row gap-2 items-center"}>
                        {/* <Link to={'..'}
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(-1);
                            }}>
                            <IoArrowBackCircleOutline style={{ width: '25px', height: '25px' }}
                                className="rounded-l-md border-sjOrange text-sjLink"></IoArrowBackCircleOutline>
                        </Link> */}
                        <Link to={".."} onClick={(e) => {
                            e.preventDefault();
                            navigate(-1);
                        }}>
                            <img src={BackIcon} width={24} height={24} alt="Go Back"
                                className={"hover:cursor-pointer"} />
                        </Link>
                        <span className="text-xl font-semibold text-sjGray" data-testid="sessionType"> {title} {'|'} </span>
                        <span className="text-xl font-semibold"> {_.capitalize(patient?.firstName)} {_.capitalize(patient?.lastName)} </span>

                        {/* <h5 className="text-xl font-semibold text-sjGray">  {title} | {patient?.firstName} {patient?.lastName}</h5> */}
                    </div>
                    <div id={"button-section"} className={"pr-1.5"}>
                        {actionComponent}
                    </div>
                </div>
            </div>
            <div className="px-5 flex-1" >
                {renderPatientProfile()}
                {children}
            </div>
        </main>
    )
}

export default ClinicalNotesComponent