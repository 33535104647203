import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { AnyAction } from 'redux'

type UseToastNotificationsParams<T> = {
    toastId: string
    errorCondition: T
    successCondition: boolean | undefined
    errorMessage: string
    successMessage: string
    clearErrorAction: () => AnyAction
}

function useToastNotifications<T>({
    toastId,
    errorCondition,
    successCondition,
    errorMessage,
    successMessage,
    clearErrorAction,
}: UseToastNotificationsParams<T>) {
    const dispatch = useDispatch()

    useEffect(() => {
        if (!_.isUndefined(errorCondition) && errorCondition) {
            dispatch(clearErrorAction())
            toast.error(errorMessage, {
                position: 'top-right',
                hideProgressBar: true,
                closeOnClick: true,
                toastId
            })
        } else if (_.isUndefined(errorCondition) && successCondition) {
            dispatch(clearErrorAction())
            toast.success(successMessage, {
                position: 'top-right',
                hideProgressBar: true,
                closeOnClick: true,
                autoClose: 2000,
                toastId
            })
        }
    }, [errorCondition, successCondition, errorMessage, successMessage, dispatch, clearErrorAction])
}

export default useToastNotifications
