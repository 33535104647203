import {AxiosResponse} from "axios";
import {apiHttpClient} from "../lib/http-client"
import {IDebug} from '../models'

export const debugGet = () => {
    return apiHttpClient.get<IDebug>("/ping")
        .then((response: AxiosResponse) => {
            return response.data.data
        }).catch((e) => {
            throw "Failed Creating Account"
        });
}
