import {call, put, takeLatest} from "@redux-saga/core/effects";
import {apiHttpClient, getAuthHeaderConfig} from "../../../lib";
import {Either} from "monet";
import {CheckedError, ServiceError} from "../../../types/ServiceError";
import {
    AuthState,
    IAppointment,
    IAppointmentWithProspect, IAppointmentWithTherapist,
    IBackdatedAppointmentCreateResponse,
    ICreateAppointment,
    IEditAppointment, IPotentialReferralPatientsResponse,
    ITherapistsResponse,
    SessionTypes,
} from "../../../models";
import {AxiosError, AxiosResponse} from "axios";
import {callFinished, callInProgress, asyncCallInProgress, asyncCallFinished} from "../../actions/loading.action";
import {
    CANCEL_APPOINTMENT,
    CANCEL_APPOINTMENT_FAILED,
    CANCEL_APPOINTMENT_SUCCESS,
    cancelAppointmentInProgress,
    cancelingAppointmentFailed,
    CREATE_APPOINTMENT,
    CREATE_BACKDATED_APPOINTMENT,
    createdAppointment,
    createdBackDatedAppointment,
    creatingAppointmentFailed,
    creatingBackDatedAppointmentFailed,
    FETCH_APPOINTMENTS,
    FETCH_SESSION,
    FETCH_THERAPISTS,
    fetchedAppointments,
    fetchedSession,
    fetchedTherapists,
    fetchingAppointmentsFailed,
    fetchingSessionFailed,
    fetchTherapistsFailed,
    SEARCH_APPOINTMENTS,
    searchAppointmentsInProgress,
    searchedAppointments,
    searchingAppointmentsFailed,
    UPDATE_SESSION_CALL_LOG,
    UPDATE_SESSION_STATUS,
    updatedSessionCallLog,
    updatedSessionStatus,
    updateSessionStatusFailed,
    updatingSessionCallLogFailed
} from "../../actions/counsellor/appointments.action";
import {catchErrorMessage, handleErrorOrResponse, postAPI} from "../../../lib/api-helpers";
import {fetchProspectWithSummary, fetchSessionBySourcePatientIdFailed, patchPatientInfoStatus} from "../../actions/counsellor/prospects.action";
import { updatePatientInfo } from "../../actions/counsellor/patients.action";
import {ReferredPatientCallStatus} from "../../../models/index"

const apiFetchAppointments = (action: { type: string, authState: AuthState, payload: {filters?: {status: string[]}}}) => {
    const url = `${action.authState.accountId}/appointments/search`;
    return apiHttpClient.post<Either<CheckedError, IAppointmentWithProspect[]>>(url, action.payload.filters ? {filters: action.payload.filters} : {}, {headers: {"x-auth-token": action.authState.token}})
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IAppointmentWithProspect[])
        }).catch((e: AxiosError<ServiceError>) => catchErrorMessage(e));
}

interface SearchAppointmentsResponse {
    patientSessions: IAppointmentWithProspect[];
    count: number;
}

const apiSearchAppointments = (action: { type: string, payload: {currentPage: number, recordsPerPage: number, searchText: string, filters?: {status: string[]}}, authState: AuthState}) => {
    const url = `${action.authState.accountId}/appointments/v2/search?searchText=${action.payload.searchText}&pageNumber=${action.payload.currentPage-1}&recordsPerPage=${action.payload.recordsPerPage}`;
    return apiHttpClient.post<Either<CheckedError, SearchAppointmentsResponse>>(url, action.payload.filters? {filters: action.payload.filters} : {}, {headers: {"x-auth-token": action.authState.token}})
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as SearchAppointmentsResponse)
        }).catch((e: AxiosError<ServiceError>) => catchErrorMessage(e));
}

const apiCreateAppointment = (action: { type: string, authState: AuthState, payload: {appointment: ICreateAppointment, sourcePatientId: string}}) => {
    const url = `${action.authState.accountId}/appointments`;
    return apiHttpClient.post<Either<CheckedError, IAppointment>>(url,{ ...action.payload.appointment, sourcePatientId: action.payload.sourcePatientId}, {
        headers: {"x-auth-token": action.authState.token}})
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IAppointment)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if(statusCode === 422) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 422 Error", errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred"))
            } else if(statusCode >=400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiUpdateCallLog = (action: { type: string, authState: AuthState, payload: {sessionId: string, callInstanceId: string, connectedAtTime: string, closedAtTime: string, status: "started" | "in_progress" | "finished"}}) => {
    const url = `${action.authState.accountId}/appointments/${action.payload.sessionId}/log-call-session`;
    const {status, callInstanceId, connectedAtTime, closedAtTime} = action.payload
    return apiHttpClient.put<Either<CheckedError, IAppointment>>(url, {status, callInstanceId, connectedAtTime, closedAtTime}, {headers: {"x-auth-token": action.authState.token}})
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IAppointment)
        }).catch((e: AxiosError<ServiceError>) => catchErrorMessage(e));
}

const apiUpdateSessionStatus = (action: { type: string, authState: AuthState, payload: {sessionId: string, status: "started" | "in_progress" | "finished"}}) => {
    const url = `${action.authState.accountId}/appointments/${action.payload.sessionId}/v2/status`;
    const {status} = action.payload
    return apiHttpClient.put<Either<CheckedError, IAppointment>>(url, {status}, {headers: {"x-auth-token": action.authState.token}})
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IAppointment)
        }).catch((e: AxiosError<ServiceError>) => catchErrorMessage(e));
}

const apiFetchSession = (action: { type: string, authState: AuthState, payload: { patientId: string, sessionType: SessionTypes } }) => {
    const url = `${action.authState.accountId}/appointments/patients/${action.payload.patientId}/latest?sessionType=${action.payload.sessionType}`;
    return apiHttpClient.get<Either<CheckedError, IAppointmentWithTherapist | null>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IAppointmentWithTherapist | null)
        }).catch(e => catchErrorMessage(e));
}

const apiFetchTherapists = (action: { type: string, authState: AuthState }) => {
    const url = ``;
    return apiHttpClient.get<Either<CheckedError, ITherapistsResponse[]>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as ITherapistsResponse[])
        }).catch(e => catchErrorMessage(e));
}

const apiCreateBackdatedAppointment = (action: { type: string, authState: AuthState, payload: {appointment: ICreateAppointment, sourcePatientId: string}}) => {
    const url = `${action.authState.accountId}/appointments/past`;
    return apiHttpClient.post<Either<CheckedError, IBackdatedAppointmentCreateResponse>>(url,{ ...action.payload.appointment, sourcePatientId: action.payload.sourcePatientId}, {
        headers: {"x-auth-token": action.authState.token}})
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IBackdatedAppointmentCreateResponse)
        }).catch((e: AxiosError<ServiceError>) => catchErrorMessage(e));
}

function* fetchAppointments(action: { type: string, authState: AuthState, payload: {filters? : {status: string[]}}}) {
    try {
        console.log(`appointmentsSaga:fetchAppointments`)
        yield put(callInProgress())
        const response: Either<CheckedError, IAppointmentWithProspect[]> = yield call(apiFetchAppointments, action)
        if(response.isLeft()) {
            const error = response.left()
            if(error.isChecked) {
                console.log("appointmentsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingAppointmentsFailed({error: error.message}));
            } else {
                yield put(fetchingAppointmentsFailed({error: error.message}));
            }
        } else {
            const appointments = response.right();
            yield put(fetchedAppointments(appointments));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingAppointmentsFailed({error: "Error fetching appointments!"}));
    } finally {
        yield put(callFinished())
    }
}

function* searchAppointments(action: { type: string, authState: AuthState, payload: {currentPage: number, recordsPerPage: number, searchText: string, filters? : {status: string[]}}}) {
    try {
        console.log(`appointmentsSaga:searchAppointments`)
        yield put(callInProgress())
        yield put(searchAppointmentsInProgress())
        const response: Either<CheckedError, SearchAppointmentsResponse> = yield call(apiSearchAppointments, action)
        if(response.isLeft()) {
            const error = response.left()
            if(error.isChecked) {
                console.log("appointmentsSaga: Encountered a Checked Error", error.message)
                yield put(searchingAppointmentsFailed({error: error.message}));
            } else {
                yield put(searchingAppointmentsFailed({error: error.message}));
            }
        } else {
            const appointments = response.right();
            yield put(searchedAppointments(appointments.patientSessions, appointments.count));
        }
    } catch (e) {
        console.log(e)
        yield put(searchingAppointmentsFailed({error: "Error Searching appointments!"}));
    } finally {
        yield put(callFinished())
    }
}
function* createAppointment(action: { type: string, authState: AuthState, payload: {appointment: ICreateAppointment, sourcePatientId: string, practiceId: string}}) {
    try {
        console.log(`appointmentsSaga:createAppointment`)
        yield put(callInProgress())
        const response: Either<CheckedError, IAppointment> = yield call(apiCreateAppointment, action)
        if(response.isLeft()) {
            const error = response.left()
            if(error.isChecked) {
                console.log("appointmentsSaga: Encountered a Checked Error", error.message)
                yield put(creatingAppointmentFailed({error: error.message}));
            } else {
                yield put(creatingAppointmentFailed({error: error.message}));
            }
        } else {
            const appointment = response.right();
            yield put(createdAppointment(appointment));
            
            if(action.payload.sourcePatientId && action.payload.practiceId) {
                const callStatus = 'scheduled' as unknown
                yield put(updatePatientInfo(action.payload.sourcePatientId, action.payload.practiceId, { referredCallStatus: callStatus as  ReferredPatientCallStatus }))
                yield put(patchPatientInfoStatus({referredCallStatus: callStatus as keyof ReferredPatientCallStatus}))
            }
        }
    } catch (e) {
        console.log(e)
        yield put(creatingAppointmentFailed({error: "Error creating appointment!"}));
    } finally {
        yield put(callFinished())
    }
}

const apiCancelAppointment = (action: { type: string, authState: AuthState, payload: {appointment: IEditAppointment}}) => {
    const url = `${action.authState.accountId}/appointments/${action.payload.appointment.sessionId}/cancel`;
    return postAPI<IAppointment, {appointment: IEditAppointment}>(
        url,
        action.payload,
        action.authState
    )
}

function* cancelAppointment(action: { type: string, authState: AuthState, payload: {appointment: IEditAppointment, sourcePatientId?: string, practiceId?: string}}) {
    try {
        console.log(`appointmentsSaga:cancelAppointment`)
        yield put(callInProgress())
        yield put(cancelAppointmentInProgress());
        const response: Either<CheckedError, IAppointment> = yield call(apiCancelAppointment, action)
        yield handleErrorOrResponse(
            response,
            CANCEL_APPOINTMENT_FAILED,
            CANCEL_APPOINTMENT_SUCCESS,
            "appointment",
            "cancelAppointment"
        )

        if(action.payload.appointment.patientId && action.payload.sourcePatientId && action.payload.practiceId) {
            yield put(fetchProspectWithSummary(action.payload.appointment.patientId, action.payload.practiceId, action.payload.sourcePatientId))
        }

        if(action.payload.sourcePatientId && action.payload.practiceId) {
            const callStatus = 'pending' as unknown
            yield put(updatePatientInfo(action.payload.sourcePatientId, action.payload.practiceId, { referredCallStatus: callStatus as  ReferredPatientCallStatus }))
            yield put(patchPatientInfoStatus({referredCallStatus: callStatus as keyof ReferredPatientCallStatus}))
        }
    } catch (e) {
        console.log(e)
        yield put(cancelingAppointmentFailed({error: "Error canceling appointment!"}));
    }
}

function* updateCallLog(action: { type: string, authState: AuthState, payload: {sessionId: string, callInstanceId: string, connectedAtTime: string, closedAtTime: string, status: "started" | "in_progress" | "finished"}}) {
    try {
        console.log(`appointmentsSaga:updateCallLog`)
        yield put(callInProgress())
        const response: Either<CheckedError, IAppointment> = yield call(apiUpdateCallLog, action)
        if(response.isLeft()) {
            const error = response.left()
            if(error.isChecked) {
                console.log("appointmentsSaga: Encountered a Checked Error", error.message)
                yield put(updatingSessionCallLogFailed({error: error.message}));
            } else {
                yield put(updatingSessionCallLogFailed({error: error.message}));
            }
        } else {
            const appointment = response.right();
            yield put(updatedSessionCallLog(appointment));
        }
    } catch (e) {
        console.log(e)
        yield put(updatingSessionCallLogFailed({error: "Error updating call log!"}));
    } finally {
        yield put(callFinished())
    }
}

function* updateSessionStatus(action: { type: string, authState: AuthState, payload: {sessionId: string, status: "started" | "in_progress" | "finished"}}) {
    try {
        console.log(`appointmentsSaga:updateAppointmentStatus`)
        yield put(callInProgress())
        const response: Either<CheckedError, IAppointment> = yield call(apiUpdateSessionStatus, action)
        if(response.isLeft()) {
            const error = response.left()
            if(error.isChecked) {
                console.log("appointmentsSaga: Encountered a Checked Error", error.message)
                yield put(updateSessionStatusFailed({error: error.message}));
            } else {
                yield put(updateSessionStatusFailed({error: error.message}));
            }
        } else {
            const appointment = response.right();
            yield put(updatedSessionStatus(appointment));
        }
    } catch (e) {
        console.log(e)
        yield put(updateSessionStatusFailed({error: "Error updating appointment status!"}));
    } finally {
        yield put(callFinished())
    }
}


function* fetchSession(action: { type: string, authState: AuthState, payload: { patientId: string, sessionType: SessionTypes } }) {
    try {
        console.log(`appointmentsSaga:fetchSession`)
        yield put(callInProgress())
        const apiFetchSessionResponse: Either<CheckedError, IAppointmentWithTherapist | null> = yield call(apiFetchSession, action)
        if (apiFetchSessionResponse.isLeft()) {
            const error = apiFetchSessionResponse.left()
            if (error.isChecked) {
                console.log("appointmentsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingSessionFailed({ error: error.message }));
            } else {
                yield put(fetchingSessionFailed({ error: error.message }));
            }
        } else {
            const session = apiFetchSessionResponse.right();
            yield put(fetchedSession(session));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchSessionBySourcePatientIdFailed({ error: "Error fetching session!" }));
    } finally {
        yield put(callFinished())
    }
}

function* fetchTherapists(action: { type: string, authState: AuthState }) {
    try {
        console.log(`appointmentsSaga:fetchTheapists`)
        yield put(asyncCallInProgress())
        const apiFetchTherapistsResponse: Either<CheckedError, ITherapistsResponse[]> = yield call(apiFetchTherapists, action)
        if (apiFetchTherapistsResponse.isLeft()) {
            const error = apiFetchTherapistsResponse.left()
            if (error.isChecked) {
                console.log("appointmentsSaga: Encountered a Checked Error", error.message)
                yield put(fetchTherapistsFailed({ error: error.message }));
            } else {
                yield put(fetchTherapistsFailed({ error: error.message }));
            }
        } else {
            const therapists = apiFetchTherapistsResponse.right();
            yield put(fetchedTherapists(therapists));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchTherapistsFailed({ error: "Error fetching session!" }));
    } finally {
        yield put(asyncCallFinished())
    }
}

function* createBackdatedAppointment(action: { type: string, authState: AuthState, payload: {appointment: ICreateAppointment, sourcePatientId: string}}) {
    try {
        console.log(`appointmentsSaga:createBackdatedClinicalNote`)
        yield put(callInProgress());
        const response: Either<CheckedError, IBackdatedAppointmentCreateResponse> = yield call(apiCreateBackdatedAppointment, action)
        if(response.isLeft()) {
            const error = response.left()
            if(error.isChecked) {
                console.log("appointmentsSaga: Encountered a Checked Error", error.message)
                yield put(creatingBackDatedAppointmentFailed({error: error.message}));
            } else {
                yield put(creatingBackDatedAppointmentFailed({error: error.message}));
            }
        } else {
            const clinicalNote = response.right();
            yield put(createdBackDatedAppointment(clinicalNote));
        }
    } catch (e) {
        console.log(e)
        yield put(creatingAppointmentFailed({error: "Error creating backdated clinical note!"}));
    } finally {
        yield put(callFinished())
    }
}

export default function* appointmentsSaga() {
    yield takeLatest(FETCH_APPOINTMENTS, fetchAppointments)
    yield takeLatest(SEARCH_APPOINTMENTS, searchAppointments)
    yield takeLatest(CREATE_APPOINTMENT, createAppointment)
    yield takeLatest(CANCEL_APPOINTMENT, cancelAppointment)
    yield takeLatest(UPDATE_SESSION_CALL_LOG, updateCallLog)
    yield takeLatest(UPDATE_SESSION_STATUS, updateSessionStatus)
    yield takeLatest(FETCH_SESSION, fetchSession)
    yield takeLatest(FETCH_THERAPISTS, fetchTherapists)
    yield takeLatest(CREATE_BACKDATED_APPOINTMENT, createBackdatedAppointment)
}
