import React from "react";
import {useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import PhysicianPatients from "../../../components/patients/physician/PhysicianPatients";

const PatientsScreen = () => {
    const {role} = useSelector((state: StateParams) => state.physicianAccount)


    function renderPatientList() {
        if(!role) {
            return <h1>You dont have access to view this data</h1>
        }
        return <PhysicianPatients />

    }

    return (
        <>{ renderPatientList() } </>
    )
}

export default PatientsScreen
