import React, {useMemo} from "react";
import {DateTime} from "luxon";
import TablePaginationV2 from "../generic/TablePaginationV2";
import {IAppointmentWithProspect} from "../../models";
import {deduceActionStatus} from "../../shared/Utils";
import {AppointmentStatusButtonV2} from "../generic/AppointmentStatusButtonV2";
import {useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import { MaskPhoneNumber } from "../generic/MaskPhoneNumber";

interface AppointmentTableProps {
    appointments: IAppointmentWithProspect[],
    searchInProgress: boolean | undefined
}

const AppointmentTable : React.FC<AppointmentTableProps> = ({appointments, searchInProgress}) => {

    const {firstName, lastName} = useSelector((state: StateParams) => state.practitionerAccount)

    const data = appointments.map((appointment) => {
        const dob = DateTime.fromISO(appointment.patientProspect.dob.toString(), {zone: 'utc'}).toFormat('MM-dd-yyyy')
        const referringPhysician = appointment.patientProspect.referredByPhysicianFirstName ?
            `Dr. ${appointment.patientProspect.referredByPhysicianFirstName} ${appointment.patientProspect.referredByPhysicianLastName}` : 'N/A'
        return {
            id: appointment.id,
            name: `${appointment.patientProspect.lastName}, ${appointment.patientProspect.firstName}`,
            dob: dob,
            phoneNumber: appointment.patientProspect.phoneNumber,
            referringPhysician: referringPhysician,
            scheduledFor: DateTime.fromISO(appointment.scheduledDate, {zone: 'America/New_York'}).toFormat('MM/dd/yyyy, hh:mm a'),
            sessionType: `${appointment.sessionType} ( ${appointment.durationInMinutes} mins )`,
            sessionDetails: {
                id: appointment.id,
                status: deduceActionStatus(appointment),
                sessionName: `${lastName}, ${firstName}`,
                scheduledDate: DateTime.fromISO(appointment.scheduledDate).toLocal(),
                endDate: DateTime.fromISO(appointment.endsAtDate).toLocal(),
                patientProspect: appointment.patientProspect,
                sessionType: appointment.sessionType
            }
        }
    })

    const columns = useMemo(
        () => [
            { header: "Patient Name", accessorKey: "name" },
            { header: "DOB", accessorKey: "dob" },
            { header: "Phone Number", accessorKey: "phoneNumber", cell: (props: any) =>
                <MaskPhoneNumber value={props.getValue()} disabled={true} className={'border-0 bg-transparent'}/> },
            { header: "Referring Physician", accessorKey: "referringPhysician" },
            { header: "Scheduled For", accessorKey: "scheduledFor" },
            { header: "Session Type", accessorKey: "sessionType" },
            { header: "Action", accessorKey: "sessionDetails", cell: AppointmentStatusButtonV2 },
        ],
        []
    );

    return <>
        <div>
            <TablePaginationV2 columns={columns} data={data} searchInProgress={searchInProgress}/>
        </div>
    </>
}

export default AppointmentTable
