import React, { useEffect, useRef } from 'react'
import ComponentToPrint from './ComponentToPrint';
import { useReactToPrint } from 'react-to-print';
import { PrinterIcon } from '@heroicons/react/20/solid'

const QRCode = () => {
    const componentRef = useRef<HTMLDivElement>(null);

    const pageStyle = `
        body{
            padding: 150px;
        }
    `;

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `${pageStyle}`
    });

    useEffect(() => {
        document.addEventListener("keydown", function (event) {
            if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
                event.cancelBubble = true;
                event.preventDefault();
                event.stopImmediatePropagation();
                alert("ctrl+P detected");
            }   
        });
    },[])

    const contextMenu = (e: any) => {
        e.preventDefault();
        alert('Right click disabled')
        return false
    }

    return (
        <div className='print-source h-screen w-full bg-no-repeat bg-cover bg-qrBackground flow-root p-4' onContextMenu={contextMenu}>
                <button className='w-[140px] h-[46px] border-2 rounded-md p-2 bg-sjOrange font-bold text-white text-lg float-right flex justify-center' onClick={handlePrint}><PrinterIcon className="h-5 w-5 m-1" aria-hidden="true"/>Print</button>
                <ComponentToPrint ref={componentRef}/>
        </div>
    )
}

export default QRCode;