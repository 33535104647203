
import { useState } from "react"
import { AiFillCloseCircle } from "react-icons/ai"
import Modal from "react-modal"
import { useDispatch, useSelector } from "react-redux"
import { IEditAppointment, IProspect, PatientSessionStatus } from "../../../models"
import { cancelAppointment } from "../../../store/actions/counsellor/appointments.action"
import { StateParams } from "../../../store/reducers"

interface Props {
    prospect: IProspect
    setIsModalOpen: (isModalOpen: boolean) => void
    isModalOpen: boolean
}

const IntakeCancelModal = ({ prospect, isModalOpen, setIsModalOpen }: Props) => {
    const dispatch = useDispatch();
    const { session } = useSelector((state: StateParams) => state.appointments)
    const [cancelReason, setCancelReason] = useState('')

    const cancelIntakeEvent = () => {
        if (!prospect.id || !session?.id) {
            return
        }
        setIsModalOpen(false)
        const appointmentRequest: IEditAppointment = {
            patientId: prospect.id,
            sessionId: session.id,
            status: PatientSessionStatus.canceled,
            reason: cancelReason
        }
        dispatch(cancelAppointment(appointmentRequest, prospect.sourcePatientId, prospect.referredByPracticeId))
    }

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isModalOpen}
            shouldCloseOnOverlayClick={false}
            className="relative top-20 mx-auto p-5 border w-[634px] h-[307px] shadow-lg rounded-md bg-white border-sjOrange"
        >
            <div className="mt-1 flex flex-col p-2">
                <div onClick={() => setIsModalOpen(false)} className="cursor-pointer absolute top-1 right-1 m-1">
                    <AiFillCloseCircle
                        className="text-gray-500 hover:text-gray-700"
                        style={{ width: '25px', height: '25px' }}
                    />
                </div>
                <label className="mt-2 text-center text-sjGray">
                    {`Are you sure you want to proceed with canceling Intake Appointment with ${prospect.firstName} ${prospect.lastName} & Dr. ${prospect.referredByPracticeName}?`}
                </label>
                <textarea rows={4} className="mt-6 rounded-md border-sjGrey" onChange={(e) => setCancelReason(e.target.value)} placeholder="Enter reason for cancellation." />
                <button
                    id="ok-btn"
                    onClick={cancelIntakeEvent}
                    className="py-2 mt-5 bg-sjOrange text-white text-base font-medium rounded-md w-full shadow-sm focus:outline-none focus:ring-2"
                >
                    Cancel Intake Appointment
                </button>
            </div>
        </Modal>
    )
}

export default IntakeCancelModal