import React, {useEffect} from "react";
import {Link, NavLink, Outlet, useNavigate, useParams} from "react-router-dom";
import {RoutePaths} from "../../../shared/Utils";
import {useDispatch, useSelector} from "react-redux";
import {fetchPatientBasicInformation} from "../../../store/actions/care-coordinator/patients.action";
import {StateParams} from "../../../store/reducers";
import PatientDetails from "./PatientDetails";
import CoordinatorPatientProfile from "./CoordinatorPatientProfile";
import BackIcon from "../../../assets/images/common/back.svg";
import * as _ from "lodash";


const PatientDetailsScreen = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const { patientBasicInformation: patient} = useSelector((state: StateParams) => state.coordinatorPatients);
  
    useEffect(() => {
        navigate(RoutePaths.careCoordinator.potentialPatients.patientDetails.patientInformation);
    }, []);

    useEffect(() => {
        if(params.patientId) {
            dispatch(fetchPatientBasicInformation(params.patientId));
        }
    }, [params.patientId]);

    function renderBackButton(patient: any) {
        return <div className={"flex flex-row gap-2 items-center"}>
            <Link to={".."} onClick={(e) => {
                e.preventDefault();
                navigate(-1);
            }}>
                <img src={BackIcon} width={24} height={24} alt="Go Back"
                     className={"hover:cursor-pointer"}/>
            </Link>
            <div id={"link-section"} className={"flex flex-row gap-2 items-center"}>
                <span className="text-xl font-semibold text-sjGray"> Patient Details {'|'} </span>
                <span className="text-xl font-semibold"> {_.capitalize(patient.firstName)} {_.capitalize(patient.lastName)} </span>
            </div>
        </div>;
    }

    return (
        <div className="w-full h-full p-4 bg-[#F7F8FA]">
            <div className={" floating-section bg-inherit"}>
                <div className={"pb-3 flex flex-row justify-between"}>
                {patient ? renderBackButton(patient) : null}
                </div>
            </div>
            {patient && <CoordinatorPatientProfile patient={patient}/>}
            {patient && <PatientDetails patient={patient}/>}
        </div>
    )
}

export default PatientDetailsScreen
