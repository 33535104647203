import { AnyAction } from "redux";
import { ISOAPNote, ISOAPNoteList, ISOAPNotePatient, ISOAPNotePatients, IUser, IUsers, LocationIndexItem } from "../../../models";
import {
    CLEAR_LOCATION,
    FETCH_LOCATION,
    FETCH_LOCATION_FAILED,
    FETCH_LOCATION_IN_PROGRESS,
    FETCH_LOCATION_SUCCESS,
    FETCH_LOCATIONS,
    FETCH_LOCATIONS_FAILED,
    FETCH_LOCATIONS_IN_PROGRESS,
    FETCH_LOCATIONS_SUCCESS,
    SAVE_LOCATION,
    SAVE_LOCATION_FAILED,
    SAVE_LOCATION_IN_PROGRESS,
    SAVE_LOCATION_SUCCESS,
    INITIATE_LOCATION_PHONE_VERIFICATION,
    INITIATE_LOCATION_PHONE_VERIFICATION_FAILED,
    INITIATE_LOCATION_PHONE_VERIFICATION_IN_PROGRESS,
    INITIATE_LOCATION_PHONE_VERIFICATION_SUCCESS,
    FETCH_USERS,
    FETCH_USERS_FAILED,
    FETCH_USERS_IN_PROGRESS,
    FETCH_USERS_SUCCESS, SAVE_USER, SAVE_USER_FAILED, SAVE_USER_SUCCESS, RESET_SAVE_USER,
    FETCH_SOAP_NOTES,
    FETCH_SOAP_NOTES_FAILED,
    FETCH_SOAP_NOTES_IN_PROGRESS,
    FETCH_SOAP_NOTE_PATIENTS_SUCCESS,
    FETCH_SOAP_NOTE_PATIENTS,
    FETCH_SOAP_NOTE_PATIENTS_IN_PROGRESS,
    FETCH_SOAP_NOTE_PATIENTS_FAILED,
    FETCH_SOAP_NOTES_SUCCESS,
    MARK_SOAP_NOTE_AS_READ,
    MARK_SOAP_NOTE_AS_READ_IN_PROGRESS,
    MARK_SOAP_NOTE_AS_READ_SUCCESS,
    MARK_SOAP_NOTE_AS_READ_FAILED,
    FETCH_SOAP_NOTE_CONTENT,
    FETCH_SOAP_NOTE_CONTENT_IN_PROGRESS,
    FETCH_SOAP_NOTE_CONTENT_SUCCESS,
    FETCH_SOAP_NOTE_CONTENT_FAILED,
    EDIT_SOAP_NOTE_CONTENT,
    EDIT_SOAP_NOTE_CONTENT_IN_PROGRESS,
    EDIT_SOAP_NOTE_CONTENT_SUCCESS,
    EDIT_SOAP_NOTE_CONTENT_FAILED,
    EDIT_SOAP_NOTE_REASON,
    EDIT_SOAP_NOTE_REASON_IN_PROGRESS,
    EDIT_SOAP_NOTE_REASON_SUCCESS,
    SET_LOCATION_ID_TO_INTITATE_CALL,
    CLEAR_LOCATION_ID_TO_INTITATE_CALL
} from "../../actions/physician/admin-operations.action";
import { SIGN_OUT_ACCOUNT } from "../../actions/common";

export interface AdminOperationsParams {
    locations: LocationIndexItem[]
    locationsCount?: number
    fetchLocationsInProgress?: boolean
    sideBarTab?: string
    location?: LocationIndexItem
    fetchLocationInProgress?: boolean
    fetchLocationIsSuccess?: boolean
    phoneVerificationInProgress?: boolean
    phoneVerificationCode?: string
    initiatingPhoneVerificationError?: string
    saveLocationError?: string
    users: IUser[],
    usersCount: number,
    fetchUsersInProgress?: boolean,
    fetchUsersIsSuccess: boolean,
    fetchUsersError: string,
    saveUserError?: string,
    savedUser?: IUser,
    savingUserInProgress?: boolean,
    SOAPNotepatients: ISOAPNotePatient[]
    SOAPNoteCount?: number
    fetchSoapNotePatientsInProgress?: boolean
    fetchSoapNotePatientsIsSuccess?: boolean
    fetchSoapNotePatientsError?: string
    soapNoteList?: ISOAPNote[]
    fetchSoapNotesInProgress?: boolean
    fetchSoapNotesIsSuccess?: boolean
    fetchSoapNotesError?: string,
    markSoapNoteAsReadInProgress?: boolean,
    markSoapNoteAsReadSuccess?: boolean,
    markSoapNoteAsReadError?: string,
    SOAPNoteContent?: object,
    fetchSoapNoteContentInProgress?: boolean,
    fetchSoapNoteContentIsSuccess?: boolean,
    fetchSoapNoteContentError?: string,

    editSoapNoteContentInProgress?: boolean,
    editSoapNoteContentError?: string,
    editSoapNoteContentSuccess?: boolean,

    editedSoapNote?: ISOAPNote,
    editSoapNoteReasonInProgress?: boolean,
    editSoapNoteReasonError?: string,
    editSoapNoteReasonSuccess?: boolean

    locationIdToInitiateCall?: string
}

const initialState: AdminOperationsParams = {
    locations: [],
    locationsCount: 0,
    fetchLocationsInProgress: false,
    sideBarTab: '',
    location: undefined,
    fetchLocationInProgress: false,
    fetchLocationIsSuccess: false,
    phoneVerificationInProgress: false,
    phoneVerificationCode: undefined,
    initiatingPhoneVerificationError: undefined,
    saveLocationError: undefined,
    users: [],
    usersCount: 0,
    fetchUsersInProgress: false,
    fetchUsersIsSuccess: false,
    fetchUsersError: '',
    saveUserError: '',
    savedUser: undefined,
    savingUserInProgress: undefined,
    SOAPNotepatients: [],
    SOAPNoteCount: 0,
    fetchSoapNotePatientsInProgress: false,
    fetchSoapNotePatientsIsSuccess: false,
    fetchSoapNotePatientsError: '',
    soapNoteList: [],
    fetchSoapNotesInProgress: false,
    fetchSoapNotesIsSuccess: false,
    fetchSoapNotesError: '',
    markSoapNoteAsReadInProgress: false,
    markSoapNoteAsReadSuccess: false,
    markSoapNoteAsReadError: '',
    SOAPNoteContent: undefined,
    fetchSoapNoteContentInProgress: false,
    fetchSoapNoteContentIsSuccess: false,
    fetchSoapNoteContentError: '',

    editSoapNoteContentError: '',
    editSoapNoteContentInProgress: false,
    editSoapNoteContentSuccess: false,

    editedSoapNote: undefined,
    editSoapNoteReasonError: '',
    editSoapNoteReasonInProgress: false,
    editSoapNoteReasonSuccess: false,

    locationIdToInitiateCall: undefined
}

const AdminOperationsReducer = (state: AdminOperationsParams = initialState, action: AnyAction): AdminOperationsParams => {
    switch (action.type) {
        case FETCH_LOCATIONS:
            state = {
                ...state, locations: action.payload.locations, fetchLocationsInProgress: undefined, sideBarTab: ''
            }
            return state
        case FETCH_LOCATIONS_FAILED:
            state = { ...state, fetchLocationsInProgress: false }
            return state
        case FETCH_LOCATIONS_IN_PROGRESS:
            state = { ...state, fetchLocationsInProgress: true }
            return state
        case FETCH_LOCATIONS_SUCCESS:
            state = {
                ...state,
                locations: action.payload.locations,
                locationsCount: action.payload.locationsCount,
                fetchLocationsInProgress: false,
                sideBarTab: action.payload.sideBarTab,
                location: undefined,
                fetchLocationIsSuccess: false
            }
            return state

        case FETCH_LOCATION:
            state = { ...state, fetchLocationInProgress: undefined }
            return state
        case FETCH_LOCATION_FAILED:
            state = { ...state, fetchLocationInProgress: false }
            return state
        case FETCH_LOCATION_IN_PROGRESS:
            state = { ...state, fetchLocationInProgress: true }
            return state
        case FETCH_LOCATION_SUCCESS:
            state = { ...state, fetchLocationInProgress: false, location: action.payload.location }
            return state

        case INITIATE_LOCATION_PHONE_VERIFICATION:
            state = { ...state, phoneVerificationInProgress: undefined }
            return state
        case INITIATE_LOCATION_PHONE_VERIFICATION_FAILED:
            state = {
                ...state,
                phoneVerificationInProgress: false,
                initiatingPhoneVerificationError: action.payload.error
            }
            return state
        case INITIATE_LOCATION_PHONE_VERIFICATION_IN_PROGRESS:
            state = { ...state, phoneVerificationInProgress: true }
            return state
        case INITIATE_LOCATION_PHONE_VERIFICATION_SUCCESS:
            state = {
                ...state,
                phoneVerificationInProgress: false,
                phoneVerificationCode: action.payload.code,
                initiatingPhoneVerificationError: undefined
            }
            return state

        case SAVE_LOCATION:
            state = { ...state, fetchLocationsInProgress: undefined }
            return state
        case SAVE_LOCATION_FAILED:
            state = { ...state, fetchLocationsInProgress: false, saveLocationError: action.payload.error }
            return state
        case SAVE_LOCATION_IN_PROGRESS:
            state = { ...state, fetchLocationsInProgress: true }
            return state
        case SAVE_LOCATION_SUCCESS:
            state = {
                ...state, fetchLocationsInProgress: false, location: action.payload.location,
                fetchLocationIsSuccess: true, saveLocationError: undefined
            }
            return state
        case CLEAR_LOCATION:
            state = { ...state, location: undefined, fetchLocationIsSuccess: false }
            return state

        // User actions
        case FETCH_USERS:
            state = { ...state, users: [], usersCount: 0, fetchUsersInProgress: undefined, fetchUsersError: '' }
            return state
        case FETCH_USERS_FAILED:
            state = { ...state, fetchUsersInProgress: false, fetchUsersError: action.payload.error }
            return state
        case FETCH_USERS_IN_PROGRESS:
            state = { ...state, fetchUsersInProgress: true }
            return state
        case FETCH_USERS_SUCCESS:
            state = {
                ...state, users: action.payload.users, usersCount: action.payload.usersCount,
                fetchUsersInProgress: false, fetchUsersIsSuccess: true, fetchUsersError: ''
            }
            return state
        case SAVE_USER:
            state = { ...state, savingUserInProgress: true }
            return state
        case SAVE_USER_FAILED:
            state = { ...state, savingUserInProgress: undefined, saveUserError: action.payload.error }
            return state
        case SAVE_USER_SUCCESS:
            state = {
                ...state,
                savedUser: action.payload.user,
                savingUserInProgress: false,
                saveUserError: ''
            }
            return state;

        case FETCH_SOAP_NOTE_PATIENTS:
            state = {
                ...state,
                // SOAPNoteContent: undefined,
                SOAPNotepatients: [],
                SOAPNoteCount: 0,
                fetchSoapNotePatientsInProgress: undefined,
                fetchSoapNotePatientsIsSuccess: false,
                fetchSoapNotePatientsError: ''
            }
            return state;

        case FETCH_SOAP_NOTE_PATIENTS_FAILED:
            state = {
                ...state,
                fetchSoapNotePatientsInProgress: false,
                fetchSoapNotePatientsError: action.payload.error
            }
            return state;

        case FETCH_SOAP_NOTE_PATIENTS_IN_PROGRESS:
            state = { ...state, fetchSoapNotePatientsInProgress: true }
            return state;

        case FETCH_SOAP_NOTE_PATIENTS_SUCCESS:
            state = {
                ...state,
                SOAPNotepatients: action.payload.SOAPNotepatients,
                SOAPNoteCount: action.payload.count,
                fetchSoapNotePatientsInProgress: false,
                fetchSoapNotePatientsIsSuccess: true,
                fetchSoapNotePatientsError: ''
            }
            return state;

        case FETCH_SOAP_NOTES:
            state = {
                ...state,
                soapNoteList: [],
                fetchSoapNotesInProgress: undefined,
                fetchSoapNotesIsSuccess: false,
                fetchSoapNotesError: ''
            }
            return state;

        case FETCH_SOAP_NOTES_FAILED:
            state = {
                ...state,
                fetchSoapNotesInProgress: false,
                fetchSoapNotesError: action.payload.error
            }
            return state;

        case FETCH_SOAP_NOTES_IN_PROGRESS:
            state = { ...state, fetchSoapNotesInProgress: true }
            return state;

        case FETCH_SOAP_NOTES_SUCCESS:
            console.log(action.payload.soapNoteList)
            state = {
                ...state,
                soapNoteList: action.payload.soapNoteList,
                fetchSoapNotesInProgress: false,
                fetchSoapNotesIsSuccess: true,
                fetchSoapNotesError: ''
            }
            return state;

        case MARK_SOAP_NOTE_AS_READ:
            state = {
                ...state,
                markSoapNoteAsReadInProgress: undefined,
                markSoapNoteAsReadSuccess: false,
                markSoapNoteAsReadError: ''
            }
            return state;

        case MARK_SOAP_NOTE_AS_READ_IN_PROGRESS:
            state = { ...state, markSoapNoteAsReadInProgress: true }
            return state;

        case MARK_SOAP_NOTE_AS_READ_SUCCESS:
            state = { ...state, markSoapNoteAsReadInProgress: false, markSoapNoteAsReadSuccess: true }
            return state;

        case MARK_SOAP_NOTE_AS_READ_FAILED:
            state = {
                ...state,
                markSoapNoteAsReadInProgress: false,
                markSoapNoteAsReadError: action.payload.error
            }
            return state;

        case FETCH_SOAP_NOTE_CONTENT:
            state = {
                ...state,
                SOAPNoteContent: undefined,
                editedSoapNote: undefined,
                fetchSoapNoteContentInProgress: undefined,
                fetchSoapNoteContentIsSuccess: false,
                fetchSoapNoteContentError: ''
            }
            return state;

        case FETCH_SOAP_NOTE_CONTENT_IN_PROGRESS:
            state = { ...state, fetchSoapNoteContentInProgress: true }
            return state;


        case FETCH_SOAP_NOTE_CONTENT_SUCCESS:
            console.log(action.payload.SOAPNoteContent)
            state = {
                ...state,
                SOAPNoteContent: action.payload.SOAPNoteContent,
                editedSoapNote: undefined,
                fetchSoapNoteContentInProgress: false,
                fetchSoapNoteContentIsSuccess: true,
                fetchSoapNoteContentError: ''
            }
            return state;

        case FETCH_SOAP_NOTE_CONTENT_FAILED:
            state = {
                ...state,
                fetchSoapNoteContentInProgress: false,
                fetchSoapNoteContentError: action.payload.error
            }
            return state;

        case EDIT_SOAP_NOTE_CONTENT:
            state = {
                ...state,
                editSoapNoteContentInProgress: undefined,
                editSoapNoteContentSuccess: false,
                editSoapNoteContentError: ''
            }
            return state;

        case EDIT_SOAP_NOTE_CONTENT_IN_PROGRESS:
            state = { ...state, editSoapNoteContentInProgress: true }
            return state;

        case EDIT_SOAP_NOTE_CONTENT_SUCCESS:
            state = {
                ...state,
                editSoapNoteContentInProgress: false,
                editSoapNoteContentSuccess: true
            }
            return state;


        case EDIT_SOAP_NOTE_CONTENT_FAILED:
            state = {
                ...state,
                editSoapNoteContentInProgress: false,
                editSoapNoteContentError: action.payload.error
            }
            return state;

        case EDIT_SOAP_NOTE_REASON:
            state = {
                ...state,
                editedSoapNote: undefined,
                editSoapNoteReasonInProgress: undefined,
                editSoapNoteReasonSuccess: false,
                editSoapNoteReasonError: ''
            }
            return state;
            
        case EDIT_SOAP_NOTE_REASON_IN_PROGRESS:
            state = { ...state, editSoapNoteReasonInProgress: true }
            return state;
            
        case EDIT_SOAP_NOTE_REASON_SUCCESS:
            state = {
                ...state,
                editedSoapNote: action.payload.soapNote,
                editSoapNoteReasonInProgress: false,
                editSoapNoteReasonSuccess: true
            }
            return state;

        case SET_LOCATION_ID_TO_INTITATE_CALL:
            state = {
                ...state,
                locationIdToInitiateCall: action.payload.locationId
            }
            return state;
        case CLEAR_LOCATION_ID_TO_INTITATE_CALL:
            state = {
                ...state,
                locationIdToInitiateCall: undefined
            }
            return state;
            
        case RESET_SAVE_USER:
            state = {
                ...state,
                savedUser: undefined,
                saveUserError: '',
                savingUserInProgress: undefined
            }
            return state;

        case SIGN_OUT_ACCOUNT:
            state = { ...state, ...initialState }
            return state


        default:
            return state;
    }
}

export default AdminOperationsReducer
