import {
    ACCOUNT_SIGN_IN_FAILED,
    ACCOUNT_SIGN_IN_IN_PROGRESS,
    ACCOUNT_SIGN_IN_SUCCESS
} from "../../actions/physician/account.action";

const ERROR_MAP : {[key: string]: string} = {
    "No data available": "No account exists with the given credentials, please check your email and password.",
    "Invalid Password": "Please check your credentials again.",
    "Invalid Phone Number": "The phone number you provided is not valid or cannot receive the OTP. Please check your phone number and try again.",
    "Unauthorized Token is invalid": "The phone number is not valid or cannot receive messages at this moment. Please check the phone number and try again.",
    "Verification code entered is not valid": "The verification code entered is not valid. Please check the code and try again.",
    "Too Many Requests API usage limit": "You have reached the maximum number of verification attempts. Please try again later.",
    "Your account has been disabled": "Your account has been disabled"
}

export interface ErrorParams {
    error?: string
}

export interface SigninParams extends ErrorParams {
    email?: string
    phone?: string
}

const initialState: SigninParams = {
    email: undefined,
    phone: undefined,
    error: undefined
}

const SigninReducer= (state: SigninParams = initialState, action: any): SigninParams => {
    switch (action.type) {
        case ACCOUNT_SIGN_IN_IN_PROGRESS:
            state = {...state, error: undefined}
            return state
        case ACCOUNT_SIGN_IN_SUCCESS:
            state = {...state, error: undefined}
            return state
        case ACCOUNT_SIGN_IN_FAILED:
            state = {
                ...state,
                error: ERROR_MAP[action.payload.error]
            }
            return state;
        default:
            return state;
    }

}

export default SigninReducer
