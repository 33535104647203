import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import {cancelAppointment} from "../../store/actions/counsellor/appointments.action";
import {IEditAppointment, PatientSessionStatus} from "../../models";
import {useNavigate} from "react-router";

const EditPatientIntake: React.FC = () => {
  const dispatch = useDispatch()
  const router = useNavigate()
  const params = useParams()
  const canceledAppointment = useSelector((state : StateParams) => state.appointments.canceledAppointment)
  const [patientStatus, setStatus] = useState<string>()

  const cancelIntakeEvent = () => {
    if(!params.patientId || !params.appointmentId || !patientStatus) {
      return
    }
    const appointmentRequest : IEditAppointment = {
      patientId: params.patientId,
      sessionId: params.appointmentId,
      status: patientStatus
    }
    dispatch(cancelAppointment(appointmentRequest))
  };

  useEffect(() => {
   if(canceledAppointment) {
       if(canceledAppointment.id === params.appointmentId) {
           router(-1)
           return
       }
   }
  }, [canceledAppointment, params.appointmentId])

  function renderTopButtonPanel() {
    return <div>
      <Link to={'...'} onClick={(e) => { e.preventDefault(); router(-1); }}>
        <span className="rounded-l-md border-sjOrange text-sjLink">
            Back to CoCM Patients
        </span>
      </Link>
    </div>;
  }

  function renderCancelIntakeButton() {
    return (
      <div className={"mt-10"}>
        <input type={"button"}
               value={"Cancel"}
               className="inline-block bg-orange-500 text-white rounded shadow py-2 px-5 text-sm"
               onClick={cancelIntakeEvent}
        />
      </div>
    )
  }

  function renderReasoning() {
    return (
      <div className="mb-8">
        <label
          htmlFor="reason"
          className={`block text-md mb-2 text-sjOrange`}
        >
          Select a reason for cancellation of the session
        </label>
        <div className='flex items-center bg-white m-2 h-12 rounded-md p-2'>
          <input id='noshow' type="radio" name='answer' value='Sick Visit' onChange={() => setStatus(PatientSessionStatus.canceled)} className='h-4 w-4 mr-2 text-orange focus:ring-0' />
          <label htmlFor={'reason'} className='text-sm w-full'>Patient did not join</label>
        </div>
        <div className='flex items-center bg-white m-2 h-12 rounded-md p-2'>
          <input id='reschedule' type="radio" name='answer' value='Well Check/Annual Visit' onChange={() => setStatus(PatientSessionStatus.canceled)} className='h-4 w-4 mr-2 text-orange focus:ring-0' />
          <label htmlFor={'reason'} className='text-sm w-full'>Need to reschedule</label>
        </div>
      </div>
    )
  }

  return (<div className="py-10 px-10 bg-white-700 min-h-screen">
    {renderTopButtonPanel()}
    {renderReasoning()}
    {renderCancelIntakeButton()}
  </div>)
}

export default EditPatientIntake
