import React, { useCallback, useEffect, useState } from "react";
import TableFilterPaginationV2 from "../../../components/generic/TableFilterPaginationV2";
import Select from "react-select";
import { Practice } from "../../../models";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import { HiCalendarDays } from "react-icons/hi2";
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-modal';
import { Button } from "../../../components/generic/Buttons";
import SearchFilter from "../../../components/generic/SearchFilter";
import {PreferredLanguageFilterOptions} from "../../../services/common.service";
interface Filter {
    isOpen: boolean
    setOpenFilterModal: (openFilterModal: boolean) => void
    practices?: Practice[]
    practiceIds: string[]
    setPracticeIds: (practiceId: string[]) => void
    searchText: string
    setSearchText: (text: string) => void
    recordsCount?: number
    setCurrentPage: (page: number) => void
    onFilterChange: (startDate: string | undefined, endDate: string | undefined) => void;
    startDate?: string
    endDate?: string
    isPendingNotes: boolean
    onFiltersApply: () => void
    preferredLanguage?: string
    setPreferredLanguage?: (preferredLanguage: string) => void
    isSWList?: boolean
}

//Creating SWComplianceListFilterV2 to show filters in advanced filters section.
//SWComplianceListFilter shows all filters in the main UI.
const SWComplianceListFilterV2 = (props: Filter) => {
    const { isOpen, setOpenFilterModal, practices, practiceIds, setPracticeIds, searchText, setSearchText, recordsCount, setCurrentPage, onFilterChange, startDate, endDate, isPendingNotes, onFiltersApply, preferredLanguage, setPreferredLanguage, isSWList } = props
    const [showCustomDateFilter, setShowCustomDateFilter] = useState<boolean>(false);
    const [filterStartDate, setFilterStartDate] = useState<Date | undefined>(startDate ? new Date(startDate) : undefined);
    const [filterEndDate, setFilterEndDate] = useState<Date | undefined>(endDate ? new Date(endDate) : undefined);
    const startDateString = filterStartDate ? DateTime.fromJSDate(filterStartDate).startOf('day').toFormat('MM-dd-yyyy') : undefined
    const endDateString = filterEndDate ? DateTime.fromJSDate(filterEndDate).startOf('day').toFormat('MM-dd-yyyy') : undefined
    const [selectedPractices, setSelectedPractices] = useState<Practice[]>([]);

    useEffect(() => {
        if (!isPendingNotes) {
            setFilterStartDate(undefined)
            setFilterEndDate(undefined)
        }
    }, [isPendingNotes])

    useEffect(() => {
        const selectedOptions = practices?.filter(practice => practiceIds.includes(practice.id)) || [];
        setSelectedPractices(selectedOptions);
    }, [practices, practiceIds]);

    const handleChange = useCallback((dates: [Date, Date]) => {
        const [start, end] = dates;
        setFilterStartDate(start);
        setFilterEndDate(end);
    }, []);

    const handleInputClick = () => {
        setShowCustomDateFilter(!showCustomDateFilter);
    };

    const handleDateFilterAction = (action: 'clear' | 'apply' | 'cancel') => {
        if (action === 'clear') {
            setFilterStartDate(undefined);
            setFilterEndDate(undefined);
            onFilterChange(undefined, undefined);
        } else if (filterStartDate && filterEndDate && action === 'apply') {
            onFilterChange(
                DateTime.fromJSDate(filterStartDate).toFormat('yyyy-MM-dd'),
                DateTime.fromJSDate(filterEndDate).toFormat('yyyy-MM-dd')
            );
        }
        setShowCustomDateFilter(false);
    };

    const handleCancelFilter = () => {
        setOpenFilterModal(false)
    }

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isOpen}
            onRequestClose={() => setOpenFilterModal(false)}
            className="fixed top-0 right-0 mx-auto p-5 border w-[480px] shadow-lg bg-white min-h-screen"
        >
            <span className="flex justify-center font-bold text-lg">Filters</span>
            <div className="mt-2 px-4 py-3">
                {isPendingNotes &&
                    <div className="physician-dashboard-date-filter">
                        <span className="content-start">Notes Date</span>
                        <div className="relative">
                            <input
                                value={filterStartDate && filterEndDate ? `${startDateString} - ${endDateString}` : ''}
                                placeholder="Notes Date"
                                className={"p-2 w-full border-[1.3px] rounded-md h-10 border-gray-300 focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"}
                                readOnly
                                onClick={handleInputClick}
                            />
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                                <HiCalendarDays className="w-5 h-5 text-sjLightGrey" />
                            </span>
                        </div>
                        {showCustomDateFilter &&
                            <div className="absolute mt-2 ml-4 text-center">
                                <DatePicker
                                    onChange={handleChange}
                                    startDate={filterStartDate}
                                    endDate={filterEndDate}
                                    maxDate={new Date()}
                                    selectsRange
                                    inline
                                >
                                    <div className="custom-date-filter-actions mb-2">
                                        <button
                                            onClick={() => handleDateFilterAction('cancel')}
                                            className="rounded-l-md text-sjDarkGray py-2 px-2"
                                        >Cancel
                                        </button>
                                        <button
                                            onClick={() => handleDateFilterAction('clear')}
                                            className="rounded-l-md border-sjOrange text-sjOrange py-2 px-4"
                                        >Clear
                                        </button>&nbsp;
                                        <button
                                            onClick={() => handleDateFilterAction('apply')}
                                            disabled={!filterStartDate || !filterEndDate}
                                            className="bg-sjOrange text-white py-2 px-4 rounded"
                                        >Apply
                                        </button>
                                    </div>
                                </DatePicker>
                            </div>
                        }
                    </div>
                }
                {!isPendingNotes && !isSWList &&
                <div className="mt-4">
                    <span>Preferred Language</span>
                    <Select
                        className={"w-full"}
                        options={PreferredLanguageFilterOptions}
                        placeholder={"Language"}
                        isClearable={true}
                        isSearchable={false}
                        onChange={(selectedOption: any) => {
                            setCurrentPage(1);
                            setPreferredLanguage?.(selectedOption?.value);
                        }}
                        value={PreferredLanguageFilterOptions.find(option => option.value === preferredLanguage) || null}
                    />
                    </div>
                }
                <div className="mt-4">
                    <span>Practice</span>
                    <Select
                        className="w-full"
                        options={practices}
                        onChange={(selectedOptions) => {
                            const mutableOptions = selectedOptions ? selectedOptions as Practice[] : [];
                            const selectedIds = mutableOptions.map(option => option.id);
                            setPracticeIds(selectedIds);
                            setSelectedPractices(mutableOptions);
                        }}
                        placeholder={"Practice"}
                        isClearable
                        isSearchable={false}
                        isMulti
                        getOptionLabel={option => `${option?.name}`}
                        getOptionValue={option => option?.id}
                        value={selectedPractices}
                    />
                </div>
                {/* <div className="mt-4">
                    <span>Name Search</span>
                    <SearchFilter
                        searchClassNames='w-full'
                        searchText={searchText}
                        setSearchFilter={setSearchText}
                        currentPageHandler={setCurrentPage}
                    />
                </div> */}
            </div>
            <div className="mt-8 flex justify-center px-4 py-3">
                <Button className='!bg-sjOrange !text-sjWhite mr-4' onClick={onFiltersApply}>
                    Apply Filter
                </Button>
                <Button className='!text-sjOrange !border-sjOrange !border-2' onClick={handleCancelFilter}>
                    Discard
                </Button>
            </div>
        </Modal>
    )
}

export default SWComplianceListFilterV2