import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import {RoutePaths} from "../../../shared/Utils";
import _ from "lodash";

const PhysicianSideBarFooter : React.FC = () => {
    const { firstName, lastName, email } = useSelector((state: StateParams) => state.account)
    const { defaultLocation } = useSelector((state: StateParams) => state.physicianAccount)

    const [defaultAddress, setDefaultAddress] = useState<string[]>([])

    useEffect(() => {
        if(defaultLocation) {
            const addressParts = [defaultLocation.name, defaultLocation.addressLine, defaultLocation.city, defaultLocation.zipCode]
            const validAddressParts = _.filter(addressParts, part => !_.isUndefined(part) && !_.isNull(part))
            setDefaultAddress(validAddressParts)
        }
    }, [defaultLocation])

    const renderDefaultLocation = () => {
        return <div>
            {defaultAddress.map((part, index) => {
                return <p key={index} className="mt-1 text-left text-xs text-[#5D7285] ellipsis-text">{part}</p>

            })}
        </div>
    }

    const renderFooter = () => {
        return <div className={"flex justify-center items-center bg-[#FFFAF1]"}>
                <div className="p-2 pb-4 flex flex-col text-left">
                    <p className="mt-1 text-left text-sm text-[#1D1B20] ellipsis-text">{`${firstName} ${lastName}`}</p>
                    <p className="mt-1 text-left text-xs text-[#5D7285] ellipsis-text">{`${email}`}</p>
                    {
                        defaultLocation
                            ? renderDefaultLocation()
                            : null
                    }
                    <Link to={RoutePaths.physicianCare.providerProfile} className='text-sjLink underline mt-1 text-left text-xs'>
                        View Profile
                    </Link>
                </div>
            </div>
    }

    return renderFooter()
}

export default PhysicianSideBarFooter
