import React, {useCallback, useEffect, useState} from 'react'
import sjCircle from "../../assets/images/loginBackground/sj-circle.png";
import {batch, useDispatch, useSelector} from "react-redux";
import {clearCodeSent, clearErrorMessages, sendEmail, sendOTP, sendOTPInProgress, verifyOTP} from "../../store/actions/physician/account.action";
import {StateParams} from "../../store/reducers";
import {useNavigate} from "react-router";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {MixpanelContext} from "../../analytics/Mixpanel";
import {FORGOT_PASSWORD_REQUEST} from "../../analytics/Events";
import { useLocation } from 'react-router-dom';
import { RoutePaths } from '../../shared/Utils';
import OTPInput from './OTPInput';
import _ from 'lodash';
import { homeContentReady } from '../../store/actions/counsellor/navigation.actions';
import { Timer } from '../../components/generic/Timer';

const OTPScreen: React.FC<{}> = () => {
    const dispatch = useDispatch()
    const analytics = React.useContext(MixpanelContext);
    const email = useLocation().state?.email
    const navigate = useNavigate()

    const [formErrors, setFormErrors] = useState<string[]>([])
    const [otp, setOTP] = useState<string[]>(['', '', '', '','','']);
    const {phone, verifyOtpError:error, name, jwt, isOTPSent} = useSelector((state: StateParams) => state.physicianAccount)
    const [toggleTimer, setToggleTimer] = useState<boolean>(false)

    useEffect(() => {
        if(!email) {
            navigate(RoutePaths.app.root)
        }
        batch(() => {
            dispatch(clearErrorMessages())
            dispatch(sendOTPInProgress())
        })
    }, [])

    useEffect(() => {
        if(error) {
            setFormErrors((errors) => {
                return errors ? [...errors, error] : [error]
            })
        }
    }, [error])

    useEffect(() => {
        if(isOTPSent) {
            setToggleTimer(true)
            toast.success(`One-Time Password (OTP) has been sent to your registered phone number ${phone}`, {
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
            })
        }
    }, [isOTPSent])

    useEffect(() => {
        if(jwt) {
            dispatch(homeContentReady())
        }
    }, [jwt])

    const validateForOTP = useCallback(_.debounce((dispatchOTPVerification: Function) => {
        const code = otp.join('')
        if(code.length < 6) {
            setFormErrors(["OTP is required"])
            return
        }
        dispatch(dispatchOTPVerification({otp: code, email, phoneNumber:phone}))
    }, 500), [otp])

    const validateOTP = useCallback(() => {
        setFormErrors([])
        dispatch(clearErrorMessages())
        validateForOTP(verifyOTP)
    }, [otp])

    const resendOTP = _.debounce(() => {
        if(!toggleTimer) dispatch(sendOTP({email}))
    }, 500)

    const renderTimerAndResendOTP = () => {
        return(
            <div className="grid grid-cols-12 gap-x-6 py-4">
                {
                    !toggleTimer ? <>
                        <p className='col-span-8 text-sm tracking-wide'>Didn’t receive the OTP?</p>
                        
                    </>
                    : <p className="col-span-8 text-sm tracking-wide">
                        <Timer setContainerVisible={setToggleTimer} />
                    </p>
                }
                <p onClick={resendOTP}
                    className={`${toggleTimer ? 'opacity-50	cursor-not-allowed' : 'hover:text-blue-800 hover:cursor-pointer visited:text-purple-600'} col-span-4 font-bold text-hyperLink text-[#508DE8]`}>Resend OTP
                </p>
            </div>
        )
    }

    return (
        <main className="xs:pt-28 md:pt-2 0 px-3 xs:h-auto md:h-screen">
            <ToastContainer />
            <div className="container mx-auto flex flex-col items-center">
                <div className='w-full h-32 flex items-center justify-center'>
                    <img src={sjCircle} className='h-[200px]' />
                </div>
                <p className="flex-col text-lg font-medium text-sjAnswer">Hello {name}</p>
                <p className="text-sm text-[#667B8B] py-5">For verification, we’ve sent 6-digit code at {phone}. Please enter the code below to proceed.</p>
                <p className='flex-col text-base font-medium text-sjAnswer py-5'>Enter OTP</p>
                <OTPInput otp={otp} setOTP={setOTP}/>
                {renderTimerAndResendOTP()}
                <div className="mt-10 grid grid-cols-3 gap-6">
                    <div></div>
                    <div className="flex flex-col justify-center items-center">
                        {formErrors?.map((error, index) => {
                            return <p className="error-msg text-center" key={"error-" + index}>{error}</p>
                        })}
                    </div>
                    <div></div>
                </div>
                <button
                    className="bg-sj2 hover:bg-sjOrange text-white font-bold py-2 w-2/6 my-4 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={validateOTP}>
                    Continue
                </button>
            </div>
        </main>
    )
}

export default OTPScreen
