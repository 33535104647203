import {AnyAction} from "redux";
import { ComplianceSWListRequest, ISWListWithPendingNotesOrScreeners } from "../../../models";

export const FETCH_COMPLIANCE_SW_LIST = "FETCH_COMPLIANCE_SW_LIST";
export const FETCH_COMPLIANCE_SW_LIST_IN_PROGRESS = "FETCH_COMPLIANCE_SW_LIST_IN_PROGRESS";
export const FETCH_COMPLIANCE_SW_LIST_SUCCESS = "FETCH_COMPLIANCE_SW_LIST_SUCCESS";
export const FETCH_COMPLIANCE_SW_LIST_FAILED = "FETCH_COMPLIANCE_SW_LIST_FAILED";

export const fetchComplianceSWList = (request: ComplianceSWListRequest): AnyAction => {
    return {type: FETCH_COMPLIANCE_SW_LIST, payload: {request}}
}
export const fetchComplianceSWListInProgress = (): AnyAction => {
    return {type: FETCH_COMPLIANCE_SW_LIST_IN_PROGRESS}
}
export const fetchComplianceSWListFailed = (payload: { error: string }): AnyAction => {
    return {type: FETCH_COMPLIANCE_SW_LIST_FAILED, payload}
}
export const fetchedComplianceSWList = (swList: ISWListWithPendingNotesOrScreeners[], swListCount: number, tabCount: Record<string, number>): AnyAction => {
    return {type: FETCH_COMPLIANCE_SW_LIST_SUCCESS, payload: {swList, swListCount, tabCount}}
}
