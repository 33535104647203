import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import {Provider} from 'react-redux'
import {persistor, store} from './store'
import {PersistGate} from 'redux-persist/integration/react';
import ZoomVideo from '@zoom/videosdk';
import ZoomContext from './context/ZoomContext';

const zmClient = ZoomVideo.createClient();

if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MSW) {
  const { worker } = require("./__mocks__/server/browser");
  worker.start()
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ZoomContext.Provider value={zmClient}>
          <App />
        </ZoomContext.Provider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
