import React, { PropsWithChildren, useState } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/outline";


interface AccordianCardProps {
    title: string;
    canToggle?: boolean;
}

const AccordianCard = (props: PropsWithChildren<AccordianCardProps>) => {

    const { title, children} = props;
    let canToggle = props.canToggle ?? true;
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const iconStyles = "h-6 w-6 m-1 ml-4 self-center";

    return (
        <div className="bg-white rounded-md p-4 mb-6">
            <div className="flex justify-between items-center">
                <h2 className="text-">{title}</h2>
                {canToggle &&
                    <button onClick={() => setIsOpen(!isOpen)}>
                        {isOpen ? <ChevronUpIcon className={iconStyles} /> : <ChevronDownIcon className={iconStyles} />}
                    </button>
                }
            </div>
            {isOpen && <div className="pt-2">
                <div>{children}</div>
            </div>}
        </div>
    );

}

export default AccordianCard;