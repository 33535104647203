import React, {useCallback, useContext, useEffect, useMemo, useReducer, useState} from 'react'
import {useLocation, useNavigate} from "react-router-dom";
import ZoomContext from '../../context/ZoomContext';
import ZoomMediaContext from '../../context/MediaContext';
import {
    ChatClient,
    CommandChannelClient,
    MediaStream,
    RecordingClient,
    SubsessionClient
} from '../../types/ZoomIndexTypes';
import ZoomVideo, {ConnectionState, ReconnectReason} from '@zoom/videosdk';
import VideoNonSAB from './VideoNonSAB'
import produce from 'immer';
import {useDispatch} from 'react-redux';
import {activateZoomSession} from '../../store/actions/counsellor/account.action';
import {updateSessionCallLog} from "../../store/actions/counsellor/appointments.action";
import {DateTime} from "luxon";
import {v4 as uuid} from 'uuid'
import ProspectIntakeComponent from '../../components/prospects/ProspectIntakeComponent';
import {IProspect, SessionTypes} from '../../models';
import PatientTreatmentPlanComponent from '../../components/clinical-notes/treatment-plan/PatientTreatmentPlanComponent';
import PatientProgressNotesComponent
    from '../../components/clinical-notes/progress-notes/PatientProgressNotesComponent';
import PatientSafetyPlanNotesComponent
    from '../../components/clinical-notes/safety-plan-notes/PatientSafetyPlanNotesComponent';
import { ModalProvider } from '../../context/ModalContext';
import PatientCatchupNotesComponent from '../../components/clinical-notes/catchup-notes/PatientCatchupNotesComponent';

interface LocationState {
  sessionId: string
  sessionName: string
  sessionPassword: string
  displayName: string
  sessionIdleTimeoutMins: number
  roleType: number
  token: string
  prospect: IProspect,
  sessionType: string
}

declare global {
  interface Window {
    webEndpoint: string | undefined;
    zmClient: any | undefined;
    mediaStream: any | undefined;
    crossOriginIsolated: boolean;
  }
}

const mediaShape = {
  audio: {
    encode: false,
    decode: false
  },
  video: {
    encode: false,
    decode: false
  },
  share: {
    encode: false,
    decode: false
  }
};
const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case 'audio-encode': {
      draft.audio.encode = action.payload;
      break;
    }
    case 'audio-decode': {
      draft.audio.decode = action.payload;
      break;
    }
    case 'video-encode': {
      draft.video.encode = action.payload;
      break;
    }
    case 'video-decode': {
      draft.video.decode = action.payload;
      break;
    }
    case 'share-encode': {
      draft.share.encode = action.payload;
      break;
    }
    case 'share-decode': {
      draft.share.decode = action.payload;
      break;
    }
    case 'reset-media': {
      Object.assign(draft, { ...mediaShape });
      break;
    }
    default:
      break;
  }
}, mediaShape);

const CallScreen: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const zmClient = useContext(ZoomContext)


  const [locationState, setLocationState] = useState(location.state as LocationState)
  const [sessionName, setSessionName] = useState(locationState.sessionName)
  const [sessionId, setSessionId] = useState(locationState.sessionId)
  const [sessionPassword, setSessionPassword] = useState(locationState.sessionPassword)
  const [displayName, setDisplayName] = useState(locationState.displayName)
  const [token, setToken] = useState(locationState.token)
  const [prospect, setProspect] = useState(locationState.prospect)
  const [sessionIdleTimeoutMins, setSessionIdleTimeoutMins] = useState('40')
  const [roleType, setRoleType] = useState(locationState.roleType)
  const [isFailover, setIsFailover] = useState<boolean>(false)
  const [status, setStatus] = useState<string>('closed')
  const [mediaState, setMediaState] = useReducer(mediaReducer, mediaShape);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [chatClient, setChatClient] = useState<ChatClient | null>(null)
  const [recordingClient, setRecordingClient] = useState<RecordingClient | null>(null)
  const [commandClient, setCommandClient] = useState<CommandChannelClient | null>(null)
  const [subsessionClient, setSubsessionClient] = useState<SubsessionClient | null>(null)
  const [isSupportGalleryView, setIsSupportGalleryView] = useState<boolean>(true)
  const [connectedAtTime, setConnectedAtTime] = useState<string|null>()
  const [closedAtTime, setClosedAtTime] = useState<string|null>()
  const [callInstanceId, setCallInstanceId] = useState<string>()
  const [sessionType, setSessionType] = useState<string>(locationState.sessionType)

  const mediaContext = useMemo(() => ({ ...mediaState, mediaStream }), [mediaState, mediaStream]);

  useEffect(() => {
    const init = async () => {
      await zmClient.init('en-US', 'Global', { enforceMultipleVideos: true })
      try {
        if (token) {
          console.log('Joining the session...')
          await zmClient.join(sessionName, token, displayName, sessionPassword)
          const stream = zmClient.getMediaStream()
          setMediaStream(stream)
          setIsSupportGalleryView(stream.isSupportMultipleVideos())
          const chatClient = zmClient.getChatClient()
          const commandClient = zmClient.getCommandClient()
          const recordingClient = zmClient.getRecordingClient()
          // const ssClient = zmClient.getSubsessionClient()
          setChatClient(chatClient)
          setCommandClient(commandClient)
          setRecordingClient(recordingClient)
          setCallInstanceId(uuid())
        } else {
          console.log('No token present')
        }
      } catch (e) {
        console.error(e)
      }
    }
    init()
    return () => {
      ZoomVideo.destroyClient()
    }
  }, [])

  const onConnectionChange = useCallback(
    (payload: any) => {
      if (payload.state === ConnectionState.Reconnecting) {
        setIsFailover(true);
        setStatus('connecting');
        const { reason, subsessionName } = payload;
        if (reason === ReconnectReason.Failover) {
          console.log('Session Disconnected,Try to reconnect');
        } else if (reason === ReconnectReason.JoinSubsession || reason === ReconnectReason.MoveToSubsession) {
          console.log(`Joining ${subsessionName}...`);
        } else if (reason === ReconnectReason.BackToMainSession) {
          console.log('Returning to Main Session...');
        }
      } else if (payload.state === ConnectionState.Connected) {
        setStatus('connected');
        setConnectedAtTime(DateTime.fromJSDate(new Date()).toUTC().toISO())
        console.log("Zoom Connected")
        if (isFailover) { }
        window.zmClient = zmClient;
        window.mediaStream = zmClient.getMediaStream();
      } else if (payload.state === ConnectionState.Closed) {
        setStatus('closed');
        setClosedAtTime(DateTime.fromJSDate(new Date()).toUTC().toISO())
        console.log("Zoom Closed")
        setMediaState({ type: 'reset-media' });
      }
    },
    [isFailover, zmClient]
  );

  const onMediaSDKChange = useCallback((payload: any) => {
    const { action, type, result } = payload;
    setMediaState({ type: `${type}-${action}`, payload: result === 'success' });
  }, []);

  const onDialoutChange = useCallback((payload: any) => {
    // console.log('onDialoutChange', payload);
  }, []);

  const onAudioMerged = useCallback((payload: any) => {
    // console.log('onAudioMerged', payload);
  }, []);

  const onUserRemoved = useCallback((payload: any) => {
    // console.log('onUserRemoved', payload);
  }, []);

  const beforeUnload = useCallback(async (e: any) => {
    dispatch(activateZoomSession({ canActivate: false }));
    await zmClient.leave();
    console.log('You have left the session.');
    return true
  }, [])

  useEffect(() => {
    if (sessionId && callInstanceId && connectedAtTime && closedAtTime && status === 'connected') {
      console.log("Update Appointment Status: Connected", sessionId, callInstanceId, connectedAtTime, status, closedAtTime)
      dispatch(updateSessionCallLog({ sessionId, callInstanceId, connectedAtTime, closedAtTime: closedAtTime, status: "started" }))
    }
    if (sessionId && callInstanceId && connectedAtTime && closedAtTime && status === 'closed') {
      console.log("Update Appointment Status: Closed", sessionId, callInstanceId, connectedAtTime, status, closedAtTime)
      dispatch(updateSessionCallLog({ sessionId, callInstanceId, connectedAtTime, closedAtTime, status: "in_progress" }))
    }
  }, [sessionId, callInstanceId, connectedAtTime, closedAtTime, status])

  useEffect(() => {
    zmClient.on('connection-change', onConnectionChange);
    zmClient.on('media-sdk-change', onMediaSDKChange);
    zmClient.on('dialout-state-change', onDialoutChange);
    zmClient.on('merged-audio', onAudioMerged);
    zmClient.on('user-removed', onUserRemoved);
    window.addEventListener("beforeunload", beforeUnload)
    return () => {
      zmClient.off('connection-change', onConnectionChange);
      zmClient.off('media-sdk-change', onMediaSDKChange);
      zmClient.off('dialout-state-change', onDialoutChange);
      zmClient.off('merged-audio', onAudioMerged);
      zmClient.off('user-removed', onUserRemoved);
      window.removeEventListener("beforeunload", beforeUnload)
    };
  }, [zmClient, onConnectionChange, onMediaSDKChange, onDialoutChange, onAudioMerged]);

  return (
    <div className='grid grid-cols-3 h-screen'>
      <div className='h-full col-span-1 max-h-screen overflow-y-auto'>
        <ZoomMediaContext.Provider value={mediaContext}>
          <VideoNonSAB />
        </ZoomMediaContext.Provider>
      </div>
      <div className='bg-black col-span-2 max-h-screen overflow-y-auto'>
        {SessionTypes.Intake === sessionType && <ModalProvider><ProspectIntakeComponent prospectId={prospect.id} notesSessionId={sessionId} screenName={"call"} /></ModalProvider>}
        {SessionTypes.TreatmentPlan === sessionType && <PatientTreatmentPlanComponent patientProspectId={prospect.id} notesSessionId={sessionId} />}
        {SessionTypes.FollowUp === sessionType && <PatientProgressNotesComponent patientProspectId={prospect.id} notesSessionId={sessionId}/>}
        {SessionTypes.SafetyPlan === sessionType && <PatientSafetyPlanNotesComponent patientProspectId={prospect.id} notesSessionId={sessionId}/>}
        {SessionTypes.Catchup === sessionType && <PatientCatchupNotesComponent patientProspectId={prospect.id} notesSessionId={sessionId}/>}
      </div>
    </div>
  )
}

export default CallScreen