const LOGIN_SUCCESS = "Care_Login_Success"
const LOGIN_FAILED = "Care_Login_Failed"
const LOGIN_REQUEST = "Care_Login_Request"
const FORGOT_PASSWORD_REQUEST = "Forgot_Password_Request"
const REFER_PATIENT_FROM_LIST = "Refer_From_Patient_List"
const REFER_PATIENT_FROM_DETAILS = "Refer_From_Patient_Details"
const REFER_PATIENT_REQUEST = "Refer_Patient_Request"
const REFER_PATIENT_SUCCESS = "Refer_Patient_Success"
const REFER_PATIENT_FAILED = "Refer_Patient_Failed"
const VIEW_SCREENER_PDF = "View_Screener_PDF"
const DOWNLOAD_SCREENER_PDF = "Download_Screener_PDF"

export {
    LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILED, FORGOT_PASSWORD_REQUEST,
    REFER_PATIENT_FAILED, REFER_PATIENT_SUCCESS, REFER_PATIENT_REQUEST, REFER_PATIENT_FROM_LIST, REFER_PATIENT_FROM_DETAILS,
    VIEW_SCREENER_PDF, DOWNLOAD_SCREENER_PDF
}
