import {AnyAction} from "redux";
import {
    CatchupNotes,
    CatchupNotesResponse,
    CurrentQuestion,
    DischargedPatients,
    IAssignedScreeners, IClinicalNotesComplianceStats, IPendingClinicalNote,
    IProspect,
    IScreenerComplianceStats, IScreenerPendingPatient, IScreenerPendingPatients,
    ITherapistPatient,
    LocationIndexItem,
    NotesStatus,
    PatientAccount,
    PatientDailyCheckinInsights,
    PatientJourney,
    PatientSessionInsights,
    PsychiatristNotes,
    PsychiatristNotesResponse,
    ScreenerProviderType,
    SourcePatient, TPendingClinicalNotesListRequest, TPendingClinicalNotesStatsRequest,
    TreatmentPlanNotes, TScreeningPendingPatientsRequest
} from "../../../models";

export const FETCH_SCREENER_COMPLIANCE_STATS = "FETCH_SCREENER_COMPLIANCE_STATS";

export const FETCH_SCREENER_COMPLIANCE_STATS_IN_PROGRESS = "FETCH_SCREENER_COMPLIANCE_STATS_IN_PROGRESS";

export const FETCH_SCREENER_COMPLIANCE_STATS_SUCCESS = "FETCH_SCREENER_COMPLIANCE_STATS_SUCCESS";

export const FETCH_SCREENER_COMPLIANCE_STATS_FAILED = "FETCH_SCREENER_COMPLIANCE_STATS_FAILED";

export const FETCH_SCREENER_PENDING_PATIENTS = "FETCH_SCREENER_PENDING_PATIENTS";

export const FETCH_SCREENER_PENDING_PATIENTS_IN_PROGRESS = "FETCH_SCREENER_PENDING_PATIENTS_IN_PROGRESS";

export const FETCH_SCREENER_PENDING_PATIENTS_SUCCESS = "FETCH_SCREENER_PENDING_PATIENTS_SUCCESS";

export const FETCH_SCREENER_PENDING_PATIENTS_FAILED = "FETCH_SCREENER_PENDING_PATIENTS_FAILED";

export const FETCH_CLINICAL_NOTES_COMPLIANCE_STATS = "FETCH_CLINICAL_NOTES_COMPLIANCE_STATS";

export const FETCH_CLINICAL_NOTES_COMPLIANCE_STATS_IN_PROGRESS = "FETCH_CLINICAL_NOTES_COMPLIANCE_STATS_IN_PROGRESS";

export const FETCH_CLINICAL_NOTES_COMPLIANCE_STATS_SUCCESS = "FETCH_CLINICAL_NOTES_COMPLIANCE_STATS_SUCCESS";

export const FETCH_CLINICAL_NOTES_COMPLIANCE_STATS_FAILED = "FETCH_SCREENER_COMPLIANCE_STATS_FAILED";

export const FETCH_PENDING_CLINICAL_NOTES = "FETCH_PENDING_CLINICAL_NOTES";

export const FETCH_PENDING_CLINICAL_NOTES_IN_PROGRESS = "FETCH_PENDING_CLINICAL_NOTES_IN_PROGRESS";

export const FETCH_PENDING_CLINICAL_NOTES_SUCCESS = "FETCH_PENDING_CLINICAL_NOTES_SUCCESS";

export const FETCH_PENDING_CLINICAL_NOTES_FAILED = "FETCH_PENDING_CLINICAL_NOTES_FAILED";

export const fetchScreenerComplianceStats = (): AnyAction => {
    return {type: FETCH_SCREENER_COMPLIANCE_STATS}
}

export const fetchScreenerComplianceStatsInProgress = (): AnyAction => {
    return {type: FETCH_SCREENER_COMPLIANCE_STATS_IN_PROGRESS}
}

export const fetchScreenerComplianceStatsFailed = (payload: { error: string }): AnyAction => {
    return {type: FETCH_SCREENER_COMPLIANCE_STATS_FAILED, payload}
}

export const fetchedScreenerComplianceStats = (stats: IScreenerComplianceStats): AnyAction => {
    return {type: FETCH_SCREENER_COMPLIANCE_STATS_SUCCESS, payload: {stats}}
}

export const fetchScreenerPendingPatients = (request: TScreeningPendingPatientsRequest): AnyAction => {
    return {type: FETCH_SCREENER_PENDING_PATIENTS, payload: {request}}
}

export const fetchScreenerPendingPatientsInProgress = (): AnyAction => {
    return {type: FETCH_SCREENER_PENDING_PATIENTS_IN_PROGRESS}
}

export const fetchScreenerPendingPatientsFailed = (payload: { error: string }): AnyAction => {
    return {type: FETCH_SCREENER_PENDING_PATIENTS_FAILED, payload}
}

export const fetchedScreenerPendingPatients = (patients: IScreenerPendingPatient[], patientsCount: number): AnyAction => {
    return {type: FETCH_SCREENER_PENDING_PATIENTS_SUCCESS, payload: {patients, patientsCount}}
}

export const fetchClinicalNotesComplianceStats = (request?: TPendingClinicalNotesStatsRequest): AnyAction => {
    return {type: FETCH_CLINICAL_NOTES_COMPLIANCE_STATS, payload: {request}}
}

export const fetchClinicalNotesComplianceStatsInProgress = (): AnyAction => {
    return {type: FETCH_CLINICAL_NOTES_COMPLIANCE_STATS_IN_PROGRESS}
}

export const fetchClinicalNotesComplianceStatsFailed = (payload: { error: string }): AnyAction => {
    return {type: FETCH_CLINICAL_NOTES_COMPLIANCE_STATS_FAILED, payload}
}

export const fetchedClinicalNotesComplianceStats = (stats: IClinicalNotesComplianceStats): AnyAction => {
    return {type: FETCH_CLINICAL_NOTES_COMPLIANCE_STATS_SUCCESS, payload: {stats}}
}

export const fetchPendingClinicalNotes = (request: TPendingClinicalNotesListRequest): AnyAction => {
    return {type: FETCH_PENDING_CLINICAL_NOTES, payload: {request}}
}

export const fetchPendingClinicalNotesInProgress = (): AnyAction => {
    return {type: FETCH_PENDING_CLINICAL_NOTES_IN_PROGRESS}
}

export const fetchPendingClinicalNotesFailed = (payload: { error: string }): AnyAction => {
    return {type: FETCH_PENDING_CLINICAL_NOTES_FAILED, payload}
}

export const fetchedPendingClinicalNotes = (notes: IPendingClinicalNote[], notesCount: number): AnyAction => {
    return {type: FETCH_PENDING_CLINICAL_NOTES_SUCCESS, payload: {notes, notesCount}}
}

