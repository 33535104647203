export default [
    {
        generalApearanceAndBehavior: [
            {
                hygiene: [
                    { label: "Appropriate", value: "Appropriate" },
                    { label: "Poor", value: "Poor" },
                ],
                grooming: [
                    { label: "Appropriately dressed", value: "Appropriately dressed" },
                    { label: "Unkempt", value: "Unkempt" },
                ],
                eyeContact: [
                    { label: "Good", value: "Good" },
                    { label: "Poor", value: "Poor" },
                ],
                behavior: [
                    { label: "Typical", value: "Typical" }, { label: "Cooperative", value: "Cooperative" },
                    { label: "Uncooperative", value: "Uncooperative" }, { label: "Belligerent", value: "Belligerent" },
                    { label: "Negativistic", value: "Negativistic" }, { label: "Suspicious", value: "Suspicious" },
                    { label: "Apathetic", value: "Apathetic" }, { label: "Fearful", value: "Fearful" },
                    { label: "Sleep disturbances", value: "Sleep disturbance" }, { label: "Appetite disturbances", value: "Appetite disturbances" },
                ]
            }
        ],
        motorActivity: [
            { label: "Normal", value: "Normal" },{ label: "Restless", value: "Restless" },
            { label: "Hyperactive", value: "Hyperactive" }, { label: "Bizarre gestures", value: "Bizarre gestures" },
            { label: "Mannerisms", value: "Mannerisms" }, { label: "Stereotypes", value: "Stereotypes" },
            { label: "Posturing", value: "Posturing" }, { label: "Tics", value: "Tics" },
            { label: "Tremors", value: "Tremors" }, { label: "Psychomotor agitation", value: "Psychomotor agitation" },
            { label: "Psychomotor retardation", value: "Psychomotor retardation" }
        ],
        affect: [
            { label: 'Normal', value: 'Normal' }, { label: 'Flat', value: 'Flat' },
            { label: 'Blunted', value: 'Blunted' }, { label: 'Constricted', value: 'Constricted' },
            { label: 'Full Range', value: 'Full Range' }, { label: 'Depressed', value: 'Depressed' },
            { label: 'Anxious', value: 'Anxious' }, { label: 'Expansive', value: 'Expansive' },
            { label: 'Congruent to Mood', value: 'Congruent to Mood' }, { label: 'Incongruent to Mood', value: 'Incongruent to Mood' },
        ],
        speech: [
            {
                rate: [
                    {label: 'Normal', value: 'Normal'}, {label: 'Spontaneous', value: 'Spontaneous'},
                    {label: 'Mute', value: 'Mute'}, {label: 'Rapid', value: 'Rapid'},
                    {label: 'Pressured', value: 'Pressured'}, {label: 'Talkative', value: 'Talkative'},
                    {label: 'Poor speech', value: 'Poor speech'}, {label: 'Dysarthria', value: 'Dysarthria'},
                ],
                fluencyOrRhythm: [
                    {label: 'Normal', value: 'Normal'}, {label: 'Slurred', value: 'Slurred'},
                    {label: 'Hesitant', value: 'Hesitant'}, {label: 'Aphasic', value: 'Aphasic'},
                ],
                relevance: [
                    {label: 'Relevant', value: 'Relevant'}, {label: 'Irrelevant', value: 'Irrelevant'},
                    {label: 'Incomprehensible', value: 'Incomprehensible'}, {label: 'Neologism', value: 'Neologism'},
                ]
            }
        ],
        thoughtProcess: [
            { label: 'Logical & Goal Directed', value: 'Logical & Goal Directed' }, { label: 'Tangential', value: 'Tangential' },
            { label: 'Loose associations', value: 'Loose associations' }, { label: 'Circumstantial', value: 'Circumstantial' },
            { label: 'Flight-of-ideas', value: 'Flight-of-ideas' }, { label: 'Over-inclusive', value: 'Over-inclusive' },
            { label: 'Word Salad', value: 'Word Salad' }, { label: 'Thought blocking', value: 'Thought blocking' },
        ],
        thoughtContent: [
            {
                delusions: [
                    { label: 'None', value: 'None' }, { label: 'Paranoid', value: 'Paranoid' },
                    { label: 'Persecutor', value: 'Persecutor' }, { label: 'Control', value: 'Control' },
                    { label: 'Grandiose', value: 'Grandiose' }, { label: 'Reference', value: 'Reference' },
                    { label: 'Guilt', value: 'Guilt' }, { label: 'Nihilistic', value: 'Nihilistic' },
                    { label: 'Jealousy', value: 'Jealousy' }, { label: 'Erotic', value: 'Erotic' },
                    { label: 'Somatic', value: 'Somatic' }, { label: 'Religious', value: 'Religious' },
                    { label: 'Bizarre', value: 'Bizarre' }, { label: 'Obsession', value: 'Obsession' },
                ],
                suicidalThought: [
                    {label: 'None', value: 'None'}, {label: 'with plan', value: 'Ideation'},
                    {label: 'with vague plan', value: 'with plan'}, {label: 'will not disclose', value: 'will_not_disclose'},
                ],
                homicidalThought: [
                    {label: 'None', value: 'None'}, {label: 'with plan', value: 'Ideation'},
                    {label: 'with vague plan', value: 'with plan'}, {label: 'will not disclose', value: 'will_not_disclose'},
                ]
            }
        ]
    }
]