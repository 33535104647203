import {
    ACCOUNT_IF_EXISTS_REQUEST,
    checkIfEmailExistsFailed,
    checkIfEmailExistsInProgress,
    storeAccountDetails,
    clearEmailExists,
    failedGeneratingZoomToken,
    GENERATE_ZOOM_JWT_TOKEN,
    generatedZoomToken
} from "../../actions/counsellor/account.action";
import {call, put, takeLatest} from "@redux-saga/core/effects";
import {callFinished, callInProgress} from "../../actions/loading.action";
import {apiHttpClient} from "../../../lib";
import {AuthState, IAccount, IPracticeResponse} from "../../../models";
import {AxiosError, AxiosResponse} from "axios";
import {Either} from "monet";
import {CheckedError, ServiceError} from "../../../types/ServiceError";
import {signinSuccessful} from "../../actions/common";
import {catchErrorMessage} from "../../../lib/api-helpers";
import {
    FETCH_PRACTICE_DETAILS,
    fetchedPracticeDetails,
    fetchingPracticeDetailsFailed
} from "../../actions/counsellor/practice.action";


const apiCheckIfEmailExists = (request: {email: string}) => {
    return apiHttpClient.get<Either<CheckedError, IAccount>>(`/check-exists?email=${request.email}`)
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IAccount)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if(statusCode >=400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during validating process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiGenerateZoomJwtToken = (request: {sessionName: string, roleType: string, token: string}) => {
    const headers = {
        'x-csrf-token': 'e83d4c04-ab83-4672-847e-a69c2bed6bce',
        'x-auth-token': request.token
    }
    const { ...requestBody } = request
    return apiHttpClient.post<{ jwt: string }>(`/zoom/jwt`, requestBody, { headers })
        .then((response: AxiosResponse) => {
            return response.data.data as { jwt: string }
        }).catch((e) => {
            console.log(e)
            throw `Failed saving substep`
        });
}

const apiFetchPracticeDetails = (action: { type: string, authState: AuthState, payload: { practiceId: string } }) => {
    const url = `/practice/${action.payload.practiceId}`;
    return apiHttpClient.get<Either<CheckedError, IPracticeResponse>>(url, { headers: { "x-auth-token": action.authState.token } })
        .then((response: AxiosResponse) => {
            return Either.right(response.data as IPracticeResponse)
        }).catch(e => catchErrorMessage(e));
}

function* checkIfEmailExists(action: { type: string,  payload: {email: string}}) {
    try {
        console.log(`AccountSaga:checkIfEmailExists`)
        yield put(callInProgress())
        yield put(clearEmailExists())
        yield put(checkIfEmailExistsInProgress());
        const eitherIfExistsResponse: Either<CheckedError, IAccount> = yield call(apiCheckIfEmailExists, {...action.payload})
        if(eitherIfExistsResponse.isLeft()) {
            const error = eitherIfExistsResponse.left()
            if(error.isChecked) {
                console.log("Encountered a Checked Error", error.message)
                yield put(checkIfEmailExistsFailed({error: error.message}));
            } else {
                yield put(checkIfEmailExistsFailed({error: error.message}));
            }
        } else {
            const account = eitherIfExistsResponse.right();
            console.log(`AccountSaga:signinAccount->\n fetched account details: ${account?.token} `)
            yield put(storeAccountDetails(account));
            yield put(signinSuccessful(account));
        }
    } catch (e) {
        console.log(e)
        yield put(checkIfEmailExistsFailed({error: "Error signing into account!"}));
    } finally {
        yield put(callFinished())
    }
}

function* generateZoomJwtToken(action: {type: string, payload: {sessionName: string, roleType: string, token: string}}){
    try{
        console.log(`AccountSaga:generateZoomJwtToken`)
        yield put(callInProgress())
        const response: {jwt: string} = yield call(apiGenerateZoomJwtToken, action.payload)
        console.log(`AccountSaga:generateZoomJwtToken token generation ${response.jwt}`)
        yield put(generatedZoomToken({token: response.jwt}))
    } catch(e: any) {
        yield put(failedGeneratingZoomToken({error: e.toString()}))
    } finally {
        yield put(callFinished())
    }
}

function* fetchPracticeDetails(action: { type: string, authState: AuthState, payload: {practiceId: string}}) {
    try {
        console.log(`AccountSaga:fetchPracticeDetails`)
        yield put(callInProgress())
        const eitherIfExistsResponse: Either<CheckedError, IPracticeResponse> = yield call(apiFetchPracticeDetails, action)
        if(eitherIfExistsResponse.isLeft()) {
            const error = eitherIfExistsResponse.left()
            if(error.isChecked) {
                console.log("AccountSaga: fetchPracticeDetails: Encountered a Checked Error", error.message)
                yield put(fetchingPracticeDetailsFailed({error: error.message}));
            } else {
                yield put(fetchingPracticeDetailsFailed({error: error.message}));
            }
        } else {
            const practice = eitherIfExistsResponse.right().data;
            console.log(`AccountSaga:fetchPracticeDetails->\n fetched practice details:  `)
            yield put(fetchedPracticeDetails({practice}));
        }
    } catch (e) {
        console.log(`AccountSaga:fetchPracticeDetails->\n error ${e}`)
        yield put(fetchingPracticeDetailsFailed({error: "Error fetching practice details!"}));
    } finally {
        yield put(callFinished())
    }
}

export default function* accountSaga() {
    yield takeLatest(ACCOUNT_IF_EXISTS_REQUEST, checkIfEmailExists)
    yield takeLatest(GENERATE_ZOOM_JWT_TOKEN, generateZoomJwtToken)
    yield takeLatest(FETCH_PRACTICE_DETAILS, fetchPracticeDetails)
}