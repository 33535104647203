import { DateTime } from "luxon";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { StateParams } from "../../store/reducers";
import { IProspect, ISummary, Roles } from "../../models";
import { PositiveNegative } from "../generic/PositiveNegative";
import ReferDetails from "../../screens/refer/ReferDetails";
import { SLUG_TO_TAB_MAP } from "./ScreenerResults";
import { useFlags } from "flagsmith/react";
import ReferDetailsV2 from "../../screens/refer/ReferDetailsV2";
import { formatISODate } from "../../shared/DateUtils";

const ScreenerSummary: React.FC<{ patientDetails: IProspect, summary: ISummary, summaryComponentRef: any, updateSelectedIndex: Function }> = ({ patientDetails, summary }) => {
    const flags = useFlags(['intake-api-v2'])
    const isIntakeV2APIEnabled = flags["intake-api-v2"].enabled
    const screenerScores = summary
    const { prospect } = useSelector((state: StateParams) => state.prospects)
    const { role } = useSelector((state: StateParams) => state.practitionerAccount)
    const pscyComplete = screenerScores?.pscyComplete || false
    const pscyScore = screenerScores?.pscyScore
    const aceComplete = screenerScores?.aceComplete || false
    const depressionComplete = screenerScores?.depressionComplete || false
    const anxietyComplete = screenerScores?.anxietyComplete || false
    const suicidalComplete = screenerScores?.suicidalComplete || false
    const pscComplete = screenerScores?.pscComplete || false
    const depressionMFQComplete = screenerScores?.depressionMFQComplete || false
    const anxietySCAREDComplete = screenerScores?.anxietySCAREDComplete || false
    const adhdComplete = screenerScores?.adhdComplete || false
    const phq9Complete = screenerScores?.phq9Complete || false
    const twoToSixComplete = screenerScores?.twoToSixMonthComplete || false
    const nineToFifteenComplete = screenerScores?.nineToFifteenMonthComplete || false
    const eighteenToThirtyComplete = screenerScores?.eighteenToThirtyMonthComplete || false
    const thirtySixToSixtyComplete = screenerScores?.thirtySixToSixtyMonthComplete || false
    const mChatRComplete = screenerScores?.mChatRComplete || false
    const drugComplete = screenerScores.drugComplete || false
    const alcoholComplete = screenerScores.alcoholComplete || false
    const ptsdComplete = screenerScores.ptsdComplete || false
    const sdohComplete = screenerScores.sdohComplete || false
    const mmseComplete = screenerScores.mmseComplete || false
    const qdrComplete = screenerScores.qdrsComplete || false

    const pscyFailed = screenerScores?.pscyFailed
    const pscySuicidalPositive = screenerScores?.pscySuicidalPositive
    const acePositive = screenerScores?.acePositive
    const depressionPositive = screenerScores?.depressionPositive
    const anxietyPositive = screenerScores?.anxietyPositive
    const suicidalPositive = screenerScores?.suicidalPositive
    const adhdInattentivePositive = screenerScores?.adhdInattentivePositive
    const adhdHyperactivePositive = screenerScores?.adhdHyperactivePositive
    const adhdCombinedPositive = screenerScores?.adhdCombinedPositive
    const pscPositive = screenerScores?.pscScreenerPositive
    const depressionMFQPositive = screenerScores?.depressionMFQPositive
    const anxietySCAREDPositive = screenerScores?.anxietySCAREDPositive
    const phq9Positive = screenerScores?.phq9Positive
    const questionnaireDrugPositive = screenerScores?.questionnaireDrugPositive
    const drugScore = screenerScores?.drugScore
    const sdohScreenerPostive = screenerScores?.sdohScreenerPostive
    const mmsePositive = screenerScores?.mmsePositive
    const qdrPositive = screenerScores?.qdrsPositive
    // const sdohNegative = screenerScores?.sdohNegative

    const questionnaireAlcoholPositive = screenerScores?.questionnaireAlcoholPositive
    const questionnaireAlcoholPositiveV2 = screenerScores?.questionnaireAlcoholPositiveV2
    const alcoholScore = screenerScores?.alcoholScore

    const questionnairePtsdPositive = screenerScores?.questionnairePtsdPositive
    const ptsdScore = screenerScores?.ptsdScore

    const potentialDevelopmental = screenerScores?.potentialDevelopmental
    const potentialTwoToSixBPSC = screenerScores?.potentialTwoToSixBPSC
    const potentialTwoToSixParentConcern = screenerScores?.potentialTwoToSixParentConcern
    const potentialTwoToSixTobacco = screenerScores?.potentialTwoToSixTobacco
    const potentialTwoToSixSubstanceAbuse = screenerScores?.potentialTwoToSixSubstanceAbuse
    const potentialTwoToSixFoodInsecurity = screenerScores?.potentialTwoToSixFoodInsecurity
    const potentialTwoToSixDomesticViolence = screenerScores?.potentialTwoToSixDomesticViolence
    const potentialTwoToSixSelfHarm = screenerScores?.potentialTwoToSixSelfHarm
    const potentialTwoToSixEmotinalChanges = screenerScores?.potentialTwoToSixEmotinalChanges
    const potentialNineToFifteenBPSC = screenerScores?.potentialNineToFifteenBPSC
    const potentialNineToFifteenParentConcern = screenerScores?.potentialNineToFifteenParentConcern
    const potentialNineToFifteenTobacco = screenerScores?.potentialNineToFifteenTobacco
    const potentialNineToFifteenSubstanceAbuse = screenerScores?.potentialNineToFifteenSubstanceAbuse
    const potentialNineToFifteenFoodInsecurity = screenerScores?.potentialNineToFifteenFoodInsecurity
    const potentialNineToFifteenParentalDepression = screenerScores?.potentialNineToFifteenParentalDepression
    const potentialNineToFifteenDomesticViolence = screenerScores?.potentialNineToFifteenDomesticViolence
    const potentialNineToFifteenSelfHarm = screenerScores?.potentialNineToFifteenSelfHarm
    const potentialNineToFifteenEmotinalChanges = screenerScores?.potentialNineToFifteenEmotinalChanges
    const potentialEighteenToThirtyPPSC = screenerScores?.potentialEighteenToThirtyPPSC
    const potentialEighteenToThirtyPOSI = screenerScores?.potentialEighteenToThirtyPOSI
    const potentialEighteenToThirtyParentConcern = screenerScores?.potentialEighteenToThirtyParentConcern
    const potentialEighteenToThirtyTobacco = screenerScores?.potentialEighteenToThirtyTobacco
    const potentialEighteenToThirtySubstanceAbuse = screenerScores?.potentialEighteenToThirtySubstanceAbuse
    const potentialEighteenToThirtyFoodInsecurity = screenerScores?.potentialEighteenToThirtyFoodInsecurity
    const potentialEighteenToThirtyParentalDepression = screenerScores?.potentialEighteenToThirtyParentalDepression
    const potentialEighteenToThirtyDomesticViolence = screenerScores?.potentialEighteenToThirtyDomesticViolence
    const potentialThirtySixToSixtyPPSC = screenerScores?.potentialThirtySixToSixtyPPSC
    const potentialThirtySixToSixtyParentConcern = screenerScores?.potentialThirtySixToSixtyParentConcern
    const potentialThirtySixToSixtyTobacco = screenerScores?.potentialThirtySixToSixtyTobacco
    const potentialThirtySixToSixtySubstanceAbuse = screenerScores?.potentialThirtySixToSixtySubstanceAbuse
    const potentialThirtySixToSixtyFoodInsecurity = screenerScores?.potentialThirtySixToSixtyFoodInsecurity
    const potentialThirtySixToSixtyParentalDepression = screenerScores?.potentialThirtySixToSixtyParentalDepression
    const potentialThirtySixToSixtyDomesticViolence = screenerScores?.potentialThirtySixToSixtyDomesticViolence
    const asdPositive = screenerScores?.asdPositive

    const aceScore = screenerScores?.aceScore
    const anxietyScore = screenerScores?.anxietyScore
    const depressionScore = screenerScores?.depressionScore
    const phq9Score = screenerScores?.phq9Score
    const suicidalScore = screenerScores?.suicidalScore
    const adhdScore = screenerScores?.adhdScore
    const asdScore = screenerScores?.asdScore
    const pscScore = screenerScores?.pscScore
    const sdohScore = screenerScores?.sdohScore
    const mmseScore = screenerScores?.mmseScore
    const qdrScore = screenerScores?.qdrsScore
    const anxietySCAREDScore = screenerScores.anxietySCAREDScore
    const depressionMFQScore = screenerScores.depressionMFQScore
    const suicidalLevel = screenerScores.suicidalLevel
    const aceLevel = screenerScores.aceLevel
    const adhdLevel = screenerScores.adhdLevel
    const phq9Level = screenerScores.phq9Level
    const twoToSixLevel = screenerScores.twoToSixLevel
    const nineToFifteenLevel = screenerScores.nineToFifteenLevel
    const asdLevel = screenerScores.asdLevel
    const drugLevel = screenerScores.drugLevel
    const ptsdLevel = screenerScores.ptsdLevel
    const alcoholLevel = screenerScores.alcoholLevel
    const depressionLevel = screenerScores.depressionLevel
    const anxietyLevel = screenerScores.anxietyLevel
    const mChatRScreenerSlug = screenerScores.mChatRScreenerSlug
    const nineToFifteenMonthScreenerSlug = screenerScores.nineToFifteenMonthScreenerSlug
    const eighteenToThirtyMonthScreenerSlug = screenerScores.eighteenToThirtyMonthScreenerSlug
    const thirtySixToSixtyMonthScreenerSlug = screenerScores.thirtySixToSixtyMonthScreenerSlug
    const pscyScreenerSlug = screenerScores.pscyScreenerSlug
    const pscScreenerSlug = screenerScores.pscScreenerSlug
    const phq9ScreenerSlug = screenerScores.phq9ScreenerSlug
    const drugScreenerSlug = screenerScores.drugScreenerSlug
    const alcoholScreenerSlug = screenerScores.alcoholScreenerSlug
    const ptsdScreenerSlug = screenerScores.ptsdScreenerSlug
    const depressionScreenerSlug = screenerScores.depressionScreenerSlug
    const depressionMFQScreenerSlug = screenerScores.depressionMFQScreenerSlug
    const anxietyScreenerSlug = screenerScores.anxietyScreenerSlug
    const anxietySCAREDScreenerSlug = screenerScores.anxietySCAREDScreenerSlug
    const suicidalScreenerSlug = screenerScores.suicidalScreenerSlug
    const aceScreenerSlug = screenerScores.aceScreenerSlug
    const adhdScreenerSlug = screenerScores.adhdScreenerSlug
    const twoToSixMonthScreenerSlug = screenerScores.twoToSixMonthScreenerSlug
    const sdohScreenerSlug = screenerScores.sdohScreenerSlug
    const mmseSlug = screenerScores.mmseSlug
    const qdrSlug = screenerScores.qdrsSlug
    const mmseLevel = screenerScores.mmseLevel
    const qdrLevel = screenerScores.qdrsLevel

    const isoDateTime = patientDetails && patientDetails.patient && formatISODate(patientDetails.patient.screeners[0].createdAt)

    const renderScreenerSummaryMessage = (isComplete: boolean, isPositive: boolean | undefined, summaryDetails: string, score?: number, slug?: string, level?: any) => {
        if (isPositive === undefined || !isComplete) {
            return
        }
        return (
            <tr className="h-14 text-sm">
                <td>{slug}</td>
                <td>{score}</td>
                <td>{isPositive ? <PositiveNegative text='Positive' level={level} /> : <PositiveNegative text="Negative" level={level} />}</td>
                {role !== Roles.Care_Coordinator ? <td>{summaryDetails}</td> : null}
            </tr>
        )
    }

    const renderScreenerDetails = (slug: string, detailMessage?: string) => {
        const summaryDetailsScreener = patientDetails.patient && patientDetails.patient.screeners.find(screener => screener.slug === slug)
        const summaryDetails = summaryDetailsScreener?.userSummaryDetails
        return summaryDetails ? summaryDetails : detailMessage ? detailMessage : '-'
    }

    return screenerScores ? (<div className={"flex flex-col"}>
        <div>
            <span className={"text-lg"}>Screener Summary</span>
        </div>
        <div className={"my-2"}>
            {patientDetails ? (<div className={"flex flex-col"}>
                <span className="text-sm mr-2 text-sjGrey">Date of Visits</span>
                <span className="text-black text-sm">{isoDateTime}</span></div>
            ) : ''}
        </div>
        <div className="shadow overflow-hidden border border-sjScreenerLink rounded-lg">
            <table className="w-full summary-table divide-y divide-sjScreenerLink">
                <thead className="text-left bg-sjTableHead " >
                    <tr className="h-14" >
                        <th>Screener</th>
                        <th>Score</th>
                        <th>Interpretation</th>
                        {role !== Roles.Care_Coordinator ? <th>Details</th> : null}
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-sjScreenerLink">
                    {pscyComplete ? renderScreenerSummaryMessage(pscyComplete, pscyFailed || pscySuicidalPositive, renderScreenerDetails(pscyScreenerSlug), pscyScore, SLUG_TO_TAB_MAP["psc-y-summary"])
                        : renderScreenerSummaryMessage(pscComplete, pscPositive, renderScreenerDetails(pscScreenerSlug), pscScore, SLUG_TO_TAB_MAP['fp-psc-6to12-summary'])}
                    {renderScreenerSummaryMessage(aceComplete, acePositive, renderScreenerDetails(aceScreenerSlug), aceScore, SLUG_TO_TAB_MAP['fp-ace-6to12-summary'], aceLevel)}
                    {anxietyComplete ? renderScreenerSummaryMessage(anxietyComplete, anxietyPositive, renderScreenerDetails(anxietyScreenerSlug), anxietyScore, SLUG_TO_TAB_MAP['fp-anxiety-above18-summary'], anxietyLevel)
                        : renderScreenerSummaryMessage(anxietySCAREDComplete, anxietySCAREDPositive, renderScreenerDetails(anxietySCAREDScreenerSlug), anxietySCAREDScore, SLUG_TO_TAB_MAP['fp-anxiety-SCARED-summary'])}
                    {depressionComplete ? renderScreenerSummaryMessage(depressionComplete, depressionPositive, renderScreenerDetails(depressionScreenerSlug), depressionScore, SLUG_TO_TAB_MAP['depression-summary'], depressionLevel)
                        : depressionMFQComplete ? renderScreenerSummaryMessage(depressionMFQComplete, depressionMFQPositive, renderScreenerDetails(depressionMFQScreenerSlug), depressionMFQScore, SLUG_TO_TAB_MAP['fp-depression-MFQ-summary'])
                            : renderScreenerSummaryMessage(phq9Complete, phq9Positive, renderScreenerDetails(phq9ScreenerSlug), phq9Score, SLUG_TO_TAB_MAP['depression-summary'], phq9Level)}

                    {/* {renderScreenerSummaryMessage(pscyComplete, pscySuicidalPositive, suicidalScore)} */}
                    {/* {!phq9SuicidalPositive && renderScreenerSummaryMessage(phq9Complete, phq9SuicidalPositive, suicidalNegativeMessageText)} */}
                    {renderScreenerSummaryMessage(suicidalComplete, suicidalPositive, renderScreenerDetails(suicidalScreenerSlug), suicidalScore, SLUG_TO_TAB_MAP['fp-suicidal-summary'], suicidalLevel)}
                    {renderScreenerSummaryMessage(alcoholComplete, questionnaireAlcoholPositive || questionnaireAlcoholPositiveV2, renderScreenerDetails(alcoholScreenerSlug, 'One drink equals: 12 oz.beer, 5 oz. wine, 1.5 oz. liquor (one shot)'), alcoholScore, SLUG_TO_TAB_MAP['fp-alcohol-above18-summary'], alcoholLevel)}
                    {renderScreenerSummaryMessage(drugComplete, questionnaireDrugPositive, renderScreenerDetails(drugScreenerSlug), drugScore, SLUG_TO_TAB_MAP['fp-drug-above18-summary'], drugLevel)}
                    {renderScreenerSummaryMessage(ptsdComplete, questionnairePtsdPositive, renderScreenerDetails(ptsdScreenerSlug), ptsdScore, SLUG_TO_TAB_MAP['fp-ptsd-above18-summary'], ptsdLevel)}
                    {renderScreenerSummaryMessage(adhdComplete, adhdInattentivePositive || adhdHyperactivePositive || adhdCombinedPositive, renderScreenerDetails(adhdScreenerSlug), adhdScore, SLUG_TO_TAB_MAP['fp-adhd-6to12-summary'], adhdLevel)}
                    {/* {renderScreenerSummaryMessage(mChatRComplete, potentialASD1 || potentialASD2, renderScreenerDetails(mChatRScreenerSlug), asdScore, SLUG_TO_TAB_MAP['fp-m-chat-r'], asdLevel)} */}
                    {renderScreenerSummaryMessage(mChatRComplete, asdPositive, renderScreenerDetails(mChatRScreenerSlug), asdScore, SLUG_TO_TAB_MAP['fp-m-chat-r-summary'], asdLevel)}

                    {renderScreenerSummaryMessage(twoToSixComplete, potentialDevelopmental, renderScreenerDetails(twoToSixMonthScreenerSlug), screenerScores?.twoToSixDevelopmentalScore, SLUG_TO_TAB_MAP['developmental'])}
                    {renderScreenerSummaryMessage(twoToSixComplete, potentialTwoToSixBPSC, renderScreenerDetails(twoToSixMonthScreenerSlug), screenerScores?.twoToSixBPSCScore, SLUG_TO_TAB_MAP['bpsc'])}
                    {renderScreenerSummaryMessage(twoToSixComplete, potentialTwoToSixParentConcern, renderScreenerDetails(twoToSixMonthScreenerSlug), screenerScores?.twoToSixParentConcernScore, SLUG_TO_TAB_MAP['parent-concern'])}
                    {renderScreenerSummaryMessage(twoToSixComplete, potentialTwoToSixTobacco, renderScreenerDetails(twoToSixMonthScreenerSlug), screenerScores?.twoToSixTobaccoScore, SLUG_TO_TAB_MAP['tobacco'])}
                    {renderScreenerSummaryMessage(twoToSixComplete, potentialTwoToSixSubstanceAbuse, renderScreenerDetails(twoToSixMonthScreenerSlug), screenerScores?.twoToSixSubstanceAbuseScore, SLUG_TO_TAB_MAP['substance-abuse'])}
                    {renderScreenerSummaryMessage(twoToSixComplete, potentialTwoToSixFoodInsecurity, renderScreenerDetails(twoToSixMonthScreenerSlug), screenerScores?.twoToSixFoodInsecurityScore, SLUG_TO_TAB_MAP['food-insecurity'])}
                    {renderScreenerSummaryMessage(twoToSixComplete, potentialTwoToSixDomesticViolence, renderScreenerDetails(twoToSixMonthScreenerSlug), screenerScores?.twoToSixDomesticViolenceScore, SLUG_TO_TAB_MAP['domestic-violence'])}
                    {renderScreenerSummaryMessage(twoToSixComplete, potentialTwoToSixEmotinalChanges, renderScreenerDetails(twoToSixMonthScreenerSlug), screenerScores?.twoToSixEmotionalChangesScore, SLUG_TO_TAB_MAP['emotinal-change'], twoToSixLevel)}
                    {renderScreenerSummaryMessage(twoToSixComplete, potentialTwoToSixSelfHarm, renderScreenerDetails(twoToSixMonthScreenerSlug), screenerScores?.twoToSixSelfHarmScore, SLUG_TO_TAB_MAP['self-harm'])}

                    {renderScreenerSummaryMessage(nineToFifteenComplete, potentialDevelopmental, renderScreenerDetails(nineToFifteenMonthScreenerSlug), screenerScores?.nineToFifteenDevelopmentalScore, SLUG_TO_TAB_MAP['developmental'])}
                    {renderScreenerSummaryMessage(nineToFifteenComplete, potentialNineToFifteenBPSC, renderScreenerDetails(nineToFifteenMonthScreenerSlug), screenerScores?.nineToFifteenBPSCScore, SLUG_TO_TAB_MAP['bpsc'])}
                    {renderScreenerSummaryMessage(nineToFifteenComplete, potentialNineToFifteenParentConcern, renderScreenerDetails(nineToFifteenMonthScreenerSlug), screenerScores?.nineToFifteenParentConcernScore, SLUG_TO_TAB_MAP['parent-concern'])}
                    {renderScreenerSummaryMessage(nineToFifteenComplete, potentialNineToFifteenTobacco, renderScreenerDetails(nineToFifteenMonthScreenerSlug), screenerScores?.nineToFifteenTobaccoScore, SLUG_TO_TAB_MAP['tobacco'])}
                    {renderScreenerSummaryMessage(nineToFifteenComplete, potentialNineToFifteenSubstanceAbuse, renderScreenerDetails(nineToFifteenMonthScreenerSlug), screenerScores?.nineToFifteenSubstanceAbuseScore, SLUG_TO_TAB_MAP['substance-abuse'])}
                    {renderScreenerSummaryMessage(nineToFifteenComplete, potentialNineToFifteenFoodInsecurity, renderScreenerDetails(nineToFifteenMonthScreenerSlug), screenerScores?.nineToFifteenFoodInsecurityScore, SLUG_TO_TAB_MAP['food-insecurity'])}
                    {renderScreenerSummaryMessage(nineToFifteenComplete, potentialNineToFifteenParentalDepression, renderScreenerDetails(nineToFifteenMonthScreenerSlug), screenerScores?.nineToFifteenParentalDepressionScore, SLUG_TO_TAB_MAP['parental-depression'])}
                    {renderScreenerSummaryMessage(nineToFifteenComplete, potentialNineToFifteenDomesticViolence, renderScreenerDetails(nineToFifteenMonthScreenerSlug), screenerScores?.nineToFifteenDomesticViolenceScore, SLUG_TO_TAB_MAP['domestic-violence'])}
                    {renderScreenerSummaryMessage(nineToFifteenComplete, potentialNineToFifteenEmotinalChanges, renderScreenerDetails(nineToFifteenMonthScreenerSlug), screenerScores?.nineToFifteenEmotionalChangesScore, SLUG_TO_TAB_MAP['emotinal-change'], nineToFifteenLevel)}
                    {renderScreenerSummaryMessage(nineToFifteenComplete, potentialNineToFifteenSelfHarm, renderScreenerDetails(nineToFifteenMonthScreenerSlug), screenerScores?.nineToFifteenSelfHarmScore, SLUG_TO_TAB_MAP['self-harm'])}

                    {renderScreenerSummaryMessage(eighteenToThirtyComplete, potentialDevelopmental, renderScreenerDetails(eighteenToThirtyMonthScreenerSlug), screenerScores?.eighteenToThirtyDevelopmentalScore, SLUG_TO_TAB_MAP['developmental'])}
                    {renderScreenerSummaryMessage(eighteenToThirtyComplete, potentialEighteenToThirtyPPSC, renderScreenerDetails(eighteenToThirtyMonthScreenerSlug), screenerScores?.eighteenToThirtyPPSCScore, SLUG_TO_TAB_MAP['ppsc'])}
                    {renderScreenerSummaryMessage(eighteenToThirtyComplete, potentialEighteenToThirtyPOSI, renderScreenerDetails(eighteenToThirtyMonthScreenerSlug), screenerScores?.eighteenToThirtyPOSIScore, SLUG_TO_TAB_MAP['posi'])}
                    {renderScreenerSummaryMessage(eighteenToThirtyComplete, potentialEighteenToThirtyParentConcern, renderScreenerDetails(eighteenToThirtyMonthScreenerSlug), screenerScores?.eighteenToThirtyParentConcernScore, SLUG_TO_TAB_MAP['parent-concern'])}
                    {renderScreenerSummaryMessage(eighteenToThirtyComplete, potentialEighteenToThirtyTobacco, renderScreenerDetails(eighteenToThirtyMonthScreenerSlug), screenerScores?.eighteenToThirtyTobaccoScore, SLUG_TO_TAB_MAP['tobacco'])}
                    {renderScreenerSummaryMessage(eighteenToThirtyComplete, potentialEighteenToThirtySubstanceAbuse, renderScreenerDetails(eighteenToThirtyMonthScreenerSlug), screenerScores?.eighteenToThirtySubstanceAbuseScore, SLUG_TO_TAB_MAP['substance-abuse'])}
                    {renderScreenerSummaryMessage(eighteenToThirtyComplete, potentialEighteenToThirtyFoodInsecurity, renderScreenerDetails(eighteenToThirtyMonthScreenerSlug), screenerScores?.eighteenToThirtyFoodInsecurityScore, SLUG_TO_TAB_MAP['food-insecurity'])}
                    {renderScreenerSummaryMessage(eighteenToThirtyComplete, potentialEighteenToThirtyParentalDepression, renderScreenerDetails(eighteenToThirtyMonthScreenerSlug), screenerScores?.eighteenToThirtyParentalDepressionScore, SLUG_TO_TAB_MAP['parental-depression'])}
                    {renderScreenerSummaryMessage(eighteenToThirtyComplete, potentialEighteenToThirtyDomesticViolence, renderScreenerDetails(eighteenToThirtyMonthScreenerSlug), screenerScores?.eighteenToThirtyDomesticViolenceScore, SLUG_TO_TAB_MAP['domestic-violence'])}

                    {renderScreenerSummaryMessage(thirtySixToSixtyComplete, potentialDevelopmental, renderScreenerDetails(thirtySixToSixtyMonthScreenerSlug), screenerScores?.thirtySixToSixtyDevelopmentalScore, SLUG_TO_TAB_MAP['developmental'])}
                    {renderScreenerSummaryMessage(thirtySixToSixtyComplete, potentialThirtySixToSixtyPPSC, renderScreenerDetails(thirtySixToSixtyMonthScreenerSlug), screenerScores?.thirtySixToSixtyPPSCScore, SLUG_TO_TAB_MAP['ppsc'])}
                    {renderScreenerSummaryMessage(thirtySixToSixtyComplete, potentialThirtySixToSixtyParentConcern, renderScreenerDetails(thirtySixToSixtyMonthScreenerSlug), screenerScores?.thirtySixToSixtyParentConcernScore, SLUG_TO_TAB_MAP['parent-concern'])}
                    {renderScreenerSummaryMessage(thirtySixToSixtyComplete, potentialThirtySixToSixtyTobacco, renderScreenerDetails(thirtySixToSixtyMonthScreenerSlug), screenerScores?.thirtySixToSixtyTobaccoScore, SLUG_TO_TAB_MAP['tobacco'])}
                    {renderScreenerSummaryMessage(thirtySixToSixtyComplete, potentialThirtySixToSixtySubstanceAbuse, renderScreenerDetails(thirtySixToSixtyMonthScreenerSlug), screenerScores?.thirtySixToSixtySubstanceAbuseScore, SLUG_TO_TAB_MAP['substance-abuse'])}
                    {renderScreenerSummaryMessage(thirtySixToSixtyComplete, potentialThirtySixToSixtyFoodInsecurity, renderScreenerDetails(thirtySixToSixtyMonthScreenerSlug), screenerScores?.thirtySixToSixtyFoodInsecurityScore, SLUG_TO_TAB_MAP['food-insecurity'])}
                    {renderScreenerSummaryMessage(thirtySixToSixtyComplete, potentialThirtySixToSixtyParentalDepression, renderScreenerDetails(thirtySixToSixtyMonthScreenerSlug), screenerScores?.thirtySixToSixtyParentalDepressionScore, SLUG_TO_TAB_MAP['parental-depression'])}
                    {renderScreenerSummaryMessage(thirtySixToSixtyComplete, potentialThirtySixToSixtyDomesticViolence, renderScreenerDetails(thirtySixToSixtyMonthScreenerSlug), screenerScores?.thirtySixToSixtyDomesticViolenceScore, SLUG_TO_TAB_MAP['domestic-violence'])}
                    {renderScreenerSummaryMessage(sdohComplete, sdohScreenerPostive, renderScreenerDetails(sdohScreenerSlug), sdohScore, SLUG_TO_TAB_MAP['fp-sdoh-above18'])}
                    {renderScreenerSummaryMessage(mmseComplete, mmsePositive, renderScreenerDetails(mmseSlug), mmseScore, SLUG_TO_TAB_MAP['fp-mmse-cognitive'], mmseLevel)}
                    {renderScreenerSummaryMessage(qdrComplete, qdrPositive, renderScreenerDetails(qdrSlug), qdrScore, SLUG_TO_TAB_MAP['fp-qdrs-cognitive'], qdrLevel)}
                </tbody>
            </table>
        </div>
        <div>
            {isIntakeV2APIEnabled && prospect ? <ReferDetailsV2 patient={prospect} /> : <ReferDetails />}
        </div>
    </div>) : null
}

export default ScreenerSummary
