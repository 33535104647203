import {
    Control,
    FieldErrorsImpl,
    useFieldArray,
    UseFormGetValues,
    UseFormRegister,
    UseFormWatch
} from "react-hook-form";
import {Inputs} from "./SafetyPlanNotes";
import {Textarea} from "@material-tailwind/react";


const CopingStrategies: React.FC<{
    control: Control<Inputs, any>, register: UseFormRegister<Inputs>,
    setValue: any, getValues: UseFormGetValues<Inputs>, watch: UseFormWatch<Inputs>, errors: Partial<FieldErrorsImpl<Inputs>>,
    saveNotes: (key: keyof Inputs, answer: any) => void

}> = ({ control, register, setValue, getValues, watch, errors, saveNotes }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: "copingStrategies"
    });

    const handleAppend = (value: any) => {
        append(value)
        saveNotes('copingStrategies', getValues().copingStrategies)
    };

    const handleDelete = (index: number) => {
        remove(index)
        saveNotes('copingStrategies', getValues().copingStrategies)
    }

    return (
        <div>
            <Textarea {...register("copingStrategiesAnswer")} className="mt-3 outline-none rounded-md" 
                placeholder="Example: Listen to music, Take a bath, Take deep breaths, Positive affirmations"/>
            {fields.map((item, index) => {
                return (
                    <div key={item.id}>
                        <div className="flex justify-between mt-3">
                            <label className="block text-lg text-sjOrange uppercase"
                                key={`label-${index}`}>Strategy {index + 1}:</label>
                            <button className={`pl-2.5 pr-2.5 pt-1 pb-1 text-sm bg-sjWhite 
                                rounded-md text-sjOrange border-2 border-solid border-sjOrange`}
                                onClick={() => handleDelete(index)}
                            >
                                Delete
                            </button>
                        </div>
                        <Textarea {...register(`copingStrategies.${index}.strategy`)} key={`strategy-${index}`}
                            className="mt-3 outline-none rounded-md" />
                    </div>
                )
            })}
            {errors && errors.copingStrategies && <p className="error-msg">{errors.copingStrategies?.message}</p>}
            <section>
                <button className={"p-2.5 text-sm bg-sjOrange rounded-md text-sjWhite border-solid border-l-sjOrange"}
                    onClick={() => handleAppend({ strategy: '' })}
                >
                    Add a strategy
                </button>
            </section>
        </div>
    )
};

export default CopingStrategies