import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {clearPatientSearchResults, searchPatient} from "../../store/actions/counsellor/prospects.action";
import {StateParams} from "../../store/reducers";
import {IPatientIndexItem, SessionTypes} from "../../models";
import Select from 'react-select'
import {DateTime} from "luxon";

const PatientAutocomplete = React.forwardRef((props: {className: string, sessionType: keyof typeof SessionTypes, onChange: Function, patientId: string, setValue: Function}, ref: any) => {
    const dispatch = useDispatch()
    const patientSearchResults = useSelector((state: StateParams) => state.prospects.patientSearchResults)
    const [patients, setPatients] = useState<IPatientIndexItem[]>([])

    useEffect(() => {
        console.log("PatientAutoComplete: Received Results", patientSearchResults)
        setPatients(() => patientSearchResults)
    }, [patientSearchResults])

    const handlePatientSearch = (value: string) => {
        if (value && value.length >= 2) {
            console.log("PatientAutoComplete: searching for key", value)
            const searchForSessionType = props.sessionType === "Intake" ? props.sessionType : props.sessionType;
            dispatch(searchPatient(value, searchForSessionType));
        } else {
            console.log("PatientAutoComplete: empty patients being set")
            setPatients([])
            dispatch(clearPatientSearchResults())
        }
    };

    return <Select ref={ref}
                   options={patients}
                   onInputChange={handlePatientSearch}
                   isSearchable={true}
                   getOptionLabel={option =>`${option.firstName} ${option.lastName} - ${DateTime.fromISO(option.dob).toFormat("DD")}`}
                   getOptionValue={option => option.id}
                   noOptionsMessage={() => null}
                   placeholder={'Enter Patient Name'}
                   autoFocus={true}
                   onChange={value => {
                        props.onChange(value?.id)
                        props.setValue('sourcePatientId', value?.sourcePatientId)
                        props.setValue('referredByPracticeId', value?.referredByPracticeId)
                    }}
                   value={props.patientId ? patients.find(patient => patient.id === props.patientId) : null}
        />

})

export default PatientAutocomplete
