import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {ArrowRightOnRectangleIcon} from '@heroicons/react/24/outline'
import {StateParams} from "../../store/reducers";
import {googleLogout} from '@react-oauth/google';
import CounsellorSideBar from "./counsellor/CounsellorSideBar";
import PsychiatristSideBar from "./psychiatrist/PsychiatristSideBar";
import PhysicianSideBar from "./physician/PhysicianSideBar";
import {useLocation} from "react-router-dom";
import {NavigationComponentType} from "../../navigation/NavigationComponentType";
import {setActiveNavigationComponent} from "../../store/actions/counsellor/navigation.actions";
import {logout} from "../../store/actions/common";
import CareCoordinatorSideBar from "./care-coordinator/CareCoordinatorSideBar";
import {PlainButton} from "../generic/Buttons";
import CommonSideBarFooter from "./CommonSideBarFooter";
import PhysicianSideBarFooter from "./physician/PhysicianSideBarFooter";
import sjLogo from "../../assets/images/logos/logo_transparent.svg";
import {isCallActive} from "../../models";
import ConfirmationModal from "../clinical-notes/ConfirmationModal";
import MedicalAssistantSideBar from "./medical-assistant/MedicalAssistantSideBar";
import MedicalAssistantSideBarFooter from "./medical-assistant/MedicalAssistantSideBarFooter"


export const SideBarByRole : {[key: string] : React.FC} = {
    "Admin": CounsellorSideBar,
    "Counsellor": CounsellorSideBar,
    "Psychiatrist": PsychiatristSideBar,
    "Physician": PhysicianSideBar,
    "Care_Coordinator": CareCoordinatorSideBar,
    "Medical_Assistant": MedicalAssistantSideBar,
}

export const SideBarFooterByRole : {[key: string] : React.FC} = {
    "Admin": CommonSideBarFooter,
    "Counsellor": CommonSideBarFooter,
    "Psychiatrist": CommonSideBarFooter,
    "Physician": PhysicianSideBarFooter,
    "Care_Coordinator": CommonSideBarFooter,
    "Medical_Assistant": MedicalAssistantSideBarFooter,
}

const SideBar: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();
    const { role } = useSelector((state: StateParams) => state.account)
    const {callStatus} = useSelector((state: StateParams )=> state.calls)
    const [callActive, setCallActive] = useState(false)
    const [showLogoutConfirmationModal, setShowLogoutConfirmationModal] = useState(false)

    useEffect(() => {
        setCallActive(isCallActive(callStatus))
    }, [callStatus]);

    useEffect(() => {
        const pathComponents = location.pathname.split("/")
        try {
            const activatePathComponent = pathComponents[pathComponents.length - 1] as NavigationComponentType
            dispatch(setActiveNavigationComponent(activatePathComponent))
        } catch (e) {
        }
    }, [location.pathname])

    const logOut = (canLogout: boolean = false) => {
        if(canLogout) {
            dispatch(logout())
            if(role !== "Physician") {
                googleLogout()
            }
            navigate('/home')
        } else {
            setShowLogoutConfirmationModal(true)
        }
    }

    function renderSideBar(role: string) {
        const SideBarByRoleComponent = SideBarByRole[role];
        const SideBarFooterByRoleComponent = SideBarFooterByRole[role];

        return (
                <div className="w-56 p-3 bg-sjSideBar overflow-auto relative">
                    <div className="m-2 text-center font-bold">
                        <div className='w-full h-12 flex items-center justify-center'>
                            <img src={sjLogo}/>
                        </div>
                    </div>
                    <SideBarByRoleComponent/>
                    <div className={"absolute bottom-0 left-0 w-full"}>
                        <div className={"flex justify-center items-center mb-4"}>
                            <PlainButton
                                className={"px-4 py-1 flex w-[80%] items-center text-sm border-2 border-sjOrange text-center rounded-md bg-sjWhite text-sjGrey"}
                                onClick={() => logOut(false)}
                            >
                                <ArrowRightOnRectangleIcon className="h-5 w-5 m-1 mr-4 ml-2 text-sjGrey"
                                                           aria-hidden="true"/>
                                <span>Logout</span>
                            </PlainButton>
                        </div>
                        <SideBarFooterByRoleComponent/>
                    </div>
                    <ConfirmationModal isOpen={showLogoutConfirmationModal}
                                       onClose={() => setShowLogoutConfirmationModal(false)}
                                       onConfirm={() => logOut(true)}
                                       key={'logoutConfirmation'}>
                        <p className="text-lg text-sjLightRed-500">{callActive ? "You have a call in progress !!" : ""}</p>
                        <p className="text-md text-gray-500">Are you sure you want to logout ?</p>
                    </ConfirmationModal>
                </div>
        );
    }

    return role ? renderSideBar(role) : <></>
}

export default SideBar
