import * as React from "react";
import {useSelector} from "react-redux";
import {StateParams} from "../store/reducers";
import {Navigate, useLocation} from 'react-router-dom';
import {RoutePaths} from "../shared/Utils";

export const authContext = React.createContext<{authed: boolean}>({authed: false});

function useAuth() {
    const {token: jwt} = useSelector((state: StateParams) => state.account);
    const [authed, setAuthed] = React.useState(!!jwt);

    return {
        authed
    };
}

export function AuthProvider(props: any) {
    const auth = useAuth();

    return <authContext.Provider value={auth}>{props.children}</authContext.Provider>;
}

export default function AuthConsumer() {
    return React.useContext(authContext);
}

export function RequireAuth(props: any) {
    const { authed } = useAuth();
    const location = useLocation()

    console.log("User is authenticated", authed)
    return authed ? props.children : <Navigate to={`/${RoutePaths.home.root}`} state={{redirect: location.pathname}} replace />;
}