import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { cancelPolling, initiateLocationPhoneVerification } from "../../store/actions/physician/admin-operations.action"
import { StateParams } from "../../store/reducers"
import { Button } from "../generic/Buttons"
import { Timer } from "../generic/Timer"

const PhoneNumberVerificationComponent: React.FC<{ phoneNumber: string }> = ({ phoneNumber }) => {
    const dispatch = useDispatch()
    const location = useSelector((state: StateParams) => state.physicianAdminOperations.location)
    const verificationCode = useSelector((state: StateParams) => state.physicianAdminOperations.phoneVerificationCode)
    const [toggleTimer, setToggleTimer] = useState<boolean>(false)
    let hasPhoneVerificationStatusChanged = false
    const isPhoneVerified = useSelector((state: StateParams) => state.physicianAdminOperations.location?.isPhoneVerified, 
    (prev, next) => {
        if(prev !== next) {
            hasPhoneVerificationStatusChanged = true
        }
        return prev === next
    })

    useEffect(() => {
        if (!toggleTimer) {
            dispatch(cancelPolling())
        }
    }, [toggleTimer])

    useEffect(() => {
        if(hasPhoneVerificationStatusChanged) {
            setToggleTimer(false)
        }
    }, [hasPhoneVerificationStatusChanged])

    function handleVerifying() {
        setToggleTimer(true)
        if(location && location.id && location.phone) {
            dispatch(initiateLocationPhoneVerification(location.id, location.phone))
        }
    }

    function renderVerifyButton() {
        return <Button className={`!bg-sjOrange h-10 !text-white ${toggleTimer ? 'opacity-50 cursor-not-allowed' : ''} `} disabled={toggleTimer}
            onClick={handleVerifying}>Verify</Button>
    }
    function renderReVerifyButton() {
        return <Button className={`!bg-sjOrange h-10 !text-white ${toggleTimer ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={toggleTimer}
            onClick={handleVerifying}>Re-Verify</Button>
    }
    function renderStatusButton(status?: boolean | null) {
        const ButtonComponent = status === false ? renderReVerifyButton : renderVerifyButton
        return !status ? <ButtonComponent /> : <></>
    }

    return (
        <div className="pt-2">
            {verificationCode && toggleTimer && <div className="border rounded-md border-[#E0E0E0] p-2">
                <Timer setContainerVisible={setToggleTimer}/>
                <span className="font-light text-xs text-sjGray">
                You will receive a call on {`${location?.phone}`}.
                Please enter below the mentioned code in your phone when on call. 
                Please keep your the phone number to be verified close & then click the verify button below.
                </span>
                <p className="text-center text-base font-bold tracking-[.35em] pt-2 pb-1">{verificationCode}</p>
            </div>}
            {renderStatusButton(location?.isPhoneVerified)}
        </div>
    )
}

export default PhoneNumberVerificationComponent