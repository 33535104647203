import {AnyAction} from "redux";
import {
    PHYSICIAN_CLEAR_REFER_STATUS,
    PHYSICIAN_DOWNLOAD_BILLING_REPORT_SUCCESS,
    PHYSICIAN_DOWNLOAD_PATIENT_RESPONSES_SUCCESS,
    PHYSICIAN_FETCH_ALL_THERAPISTS,
    PHYSICIAN_FETCH_ALL_THERAPISTS_FAILED,
    PHYSICIAN_FETCH_ALL_THERAPISTS_IN_PROGRESS,
    PHYSICIAN_FETCH_ALL_THERAPISTS_SUCCESS,
    PHYSICIAN_FETCH_BILLING_PATIENTS_FAILED,
    PHYSICIAN_FETCH_BILLING_PATIENTS_IN_PROGRESS,
    PHYSICIAN_FETCH_BILLING_PATIENTS_SUCCESS,
    PHYSICIAN_FETCH_COCM_PATIENTS,
    PHYSICIAN_FETCH_COCM_PATIENTS_FAILED,
    PHYSICIAN_FETCH_COCM_PATIENTS_IN_PROGRESS, PHYSICIAN_FETCH_COCM_PATIENTS_SUCCESS,
    PHYSICIAN_FETCH_PATIENT,
    PHYSICIAN_FETCH_PATIENT_FAILED,
    PHYSICIAN_FETCH_PATIENT_IN_PROGRESS,
    PHYSICIAN_FETCH_PATIENT_Q_AND_A,
    PHYSICIAN_FETCH_PATIENT_Q_AND_A_FAILED,
    PHYSICIAN_FETCH_PATIENT_Q_AND_A_IN_PROGRESS,
    PHYSICIAN_FETCH_PATIENT_Q_AND_A_SUCCESS,
    PHYSICIAN_FETCH_PATIENT_SUCCESS,
    PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY,
    PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY_FAILED,
    PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY_IN_PROGRESS,
    PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY_SUCCESS,
    PHYSICIAN_FETCH_PATIENTS,
    PHYSICIAN_FETCH_PATIENTS_FAILED,
    PHYSICIAN_FETCH_PATIENTS_IN_PROGRESS,
    PHYSICIAN_FETCH_PATIENTS_SUCCESS,
    PHYSICIAN_FETCH_REFERENCE_NOTES,
    PHYSICIAN_FETCH_REFERENCE_NOTES_FAILED,
    PHYSICIAN_FETCH_REFERENCE_NOTES_IN_PROGRESS,
    PHYSICIAN_FETCH_REFERENCE_NOTES_SUCCESS,
    PHYSICIAN_FETCH_REFERRED_PATIENTS,
    PHYSICIAN_FETCH_REFERRED_PATIENTS_FAILED,
    PHYSICIAN_FETCH_REFERRED_PATIENTS_IN_PROGRESS,
    PHYSICIAN_FETCH_REFERRED_PATIENTS_SUCCESS,
    PHYSICIAN_REMOVE_BILLING_REPORT_DOWNLOAD_LINK,
    PHYSICIAN_REMOVE_PATIENT_RESPONSE_DOWNLOAD_LINK,
    PHYSICIAN_SAVE_REFER_NOTES_FAILED,
    PHYSICIAN_SAVE_REFER_NOTES_IN_PROGRESS,
    PHYSICIAN_SAVE_REFER_NOTES_SUCCESS,
    PHYSICIAN_SAVE_SCHEDULE_FAILED,
    PHYSICIAN_SAVE_SCHEDULE_IN_PROGRESS,
    PHYSICIAN_SAVE_SCHEDULE_SUCCESS,
    PHYSICIAN_SAVE_SELECTED_THEARAPIST,
    DOWNLOAD_PATIENT_SUMMARY,
    DOWNLOAD_PATIENT_SUMMARY_SUCCESS,
    REMOVE_PATIENT_SUMMARY_DOWNLOAD_LINK,
    REMOVE_PATIENT_SESSION_NOTES_DOWNLOAD_LINK,
    DOWNLOAD_PATIENT_SESSION_NOTES_SUCCESS,
    PHYSICIAN_FETCH_ICD_CODES,
    PHYSICIAN_FETCH_ICD_CODES_FAILED,
    PHYSICIAN_FETCH_ICD_CODES_IN_PROGRESS,
    PHYSICIAN_FETCH_ICD_CODES_SUCCESS,
    SET_SCREENER_SESSION_ID,
    CLEAR_SCREENED_DATES
} from "../../actions/physician/patients.action";
import {
    IBillingPatient, ICoCMPatient,
    IPatient, IPatientIndexItem, IPatientListItem,
    IPhysicianNotes,
    IProspect,
    IReportReference,
    IScreenerQuestionAnswer,
    ISummary,
    ITherapists,
    ScreenedDates
} from "../../../models";
import {SIGN_OUT_ACCOUNT} from "../../actions/common";
import { CLEAR_SCREENER_QUESTIONS } from "../../actions/counsellor/patients.action";

export interface PatientsParams {
    patients: IPatientListItem[]
    cocmPatients: ICoCMPatient[]
    patient?: IPatient
    fetchCoCMPatientsInProgress?: boolean
    fetchPatientsInProgress?: boolean
    fetchPatientInProgress?: boolean
    fetchPatientQAndAInProgress?: boolean
    screenerQuestionAnswers?: {[key: string] : IScreenerQuestionAnswer[] }
    link?: string
    fileName?: string
    billingReportExportLink?: string
    billingReportFileName?: string
    summary?: ISummary
    screenedDates?: ScreenedDates[]
    recordsCount?: number
    cocmPatientRecordsCount?: number
    referStatus?: string
    saveReferNotesInProgress?: boolean
    sideBarTab?: string
    referredPatients: IPatient[]
    referredRecordsCount?: number
    physicianReportReference?: IReportReference
    physicianNotes?: IPhysicianNotes | null
    fetchReferenceNotesInProgress?: boolean
    therapists?: ITherapists[]
    fetchTherapistsInProgress? : boolean
    prospect?: IProspect
    saveScheduleInProgress?: boolean
    selectedTherapist?: string | null
    billingPatients: IBillingPatient[]
    fetchBillingPatientsInProgress?: boolean
    billingRecordsCount?: number
    patientSummaryExportLink?: string
    patientSummaryFileName?: string
    patientSessionNotesExportLink?: string
    patientSessionNotesFileName?: string,
    icdCodes?: string
    fetchICDCodesError?: string
    screenerSessionId?: string
}

const initialState: PatientsParams = {
    patients: [],
    cocmPatients: [],
    referredPatients: [],
    billingPatients: [],
    patient: undefined,
    screenerQuestionAnswers: undefined,
    link: undefined,
    billingReportExportLink: undefined,
    billingReportFileName: undefined,
    fetchPatientInProgress: undefined,
    fetchPatientQAndAInProgress: undefined,
    fetchPatientsInProgress: undefined,
    fileName: undefined,
    recordsCount: undefined,
    cocmPatientRecordsCount: undefined,
    referredRecordsCount: undefined,
    referStatus: undefined,
    saveReferNotesInProgress: undefined,
    sideBarTab: undefined,
    physicianReportReference: undefined,
    physicianNotes: undefined,
    fetchReferenceNotesInProgress: undefined,
    fetchTherapistsInProgress: undefined,
    therapists: undefined,
    saveScheduleInProgress: undefined,
    prospect: undefined,
    selectedTherapist: undefined,
    fetchBillingPatientsInProgress: undefined,
    billingRecordsCount: undefined,
    patientSummaryExportLink: undefined,
    patientSummaryFileName: undefined,
    patientSessionNotesExportLink: undefined,
    patientSessionNotesFileName: undefined,
    icdCodes: undefined,
    fetchICDCodesError: undefined,
    screenedDates: undefined,
    screenerSessionId: undefined
}

const PatientsReducer = (state: PatientsParams = initialState, action: AnyAction) : PatientsParams => {
    switch (action.type) {
        case PHYSICIAN_FETCH_PATIENTS:
            state = {...state, patients: [], fetchPatientsInProgress: undefined, recordsCount: 0, sideBarTab: ''}
            return state
        case PHYSICIAN_FETCH_PATIENTS_FAILED:
            state = {...state, fetchPatientsInProgress: false}
            return state
        case PHYSICIAN_FETCH_PATIENTS_IN_PROGRESS:
            state = {...state, fetchPatientsInProgress: true}
            return state
        case PHYSICIAN_FETCH_PATIENTS_SUCCESS:
            state = {...state, patients: action.payload.patients, patient: undefined, fetchPatientsInProgress: false, recordsCount: action.payload.recordsCount, sideBarTab: action.payload.sideBarTab}
            return state

        case PHYSICIAN_FETCH_COCM_PATIENTS:
            state = {...state, cocmPatients: [], fetchCoCMPatientsInProgress: undefined, cocmPatientRecordsCount: 0, sideBarTab: ''}
            return state
        case PHYSICIAN_FETCH_COCM_PATIENTS_FAILED:
            state = {...state, fetchCoCMPatientsInProgress: false}
            return state
        case PHYSICIAN_FETCH_COCM_PATIENTS_IN_PROGRESS:
            state = {...state, fetchCoCMPatientsInProgress: true}
            return state
        case PHYSICIAN_FETCH_COCM_PATIENTS_SUCCESS:
            state = {...state, cocmPatients: action.payload.patients, fetchCoCMPatientsInProgress: false, cocmPatientRecordsCount: action.payload.recordsCount, sideBarTab: action.payload.sideBarTab}
            return state

        case PHYSICIAN_FETCH_PATIENT:
            state = {...state, patient: undefined, fetchPatientInProgress: undefined, link: undefined}
            return state
        case PHYSICIAN_FETCH_PATIENT_IN_PROGRESS:
            state = {...state, fetchPatientInProgress: true, link: undefined}
            return state
        case PHYSICIAN_FETCH_PATIENT_FAILED:
            state = {...state, fetchPatientInProgress: false, link: undefined}
            return state
        case PHYSICIAN_FETCH_PATIENT_SUCCESS:
            state = {...state, patient: action.payload.patient, fetchPatientInProgress: false}
            return state

        case PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY:
            state = {...state, patient: undefined , fetchPatientInProgress: undefined, link: undefined}
            return state
        case PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY_IN_PROGRESS:
            state = {...state, fetchPatientInProgress: true, link: undefined}
            return state
        case PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY_FAILED:
            state = {...state, fetchPatientInProgress: false, link: undefined}
            return state
        case PHYSICIAN_FETCH_PATIENT_WITH_SUMMARY_SUCCESS:
            state = {...state, 
                patient: action.payload.patient, 
                summary: action.payload.summary, 
                screenedDates: action.payload.screenedDates,
                fetchPatientInProgress: false
            }
            return state
        case CLEAR_SCREENED_DATES:
            state = {...state, screenedDates: []}
            return state

        case PHYSICIAN_FETCH_PATIENT_Q_AND_A:
            state = {...state, screenerQuestionAnswers: undefined, fetchPatientQAndAInProgress: undefined}
            return state
        case PHYSICIAN_FETCH_PATIENT_Q_AND_A_IN_PROGRESS:
            state = {...state, fetchPatientQAndAInProgress: true}
            return state
        case PHYSICIAN_FETCH_PATIENT_Q_AND_A_FAILED:
            state = {...state, fetchPatientQAndAInProgress: false}
            return state
        case PHYSICIAN_FETCH_PATIENT_Q_AND_A_SUCCESS:
            state = {...state, fetchPatientQAndAInProgress: false, screenerQuestionAnswers: {...state.screenerQuestionAnswers, [action.payload.screenerId]: action.payload.questionAnswers}}
            return state

        case PHYSICIAN_SAVE_REFER_NOTES_IN_PROGRESS:
            state = {...state, saveReferNotesInProgress: true}
            return state
        case PHYSICIAN_SAVE_REFER_NOTES_SUCCESS:
            state = { ...state, referStatus: action.payload.referNotes.referStatus, saveReferNotesInProgress: false }
            return state
        case PHYSICIAN_SAVE_REFER_NOTES_FAILED:
            state = { ...state, saveReferNotesInProgress: false}
            return state

        case SIGN_OUT_ACCOUNT:
            state = {...state, ...initialState}
            return state
        case PHYSICIAN_DOWNLOAD_PATIENT_RESPONSES_SUCCESS:
            state = {...state, link: action.payload.link, fileName: action.payload.fileName}
            return state
        case PHYSICIAN_REMOVE_PATIENT_RESPONSE_DOWNLOAD_LINK:
            state = { ...state, link: undefined, fileName: undefined }
            return state

        case PHYSICIAN_FETCH_REFERRED_PATIENTS:
            state = {...state, referredPatients: [], fetchPatientsInProgress: undefined, referredRecordsCount: 0, sideBarTab: ''}
            return state
        case PHYSICIAN_FETCH_REFERRED_PATIENTS_SUCCESS:
            state = {...state, referredPatients: action.payload.patients, patient: undefined, fetchPatientsInProgress: false, referredRecordsCount: action.payload.recordsCount, sideBarTab: action.payload.sideBarTab}
            return state
        case PHYSICIAN_FETCH_REFERRED_PATIENTS_IN_PROGRESS:
            state = {...state, fetchPatientsInProgress: true}
            return state
        case PHYSICIAN_FETCH_REFERRED_PATIENTS_FAILED:
            state = {...state, fetchPatientsInProgress: false}
            return state

        case PHYSICIAN_FETCH_REFERENCE_NOTES:
            state = {...state, physicianReportReference: undefined, physicianNotes: undefined , fetchReferenceNotesInProgress: undefined}
            return state
        case PHYSICIAN_FETCH_REFERENCE_NOTES_SUCCESS:
            state = {...state, physicianReportReference: action.payload.physicianReportReference, physicianNotes: action.payload.physicianNotes, fetchReferenceNotesInProgress: false}
            return state
        case PHYSICIAN_FETCH_REFERENCE_NOTES_IN_PROGRESS:
            state = {...state, fetchReferenceNotesInProgress: true}
            return state
        case PHYSICIAN_FETCH_REFERENCE_NOTES_FAILED:
            state = {...state, fetchReferenceNotesInProgress: false}
            return state

        case PHYSICIAN_FETCH_ALL_THERAPISTS:
            state = {...state, therapists: [], fetchTherapistsInProgress: undefined}
            return state
        case PHYSICIAN_FETCH_ALL_THERAPISTS_SUCCESS:
            state = {...state, therapists: action.payload.therapists, fetchTherapistsInProgress: false}
            return state
        case PHYSICIAN_FETCH_ALL_THERAPISTS_IN_PROGRESS:
            state = {...state, fetchTherapistsInProgress: true}
            return state
        case PHYSICIAN_FETCH_ALL_THERAPISTS_FAILED:
            state = {...state, fetchTherapistsInProgress: false}
            return state

        case PHYSICIAN_SAVE_SCHEDULE_IN_PROGRESS:
            state = {...state, saveScheduleInProgress: true}
            return state
        case PHYSICIAN_SAVE_SCHEDULE_SUCCESS:
            state = { ...state, prospect: action.payload.prospect, saveScheduleInProgress: false }
            return state
        case PHYSICIAN_SAVE_SCHEDULE_FAILED:
            state = { ...state, saveScheduleInProgress: false}
            return state

        case PHYSICIAN_FETCH_BILLING_PATIENTS_SUCCESS:
            state = {...state, billingPatients: action.payload.billingPatients, patient: undefined, fetchBillingPatientsInProgress: false, billingRecordsCount: action.payload.recordsCount, sideBarTab: action.payload.sideBarTab}
            return state
        case PHYSICIAN_FETCH_BILLING_PATIENTS_IN_PROGRESS:
            state = {...state, fetchBillingPatientsInProgress: true}
            return state
        case PHYSICIAN_FETCH_BILLING_PATIENTS_FAILED:
            state = { ...state, fetchBillingPatientsInProgress: false }
            return state

        case PHYSICIAN_DOWNLOAD_BILLING_REPORT_SUCCESS:
            state = { ...state, billingReportExportLink: action.payload.link, billingReportFileName: action.payload.fileName }
            return state
        case PHYSICIAN_REMOVE_BILLING_REPORT_DOWNLOAD_LINK:
            state = { ...state, billingReportExportLink: undefined, billingReportFileName: undefined }
            return state

        case DOWNLOAD_PATIENT_SUMMARY_SUCCESS:
            state = { ...state, patientSummaryExportLink: action.payload.presignedURL, patientSummaryFileName: action.payload.fileName }
            return state
        case REMOVE_PATIENT_SUMMARY_DOWNLOAD_LINK:
            state = { ...state, patientSummaryExportLink: undefined, patientSummaryFileName: undefined }
            return state

        case DOWNLOAD_PATIENT_SESSION_NOTES_SUCCESS:
            state = { ...state, patientSessionNotesExportLink: action.payload.presignedURL, patientSessionNotesFileName: action.payload.fileName }
            return state
        case REMOVE_PATIENT_SESSION_NOTES_DOWNLOAD_LINK:
            state = { ...state, patientSessionNotesExportLink: undefined, patientSessionNotesFileName: undefined }
            return state

        case PHYSICIAN_FETCH_ICD_CODES_SUCCESS:
            state = { ...state, icdCodes: action.payload.icdCodes }
            return state
        case PHYSICIAN_FETCH_ICD_CODES_FAILED:
            state = { ...state, fetchICDCodesError: action.payload.error }
            return state

        case PHYSICIAN_SAVE_SELECTED_THEARAPIST:
            state = {...state, selectedTherapist: action.payload.therapistId}
            return state

        case PHYSICIAN_CLEAR_REFER_STATUS:
            state = {...state, referStatus: undefined, selectedTherapist: undefined}
            return state

        case CLEAR_SCREENER_QUESTIONS:
            state = {...state, screenerQuestionAnswers: undefined}
            return state

        case SET_SCREENER_SESSION_ID:
            state = { ...state, screenerSessionId: action.payload.sessionId }
            return state

        default:
            return state;
    }
}

export default PatientsReducer
