import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import {ActiveStyle, InactiveStyle} from "./Styles";

export type SideBarButtonProps = {
    style: string
    component: string
    navigateTo: string
    iconRenderer?: any
    Icon?: React.ComponentType<any>
}

export function withActiveComponentTreatment<P extends SideBarButtonProps = SideBarButtonProps>(WrappedComponent: React.ComponentType<P>) {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

    const ComponentWithStyle = (props: Omit<P, "style">) => {
        const activeComponent = useSelector((state: StateParams) => state.navigation.activeComponent)
        const [style, setStyle] = useState<string>(InactiveStyle)

        useEffect(() => {
            if (activeComponent === props.component) {
                setStyle(ActiveStyle)
            } else {
                setStyle(InactiveStyle)
            }
        }, [activeComponent])

        return <WrappedComponent {...(props as P)} style={style} />;
    };

    ComponentWithStyle.displayName = `withActiveComponentTreatment(${displayName})`

    return ComponentWithStyle
}

export function withIconStyling<P extends SideBarButtonProps = SideBarButtonProps>(WrappedComponent: React.ComponentType<P>) {

    const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

    const ComponentWithStyle = (props: P) => {

        const renderWithIcon = (title: string) => {
            if(!props.Icon) return renderWithoutIcon(title)
            return <div className={"flex space-x-4 align-items-center"}>
                <props.Icon className="h-6 w-6 m-1 ml-4 self-center" />
                <span className="m-1 text-left">{title}</span>
            </div>
        }

        const renderWithoutIcon = (title: string) => {
            return <span className="self-center">{title}</span>
        }

        return <WrappedComponent {...(props as P)} iconRenderer={props.Icon ? renderWithIcon : renderWithoutIcon}/>;
    };

    ComponentWithStyle.displayName = `withIconStyling(${displayName})`

    return ComponentWithStyle
}
