import React from "react";
import {RoutePaths} from "../../../shared/Utils";
import PatientsButton from "../common/PatientsButton";
import {Bars3BottomLeftIcon, DocumentPlusIcon, UsersIcon, ShieldExclamationIcon} from "@heroicons/react/24/outline";
import { LuCalendarHeart } from "react-icons/lu"
import PotentialPatientsButton from "../common/PotentialPatientsButton";
import ReferredPatientsButton from "../common/ReferredPatientsButton";
import {useFlags} from "flagsmith/react";
import PatientMasterButton from "../common/PatientMasterButton";
import ComplianceButton from "../common/ComplianceButton";
import CareLinkButton from "../common/CareLinkButton";

const CareCoordinatorSideBar: React.FC = () => {

    const isComplianceEnabled = useFlags(['non_physician_compliance_for_cc'])?.non_physician_compliance_for_cc?.enabled;
    const isPatientMasterEnabled = useFlags(['cc_patient_master_side_menu_item'])?.cc_patient_master_side_menu_item?.enabled;

    function render() {
        return <div className={"flex flex-col"}>
            {isComplianceEnabled && <ComplianceButton
                component={"sw-list"}
                navigateTo={`${RoutePaths.careCoordinator.compliance.root}?page=1`}
                Icon={ShieldExclamationIcon} />}
            <PotentialPatientsButton
                component={"potential-patients"}
                navigateTo={`${RoutePaths.careCoordinator.potentialPatients.root}?page=1`}
                Icon={Bars3BottomLeftIcon}/>
            <ReferredPatientsButton
                component={"referred-patients"}
                navigateTo={`${RoutePaths.careCoordinator.referredPatient.root}?page=1`}
                Icon={DocumentPlusIcon}/>
            <PatientsButton
                component={"patients"}
                navigateTo={`${RoutePaths.careCoordinator.cocmPatient.root}?page=1`}
                Icon={UsersIcon}/>
            {isPatientMasterEnabled && <PatientMasterButton
                component={'patient-master'}
                navigateTo={`${RoutePaths.careCoordinator.patientMaster.root}?page=1`}
                Icon={UsersIcon}/>}
            <CareLinkButton 
                component={'send-screener-link'}
                navigateTo={`${RoutePaths.careCoordinator.sendScreenerLink}`}
                Icon={LuCalendarHeart}
            />
        </div>
    }

    return <>
        {render()}
    </>
}

export default CareCoordinatorSideBar;
