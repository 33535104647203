import React, { useEffect, useState } from "react";
import { PatientSessionTypes, Roles } from "../../models";
import { CounsellorPatientsDataView } from "./counsellor/CounsellorPatientsDataView";
import { PatientsDataView } from "./common/PatientsDataView";
import { PsychiatristPatientsDataView } from "./psychiatrist/PsychiatristPatientsDataView";
import { useDispatch, useSelector } from "react-redux";
import { StateParams } from "../../store/reducers";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { PatientFilters, searchPatients } from "../../store/actions/counsellor/patients.action";
import { RoutePaths } from "../../shared/Utils";
import { PageButtonV2 } from "../generic/Buttons";
import TableFilterPaginationV2 from "../generic/TableFilterPaginationV2";
import PaginationV2 from "../generic/PaginationV2";
import { Controller, useForm } from 'react-hook-form';
import Select from "react-select";


import { CareCoordinatorPatientsDataView } from "./care-coordinator/CareCoordinatorPatientsDataView";
import StatusTabs from "../generic/StatusTabs";
import PatientTable from "./PatientTable";
import { fetchPsychiatristSocialWorkers } from "../../store/actions/counsellor/prospects.action";

interface TabComponentProps {
    handleSelectedFilters: (status: string) => void,
    getTabClassName: (value: string) => string
}


const PsychiatristPatientTabs: React.FC<TabComponentProps> = ({ handleSelectedFilters, getTabClassName }) => {
    const TABS = [
        { label: "Intake Pending", value: "pendingIntake" },
        { label: "Treatment Pending", value: "pendingTreatment" },
        { label: "All", value: "all" }
    ]
    return <StatusTabs
        tabs={TABS}
        handleSelectedFilters={handleSelectedFilters}
        getTabClassName={getTabClassName}
    />
}

const PatientsDataViewByRole: { [key in Roles]?: PatientsDataView } = {
    Admin: new CounsellorPatientsDataView(),
    Counsellor: new CounsellorPatientsDataView(),
    Care_Coordinator: new CareCoordinatorPatientsDataView(),
    Psychiatrist: new PsychiatristPatientsDataView()
}

const PatientsFilterTabs: { [key in Roles]?: React.ComponentType<TabComponentProps> } = {
    Psychiatrist: PsychiatristPatientTabs
}

const TabManager: React.FC<{ role: Roles, setSelectedTab: (params: { page: number, tab: string | null }) => void }> = ({ role, setSelectedTab }) => {
    const defaultTab = "pendingIntake"
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState(searchParams.get("tab") || defaultTab)
    const navigate = useNavigate()
    useEffect(() => {
        if (role === Roles.Care_Coordinator) {
            navigate(`${RoutePaths.careCoordinator.referredPatient.root}?page=1&tab=${activeTab}`)
        } else {
            navigate(`${RoutePaths.collaborativeCare.prospects.root}?page=1&tab=${activeTab}`)
        }
    }, [role, activeTab]);

    const handleSelectedFilters = (status: string) => {
        setSelectedTab({ page: 1, tab: status });
        setActiveTab(status);
    }
    const getStatusTabClassName = (value: string) => {
        return activeTab === value ? "bg-sjLightOrange text-sjOrange" : "bg-sjWhite text-black"
    };

    const TabComponent = PatientsFilterTabs[role]
    return <div className={"flex justify-between pb-2"}>
        <div className="inline-flex rounded-md shadow-sm">
            {TabComponent && <TabComponent handleSelectedFilters={handleSelectedFilters} getTabClassName={getStatusTabClassName} />}
        </div>
    </div>
}

const DEFAULT_TAB_BY_ROLE: { [key in Roles]?: string } = {
    [Roles.Psychiatrist]: "pendingIntake"
}

const Patients: React.FC<{ role: string }> = ({ role }) => {
    const typedRole = role as keyof typeof Roles
    const userRole = Roles[typedRole]
    const patientsDataViewForRole = PatientsDataViewByRole[userRole];
    const defaultTab = DEFAULT_TAB_BY_ROLE[userRole]
    const dispatch = useDispatch()
    const patients = useSelector((state: StateParams) => state.patients.patients)
    const patientSearchInProgress = useSelector((state: StateParams) => state.patients.fetchPatientsInProgress)
    const patientsCount = useSelector((state: StateParams) => state.patients.patientsCount)
    const canceledAppointment = useSelector((state: StateParams) => state.appointments.canceledAppointment)
    const createdAppointment = useSelector((state: StateParams) => state.appointments.createdAppointment)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 1);
    const [currentTab, setCurrentTab] = useState(defaultTab);
    const [selectedTab, setSelectedTab] = useState<{ page: number, tab: string | null }>({ page: Number(searchParams.get("page")) || 1, tab: null });
    const [socialWorkerId, setSocialWorkerId] = useState<string | undefined>(undefined)
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [searchText, setSearchText] = useState('')
    const isPsychiatrist = Roles.Psychiatrist === role;
    const [sessionTypes, setSessionTypes] = useState([{
        key: PatientSessionTypes.PsychiatristNotes, value: 'Psychiatrist Notes'
    }])
    const { therapists } = useSelector((state: StateParams) => state.prospects)

    const therapistList = therapists && therapists?.map(therapist => ({
        value: therapist.id,
        label: `${therapist.firstName} ${therapist.lastName}`
    }));

    useEffect(() => {
        if (currentPage || recordsPerPage) {
            let searchFilters: PatientFilters = {}
            if (currentTab) {
                searchFilters = {
                    ...searchFilters,
                    [currentTab]: true,
                    socialWorkerId: socialWorkerId
                }
            }
            dispatch(searchPatients(currentPage, recordsPerPage, searchText, searchFilters))
        }
    }, [currentPage, currentTab, recordsPerPage, searchText, socialWorkerId]);

    useEffect(() => {
        if (isPsychiatrist) {
            dispatch(fetchPsychiatristSocialWorkers());
        }
    }, [])

    useEffect(() => {
        let searchFilters: PatientFilters = {}
        if (currentTab) {
            searchFilters = {
                ...searchFilters,
                [currentTab]: true,
                socialWorkerId: socialWorkerId
            }
        }
        if (createdAppointment || canceledAppointment) {
            dispatch(searchPatients(currentPage, recordsPerPage, searchText, searchFilters))
        }
    }, [currentPage, currentTab, recordsPerPage, searchText, createdAppointment, canceledAppointment, socialWorkerId])

    useEffect(() => {
        if (searchParams && searchParams.get("page")) {
            setCurrentPage(Number(searchParams.get("page")) || 1)
        }
        if (searchParams && searchParams.get("tab")) {
            setCurrentTab(searchParams.get("tab") || defaultTab)
        }
    }, [searchParams])

    useEffect(() => {
        const page = selectedTab.tab ? selectedTab.page : currentPage
        const tab = selectedTab.tab
        if (role === Roles.Care_Coordinator) {
            navigate(`${RoutePaths.careCoordinator.cocmPatient.root}?page=${page}${tab ? `&tab=${tab}` : ""}`)
        } else {
            navigate(`/${RoutePaths.collaborativeCare.patients.root}?page=${page}${tab ? `&tab=${tab}` : ""}`)
        }
    }, [currentPage, selectedTab]);

    return <div className="w-full p-4 h-full bg-[#F7F8FA]">
        <div className={"flex justify-between pb-4 items-center"}>
            <h1 className="text-xl">CoCM Patients - Active</h1>
            <div className="flex gap-x-2 justify-end items-center">
                {isPsychiatrist &&
                    <div className="w-72">
                        <Select
                            className="border-gray-400 rounded text-center"
                            options={therapistList}
                            value={therapistList?.find(option => option.value === socialWorkerId)}
                            onChange={(option: any) => { setSocialWorkerId(option?.value) }}
                            isSearchable={false}
                            isClearable={true}
                            placeholder={'Select Therapist'}
                        />

                    </div>
                }

                <TableFilterPaginationV2
                    setTableFilter={setSearchText}
                    totalRows={patientsCount || 0}
                    currentPageHandler={setCurrentPage}
                />

                {role && role === Roles.Psychiatrist && <div className="mt-2 flex relative">
                    <Link to={"create"} state={{
                        screen: 'CoCM Patients - Active',
                        sessionTypes,
                        defaultSessionType: 'Individual_Psychiatrist_Notes'
                    }}>
                        <PageButtonV2
                            className={"!bg-sjOrange !text-white !ml-2 px-[16px] py-[8px] !h-8 text-sm"}
                        >
                            <div className={"flex flex-row gap-x-2 items-center"}>
                                <span>Create Session</span>
                            </div>
                        </PageButtonV2>

                    </Link>
                </div>}
            </div>
        </div>
        <TabManager role={Roles[typedRole]} setSelectedTab={setSelectedTab} />
        {patientsDataViewForRole && <PatientTable dataView={patientsDataViewForRole} patients={patients} searchInProgress={patientSearchInProgress} />}
        {patients && patients.length > 0 &&
            <PaginationV2
                totalRows={patientsCount || 0}
                rowsPerPage={recordsPerPage}
                recordsPerPageHandler={setRecordsPerPage}
                currentPage={currentPage}
                currentPageHandler={setCurrentPage}
                showPages={false}
            />
        }
    </div>

}

export default Patients
