import React, {useState} from "react";
import {IProspectScreenerResponse, ISummary} from "../../models";
import * as _ from "lodash";
import {Accordion, AccordionBody, AccordionHeader} from "@material-tailwind/react";
import QuestionAnswer from "../generic/QuestionAnswer";

enum Conditions {
    "predominantly-inattentive-subtype",
    "predominantly-hyperactive-subtype",
    "oppositional-defiant-disorder",
    "adhd-conduct-disorder",
    "anxiety-depression",
    "impairment-in-performance"
}

type ConditionType = keyof typeof Conditions

const CONDITION_MAP : {[key in ConditionType]: string} = {
    "predominantly-inattentive-subtype": "Predominantly Inattentive Subtype",
    "predominantly-hyperactive-subtype": "Predominantly Hyperactive Subtype",
    "oppositional-defiant-disorder": "Oppositional Defiant Disorder",
    "adhd-conduct-disorder": "Conduct Disorder",
    "anxiety-depression": "Anxiety Depression",
    "impairment-in-performance": "Impairment In Performance"
}

const ADHDResults: React.FC<{screenerScoreMessage: string, questionAnswers: IProspectScreenerResponse[], summary: ISummary}> = ({questionAnswers, summary}) => {
    const screenerScores = summary
    const [open, setOpen] = useState(0);

    const handleOpen = (value: number) => {
        setOpen(open === value ? 0 : value);
    };
    const groupedByCondition : {[key: string]: IProspectScreenerResponse[]} = _.chain(questionAnswers)
        .groupBy((item: IProspectScreenerResponse) => {
            if(item.condition) {
                return item.condition
            } else {
                return "normal"
            }
        })
        .value()

    function renderGroupedQuestionAnswers(title: string | null, index: number, groupedQuestionAnswers: IProspectScreenerResponse[]) {
        return groupedQuestionAnswers && groupedQuestionAnswers.length > 0 ?
            (<Accordion className={"mb-2"} open={open === index} onClick={() => handleOpen(index)}>
                <AccordionHeader className={"bg-sjAdhd text-black rounded text-sm px-2"}><span dangerouslySetInnerHTML={{__html: title || ""}}/></AccordionHeader>
                <AccordionBody>
                    <div className={"flex flex-col font-medium"}>
                        {groupedQuestionAnswers.map((questionAnswer, index) => {
                            return <QuestionAnswer key={questionAnswer.questionId} questionAnswer={questionAnswer}
                                                   index={index}/>
                        })}
                    </div>
                </AccordionBody>
            </Accordion>)
            : null;
    }

    function renderQuestionAnswers(groupedQuestionAnswers: IProspectScreenerResponse[]) {
        return groupedQuestionAnswers && groupedQuestionAnswers.length > 0 ?
            (
                <div className={"flex flex-col"}>
                    {groupedQuestionAnswers.map((questionAnswer, index) => {
                        return <QuestionAnswer key={questionAnswer.questionId} questionAnswer={questionAnswer}
                                               index={index}/>
                    })}
                </div>
            )
            : null;
    }

    function evaluationQuestionAnswers(index: number) {
        const groupedQuestionAnswers = groupedByCondition.normal
        return renderQuestionAnswers(groupedQuestionAnswers);
    }

    function inattentiveSubtypeQuestionAnswers(index: number) {
        if("predominantly-inattentive-subtype" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition["predominantly-inattentive-subtype"]
            const title = CONDITION_MAP["predominantly-inattentive-subtype"]
            const styledTitle = `${screenerScores?.potentiallyPredominantlyInattentive === true ? "The patient has received a <span class='text-sjTestPositive'>Positive</span> score on the " : "The patient has received a <span class='text-sjTestNegative'>Negative</span> score on the " } ${title}`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }

    function hyperactiveSubtypeConditionQuestionAnswers(index: number) {
        if("predominantly-hyperactive-subtype" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition["predominantly-hyperactive-subtype"]
            const title = CONDITION_MAP["predominantly-hyperactive-subtype"]
            const styledTitle = `${screenerScores?.potentiallyPredominantlyHyperactive === true ? "The patient has received a <span class='text-sjTestPositive'>Positive</span> score on the " : "The patient has received a <span class='text-sjTestNegative'>Negative</span> score on the " } ${title}`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }
    function oppositionalDefiantConditionQuestionAnswers(index: number) {
        if("oppositional-defiant-disorder" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition["oppositional-defiant-disorder"]
            const title = CONDITION_MAP["oppositional-defiant-disorder"]
            const styledTitle = `${screenerScores?.potenttiallyOppositionalDefiantDisorder === true ? "The patient has received a <span class='text-sjTestPositive'>Positive</span> score on the " : "The patient has received a <span class='text-sjTestNegative'>Negative</span> score on the " } ${title}`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }
    function conductDisorderConditionQuestionAnswers(index: number) {
        if("adhd-conduct-disorder" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition["adhd-conduct-disorder"]
            const title = CONDITION_MAP["adhd-conduct-disorder"]
            const styledTitle = `${screenerScores?.potentiallyAdhdConductDisorder === true ? "The patient has received a <span class='text-sjTestPositive'>Positive</span> score on the " : "The patient has received a <span class='text-sjTestNegative'>Negative</span> score on the "} ${title}`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }
    function anxietyDepressionConditionQuestionAnswers(index: number) {
        if("anxiety-depression" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition["anxiety-depression"]
            const title = CONDITION_MAP["anxiety-depression"]
            const styledTitle = `${screenerScores?.potentiallyAnxietyDepression === true ? "The patient has received a <span class='text-sjTestPositive'>Positive</span> score on the " : "The patient has received a <span class='text-sjTestNegative'>Negative</span> score on the "} ${title}`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }
    function impairmentInPerformanceConditionQuestionAnswers(index: number) {
        if("impairment-in-performance" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition["impairment-in-performance"]
            const title = CONDITION_MAP["impairment-in-performance"]
            const styledTitle = `${screenerScores?.potentiallyImpairmentInPerformance === true ? "The patient has received a <span class='text-sjTestPositive'>Positive</span> score on the " : "The patient has received a <span class='text-sjTestNegative'>Negative</span> score on the "} ${title}`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }

    return questionAnswers && questionAnswers.length > 0
        ? (<>
            { evaluationQuestionAnswers(1) }
            { inattentiveSubtypeQuestionAnswers(2) }
            { hyperactiveSubtypeConditionQuestionAnswers(3) }
            { oppositionalDefiantConditionQuestionAnswers(4) }
            { conductDisorderConditionQuestionAnswers(5) }
            { anxietyDepressionConditionQuestionAnswers(6) }
            { impairmentInPerformanceConditionQuestionAnswers(7) }
        </>)
        : null
}

export default ADHDResults