import {AnyAction} from "redux";
import {
    CANCEL_APPOINTMENT,
    CANCEL_APPOINTMENT_FAILED,
    CANCEL_APPOINTMENT_IN_PROGRESS,
    CANCEL_APPOINTMENT_SUCCESS,
    CLEAR_CREATE_APPOINTMENT_ERROR,
    CLEAR_CREATE_BACKDATED_APPOINTMENT_ERROR,
    CLEAR_SESSION_INFORMATION,
    CLEAR_SESSION_STATUS,
    CREATE_APPOINTMENT,
    CREATE_APPOINTMENT_FAILED,
    CREATE_APPOINTMENT_IN_PROGRESS,
    CREATE_APPOINTMENT_SUCCESS,
    CREATE_BACKDATED_APPOINTMENT,
    CREATE_BACKDATED_APPOINTMENT_FAILED,
    CREATE_BACKDATED_APPOINTMENT_SUCCESS,
    FETCH_APPOINTMENTS,
    FETCH_APPOINTMENTS_FAILED,
    FETCH_APPOINTMENTS_IN_PROGRESS,
    FETCH_APPOINTMENTS_SUCCESS,
    FETCH_SESSION,
    FETCH_SESSION_FAILED,
    FETCH_SESSION_SUCCESS,
    FETCH_THERAPISTS,
    FETCH_THERAPISTS_FAILED,
    FETCH_THERAPISTS_SUCCESS,
    PATCH_APPONTMENT_LIST_WITH_STATUS,
    PATCH_SESSION_STATUS,
    SEARCH_APPOINTMENTS,
    SEARCH_APPOINTMENTS_FAILED, SEARCH_APPOINTMENTS_IN_PROGRESS, SEARCH_APPOINTMENTS_SUCCESS,
    UPDATE_SESSION_ID,
    UPDATE_SESSION_INFORMATION,
    UPDATE_SESSION_STATUS_FAILED,
    UPDATE_SESSION_STATUS_SUCCESS
} from "../../actions/counsellor/appointments.action";
import {
    IAppointment,
    IAppointmentSession,
    IAppointmentWithProspect,
    IAppointmentWithTherapist,
    IBackdatedAppointmentCreateResponse,
    ITherapistsResponse,
    SessionTypes
} from "../../../models";
import {
    FETCH_PROSPECTS_SUCCESS
} from "../../actions/counsellor/prospects.action";
import {SIGN_OUT_ACCOUNT} from "../../actions/common";

export interface AppointmentsParams {
    appointments: IAppointmentWithProspect[]
    appointmentsCount: number
    session?: IAppointmentWithTherapist | null
    createdAppointment?: IAppointment
    canceledAppointment?: IAppointment
    fetchAppointmentsInProgress?: boolean
    fetchSessionInProgress?: boolean
    createAppointmentInProgress?: boolean
    createAppointmentError?: string
    cancelAppointmentInProgress?: boolean
    cancelAppointmentError?: string
    currentSessionType?: SessionTypes
    currentSessionsPatientId?: string
    sessionId?: string
    appointmentStatus?: IAppointment
    isAppointmentStatusUpdated?: boolean
    appointmentStatusError?: string
    therapists: ITherapistsResponse[]
    fetchTherapistsInProgress?: boolean
    backDatedAppointment?: IBackdatedAppointmentCreateResponse,
    createBackDatedAppointmentInProgress?: boolean,
    createBackDatedAppointmentError?: string
}

const initialState: AppointmentsParams = {
    appointments: [],
    appointmentsCount: 0,
    session: undefined,
    fetchSessionInProgress: undefined,
    createdAppointment: undefined,
    canceledAppointment: undefined,
    fetchAppointmentsInProgress: undefined,
    createAppointmentInProgress: undefined,
    createAppointmentError: undefined,
    currentSessionType: undefined,
    currentSessionsPatientId: undefined,
    sessionId: undefined,
    appointmentStatusError: undefined,
    therapists: [],
    fetchTherapistsInProgress: undefined,
    isAppointmentStatusUpdated: undefined,
    appointmentStatus: undefined,
    backDatedAppointment: undefined,
    createBackDatedAppointmentInProgress: undefined,
    createBackDatedAppointmentError: undefined
}

const AppointmentsReducer = (state: AppointmentsParams = initialState, action: AnyAction) : AppointmentsParams => {
    switch (action.type) {
        case FETCH_APPOINTMENTS:
            state = {...state, appointments: [], appointmentsCount: 0}
            return state
        case FETCH_APPOINTMENTS_FAILED:
            state = {...state, fetchAppointmentsInProgress: false}
            return state
        case FETCH_APPOINTMENTS_IN_PROGRESS:
            state = {...state, fetchAppointmentsInProgress: true}
            return state
        case FETCH_APPOINTMENTS_SUCCESS:
            state = {...state, appointments: action.payload.appointments, fetchAppointmentsInProgress: false}
            return state

        case SEARCH_APPOINTMENTS:
            state = {...state, appointments: []}
            return state
        case SEARCH_APPOINTMENTS_FAILED:
            state = {...state, fetchAppointmentsInProgress: false}
            return state
        case SEARCH_APPOINTMENTS_IN_PROGRESS:
            state = {...state, fetchAppointmentsInProgress: true}
            return state
        case SEARCH_APPOINTMENTS_SUCCESS:
            state = {...state, appointments: action.payload.appointments, appointmentsCount: action.payload.count, fetchAppointmentsInProgress: false}
            return state

        case FETCH_SESSION:
            state = { ...state, session: undefined, fetchSessionInProgress: true}
            return state
        case FETCH_SESSION_SUCCESS:
            state = { ...state, fetchSessionInProgress: false, session: action.payload.session }
            return state
        case FETCH_SESSION_FAILED:
            state = { ...state, fetchSessionInProgress: false}
            return state

        case CREATE_APPOINTMENT:
            state = {...state, createdAppointment: undefined, createAppointmentError: undefined}
            return state
        case CREATE_APPOINTMENT_FAILED:
            state = {...state, createAppointmentInProgress: false, createAppointmentError: action.payload.error}
            return state
        case CREATE_APPOINTMENT_IN_PROGRESS:
            state = {...state, createAppointmentInProgress: true, createAppointmentError: undefined}
            return state
        case CREATE_APPOINTMENT_SUCCESS:
            state = {...state, createdAppointment: action.payload.appointment, createAppointmentInProgress: false,
                createAppointmentError: undefined}
            return state

        case CANCEL_APPOINTMENT:
            state = {...state, canceledAppointment: undefined, cancelAppointmentError: undefined}
            return state
        case CANCEL_APPOINTMENT_FAILED:
            state = {...state, cancelAppointmentInProgress: false, cancelAppointmentError: action.payload.error}
            return state
        case CANCEL_APPOINTMENT_IN_PROGRESS:
            state = {...state, cancelAppointmentInProgress: true, cancelAppointmentError: undefined}
            return state
        case CANCEL_APPOINTMENT_SUCCESS:
            state = {...state, canceledAppointment: action.payload.appointment, cancelAppointmentInProgress: false,
                cancelAppointmentError: undefined}
            return state

        case UPDATE_SESSION_INFORMATION:
            state = { ...state, currentSessionType: action.payload.sessionType , currentSessionsPatientId: action.payload.currentSessionsPatientId}
            return state
        case CLEAR_SESSION_INFORMATION:
            state = {...state, currentSessionType: undefined, currentSessionsPatientId: undefined}
            return state

        case UPDATE_SESSION_STATUS_FAILED:
            state = {...state, appointmentStatusError: action.payload.error, isAppointmentStatusUpdated: undefined}
            return state
        case UPDATE_SESSION_STATUS_SUCCESS:
            state = {...state, appointmentStatus: action.payload.appointment, createAppointmentError: undefined, isAppointmentStatusUpdated: true}
            return state
        case CLEAR_SESSION_STATUS:
            state = {...state, isAppointmentStatusUpdated: undefined }
            return state
        case PATCH_SESSION_STATUS:
            state = { ...state, session: state.session ? {
                ...state.session, status: action.payload.sessionStatus
            } : undefined }
            return state

        case PATCH_APPONTMENT_LIST_WITH_STATUS:
            state = {...state,
                appointments: state.appointments.map((appointment: IAppointmentWithProspect) => {
                    if (appointment.id === action.payload.appointmentId) {
                        return {...appointment, status: action.payload.status}
                    }
                    return appointment
                })
            }
            return state

        case FETCH_PROSPECTS_SUCCESS:
            state = {...state,
                canceledAppointment: undefined, cancelAppointmentInProgress: false, cancelAppointmentError: undefined,
                createdAppointment: undefined, createAppointmentInProgress: false, createAppointmentError: undefined
            }
            return state

        case SIGN_OUT_ACCOUNT:
            state = {...state, ...initialState}
            return state

        case UPDATE_SESSION_ID:
            state = { ...state, sessionId: action.payload.sessionId}
            return state

        case FETCH_THERAPISTS:
            state = { ...state, therapists: [], fetchTherapistsInProgress: true }
            return state
        case FETCH_THERAPISTS_SUCCESS:
            state = { ...state, fetchTherapistsInProgress: false, therapists: action.payload.therapists }
            return state
        case FETCH_THERAPISTS_FAILED:
            state = { ...state, fetchTherapistsInProgress: false }
            return state

        case CLEAR_CREATE_APPOINTMENT_ERROR:
            state = { ...state, createAppointmentError: undefined }
            return state

        case CREATE_BACKDATED_APPOINTMENT:
            state = {
                ...state,
                createBackDatedAppointmentInProgress: true,
                backDatedAppointment: undefined,
                createBackDatedAppointmentError: undefined
            }
            return state;

        case CREATE_BACKDATED_APPOINTMENT_FAILED:
            state = {
                ...state,
                createBackDatedAppointmentInProgress: false,
                createBackDatedAppointmentError: action.payload.error
            }
            return state;

        case CREATE_BACKDATED_APPOINTMENT_SUCCESS:
            state = {
                ...state,
                createBackDatedAppointmentInProgress: false,
                backDatedAppointment: action.payload.appointment
            }
            return state;

        case CLEAR_CREATE_BACKDATED_APPOINTMENT_ERROR:
            state = {
                ...state,
                createBackDatedAppointmentError: undefined
            }
            return state;

        default:
            return state;
    }
}

export default AppointmentsReducer
