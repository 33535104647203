import React, {useCallback, useEffect, useState} from 'react'
import sjCircle from "../../assets/images/loginBackground/sj-circle.png";
import {batch, useDispatch, useSelector} from "react-redux";
import {checkIfEmailExists, clearEmailExists} from "../../store/actions/counsellor/account.action";
import {StateParams} from "../../store/reducers";
import {CredentialResponse, GoogleLogin, GoogleOAuthProvider} from '@react-oauth/google';
import jwtDecode from "jwt-decode";
import {homeContentReady} from "../../store/actions/counsellor/navigation.actions";
import {MixpanelContext} from "../../analytics/Mixpanel";
import {LOGIN_FAILED, LOGIN_REQUEST, LOGIN_SUCCESS} from "../../analytics/Events";
import {RoutePaths} from "../../shared/Utils";
import {useNavigate} from "react-router";

const CLIENT_ID = String(process.env.REACT_APP_GOOGLE_CLIENT_ID);

const LoginUsingGoogle = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const analytics = React.useContext(MixpanelContext);
  const [formErrors, setFormErrors] = useState<string[]>()
  let [profile, setProfile] = useState<any>()
  const { token: jwt , id, role, checkIfEmailExistsError } = useSelector((state: StateParams) => state.practitionerAccount)

  useEffect(() => {
    dispatch(clearEmailExists())
  }, [])

  useEffect(() => {
    if (checkIfEmailExistsError && profile) {
      const errors: string[] = ['Please reach out to your Smalljoys correspondent to ensure you are onboarded'];
      setFormErrors(errors)
      analytics?.track(LOGIN_FAILED, {email: profile.email, error: checkIfEmailExistsError})
    } else if(jwt) {
      analytics?.track(LOGIN_SUCCESS, {id: id, type: role})
      analytics?.identify(id)
      setFormErrors([])
      dispatch(homeContentReady())
    }
  }, [jwt, id, role, profile])

  const onSuccess = useCallback((res: CredentialResponse) => {
    console.log('Google Oauth response', res)
    if(!res.credential) {
      console.log('Login success without credentials with Google');
      return
    }
    const profileResponse = jwtDecode<any>(res.credential)
    console.log('Decoded response', profileResponse)
    setProfile(profileResponse)
    profile = profileResponse
    batch(() => {
      dispatch(clearEmailExists())
      if(profile) {
        analytics?.track(LOGIN_REQUEST, {email: profile.email})
        dispatch(checkIfEmailExists({ email: profile.email }))
      }
    })
  }, [])

  const onFailure = useCallback(() => {
    console.log('Login failed with Google');
  }, [])

  const cancelLogin = useCallback(() => {
    navigate(RoutePaths.app.root)
  }, [navigate])

  return (
  <GoogleOAuthProvider clientId={CLIENT_ID}>
    <section className="xs:pt-28 md:pt-2
          0 px-3 border-b xs:h-auto md:h-screen">
        <div className="container mx-auto flex flex-col items-center">
          <div className='w-full h-32 flex items-center justify-center'>
            <p className="md:text-3xl text-sjOrange font-semibold">Welcome to Small Joys</p>
          </div>
          <div className='w-full h-32 flex items-center justify-center'>
            <img src={sjCircle} className='h-[200px]' />
          </div>
        </div>
      <div className="py-12">
        <div className="mt-8 w-full">
          <div className="grid grid-cols-3 gap-6">
            <div></div>
            <div className="flex justify-center items-center">
              <GoogleLogin
                  onSuccess={onSuccess}
                  onError={onFailure}
                  useOneTap
              />
            </div>
            <div></div>
          </div>
          <div className="mt-10 grid grid-cols-3 gap-6"></div>
          <div className="mt-10 grid grid-cols-3 gap-6">
            <div></div>
            <div className="flex flex-col justify-center items-center">
              {formErrors?.map((error, index) => {
                return <div className="text-red-500" key={"error-" + index}>{error}</div>
              })}
            </div>
            <div></div>
          </div>
        </div>
        <div className="mt-10 grid grid-cols-3 gap-6">
          <div>
          </div>
          <div className="flex justify-center items-center justify-evenly">
            <button
                data-test="login-proceed"
                className="bg-sjGrey text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={cancelLogin}>
              Cancel
            </button>
          </div>
          <div></div>
        </div>
      </div>
    </section>
  </GoogleOAuthProvider>
  )
}

export default LoginUsingGoogle
