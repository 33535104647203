import React from "react";
import {Link} from "react-router-dom";

export const DetailsLinkButton: React.FC<{value: string}> = ({ value }) => {
    return (
        <Link to={`${value}`}>
            <span className="rounded-l-md border-sjOrange text-sjLink" >
                Details
            </span>
        </Link>
    );
}