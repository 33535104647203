import React, {useMemo, useState} from 'react'
import {IBillingPatient, IcdCodesRequest, VisitTypeVerbiage} from '../../models';
import {DateTime} from 'luxon';
import TablePagination from '../generic/TablePagination';
import {DownloadScreener} from '../generic/DownloadScreener';
import TablePaginationV2 from "../generic/TablePaginationV2";
import { useFlags } from 'flagsmith/react';
import IcdCodesModal from './IcdCodesModal';
import ConfirmationModal from '../clinical-notes/ConfirmationModal';

interface PatientsProps {
    patients: IBillingPatient[]
    searchInProgress: boolean | undefined
}

const BhScreening : React.FC<PatientsProps> = ({patients, searchInProgress}) => {
    const canViewScreenerType = useFlags(['cognitive_screening'])?.cognitive_screening?.enabled;
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedPatient, setSelectedPatient] = useState<IcdCodesRequest | null>(null)
    
    const columnsWithoutScreeningType = useMemo(
        () => [
            { header: "Patient Name", accessorKey: "name" },
            { header: "Date of birth", accessorKey: "dob" },
            { header: "Phone number", accessorKey: "phone" },
            { header: "Date of Screening", accessorKey: "screeningDate" },
            { header: "CPT code", accessorKey: "cptCode" },
            {
                header: "ICD code", accessorKey: "icdCodes", cell: (props: any) => 
                <p className="underline text-sjLink hover:text-blue-800 hover:cursor-pointer visited:text-purple-600" onClick={() => {
                    setIsModalOpen(true);
                    setSelectedPatient(props.getValue());
                }}>View ICD Code</p>
            },
            { header: "Units", accessorKey: "units" },
            { header: "Download screener", accessorKey: "downloadScreener", cell: (props: any) => 
                <DownloadScreener value={props.getValue()?.id} cptCode={props.getValue()?.cptCode}
                sessionId={props.getValue()?.sessionId}/> 
            },
        ],
        []
    )
    
    const columnsWithScreeningType = useMemo(
        () => [
            { header: "Patient Name", accessorKey: "name" },
            { header: "Date of birth", accessorKey: "dob" },
            { header: "Phone number", accessorKey: "phone" },
            { header: "Screener Type", accessorKey: "visitType" },
            { header: "Date of Screening", accessorKey: "screeningDate" },
            { header: "CPT code", accessorKey: "cptCode" },
            {
                header: "ICD code", accessorKey: "icdCodes", cell: (props: any) => 
                <p className="underline text-sjLink hover:text-blue-800 hover:cursor-pointer visited:text-purple-600" onClick={() => {
                    setIsModalOpen(true);
                    setSelectedPatient(props.getValue());
                }}>View ICD Code</p>
            },
            { header: "Units", accessorKey: "units" },
            { header: "Download screener", accessorKey: "downloadScreener", cell: (props: any) => 
                <DownloadScreener value={props.getValue()?.id} cptCode={props.getValue()?.cptCode}
                sessionId={props.getValue()?.sessionId}/> 
            },
        ],
        []
    )

    const data = patients ? patients.map((patient) => {
        const screeningDate = DateTime.fromISO(patient.createdAt.toString(), {zone: 'utc'}).toFormat('MM/dd/yyyy');
        const dob = DateTime.fromISO(patient.dob.toString(), {zone: "utc"}).toFormat('MM/dd/yyyy')
            return {
                id: patient.id,
                name: `${patient.lastName}, ${patient.firstName}`,
                dob: dob,
                phone: patient.phoneNumber,
                visitType: VisitTypeVerbiage[patient.visitType[0]],
                screeningDate: screeningDate,
                cptCode: patient.cptCode,
                icdCodes: {
                    userId: patient.id,
                    screeningDate: DateTime.fromISO(patient.createdAt.toString(), {zone: 'utc'}).toFormat('yyyy-MM-dd'),
                    sessionId: patient.sessionId,
                    units: patient.unit,
                    cptCode: patient.cptCode,
                    patientName: `${patient.firstName} ${patient.lastName}`,
                    dob: dob,
                },
                downloadScreener: {
                    id: patient.id,
                    cptCode: patient.cptCode,
                    screeningDate: DateTime.fromISO(patient.createdAt.toString(), {zone: 'utc'}).toFormat('yyyy-MM-dd'),
                    sessionId: patient.sessionId
                },
                units: patient.unit
            }
        }) : []

        const columns = canViewScreenerType ? columnsWithScreeningType : columnsWithoutScreeningType

    return (
        <div className='mt-4 w-full'>
            <TablePaginationV2 columns={columns} data={data} searchInProgress={searchInProgress}/>
            <IcdCodesModal value={selectedPatient} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
        </div>
    )
}

export default BhScreening;
