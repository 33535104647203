import { Inputs } from "../../components/clinical-notes/intake";

export const socialDeterminantsOfHealth: { [K in keyof Partial<Inputs>]: { question: string; options: { label: string; value: string; }[] } } =
{
    religion: {
        question: '1. What is your religion?',
        options: [
            {label: 'No religion', value: 'noReligion'}, {label: 'Buddhist', value: 'buddhist'},
            {label: 'Christian (All denominations)', value: 'christian'}, {label: 'Hindu', value: 'hindu'},
            {label: 'Jewish', value: 'jewish'}, {label: 'Muslim', value: 'muslim'},
            {label: 'Don\'t wish to disclose', value: 'skip'}, {label: 'Other', value: 'religionOther'},
        ]
    },
    ethnicity: {
        question: '2. Which race or ethnicity best describes you?',
        options: [
            {label: 'American Indian or Alaskan Native', value: 'americanIndian'}, {label: 'Asian or Pacific Islander', value: 'asianPacific'},
            {label: 'Black or African American', value: 'blackAfricanAmerican'}, {label: 'Hispanic', value: 'hispanic'},
            {label: 'White or Caucasian', value: 'whiteCaucasian'}, {label: 'Don\'t wish to disclose', value: 'ethnicityDisclose'},
            {label: 'Multiple ethnicity / Other', value: 'ethnicityOther'}
        ]
    },
    sourceOfIncome: {
        question: '3. At any point in the past 2 years, has season or migrant farm work been your or your family’s main source of income?',
        options: [
            {label: 'Yes', value: 'Yes'},{label: 'No', value: 'No'},
            {label: 'I choose not to answer this question', value: 'skip'},
        ]
    },
    retiredMilitary: {
        question: '4. Have you been discharged from the armed forces of the United States?',
        options: [
            {label: 'Yes', value: 'Yes'},{label: 'No', value: 'No'},
            {label: 'I choose not to answer this question', value: 'skip'},
        ]
    },
    housingSituationToday: {
        question: '7. What is your housing situation today?',
        options: [
            { label: 'I have housing', value: 'ownHouse' }, { label: 'I do not have housing (staying with others, in a hotel, in a shelter, living outside on the street, on a beach, in a car, or in a park)', value: 'notHaveHouse' },
            { label: 'I choose not to answer this question', value: 'skip' }
        ]
    },
    losingHousing: {
        question: '8. Are you worried about losing your housing?',
        options: [
            {label: 'Yes', value: 'Yes'},{label: 'No', value: 'No'},
            {label: 'I choose not to answer this question', value: 'skip'}
        ]
    },
    education: {
        question: '10. What is the highest level of school that you have finished?',
        options: [
            {label: 'Less than high school degree', value: 'belowHighSchool'},{label: 'High school diploma or GED', value: 'aboveHighSchool'},
            {label: 'More than high school', value: 'graduate'},{label: 'I choose not to answer this question', value: 'skip'}
        ]
    },
    currentEmployment: {
        question: '11. What is your current work situation?',
        options: [
            {label: 'Unemployed', value: 'unemployed'},{label: 'Part-time or temporary work', value: 'partTime'},
            {label: 'Full-time work', value: 'fullTime'},{label: 'I choose not to answer this question', value: 'currentWorkNotDisclose'},
            {label: 'I choose not to answer this question', value: 'skip'},
        ]
    },
    insurance: {
        question: '12. What is your main insurance?',
        options: [
            {label: 'None/uninsured', value: 'uninsured'},{label: 'Medicaid', value: 'medicaid'},
            {label: 'CHIP Medicaid', value: 'chipMedicaid'},{label: 'Medicare', value: 'medicare'},
            {label: 'Other public insurance (not CHIP)', value: 'publicInsuranceNotChip'},{label: 'Other Public Insurance (CHIP)', value: 'publicInsuranceChip'},
            {label: 'Private Insurance', value: 'privateInsurance'}
        ]
    },
    food: {
        question: 'Food',
        options: [
            {label: 'Yes', value: 'Yes'},{label: 'No', value: 'No'},
            {label: 'I choose not to answer this question', value: 'skip'},
        ]
    },
    clothing: {
        question: 'Clothing',
        options: [
            {label: 'Yes', value: 'Yes'},{label: 'No', value: 'No'},
            {label: 'I choose not to answer this question', value: 'skip'},
        ]
    },
    utilities: {
        question: 'Utilities',
        options: [
            {label: 'Yes', value: 'Yes'},{label: 'No', value: 'No'},
            {label: 'I choose not to answer this question', value: 'skip'},
        ]
    },
    childCare: {
        question: 'Child Care',
        options: [
            {label: 'Yes', value: 'Yes'},{label: 'No', value: 'No'},
        ]
    },
    healthCare: {
        question: 'Medicine or Any Health Care (Medical, Dental, Mental Health, Vision)',
        options: [
            {label: 'Yes', value: 'Yes'},{label: 'No', value: 'No'},
            {label: 'I choose not to answer this question', value: 'skip'},
        ]
    },
    phone: {
        question: 'Phone',
        options: [
            {label: 'Yes', value: 'Yes'},{label: 'No', value: 'No'},
            {label: 'I choose not to answer this question', value: 'skip'},
        ]
    },
    transportation: {
        question: '15. Has lack of transportation kept you from medical appointments, meetings, work, or from getting things needed for daily living? Check all that apply',
        options: [
            {label: 'Yes, it has kept me from medical appointments', value: 'yesMedical'},{label: 'Yes, it has kept me from non-medical appointments meetings, appointments, work, or from getting that I need', value: 'medical'},
            {label: 'No', value: 'No'},{label: 'I choose not to answer this question', value: 'skip'},
        ]
    },
    social: {
        question: '16. How often do you see or talk to people that you care about and feel close to? (For example: talking to friends on the phone, visiting friends or family, going to church or club meetings)',
        options: [
            {label: 'Less than once a week', value: 'lessThan'}, {label: '1 or 2 times a week', value: '1or2'}, 
            {label: '3 to 5 times a week 5 or more times a week', value: '3to5'}, {label: '5 or more times a week', value: 'moreThan5'}, 
            {label: 'I choose not to answer this week question', value: 'skip'}
        ]
    },
    emotional: {
        question: '17. Stress is when someone feels tense, nervous, anxious, or can’t sleep at night because the',
        options: [
            {label: 'Not at all', value: 'notAtAll'}, {label: 'A little bit', value: 'littleBit'},
            {label: 'Somewhat', value: 'someWhat'}, {label: 'Quite a bit', value: 'quiteABit'},
            {label: 'Very much', value: 'veryMuch'}, {label: 'I choose not to answer this question', value: 'skip'},
        ]
    },
    pastYearSpentInJail: {
        question: '18. In the past year, have you spent more than 2 nights in a row in a jail, prison, detention center, or juvenile correctional facility?',
        options: [
            { label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }, { label: 'I choose not to answer this', value: 'skip' }
        ]
    },
    refugee: {
        question: '19. Are you a refugee?',
        options: [
            { label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }, { label: 'I choose not to answer this', value: 'skip' }
        ]
    },
    physicallyAndEmotionallySafe: {
        question: '20. Do you feel physically and emotionally safe where you currently live?. Do you feel physically and emotionally safe where you currently live?',
        options: [
            { label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' },
            { label: 'Unsure', value: 'unsure' }, { label: 'I choose not to answer this', value: 'skip' }
        ]
    },
    afraidOfYourPartner: {
        question: '21. In the past year, have you been afraid of your partner or ex-partner?',
        options: [
            { label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' },
            { label: 'Unsure', value: 'unsure' }, { label: 'I have not had a partner in the past year', value: 'noPartner' },
            { label: 'I choose not to answer this', value: 'skip' }
        ]
    },
}