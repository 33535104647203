import {IProspect, IReferredPatientsDetails} from "../../models";
import React, {useMemo} from "react";
import TablePaginationV2 from "../generic/TablePaginationV2";
import { ProspectsDataView } from "./common/ProspectsDataView";

interface ProspectTableProps {
    dataView: ProspectsDataView
    prospects: IReferredPatientsDetails[]
    searchInProgress: boolean | undefined
    activeTab: string
}

const ProspectTable: React.FC<ProspectTableProps> = ({dataView, prospects, searchInProgress, activeTab}) => {
    const statusFilters = activeTab === 'all' ? [] : [activeTab]
    const columns = useMemo( dataView.getColumns(), [] );
    dataView.setProspects(prospects)
    const data = dataView.getData()

    return <div>
        <TablePaginationV2 columns={columns} data={data} searchInProgress={searchInProgress} possibleColumnsToHide={{callStatus: statusFilters?.length ? false : true}}/>
    </div>
}

export default ProspectTable
