import React, {useEffect} from 'react'
import Avatar from 'react-avatar';
import {useDispatch, useSelector} from 'react-redux';
import top from '../../assets/images/providerProfile/top.png'
import {StateParams} from '../../store/reducers';
import {FiEdit, FiMail, FiMapPin, FiPhoneCall} from 'react-icons/fi'
import {useNavigate} from 'react-router-dom';
import {AiOutlineCheck, AiOutlineClose} from 'react-icons/ai';
import {updatePhoneNumber} from '../../store/actions/physician/account.action';
import PhoneInput, {Country} from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import _ from 'lodash';
import PhysicianLocationSelectionComponent from "../locations/PhysicianLocationSelectionComponent";

const ProviderDetails = () => {
    const { name, phone, email, address, defaultLocation } = useSelector((state: StateParams) => state.physicianAccount)
    const { role } = useSelector((state: StateParams) => state.account)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [changeDefaultLocation, setChangeDefaultLocation] = React.useState(false)
    const [canEdit, setCanEdit] = React.useState(false)
    const [phoneNumber, setPhoneNumber] = React.useState(phone)
    const countries: Country[] = ['US', 'IN']
    const [defaultAddress, setDefaultAddress] = React.useState("")

    useEffect(() => {
        if(defaultLocation) {
            const addressParts = [defaultLocation.addressLine, defaultLocation.city, defaultLocation.zipCode, defaultLocation.phone]
            const validAddressParts = _.filter(addressParts, part => !_.isUndefined(part) && !_.isNull(part))
            setDefaultAddress(validAddressParts.join(", "))
        } else {
            setDefaultAddress("")
        }
    }, [defaultLocation])

    function handlePhoneNumberSave() {
        if(phoneNumber) {
            dispatch(updatePhoneNumber({ phoneNumber }))
            setCanEdit(false)
        }
    }

    function renderPhoneNumberSaveAndCancel() {
        return <div className='flex flex-row'>
                    <div>
                        <AiOutlineCheck className="h-7 w-6 m-1" title='Save' cursor={'pointer'} color='green' onClick={handlePhoneNumberSave} />
                    </div>
                    <div>
                        <AiOutlineClose className="h-7 w-6 m-1" title='Cancel' cursor={'pointer'} color='red' onClick={() => {
                            setCanEdit(!canEdit)
                            setPhoneNumber(phone)
                        }} />
                    </div>
                </div>
    }
    function renderPhoneNumber() {
        return <>
                {!canEdit ?
                    <FiEdit className="h-7 w-6 m-1 text-sjGrey" aria-hidden="true" title='Edit' cursor={'pointer'} onClick={() => setCanEdit(!canEdit)} />
                    : <>{renderPhoneNumberSaveAndCancel()}</>
                }
            </>
    }
    return (
        <div className='mt-4 w-full'>
            <img className='w-full h-24' src={top} />
            <div className="mt-2 ml-4 flex flex-row">
                <Avatar
                    name={name}
                    size="80"
                    round={true}
                    color='#FFA200'
                />
                <div className='ml-2 mt-4'>
                    <p className='text-sjGrey text-sm font-bold'>Provider Name</p>
                    <p className='text-xl font-bold'>{name}</p>
                </div>
            </div>
            <div className='flex flex-row'>
    {/*            <div className='w-1/2 ml-4 mt-4 p-6 bg-sjInfo grid grid-cols-10'>*/}
    {/*                <FiPhoneCall className="h-7 w-6 m-1 text-sjGrey" aria-hidden="true" />*/}
    {/*                <div className='col-span-8'>*/}
    {/*                    {!canEdit ? <><p className='text-sjGrey text-xs'>Contact number</p>*/}
    {/*                        <p className='text-sm font-bold'>{phone}</p> </> :*/}
    {/*                        // <Input variant="static" label="Contact number" color='orange' value={phoneNumber}*/}
    {/*                        // onChange={handlePhoneNumberChange}/>*/}
    {/*                        <>*/}
    {/*                            <PhoneInput defaultCountry='US'*/}
    {/*                                countries={countries} placeholder="Enter Contact number" value={phoneNumber} onChange={setPhoneNumber} />*/}
    {/*                        </>*/}
    {/*}*/}
    {/*                </div>*/}
    {/*                {renderPhoneNumber()}*/}
    {/*            </div>*/}
                <div className='w-full ml-4 mt-4 p-6 bg-sjInfo flex flex-row'>
                    <FiMail className="h-7 w-6 m-1 text-sjGrey" aria-hidden="true" />
                    <div className='ml-4'>
                        <p className='text-sjGrey text-xs'>Email</p>
                        <p className='text-sm font-bold'>{email}</p>
                    </div>
                </div>
            </div>
            <div className='ml-4 mt-4 p-6 bg-sjInfo flex flex-row'>
                <FiMapPin className="h-7 w-6 m-1 text-sjGrey" aria-hidden="true" />
                <div className='ml-4'>
                    <p className='text-sjGrey text-xs'>Address</p>
                    <p className='text-sm font-bold'>{address}</p>
                </div>
            </div>
           {role === 'Physician' && <div className='ml-4 mt-4 p-6 bg-sjInfo flex flex-row'>
                <FiMapPin className="h-7 w-6 m-1 text-sjGrey" aria-hidden="true" />
                <div className='ml-4'>
                    <p className='text-sjGrey text-xs'>Default Location</p>
                    <p className='text-sm font-bold'>{defaultAddress}</p>
                    <span className='text-xs text-sjLink cursor-pointer' onClick={() => {setChangeDefaultLocation(true)}}>Change Default Location</span>
                </div>
            </div>}
            <hr className='mt-4 ml-4' />
            <div className='ml-4 mt-2 mb-4'>
                <p className='font-bold'>Password setup</p>
                <p className='mt-2 ml-1 text-sm'>Password*</p>
                <div className='w-52 p-1 border-2 rounded-md text-sm'>
                    <p className='pt-2 ml-3'>**********</p>
                </div>
                <div className='w-52 mt-1 flex justify-end'>
                    <span className='text-xs text-sjLink cursor-pointer' onClick={() => { navigate('/changePassword') }}>Change Password?</span>
                </div>
            </div>
            <PhysicianLocationSelectionComponent changeDefault={changeDefaultLocation}
                                                 onClose={ () => setChangeDefaultLocation(false)}
                                                 locationChanged={ () => setChangeDefaultLocation(false)} />
        </div>
    )
}

export default ProviderDetails;
