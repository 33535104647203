import React from 'react';
import {Link} from "react-router-dom";

export interface NotFoundComponentProps {
    backUrl?: string
}

const NotFoundComponent = (props: NotFoundComponentProps) => {
    const backUrl = props.backUrl || '/';
    return (
        <div className="container">
            <div className="text-center">
                {/* <img src={NotFound} alt={''} /> */}
                <Link to={backUrl}>
                    Back
                </Link>
            </div>

        </div>

    )
};

export default NotFoundComponent;