import React, {useMemo} from "react";
import {IPendingClinicalNote, SessionTypes} from "../../../models";
import TablePaginationV2 from "../../../components/generic/TablePaginationV2";
import {DateTime} from "luxon";
import ClinicalNotePendingSinceComponent from "../../../components/generic/ClinicalNotePendingSinceComponent";
import {handleJourneyRoutes} from "../../../shared/Utils";
import {Link} from "react-router-dom";
import {Button} from "../../../components/generic/Buttons";
import { useSelector } from "react-redux";
import { StateParams } from "../../../store/reducers";

interface IClinicalNotesPendingListComponentProps {
    notes: IPendingClinicalNote[] | undefined;
    currentPageHandler: Function;
    searchInProgress: boolean | undefined;
    activeTab: string;
}

const ClinicalNotesPendingListComponent = (props: IClinicalNotesPendingListComponentProps) => {
    const role = useSelector((state: StateParams) => state.account.role)
    const {notes, searchInProgress, activeTab} = props;
    const isCareCoordinator = role === 'Care_Coordinator'

    const data = notes ? notes.map((note) => {
        const dob = DateTime.fromISO(note.patientDob.toString(), {zone: 'utc'}).toFormat('MM/dd/yyyy');
        return {
            noteId: note.noteId,
            patientId: note.patientId,
            name: `${note?.patientFirstName} ${note?.patientLastName}`,
            dateOfBirth: dob,
            practice: note?.referredByPracticeName || "N/A",
            noteType: note?.noteType,
            noteCreatedAt: note?.noteCreatedAt,
            pendingSinceDays: note?.pendingSinceDays,
            socialWorker: `${note.therapistFirstName} ${note.therapistLastName}`
        }
    }) : [];

    const columns = useMemo(
        () => [
            {header: "Name", accessorKey: "name"},
            {header: "Date of birth", accessorKey: "dateOfBirth"},
            {header: "Practice", accessorKey: "practice"},
            {header: "Social Worker", accessorKey: "socialWorker"},
            {header: "Note Type", accessorKey: "noteType"},
            {
                header: "Pending Since", accessorKey: "pendingSince", cell: (props: any) => {
                    const {noteId, noteCreatedAt, pendingSinceDays} = props?.row?.original;
                    return <ClinicalNotePendingSinceComponent
                        noteId={noteId}
                        noteCreatedAt={noteCreatedAt}
                        pendingSinceDays={pendingSinceDays}/>
                }
            },
            {
                header: "", accessorKey: "note_details", cell: (props: any) => {
                    const { noteId, patientId, noteType } = props?.row?.original;
                    return (<Link to={handleJourneyRoutes(noteType as SessionTypes, noteId, patientId, role)}>
                            <Button className={"!bg-sjOrange !text-white !ml-2"}>
                                View Note
                            </Button>
                        </Link>)
                }
            }
        ],
        []);

    return (
        <div className='mt-4 w-full'>
            <TablePaginationV2
                columns={columns}
                data={data}
                searchInProgress={searchInProgress}
                possibleColumnsToHide={{noteType: activeTab === 'all' ? true : false, socialWorker: isCareCoordinator ? true : false, note_details: isCareCoordinator ? false : true}}
            />
        </div>
    )
}

export default ClinicalNotesPendingListComponent;
