import {useAsyncDebounce} from 'react-table'
import React, {useEffect} from "react"; // new
import {MagnifyingGlassIcon, XMarkIcon} from '@heroicons/react/20/solid'
import {useLocation} from 'react-router-dom';

interface TableFilterProps {
    searchClassNames?: string
    placeholder?: string
    setTableFilter: Function
    totalRows?: number
    currentPageHandler: Function
}

interface LocationState {
    sidebar: boolean
}

const TableFilterPaginationV2: React.FC<TableFilterProps> = ({ searchClassNames, setTableFilter, totalRows, currentPageHandler, placeholder = 'Search' }) => {

    const location = useLocation()
    const [value, setValue] = React.useState('')

    useEffect(()=>{
        const locationState: LocationState | null = location.state as LocationState
        if(locationState?.sidebar){
            setValue('')
            setTableFilter('')
        }
    },[location.state])

    const search = useAsyncDebounce(v => {
        setTableFilter(value || '')
        currentPageHandler(1)
    }, 200)

    const clearSearch = useAsyncDebounce(v => {
        setValue("")
        setTableFilter('')
        currentPageHandler(1)
    }, 200)

    const handleKeyPress = (e: any) => {
        if(e.key === 'Enter' || e.which === 13) {
            setTableFilter(value || '')
            currentPageHandler(1)
        }
    }

    return (
        <div>
            <div className="relative text-gray-400 focus-within:text-gray-600 block">
                {value.length === 0
                    ? <MagnifyingGlassIcon onClick={search} className="hover:cursor-pointer w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3" />
                    : <XMarkIcon onClick={clearSearch} className="hover:cursor-pointer w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3"/>
                }
                <input
                    type={"text"}
                    className={`rounded-md h-10 ${searchClassNames} border-gray-300 focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50`}
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value?.trim());
                    }}
                    onKeyPress={handleKeyPress}
                    placeholder={placeholder}
                />
            </div>
    </div>
    )
}

export default TableFilterPaginationV2
