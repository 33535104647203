import React from "react";
import {IProspect, ISummary} from "../../models";
import ProspectProfile from "../prospects/ProspectProfile";
import ScreenerResults from "../screeners/ScreenerResults";
import { DateOfVisit } from "./DateOfVisit";

interface PatientProps {
    patient: IProspect
    summary: ISummary
}

const Patient : React.FC<PatientProps> = ({patient, summary}) => {
    const [selectedIndex, setSelectedIndex] = React.useState<number>(0)
    return <>
        <ProspectProfile prospect={patient}/>
        <DateOfVisit patientWithSummary={patient} setSelectedIndex={setSelectedIndex}/>
        <ScreenerResults prospect={patient} summary={summary} selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}/>
    </>
}

export default Patient
