import { DateTime } from "luxon";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { downloadPatientResponses, removeDownloadedLink } from "../../../store/actions/physician/patients.action";
import { StateParams } from "../../../store/reducers";
import { PositiveNegative } from "../../generic/PositiveNegative";
import { IPatient, ISummary, patient_status, Roles } from "../../../models";
import { SLUG_TO_TAB_MAP } from "../../screeners/ScreenerResults";
import { MixpanelContext } from "../../../analytics/Mixpanel";
import {
    DOWNLOAD_SCREENER_PDF,
    REFER_PATIENT_FROM_DETAILS,
    REFER_PATIENT_FROM_LIST,
    VIEW_SCREENER_PDF
} from "../../../analytics/Events";
import { formatISODate } from "../../../shared/DateUtils";

const ScreenerSummary: React.FC<{ patientDetails: IPatient, summary: ISummary, summaryComponentRef: any, updateSelectedIndex: Function, role?: string }> = React.memo(({ patientDetails, summary, summaryComponentRef, updateSelectedIndex, role }) => {
    const screenerScores: ISummary = summary
    const dispatch = useDispatch()
    const analytics = React.useContext(MixpanelContext);
    const params = useParams()
    const { canMARefer } = useSelector((state: StateParams) => state.account)
    const { link, fileName, screenerSessionId } = useSelector((state: StateParams) => state.physicianPatients)
    const [downloadButtonClicked, setDownloadButtonClicked] = useState(false)
    const [pdfViewButtonClicked, setPdfViewButtonClicked] = useState(false)
    const [referOption, setReferOption] = useState<{ url: string, patientStatus: patient_status } | null>()
    const navigate = useNavigate()
    const pscyComplete = screenerScores?.pscyComplete || false
    const pscyScore = screenerScores?.pscyScore
    const aceComplete = screenerScores?.aceComplete || false
    const depressionComplete = screenerScores?.depressionComplete || false
    const anxietyComplete = screenerScores?.anxietyComplete || false
    const suicidalComplete = screenerScores?.suicidalComplete || false
    const pscComplete = screenerScores?.pscComplete || false
    const depressionMFQComplete = screenerScores?.depressionMFQComplete || false
    const anxietySCAREDComplete = screenerScores?.anxietySCAREDComplete || false
    const adhdComplete = screenerScores?.adhdComplete || false
    const phq9Complete = screenerScores?.phq9Complete || false
    const twoToSixComplete = screenerScores?.twoToSixMonthComplete || false
    const nineToFifteenComplete = screenerScores?.nineToFifteenMonthComplete || false
    const eighteenToThirtyComplete = screenerScores?.eighteenToThirtyMonthComplete || false
    const thirtySixToSixtyComplete = screenerScores?.thirtySixToSixtyMonthComplete || false
    const mChatRComplete = screenerScores?.mChatRComplete || false
    const drugComplete = screenerScores.drugComplete || false
    const alcoholComplete = screenerScores.alcoholComplete || false
    const ptsdComplete = screenerScores.ptsdComplete || false
    const sdohComplete = screenerScores.sdohComplete || false
    const mmseComplete = screenerScores.mmseComplete || false
    const qdrComplete = screenerScores.qdrsComplete || false


    const pscyFailed = screenerScores?.pscyFailed
    const pscySuicidalPositive = screenerScores?.pscySuicidalPositive
    const acePositive = screenerScores?.acePositive
    const depressionPositive = screenerScores?.depressionPositive
    const anxietyPositive = screenerScores?.anxietyPositive
    const suicidalPositive = screenerScores?.suicidalPositive
    const adhdInattentivePositive = screenerScores?.adhdInattentivePositive
    const adhdHyperactivePositive = screenerScores?.adhdHyperactivePositive
    const adhdCombinedPositive = screenerScores?.adhdCombinedPositive
    const pscPositive = screenerScores?.pscScreenerPositive
    const depressionMFQPositive = screenerScores?.depressionMFQPositive
    const anxietySCAREDPositive = screenerScores?.anxietySCAREDPositive
    const phq9Positive = screenerScores?.phq9Positive
    const questionnaireDrugPositive = screenerScores?.questionnaireDrugPositive
    const drugScore = screenerScores?.drugScore
    const sdohScore = screenerScores?.sdohScore
    const mmseScore = screenerScores?.mmseScore
    const qdrScore = screenerScores?.qdrsScore
    const sdohScreenerPostive = screenerScores?.sdohScreenerPostive
    const mmsePositive = screenerScores?.mmsePositive
    const qdrPositive = screenerScores?.qdrsPositive

    const questionnaireAlcoholPositive = screenerScores?.questionnaireAlcoholPositive
    const questionnaireAlcoholPositiveV2 = screenerScores?.questionnaireAlcoholPositiveV2
    const alcoholScore = screenerScores?.alcoholScore

    const questionnairePtsdPositive = screenerScores?.questionnairePtsdPositive
    const ptsdScore = screenerScores?.ptsdScore

    const potentialDevelopmental = screenerScores?.potentialDevelopmental
    const potentialTwoToSixBPSC = screenerScores?.potentialTwoToSixBPSC
    const potentialTwoToSixParentConcern = screenerScores?.potentialTwoToSixParentConcern
    const potentialTwoToSixTobacco = screenerScores?.potentialTwoToSixTobacco
    const potentialTwoToSixSubstanceAbuse = screenerScores?.potentialTwoToSixSubstanceAbuse
    const potentialTwoToSixFoodInsecurity = screenerScores?.potentialTwoToSixFoodInsecurity
    const potentialTwoToSixDomesticViolence = screenerScores?.potentialTwoToSixDomesticViolence
    const potentialTwoToSixSelfHarm = screenerScores?.potentialTwoToSixSelfHarm
    const potentialTwoToSixEmotinalChanges = screenerScores?.potentialTwoToSixEmotinalChanges
    const potentialNineToFifteenBPSC = screenerScores?.potentialNineToFifteenBPSC
    const potentialNineToFifteenParentConcern = screenerScores?.potentialNineToFifteenParentConcern
    const potentialNineToFifteenTobacco = screenerScores?.potentialNineToFifteenTobacco
    const potentialNineToFifteenSubstanceAbuse = screenerScores?.potentialNineToFifteenSubstanceAbuse
    const potentialNineToFifteenFoodInsecurity = screenerScores?.potentialNineToFifteenFoodInsecurity
    const potentialNineToFifteenParentalDepression = screenerScores?.potentialNineToFifteenParentalDepression
    const potentialNineToFifteenDomesticViolence = screenerScores?.potentialNineToFifteenDomesticViolence
    const potentialNineToFifteenSelfHarm = screenerScores?.potentialNineToFifteenSelfHarm
    const potentialNineToFifteenEmotinalChanges = screenerScores?.potentialNineToFifteenEmotinalChanges
    const potentialEighteenToThirtyPPSC = screenerScores?.potentialEighteenToThirtyPPSC
    const potentialEighteenToThirtyPOSI = screenerScores?.potentialEighteenToThirtyPOSI
    const potentialEighteenToThirtyParentConcern = screenerScores?.potentialEighteenToThirtyParentConcern
    const potentialEighteenToThirtyTobacco = screenerScores?.potentialEighteenToThirtyTobacco
    const potentialEighteenToThirtySubstanceAbuse = screenerScores?.potentialEighteenToThirtySubstanceAbuse
    const potentialEighteenToThirtyFoodInsecurity = screenerScores?.potentialEighteenToThirtyFoodInsecurity
    const potentialEighteenToThirtyParentalDepression = screenerScores?.potentialEighteenToThirtyParentalDepression
    const potentialEighteenToThirtyDomesticViolence = screenerScores?.potentialEighteenToThirtyDomesticViolence
    const potentialThirtySixToSixtyPPSC = screenerScores?.potentialThirtySixToSixtyPPSC
    const potentialThirtySixToSixtyParentConcern = screenerScores?.potentialThirtySixToSixtyParentConcern
    const potentialThirtySixToSixtyTobacco = screenerScores?.potentialThirtySixToSixtyTobacco
    const potentialThirtySixToSixtySubstanceAbuse = screenerScores?.potentialThirtySixToSixtySubstanceAbuse
    const potentialThirtySixToSixtyFoodInsecurity = screenerScores?.potentialThirtySixToSixtyFoodInsecurity
    const potentialThirtySixToSixtyParentalDepression = screenerScores?.potentialThirtySixToSixtyParentalDepression
    const potentialThirtySixToSixtyDomesticViolence = screenerScores?.potentialThirtySixToSixtyDomesticViolence
    const asdPositive = screenerScores?.asdPositive

    const aceScore = screenerScores?.aceScore
    const anxietyScore = screenerScores?.anxietyScore
    const depressionScore = screenerScores?.depressionScore
    const phq9Score = screenerScores?.phq9Score
    const suicidalScore = screenerScores?.suicidalScore
    const adhdScore = screenerScores?.adhdScore
    const asdScore = screenerScores?.asdScore
    const pscScore = screenerScores?.pscScore
    const anxietySCAREDScore = screenerScores.anxietySCAREDScore
    const depressionMFQScore = screenerScores.depressionMFQScore
    const suicidalLevel = screenerScores.suicidalLevel
    const aceLevel = screenerScores.aceLevel
    const adhdLevel = screenerScores.adhdLevel
    const phq9Level = screenerScores.phq9Level
    const twoToSixLevel = screenerScores.twoToSixLevel
    const nineToFifteenLevel = screenerScores.nineToFifteenLevel
    const asdLevel = screenerScores.asdLevel
    const drugLevel = screenerScores.drugLevel
    const ptsdLevel = screenerScores.ptsdLevel
    const alcoholLevel = screenerScores.alcoholLevel
    const depressionLevel = screenerScores.depressionLevel
    const anxietyLevel = screenerScores.anxietyLevel
    const mmseLevel = screenerScores.mmseLevel
    const qdrLevel = screenerScores.qdrsLevel
    const mChatRScreenerSlug = screenerScores.mChatRScreenerSlug
    const nineToFifteenMonthScreenerSlug = screenerScores.nineToFifteenMonthScreenerSlug
    const eighteenToThirtyMonthScreenerSlug = screenerScores.eighteenToThirtyMonthScreenerSlug
    const thirtySixToSixtyMonthScreenerSlug = screenerScores.thirtySixToSixtyMonthScreenerSlug
    const pscyScreenerSlug = screenerScores.pscyScreenerSlug
    const pscScreenerSlug = screenerScores.pscScreenerSlug
    const phq9ScreenerSlug = screenerScores.phq9ScreenerSlug
    const drugScreenerSlug = screenerScores.drugScreenerSlug
    const alcoholScreenerSlug = screenerScores.alcoholScreenerSlug
    const ptsdScreenerSlug = screenerScores.ptsdScreenerSlug
    const depressionScreenerSlug = screenerScores.depressionScreenerSlug
    const depressionMFQScreenerSlug = screenerScores.depressionMFQScreenerSlug
    const anxietyScreenerSlug = screenerScores.anxietyScreenerSlug
    const anxietySCAREDScreenerSlug = screenerScores.anxietySCAREDScreenerSlug
    const suicidalScreenerSlug = screenerScores.suicidalScreenerSlug
    const aceScreenerSlug = screenerScores.aceScreenerSlug
    const adhdScreenerSlug = screenerScores.adhdScreenerSlug
    const twoToSixMonthScreenerSlug = screenerScores.twoToSixMonthScreenerSlug
    const sdohScreenerSlug = screenerScores.sdohScreenerSlug
    const mmseSlug = screenerScores.mmseSlug
    const qdrSlug = screenerScores.qdrsSlug
    const canRefer = role === 'Physician' || (role === 'Medical_Assistant' && canMARefer);
    const isoDateTime = patientDetails && formatISODate(patientDetails.screeners[0].createdAt)

    useEffect(() => {
        if (patientDetails && patientDetails.patientStatus) {
            setReferOption({ url: `/referScreen/${patientDetails.id}`, patientStatus: patientDetails.patientStatus })
        }
    }, [])

    useEffect(() => {
        if (downloadButtonClicked && link && fileName) {
            setDownloadButtonClicked(false)
            axios({
                url: link,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            });
            dispatch(removeDownloadedLink())
        }
    }, [downloadButtonClicked, link])

    useEffect(() => {
        if (pdfViewButtonClicked && link && fileName) {
            setPdfViewButtonClicked(false)
            window.open(link)
            dispatch(removeDownloadedLink())
        }
    }, [pdfViewButtonClicked, link, fileName])

    summaryComponentRef.current = updateSelectedIndex;

    const downloadPDF = () => {
        setDownloadButtonClicked(true)
        dispatch(downloadPatientResponses(String(params.patientId), {
            sessionId: screenerSessionId ? screenerSessionId : ''
        }))
        analytics?.track(DOWNLOAD_SCREENER_PDF, { patientId: String(params.patientId) })
    }

    const viewPDF = () => {
        setPdfViewButtonClicked(true)
        dispatch(downloadPatientResponses(String(params.patientId), {
            sessionId: screenerSessionId ? screenerSessionId : ''
        }))
        analytics?.track(VIEW_SCREENER_PDF, { patientId: String(params.patientId) })
    }

    const renderReferButton = (value: { url: string, patientStatus: patient_status }) => {
        console.log('refer')
        const navigateToReferScreen = () => {
            analytics?.track(REFER_PATIENT_FROM_DETAILS, { patientId: String(params.patientId) })
            navigate(value.url)
        }
        return <>
            {canRefer && <button className="mb-1 float-right text-sm w-1/9 h-1/6 mr-2 bg-white border-2 rounded-md border-sjOrange text-sjOrange"
                onClick={navigateToReferScreen}>Refer
            </button>}
        </>
    }

    const renderReferredButton = () => {
        return <>
            <button className="mb-1 float-right text-sm w-1/9 h-1/6 mr-2 bg-white border-2 rounded-md border-sjGrey text-sjGrey cursor-auto">
                Referred
            </button>
        </>
    }


    const renderReferPatient = (value: { url: string, patientStatus: patient_status }) => {
        console.log('refered')
        return <>
            {value.patientStatus === patient_status.inactive ?
                renderReferButton(value)
                :
                renderReferredButton()
            }
        </>
    }

    const renderScreenerDetails = (slug: string, detailMessage?: string) => {
        const summaryDetailsScreener = patientDetails.screeners.find(screener => screener.slug === slug)
        const summaryDetails = summaryDetailsScreener?.userSummaryDetails
        return summaryDetails ? summaryDetails : detailMessage ? detailMessage : '-'
    }

    const renderScreenerSummaryMessage = (isComplete: boolean, isPositive: boolean | undefined, summaryDetails: string, score?: number, slug?: string, level?: any) => {
        if (isPositive === undefined || !isComplete) {
            return
        }
        return (
            <tr className="h-14 text-sm">
                <td>{slug}</td>
                <td>{score}</td>
                <td>{isPositive ? <PositiveNegative text='Positive' level={level} /> : <PositiveNegative text="Negative" level={level} />}</td>
                {role !== Roles.Care_Coordinator ? <td>{summaryDetails}</td> : null}
            </tr>
        )
    }

    return screenerScores ? (<div className={"flex flex-col"}>
        <div>
            <span>Screener Summary</span>
            {(pscyComplete || pscComplete || sdohComplete || aceComplete || anxietyComplete || phq9Complete || twoToSixComplete || nineToFifteenComplete || eighteenToThirtyComplete || thirtySixToSixtyComplete || mmseComplete || qdrComplete) && (role !== Roles.Care_Coordinator) ? (<>
                {role !== Roles.Medical_Assistant && <button data-test="button-download-pdf" className="mb-1 float-right text-sm w-1/9 h-1/6 bg-white border-2 rounded-md border-sjOrange text-sjOrange" onClick={downloadPDF}>Download PDF</button>}
                {role !== Roles.Medical_Assistant && <button data-test="button-view-pdf" className="mb-1 float-right text-sm w-1/9 h-1/6 mr-2 bg-white border-2 rounded-md border-sjOrange text-sjOrange" onClick={viewPDF}>View PDF</button>}
                {referOption ? renderReferPatient(referOption) : null}
            </>) : null}
        </div>
        <div className={"my-2"}>
            {patientDetails ? (<div className={"flex flex-col"}>
                <span className="text-sm mr-2 text-sjGrey">Date of Visit</span>
                <span className="text-black text-sm">{isoDateTime}</span></div>
            ) : ''}
        </div>
        <div className="shadow overflow-hidden border border-sjScreenerLink rounded-lg">
            <table className="w-full summary-table divide-y divide-sjScreenerLink">
                <thead className="text-left bg-sjTableHead " >
                    <tr className="h-14" >
                        <th>Screener</th>
                        <th>Score</th>
                        <th>Interpretation</th>
                        {role !== Roles.Care_Coordinator ? <th>Details</th> : null}
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-sjScreenerLink">
                    {pscyComplete ? renderScreenerSummaryMessage(pscyComplete, pscyFailed || pscySuicidalPositive, renderScreenerDetails(pscyScreenerSlug), pscyScore, SLUG_TO_TAB_MAP["psc-y-summary"])
                        : renderScreenerSummaryMessage(pscComplete, pscPositive, renderScreenerDetails(pscScreenerSlug), pscScore, SLUG_TO_TAB_MAP['fp-psc-6to12-summary'])}
                    {renderScreenerSummaryMessage(aceComplete, acePositive, renderScreenerDetails(aceScreenerSlug), aceScore, SLUG_TO_TAB_MAP['fp-ace-6to12-summary'], aceLevel)}
                    {anxietyComplete ? renderScreenerSummaryMessage(anxietyComplete, anxietyPositive, renderScreenerDetails(anxietyScreenerSlug), anxietyScore, SLUG_TO_TAB_MAP['fp-anxiety-above18-summary'], anxietyLevel)
                        : renderScreenerSummaryMessage(anxietySCAREDComplete, anxietySCAREDPositive, renderScreenerDetails(anxietySCAREDScreenerSlug), anxietySCAREDScore, SLUG_TO_TAB_MAP['fp-anxiety-SCARED-summary'])}
                    {depressionComplete ? renderScreenerSummaryMessage(depressionComplete, depressionPositive, renderScreenerDetails(depressionScreenerSlug), depressionScore, SLUG_TO_TAB_MAP['depression-summary'], depressionLevel)
                        : depressionMFQComplete ? renderScreenerSummaryMessage(depressionMFQComplete, depressionMFQPositive, renderScreenerDetails(depressionMFQScreenerSlug), depressionMFQScore, SLUG_TO_TAB_MAP['fp-depression-MFQ-summary'])
                            : renderScreenerSummaryMessage(phq9Complete, phq9Positive, renderScreenerDetails(phq9ScreenerSlug), phq9Score, SLUG_TO_TAB_MAP['depression-summary'], phq9Level)}
                    {/* {renderScreenerSummaryMessage(pscyComplete, pscySuicidalPositive, suicidalScore)} */}
                    {/* {!phq9SuicidalPositive && renderScreenerSummaryMessage(phq9Complete, phq9SuicidalPositive, suicidalNegativeMessageText)} */}
                    {renderScreenerSummaryMessage(suicidalComplete, suicidalPositive, renderScreenerDetails(suicidalScreenerSlug), suicidalScore, SLUG_TO_TAB_MAP['fp-suicidal-summary'], suicidalLevel)}
                    {renderScreenerSummaryMessage(alcoholComplete, questionnaireAlcoholPositive || questionnaireAlcoholPositiveV2, renderScreenerDetails(alcoholScreenerSlug, 'One drink equals: 12 oz.beer, 5 oz. wine, 1.5 oz. liquor (one shot)'), alcoholScore, SLUG_TO_TAB_MAP['fp-alcohol-above18-summary'], alcoholLevel)}
                    {renderScreenerSummaryMessage(drugComplete, questionnaireDrugPositive, renderScreenerDetails(drugScreenerSlug), drugScore, SLUG_TO_TAB_MAP['fp-drug-above18-summary'], drugLevel)}
                    {renderScreenerSummaryMessage(ptsdComplete, questionnairePtsdPositive, renderScreenerDetails(ptsdScreenerSlug), ptsdScore, SLUG_TO_TAB_MAP['fp-ptsd-above18-summary'], ptsdLevel)}
                    {renderScreenerSummaryMessage(adhdComplete, adhdInattentivePositive || adhdHyperactivePositive || adhdCombinedPositive, renderScreenerDetails(adhdScreenerSlug), adhdScore, SLUG_TO_TAB_MAP['fp-adhd-6to12-summary'], adhdLevel)}
                    {/* {renderScreenerSummaryMessage(mChatRComplete, potentialASD1 || potentialASD2, renderScreenerDetails(mChatRScreenerSlug), asdScore, SLUG_TO_TAB_MAP['fp-m-chat-r'], asdLevel)} */}
                    {renderScreenerSummaryMessage(mChatRComplete, asdPositive, renderScreenerDetails(mChatRScreenerSlug), asdScore, SLUG_TO_TAB_MAP['fp-m-chat-r-summary'], asdLevel)}

                    {renderScreenerSummaryMessage(twoToSixComplete, potentialDevelopmental, renderScreenerDetails(twoToSixMonthScreenerSlug), screenerScores?.twoToSixDevelopmentalScore, SLUG_TO_TAB_MAP['developmental'])}
                    {renderScreenerSummaryMessage(twoToSixComplete, potentialTwoToSixBPSC, renderScreenerDetails(twoToSixMonthScreenerSlug), screenerScores?.twoToSixBPSCScore, SLUG_TO_TAB_MAP['bpsc'])}
                    {renderScreenerSummaryMessage(twoToSixComplete, potentialTwoToSixParentConcern, renderScreenerDetails(twoToSixMonthScreenerSlug), screenerScores?.twoToSixParentConcernScore, SLUG_TO_TAB_MAP['parent-concern'])}
                    {renderScreenerSummaryMessage(twoToSixComplete, potentialTwoToSixTobacco, renderScreenerDetails(twoToSixMonthScreenerSlug), screenerScores?.twoToSixTobaccoScore, SLUG_TO_TAB_MAP['tobacco'])}
                    {renderScreenerSummaryMessage(twoToSixComplete, potentialTwoToSixSubstanceAbuse, renderScreenerDetails(twoToSixMonthScreenerSlug), screenerScores?.twoToSixSubstanceAbuseScore, SLUG_TO_TAB_MAP['substance-abuse'])}
                    {renderScreenerSummaryMessage(twoToSixComplete, potentialTwoToSixFoodInsecurity, renderScreenerDetails(twoToSixMonthScreenerSlug), screenerScores?.twoToSixFoodInsecurityScore, SLUG_TO_TAB_MAP['food-insecurity'])}
                    {renderScreenerSummaryMessage(twoToSixComplete, potentialTwoToSixDomesticViolence, renderScreenerDetails(twoToSixMonthScreenerSlug), screenerScores?.twoToSixDomesticViolenceScore, SLUG_TO_TAB_MAP['domestic-violence'])}
                    {renderScreenerSummaryMessage(twoToSixComplete, potentialTwoToSixEmotinalChanges, renderScreenerDetails(twoToSixMonthScreenerSlug), screenerScores?.twoToSixEmotionalChangesScore, SLUG_TO_TAB_MAP['emotinal-change'], twoToSixLevel)}
                    {renderScreenerSummaryMessage(twoToSixComplete, potentialTwoToSixSelfHarm, renderScreenerDetails(twoToSixMonthScreenerSlug), screenerScores?.twoToSixSelfHarmScore, SLUG_TO_TAB_MAP['self-harm'])}

                    {renderScreenerSummaryMessage(nineToFifteenComplete, potentialDevelopmental, renderScreenerDetails(nineToFifteenMonthScreenerSlug), screenerScores?.nineToFifteenDevelopmentalScore, SLUG_TO_TAB_MAP['developmental'])}
                    {renderScreenerSummaryMessage(nineToFifteenComplete, potentialNineToFifteenBPSC, renderScreenerDetails(nineToFifteenMonthScreenerSlug), screenerScores?.nineToFifteenBPSCScore, SLUG_TO_TAB_MAP['bpsc'])}
                    {renderScreenerSummaryMessage(nineToFifteenComplete, potentialNineToFifteenParentConcern, renderScreenerDetails(nineToFifteenMonthScreenerSlug), screenerScores?.nineToFifteenParentConcernScore, SLUG_TO_TAB_MAP['parent-concern'])}
                    {renderScreenerSummaryMessage(nineToFifteenComplete, potentialNineToFifteenTobacco, renderScreenerDetails(nineToFifteenMonthScreenerSlug), screenerScores?.nineToFifteenTobaccoScore, SLUG_TO_TAB_MAP['tobacco'])}
                    {renderScreenerSummaryMessage(nineToFifteenComplete, potentialNineToFifteenSubstanceAbuse, renderScreenerDetails(nineToFifteenMonthScreenerSlug), screenerScores?.nineToFifteenSubstanceAbuseScore, SLUG_TO_TAB_MAP['substance-abuse'])}
                    {renderScreenerSummaryMessage(nineToFifteenComplete, potentialNineToFifteenFoodInsecurity, renderScreenerDetails(nineToFifteenMonthScreenerSlug), screenerScores?.nineToFifteenFoodInsecurityScore, SLUG_TO_TAB_MAP['food-insecurity'])}
                    {renderScreenerSummaryMessage(nineToFifteenComplete, potentialNineToFifteenParentalDepression, renderScreenerDetails(nineToFifteenMonthScreenerSlug), screenerScores?.nineToFifteenParentalDepressionScore, SLUG_TO_TAB_MAP['parental-depression'])}
                    {renderScreenerSummaryMessage(nineToFifteenComplete, potentialNineToFifteenDomesticViolence, renderScreenerDetails(nineToFifteenMonthScreenerSlug), screenerScores?.nineToFifteenDomesticViolenceScore, SLUG_TO_TAB_MAP['domestic-violence'])}
                    {renderScreenerSummaryMessage(nineToFifteenComplete, potentialNineToFifteenEmotinalChanges, renderScreenerDetails(nineToFifteenMonthScreenerSlug), screenerScores?.nineToFifteenEmotionalChangesScore, SLUG_TO_TAB_MAP['emotinal-change'], nineToFifteenLevel)}
                    {renderScreenerSummaryMessage(nineToFifteenComplete, potentialNineToFifteenSelfHarm, renderScreenerDetails(nineToFifteenMonthScreenerSlug), screenerScores?.nineToFifteenSelfHarmScore, SLUG_TO_TAB_MAP['self-harm'])}

                    {renderScreenerSummaryMessage(eighteenToThirtyComplete, potentialDevelopmental, renderScreenerDetails(eighteenToThirtyMonthScreenerSlug), screenerScores?.eighteenToThirtyDevelopmentalScore, SLUG_TO_TAB_MAP['developmental'])}
                    {renderScreenerSummaryMessage(eighteenToThirtyComplete, potentialEighteenToThirtyPPSC, renderScreenerDetails(eighteenToThirtyMonthScreenerSlug), screenerScores?.eighteenToThirtyPPSCScore, SLUG_TO_TAB_MAP['ppsc'])}
                    {renderScreenerSummaryMessage(eighteenToThirtyComplete, potentialEighteenToThirtyPOSI, renderScreenerDetails(eighteenToThirtyMonthScreenerSlug), screenerScores?.eighteenToThirtyPOSIScore, SLUG_TO_TAB_MAP['posi'])}
                    {renderScreenerSummaryMessage(eighteenToThirtyComplete, potentialEighteenToThirtyParentConcern, renderScreenerDetails(eighteenToThirtyMonthScreenerSlug), screenerScores?.eighteenToThirtyParentConcernScore, SLUG_TO_TAB_MAP['parent-concern'])}
                    {renderScreenerSummaryMessage(eighteenToThirtyComplete, potentialEighteenToThirtyTobacco, renderScreenerDetails(eighteenToThirtyMonthScreenerSlug), screenerScores?.eighteenToThirtyTobaccoScore, SLUG_TO_TAB_MAP['tobacco'])}
                    {renderScreenerSummaryMessage(eighteenToThirtyComplete, potentialEighteenToThirtySubstanceAbuse, renderScreenerDetails(eighteenToThirtyMonthScreenerSlug), screenerScores?.eighteenToThirtySubstanceAbuseScore, SLUG_TO_TAB_MAP['substance-abuse'])}
                    {renderScreenerSummaryMessage(eighteenToThirtyComplete, potentialEighteenToThirtyFoodInsecurity, renderScreenerDetails(eighteenToThirtyMonthScreenerSlug), screenerScores?.eighteenToThirtyFoodInsecurityScore, SLUG_TO_TAB_MAP['food-insecurity'])}
                    {renderScreenerSummaryMessage(eighteenToThirtyComplete, potentialEighteenToThirtyParentalDepression, renderScreenerDetails(eighteenToThirtyMonthScreenerSlug), screenerScores?.eighteenToThirtyParentalDepressionScore, SLUG_TO_TAB_MAP['parental-depression'])}
                    {renderScreenerSummaryMessage(eighteenToThirtyComplete, potentialEighteenToThirtyDomesticViolence, renderScreenerDetails(eighteenToThirtyMonthScreenerSlug), screenerScores?.eighteenToThirtyDomesticViolenceScore, SLUG_TO_TAB_MAP['domestic-violence'])}

                    {renderScreenerSummaryMessage(thirtySixToSixtyComplete, potentialDevelopmental, renderScreenerDetails(thirtySixToSixtyMonthScreenerSlug), screenerScores?.thirtySixToSixtyDevelopmentalScore, SLUG_TO_TAB_MAP['developmental'])}
                    {renderScreenerSummaryMessage(thirtySixToSixtyComplete, potentialThirtySixToSixtyPPSC, renderScreenerDetails(thirtySixToSixtyMonthScreenerSlug), screenerScores?.thirtySixToSixtyPPSCScore, SLUG_TO_TAB_MAP['ppsc'])}
                    {renderScreenerSummaryMessage(thirtySixToSixtyComplete, potentialThirtySixToSixtyParentConcern, renderScreenerDetails(thirtySixToSixtyMonthScreenerSlug), screenerScores?.thirtySixToSixtyParentConcernScore, SLUG_TO_TAB_MAP['parent-concern'])}
                    {renderScreenerSummaryMessage(thirtySixToSixtyComplete, potentialThirtySixToSixtyTobacco, renderScreenerDetails(thirtySixToSixtyMonthScreenerSlug), screenerScores?.thirtySixToSixtyTobaccoScore, SLUG_TO_TAB_MAP['tobacco'])}
                    {renderScreenerSummaryMessage(thirtySixToSixtyComplete, potentialThirtySixToSixtySubstanceAbuse, renderScreenerDetails(thirtySixToSixtyMonthScreenerSlug), screenerScores?.thirtySixToSixtySubstanceAbuseScore, SLUG_TO_TAB_MAP['substance-abuse'])}
                    {renderScreenerSummaryMessage(thirtySixToSixtyComplete, potentialThirtySixToSixtyFoodInsecurity, renderScreenerDetails(thirtySixToSixtyMonthScreenerSlug), screenerScores?.thirtySixToSixtyFoodInsecurityScore, SLUG_TO_TAB_MAP['food-insecurity'])}
                    {renderScreenerSummaryMessage(thirtySixToSixtyComplete, potentialThirtySixToSixtyParentalDepression, renderScreenerDetails(thirtySixToSixtyMonthScreenerSlug), screenerScores?.thirtySixToSixtyParentalDepressionScore, SLUG_TO_TAB_MAP['parental-depression'])}
                    {renderScreenerSummaryMessage(thirtySixToSixtyComplete, potentialThirtySixToSixtyDomesticViolence, renderScreenerDetails(thirtySixToSixtyMonthScreenerSlug), screenerScores?.thirtySixToSixtyDomesticViolenceScore, SLUG_TO_TAB_MAP['domestic-violence'])}
                    {renderScreenerSummaryMessage(sdohComplete, sdohScreenerPostive, renderScreenerDetails(sdohScreenerSlug), sdohScore, SLUG_TO_TAB_MAP['fp-sdoh-above18'])}
                    {renderScreenerSummaryMessage(mmseComplete, mmsePositive, renderScreenerDetails(mmseSlug), mmseScore, SLUG_TO_TAB_MAP['fp-mmse-cognitive'], mmseLevel)}
                    {renderScreenerSummaryMessage(qdrComplete, qdrPositive, renderScreenerDetails(qdrSlug), qdrScore, SLUG_TO_TAB_MAP['fp-qdrs-cognitive'], qdrLevel)}
                </tbody>
            </table>
        </div>
    </div>) : null
})

export default ScreenerSummary
