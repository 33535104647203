import React from "react";
import {Link} from "react-router-dom";
import { Button } from "./Buttons";

function renderProfile(value: string) {
    return (
        <Link to={value}>
            <Button className={"!bg-sjOrange !text-white !ml-2"}>View Details</Button>
        </Link>
    );
}

export const ProfileView: React.FC<{value: string}> = ({ value }) => {
    return renderProfile(value);
}
export const ProfileViewV2: React.FC<{getValue: any}> = ({ getValue }) => {
    const value = getValue()
    return renderProfile(value);
}
