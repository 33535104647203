import React, { useMemo } from 'react'
import { LocationIndex, LocationIndexItem } from '../../models';
import { SuccessFailurePill } from '../generic/SuccessFailurePill';
import { LocationAction } from '../generic/LocationAction';
import TablePaginationV2 from '../generic/TablePaginationV2';
import { MaskPhoneNumber } from '../generic/MaskPhoneNumber';
interface LocationProps {
    locationsIndex: LocationIndexItem[]
    totalRows: number
    currentPageHandler: Function,
    searchInProgress: boolean | undefined
}

const Locations: React.FC<LocationProps> = ({ locationsIndex, totalRows, currentPageHandler, searchInProgress }) => {

    const data = (locationsIndex) ? locationsIndex.map((location) => {
        return {
            id: location.id,
            name: `${location.name}`,
            phone: location.phone,
            isPhoneVerified: location.isPhoneVerified,
            addressLine: location.addressLine,
            zipCode: location.zipCode,
            // status: location.isActive,
            locationId: location.id
        }
    }) : []

    const columns = useMemo(
        () => [
            { header: "Location Name", accessorKey: "name" },
            { header: "Phone Number", accessorKey: "phone", cell: (props: any) => 
                <MaskPhoneNumber value={props.getValue()} disabled={true} className={'border-0 bg-transparent'}/>
            },
            { header: "Phone Verified", accessorKey: "isPhoneVerified", cell: (props: any) => <SuccessFailurePill value={props.getValue()}/> },
            { header: "Address", accessorKey: "addressLine" },
            { header: "Zip Code", accessorKey: "zipCode" },
            // { header: "Status", accessorKey: "status", cell: (props: any) => <IsActivePill value={props.getValue()}/> },
            { header: '', accessorKey: 'locationId', cell: (props: any) => <LocationAction value={props.getValue()}/> }
        ],
        []
    );

    return (
        <div className='mt-4 w-full'>
            <TablePaginationV2 columns={columns} data={data} searchInProgress={searchInProgress} />
        </div>
    )
}

export default Locations;
