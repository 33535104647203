import React, {useEffect, useState} from "react"
import {IoArrowBackCircleOutline} from "react-icons/io5"
import {useDispatch, useSelector} from "react-redux"
import {Link, Outlet, useLocation, useParams} from "react-router-dom"
import {StateParams} from "../../../store/reducers"
import PatientEngagement from "./PatientEngagement"
import {fetchPatient, fetchPracticePatient} from "../../../store/actions/counsellor/patients.action";
import {RoutePaths} from "../../../shared/Utils";
import ProspectProfile from "../../prospects/ProspectProfile"
import {IProspect} from "../../../models";
import BackIcon from "../../../assets/images/common/back.svg";
import * as _ from "lodash";

const enum CareProviderType {
    "physician" = "physician", "non_physician" = "non_physician"
}

const PatientJourney: React.FC = () => {
    const location = useLocation();
    const {patient} = useSelector((state: StateParams) => state.patients)
    const [careProviderType, setCareProviderType] = useState<CareProviderType | null>(null)
    const [linkToGoBackTo, setLinkToGoBackTo] = useState<string>("")
    const dispatch = useDispatch()
    const params = useParams()
    const pathComponents = location.pathname.split("/")

    useEffect(() => {
        console.log("path components", pathComponents);
        try {
            const activatePathComponent = pathComponents[1] as CareProviderType;
            console.log("activate path", activatePathComponent);
            setCareProviderType(activatePathComponent);
        } catch (e) {
            console.log("No matching Login component");
            setCareProviderType("non_physician" as CareProviderType);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (careProviderType === CareProviderType.physician) {
            const route = pathComponents.includes('discharged-patients') ? '..' :
                RoutePaths.physicianCare.patients
            setLinkToGoBackTo(route);
        } else if (pathComponents.includes('discharged-patients')) {
            setLinkToGoBackTo(`/${RoutePaths.collaborativeCare.dischargedPatients.root}`);
        } else if (pathComponents.includes('compliance')) {
                setLinkToGoBackTo(`../${RoutePaths.collaborativeCare.compliance.screeningPendingPatients}`);
        } else {
            setLinkToGoBackTo(`/${RoutePaths.collaborativeCare.patients.root}`);
        }
    }, [careProviderType]);

    useEffect(() => {
        if (params.patientId) {
            if (pathComponents.includes('physician')) {
                dispatch(fetchPracticePatient(params.patientId))
            } else {
                dispatch(fetchPatient(params.patientId))
            }
        }
    }, [params.patientId])

    function renderBackButton(patient: IProspect) {
        return <div className={"flex flex-row gap-2 items-center"}>
            <Link to={linkToGoBackTo}>
                <img src={BackIcon} width={24} height={24} alt="Go Back"
                     className={"hover:cursor-pointer"}/>
            </Link>
            <div className={"flex flex-row gap-2 items-center"}>
                <span className="text-xl font-semibold text-sjGray"> CoCM Patient Profile {'|'} </span>
                <span
                    className="text-xl font-semibold"> {_.capitalize(patient.firstName)} {_.capitalize(patient.lastName)} </span>
            </div>
        </div>;
    }

    return (
        <>
            <main className="relative min-h-screen mx-auto flex flex-col bg-[#F7F8FA]">
                <div className={`p-3 ${pathComponents.includes('schedule-backdated-appointment') ? '' : 'floating-section'}`}>
                    {patient ? renderBackButton(patient) : null}
                </div>
                <div className="px-5 flex-1">
                    {patient && <ProspectProfile prospect={patient} />}
                    {patient && <PatientEngagement patient={patient} />}
                </div>
            </main>
            <div className={"absolute top-0 right-0 bg-white z-[2000] custom-sidebar"}>
                <Outlet />
            </div>
        </>
    )
}

export default PatientJourney
