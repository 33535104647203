import React, { useEffect, useState } from 'react';

type Props = {
    setContainerVisible: (close: boolean) => void
}

const TIMER_IN_SECONDS = 120
export const Timer: React.FC<Props> = ({ setContainerVisible }) => {
    const [timeLeft, setTimeLeft] = useState(TIMER_IN_SECONDS); // 2 minutes in seconds
    const [timerActive, setTimerActive] = useState(true);

    useEffect(() => {
        if (timerActive && timeLeft > 0) {
            console.log("timerActive")
            const timer = setTimeout(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000)

            return () => {
                clearTimeout(timer)
            };
        } else {
            setContainerVisible(false)
            setTimerActive(false)
        }
    }, [timerActive, timeLeft])

    const minutes = Math.floor(timeLeft / 60)
    const seconds = timeLeft % 60

    return (
        <div>
            <span className='font-bold text-sm'>Time Remaining - {`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}</span>
        </div>
    );
}
