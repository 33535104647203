import React from "react";
import PatientsButton from "../common/PatientsButton";
import { RoutePaths } from "../../../shared/Utils";
import DischargedPatientsButton from "../common/DischargedPatientsButton";
import { CiMedicalClipboard } from "react-icons/ci";
import { UsersIcon, DocumentPlusIcon } from "@heroicons/react/24/outline";
import ReferredPatientsButton from "../common/ReferredPatientsButton";
import { useFlags } from "flagsmith/react";

const PsychiatristSideBar: React.FC = () => {
    const isReferredPatientsEnabled = useFlags(['psych_referred_patients_menu_item'])?.psych_referred_patients_menu_item?.enabled;

    function render() {
        return <div>
            {/* <ReferredPatientsButton component={"prospects"} navigateTo={`${RoutePaths.collaborativeCare.prospects.root}?page=1`} Icon={DocumentPlusIcon} /> */}
            {isReferredPatientsEnabled && <ReferredPatientsButton component={"prospects"} navigateTo={`${RoutePaths.collaborativeCare.prospects.root}?page=1`} Icon={DocumentPlusIcon} />}
            <PatientsButton component={"patients"} navigateTo={`/${RoutePaths.collaborativeCare.patients.root}?page=1`} Icon={UsersIcon} />
            <DischargedPatientsButton component={"discharged-patients"} navigateTo={`/${RoutePaths.collaborativeCare.dischargedPatients.root}?page=1`} Icon={CiMedicalClipboard} />
        </div>
    }
    return render()
}

export default PsychiatristSideBar