import React, {useState} from "react";
import {IScreenerQuestionAnswer, ISummary} from "../../../models";
import * as _ from "lodash";
import {Accordion, AccordionBody, AccordionHeader} from "@material-tailwind/react";
import QuestionAnswer from "../../generic/QuestionAnswer";

enum Conditions {
    "developmental",
    "ppsc",
    "posi",
    "parent-concern",
    "tobacco",
    "substance-abuse",
    "food-insecurity",
    "parental-depression",
    "domestic-violence",
    "reading-frequency"
}

type ConditionType = keyof typeof Conditions

const CONDITION_MAP : {[key in ConditionType]: string} = {
    "developmental": "Developmental Milestones",
    "ppsc": "Preschool Pediatric Symptom Checklist (PPSC)",
    "posi": "Parent’s Observations of Social Interactions (POSI)",
    "parent-concern": "Parental Concern",
    "tobacco": "Tobacco Use",
    "substance-abuse": "Substance Abuse Disorder",
    "food-insecurity": "Food Insecurity",
    "parental-depression": "Parental Depression",
    "domestic-violence": "Domestic Abuse",
    "reading-frequency": "Reading Frequency"
}

const EighteenToThirtyResults: React.FC<{screenerScoreMessage: string, questionAnswers: IScreenerQuestionAnswer[], summary: ISummary}> = ({questionAnswers, summary}) => {
    const screenerScores = summary
    const [open, setOpen] = useState(0);

    const handleOpen = (value: number) => {
        setOpen(open === value ? 0 : value);
    };
    const groupedByCondition : {[key: string]: IScreenerQuestionAnswer[]} = _.chain(questionAnswers)
        .groupBy((item: IScreenerQuestionAnswer) => {
            if(item.condition) {
                return item.condition
            } else {
                return "normal"
            }
        })
        .value()

    function renderGroupedQuestionAnswers(title: string | null, index: number, groupedQuestionAnswers: IScreenerQuestionAnswer[]) {
        
        return groupedQuestionAnswers && groupedQuestionAnswers.length > 0 ?
            (<Accordion className={"mb-2"} open={open === index} onClick={() => handleOpen(index)}>
                <AccordionHeader className={"bg-sjAnxiety text-black rounded text-sm px-2"}><span dangerouslySetInnerHTML={{__html: title || ""}}/></AccordionHeader>
                <AccordionBody>
                    <div className={"flex flex-col font-medium"}>
                        {groupedQuestionAnswers.map((questionAnswer, index) => {
                            return <QuestionAnswer key={questionAnswer.questionId} questionAnswer={questionAnswer}
                                                   index={index}/>
                        })}
                    </div>
                </AccordionBody>
            </Accordion>)
            : null;
    }

    function developmentalConditionQuestionAnswers(index: number) {
        if("developmental" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition.developmental
            const title = CONDITION_MAP["developmental"]
            const styledTitle = `${screenerScores.eighteenToThirtyMonthComplete ? (screenerScores?.potentialDevelopmental === true ? `The patient's ${title} <span class='text-sjTestPositive'>Needs Review</span>` : `The patient's ${title} <span class='text-sjTestNegative'>Appears to Meet Age Expectations</span>`) : `${title}` }`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }
    function ppscConditionQuestionAnswers(index: number) {
        if("ppsc" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition.ppsc
            const title = CONDITION_MAP["ppsc"]
            const styledTitle = `${screenerScores.eighteenToThirtyMonthComplete ? (screenerScores?.potentialEighteenToThirtyPPSC === true ? `The patient is <span class='text-sjTestPositive'>At Risk</span> on the ${title}` : `The patient is <span class='text-sjTestNegative'>Not At Risk</span> on the ${title}`) : `${title}` }`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }
    function posiConditionQuestionAnswers(index: number) {
        if("posi" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition.posi
            const title = CONDITION_MAP["posi"]
            const styledTitle = `${screenerScores.eighteenToThirtyMonthComplete ? (screenerScores?.potentialEighteenToThirtyPOSI === true ? `The patient is <span class='text-sjTestPositive'>At Risk</span> on the ${title}` : `The patient is <span class='text-sjTestNegative'>Not At Risk</span> on the ${title}`) : `${title}` }`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }
    function parentConcernConditionQuestionAnswers(index: number) {
        if("parent-concern" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition["parent-concern"]
            const title = CONDITION_MAP["parent-concern"]
            const styledTitle = `${screenerScores.eighteenToThirtyMonthComplete ? (screenerScores?.potentialEighteenToThirtyParentConcern === true ? `The caregiver has <span class='text-sjTestPositive'>Indicated</span> ${title}` : `The caregiver has <span class='text-sjTestNegative'>Denied</span> ${title}`) : `${title}` }`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }
    function tobaccoConditionQuestionAnswers(index: number) {
        if("tobacco" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition.tobacco
            const title = CONDITION_MAP["tobacco"]
            const styledTitle = `${screenerScores.eighteenToThirtyMonthComplete ? (screenerScores?.potentialEighteenToThirtyTobacco === true ? `The caregiver has <span class='text-sjTestPositive'>Indicated</span> ${title}` : `The caregiver has <span class='text-sjTestNegative'>Denied</span> ${title}`) : `${title}`}`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }
    function substanceAbuseConditionQuestionAnswers(index: number) {
        if("substance-abuse" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition["substance-abuse"]
            const title = CONDITION_MAP["substance-abuse"]
            const styledTitle = `${screenerScores.eighteenToThirtyMonthComplete ? (screenerScores?.potentialEighteenToThirtySubstanceAbuse === true ? `The caregiver has <span class='text-sjTestPositive'>Indicated</span> ${title}` : `The caregiver has <span class='text-sjTestNegative'>Denied</span> ${title}`) : `${title}`}`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }
    function foodInsecurityConditionQuestionAnswers(index: number) {
        if("food-insecurity" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition["food-insecurity"]
            const title = CONDITION_MAP["food-insecurity"]
            const styledTitle = `${screenerScores.eighteenToThirtyMonthComplete ? (screenerScores?.potentialEighteenToThirtyFoodInsecurity === true ? `The caregiver has <span class='text-sjTestPositive'>Indicated</span> ${title}` : `The caregiver has <span class='text-sjTestNegative'>Denied</span> ${title}`) : `${title}`}`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }
    function parentalDepressionConditionQuestionAnswers(index: number) {
        if("parental-depression" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition["parental-depression"]
            const title = CONDITION_MAP["parental-depression"]
            const styledTitle = `${screenerScores.eighteenToThirtyMonthComplete ? (screenerScores?.potentialEighteenToThirtyParentalDepression === true ? `The caregiver has <span class='text-sjTestPositive'>Indicated</span> ${title}` : `The caregiver has <span class='text-sjTestNegative'>Denied</span> ${title}`) : `${title}`}`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }
    function domesticViolenceConditionQuestionAnswers(index: number) {
        if("domestic-violence" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition["domestic-violence"]
            const title = CONDITION_MAP["domestic-violence"]
            const styledTitle = `${screenerScores.eighteenToThirtyMonthComplete ? (screenerScores?.potentialEighteenToThirtyDomesticViolence === true ? `The caregiver has <span class='text-sjTestPositive'>Indicated</span> ${title}` : `The caregiver has <span class='text-sjTestNegative'>Denied</span> ${title}`) : `${title}`}`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }
    function readingFrequencyConditionQuestionAnswers(index: number) {
        if("reading-frequency" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition["reading-frequency"]
            const title = CONDITION_MAP["reading-frequency"]
            const styledTitle = `${screenerScores.eighteenToThirtyMonthComplete ? (screenerScores?.potentialEighteenToThirtyReadingFreq === true ? `The patient has received a <span class='text-sjTestPositive'>Positive</span> score on the ${title}` : `The patient has received a <span class='text-sjTestNegative'>Negative</span> score on the ${title}`) : `${title}`}`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }

    return questionAnswers && questionAnswers.length > 0
        ? (<>
            { developmentalConditionQuestionAnswers(1) }
            { ppscConditionQuestionAnswers(2) }
            { posiConditionQuestionAnswers(3) }
            { parentConcernConditionQuestionAnswers(4) }
            { tobaccoConditionQuestionAnswers(5) }
            { substanceAbuseConditionQuestionAnswers(6) }
            { foodInsecurityConditionQuestionAnswers(7) }
            { parentalDepressionConditionQuestionAnswers(8) }
            { domesticViolenceConditionQuestionAnswers(9) }
            { readingFrequencyConditionQuestionAnswers(10) }
        </>)
        : null
}

export default EighteenToThirtyResults