import React from "react";
import AppointmentsButton from "../common/AppointmentsButton";
import PatientsButton from "../common/PatientsButton";
import {RoutePaths} from "../../../shared/Utils";
import {Bars3BottomLeftIcon, DocumentPlusIcon, UsersIcon, ShieldExclamationIcon} from "@heroicons/react/24/outline";
import ReferredPatientsButton from "../common/ReferredPatientsButton";
import { CiMedicalClipboard } from "react-icons/ci";
import DischargedPatientsButton from "../common/DischargedPatientsButton";
import ComplianceButton from "../common/ComplianceButton";
import {useFlags} from "flagsmith/react";

const CounsellorSideBar : React.FC = () => {

    const isComplianceEnabled = useFlags(['non_physician_compliance_for_sw'])?.non_physician_compliance_for_sw?.enabled;

    function render() {
        return <div>
            {isComplianceEnabled && <ComplianceButton component={"compliance"} navigateTo={`${RoutePaths.collaborativeCare.compliance.root}`} Icon={ShieldExclamationIcon}/> }
            <ReferredPatientsButton component={"prospects"} navigateTo={`${RoutePaths.collaborativeCare.prospects.root}?page=1`} Icon={DocumentPlusIcon}/>
            <PatientsButton component={"patients"} navigateTo={`/${RoutePaths.collaborativeCare.patients.root}?page=1`} Icon={UsersIcon}/>
            <DischargedPatientsButton component={"discharged-patients"} navigateTo={`/${RoutePaths.collaborativeCare.dischargedPatients.root}?page=1`} Icon={CiMedicalClipboard}/>
            <AppointmentsButton component={"appointments"}  navigateTo={`/${RoutePaths.collaborativeCare.appointments.root}?page=1`} Icon={Bars3BottomLeftIcon}/>
        </div>
    }
    return <>
        {render()}
    </>
}

export default CounsellorSideBar
    