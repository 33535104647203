import {AnyAction} from "redux";
import {
    ISWListWithPendingNotesOrScreeners,
} from "../../../models";
import { FETCH_COMPLIANCE_SW_LIST_FAILED, FETCH_COMPLIANCE_SW_LIST_IN_PROGRESS, FETCH_COMPLIANCE_SW_LIST_SUCCESS } from "../../actions/care-coordinator/compliance.action";

export interface CareCoordinatorComplianceParams {
    swList: ISWListWithPendingNotesOrScreeners[]
    swListCount?: number
    tabCount?: Record<string, number>;
    isComplianceSWListSucess?: boolean;
    isComplianceSWListInProgress?: boolean;
    isComplianceSWListFailed?: boolean;
    complianceSWListError?: string
}

const initialState: CareCoordinatorComplianceParams = {
    swList: [],
    swListCount: undefined,
    tabCount: undefined,
    isComplianceSWListSucess: undefined,
    isComplianceSWListInProgress: undefined,
    isComplianceSWListFailed: undefined,
    complianceSWListError: undefined
}

const CareCoordinatorComplianceReducer = (state: CareCoordinatorComplianceParams = initialState, action: AnyAction): CareCoordinatorComplianceParams => {
    switch (action.type) {
        case FETCH_COMPLIANCE_SW_LIST_IN_PROGRESS:
            state = {
                ...state,
                complianceSWListError: undefined,
                isComplianceSWListInProgress: true,
                isComplianceSWListSucess: false,
                isComplianceSWListFailed: false
            }
            return state;
        case FETCH_COMPLIANCE_SW_LIST_SUCCESS:
            state = {
                ...state,
                complianceSWListError: undefined,
                isComplianceSWListInProgress: false,
                isComplianceSWListSucess: true,
                isComplianceSWListFailed: false,
                swList: action.payload.swList,
                swListCount: action.payload.swListCount,
                tabCount: action.payload.tabCount
            }
            return state;
        case FETCH_COMPLIANCE_SW_LIST_FAILED:
            state = {
                ...state,
                isComplianceSWListInProgress: false,
                isComplianceSWListSucess: false,
                isComplianceSWListFailed: true,
                complianceSWListError: action.payload.error
            }
            return state;
        default:
            return state;
    }
}

export default CareCoordinatorComplianceReducer
