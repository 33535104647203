import { useEffect, useState } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom"
import { NotesStatus } from "../../../models";
import { fetchCatchupNotes, fetchPatient, fetchTreatmentNotes, reviewAndSubmitTreatmentNotes } from "../../../store/actions/counsellor/patients.action";
import { StateParams } from "../../../store/reducers";
import { Button } from "../../generic/Buttons";
import { toast } from "react-toastify";
import ClinicalNotesComponent from "../ClinicalNotesComponent";
import { canAddPsychiatristNotes, canCounsellorSubmit, canEditCounsellorNotes, isCounsellorNotesLocked, isNotesLocked, isPsychiatrist } from "../accessControl";
import CatchupNotes from "./CatchupNotes";
import NotesErrorModal from "../NotesErrorModal";

const PatientCatchupNotesComponent: React.FC<{ patientProspectId?: string, notesSessionId?: string }> = ({ patientProspectId: patientId, notesSessionId }) => {
    const { notesStatus, sessionNotesId: catchupNotesId } = useSelector((state: StateParams) => state.patients)
    const patient = useSelector((state: StateParams) => state.patients.patient)
    const isZoomSessionActive = useSelector((state: StateParams) => state.practitionerAccount.isZoomSessionActive)
    const dispatch = useDispatch()
    const params = useParams()
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [notesId, setNotesId] = useState<string | null>(searchParams.get('notesId'))
    const role = useSelector((state: StateParams) => state.account.role);
    const [canEdit, setCanEdit] = useState<boolean>(false)
    const [isAddendumAdded, setIsAddendumAdded] = useState<boolean>(false)
    const [hasSaved, setHasSaved] = useState<boolean>(false)
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [notesIdError, setNotesIdError] = useState<boolean>(false)

    useEffect(() => {
        const id = patientId || params.patientProspectId
        if (id && notesId) {
            batch(() => {
                dispatch(fetchPatient(id))
                dispatch(fetchCatchupNotes(id, notesId))
            })
        } else if(!notesId) {
            setNotesIdError(true)
        }
    }, [patientId, params])

    useEffect(() => {
        if (notesStatus) {
            const canEditNotes = canEditCounsellorNotes(notesStatus, role)
            setCanEdit(canEditNotes)
        }
    }, [notesStatus])

    const handleAddendum = () => setIsAddendumAdded(true)
    const handleSave = () => setHasSaved(true)
    const handleSubmit = () => setHasSubmitted(true)
    
    function renderAddAddendumButton() {
        const renderBtn = () => <Button className={"!bg-sjOrange !text-white !ml-2"} data-testid="addAddendumBtn" onClick={handleAddendum}>Add Addendum note</Button>
        if(isCounsellorNotesLocked(notesStatus, role)) {
            return renderBtn()
        } else if(isPsychiatrist(role) && canAddPsychiatristNotes(notesStatus, role)) {
            return renderBtn()
        }
    }

    function renderSaveAndSubmit() {
        const btns = () => <div className="flex justify-end mt-4">
            <Button className={"!bg-sjOrange !text-white !ml-2"} onClick={handleSave} data-testid="saveCatchup">Save</Button>
            <Button className={"!bg-sjOrange !text-white !ml-2"} onClick={handleSubmit} data-testid="submitCatchup">Submit</Button>
        </div>
        if(canCounsellorSubmit(notesStatus, role, isZoomSessionActive)) {
            return btns()
        }
    }

    function actionComponent() {
        return <>
            {renderAddAddendumButton()}
            {renderSaveAndSubmit()}
        </>
    }

    function renderNotesSection() {
        return (<>
            {patient && <CatchupNotes patientId={patient.id} canEdit={canEdit} notesId={notesId} 
            isAddendumAdded={isAddendumAdded} setIsAddendumAdded={setIsAddendumAdded}
            hasSaved={hasSaved} setHasSaved={setHasSaved}
            hasSubmitted={hasSubmitted} setHasSubmitted={setHasSubmitted}/>}
        </>)
    }

    return (<>
        {patient && patient.id && <ClinicalNotesComponent title="Catchup Notes" patient={patient} actionComponent={actionComponent()} notesSessionId={notesSessionId} isModalOpen={isModalOpen}>
            {renderNotesSection()}
        </ClinicalNotesComponent>}
        <NotesErrorModal isOpen={notesIdError}/>
        </>
    )
}

export default PatientCatchupNotesComponent