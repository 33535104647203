import React, { useEffect } from "react";
import { IProspect } from "../../../models";
import SWScreeners from "../../insights/SWScreeners";
import { useDispatch, useSelector } from "react-redux";
import { fetchSWScreenersInsights } from "../../../store/actions/counsellor/patients.action";
import { StateParams } from "../../../store/reducers";

const PatientInsights: React.FC<{ patient: IProspect }> = ({ patient }) => {
    const dispatch = useDispatch()
    const { swScreenerInsights, fetchSwScreenerInsightsInProgress, patientJourney } = useSelector((state: StateParams) => state.patients)
    
    useEffect(() => {
        if(patient && patient.sourcePatientId && !swScreenerInsights) {
            dispatch(fetchSWScreenersInsights(patient.id, patient.sourcePatientId, false))
        }
    }, [patient?.id])

    if(fetchSwScreenerInsightsInProgress) {
        return (
            <div className="text-center text-sjGrey-400 mt-5">Loading...</div>
        )
    }

    return (
        <div>
            {patientJourney ? 
            <SWScreeners patient={patient} key={'sw-screeners-scores'}/> : <div className="text-center text-sjGrey-400 mt-5">No data.</div>}
        </div>
    )
}

export default PatientInsights