import React from 'react';
import {AiOutlineAudioMuted} from "react-icons/ai";
import classNames from 'classnames';
import './Avatar.scss';
import {Participant} from '../../types/ZoomIndexTypes';

interface AvatarProps {
  participant: Participant;
  style?: { [key: string]: string };
  isActive: boolean;
  className?: string;
}
const Avatar = (props: AvatarProps) => {
  const { participant, style, isActive, className } = props;
  const { displayName, audio, muted, bVideoOn } = participant;
  return (
    <div
      className={classNames('avatar', { 'avatar-active': isActive }, className)}
      style={{ ...style, background: bVideoOn ? 'transparent' : 'rgb(26,26,26)' }}
    >
      {(bVideoOn || (audio === 'computer' && muted)) && (
        <div className="corner-name">
          {audio === 'computer' && muted && <AiOutlineAudioMuted style={{ color: '#f00' }} />}
          {bVideoOn && <span>{displayName}</span>}
        </div>
      )}
      {!bVideoOn && <p className="center-name">{displayName}</p>}
    </div>
  );
};

export default Avatar;
