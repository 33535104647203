import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {completeIntakeSession, fetchProspect, resetProspectData} from "../../store/actions/counsellor/prospects.action";
import {StateParams} from "../../store/reducers";
import ProspectProfile from "./ProspectProfile";
import {ProspectUserInfo} from "./ProspectUserInfo";
import {ProspectIntakeForm} from "./ProspectIntakeForm";
import {fetchSession} from "../../store/actions/counsellor/appointments.action";
import {IProspect, NotesStatus, SessionTypes} from "../../models";
import {Button} from "../generic/Buttons";
import {toast} from "react-toastify";
import {joiResolver} from "@hookform/resolvers/joi";
import Joi from "joi";
import {Inputs} from "../clinical-notes/intake";
import {schema} from "../clinical-notes/intake/Validations";
import {useFieldArray, useForm} from "react-hook-form"
import {useModalContext} from "../../context/ModalContext";
import ConfirmationModal from "../clinical-notes/ConfirmationModal";
import {
    canAddPsychiatristNotes,
    canCounsellorSubmit,
    isCounsellorNotesLocked,
    isNotesLocked,
    isPsychiatrist
} from "../clinical-notes/accessControl";
import {DateTime} from "luxon";
import {fromEvent} from "rxjs";
import WarningIcon from "../../assets/images/common/warning.svg"
import {createOrFetchExistingIntake} from "../../store/actions/counsellor/intake.action";
import BackIcon from "../../assets/images/common/back.svg";
import * as _ from "lodash";

const Source = {
    referred: 'referred'
}

const PatientStartIntakeComponent: React.FC = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const params = useParams()
    const location = useLocation();
    const {prospect, intakeForm, completedIntakeProspect } = useSelector((state: StateParams) => state.prospects)
    const sourceRefData = useSelector((state: StateParams) => state.intake.intake)
    const intakeNotesStatus = useSelector((state: StateParams) => state.intake.intake?.notesStatus, shallowEqual)
    const { firstName, lastName } = useSelector((state: StateParams) => state.account)
    const {session} = useSelector((state: StateParams) => state.appointments)
    const isZoomSessionActive = useSelector((state: StateParams) => state.practitionerAccount.isZoomSessionActive)
    const role = useSelector((state: StateParams) => state.account.role)
    const [isConsentGivenForCoCM, setIsConsentGivenForCoCM] = useState<boolean>(false)
    const [showSubmitConfirmationModal, setShowSubmitConfirmationModal] = useState(false)
    const { toggleModal, isModalOpen } = useModalContext();
    const [isProspectHeaderSticky, setIsProspectHeaderSticky] = useState<boolean>(false);
    const source = location.state?.source
    
    useEffect(() => {
        const container = document.getElementById("main-content-container");
        if (container) {
            const subscription = fromEvent(container, 'scroll').subscribe(() => {
                const element = document.getElementById('prospectIvalHeader');
                // Your logic for handling scroll events goes here
                if (element){
                    const rect = element.getBoundingClientRect();
                    const top = rect.top;

                    // Check if the element's top position is zero or less, meaning it's stuck
                    if (top <= 0 && !isProspectHeaderSticky) {
                        console.log('Element is now sticky');
                        setIsProspectHeaderSticky(true);
                    } else if (top > 0 && isProspectHeaderSticky) {
                        setIsProspectHeaderSticky(false);
                    }
                }
            });

            return () => {
                // Unsubscribe from the observable when the component unmounts
                subscription.unsubscribe();
            };
        }
    }, [isProspectHeaderSticky]);

    const { register, handleSubmit, control, watch, formState: { errors }, reset, trigger, getValues } = useForm<Inputs>({
        resolver: joiResolver(Joi.object(schema), { allowUnknown: true })
    })

    const { fields: addendums, append, remove } = useFieldArray({
        control,
        name: "newAddendum"
    })

    useEffect(() => {
        const id = String(params.prospectId)
        if (id) dispatch(fetchProspect(id))
        return () => {
            dispatch(resetProspectData())
        }
    }, [params.prospectId])

    useEffect(() => {
        if(prospect) {
            dispatch(fetchSession(prospect.id, SessionTypes.Intake))
            dispatch(createOrFetchExistingIntake(prospect.id));
        }
    }, [prospect])


    useEffect(() => {
        if(intakeForm?.patientIntakeNotes || sourceRefData?.additionalInfo) {
            let consentGivenCollaborativeCareFromIntake = intakeForm?.patientIntakeNotes.isConsentGivenCollaborativeCare
            let consentGivenCollaborativeCareFromSource = sourceRefData?.additionalInfo.isConsentGivenCollaborativeCare
            const consentGivenCollaborativeCare = (consentGivenCollaborativeCareFromIntake || consentGivenCollaborativeCareFromSource || "No") === "Yes";
            setIsConsentGivenForCoCM(consentGivenCollaborativeCare)
        }
    }, [
        sourceRefData, intakeForm
    ])


    const onCounsellorSubmit = (canContinue: boolean) => {
        const noteId = sourceRefData?.noteId
        if(canContinue && prospect && session && noteId) {
            dispatch(completeIntakeSession(prospect.id, session.id, isConsentGivenForCoCM, prospect.sourcePatientId, noteId, NotesStatus.PENDING_PSYCH_REVIEW))
            setShowSubmitConfirmationModal(false)
        }
    }

    useEffect(() => {
        if(completedIntakeProspect) {
            navigate(-2)
        }
    },[completedIntakeProspect])


    const getPsychiatristNotesLabel = () => {
        if(canAddPsychiatristNotes(intakeNotesStatus, role)) return 'Add'
    }

    const onSave = () => {
        toast("Notes has been saved successfully.")
    }

    const handleAddendum = () => {
        const createdBy = `${firstName} ${lastName}`
        if(addendums.length === 0) append({ addendum: '', by: createdBy, on: DateTime.now().toISO()})
    }

    const handleCounsellorLock = (data: Inputs) => {
        setShowSubmitConfirmationModal(true)
    }

    const onError = (errors: any) => {
        console.log(`Errors when submitting the Initial Evaluation => `, errors)
        toast.error('Mandatory fields must be filled before submitting the Initial Evaluation ')
    }

    const renderSubmitForReviewButton = () => <>
        {canCounsellorSubmit(intakeNotesStatus, role, false) &&
            <button type="submit" onClick={async () => await trigger()}
                    className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium 
        rounded-md text-gray-700 bg-white hover:bg-gray-50 !bg-sjOrange !text-white !ml-2`}>
                Submit
            </button>}
    </>

    const renderPsychiatristNotesButton = () => <Button className={"!bg-sjOrange !text-white !ml-2"} onClick={toggleModal}>{`${getPsychiatristNotesLabel()} Psychiatrist Notes`}</Button>

    const renderAddViewPsychiatristNotesButton = () => canAddPsychiatristNotes(intakeNotesStatus, role) ? renderPsychiatristNotesButton() : <></>

    const renderSaveButton = () => canCounsellorSubmit(intakeNotesStatus, role, isZoomSessionActive) && <Button className={"!bg-sjOrange !text-white !ml-2"} onClick={onSave}>Save</Button>

    const renderAddAddendumButton = () => {
        if (isCounsellorNotesLocked(intakeNotesStatus, role)) {
            return <Button className={"!bg-sjOrange !text-white !ml-2"} onClick={handleAddendum}>Add Addendum note</Button>
        } else if (isPsychiatrist(role) && isNotesLocked(intakeNotesStatus)) {
            return <Button className={"!bg-sjOrange !text-white !ml-2"} onClick={handleAddendum}>Add Addendum note</Button>
        }
    }

    const actionComponent = () => {
        return <>
            {renderSaveButton()}
            {renderSubmitForReviewButton()}
            {renderAddViewPsychiatristNotesButton()}
            {renderAddAddendumButton()}
        </>
    }

    const renderSubmitForReviewConfirmationModal = () => <ConfirmationModal modalClassNames={'!w-2/6'} isOpen={showSubmitConfirmationModal} onClose={() => setShowSubmitConfirmationModal(false)}
                                                                            onConfirm={() => onCounsellorSubmit(true)} continueButtonName={isConsentGivenForCoCM ? "Submit Note & Accept Patient" : "Submit Note & Not Accept Patient"}
                                                                            key={'counsellorLock'}>
        <span style={{ fontWeight: "bold" }}>Patient Consent to CoCM: {isConsentGivenForCoCM ? "Yes" : "No"} </span>
        <p className="text-sm text-gray-500 mt-[24px]">{isConsentGivenForCoCM ?
            'Submitting this Intake note will accept the patient into Collaborative Care Treatment. The patient details will be submitted to Psychiatrist.'
            : 'Submitting this Intake note will NOT accept the patient into Collaborative Care Trearment. The patient can be viewed under the “Not Accepted” tab in Referred Patients List.'}</p>
        <div className="mt-[24px] flex bg-sjLighterOrange p-1">
            <img src={WarningIcon} width={32} height={32}/>
            <span className="text-sm text-sjOrange">Once the Intake note is submitted, it will be locked & uneditable in the future.</span>
        </div>
    </ConfirmationModal>

    function renderTopNav(patient: IProspect) {
        return <div className={"flex flex-row justify-between"}>
            <div id={"link-section"} className={"flex flex-row gap-2 items-center"}>
                {
                    <Link to={".."} onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                    }}>
                        <img src={BackIcon} width={24} height={24} alt="Go Back" className={"hover:cursor-pointer"}/>
                    </Link>

                }
                <div id={"link-section"} className={"flex flex-row gap-2 items-center"}>
                    <span className="text-xl font-semibold text-sjGray"> Initial Evaluation {'|'} </span>
                    <span className="text-xl font-semibold"> {_.capitalize(patient.firstName)} {_.capitalize(patient.lastName)} </span>
                </div>
            </div>
            <div id={"button-section"} className={"mr-6"}>
                {actionComponent()}
            </div>
        </div>;
    }

    return (
        <form onSubmit={handleSubmit(handleCounsellorLock, onError)}>
            <fieldset>
                <main className={`relative min-h-screen mx-auto flex flex-col`} style={{backgroundColor: '#EEF7FA'}}>
                    <div id={"prospectIvalHeader"} className={`flex-1 ${!isModalOpen ? 'floating-section' : ''} `}>
                        <div className={"p-3"}>
                            {prospect ? renderTopNav(prospect) : null}
                        </div>
                    </div>
                    {prospect
                        ? <div className={`px-5 flex-1`}>
                            <ProspectProfile prospect={prospect}/>
                            <ProspectUserInfo prospect={prospect}/>
                            {sourceRefData
                                ? <ProspectIntakeForm {...{
                                    register,
                                    control,
                                    watch,
                                    errors,
                                    reset,
                                    trigger,
                                    addendums,
                                    append,
                                    remove,
                                    getValues
                                }} prospect={prospect} sourceRefData={sourceRefData}
                                                      intakeNotesStatus={intakeNotesStatus}/>
                                : null
                            }
                        </div>
                        : null
                    }
                </main>

                {renderSubmitForReviewConfirmationModal()}
            </fieldset>
        </form>
    )
}
export default PatientStartIntakeComponent;
