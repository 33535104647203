import {Link, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {fetchProspectWithSummary} from "../../store/actions/counsellor/prospects.action";
import {StateParams} from "../../store/reducers";
import Prospect from "./Prospect";
import BackIcon from "../../assets/images/common/back.svg"
import {IProspect, Roles} from "../../models";
import { clearErrors } from "../../store/actions/counsellor/appointments.action";
import * as _ from "lodash"

const ProspectSummaryComponent = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const { prospect } = useSelector((state: StateParams) => state.prospects)
    const { role } = useSelector((state: StateParams) => state.practitionerAccount)
    const [showAddLocationForm, setShowAddLocationForm] = useState<boolean>(false)

    useEffect(() => {
        const practiceId = searchParams.get('practiceId')
        const sourcePatientId = searchParams.get('sourcePatientId')
        dispatch(clearErrors())
        if (params.prospectId && practiceId) {
            dispatch(fetchProspectWithSummary(String(params.prospectId), String(practiceId), String(sourcePatientId)))
        }
    }, [params.prospectId])

    useEffect(() => {
        if (location.pathname.includes("appointment")) {
            setShowAddLocationForm(true)
        } else {
            setShowAddLocationForm(false)
        }
    }, [location.pathname])

    const renderCreateSessionForm = () => {
        return (
            <div className={"absolute top-0 right-0 w-[57%] bg-white z-[2000]"}>
                <Outlet />
            </div>
        )
    }

    function renderBackButton(patient: IProspect) {
        return <div className={"flex flex-row gap-2 items-center"}>
            <Link to={".."} onClick={(e) => {
                e.preventDefault();
                navigate(-1);
            }}>
                <img src={BackIcon} width={24} height={24} alt="Go Back"
                     className={"hover:cursor-pointer"}/>
            </Link>
            <div id={"link-section"} className={"flex flex-row gap-2 items-center"}>
                <span className="text-xl font-semibold text-sjGray"> Patient Details {'|'} </span>
                <span className="text-xl font-semibold"> {_.capitalize(patient.firstName)} {_.capitalize(patient.lastName)} </span>
            </div>
        </div>;
    }

    const renderSummaryComp = () => {
        return (
            <>
                <div className={showAddLocationForm ? "foreground" : ""}/>
                <div className={showAddLocationForm ? "uneditable" : ""}>
                    <main className="relative min-h-screen mx-auto flex flex-row bg-[#F7F8FA] overflow-auto">
                        <div className="mt-2 px-5 flex-1">
                            {prospect ? renderBackButton(prospect) : null}

                            <div className="mt-4">
                                {prospect && prospect.summary
                                    ? <Prospect prospect={prospect} summary={prospect.summary}/>
                                    : null}
                            </div>
                        </div>
                    </main>
                </div>
            </>
        )

    }

    return (
        <div className={"w-full p-4 h-full bg-[#F7F8FA]"}>
            {renderCreateSessionForm()}
            {renderSummaryComp()}
        </div>
    )
}
export default ProspectSummaryComponent;
