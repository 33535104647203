import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "../../components/generic/Checkbox";
import { fetchProspectReferenceData } from "../../store/actions/counsellor/prospects.action";
import { StateParams } from "../../store/reducers";
import { DateTime } from 'luxon'

type SourceDataProps = {
    key: string
    label?: string
    data?: DataProps[]
}

type DataProps = {
    value: string
    label: string
    key: string
    type: string
    defaultChecked?: boolean
}
const ReferDetails: React.FC = () => {
    const dispatch = useDispatch()
    const { sourceRefData, prospect } = useSelector((state: StateParams) => state.prospects)
    const [allergies, setAllergies] = useState('')
    const [symptoms, setSymptoms] = useState<SourceDataProps>()
    const [problems, setProblems] = useState<SourceDataProps>()
    const [diagnosis, setDiagnosis] = useState<SourceDataProps>()
    const [comorbidities, setComorbidities] = useState<SourceDataProps>()
    const [additionalNotes, setAdditionNotes] = useState('')
    const [isAllergiesChecked, setIsAllergiesChecked] = useState(false)
    const [currentMedications, setCurrentMedications] = useState('')
    const [medicationHistory, setMedicationHistory] = useState('')
    const [isConsentGiven, setIsConsentGiven] = useState<boolean>(false)
    const [referralDate, setReferralDate] = useState('')

    useEffect(() => {
        if (prospect) {
            dispatch(fetchProspectReferenceData(prospect.sourcePatientId, prospect.referredByPracticeId));
        }
    }, []);

    useEffect(() => {
        if (sourceRefData && prospect) {
            setSymptoms(sourceRefData.result.filter(x => x.key === 'symptoms')[0] as SourceDataProps)
            setProblems(sourceRefData.result.filter(x => x.key === 'problems')[0] as SourceDataProps)
            setDiagnosis(sourceRefData.result.filter(x => x.key === 'diagnosis')[0] as SourceDataProps)
            setComorbidities(sourceRefData.result.filter(x => x.key === 'comorbidities')[0] as SourceDataProps)
            if (prospect.referralDetails.physicianNotes) setAdditionNotes(prospect.referralDetails.physicianNotes)
            if (prospect.referralDetails.allergies) {
                setAllergies(prospect.referralDetails.allergies.other)
                setIsAllergiesChecked(prospect.referralDetails.allergies.selected === 'Yes')
            }
            setCurrentMedications(prospect.referralDetails.currentMedications.selected.toString())
            setMedicationHistory(prospect.referralDetails.patientMedicationsHistory.selected.toString())
            setIsConsentGiven(prospect.referralDetails.isConsentGiven === 'Yes')
            setReferralDate(DateTime.fromISO(prospect.updatedAt.toString()).toFormat('MM/dd/yyyy'))
        }
    }, [sourceRefData])

    return (
        sourceRefData?.result.length ?
            <fieldset disabled={true}>
                <div className="mt-5">Date of referral {referralDate}</div>
                <main className="w-full mt-4 p-4 bg-white border-sjBorderGreen rounded-lg shadow-lg">
                    <div>
                        <span>Identified Symptoms/Problems Leading to CoCM Referral</span>
                        <div className="w-full flex mt-2 text-sm">
                            <div className="w-1/2 flex flex-col">
                                <p className="font-bold mb-1">Symptoms</p>
                                {symptoms && symptoms.data?.map((symptom) => {
                                    return (
                                        symptom.type === 'checkbox' && <Checkbox
                                            value={symptom.label}
                                            key={symptom.label}
                                            checked={!!symptom.defaultChecked}
                                            onChange={() => { }}
                                            classNames={'text-gray-400'}
                                        />
                                    )
                                })}
                                <p className="mt-1 ml-4">Other: </p>
                                <input
                                    type={"text"}
                                    className={"ml-4 w-3/4 h-8 mt-1 text-sm rounded-md bg-gray-200"}
                                    placeholder=""
                                    value={symptoms?.data?.filter(x => x.type === 'textbox').length ?
                                        symptoms?.data?.filter(x => x.type === 'textbox')[0].value : ''}
                                    onChange={() => { }}
                                />
                            </div>
                            <div className="w-1/2 flex flex-col">
                                <p className="font-bold mb-1">Problems</p>
                                {problems && problems.data?.map((problem) => {
                                    return (
                                        problem.type === 'checkbox' && <Checkbox
                                            value={problem.label}
                                            key={problem.label}
                                            checked={!!problem.defaultChecked}
                                            onChange={() => { }}
                                            classNames={'text-gray-400'}
                                        />
                                    )
                                })}
                                <p className="mt-1 ml-4">Other: </p>
                                <input
                                    type={"text"}
                                    className={"ml-4 w-3/4 h-8 mt-1 text-sm rounded-md bg-gray-200"}
                                    placeholder=""
                                    value={problems?.data?.filter(x => x.type === 'textbox').length ?
                                        problems?.data?.filter(x => x.type === 'textbox')[0].value : ''}
                                    onChange={() => { }}
                                />
                            </div>
                        </div>
                    </div>
                    <hr className="mt-4 h-[2px] bg-sjGrey" />
                    <div className="mt-2">
                        <span>Additional Physician Notes</span>
                        <div className="mt-2">
                            <textarea
                                className="w-full h-28 text-sm rounded-md bg-gray-200"
                                placeholder=""
                                onChange={() => { }}
                                value={additionalNotes}
                            />
                        </div>
                    </div>
                    <hr className="mt-4 h-[2px] bg-sjGrey" />
                    <div className="mt-2 ">
                        <span>{`Existing Diagnosis (if known)`}</span>
                        <div className="mt-2 text-sm grid grid-cols-2 ">
                            {diagnosis && diagnosis?.data?.map((diagnose, index) => {
                                return (
                                    <Checkbox
                                        key={diagnose.label}
                                        value={diagnose.label}
                                        checked={!!diagnose.defaultChecked}
                                        onChange={() => { }}
                                        classNames={'text-gray-400'}
                                    />
                                )
                            })}
                            <div>
                                <p className="mt-1 ml-4">Other: </p>
                                <input
                                    type={"text"}
                                    className={"ml-4 w-3/4 h-8 mt-1 text-sm rounded-md bg-gray-200"}
                                    placeholder=""
                                    value={diagnosis?.data?.filter(x => x.type === 'textbox').length ?
                                        diagnosis?.data?.filter(x => x.type === 'textbox')[0].value : ''}
                                    onChange={() => { }}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <hr className="mt-4 h-[2px] bg-sjGrey" />
                <div className="mt-2 ">
                    <span>{`Comorbidities`}</span>
                    <div className="mt-2 text-sm flex flex-col">
                        {comorbidities && comorbidities.data?.map((comorbidity, index) => {
                            return (
                                <Checkbox
                                    key={comorbidity.label}
                                    value={comorbidity.label}
                                    checked={!!comorbidity.defaultChecked}
                                    onChange={() => {}}
                                    classNames={'text-gray-400'}
                                />
                            )
                        })}
                        <div>
                            <p className="mt-1 ml-4">Other: </p>
                            <input
                                type={"text"}
                                className={"ml-4 w-3/4 h-8 mt-1 text-sm rounded-md bg-gray-200"}
                                placeholder=""
                                value={comorbidities?.data?.filter(x => x.type === 'textbox').length ?
                                    comorbidities?.data?.filter(x => x.type === 'textbox')[0].value : ''}
                                onChange={() => {}}
                            />
                        </div>
                    </div>
                </div> */}
                    <hr className="mt-4 h-[2px] bg-sjGrey" />
                    <div className="mt-2">
                        <span>Allergies</span><br></br>
                        <span>(including medication allergies)</span>
                        <div className='flex'>
                            <div className='flex items-center h-12 rounded-md mr-4'>
                                <input id='yes' type="radio" name='allergies' checked={isAllergiesChecked}
                                    value='yes' onChange={() => { }} className='h-3 w-3 mr-2 text-gray-400 focus:ring-0' />
                                <p className='text-sm'>Yes</p>
                            </div>
                            <div className='flex items-center h-12 rounded-md mr-4'>
                                <input id='no' type="radio" name='allergies' checked={!isAllergiesChecked}
                                    value='no' onChange={() => { }} className='h-3 w-3 mr-2 text-gray-400 focus:ring-0' />
                                <p className='text-sm'>No</p>
                            </div>
                        </div>
                        {isAllergiesChecked && <div className="mt-2">
                            <textarea
                                className="w-full h-28 text-sm rounded-md bg-gray-200"
                                placeholder=""
                                value={allergies}
                                onChange={(e) => { }}
                            />
                        </div>}
                    </div>
                    <hr className="mt-4 h-[2px] bg-sjGrey" />
                    <div className="mt-2">
                        <span>Psychiatric Medication </span>
                        <div className="flex mt-5"></div>
                        <p className="text-sm">Current psychiatric medications</p>
                        <input type={'text'} className={"ml-4 w-3/4 h-8 mt-1 text-sm rounded-md bg-gray-200"}
                            value={currentMedications} onChange={() => { }} />
                        <p className="mt-2 text-sm">Psychiatric medications in patient history</p>
                        <input type={'text'} className={"ml-4 w-3/4 h-8 mt-1 text-sm rounded-md bg-gray-200"}
                            value={medicationHistory} onChange={() => { }} />
                    </div>
                    <hr className="mt-4 h-[2px] bg-sjGrey" />
                    <div className="mt-2">
                        <p>Did the caregiver and /or patient provide verbal consent to be referred to CoCM?</p>
                        <div className='flex'>
                            <div className='flex items-center h-12 rounded-md mr-4'>
                                <input id='yes' type="radio" name='consent' value={'yes'} checked={isConsentGiven}
                                    className='h-3 w-3 mr-2 text-gray-400 focus:ring-0' onChange={() => { }} />
                                <p className='text-sm'>Yes</p>
                            </div>
                            <div className='flex items-center h-12 rounded-md'>
                                <input id='no' type="radio" name='consent' value={'no'} checked={!isConsentGiven}
                                    className='h-3 w-3 mr-2 text-gray-400 focus:ring-0' onChange={() => { }} />
                                <p className='text-sm'>No</p>
                            </div>
                        </div>
                    </div>
                </main></fieldset> : <></>
    )
}

export default ReferDetails