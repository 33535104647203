import React from "react";
import { classNames } from "../../shared/Utils";
import CSS from 'csstype';
import { IntakeNotesStatus } from "../../models";

type NoteStatusMetadata = {
    description: string
    style?: CSS.Properties
}

export const NOTE_STATUS: Record<keyof IntakeNotesStatus, NoteStatusMetadata> = {
    pending_psych_review: { description: "Review Pending", style: { backgroundColor: "bg-sjLightGray", color: "text-sjGray" } },
    locked: { description: "Review Completed", style: { backgroundColor: "bg-sjLightGreen", color: "text-sjDarkGreen" } },
}

export const NoteStatus: React.FC<{ value: keyof (IntakeNotesStatus) }> = ({value}) => {

    console.log(value,'value');

    const { description, style } = NOTE_STATUS[value];

    return (
        <span
            className={classNames(
                `py-1 px-2 font-bold rounded-full shadow-sm ${style?.backgroundColor} ${style?.color}`
            )}
        >
            {description}
        </span>
    );
}