import {useEffect, useState} from "react";
import {IoArrowBackCircleOutline} from "react-icons/io5"
import {batch, useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useParams} from "react-router-dom"
import { toast } from "react-toastify";
import { useStatusChange } from "../../../hooks/useStatusChange";
import { NotesStatus, Roles, SessionTypes } from "../../../models";
import {fetchPatient, fetchSafetyPlanNotes} from "../../../store/actions/counsellor/patients.action";
import {StateParams} from "../../../store/reducers";
import { Button } from "../../generic/Buttons";
import ClinicalNotesComponent from "../ClinicalNotesComponent";
import SafetyPlanNotes from "./SafetyPlanNotes";

const PatientSafetyPlanNotesComponent: React.FC<{ patientProspectId?: string, notesSessionId?: string }> = ({patientProspectId: patientId, notesSessionId}) => {
    const {patient, notesStatus, sessionNotesId} = useSelector((state: StateParams) => state.patients)
    const role = useSelector((state: StateParams) => state.account.role)
    const dispatch = useDispatch()
    const params = useParams()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)
    const [notesId, setNotesId] = useState(searchParams.get('notesId'))
    const [isPsychiatrist, setIsPsychiatrist] = useState<boolean>(Roles.Psychiatrist === role)
    const [canEdit, setCanEdit] = useState<boolean>(false)
    const dispatchNotesStatusChange = useStatusChange(SessionTypes.SafetyPlan)

    useEffect(() => {
        const id = patientId || params.patientProspectId
        if(id) {
            batch(() => {
                dispatch(fetchPatient(id))
                dispatch(fetchSafetyPlanNotes(id))
            })
        }
    }, [patientId, params])

    useEffect(() => {
        if(notesStatus) {
            const canEditNotes = notesStatus === NotesStatus.LOCKED ? false : isPsychiatrist || role === Roles.Physician ? false : true
            setCanEdit(canEditNotes)
        }
    }, [notesStatus])

    const handleStatusChange = (status: NotesStatus) => {
        const id = patientId || params.patientProspectId
        //when accessed from the session, the `sessionNotesId` should be used.
        //when accessed from the journey, the `notesid` should be used.
        const documentId = notesId || sessionNotesId
        if(id && documentId) {
            batch(() => {
                dispatchNotesStatusChange(status, documentId, id)
                if (status === NotesStatus.LOCKED && patient) {
                    toast("Notes has been locked successfully.")
                } else {
                    toast("Notes has been sent for review successfully.")
                }
            })
        }
    }

    const renderSubmitForReviewButton = () => <>
        {(role === Roles.Counsellor || role === Roles.Admin) && notesStatus !== NotesStatus.LOCKED &&
            <Button className={"!bg-sjOrange !text-white"} onClick={() => handleStatusChange(NotesStatus.PENDING_PSYCH_REVIEW)}>Submit for Review
            </Button>}
    </>

    const renderLockButton = () => <>
        {isPsychiatrist && notesStatus === NotesStatus.PENDING_PSYCH_REVIEW && <Button className={"!bg-sjOrange !text-white"}
            onClick={() => handleStatusChange(NotesStatus.LOCKED)}>Lock</Button>}
    </>

    const actionComponent = () => {
        return <>
            {renderSubmitForReviewButton()}
            {renderLockButton()}
        </>
    }

    function renderNotesSection() {
        return (<>
                {patient && <SafetyPlanNotes patientId={patient.id} canEdit={canEdit} isPsychiatrist={isPsychiatrist} />}
        </>)
    }

    return (
        <ClinicalNotesComponent title="Safety Plan" patient={patient} actionComponent={actionComponent()} notesSessionId={notesSessionId}>
            {renderNotesSection()}
        </ClinicalNotesComponent>
    )
}

export default PatientSafetyPlanNotesComponent
