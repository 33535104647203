import React from "react";
import ConfirmationModal from "../../../components/clinical-notes/ConfirmationModal";
import { IPatientInCall } from "../../../models";
import VoipPhoneNumber from "../../../components/calls/VoipPhoneNumber";
import { MaskPhoneNumber } from "../../../components/generic/MaskPhoneNumber";

interface CallModalProps {
    isModalOpen: boolean
    handleCloseModal: () => void
    prospectDetails: IPatientInCall
}

const CallModalComponent: React.FC<CallModalProps> = ({ isModalOpen, handleCloseModal, prospectDetails}) => {
    const patientName = `${prospectDetails.firstName} ${prospectDetails.lastName}`
    const capitalizedName = patientName.replace(/\b\w/g, (c) => c.toUpperCase())

    const CallPatientAction = () => {
        const patient: IPatientInCall = {
            id: prospectDetails.id,
            sourcePatientId: prospectDetails.id,
            referredByPracticeId: prospectDetails.referredByPracticeId,
            firstName: prospectDetails.firstName,
            lastName: prospectDetails.lastName,
            dob: prospectDetails.dob as unknown as Date,
            phoneNumber: prospectDetails.phoneNumber,
        }
        return <VoipPhoneNumber patient={patient} onCallInitated={handleCloseModal}/>
    }

    const renderCallModal = () => {
        return (
        <ConfirmationModal isOpen={isModalOpen} onClose={handleCloseModal}
            key={'callModal'}
            actions={CallPatientAction()}
            >
            <header className="text-[20px] font-semibold text-[#5D7285] leading-27">{`Call ${capitalizedName}`}</header>
            <p className="text-sm text-[#5D7285] text-opacity-60 leading-22">
                {`Proceed to call `}<MaskPhoneNumber value={prospectDetails.phoneNumber} disabled={true} className={'border-0 bg-transparent'}/>
            </p>
        </ConfirmationModal>
        )
    }

    return (
        <>
            {renderCallModal()}
        </>
    )
}
export default CallModalComponent;