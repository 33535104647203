import React, { useCallback } from "react";
import { FiCheckCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { patient_status } from "../../../../models";
import { Button } from "../../../generic/Buttons";
import { useSelector } from "react-redux";
import { StateParams } from "../../../../store/reducers";
import { Tooltip } from "react-tooltip";

interface Value {
    url: string
    patientStatus: patient_status
    canBeReferred: boolean
    analyticsAction: () => void
}

export const ReferButton: React.FC<{ value: Value }> = ({ value }) => {
    const navigate = useNavigate();
    const { role, canMARefer } = useSelector((state: StateParams) => state.account);
    const canRefer = role === 'Physician' || (role === 'Medical_Assistant' && canMARefer);

    const goToReferNotes = useCallback(() => {
        value.analyticsAction()
        navigate(value.url)
    }, [value])

    function referButtonTooltip() {
        return <Tooltip style={{ borderRadius: 5 }} id="refer-button-tooltip">
            <div>
                You don't have access to refer
            </div>
        </Tooltip>
    }

    function referDisableButtonTooltip() {
        return <Tooltip style={{ borderRadius: 5, width: 290 }} id="refer-disable-tooltip">
            <div className="text-[14px] text-wrap text-center">
                Patient screener incomplete. Complete screener to refer.
            </div>
        </Tooltip>
    }

    return (
        <>
            {value.patientStatus === 'inactive' ?
                <div>
                    {!canRefer
                        ? <>Not eligible</>
                        : (<Button
                            data-tooltip-id={canRefer ? value.canBeReferred ? '' : 'refer-disable-tooltip' : "refer-button-tooltip"} 
                            className={`rounded-l-md !px-2 !py-1 ${
                                canRefer && value.canBeReferred
                                    ? "border-sjOrange text-sjOrange"
                                    : "border-sjPediatrician text-sjPediatrician cursor-not-allowed"
                            }`}
                            disabled={!canRefer || !value.canBeReferred}
                            onClick={goToReferNotes}>Refer</Button>)
                    }
                </div>
                :
                <div className={"flex flex-row items-center"}>
                    <FiCheckCircle className="mr-1" aria-hidden="true" />
                    <span>Referred</span>
                </div>
            }
            {canRefer ? 
                value.canBeReferred ? null : referDisableButtonTooltip() : referButtonTooltip() }
        </>
    )
}
