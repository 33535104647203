import React from "react";
import {IProspectScreenerResponse} from "../../models";
import QuestionAnswer from "../generic/QuestionAnswer";

const SecondaryResults: React.FC<{backgroundColor: string, screenerScoreMessage: string, questionAnswers: IProspectScreenerResponse[]}> = ({backgroundColor, screenerScoreMessage, questionAnswers}) => {
    function renderQuestionAnswers(groupedQuestionAnswers: IProspectScreenerResponse[]) {
        return groupedQuestionAnswers && groupedQuestionAnswers.length > 0 ?
            (
                <div className={"flex flex-col"}>
                    <div className={`m-2 w-full uppercase p-3 rounded ${backgroundColor}`}> <span dangerouslySetInnerHTML={{__html: screenerScoreMessage || ""}} /> </div>
                    {groupedQuestionAnswers.map((questionAnswer, index) => {
                        return <QuestionAnswer key={questionAnswer.questionId} questionAnswer={questionAnswer}
                                               index={index}/>
                    })}
                </div>
            )
            : null;
    }

    function normalConditionQuestionAnswers() {
        return renderQuestionAnswers(questionAnswers);
    }

    return questionAnswers && questionAnswers.length > 0
        ? (<>
            { normalConditionQuestionAnswers() }
        </>)
        : null
}

export default SecondaryResults