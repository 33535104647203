import React, {useState} from 'react'
import {useLocation, useNavigate} from "react-router-dom";
import {Button} from '../../components/generic/Buttons';

interface LocationState {
    isJoin: boolean
}

const JoinScreen: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const [locationState, setLocationState] = useState(location.state as LocationState)
    const [sessionName, setSessionName] = useState('')
    const [sessionPassword, setSessionPassword] = useState('')
    const [displayName, setDisplayName] = useState('')
    const [sessionIdleTimeoutMins, setSessionIdleTimeoutMins] = useState('40')
    const [roleType, setRoleType] = useState('')

    return (
        <div>
            <Button className="rounded-l-md bg-orange-100" onClick={() => navigate('/call', {
                state: {
                    sessionName,
                    sessionPassword,
                    displayName,
                    sessionIdleTimeoutMins,
                    roleType
                }
            })}>
                {locationState.isJoin ? 'Join' : 'Create'}
            </Button>
        </div>
    )
}

export default JoinScreen