import React, {PropsWithChildren} from 'react';
import {ENV} from "../constants"
import mixpanel, {OverridedMixpanel} from 'mixpanel-browser';

export const MixpanelContext = React.createContext<OverridedMixpanel | undefined>(undefined);

export const MixpanelProvider : React.FC<PropsWithChildren<any>> = ({children}) => {
    const [mixpanelProvider, setMixpanelProvider] = React.useState<OverridedMixpanel | undefined>(undefined);

    React.useEffect(() => {
        console.log(mixpanel)
        console.log(ENV.MIXPANEL_TOKEN)
        mixpanel.init(ENV.MIXPANEL_TOKEN );
        setMixpanelProvider(mixpanel);
    }, []);

    return <MixpanelContext.Provider value={mixpanelProvider}>{children}</MixpanelContext.Provider>;
};
