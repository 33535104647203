import { Card, CardHeader, Radio } from "@material-tailwind/react"
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { CurrentQuestion, IScreenerQuestionAnswer } from '../../../models'
import {
    saveScreenerAnswer
} from "../../../store/actions/counsellor/patients.action";
import { DateTime } from "luxon";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";

const Screeners: React.FC<{ sourcePatientId: string, userScreenerId: string | null, canEdit: boolean, screenerQAndA?: CurrentQuestion, screenerQuestionAnswers?: { [key: string]: IScreenerQuestionAnswer[] } }> = ({ sourcePatientId, userScreenerId, canEdit, screenerQAndA, screenerQuestionAnswers }) => {
    const dispatch = useDispatch()
    const [answeredQuestionsCount, setAnsweredQuestionsCount] = useState({
        count: new Set()
    })

    const onSave = (event: any, questionId: string, userScreenerId: string) => {
        const answerId = event.target.value
        const patientId = sourcePatientId

        const answeredQuestions = screenerQuestionAnswers?.[userScreenerId] || []
        for(const answeredQuestion of answeredQuestions) {
            setAnsweredQuestionsCount({
                count: answeredQuestionsCount.count.add(answeredQuestion.questionId)
            })
        }
        answeredQuestionsCount.count.add(questionId)
        const screenerAnsweredQuestions = answeredQuestionsCount.count.size || 0
        const totalQuestions = screenerQAndA?.allScreenerQuestions?.length || 0
        const screenerStatus = screenerAnsweredQuestions === totalQuestions ? 'finished' : screenerAnsweredQuestions === 1 ? 'in_progress' : undefined

        if (patientId && answerId && questionId && userScreenerId) {
            dispatch(saveScreenerAnswer({patientId, userScreenerId, questionId, answerId, screenerStatus: screenerStatus}))
        }
    }

    return (
        <div>
            <section style={{ backgroundColor: '#FFFFFF' }} className="w-full divide-y mt-4 p-5  min-h-[25vh] rounded-lg shadow-lg">
                <fieldset disabled={!canEdit}>
                    {screenerQAndA && screenerQAndA.allScreenerQuestions?.map((question, index) => (
                        <div key={index} className="p-2">
                            <div>
                                {question.content}
                            </div>
                            <div>
                                {userScreenerId && question.screenerAnswers.map((answer, i) => (
                                    <Radio
                                        id={answer.id}
                                        key={answer.id}
                                        name={question.id}
                                        className="text-sjOrange"
                                        label={answer.content}
                                        value={answer.id}
                                        onClick={event => onSave(event, question.id, userScreenerId)}
                                        defaultChecked={
                                            screenerQuestionAnswers && screenerQuestionAnswers[userScreenerId].some(answeredQuestion => answeredQuestion.answerId === answer.id)
                                        }
                                    />
                                )
                                )}
                            </div>
                            <hr className="border-y-sjGrey-100" />
                        </div>
                    )
                    )}
                </fieldset>
            </section>
        </div>
    )
}

export default Screeners