import { IProspect } from "../../models"
import { convertISODateToMMDDYYYY } from "../../shared/DateUtils"
import ConfirmationModal from "../clinical-notes/ConfirmationModal"

interface Props {
    setCanConfirmationModal: (canShowConfirmationModal: boolean) => void
    canShowConfirmationModal: boolean
    treatmentPlans: { id: string, createdAt: string }[]
    selectedTreatmentPlan: string
    setSelectedTreatmentPlan: (selectedTreatmentPlan: string) => void
    patientName: string
    patientDob: string | null
}

const ConfirmBackdatedModal = ({
    canShowConfirmationModal,
    setCanConfirmationModal,
    treatmentPlans,
    selectedTreatmentPlan,
    setSelectedTreatmentPlan,
    patientDob,
    patientName
}: Props) => {

    const handleTreatmentDate = (event: React.ChangeEvent<HTMLSelectElement> | undefined) => {
        if (event?.target.value) {
            const selected = event?.target.value
            setSelectedTreatmentPlan(selected)
        }
    }

    const handleModalClose = () => {
        setCanConfirmationModal(!canShowConfirmationModal)
        setSelectedTreatmentPlan(treatmentPlans?.[0]?.id)
    }

    const handleContinue = () => {
        setCanConfirmationModal(!canShowConfirmationModal)
        setSelectedTreatmentPlan(selectedTreatmentPlan || treatmentPlans?.[0]?.id)
    }

    return (
        <ConfirmationModal isOpen={canShowConfirmationModal} onClose={handleModalClose}
            key={'confirmAppointmentModal'} onConfirm={handleContinue} modalClassNames={'!w-1/2'}
            continueButtonName="Confirm Treatment Plan"
        >
            <p className="text-lg font-normal text-sjHeader text-left">{`Create Progress Note`}</p>
            <p className="text-xs text-sjGray pb-6 text-left">{`Select Treatment Plan for this Progress Note`}</p>
            <p className="text-base text-left text-sjHeader font-medium pb-6">Patient - {patientName} ({patientDob})</p>
            <p className="text-sjGray text-sm text-left pb-4">This patient has multiple treatment plans. Please select the appropriate treatment plan to associate with the progress note you are creating.</p>
            <div>
                <select onChange={handleTreatmentDate} value={selectedTreatmentPlan}
                    className={`block w-full bg-transparent outline-none py-2 px-4 rounded-lg placeholder-gray-500`}
                    defaultValue={treatmentPlans?.[0]?.id}>
                    {treatmentPlans.map(treatment =>
                        <option key={treatment.id} value={treatment.id}>Treatment Plan - {convertISODateToMMDDYYYY(treatment.createdAt)}</option>
                    )}
                </select>
            </div>
        </ConfirmationModal>
    )
}

export default ConfirmBackdatedModal