import { Radio, Typography } from "@material-tailwind/react"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import statusBucket from "../../assets/data/statusBucket"
import { IProspect, patient_status } from "../../models"
import { RoutePaths } from "../../shared/Utils"
import { updatePatientInfo } from "../../store/actions/counsellor/patients.action"
import ConfirmationModal from "../clinical-notes/ConfirmationModal"

type Props = {
    prospect: IProspect
    patientStatus: string | undefined
    setPatientStatus: (patientStatus: string) => void
}

const DischargePatient = ({ prospect, patientStatus, setPatientStatus }: Props) => {
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(true)
    const [dischargeReason, setDischargeReason] = useState('')
    const paths = useLocation().pathname.split('/')
    const navigate = useNavigate()
    
    const reasons = Object.entries(statusBucket.discharge_reason).map(([key, value]) => ({ value: key, label: value }))

    const handleDischargeReasonSave = () => {
        if(dischargeReason) {
            navigate(`../${RoutePaths.collaborativeCare.patients.dischargeSummary.replace(':patientId', prospect.id)}`, {
                state: { dischargeReason, status: patient_status.discharge_from_cocm, isJourney: false }
            });
            setIsModalOpen(!isModalOpen)
        }
    }

    const handleDischargeReasonClose = () => {
        setIsModalOpen(!isModalOpen)
        setPatientStatus(patient_status.accepted_to_cocm)
    }

    const handleDischargeReasonChange = (reason: React.ChangeEvent<HTMLInputElement> | undefined) => {
        if (reason?.target?.value) {
            setDischargeReason(reason.target.value)
        }
    }

    const Options = ({ reason }: any) => {
        return <div className='flex items-center h-12 rounded-md'>
            <Radio required className="text-md" onChange={handleDischargeReasonChange} name={'dischargeReason'} 
                value={reason.value} checked={dischargeReason === reason.value}
                label={
                    <Typography variant="small" className="flex font-xs" >{reason.label}</Typography>
                } />
        </div>
    }
    
    return (
        <div>
            {paths.includes('journey') && <ConfirmationModal isOpen={isModalOpen} onClose={handleDischargeReasonClose}
                key={'dischargeReasonModal'} onConfirm={handleDischargeReasonSave} modalClassNames={'!w-1/2'}
            >
                <p className="text-lg font-semibold text-sjHeader pb-2 text-left">{`Patient Discharge`}</p>
                <p className="text-sjGray text-sm text-left pb-2">To proceed with discharging the patient, kindly select the appropriate reason for discharge below.</p>
                <div>
                    {reasons.map(reason => <Options reason={reason} />)}
                </div>
                {!dischargeReason && <p className="error-msg">Please select the reason for the patient's discharge.</p>}
            </ConfirmationModal>}
        </div>
    )
}

export default DischargePatient