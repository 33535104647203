import React from "react";
import {Popover, PopoverContent, PopoverHandler,} from "@material-tailwind/react";
import {BsThreeDotsVertical} from "react-icons/bs";
import {useNavigate} from "react-router-dom";

interface Props {
    value?: string;
    navigationUrl: string;
    classname?: string;
}
export const MenuAction: React.FC<Props> = ({ value, navigationUrl, classname }) => {
    const navigate = useNavigate()

    return (
        <div className={classname}>
            <Popover placement="bottom-end">
                <PopoverHandler>
                    <button><BsThreeDotsVertical className="w-5 h-5 cursor-pointer"/></button>
                </PopoverHandler>
                <PopoverContent className="w-30">
                    <button
                        onClick={() => navigate(navigationUrl)}
                        className="block text-sm text-gray-700 hover:bg-gray-100"
                    >
                        {value}
                    </button>
                </PopoverContent>
            </Popover>
        </div>
    );
}
