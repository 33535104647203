import {
    Control,
    Controller,
    FieldErrorsImpl,
    useFieldArray,
    UseFormGetValues,
    UseFormRegister,
    UseFormReset,
    UseFormSetValue,
    UseFormWatch
} from "react-hook-form";
import { Inputs } from "./IndividualPsychiatristNotes";
import { canAddPsychiatristNotes } from "../accessControl";
import { shallowEqual, useSelector } from "react-redux";
import { StateParams } from "../../../store/reducers";
import { useEffect, useState } from "react";
import medications, { frequency, medicationDrugs } from "../../../assets/data/medications";
import { AiFillCloseCircle } from "react-icons/ai";
import Select from 'react-select'

type KeyValuePair = {
    label: string, value: string
}

const ProposedMedication: React.FC<{
    control: Control<Inputs, any>, register: UseFormRegister<Inputs>,
    watch: UseFormWatch<Inputs>, reset: UseFormReset<Inputs>, setValue: UseFormSetValue<Inputs>,
    saveNotes: (key: keyof Inputs, answer: any) => void, getValues: UseFormGetValues<Inputs>
}> = ({ control, reset, register, watch, saveNotes, getValues, setValue }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: "proposedMedication"
    });
    const { notesStatus, psychiatristNotes } = useSelector((state: StateParams) => state.patients, shallowEqual)
    const role = useSelector((state: StateParams) => state.account.role, shallowEqual)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [medicines, setMedicines] = useState<KeyValuePair[]>();

    useEffect(() => {
        const notes = psychiatristNotes?.psychiatristNotes
        if (notes) {
            const proposedMedication = notes?.proposedMedication
            getMedicines(proposedMedication)
            reset(notes)
            setIsLoading(false)
        }
    }, [psychiatristNotes])

    function getMedicines(proposedMedication: any) {
        const medicines = medicationDrugs.map(medicine => ({ label: medicine.drugName, value: medicine.drugName }))
        if(proposedMedication) {
            const savedMedicines = proposedMedication.map((medication: any) => ({
                label: medication.medicine,
                value: medication.medicine
            }))
            const filteredSavedMedicines = savedMedicines.filter((savedMedicine: any) => {
                return !medicines.some((medicine) => medicine.value === savedMedicine.value);
              });
            const completeResultSetOfMedicines = [...medicines, ...filteredSavedMedicines]
            setMedicines(completeResultSetOfMedicines)
        } else {
            setMedicines([...medicines])
        }
        
    }

    const handleAppend = (value: any) => {
        append(value)
        saveNotes('proposedMedication', getValues().proposedMedication)
    };

    const handleDelete = (index: number) => {
        remove(index)
        saveNotes('proposedMedication', getValues().proposedMedication)
    }

    return (
        <fieldset disabled={canAddPsychiatristNotes(notesStatus, role) ? false : true}>
            <div>
                <label className="block text-lg mt-6 uppercase">Proposed Medication:</label>
                <div className="flex">
                    <label className="w-1/2 mr-2 block text-sm">Medicine</label>
                    {/* <label className="w-1/2 mr-2 block text-sm">Medicine Type</label> */}
                    <label className="w-1/2 mr-2 block text-sm">Dosage</label>
                    <label className="w-1/2 mr-2 block text-sm">Frequency</label>
                    <label className="w-1/2  block text-sm">Comments</label>
                </div>
                {fields.map((item, index) => {
                    return (
                        <div key={item.id} className="flex mt-1">
                            <div className="w-1/2 mr-2">
                                {!isLoading && <Controller
                                    key={`med-${index}`}
                                    control={control}
                                    name={`proposedMedication.${index}.medicine`}
                                    render={({ field }) => {
                                        return (
                                            <Select
                                                className="outline-none rounded-md"
                                                options={medicines}
                                                isMulti={false}
                                                isClearable
                                                value={medicines?.find((option) => option.value === field.value)}
                                                onChange={(selectedOption) => {
                                                    if(field.value !== selectedOption?.value) {
                                                        setValue(`proposedMedication.${index}.type`, '')
                                                        setValue(`proposedMedication.${index}.dosage`, '')
                                                        setValue(`proposedMedication.${index}.frequency`, '')
                                                        field.onChange(selectedOption?.value);
                                                    }
                                                }}
                                            />
                                        )
                                    }}
                                />}
                            </div>
                            {/* <div className="w-1/2 mr-2">
                                {!isLoading && <Controller
                                    key={`med-${index}-type`}
                                    control={control}
                                    name={`proposedMedication.${index}.type`}
                                    render={({ field }) => {
                                        const selectedDrugObj = medicationDrugs.find(
                                            drug => drug.drugName === watch(`proposedMedication.${index}.medicine`)
                                        )
                                        const options = selectedDrugObj?.medicineForms.map(form => ({
                                            label: form.form, value: form.form
                                        }))
                                        return (
                                            <Select
                                                className="outline-none rounded-md"
                                                options={options}
                                                key={`select-${index}-type`}
                                                isMulti={false}
                                                isClearable
                                                value={options?.find((option) => option.value === field.value) || null}
                                                onChange={(selectedOption) => {
                                                    if(!selectedOption) {
                                                        setValue(`proposedMedication.${index}.type`, '')
                                                        setValue(`proposedMedication.${index}.dosage`, '')
                                                    } else if(field.value !== selectedOption?.value) {
                                                        setValue(`proposedMedication.${index}.dosage`, '')
                                                    }
                                                    field.onChange(selectedOption?.value || '');
                                                }}
                                            />
                                        )
                                    }}
                                />}
                            </div> */}
                            <div className="w-1/2 mr-2">
                                {!isLoading && <Controller
                                    key={`med-${index}`}
                                    control={control}
                                    name={`proposedMedication.${index}.dosage`}
                                    render={({ field }) => {
                                        const selectedDrugObj = medicationDrugs.find(
                                            drug => drug.drugName === watch(`proposedMedication.${index}.medicine`)
                                        )
                                        const options = selectedDrugObj?.dosages.map(dosage => ({
                                            label: dosage, value: dosage
                                        }))
                                        return (
                                            <Select
                                                className="outline-none rounded-md"
                                                key={`select-${index}-dosage`}
                                                options={options}
                                                isMulti={false}
                                                isClearable
                                                value={options?.find((option) => option.value === field.value) || null}
                                                onChange={(selectedOption) => {
                                                    field.onChange(selectedOption?.value); // Update the form value
                                                }}
                                            />
                                        )
                                    }
                                    }
                                />}
                            </div>
                            <div className="w-1/2 mr-2">
                                {!isLoading && <Controller
                                    control={control}
                                    name={`proposedMedication.${index}.frequency`}
                                    render={({ field }) => (
                                        <Select
                                            className="outline-none rounded-md"
                                            key={`select-${index}-frequency`}
                                            options={frequency}
                                            isMulti={false}
                                            isClearable
                                            value={frequency.find((option) => option.value === field.value) || null}
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption?.value); // Update the form value
                                            }}
                                        />
                                    )}
                                />}
                            </div>
                            <div className="w-1/2 mr-2">
                                <input key={`details-${index}`} {...register(`proposedMedication.${index}.details`)} className="w-full border-2 p-1 rounded-md" />
                            </div>
                            <div className={` text-sjOrange cursor-pointer mt-1`}
                                onClick={() => handleDelete(index)}
                            >
                                {canAddPsychiatristNotes(notesStatus, role) && <AiFillCloseCircle className="text-sjOrange" 
                                style={{ width: '25px', height: '25px' }}  />}
                            </div>
                        </div>
                    )
                })}
                <section>
                <button className={"p-2 mt-1 text-sm bg-sjOrange rounded-md text-sjWhite border-solid border-l-sjOrange"}
                    onClick={() => handleAppend({ medicine: [''], details: '' })}
                >
                    Add Medication
                </button>
            </section>
            </div>
        </fieldset>
    )
};

export default ProposedMedication