import {IPractice} from "../../../models";

export const FETCH_PRACTICE_DETAILS = "FETCH_PRACTICE_DETAILS"
export const FETCHED_PRACTICE_DETAILS = "FETCHED_PRACTICE_DETAILS"
export const FETCHING_PRACTICE_DETAILS_FAILED = "FETCHING_PRACTICE_DETAILS_FAILED"
export const fetchPracticeDetails = (payload: { practiceId: string }) => {
    return {type: FETCH_PRACTICE_DETAILS, payload}
}
export const fetchingPracticeDetailsFailed = (payload: { error: string }) => {
    return {type: FETCHING_PRACTICE_DETAILS_FAILED, payload}
}
export const fetchedPracticeDetails = (payload: { practice: IPractice }) => {
    return {type: FETCHED_PRACTICE_DETAILS, payload}
}