import React, {useEffect, useState} from 'react';
import {useStopwatch} from 'react-timer-hook';

const StopWatch: React.FC<{startClock: boolean}> = ({startClock}) => {
    const { seconds, minutes, hours, start } = useStopwatch();

    useEffect(() => {
        if(startClock) {
            start()
        }
    }, [startClock])

    function padNumberWith0(num: number) {
        return String(num).padStart(2, '0');
    }

    return (
        <span>{padNumberWith0(hours)}:{padNumberWith0(minutes)}:{padNumberWith0(seconds)}</span>
    );
}

export default StopWatch
