import React from "react";
import "../PhysicianDashboardScreen.scss";
import {ImageConfig} from "../../../../constants";
import { useSelector } from "react-redux";
import { StateParams } from "../../../../store/reducers";
import {useFlags} from "flagsmith/react";

interface PhysicianDashboardStatisticsComponentProps {

}

const PhysicianDashboardStatisticsComponent = (props: PhysicianDashboardStatisticsComponentProps) => {

    const {patientsSummary, fetchPatientsSummaryInProgress} = useSelector((state: StateParams) => state.patientsDashboard);
    const isPotentialPatientsCardEnabled = useFlags(['dashboard_potential_patients_count'])?.dashboard_potential_patients_count?.enabled;
    const isSOAPStatsCardEnabled = useFlags(['soap_stats_in_physician_dashboard'])?.soap_stats_in_physician_dashboard?.enabled;

    const renderPhysicianDashboardStatisticsCard = (label: string, count: number | undefined, percentage?: number| undefined) => {
        return (
            <div className={`physician-dashboard-statistics-card`}>
                {fetchPatientsSummaryInProgress && <div className="card-loading"/>}
                <div className="physician-dashboard-statistics-card-label-and-count">
                    <div className="physician-dashboard-statistics-card-count">
                        {count || 0}
                    </div>
                    <div className="physician-dashboard-statistics-card-label">
                        {label}
                    </div>
                </div>
                {percentage && <div className="physician-dashboard-statistics-card-percentage">
                    {percentage}% <ImageConfig.StatsIcon/>
                </div>}
            </div>
        )
    }

    return (
        <div className="physician-dashboard-statistics">
            <div className={"mb-5"}>
                <div className={"font-bold mb-2"}>
                    CoCM Metrics
                </div>
                <div className={`grid grid-cols-1 md:grid-cols-2 ${isPotentialPatientsCardEnabled ? 'lg:grid-cols-5' : 'lg:grid-cols-2'} gap-4`}>
                    {renderPhysicianDashboardStatisticsCard("No. of Locations", patientsSummary?.locationsCount)}
                    {renderPhysicianDashboardStatisticsCard("Patients screened", patientsSummary?.patientsScreened)}
                    {isPotentialPatientsCardEnabled && renderPhysicianDashboardStatisticsCard("Potential patients", patientsSummary?.potentialPatients)}
                    {renderPhysicianDashboardStatisticsCard("Patients referred", patientsSummary?.patientsReferred.count, patientsSummary?.patientsReferred.percentage)}
                    {renderPhysicianDashboardStatisticsCard("Patients enrolled in CoCM", patientsSummary?.patientsEnrolled.count, patientsSummary?.patientsEnrolled.percentage)}
                </div>
            </div>
            {isSOAPStatsCardEnabled && <div className={"mb-5"}>
                <div className={"font-bold mb-2"}>
                    S.O.A.P Metrics
                </div>
                <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4`}>
                    {renderPhysicianDashboardStatisticsCard("No. of S.O.A.P Notes", patientsSummary?.noOfSOAPNotes)}
                </div>
            </div>}
        </div>
    )
}

export default PhysicianDashboardStatisticsComponent;
