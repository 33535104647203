import {MediaDevice} from '../../types/VideoTypes';
import {AiOutlineVideoCamera, AiOutlineVideoCameraAdd} from "react-icons/ai";

interface CameraButtonProps {
    isStartedVideo: boolean;
    isMirrored?: boolean;
    onCameraClick: () => void;
    onSwitchCamera: (deviceId: string) => void;
    onMirrorVideo?: () => void;
    onVideoStatistic?: () => void;
    className?: string;
    cameraList?: MediaDevice[];
    activeCamera?: string;
  }
  
  const CameraButton: React.FC<CameraButtonProps> = (props: CameraButtonProps) => {
    const {
        isStartedVideo,
        className,
        cameraList,
        activeCamera,
        isMirrored,
        onCameraClick,
        onSwitchCamera,
        onMirrorVideo,
        onVideoStatistic
    } = props;

    return (
        <div>
            <button> {isStartedVideo ? <AiOutlineVideoCamera className="h-7 w-7 text-white" title="Turn off camera" aria-hidden="true" onClick={onCameraClick} /> :
                <AiOutlineVideoCameraAdd className="h-7 w-7 text-white" aria-hidden="true" title="Turn on camera" onClick={onCameraClick} />}
            </button>
        </div>
    )
}

export default CameraButton