import {AnyAction} from "redux";
import {
    AggregatePatientSummaryByLocation,
    PatientSummaryResponseType,
    ScreenerCompletionRate
} from "../../../models";
import { FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE, FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_FAILED, FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_IN_PROGRESS, FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_SUCCESS, FETCH_DASHBOARD_PATIENTS_SUMMARY, FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION, FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_FAILED, FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_IN_PROGRESS, FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_SUCCESS, FETCH_DASHBOARD_PATIENTS_SUMMARY_FAILED, FETCH_DASHBOARD_PATIENTS_SUMMARY_IN_PROGRESS, FETCH_DASHBOARD_PATIENTS_SUMMARY_SUCCESS } from "../../actions/physician/dashboard.action";

export interface PatientsDashboardParams {
    noOfSOAPNotes?: number
    patientsSummary?: PatientSummaryResponseType
    fetchPatientsSummaryInProgress?: boolean
    patientsSummaryByLocation: AggregatePatientSummaryByLocation[]
    fetchPatientsSummaryByLocationInProgress?: boolean
    patientsScreenerCompletionRate: ScreenerCompletionRate[]
    fetchPatientsScreenerCompletionRateInProgress?: boolean
}

const initialState: PatientsDashboardParams = {
    patientsSummary: undefined,
    fetchPatientsSummaryInProgress: undefined,
    patientsSummaryByLocation: [],
    fetchPatientsSummaryByLocationInProgress: undefined,
    patientsScreenerCompletionRate: [],
    fetchPatientsScreenerCompletionRateInProgress: undefined
}

const PatientsDashboardReducer = (state: PatientsDashboardParams = initialState, action: AnyAction) : PatientsDashboardParams => {
    switch (action.type) {
        case FETCH_DASHBOARD_PATIENTS_SUMMARY:
            state = {...state, patientsSummary: undefined, fetchPatientsSummaryInProgress: undefined}
            return state
        case FETCH_DASHBOARD_PATIENTS_SUMMARY_FAILED:
            state = {...state, fetchPatientsSummaryInProgress: false}
            return state
        case FETCH_DASHBOARD_PATIENTS_SUMMARY_IN_PROGRESS:
            state = {...state, fetchPatientsSummaryInProgress: true}
            return state
        case FETCH_DASHBOARD_PATIENTS_SUMMARY_SUCCESS:
            state = {...state, patientsSummary: action.payload.patientsSummary, fetchPatientsSummaryInProgress: false}
            return state

        case FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION:
            state = {...state, patientsSummaryByLocation: [], fetchPatientsSummaryByLocationInProgress: undefined}
            return state
        case FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_FAILED:
            state = {...state, fetchPatientsSummaryByLocationInProgress: false}
            return state
        case FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_IN_PROGRESS:
            state = {...state, fetchPatientsSummaryByLocationInProgress: true}
            return state
        case FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_SUCCESS:
            state = {...state, patientsSummaryByLocation: action.payload.patientsSummaryByLocation, fetchPatientsSummaryByLocationInProgress: false}
            return state

        case FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE:
            state = {...state, patientsScreenerCompletionRate: [], fetchPatientsScreenerCompletionRateInProgress: undefined}
            return state
        case FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_FAILED:
            state = {...state, fetchPatientsScreenerCompletionRateInProgress: false}
            return state
        case FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_IN_PROGRESS:
            state = {...state, fetchPatientsScreenerCompletionRateInProgress: true}
            return state
        case FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_SUCCESS:
            state = {...state, patientsScreenerCompletionRate: action.payload.patientsScreenerCompletionRate, fetchPatientsScreenerCompletionRateInProgress: false}
            return state

        default:
            return state;
    }
}

export default PatientsDashboardReducer
