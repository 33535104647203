import {AppointmentStatus, ISession, ITherapistPatient, PatientSessionTypes, SessionTypes} from "../../../models";
import {DateTime} from "luxon";
import {DetailsButtonV2} from "../../generic/DetailsButton";
import {ScheduledButtonV2} from "../../generic/ScheduledButton";
import {IntakeActionPillV2} from "../../generic/IntakeActionPill";
import {ProfileViewV2} from "../../generic/ProfileView";
import {PatientsDataView} from "../common/PatientsDataView";
import { RoutePaths } from "../../../shared/Utils";

const STARTABLE_STATUSES = [AppointmentStatus.started, AppointmentStatus.inProgress, AppointmentStatus.rejoin];

function isSessionReadyToStart(firstNonCancelledIntakeSession: ISession | undefined) {
    const isScheduledIntakeSession = firstNonCancelledIntakeSession
        && (
            [AppointmentStatus.scheduled, AppointmentStatus.rescheduled].includes(firstNonCancelledIntakeSession.appointmentStatus)
            && DateTime.fromISO(firstNonCancelledIntakeSession.scheduledDate) <= DateTime.now()
        );

    return firstNonCancelledIntakeSession
        && (STARTABLE_STATUSES.includes(firstNonCancelledIntakeSession.appointmentStatus) || isScheduledIntakeSession);
}

function isSessionEditable(firstNonCancelledIntakeSession: ISession | undefined) {
    return firstNonCancelledIntakeSession && firstNonCancelledIntakeSession.appointmentId &&
        (
            [AppointmentStatus.scheduled, AppointmentStatus.rescheduled].includes(firstNonCancelledIntakeSession.appointmentStatus)
        );
}

export class CounsellorPatientsDataView implements PatientsDataView {
    private therapistPatients: ITherapistPatient[];

    constructor() {
        this.therapistPatients = []
    }

    setPatients(therapistPatients: ITherapistPatient[]) {
        this.therapistPatients = therapistPatients
    }

    getColumns(): any {
        return () => [
            { header: "Patient Name", accessorKey: "name" },
            { header: "Date of birth", accessorKey: "dateOfBirth" },
            { header: "Referring Physician", accessorKey: "physician" },
            { header: "Screening Summary", accessorKey: "summaryLink", cell: DetailsButtonV2 },
            { header: "Next Session", accessorKey: "timeAndDate", cell: ScheduledButtonV2 },
            { header: "Action", accessorKey: "session", cell: IntakeActionPillV2 },
            { header: "Profile", accessorKey: "profile", cell: ProfileViewV2 }
        ]
    }

    getData(): any {
        return this.therapistPatients.map((therapistPatient) => {
            const notesSession = therapistPatient.patientProspect.patientSessions?.filter(session => {
                return Object.values(PatientSessionTypes).includes(session.sessionType as unknown as PatientSessionTypes)
                    && session.status !== AppointmentStatus.canceled
                    && session.status !== AppointmentStatus.finished
                    && session.status !== AppointmentStatus.noShow
            })[0]
            const noteSessionMappedToCommonSession = notesSession ? {
                appointmentId: notesSession.id,
                sessionType: notesSession.sessionType,
                scheduledDate: notesSession.scheduledDate,
                appointmentStatus: notesSession.status
            } as ISession : undefined

            const sessionReadyToStart = isSessionReadyToStart(noteSessionMappedToCommonSession)
            const intakeSessionEditable = isSessionEditable(noteSessionMappedToCommonSession)

            const dob = DateTime.fromISO(therapistPatient.patientProspect.dob.toString(), {zone: 'utc'}).toFormat('MM-dd-yyyy')

            const handleRoute = (sessionType: string | undefined, patientProspect: string) => {
                switch (sessionType) {
                    case SessionTypes.SafetyPlan: return `${patientProspect}/safety-plan`
                    case SessionTypes.Catchup: return `${patientProspect}/catchup-notes`
                    case SessionTypes.FollowUp: return `${patientProspect}/progress-notes`
                    case SessionTypes.TreatmentPlan: return `${patientProspect}/treatment`
                }
            }
            const sessionLink = handleRoute(notesSession?.sessionType, therapistPatient.patientProspect.id);
            const referringPhysician = therapistPatient.patientProspect.referredByPhysicianFirstName ? `Dr. ${therapistPatient.patientProspect.referredByPhysicianFirstName} ${therapistPatient.patientProspect.referredByPhysicianLastName}` : 'N/A'
            return {
                id: therapistPatient.patientProspect.id,
                summaryLink: {url: `${therapistPatient.patientProspect.id}/summary`, practiceId: therapistPatient.patientProspect.referredByPracticeId, sourcePatientId: therapistPatient.patientProspect.sourcePatientId},
                name: `${therapistPatient.patientProspect.lastName}, ${therapistPatient.patientProspect.firstName}`,
                dateOfBirth: dob,
                physician: referringPhysician,
                session: {link: sessionLink, intakeSessionReadyToStart: sessionReadyToStart},
                timeAndDate: {
                    status: notesSession && notesSession.id ? notesSession.status : "",
                    canSchedule: !notesSession || !notesSession.id,
                    canEdit: intakeSessionEditable,
                    link: `${RoutePaths.collaborativeCare.patients.scheduleIntakeAppointment.replace(':patientId', therapistPatient.patientProspect.id)}`,
                    editLink: notesSession && notesSession.id ? `${therapistPatient.patientProspect.id}/appointments/${notesSession.id}/edit-intake` : null,
                    scheduledDate: notesSession ? notesSession.scheduledDate : null
                },
                profile: `${therapistPatient.patientProspect.id}/journey`
            }
        })
    }

}
