import { useState, useEffect, useCallback } from 'react';
import axios, { AxiosRequestConfig } from 'axios';

interface UseDownloadBlobOptions extends AxiosRequestConfig {
    filename?: string;
    setDownloadButtonClicked?: (value: boolean) => void;
}

const useDownloadBlob = () => {
    const [url, setUrl] = useState<string | null>(null);
    const [options, setOptions] = useState<UseDownloadBlobOptions | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const downloadBlob = useCallback((downloadUrl: string, downloadOptions: UseDownloadBlobOptions) => {
        setUrl(downloadUrl);
        setOptions(downloadOptions);
        downloadOptions.setDownloadButtonClicked && downloadOptions.setDownloadButtonClicked(false);
    }, []);

    useEffect(() => {
        const downloadFile = async () => {
            if (!url || !options) return;

            setLoading(true);
            setError(null);

            try {
                const response = await axios({
                    url: url,
                    method: 'GET',
                    responseType: 'blob', 
                    ...options
                });
                const link = document.createElement('a');
                const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                link.href = blobUrl;
                link.setAttribute('download', options.filename || 'file.pdf');
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(blobUrl);
            } catch (err) {
                setError(err as Error);
            } finally {
                setLoading(false);
                setUrl(null);
                setOptions(null);
            }
        };

        downloadFile();
    }, [url, options]);

    return { downloadBlob, loading, error };
};

export default useDownloadBlob;
