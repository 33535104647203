import React from "react";

interface DataLabelProps {
    label: string;
}

const DataLabelComponent = (props: React.PropsWithChildren<DataLabelProps>) => {

    const {label, children} = props;

    return <div className="data-label-component">
        <div className="data-label-title">{label}</div>
        <div className="data-label-content">{children}</div>
    </div>

}

export default DataLabelComponent;