import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import ComplianceStatisticsCardComponent from "./components/ComplianceStatisticsCardComponent";
import {RoutePaths} from "../../shared/Utils";
import {
    fetchClinicalNotesComplianceStats,
    fetchScreenerComplianceStats
} from "../../store/actions/counsellor/non-physician-compliance.action";

const ComplianceScreen = () => {

    const {
        firstName, lastName
    } = useSelector((state: StateParams) => state.account);
    const {
        screenerComplianceStats,
        isScreenerComplianceStatsInProgress,
        isScreenerComplianceStatsFailed,
        clinicalNotesComplianceStats,
        isClinicalNotesComplianceStatsInProgress,
        isClinicalNotesComplianceStatsFailed
    } = useSelector((state: StateParams) => state.nonPhysicianCompliance);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchScreenerComplianceStats());
        dispatch(fetchClinicalNotesComplianceStats());
    }, [dispatch]);

    const pendingIntakeCount = clinicalNotesComplianceStats?.pendingIntakeCount || 0;
    const totalNotesCount = clinicalNotesComplianceStats?.totalNotesCount || 0;
    const pendingTreatmentCount = clinicalNotesComplianceStats?.pendingTreatmentCount || 0;
    const pendingProgressCount = clinicalNotesComplianceStats?.pendingProgressCount || 0;
    const pendingCatchupCount = clinicalNotesComplianceStats?.pendingCatchupCount || 0;
    const totalPendingNotesCount = clinicalNotesComplianceStats?.totalPendingCount || 0;
    const pendingScreenersCount = screenerComplianceStats?.pendingScreenersCount || 0;
    const totalScreenersCount = screenerComplianceStats?.totalScreenersCount || 0;

    return (
        <div className="flex-1 px-4 py-4 bg-[#F7F8FA] h-full">
            <div className="flex">
                <div className="text-xl"> Hello {firstName} {lastName} 👋</div>
            </div>
            <div className="py-4">
                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
                    <ComplianceStatisticsCardComponent
                        isLoading={isClinicalNotesComplianceStatsInProgress}
                        isFailed={isClinicalNotesComplianceStatsFailed}
                        mode={"notes"}
                        title="Pending Notes"
                        subtitle="Notes that need to be submitted for psychiatrist review/locked."
                        pendingCount={totalPendingNotesCount}
                        totalCount={totalNotesCount}
                        intakesCount={pendingIntakeCount}
                        treatmentPlansCount={pendingTreatmentCount}
                        progressNotesCount={pendingProgressCount}
                        catchUpNotesCount={pendingCatchupCount}
                        footerTitle={"Notes Submission Pending"}
                        actionUrl={RoutePaths.collaborativeCare.compliance.clinicalNotesPendingList}
                    />
                    <ComplianceStatisticsCardComponent
                        isLoading={isScreenerComplianceStatsInProgress}
                        isFailed={isScreenerComplianceStatsFailed}
                        mode={"screeners"}
                        title="Pending Monthly Screeners"
                        subtitle="Number of monthly assigned screeners yet to be completed."
                        pendingCount={pendingScreenersCount}
                        totalCount={totalScreenersCount}
                        footerTitle={"Monthly Screeners Pending"}
                        actionUrl={RoutePaths.collaborativeCare.compliance.screeningPendingPatients}
                    />
                </div>
            </div>
        </div>
    )
}

export default ComplianceScreen;
