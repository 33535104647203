import { Radio, Textarea } from "@material-tailwind/react";
import { UseFormRegister, UseFormWatch } from "react-hook-form";

const RadioWithTextComponent: React.FC<{
    register: UseFormRegister<any>, watch: UseFormWatch<any>, question: string, input: keyof any, inputText: keyof any,
    data: { label: string, value: string }[], hasToDisableOn: string, textPlaceholder?: string
}> = ({ register, watch, question, input, inputText, data, hasToDisableOn, textPlaceholder }) => {
    return (<div>
        {question && <label className="block text-lg mt-2 text-sjOrange uppercase">
            {question}
        </label>}
        <div className="grid grid-cols-6">
            {data.map((x, i) => (<>
                <Radio {...register(input as any)} className="" id={(input as any)+i} label={x.label} value={x.value} />
            </>))}
        </div>
        <div className="grid mt-3">
            <Textarea className="outline-none rounded-md" id={input.toString()} {...register(inputText as any,
                { disabled: watch(input as any) === hasToDisableOn })} placeholder={textPlaceholder ? textPlaceholder : ''} />
        </div>
    </div>)
}

export default RadioWithTextComponent