import React from "react";
import {useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";

const CommonSideBarFooter : React.FC = () => {
    const { firstName, lastName, email } = useSelector((state: StateParams) => state.account)
    const renderFooter = () => {

        return <div className={"flex justify-center items-center bg-[#FFFAF1]"}>
            <div className="pb-6 flex flex-col text-left">
                <p className="mt-1 text-left text-sm text-[#1D1B20]">{`${firstName} ${lastName}`}</p>
                <p className="mt-1 text-left text-xs text-[#5D7285]">{`${email}`}</p>
            </div>
        </div>
    }

    return renderFooter()
}

export default CommonSideBarFooter
