import React, {useCallback, useEffect, useState} from 'react'
import sjCircle from "../../assets/images/loginBackground/sj-circle.png";
import {useDispatch, useSelector} from "react-redux";
import {clearCodeSent, clearErrorMessages, sendEmail} from "../../store/actions/physician/account.action";
import {StateParams} from "../../store/reducers";
import {useNavigate} from "react-router";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {MixpanelContext} from "../../analytics/Mixpanel";
import {FORGOT_PASSWORD_REQUEST} from "../../analytics/Events";
import {RoutePaths} from "../../shared/Utils";

const ForgotPassword: React.FC<{}> = () => {
    const dispatch = useDispatch()
    const analytics = React.useContext(MixpanelContext);
    const navigate = useNavigate()

    const [email, setEmail] = useState<string>()
    const [formErrors, setFormErrors] = useState<string[]>([])
    const {sendEmailError, validateCodeError, isCodeSent} = useSelector((state: StateParams) => state.physicianAccount)

    useEffect(() => {
        dispatch(clearErrorMessages())
    }, [])

    const sendMail = useCallback(() => {
        if(!email || !email.trim()) {
            const emailError = (email && email.trim().length > 0) || "Email is required"
            const errors : string[] = [emailError].filter(e => e !== true) as string[];
            setFormErrors(errors)
            return
        }
        analytics?.track(FORGOT_PASSWORD_REQUEST, {email})
        dispatch(sendEmail({email}))
    }, [email, analytics, dispatch]);

    useEffect(() => {
        if(isCodeSent && !sendEmailError) {
            toast(`Thanks! If there's an account associated with this email, we'll send the password reset instructions immediately.`)
            dispatch(clearCodeSent());
            navigate(RoutePaths.home.physician);
        }
    }, [isCodeSent, sendEmailError, navigate]);

    useEffect(() => {
        if(sendEmailError) {
            setFormErrors((errors) => {
                return errors ? [...errors, sendEmailError] : [sendEmailError]
            })
        }
    }, [sendEmailError])

    useEffect(() => {
        if(validateCodeError) {
            setFormErrors((errors) => {
                return errors ? [...errors, validateCodeError] : [validateCodeError]
            })
        }
    }, [validateCodeError])

    return (
        <main className="xs:pt-28 md:pt-2 0 px-3 xs:h-auto md:h-screen">
            <div className="container mx-auto flex flex-col items-center">
                <div className='w-full h-32 flex items-center justify-center'>
                    <img src={sjCircle} className='h-[200px]' />
                </div>
                <div className="flex-col items-center text-2xl font-bold text-sjAnswer">Forgot password?</div>
                <div className="text-xs text-[#667B8B]">Link reset will be sent to your registered email address</div>
            </div>
            <div className="grid grid-cols-3 gap-6">
                <div></div>
                <div className="pt-10">
                    <label className="block w-90">
                        <span className="text-gray-700">Enter registered email</span>
                        <input
                            type="email"
                            autoComplete={"off"}
                            autoCorrect={"off"}
                            autoCapitalize={"off"}
                            autoFocus={true}
                            className=" mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                            placeholder=""
                            onChange={(e) => {
                                setEmail(e.target.value)
                                setFormErrors([])
                            }}
                        />
                    </label>
                </div>
                <div></div>
            </div>
            <div className="mt-10 grid grid-cols-3 gap-6">
                <div></div>
                <div className="flex justify-center items-center">
                    <button
                        className="bg-sj2 hover:bg-sjOrange text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={sendMail}>
                        Reset Password
                    </button>
                    {/* <ToastContainer
                            autoClose={3000}
                            toastStyle={{ color: "#ffa103", backgroundColor: "#white" }}
                        /> */}
                </div>
                <div></div>
            </div>
            <div className="mt-10 grid grid-cols-3 gap-6">
                <div></div>
                <div className="flex flex-col justify-center items-center">
                    {formErrors?.map((error, index) => {
                        return <div className="text-red-500" key={"error-" + index}>{error}</div>
                    })}
                </div>
                <div></div>
            </div>
        </main>
    )
}

export default ForgotPassword
