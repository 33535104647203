import React, {useState, useEffect} from "react";
import {Button, PageButton, PageButtonV2} from "./Buttons";
import {
    ChevronDoubleLeftIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronDoubleRightIcon
} from '@heroicons/react/20/solid'

type PaginationProps = {
    totalRows: number;
    rowsPerPage: number
    currentPage: number
    recordsPerPageHandler: (recordsPerPage: number) => any
    currentPageHandler: (currentPage: number) => any
    pageSizes?: number[]
    showPages?: boolean
}

const PaginationV2: React.FC<PaginationProps> = ({
                                                   currentPage,
                                                   currentPageHandler,
                                                   totalRows,
                                                   rowsPerPage,
                                                   recordsPerPageHandler,
                                                   pageSizes,
                                                   showPages
                                               }) => {

    pageSizes = pageSizes || [100, 150, 200]
    showPages = showPages === undefined ? true : showPages

    // Calculating max number of pages
    const noOfPages = Math.ceil(totalRows / rowsPerPage);

    // Creating an array with length equal to no.of pages
    const pagesArr = [...new Array(noOfPages)];

    // State variable to hold the current page. This value is
    // passed to the callback provided by the parent
    // const [currentPage, setCurrentPage] = useState(1);

    // Navigation arrows enable/disable state
    const [canGoBack, setCanGoBack] = useState(false);
    const [canGoNext, setCanGoNext] = useState(true);

    // Onclick handlers for the butons
    const onNextPage = () => currentPageHandler(currentPage + 1);
    const onPrevPage = () => currentPageHandler(currentPage - 1);
    const onPageSelect = (pageNo: number) => currentPageHandler(pageNo);

    // Disable previous and next buttons in the first and last page
    // respectively
    useEffect(() => {
        if (noOfPages === currentPage) {
            setCanGoNext(false);
        } else {
            setCanGoNext(true);
        }
        if (currentPage === 1) {
            setCanGoBack(false);
        } else {
            setCanGoBack(true);
        }
    }, [noOfPages, currentPage]);

    // To set the starting index of the page


    function renderPageSizes() {
        return <span className="text-sm text-gray-700">
                            {/* @ts-ignore */}
            Page <span className="font-medium">{currentPage}</span> of <span
            className="font-medium">{noOfPages}</span>{' '}
            {/* @ts-ignore */}
            <select value={rowsPerPage}
                    className={"rounded-full focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"}
                    onChange={e => {
                        recordsPerPageHandler(Number(e.target.value))
                        currentPageHandler(1)
                    }}>
                                {pageSizes?.map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </span>;
    }

    function renderResultCount() {
        return <span className="mx-1 leading-4 text-[#5D7285] text-sm">
            {
                currentPage === 1
                    ? (totalRows >  rowsPerPage) ? <span> 1 - {rowsPerPage} of {totalRows}</span> : <span> 1 - {totalRows}</span>
                    : (currentPage * rowsPerPage) < totalRows
                        ? <span> {((currentPage - 1) * rowsPerPage) + 1} - {currentPage * rowsPerPage} of {totalRows}</span>
                        : <span> {((currentPage - 1) * rowsPerPage) + 1} - {totalRows}</span>
            }
        </span>;
    }

    return (<>
        <div className="py-3 flex items-center justify-between">
            <div className="flex-1 flex justify-between sm:hidden">
                <Button onClick={onPrevPage} disabled={!canGoBack}>Previous</Button>
                <Button onClick={onNextPage} disabled={!canGoNext}>Next</Button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div className="flex gap-x-2">
                    {showPages ? renderPageSizes() : renderResultCount()}
                </div>
                <div>
                    <nav className="relative z-0 inline-flex space-x-5" aria-label="Pagination">
                        <PageButtonV2
                            className={"bg-[#FFEFD5] w-[40px] h-[40px]"}
                            onClick={onPrevPage}
                            disabled={!canGoBack}
                        >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon className={`h-6 w-6 ${!canGoBack ? "text-sjGrey" : "text-black"}`} aria-hidden="true"/>
                        </PageButtonV2>
                        <PageButtonV2
                            className={"bg-[#FFEFD5] w-[40px] h-[40px]"}
                            onClick={onNextPage}
                            disabled={!canGoNext
                            }>
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon className={`h-6 w-6 ${!canGoNext ? "text-sjGrey" : "text-black"}`} aria-hidden="true"/>
                        </PageButtonV2>
                    </nav>
                </div>
            </div>
        </div>
    </>)
}

export default PaginationV2