import React from "react"
import {useSelector} from "react-redux"
import {StateParams} from "../../../store/reducers"
import {Card, CardHeader,} from "@material-tailwind/react";
import {Link} from "react-router-dom"
import {DateTime} from "luxon"
import {IScreenerScore, NotesStatus, PatientJourney, patient_status, Roles, SessionTypes} from "../../../models"
import {SLUG_TO_TAB_MAP, TYPE_SLUG} from "../../screeners/ScreenerResults";
import {handleJourneyRoutes, RoutePaths} from "../../../shared/Utils";
import statusBucket from "../../../assets/data/statusBucket";

interface PatientJourneyComponentProps {
    sourcePatientId: string;
    patientProspectId: string;
    patientJourney?: PatientJourney[];
}

const PatientJourneyComponent = (props: PatientJourneyComponentProps) => {

    const {
        sourcePatientId,
        patientProspectId,
        patientJourney,
    } = props;

    const {role} = useSelector((state: StateParams) => state.account);

    const sortedPatientJourney = patientJourney && patientJourney.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    const patientJourneyLength = sortedPatientJourney && sortedPatientJourney.length || 0

    const canAccessNotes = (notesStatus: string) => {
        if (role === Roles.Counsellor || role === Roles.Admin) return true
        if ((role === Roles.Psychiatrist || role === Roles.Physician) && (notesStatus !== NotesStatus.PENDING_SUBMIT_TO_PSYCH)) return true
        return false
    }

    const handleStatusDescription = (notesStatus: NotesStatus | patient_status, sessionType: string) => {
        if (sessionType === SessionTypes.Catchup && notesStatus === NotesStatus.PENDING_SUBMIT_TO_PSYCH) return 'Pending'
        if (sessionType === SessionTypes.Catchup && notesStatus === NotesStatus.PENDING_PSYCH_REVIEW) return 'Submitted'
        if (notesStatus === NotesStatus.PENDING_SUBMIT_TO_PSYCH) return 'Pending'
        if (notesStatus === NotesStatus.PENDING_PSYCH_REVIEW) return 'Review'
        if (notesStatus === NotesStatus.LOCKED) return 'Submitted'
        if (notesStatus in patient_status) return statusBucket.discharge_reason[notesStatus]
    }

    const handleScore = (score: IScreenerScore | undefined) => {
        if (score) {
            const totalScore = Object.values(score.totalScore).reduce((a, b) => a + b, 0)
            return totalScore
        }
    }

    const slugMap = (slug: string) => {
        const screenerContent = SLUG_TO_TAB_MAP[slug as TYPE_SLUG]
        return screenerContent
    }

    const renderProgressLine = (index: number) => {
        return (
            <div className="w-3 h-3 rounded-full bg-[#99A7B2] mt-2">
                {index < patientJourneyLength - 1 && <div className="border-l-2 h-24 mt-3 ml-[5px]"></div>}
            </div>
        )
    }

    const renderViewDetailsForNotes = ({notesStatus, id, notesId, sessionType}: PatientJourney) => {
        return (
            notesStatus && notesId && canAccessNotes(notesStatus) ?
                <>
                    <div className="text-slate-400 text-sm">Notes</div>
                    <div>
                        <Link key={id}
                              to={handleJourneyRoutes(sessionType as SessionTypes, notesId, patientProspectId, role)}>
                            <span className="rounded-l-md border-sjOrange text-sjLink">
                                View details
                            </span>
                        </Link>
                    </div>
                </> : <></>
        )
    }
    const renderViewDetailsForDischarge = ({notesStatus, id, notesId, additionalInfo}: PatientJourney) => {
        const status = Object.values(patient_status).includes(notesStatus as string)
        const dischargeReason: keyof typeof patient_status = notesStatus as any

        const dischargeSummaryLink = role === Roles.Physician ? `../${RoutePaths.physicianCare.dischargedPatients.dischargeSummary.replace(':patientId', patientProspectId)}`
            : `../${RoutePaths.collaborativeCare.dischargedPatients.dischargeSummary.replace(':patientId', patientProspectId)}`
        return (
            notesId ?
                <>
                    <div className="text-slate-400 text-sm">Notes</div>
                    <div>
                        <Link key={id} to={dischargeSummaryLink} state={{
                            dischargeReason: status && dischargeReason ? dischargeReason : '',
                            status: patient_status.discharge_from_cocm,
                            isJourney: true,
                            additionalInfo: additionalInfo
                        }}>
                            <span className="rounded-l-md border-sjOrange text-sjLink">
                                View details
                            </span>
                        </Link>
                    </div>
                </> : <></>
        )
    }

    const renderScreenerDetails = ({isExpired, isComplete, id, mentalConditionId}: PatientJourney) => {
        return (
            isExpired || [Roles.Care_Coordinator, Roles.Physician].includes(role as Roles) ? <></> : <>
                <div className="text-slate-400 text-sm">Action</div>
                <div>
                    <Link
                        to={`/patients/${sourcePatientId}/screeners?userScreenerId=${id}&patientId=${patientProspectId}&screenerConditionId=${mentalConditionId}`}>
                        {
                            isComplete ?
                                <span className="rounded-l-md border-sjOrange text-sjLink">
                                    View details
                                </span> :
                                <span className={"text-sjOrange border-2 rounded-md border-sjOrange px-2"}
                                      data-testid="startScreener">
                                    Start screener
                                </span>
                        }
                    </Link>
                </div>
            </>
        )
    }

    const meta = {
        notes: {
            typeText: 'Session on',
            titleText: 'Session',
            sessionWithText: 'Session with',
            statusText: 'Notes status',
            viewDetails: renderViewDetailsForNotes
        },
        screener: {
            typeText: 'Screener on',
            titleText: 'Screener',
            sessionWithText: 'Screener score',
            statusText: 'Screener status',
            viewDetails: renderScreenerDetails
        },
        discharge: {
            typeText: 'Session on',
            titleText: 'Session',
            sessionWithText: 'Session with',
            statusText: 'Discharge Type',
            viewDetails: renderViewDetailsForDischarge
        }
    }

    const renderSessionOn = (journey: PatientJourney, index: number) => {
        const type: 'notes' | 'screener' | 'discharge' = journey.screener ? 'screener' : journey.sessionType === 'discharge' ? 'discharge' : 'notes'
        const metaData = meta[type];
        return (
            <div key={journey.id} className="flex direction-row justify-center items-center">
                <div className="w-1/6 text-center mt-2">
                    <div className="flex direction-row gap-10 grid-col-2">
                        {renderProgressLine(index)}
                        <div className="text-slate-400 text-sm">{metaData.typeText}</div>
                    </div>
                    <div><span
                        className="">{DateTime.fromISO(journey.createdAt).toLocaleString(DateTime.DATE_MED)}</span>
                    </div>
                </div>
                <Card className="w-5/6 mt-4 border border-[#E5E8EB]">
                    <div>
                        <CardHeader className="">
                            <div className="p-5 grid flex-row grid-cols-4">
                                <div className="p-1">
                                    <div className="text-slate-400 text-sm">{metaData.titleText}</div>
                                    <div
                                        className="text-md">{journey.sessionTypeName ? journey.sessionTypeName : journey.screener && slugMap(journey.screener.slug)}</div>
                                    <div
                                        className="text-xs">{journey.screenerCondition ? `(${journey.screenerCondition.conditionDisplayName})` : journey.screener ? `(${journey.screener.content})` : ''}</div>
                                </div>
                                <div className="">
                                    <div className="text-slate-400 text-sm">{metaData.sessionWithText}</div>
                                    <div>{journey.notesId ? 'Counselor' : journey.score ? handleScore(journey.score) : '-'}</div>
                                </div>
                                <div className="">
                                    <div className="text-slate-400 text-sm">{metaData.statusText}</div>
                                    <div>{(journey.notesStatus && journey.sessionType) ? handleStatusDescription(journey.notesStatus, journey.sessionType) : journey.isComplete ? 'Completed' : journey.isExpired ? 'Expired' : 'Pending'}</div>
                                </div>
                                <div className="">
                                    {metaData.viewDetails(journey)}
                                </div>
                            </div>
                        </CardHeader>
                    </div>
                </Card>
            </div>
        )
    }

    return (
        <div className="mt-7">
            {(sortedPatientJourney && sortedPatientJourney.length > 0) &&
                sortedPatientJourney.map((journey: PatientJourney, index: number) =>
                    renderSessionOn(journey, index)
                )}
            {
                (!sortedPatientJourney || (sortedPatientJourney && sortedPatientJourney.length === 0)) &&
                <div className="text-center text-sjGrey-400 mt-5">No data.</div>
            }
        </div>
    )
}

export default PatientJourneyComponent
