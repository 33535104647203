// ReusablePhoneNumberInput.js
import React, { useState } from 'react';
import PhoneInput, { Country, Value } from 'react-phone-number-input';

interface PhoneNumberInputProps {
    value: Value;
    onChange: (value?: Value) => void;
    id?: string
    placeholder?: string;
    className?: string;
}

const PhoneNumberInputComponent: React.FC<PhoneNumberInputProps> = ({
    value,
    onChange,
    id,
    placeholder,
    className,
}) => {
    const COUNTRIES: Country[] = ['US', 'IN']

    return (
        <PhoneInput
            id={id}
            defaultCountry='US'
            countries={COUNTRIES}
            placeholder={placeholder || 'Enter Phone Number'}
            value={value}
            onChange={onChange}
            inputClassName={className}
        />
    );
};

export default PhoneNumberInputComponent;