import {classNames} from "../../shared/Utils";
import React from "react";

export const YesNoPill: React.FC<{value: string}> = ({ value }) => {
    const status = value ? value.toLowerCase() : "unknown";

    return (
        <span
            className={classNames(
                "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
                status.startsWith("no") ? "bg-green-100 text-green-700" : null,
                status.startsWith("yes") ? "bg-red-100 text-red-700" : null
            )}
        >
      {status}
    </span>
    );
}