import React, {useCallback, useEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {StateParams} from '../../store/reducers'
import {fetchUsers, saveUser} from '../../store/actions/physician/admin-operations.action'
import {Button} from '../../components/generic/Buttons'
import TableFilterPaginationV2 from '../../components/generic/TableFilterPaginationV2'
import UserListComponent from "./UserListComponent";
import UserFormScreen from "./UserFormScreen";
import {IUser} from "../../models";
import ConfirmationModal from "../../components/clinical-notes/ConfirmationModal";
import {toast} from "react-toastify";
import Select from "react-select";
import {UserRoles} from "../../services/common.service";
import PaginationV2 from "../../components/generic/PaginationV2";

const UserStatusFilterOptions = [
    {value: undefined, label: 'All'},
    {value: true, label: 'Active'},
    {value: false, label: 'Inactive'}
];

const UserRolesFilterOptions = [
    {value: undefined, label: 'All'},
    ...UserRoles
];

const UsersScreen = () => {

    const dispatch = useDispatch();
    const {
        savedUser,
        users,
        usersCount,
        fetchUsersInProgress
    } = useSelector((state: StateParams) => state.physicianAdminOperations);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageNumber, setPageNumber] = useState(Number(searchParams.get("page")) || 1);
    const [status, setStatus] = useState<boolean>();
    const [role, setRole] = useState<string>();
    const [showForm, setShowForm] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<IUser | undefined>(undefined);
    const [showUserToggleModal, setShowUserToggleModal] = useState<boolean>(false);

    useEffect(() => {
        if (searchParams) {
            if (searchParams.get("page")) {
                setPageNumber(Number(searchParams.get("page")))
            } else {
                setPageNumber(1);

            }
        }
    }, [searchParams])

    useEffect(() => {
        getUsers();
    }, [pageNumber, recordsPerPage, searchText, status, role]);

    const getUsers = useCallback(() => {
        if (pageNumber || recordsPerPage || searchText) {
            dispatch(fetchUsers({pageNumber, recordsPerPage, searchText, status, role}))
        }
    }, [pageNumber, recordsPerPage, searchText, status, role]);

    const openFormDrawer = useCallback(() => {
        setShowForm(true);
    }, []);

    const closeFormDrawer = useCallback((refreshList: boolean) => {
        setShowForm(false);
        setSelectedUser(undefined);
        if (refreshList) {
            getUsers();
        }
    }, [getUsers]);

    const renderRightSidebar = useCallback(() => {
        return (
            <>
                {showForm &&
                    <div className={"absolute top-0 right-0 w-1/2 bg-white z-[2000]"}>
                        <UserFormScreen user={selectedUser} onClose={closeFormDrawer}/>
                    </div>
                }
            </>
        )
    }, [showForm, selectedUser, closeFormDrawer]);

    const handleEditUser = useCallback((userId: string) => {
        setSelectedUser(users.find(user => user.id === userId));
        setShowForm(true);
    }, [users]);

    const openToggleConfirmation = useCallback((userId: string) => {
        setSelectedUser(users.find(user => user.id === userId));
        setShowUserToggleModal(true);
    }, [users]);

    const closeToggleConfirmation = useCallback(() => {
        setShowUserToggleModal(false);
        setSelectedUser(undefined);
    }, []);

    const handleToggleUser = useCallback(() => {
        dispatch(saveUser({
            id: selectedUser?.id || '',
            isActive: !selectedUser?.isActive
        }));
        setShowUserToggleModal(false);
        setSelectedUser(undefined);
    }, [selectedUser]);

    useEffect(() => {
        if (savedUser) {
            toast("User saved successfully", {type: "success"});
            getUsers();
        }
    }, [savedUser, getUsers]);


    const renderFilters = useCallback(() => {
        return <div className='flex flex-row gap-3'>
            <TableFilterPaginationV2
                setTableFilter={setSearchText}
                totalRows={usersCount || 0}
                currentPageHandler={setPageNumber}
            />
            <Select
                className={"w-48"}
                options={UserRolesFilterOptions}
                placeholder={"Role"}
                isClearable={true}
                isSearchable={false}
                onChange={(selectedOption: any) => setRole(selectedOption?.value)}
            />
            <Select
                className={"w-48"}
                options={UserStatusFilterOptions}
                placeholder={"Status"}
                isClearable={true}
                isSearchable={false}
                onChange={(selectedOption: any) => setStatus(selectedOption?.value)}
            />
        </div>
    }, [usersCount, searchText, pageNumber, role, status]);

    const renderUsers = useCallback(() => {
        return <>
            <div className={showForm ? "foreground" : ""}/>
            <div className={showForm ? "uneditable" : ""}>
                <div className="flex flex-row justify-between items-center">
                    <div className='flex'>
                        <h1 className="text-xl"> User Master </h1>
                    </div>
                    <div className='flex flex-row gap-x-2 items-center'>
                        {renderFilters()}
                        <Button className={"!bg-sjOrange !text-white !ml-2"}
                                onClick={openFormDrawer}
                        >Add User +</Button>
                    </div>
                </div>
                <div className="mt-4 relative">
                    <UserListComponent searchInProgress={fetchUsersInProgress}
                                       onEditUser={handleEditUser}
                                       onToggleUser={openToggleConfirmation}
                                       users={users}
                                       currentPageHandler={setPageNumber}/>
                    {users && users.length > 0 &&
                        <PaginationV2
                            totalRows={usersCount || 0}
                            rowsPerPage={recordsPerPage}
                            recordsPerPageHandler={setRecordsPerPage}
                            currentPage={pageNumber}
                            currentPageHandler={setPageNumber}
                            showPages={false}
                        />
                    }
                </div>
            </div>
        </>;
    }, [
        showForm, users, fetchUsersInProgress,
        usersCount, openFormDrawer,
        renderFilters, handleEditUser,
        openToggleConfirmation, recordsPerPage, pageNumber,
        setRecordsPerPage, setPageNumber
    ]);

    const renderUserToggleConfirmationModal = useCallback(() => {
        const actionLabel = selectedUser?.isActive ? 'Deactivate' : 'Activate';
        const userName = `${selectedUser?.firstName} ${selectedUser?.lastName}`;
        return <ConfirmationModal
            isOpen={showUserToggleModal}
            onClose={closeToggleConfirmation}
            modalClassNames={`border-l-4 border-${selectedUser?.isActive ? 'red' : 'orange'}-500`}
            continueButtonName={actionLabel}
            onConfirm={handleToggleUser}
            continueButtonClassNames={`bg-${selectedUser?.isActive ? 'sjDarkRed' : 'sjDarkGreen'}`
            }
        >
            <div>
                <h2 className={"font-black text-xl"}> Warning </h2>
                <div className={"mt-3"}>
                    Are you sure you want
                    to <span className={"lowercase italic"}>{actionLabel}</span> <br/>
                    <strong> {userName} </strong>?
                </div>
            </div>
        </ConfirmationModal>
    }, [showUserToggleModal, closeToggleConfirmation, selectedUser, handleToggleUser]);

    return (
        <div className={"w-full p-4 h-full bg-[#F7F8FA]"}>
            {renderRightSidebar()}
            {renderUsers()}
            {renderUserToggleConfirmationModal()}
        </div>
    )
}

export default UsersScreen;
