import React, {useCallback, useEffect, useState} from 'react'
import sjLogo from "../../assets/images/logos/logo.svg";
import {useDispatch, useSelector} from "react-redux";
import {clearIsTwoFactorEnabled, login} from "../../store/actions/physician/account.action";
import {StateParams} from "../../store/reducers";
import {useNavigate} from "react-router";
import {homeContentReady} from "../../store/actions/counsellor/navigation.actions";
import {MixpanelContext} from "../../analytics/Mixpanel";
import {LOGIN_FAILED, LOGIN_REQUEST, LOGIN_SUCCESS} from "../../analytics/Events";
import {RoutePaths} from "../../shared/Utils";
import PasswordInput from '../../components/generic/PasswordInput';
import {logout} from "../../store/actions/common";

const LoginScreen = () => {
    const dispatch = useDispatch()
    const analytics = React.useContext(MixpanelContext);
    const navigate = useNavigate()
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [formErrors, setFormErrors] = useState<string[]>()
    const {error} = useSelector((state: StateParams) => state.signin)
    const {jwt, slug, id, isTwoFactorAuthenticationEnabled, isDefaultPassword} = useSelector((state: StateParams) => state.physicianAccount)

    const validateForLogin = useCallback((dispatchLogin: Function) => {
        if (!email || !password || email.trim().length === 0 || password.trim().length === 0) {
            const emailError = (email && email.trim().length > 0) || "Email is required"
            const passwordError = (password && password.trim().length > 0) || "Password is required"
            const errors: string[] = [emailError, passwordError].filter(e => e !== true) as string[];
            setFormErrors(errors)
            return
        }
        analytics?.track(LOGIN_REQUEST, {email, type: "Physician"})
        dispatch(dispatchLogin({email, password}))
    }, [email, password, formErrors])

    const submitForLogin = useCallback(() => {
        setFormErrors([])
        validateForLogin(login)
    }, [email, password])

    const cancelLogin = useCallback(() => {
        navigate(RoutePaths.app.root)
    }, [navigate])

    useEffect(() => {
        if (error) {
            analytics?.track(LOGIN_FAILED, {email, error, type: "Physician"})
            setFormErrors((errors) => {
                return errors ? [...errors, error] : [error]
            })
        }
    }, [error])

    useEffect(() => {
        console.log("isDefaultPassword", isDefaultPassword);
        if (isDefaultPassword) {
            navigate(RoutePaths.physicianCare.changeDefaultPassword, {
                state: {email}
            });
            dispatch(logout());
        } else if(isTwoFactorAuthenticationEnabled) {
            navigate(RoutePaths.physicianCare.otpScreen, {state: {email}})
            dispatch(clearIsTwoFactorEnabled())
            return
        } else if (!jwt) {
            return
        } else {
            analytics?.track(LOGIN_SUCCESS, {email, provider: slug, id: id, type: "Physician"});
            analytics?.identify(id);
            dispatch(homeContentReady());
        }
    }, [jwt, slug, id, isDefaultPassword, isTwoFactorAuthenticationEnabled, navigate])

    const forgotPasswordClick = useCallback(() => {
        navigate('/forgotPassword')
    }, [navigate])

    const handlePasswordChange = (e: any) => {
        setPassword(e.target.value)
        setFormErrors([])
    }

    const handlePasswordOnKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            submitForLogin()
        }
    }

    return (
        <main className="xs:pt-28 md:pt-2 0 px-3 border-b xs:h-auto md:h-screen">
            <div className="container mx-auto flex flex-col items-center">
                <div className='w-full h-32 flex items-center justify-center'>
                    <img src={sjLogo}/>
                </div>
            </div>
            <div className="py-12">
                <div className="mt-8 w-full">
                    <div className="grid grid-cols-3 gap-6">
                        <div></div>
                        <div>
                            <label className="block">
                                <span className="text-gray-700">Email address</span>
                                <input
                                    data-test="login-email"
                                    type="email"
                                    value={email}
                                    autoComplete={"off"}
                                    autoCorrect={"off"}
                                    autoCapitalize={"off"}
                                    autoFocus={true}
                                    className=" mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                                    placeholder=""
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                        setFormErrors([])
                                    }}
                                />
                            </label>
                            <label className="block">
                                <span className="text-gray-700">Password</span>
                                <PasswordInput value={password} onChange={handlePasswordChange}
                                               onKeyDown={handlePasswordOnKeyDown}/>
                            </label>
                            <div>
                                <span
                                    className='text-8xs font-bold float-right	pt-1 text-hyperLink text-[#508DE8] hover:text-blue-800 hover:cursor-pointer visited:text-purple-600'
                                    onClick={forgotPasswordClick}>
                                    Forgot Password?
                                </span>
                            </div>
                        </div>
                        <div></div>
                    </div>
                    <div className="mt-10 grid grid-cols-3 gap-6">
                        <div></div>
                        <div className="flex flex-col justify-center items-center">
                            {formErrors?.map((error, index) => {
                                return <p className="error-msg text-center" key={"error-" + index}>{error}</p>
                            })}
                        </div>
                        <div></div>
                    </div>
                    <div className="mt-10 grid grid-cols-3 gap-6">
                        <div>
                        </div>
                        <div className="flex items-center justify-evenly">
                            <button
                                data-test="login-proceed"
                                className="bg-sjGrey text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="button"
                                onClick={cancelLogin}>
                                Cancel
                            </button>
                            <button
                                data-test="login-proceed"
                                className="bg-sj2 hover:bg-sjOrange text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="button"
                                onClick={submitForLogin}>
                                Sign In
                            </button>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default LoginScreen
