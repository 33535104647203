import React, {useEffect, useState} from "react";
import {batch, useDispatch, useSelector} from "react-redux";
import {useLocation, useParams} from "react-router-dom"
import {fetchPatient, fetchScreenerQAndA} from "../../../store/actions/counsellor/patients.action";
import {StateParams} from "../../../store/reducers";
import ClinicalNotesComponent from "../ClinicalNotesComponent";
import Screeners from "./Screeners";
import {fetchPatientQAndA} from "../../../store/actions/physician/patients.action";

const ScreenersComponent: React.FC = () => {
    const {patient, screenerQAndA} = useSelector((state: StateParams) => state.patients)
    const screenerQuestionAnswers = useSelector((state: StateParams) => state.physicianPatients.screenerQuestionAnswers)
    const dispatch = useDispatch()
    const params = useParams()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)
    const [userScreenerId, setUserScreenerId] = useState<string | null>(searchParams.get('userScreenerId'))
    const [patientId, setPatientId] = useState<string | null>(searchParams.get('patientId'))
    const [screenerConditionId, setScreenerConditionId] = useState<string | null>(searchParams.get('screenerConditionId'))
    const [canEdit, setCanEdit] = useState<boolean>(false)

    useEffect(() => {
        const id = params.sourcePatientId || patient?.sourcePatientId
        if (id && userScreenerId && patientId) {
            batch(() => {
                dispatch(fetchPatient(patientId))
                dispatch(fetchScreenerQAndA(id, userScreenerId, screenerConditionId))
                dispatch(fetchPatientQAndA(id, userScreenerId))
            })
        }
    }, [params.sourcePatientId, patientId, userScreenerId])

    useEffect(() => {
        if(screenerQAndA){
            setCanEdit(!screenerQAndA.isScreenerCompleted)
        }
    },[screenerQAndA])

    const renderNotesSection = () => {
        return <>
            {patient && <Screeners sourcePatientId={patient.sourcePatientId} userScreenerId={userScreenerId} canEdit={canEdit} screenerQAndA={screenerQAndA} screenerQuestionAnswers={screenerQuestionAnswers}/>}
        </>
    }

    return (
        <ClinicalNotesComponent title="Screeners" patient={patient}>
            {renderNotesSection()}
        </ClinicalNotesComponent>
    )
}

export default ScreenersComponent