import React, {useCallback, useEffect, useState} from "react";
import {clearLocationIdToInitiateCall, fetchLocations, setLocationIdToInitiateCall} from "../../store/actions/physician/admin-operations.action";
import {useDispatch, useSelector} from "react-redux";
import {IPatientInCall, LocationIndexItem} from "../../models";
import {StateParams} from "../../store/reducers";
import {List, ListItem, ListItemSuffix, Typography,} from "@material-tailwind/react";
import Modal from 'react-modal';
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import {AiFillCloseCircle} from "react-icons/ai";
import _ from "lodash";
import {Button, PageButtonV2} from "../generic/Buttons";
import {Tooltip} from "react-tooltip";
import { requestCall } from "../../store/actions/counsellor/calls.action";

interface ModalProps {
    changeDefault?: boolean
    onClose?: () => void
    locationChanged?: () => void
    patient: IPatientInCall
}

const CallPatientLocationSelectionComponent: React.FC<ModalProps> = ({changeDefault, onClose, locationChanged, patient}) => {
    const dispatch = useDispatch()
    const {locations: fetchedLocations} = useSelector((state: StateParams) => state.physicianAdminOperations)
    const [locations, setLocations] = useState<LocationIndexItem[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<LocationIndexItem>();

    useEffect(() => {
        dispatch(fetchLocations({}))
    }, []);

    useEffect(() => {
        if (fetchedLocations) {
            console.log('fetchedLocations', fetchedLocations)
            setLocations(fetchedLocations)
        }
    }, [fetchedLocations])


    const setLocation = useCallback((selectedLocation: LocationIndexItem) => {
        if(selectedLocation.isPhoneVerified) {
            setSelectedLocation(selectedLocation)
            dispatch(setLocationIdToInitiateCall(selectedLocation.id))
        } else {
            console.log("Location phone number not verified")
            setSelectedLocation(undefined)
            dispatch(clearLocationIdToInitiateCall())
        }
    }, [selectedLocation])


    const formatAddress = (location: LocationIndexItem) => {
        const addressParts = [location.addressLine, location.city, location.zipCode]
        const validAddressParts = _.filter(addressParts, part => !_.isUndefined(part) && !_.isNull(part))
        return validAddressParts.join(", ")
    }

    const initiateCall = () => {
        dispatch(requestCall(patient))
        locationChanged?.()
    }

    const renderlocation = (location: LocationIndexItem) => {
        return <ListItem key={location.id} onClick={() => setLocation(location)} className={"py-1 my-2 border hover:bg-gray-50 justify-between"} disabled={!location.isPhoneVerified}>
            <div className={(location.id === selectedLocation?.id) ? "w-3/4" : "w-full"}>
                <Tooltip id={`location-name-${location.id}`} className={"w-auto"}>
                    {!location.isPhoneVerified && <div className="whitespace-normal break-all">Phone number not verified</div>}
                </Tooltip>
                <Typography data-tooltip-id={`location-name-${location.id}`} variant="lead" className={"truncate text-md text-black capitalize"}>
                    {location?.name || ""}
                </Typography>
                <Typography variant="paragraph" className={"truncate text-sm text-sjGrey whitespace-normal"}>
                    {formatAddress(location)}
                </Typography>
                <Typography variant="small" className="text-sjGrey text-sm">
                    {location.phone}
                </Typography>
            </div>
            {
                location.id === selectedLocation?.id && location.isPhoneVerified
                    ? <ListItemSuffix>
                        <CheckCircleIcon className="h-6 w-6 m-1 ml-4 self-center text-sjOrange"/>
                    </ListItemSuffix>
                    : null
            }
        </ListItem>
    }

    const renderLocations = () => {
        return <div className={"flex flex-col mt-4"}>
            <div className={"flex flex-row align-middle gap-x-8"}>
                <Typography className={"text-lg text-black"}>
                    Select a location to call patient
                </Typography>
            </div>
            <div className="mt-1 relative w-full overflow-auto max-h-[50vh]">
                <List>
                    {
                        locations.map((location, index) => {
                            return <div key={location.id}>
                                { renderlocation(location) }
                            </div>
                        })
                    }
                </List>
            </div>
            <div className={"flex flex-row self-center gap-x-6 items-center"}>
                <PageButtonV2
                    className={`${!selectedLocation ? "!bg-sjGrey" : "!bg-sjOrange"} text-white mt-1 px-[16px] py-[8px] h-8 text-sm`}
                    disabled={!selectedLocation}
                    onClick={initiateCall}
                >

                    <div className={"flex flex-row gap-x-2 items-center"}>
                        <span>Continue to Call Patient</span>
                    </div>

                </PageButtonV2>
            </div>
        </div>;
    }

    return <Modal
        ariaHideApp={false}
        isOpen={changeDefault || false}
        shouldCloseOnOverlayClick={false}
        className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white border-sjOrange"
        contentLabel="Location Selection"
    >
        <div className="mt-1 flex flex-col">
            <div onClick={onClose} className="cursor-pointer absolute top-1 right-1 m-1">
                <AiFillCloseCircle
                    className="text-gray-500 hover:text-gray-700"
                    style={{ width: '25px', height: '25px' }}
                />
            </div>
            {
                renderLocations()
            }
        </div>
    </Modal>
}

export default CallPatientLocationSelectionComponent
