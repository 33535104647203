import axiosRetry from "axios-retry";
import axios from "axios";
import {ENV} from "../constants"
import {AuthState} from "../models";

console.log("API: ", process.env.REACT_APP_API_URL)
export function getAuthHeaderConfig(authState: AuthState) {
    return {
        headers: {
            "x-auth-token": `${authState.token}`,
            "x-auth-role": `${authState.role}`
        }
    };
}
const apiHttpClient = axios.create({
    baseURL: ENV.API_URL,
    headers: {
        "Content-type": "application/json",
        "x-api-key": ENV.API_KEY,
        "x-client-id": ENV.API_CLIENT
    }
});
axiosRetry(apiHttpClient, { retries: 5, retryDelay: axiosRetry.exponentialDelay });

const physicianHttpClient = axios.create({
    baseURL: ENV.PHYSICIAN_API_URL,
    headers: {
        "Content-type": "application/json",
        "x-api-key": ENV.API_KEY,
        "x-client-id": ENV.API_CLIENT
    }
});
axiosRetry(physicianHttpClient, { retries: 5, retryDelay: axiosRetry.exponentialDelay });

export {apiHttpClient, physicianHttpClient}


