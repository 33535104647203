import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import {useNavigate, useSearchParams} from "react-router-dom";
import {fetchCoCMPatients} from "../../../store/actions/physician/patients.action";
import {RoutePaths} from "../../../shared/Utils";
import TableFilterPaginationV2 from "../../generic/TableFilterPaginationV2";
import PaginationV2 from "../../generic/PaginationV2";
import TablePaginationV2 from "../../generic/TablePaginationV2";
import {PhysicianPatientsDataView} from "./PhysicianPatientsDataView";

const PhysicianPatients : React.FC = () => {
    const dispatch = useDispatch()
    const patients = useSelector((state: StateParams) => state.physicianPatients.cocmPatients)
    const patientSearchInProgress = useSelector((state: StateParams) => state.physicianPatients.fetchCoCMPatientsInProgress)
    const patientsCount = useSelector((state: StateParams) => state.physicianPatients.cocmPatientRecordsCount)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 1);
    const [patientsInView, setPatientsInView] = useState<any>([])
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [searchText, setSearchText] = useState('')
    const dataView = new PhysicianPatientsDataView()

    const columns = useMemo( dataView.getColumns(), [] );

    useEffect(() => {
        if(currentPage || recordsPerPage) {
            console.log("FETCH COCM PATIENTS FOR PHYSICIAN")
            dispatch(fetchCoCMPatients(currentPage, recordsPerPage, searchText))
        }
    }, [currentPage, recordsPerPage, searchText]);

    useEffect(()=>{
        if(searchParams && searchParams.get("page")) {
            setCurrentPage(Number(searchParams.get("page")) || 1)
        }
    },[searchParams])

    useEffect(() => {
        navigate(`${RoutePaths.physicianCare.patients}?page=${currentPage}`)
    }, [currentPage]);

    useEffect(() => {
        dataView.setPatients(patients)
        setPatientsInView(dataView.getData())
    }, [patients])

    return <div className="w-full p-4 h-full bg-[#F7F8FA]">
        <div className={"flex justify-between pb-4 items-center"}>
            <h1 className="text-xl"> CoCM Patients - Active</h1>
            <div className="flex gap-x-2 justify-end items-center">
                <TableFilterPaginationV2
                    setTableFilter={setSearchText}
                    totalRows={patientsCount || 0}
                    currentPageHandler={setCurrentPage}
                />
            </div>
        </div>
        <TablePaginationV2 columns={columns} data={patientsInView} searchInProgress={patientSearchInProgress} />
        {patients && patients.length > 0 &&
            <PaginationV2
                totalRows={patientsCount || 0}
                rowsPerPage={recordsPerPage}
                recordsPerPageHandler={setRecordsPerPage}
                currentPage={currentPage}
                currentPageHandler={setCurrentPage}
                showPages={false}
            />
        }
    </div>

}

export default PhysicianPatients
