import { call, put, takeLatest } from "@redux-saga/core/effects";
import { apiHttpClient, getAuthHeaderConfig } from "../../../lib";
import { Either } from "monet";
import { CheckedError, ServiceError } from "../../../types/ServiceError";
import {
  AuthState,
  FiltersTypes,
  IAceScreener,
  IAppointmentSession,
  IIntakeFormData,
  ILockIntakeResponse,
  IPatientIndex,
  IProspect,
  IProspectScreenerResponse,
  IReferenceData,
  IReferredPatientsDetails,
  ISession,
  ITherapist,
  ITherapistPatient,
  NotesStatus,
  NotesStatusRequest,
  Practice,
  ProspectScreeningSummary,
  ProspectWithStatus,
  SessionTypes,
} from "../../../models";
import { AxiosError, AxiosResponse } from "axios";
import { asyncCallFinished, asyncCallInProgress, callFinished, callInProgress } from "../../actions/loading.action";
import {
  acceptedAceScreener,
  acceptedProspectRefData,
  acceptingAceScreenerFailed,
  acceptingPatientRefDataFailed,
  COMPLETE_INTAKE_SESSION,
  COMPLETED_INTAKE_SESSION,
  completedIntakeSession,
  completeIntakeSessionFailed,
  completeIntakeSessionInProgress,
  FETCH_ACE_SCREENER,
  FETCH_PRACTICES,
  FETCH_PROSPECT,
  FETCH_PROSPECT_REFERENCE_DATA,
  FETCH_PROSPECT_SCREENER_RESPONSE,
  FETCH_PROSPECT_SESSION_BY_SRC_PATIENT,
  FETCH_PROSPECTS,
  fetchedPractices,
  fetchedProspect,
  fetchedProspects,
  fetchedProspectScreenerResponse,
  fetchingProspectFailed,
  fetchingProspectFailedScreenerResponse,
  fetchingProspectsFailed,
  fetchingSearchPatientFailed,
  fetchPracticesFailed,
  fetchSessionBySourcePatientIdFailed,
  fetchSessionBySourcePatientIdSuccess,
  INTAKE_FORM_SAVE,
  intakeFormSaveFailed,
  intakeFormSaveSuccess,
  LOCK_INTAKE_FORM,
  lockIntakeFailed,
  lockIntakeSuccess,
  SEARCH_PATIENT,
  searchedPatient,
  updatedNotesStatus, updateNotesStatusFailed, UPDATE_NOTE_STATUS, updatedNotesStatusIntake, FETCH_PROSPECT_WITH_SUMMARY, fetchingProspectWithSummaryFailed, fetchedProspectWithSummary,
  FETCH_PSYCHIATRIST_SOCIAL_WORKERS,
  fetchPsychiatristSocialWorkersFailed,
  fetchedPsychiatristSocialWorkers,
  FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID_IN_PROGRESS,
  FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID,
  fetchedProspectWithSummaryBySessionId
} from "../../actions/counsellor/prospects.action";
import { catchErrorMessage } from "../../../lib/api-helpers";
import { fetchPsychiatristNotes, reviewAndSubmitTreatmentNotes, updateLatestNotesStatus } from "../../actions/counsellor/patients.action";
import _ from "lodash";

const apiSearchPatient = (action: { type: string, authState: AuthState, payload: { searchKeyword: string, searchForSessionType: "Intake" | "Follow_Up" } }) => {
  const url = `/${action.authState.accountId}/prospects/search/patients-or-prospects`;
  return apiHttpClient.get<Either<CheckedError, IPatientIndex>>(url, {
    params: {
      keyword: action.payload.searchKeyword,
      searchForSessionType: action.payload.searchForSessionType
    }, headers: { "x-auth-token": action.authState.token }
  })
    .then((response: AxiosResponse) => {
      return Either.right(response.data.data as IPatientIndex)
    }).catch(e => catchErrorMessage(e));
}

const apiFetchProspects = (action: { type: string, authState: AuthState, payload: { currentPage: number, recordsPerPage: number, activeTab: string, filters?: FiltersTypes, searchText?: string } }) => {
  const searchParams = new URLSearchParams()
  const filters = { ...action.payload.filters, searchText: action.payload.searchText }
  if (filters) {
    for (const [key, value] of Object.entries(filters)) {
      if (value) {
        searchParams.append(key, value)
      }
    }
  }
  const url = `/${action.authState.accountId}/prospects?${searchParams.toString()}&pageNumber=${action.payload.currentPage - 1}&recordsPerPage=${action.payload.recordsPerPage}&activeTab=${action.payload.activeTab}`;
  return apiHttpClient.get<Either<CheckedError, { prospects: IReferredPatientsDetails[], count: number, tabCount: Record<string, number> }>>(url, { headers: { "x-auth-token": action.authState.token } })
    .then((response: AxiosResponse) => {
      return Either.right(response.data.data as { prospects: IReferredPatientsDetails[], count: number, tabCount: Record<string, number> })
    }).catch(e => catchErrorMessage(e));
}

const apiFetchProspect = (action: { type: string, authState: AuthState, payload: { prospectId: string } }) => {
  const url = `/${action.authState.accountId}/prospects/${action.payload.prospectId}`;
  return apiHttpClient.get<Either<CheckedError, IProspect>>(url, { headers: { "x-auth-token": action.authState.token } })
    .then((response: AxiosResponse) => {
      return Either.right(response.data.data as IProspect)
    }).catch(e => catchErrorMessage(e));
}

const apiFetchProspectWithSummary = (action: { type: string, authState: AuthState, payload: ProspectScreeningSummary }) => {
  const url = `/${action.authState.accountId}/prospects/${action.payload.prospectId}/summary?practiceId=${action.payload.practiceId}&sourcePatientId=${action.payload.sourcePatientId}&sessionId=${action.payload.sessionId}`;
  return apiHttpClient.get<Either<CheckedError, IProspect>>(url, { headers: { "x-auth-token": action.authState.token } })
    .then((response: AxiosResponse) => {
      return Either.right(response.data.data as IProspect)
    }).catch(e => catchErrorMessage(e));
}

const apiCompleteSession = (action: { type: string, authState: AuthState, payload: { prospectId: string, sessionId: string, isConsentGivenToCoCM: boolean, sourcePatientId: string, noteId: string, notesStatus: NotesStatus } }) => {
  const url = `/${action.authState.accountId}/prospects/${action.payload.prospectId}/sessions/${action.payload.sessionId}/complete`;
  console.log("COMPLETE PAYLOAD", action.payload)
  return apiHttpClient.post<Either<CheckedError, ProspectWithStatus>>(url, {
    acceptedToCoCM: action.payload.isConsentGivenToCoCM,
    sourcePatientId: action.payload.sourcePatientId,
    noteId: action.payload.noteId || '',
    notesStatus: action.payload.notesStatus
  }, { headers: { "x-auth-token": action.authState.token } })
    .then((response: AxiosResponse) => {
      return Either.right(response.data.data.response as ProspectWithStatus)
    }).catch(e => catchErrorMessage(e));
}

const apiLockIntake = (action: { type: string, authState: AuthState, payload: { prospectId: string } }) => {
  const url = `/${action.authState.accountId}/prospects/${action.payload.prospectId}/lock-intake`;
  return apiHttpClient.post<Either<CheckedError, ILockIntakeResponse>>(url, undefined, { headers: { "x-auth-token": action.authState.token } })
    .then((response: AxiosResponse) => {
      return Either.right(response.data.data as ILockIntakeResponse)
    }).catch(e => catchErrorMessage(e));
}

const apiFetchProspectScreenerResponses = (action: {
  type: string, authState: AuthState, payload: {
    prospectId: string,
    prospectScreenerId: string, sourcePatientId: string, practiceId: string
  }
}) => {
  const url = `/${action.authState.accountId}/prospects/${action.payload.prospectId}/screeners/${action.payload.prospectScreenerId}?sourcePatientId=${action.payload.sourcePatientId}&practiceId=${action.payload.practiceId}`;
  return apiHttpClient.get<Either<CheckedError, IProspectScreenerResponse[]>>(url, { headers: { "x-auth-token": action.authState.token } })
    .then((response: AxiosResponse) => {
      return Either.right(response.data.data as IProspectScreenerResponse[])
    }).catch(e => catchErrorMessage(e));
}

const apiFetchPractices = (action: { type: string, authState: AuthState }) => {
  const url = `/${action.authState.accountId}/practices`;
  return apiHttpClient.get<Either<CheckedError, Practice[]>>(url, { headers: { "x-auth-token": action.authState.token } })
    .then((response: AxiosResponse) => {
      return Either.right(response.data.data as Practice[])
    }).catch(e => catchErrorMessage(e));
}

function* fetchProspects(action: { type: string, authState: AuthState, payload: { currentPage: number, recordsPerPage: number, activeTab: string, filters?: FiltersTypes, searchText?: string } }) {
  try {
    console.log(`ProspectsSaga:fetchProspects`)
    yield put(callInProgress())
    const apiFetchProspectsResponse: Either<CheckedError, { prospects: IReferredPatientsDetails[], count: number, tabCount: Record<string, number> }> = yield call(apiFetchProspects, action)
    if (apiFetchProspectsResponse.isLeft()) {
      const error = apiFetchProspectsResponse.left()
      if (error.isChecked) {
        console.log("ProspectsSaga: Encountered a Checked Error", error.message)
        yield put(fetchingProspectsFailed({ error: error.message }));
      } else {
        yield put(fetchingProspectsFailed({ error: error.message }));
      }
    } else {
      const prospects = apiFetchProspectsResponse.right().prospects;
      const prospectsCount = apiFetchProspectsResponse.right().count
      const tabCount = apiFetchProspectsResponse.right().tabCount
      yield put(fetchedProspects(prospects, prospectsCount, tabCount));
    }
  } catch (e) {
    console.log(e)
    yield put(fetchingProspectsFailed({ error: "Error fetching prospects!" }));
  } finally {
    yield put(callFinished())
  }
}

function* fetchProspect(action: { type: string, authState: AuthState, payload: { prospectId: string } }) {
  try {
    console.log(`ProspectsSaga:fetchProspect`)
    yield put(callInProgress())
    const apiFetchProspectResponse: Either<CheckedError, IProspect> = yield call(apiFetchProspect, action)
    if (apiFetchProspectResponse.isLeft()) {
      const error = apiFetchProspectResponse.left()
      if (error.isChecked) {
        console.log("ProspectsSaga: Encountered a Checked Error", error.message)
        yield put(fetchingProspectFailed({ error: error.message }));
      } else {
        yield put(fetchingProspectFailed({ error: error.message }));
      }
    } else {
      const prospect = apiFetchProspectResponse.right();
      yield put(fetchedProspect(prospect));
    }
  } catch (e) {
    console.log(e)
    yield put(fetchingProspectFailed({ error: "Error fetching prospect!" }));
  } finally {
    yield put(callFinished())
  }
}

function* fetchProspectWithSummary(action: { type: string, authState: AuthState, payload: { prospectId: string, practiceId: string, sourcePatientId: string } }) {
  try {
    console.log(`ProspectsSaga:fetchProspectWithSummary`)
    yield put(callInProgress())
    const apiFetchProspectWithSummaryResponse: Either<CheckedError, IProspect> = yield call(apiFetchProspectWithSummary, action)
    if (apiFetchProspectWithSummaryResponse.isLeft()) {
      const error = apiFetchProspectWithSummaryResponse.left()
      if (error.isChecked) {
        console.log("ProspectsSaga: Encountered a Checked Error", error.message)
        yield put(fetchingProspectWithSummaryFailed({ error: error.message }));
      } else {
        yield put(fetchingProspectWithSummaryFailed({ error: error.message }));
      }
    } else {
      const prospect = apiFetchProspectWithSummaryResponse.right();
      yield put(fetchedProspectWithSummary(prospect));
    }
  } catch (e) {
    console.log(e)
    yield put(fetchingProspectWithSummaryFailed({ error: "Error fetching prospect!" }));
  } finally {
    yield put(callFinished())
  }
}
function* fetchProspectWithSummaryBySessionId(action: { type: string, authState: AuthState, payload: ProspectScreeningSummary }) {
  try {
    console.log(`ProspectsSaga:fetchProspectWithSummary`)
    yield put(callInProgress())
    const apiFetchProspectWithSummaryResponse: Either<CheckedError, IProspect> = yield call(apiFetchProspectWithSummary, action)
    if (apiFetchProspectWithSummaryResponse.isLeft()) {
      const error = apiFetchProspectWithSummaryResponse.left()
      if (error.isChecked) {
        console.log("ProspectsSaga: Encountered a Checked Error", error.message)
        yield put(fetchingProspectWithSummaryFailed({ error: error.message }));
      } else {
        yield put(fetchingProspectWithSummaryFailed({ error: error.message }));
      }
    } else {
      const prospect = apiFetchProspectWithSummaryResponse.right();
      yield put(fetchedProspectWithSummaryBySessionId(prospect));
    }
  } catch (e) {
    console.log(e)
    yield put(fetchingProspectWithSummaryFailed({ error: "Error fetching prospect!" }));
  } finally {
    yield put(callFinished())
  }
}

function* fetchProspectScreenerResponses(action: {
  type: string, authState: AuthState, payload: {
    prospectId: string,
    prospectScreenerId: string, sourcePatientId: string, practiceId: string
  }
}) {
  try {
    console.log(`ProspectsSaga:fetchProspectScreenerResponses`)
    yield put(callInProgress())
    const apiFetchProspectResponse: Either<CheckedError, IProspectScreenerResponse[]> = yield call(apiFetchProspectScreenerResponses, action)
    if (apiFetchProspectResponse.isLeft()) {
      const error = apiFetchProspectResponse.left()
      if (error.isChecked) {
        console.log("ProspectsSaga: Encountered a Checked Error", error.message)
        yield put(fetchingProspectFailedScreenerResponse({ error: error.message }));
      } else {
        yield put(fetchingProspectFailedScreenerResponse({ error: error.message }));
      }
    } else {
      const screenerResponses = apiFetchProspectResponse.right();
      yield put(fetchedProspectScreenerResponse(screenerResponses, action.payload.prospectScreenerId));
    }
  } catch (e) {
    console.log(e)
    yield put(fetchingProspectFailedScreenerResponse({ error: "Error fetching prospect!" }));
  } finally {
    yield put(callFinished())
  }
}

function* searchPatient(action: { type: string, authState: AuthState, payload: { searchKeyword: string, searchForSessionType: "Intake" | "Follow_Up" } }) {
  try {
    console.log(`ProspectsSaga:searchPatient`)
    yield put(callInProgress())
    const patientSearchEither: Either<CheckedError, IPatientIndex> = yield call(apiSearchPatient, action)
    if (patientSearchEither.isLeft()) {
      const error = patientSearchEither.left()
      if (error.isChecked) {
        console.log("ProspectsSaga: Encountered a Checked Error", error.message)
        yield put(fetchingSearchPatientFailed({ error: error.message }));
      } else {
        yield put(fetchingSearchPatientFailed({ error: error.message }));
      }
    } else {
      const patientIndex = patientSearchEither.right();
      yield put(searchedPatient(patientIndex));
    }
  } catch (e) {
    console.log(e)
    yield put(fetchingSearchPatientFailed({ error: "Error searching Patient!" }));
  } finally {
    yield put(callFinished())
  }
}


function* completeIntakeSession(action: { type: string, authState: AuthState, payload: { prospectId: string, sessionId: string, isConsentGivenToCoCM: boolean, sourcePatientId: string, noteId: string, notesStatus: NotesStatus } }) {
  try {
    console.log(`ProspectsSaga:acceptProspect`)
    yield put(callInProgress())
    yield put(completeIntakeSessionInProgress())
    const apiAcceptProspectResponse: Either<CheckedError, ProspectWithStatus> = yield call(apiCompleteSession, action)
    if (apiAcceptProspectResponse.isLeft()) {
      const error = apiAcceptProspectResponse.left()
      if (error.isChecked) {
        console.log("ProspectsSaga:acceptProspect: Encountered a Checked Error", error.message)
        yield put(completeIntakeSessionFailed({ error: error.message }));
      } else {
        yield put(completeIntakeSessionFailed({ error: error.message }));
      }
    } else {
      let prospectWithStatus = apiAcceptProspectResponse.right();
      const isStatusUpdated = prospectWithStatus.screenerUserStatusUpdated && prospectWithStatus.prospectStatusUpdated && prospectWithStatus.notesStatusUpdated;
      yield put(completedIntakeSession(isStatusUpdated));
    }
  } catch (e) {
    console.log(e)
    yield put(completeIntakeSessionFailed({ error: "Error Accepting Prospect!" }));
  } finally {
    yield put(callFinished())
  }
}

const doFilterContent = (data: IAceScreener) => {
  return {
    ...data,
    answeredQuestions: data.answeredQuestions?.filter(aq => aq.screenerAnswer.content === 'Yes')
  }
}

const apiAcceptProspectReferenceData = (action: { type: string, authState: AuthState, payload: { patientId: string, practiceId: string } }) => {
  const url = `/source-patients/${action.payload.patientId}/reference-data?practiceId=${action.payload.practiceId}`;
  return apiHttpClient.get<Either<CheckedError, IReferenceData>>(url, { headers: { "x-auth-token": action.authState.token } })
    .then((response: AxiosResponse) => {
      return Either.right(response.data.data as IReferenceData)
    }).catch(e => catchErrorMessage(e));
}

const fetchAceScreenerDataAPI = (action: { type: string, authState: AuthState, payload: { patientId: string, practiceId: string } }) => {
  const url = `/source-patients/${action.payload.patientId}/ace-screener?practiceId=${action.payload.practiceId}`;
  return apiHttpClient.get<Either<CheckedError, any>>(url, { headers: { "x-auth-token": action.authState.token } })
    .then((response: AxiosResponse) => {
      return Either.right(doFilterContent(response.data.data as IAceScreener))
    }).catch(e => catchErrorMessage(e));
}

const patchIntakeFormDataToAPI = (action: { type: string, authState: AuthState, payload: { patientId: string, body: any } }) => {
  const url = `/${action.authState.accountId}/prospects/${action.payload.patientId}/intake`;
  return apiHttpClient.patch<Either<CheckedError, any>>(url, action.payload.body, { headers: { "x-auth-token": action.authState.token } })
    .then((response: AxiosResponse) => {
      return Either.right(response.data.data as IIntakeFormData)
    }).catch(e => catchErrorMessage(e));
}

const apiUpdateNotesStatus = (action: { type: string, authState: AuthState, payload: { notes: NotesStatusRequest } }) => {
  const url = `/${action.authState.accountId}/prospects/${action.payload.notes.patientProspectId}/notes/${action.payload.notes.notesId}/status`;
  const body = {
    sessionType: action.payload.notes.sessionType,
    notesStatus: action.payload.notes.status
  }
  return apiHttpClient.put<Either<CheckedError, { noteId: string }>>(url, body, getAuthHeaderConfig(action.authState))
    .then((response: AxiosResponse) => {
      return Either.right(response.data.data as any)
    }).catch(e => catchErrorMessage(e));
}

const apiFetchSessionBySrcPatientId = (action: { type: string, authState: AuthState, payload: { sourcePatientId: string } }) => {
  const url = `/source-patients/${action.payload.sourcePatientId}/sessions`;
  return apiHttpClient.get<Either<CheckedError, ISession>>(url, { headers: { "x-auth-token": action.authState.token } })
    .then((response: AxiosResponse) => {
      return Either.right(response.data.data as ISession)
    }).catch(e => catchErrorMessage(e));
}

const apiFetchPsychiatristSocialWorkers = (action: { type: string, authState: AuthState }) => {
  const url = `psychiatrist/${action.authState.accountId}/social-workers`;
  return apiHttpClient.get<Either<CheckedError, IProspect>>(url, { headers: { "x-auth-token": action.authState.token } })
    .then((response: AxiosResponse) => {
      return Either.right(response.data.data as IProspect)
    }).catch(e => catchErrorMessage(e));
}

function* fetchProspectReferenceApiData(action: { type: string, authState: AuthState, payload: { patientId: string, practiceId: string } }) {
  try {
    console.log(`ProspectsSaga:FETCH_PROSPECT_REFERENCE_API_DATA`)
    yield put(callInProgress())
    const apiRefData: Either<CheckedError, IReferenceData> = yield call(apiAcceptProspectReferenceData, action)
    if (apiRefData.isLeft()) {
      const error = apiRefData.left()
      if (error.isChecked) {
        console.log("ProspectsSaga:acceptProspect: Encountered a Checked Error", error.message)
        yield put(acceptingPatientRefDataFailed({ error: error.message }));
      } else {
        yield put(acceptingPatientRefDataFailed({ error: error.message }));
      }
    } else {
      const sourceRefData = apiRefData.right();
      yield put(acceptedProspectRefData(sourceRefData));
    }
  } catch (e) {
    console.log(e)
    yield put(acceptingPatientRefDataFailed({ error: "Error Getting Patient Ref Data!" }));
  } finally {
    yield put(callFinished())
  }
}

function* fetchAceScreenerData(action: { type: string, authState: AuthState, payload: { patientId: string, practiceId: string } }) {
  try {
    console.log(`ProspectsSaga:FETCH_ACE_SCREENER`)
    yield put(callInProgress());
    const apiAceData: Either<CheckedError, any> = yield call(fetchAceScreenerDataAPI, action)
    if (apiAceData.isLeft()) {
      const error = apiAceData.left()
      if (error.isChecked) {
        console.log("ProspectsSaga:acceptProspect: Encountered a Checked Error", error.message)
        yield put(acceptingAceScreenerFailed({ error: error.message }));
      } else {
        yield put(acceptingAceScreenerFailed({ error: error.message }));
      }
    } else {
      const aceScreener = apiAceData.right();
      yield put(acceptedAceScreener(aceScreener));
    }
  } catch (e) {
    console.log(e)
    yield put(acceptingAceScreenerFailed({ error: "Error Getting Patient Ref Data!" }));
  } finally {
    yield put(callFinished())
  }
}

function* patchIntakeFormData(action: { type: string, authState: AuthState, payload: { patientId: string, body: any } }) {
  try {
    console.log(`ProspectsSaga:Patch Intake form`)
    yield put(asyncCallInProgress());
    const apiAceData: Either<CheckedError, any> = yield call(patchIntakeFormDataToAPI, action)
    if (apiAceData.isLeft()) {
      const error = apiAceData.left()
      if (error.isChecked) {
        console.log("ProspectsSaga:acceptPatchIntakeForm: Encountered a Checked Error", error.message)
        yield put(intakeFormSaveFailed({ error: error.message }));
      } else {
        yield put(intakeFormSaveFailed({ error: error.message }));
      }
    } else {
      const intakeFormData = apiAceData.right();
      yield put(intakeFormSaveSuccess(_.keys(action.payload.body.patientIntakeNotes), intakeFormData));
    }
  } catch (e) {
    console.log(e)
    yield put(intakeFormSaveFailed({ error: "Error Getting Patch Intake Form!" }));
  } finally {
    yield put(asyncCallFinished())
  }
}

function* fetchSessionBySrcPatientId(action: { type: string, authState: AuthState, payload: { sourcePatientId: string } }) {
  try {
    console.log(`ProspectsSaga:fetchSessionBySrcPatient`)
    yield put(callInProgress())
    const apiFetchSessionResponse: Either<CheckedError, IAppointmentSession> = yield call(apiFetchSessionBySrcPatientId, action)
    if (apiFetchSessionResponse.isLeft()) {
      const error = apiFetchSessionResponse.left()
      if (error.isChecked) {
        console.log("ProspectsSaga: Encountered a Checked Error", error.message)
        yield put(fetchSessionBySourcePatientIdFailed({ error: error.message }));
      } else {
        yield put(fetchSessionBySourcePatientIdFailed({ error: error.message }));
      }
    } else {
      const session = apiFetchSessionResponse.right();
      yield put(fetchSessionBySourcePatientIdSuccess(session));
    }
  } catch (e) {
    console.log(e)
    yield put(fetchSessionBySourcePatientIdFailed({ error: "Error fetching session by src patient!" }));
  } finally {
    yield put(callFinished())
  }
}

function* lockIntakeForm(action: { type: string, authState: AuthState, payload: { prospectId: string } }) {
  try {
    console.log(`ProspectsSaga:lockProspectIntake`)
    yield put(callInProgress())
    const apiLockIntakeResponse: Either<CheckedError, ILockIntakeResponse> = yield call(apiLockIntake, action)
    if (apiLockIntakeResponse.isLeft()) {
      const error = apiLockIntakeResponse.left()
      if (error.isChecked) {
        console.log("ProspectsSaga:lockProspectIntake: Encountered a Checked Error", error.message)
        yield put(lockIntakeFailed({ error: error.message }));
      } else {
        yield put(lockIntakeFailed({ error: error.message }));
      }
    } else {
      const lockIntake = apiLockIntakeResponse.right();
      yield put(lockIntakeSuccess(lockIntake));
    }
  } catch (e) {
    console.log(e)
    yield put(lockIntakeFailed({ error: "Error Locking Intake form!" }));
  } finally {
    yield put(callFinished())
  }
}


function* fetchPractices(action: { type: string, authState: AuthState }) {
  try {
    console.log(`ProspectsSaga:fetchPractices`)
    yield put(callInProgress())
    const apiFetchPracticesResponse: Either<CheckedError, Practice[]> = yield call(apiFetchPractices, action)
    if (apiFetchPracticesResponse.isLeft()) {
      const error = apiFetchPracticesResponse.left()
      if (error.isChecked) {
        console.log("ProspectsSaga: Encountered a Checked Error", error.message)
        yield put(fetchPracticesFailed({ error: error.message }));
      } else {
        yield put(fetchPracticesFailed({ error: error.message }));
      }
    } else {
      const practices = apiFetchPracticesResponse.right();
      yield put(fetchedPractices(practices));
    }
  } catch (e) {
    console.log(e)
    yield put(fetchPracticesFailed({ error: "Error fetching practices!" }));
  } finally {
    yield put(callFinished())
  }
}

function canSWAssignScreeners(payload: NotesStatusRequest) {
  return payload.sessionType === SessionTypes.TreatmentPlan && payload.status === NotesStatus.PENDING_PSYCH_REVIEW
}

function* updateNotesStatus(action: { type: string, authState: AuthState, payload: { notes: NotesStatusRequest } }) {
  try {
    console.log(`ProspectsSaga:updateNotesStatus`)
    yield put(callInProgress())
    const apiNotesStatusUpdate: Either<CheckedError, { noteId: string }> = yield call(apiUpdateNotesStatus, action)
    if (apiNotesStatusUpdate.isLeft()) {
      const error = apiNotesStatusUpdate.left()
      if (error.isChecked) {
        console.log("ProspectsSaga: Encountered a Checked Error", error.message)
        yield put(updateNotesStatusFailed({ error: error.message }));
      } else {
        const payload = action.payload.notes
          const response = apiNotesStatusUpdate.right();
          yield put(updateLatestNotesStatus(payload.status));
          yield put(updatedNotesStatus(!!response.noteId));

          if(payload.sessionType === SessionTypes.Intake) {
            yield put(updatedNotesStatusIntake(payload.status))
          }

          if(canSWAssignScreeners(payload) && payload.screeners && payload.sourcePatientId && payload.practiceId){
            yield put(reviewAndSubmitTreatmentNotes(payload.patientProspectId, payload.screeners, payload.sourcePatientId, payload.notesId, payload.practiceId))
          }
      }
    } else {
      const payload = action.payload.notes
      const response = apiNotesStatusUpdate.right();
      yield put(updateLatestNotesStatus(payload.status));
      yield put(updatedNotesStatus(!!response.noteId));

      if (payload.sessionType === SessionTypes.Intake) {
        yield put(updatedNotesStatusIntake(payload.status))
      }

      if (payload.sessionType === SessionTypes.TreatmentPlan && payload.status === NotesStatus.PENDING_PSYCH_REVIEW && payload.screeners && payload.sourcePatientId && payload.practiceId) {
        yield put(reviewAndSubmitTreatmentNotes(payload.patientProspectId, payload.screeners, payload.sourcePatientId, payload.notesId, payload.practiceId))
      }
    }
  } catch (e) {
    console.log(e)
    yield put(updateNotesStatusFailed({ error: "Error updating notes status!" }));
  } finally {
    yield put(callFinished())
  }
}

function* fetchPsychiatristSocialWorkers(action: { type: string, authState: AuthState }) {
  try {
    console.log(`ProspectsSaga:fetchPractices`)
    yield put(callInProgress())
    const apiFetchPsychiatristSocialWorkersResponse: Either<CheckedError, ITherapist[]> = yield call(apiFetchPsychiatristSocialWorkers, action)
    if (apiFetchPsychiatristSocialWorkersResponse.isLeft()) {
      const error = apiFetchPsychiatristSocialWorkersResponse.left()
      if (error.isChecked) {
        console.log("ProspectsSaga: Encountered a Checked Error", error.message)
        yield put(fetchPsychiatristSocialWorkersFailed({ error: error.message }));
      } else {
        yield put(fetchPsychiatristSocialWorkersFailed({ error: error.message }));
      }
    } else {
      const therapists = apiFetchPsychiatristSocialWorkersResponse.right();
      yield put(fetchedPsychiatristSocialWorkers(therapists));
    }
  } catch (e) {
    console.log(e)
    yield put(fetchPsychiatristSocialWorkersFailed({ error: "Error fetching practices!" }));
  } finally {
    yield put(callFinished())
  }
}


export default function* prospectsSaga() {
  yield takeLatest(FETCH_PRACTICES, fetchPractices)
  yield takeLatest(FETCH_PROSPECTS, fetchProspects)
  yield takeLatest(FETCH_PROSPECT_SESSION_BY_SRC_PATIENT, fetchSessionBySrcPatientId)
  yield takeLatest(FETCH_PROSPECT, fetchProspect)
  yield takeLatest(FETCH_PROSPECT_WITH_SUMMARY, fetchProspectWithSummary)
  yield takeLatest(FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID, fetchProspectWithSummaryBySessionId)
  yield takeLatest(FETCH_PROSPECT_SCREENER_RESPONSE, fetchProspectScreenerResponses)
  yield takeLatest(FETCH_PROSPECT_REFERENCE_DATA, fetchProspectReferenceApiData)
  yield takeLatest(FETCH_ACE_SCREENER, fetchAceScreenerData)
  yield takeLatest(SEARCH_PATIENT, searchPatient)
  yield takeLatest(COMPLETE_INTAKE_SESSION, completeIntakeSession)
  yield takeLatest(COMPLETED_INTAKE_SESSION, fetchProspects)
  yield takeLatest(INTAKE_FORM_SAVE, patchIntakeFormData)
  yield takeLatest(LOCK_INTAKE_FORM, lockIntakeForm)
  yield takeLatest(UPDATE_NOTE_STATUS, updateNotesStatus)
  yield takeLatest(FETCH_PSYCHIATRIST_SOCIAL_WORKERS, fetchPsychiatristSocialWorkers)
}
