import React from "react";
import {IScreenerQuestionAnswer} from "../../../models";
import QuestionAnswer from "../../generic/QuestionAnswer";

const ACEResults: React.FC<{screenerSlug: string, backgroundColor: string, screenerScoreMessage: string, questionAnswers: IScreenerQuestionAnswer[]}> = ({screenerSlug, backgroundColor, screenerScoreMessage, questionAnswers}) => {
    function renderQuestionAnswers(groupedQuestionAnswers: IScreenerQuestionAnswer[]) {
        return groupedQuestionAnswers && groupedQuestionAnswers.length > 0 ?
            (
                <div className={"flex flex-col"}>
                    <div className={`m-2 w-full uppercase p-3 rounded ${backgroundColor}`}> <span dangerouslySetInnerHTML={{__html: screenerScoreMessage || ""}} /> </div>
                    {groupedQuestionAnswers.map((questionAnswer, index) => {
                        return <QuestionAnswer key={questionAnswer.questionId} questionAnswer={questionAnswer}
                                               index={index} screenerSlug={screenerSlug}/>
                    })}
                </div>
            )
            : null;
    }

    function normalConditionQuestionAnswers() {
        return renderQuestionAnswers(questionAnswers);
    }

    return questionAnswers && questionAnswers.length > 0
        ? (<>
            { normalConditionQuestionAnswers() }
        </>)
        : null
}

export default ACEResults