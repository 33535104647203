import React, {useCallback, useEffect, useState} from "react";
import {fetchLocations} from "../../store/actions/physician/admin-operations.action";
import {useDispatch, useSelector} from "react-redux";
import {LocationIndexItem} from "../../models";
import {StateParams} from "../../store/reducers";
import {List, ListItem, ListItemSuffix, Typography,} from "@material-tailwind/react";
import Modal from 'react-modal';
import {setPhysicianDefaultLocation} from "../../store/actions/physician/account.action";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import {AiFillCloseCircle} from "react-icons/ai";
import _ from "lodash";
import {Button, PageButtonV2} from "../generic/Buttons";
import { Chip } from "@material-tailwind/react";
import {Tooltip} from "react-tooltip";
import {ImageConfig} from "../../constants";

interface ModalProps {
    changeDefault?: boolean
    onClose?: () => void
    locationChanged?: () => void
}

const PhysicianLocationSelectionComponent: React.FC<ModalProps> = ({changeDefault, onClose, locationChanged}) => {
    const dispatch = useDispatch()
    const {defaultLocation} = useSelector((state: StateParams) => state.physicianAccount)
    const {locations: fetchedLocations} = useSelector((state: StateParams) => state.physicianAdminOperations)
    const [locations, setLocations] = useState<LocationIndexItem[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<LocationIndexItem>();
    const [isModalOpen, setIsModalOpen] = useState(false)

    useEffect(() => {
        dispatch(fetchLocations({}))
    }, []);

    useEffect(() => {
        if (fetchedLocations) {
            console.log('fetchedLocations', fetchedLocations)
            fetchedLocations.sort(function(x,y){ return x.id === defaultLocation?.id ? -1 : y.id === defaultLocation?.id ? 1 : 0; });
            setLocations(fetchedLocations)
        }
    }, [fetchedLocations, defaultLocation])

    useEffect(() => {
        if (locations.length === 1) {
            dispatch(setPhysicianDefaultLocation(locations[0]))
        }
    }, [locations, defaultLocation])

    useEffect(() => {
        const hasMoreThanOneLocationWithNoDefaultSet = defaultLocation === undefined && locations.length > 1;
        setIsModalOpen(hasMoreThanOneLocationWithNoDefaultSet || changeDefault === true)
        return () => {
            setSelectedLocation(undefined)
        }
    }, [defaultLocation, changeDefault, locations])

    const selectLocation = useCallback((location: LocationIndexItem) => {
        if(location.id !== defaultLocation?.id) {
            setSelectedLocation(location)
        }
    }, [defaultLocation])

    const setDefaultLocation = useCallback(() => {
        if(selectedLocation) {
            dispatch(setPhysicianDefaultLocation(selectedLocation))
            locationChanged?.()
        }
    }, [selectedLocation])

    const setAllLocationsAsDefault = useCallback(() => {
        dispatch(setPhysicianDefaultLocation(null))
        setSelectedLocation(undefined)
        locationChanged?.()
    }, [])

    const formatAddress = (location: LocationIndexItem) => {
        const addressParts = [location.addressLine, location.city, location.zipCode]
        const validAddressParts = _.filter(addressParts, part => !_.isUndefined(part) && !_.isNull(part))
        return validAddressParts.join(", ")
    }

    const renderlocation = (location: LocationIndexItem) => {
        return <ListItem key={location.id} onClick={() => selectLocation(location)} className={"py-1 my-2 border hover:bg-gray-50 justify-between"}>
            <div className={(location.id === defaultLocation?.id || location.id === selectedLocation?.id) ? "w-3/4" : "w-full"}>
                <Tooltip id={`location-name-${location.id}`} className={"w-auto"}>
                    <div className="whitespace-normal break-all">{location?.name}</div>
                </Tooltip>
                <Typography data-tooltip-id={`location-name-${location.id}`} variant="lead" className={"truncate text-md text-black capitalize"}>
                    {location?.name || ""}
                </Typography>
                <Typography variant="paragraph" className={"truncate text-sm text-sjGrey whitespace-normal"}>
                    {formatAddress(location)}
                </Typography>
                <Typography variant="small" className="text-sjGrey text-sm">
                    {location.phone}
                </Typography>
            </div>
            {
                location.id === defaultLocation?.id
                    ? <Chip value="current" className={"text-sjOrange"}/>
                    : null
            }
            {
                location.id === selectedLocation?.id
                    ? <ListItemSuffix>
                        <CheckCircleIcon className="h-6 w-6 m-1 ml-4 self-center text-sjOrange"/>
                    </ListItemSuffix>
                    : null
            }
        </ListItem>
    }

    const renderLocations = () => {
        return <div className={"flex flex-col mt-4"}>
            <div className={"flex flex-row align-middle gap-x-8"}>
                <Typography className={"text-lg text-black"}>
                    Choose a Location
                </Typography>
            </div>
            <div className="mt-1 relative w-full overflow-auto max-h-[50vh]">
                <List>
                    {
                        locations.map((location, index) => {
                            return <div key={location.id}>
                                { renderlocation(location) }
                            </div>
                        })
                    }
                </List>
            </div>
            <div className={"flex flex-row self-center gap-x-6 items-center"}>
                <Button
                    className={`!border-sjOrange text-sjOrange h-8 px-[16px] mt-1 py-[8px] text-sm`}
                    onClick={setAllLocationsAsDefault}
                >
                    <div className={"flex flex-row gap-x-2 items-center"}>
                        <span>Select All Locations</span>
                    </div>

                </Button>
                <PageButtonV2
                    className={`${!selectedLocation || (defaultLocation && selectedLocation.id === defaultLocation.id) ? "!bg-sjGrey" : "!bg-sjOrange"} text-white mt-1 px-[16px] py-[8px] h-8 text-sm`}
                    disabled={!selectedLocation || (defaultLocation && selectedLocation.id === defaultLocation.id)}
                    onClick={setDefaultLocation}
                >

                    <div className={"flex flex-row gap-x-2 items-center"}>
                        <span>View Selected</span>
                    </div>

                </PageButtonV2>
            </div>
        </div>;
    }

    return <Modal
        ariaHideApp={false}
        isOpen={isModalOpen}
        shouldCloseOnOverlayClick={false}
        className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white border-sjOrange"
        contentLabel="Location Selection"
    >
        <div className="mt-1 flex flex-col">
            {defaultLocation
                ? <div onClick={onClose} className="cursor-pointer absolute top-1 right-1 m-1">
                    <AiFillCloseCircle
                        className="text-gray-500 hover:text-gray-700"
                        style={{width: '25px', height: '25px'}}
                    />
                </div>
                : null
            }

            {
                renderLocations()
            }
        </div>
    </Modal>
}

export default PhysicianLocationSelectionComponent
