import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { StateParams } from "../../store/reducers";
import { FiltersTypes, Roles } from "../../models";
import { fetchProspects } from "../../store/actions/counsellor/prospects.action";
import { RoutePaths } from "../../shared/Utils";
import Filters from "../generic/Filters";
import { ProspectsDataView } from "./common/ProspectsDataView";
import { CounsellorProspectsDataView } from "./CounsellorProspectsDataView";
import { CareCoordinatorProspectsDataView } from "./care-coordinator/CareCoordinatorProspectsDataView";
import ProspectTable from "./ProspectTable";
import PaginationV2 from "../generic/PaginationV2";
import StatusTabs from "../generic/StatusTabs";
import { PsychiatristProspectsDataView } from "./PsychiatristProspectsDataView";

const ProspectsDataViewByRole: { [key: string]: ProspectsDataView } = {
    Admin: new CounsellorProspectsDataView(),
    Counsellor: new CounsellorProspectsDataView(),
    Care_Coordinator: new CareCoordinatorProspectsDataView(),
    Psychiatrist: new PsychiatristProspectsDataView(),
}

const COUNSELLORTABS = [
    { label: "All", value: "all" },
    { label: "Scheduled", value: "scheduled" },
    { label: "In Progress", value: "in_progress" },
    { label: "Reschedule", value: "reschedule" },
    { label: "No Show", value: "no_show" },
    { label: "Not Accepted", value: "not_accepted" }

];

const CARECOORDINATORTABS = [
    { label: "All", value: "all" },
    { label: "Pending", value: "pending" },
    { label: "Scheduled", value: "scheduled" },
    { label: "Reschedule", value: "reschedule" },
    { label: "Call Again", value: "call_again" },
    { label: "No Answer", value: "no_answer" },
    { label: "No Show", value: "no_show" },
    { label: "Not Accepted", value: "not_accepted" }
];

const Prospects: React.FC<{ role: string }> = ({ role }) => {
    const prospectsDataViewForRole = ProspectsDataViewByRole[role];
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { prospects, prospectsCount, tabCount } = useSelector((state: StateParams) => state.prospects)
    const prospectsFetchInProgress = useSelector((state: StateParams) => state.prospects.fetchProspectsInProgress)
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 1);
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [filters, setFilters] = useState<FiltersTypes>();
    const [searchText, setSearchText] = useState('')
    const defaultTab = role === Roles.Care_Coordinator ? "pending" : 'scheduled'
    const [activeTab, setActiveTab] = useState(searchParams.get("tab") || defaultTab);
    const isPsychiatrist = Roles.Psychiatrist === role;


    const handleSelectedFilters = (status: string) => {
        setCurrentPage(1)
        setActiveTab(status);
    }

    const handleFiltersSubmit = (data: any) => {
        setFilters(data)
    }

    useEffect(() => {
        if (searchParams && searchParams.get("page")) {
            setCurrentPage(Number(searchParams.get("page")) || 1)
            setActiveTab(searchParams.get("tab") || defaultTab)
        }
    }, [searchParams])

    useEffect(() => {
        if (role === Roles.Care_Coordinator) {
            navigate(`${RoutePaths.careCoordinator.referredPatient.root}?page=${currentPage}&tab=${activeTab}`)
        } else {
            navigate(`${RoutePaths.collaborativeCare.prospects.root}?page=${currentPage}&tab=${activeTab}`)
        }
    }, [currentPage, role, activeTab]);

    useEffect(() => {
        let selectedTab = activeTab === 'all' ? '' : activeTab;
        if (isPsychiatrist) {
            if (activeTab) {
                searchParams.delete('tab');
                setSearchParams(searchParams);
            }
            selectedTab = 'not_accepted';
        }
        dispatch(fetchProspects(currentPage, recordsPerPage, selectedTab, filters, searchText));
    }, [currentPage, recordsPerPage, filters, searchText, activeTab, searchParams, dispatch, isPsychiatrist]);

    

    const getStatusTabClassName = (value: string) => {
        return activeTab === value ? "bg-sjLightOrange text-sjOrange" : "bg-sjWhite text-black"
    };

    return <div className={"w-full p-4 h-full bg-[#F7F8FA]"}>
        <div className="flex flex-row justify-between pb-4">
            <h1 className="text-xl"> Referred Patients </h1>
            <Filters
                onSubmit={handleFiltersSubmit}
                setCurrentPage={setCurrentPage}
                role={role}
                setSearchText={setSearchText} />
        </div>
        {!isPsychiatrist && <div className={"flex justify-between pb-2"}>
            <div className="inline-flex rounded-md shadow-sm">
                <StatusTabs
                    tabs={role === Roles.Care_Coordinator ? CARECOORDINATORTABS : COUNSELLORTABS}
                    handleSelectedFilters={handleSelectedFilters}
                    getTabClassName={getStatusTabClassName}
                    tabCount={tabCount}
                />
            </div>
        </div>}
        <ProspectTable dataView={prospectsDataViewForRole} prospects={prospects} searchInProgress={prospectsFetchInProgress} activeTab={activeTab} />
        {prospects && prospects.length > 0 &&
            <PaginationV2
                totalRows={prospectsCount || 0}
                rowsPerPage={recordsPerPage}
                recordsPerPageHandler={setRecordsPerPage}
                currentPage={currentPage}
                currentPageHandler={setCurrentPage}
                showPages={false}
            />
        }
    </div>
}

export default Prospects
