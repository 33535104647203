import {Tooltip} from "react-tooltip";
import React from "react";
import {DateTime} from "luxon";

interface ClinicalNotePendingSinceComponentProps {
    noteId: string;
    noteCreatedAt: string;
    pendingSinceDays: number;
}

const ClinicalNotePendingSinceComponent = (props: ClinicalNotePendingSinceComponentProps) => {

    const {noteId, noteCreatedAt, pendingSinceDays} = props;
    const isOverdue = pendingSinceDays && pendingSinceDays > 3;
    const createdAt = DateTime.fromISO(noteCreatedAt).toLocaleString(DateTime.DATE_SHORT);
    const tooltipId = `pending-since-tooltip-${noteId}`;

    return (
        <>
            <Tooltip place={"top"}
                     id={tooltipId}
            >
                Note Created On {createdAt}
            </Tooltip>
            <div>
                {pendingSinceDays ? <span data-tooltip-id={tooltipId}
                                          className={isOverdue ? 'text-red-500' : 'text-green-500'}> {pendingSinceDays} days</span> : 'N/A'}
            </div>
        </>
    )
}

export default ClinicalNotePendingSinceComponent;
