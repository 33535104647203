import {useCallback} from "react";
import {Tooltip} from "react-tooltip";

interface ISwitchComponent {
    isChecked?: boolean;
    onChange: (isChecked: boolean) => void;
    className?: string;
    checkedLabel?: string;
    uncheckedLabel?: string;
    disabled?: boolean;
    disabledReason?: string;
}

const SwitchComponent = (props: ISwitchComponent) => {

    const {isChecked, onChange, disabled, className} = props;
    const checkedLabel = props?.checkedLabel || 'Active';
    const uncheckedLabel = props?.uncheckedLabel || 'Inactive';
    const disabledReason = props?.disabledReason || "Disabled";

    const handleClick = useCallback((e: any) => {
        e.preventDefault();
        if (!disabled) {
            onChange(!isChecked);
        }
    }, [disabled, isChecked, onChange]);

    return (
        <>
            <label className={`switch ${className} ${disabled ? 'disabled' : ''}`}
                   data-tooltip-id={'switch-tooltip'}
                   onClick={handleClick}>
                <input className={isChecked ? 'checked' : ''} type="checkbox"/>
                <div className="slider round">
                    <span className="on">{checkedLabel}</span>
                    <span className="off">{uncheckedLabel}</span>
                </div>
            </label>
            <Tooltip
                id={'switch-tooltip'}
                place="top"
                hidden={!disabled}
            >
                {disabledReason}
            </Tooltip>
        </>
    )
}

export default SwitchComponent;
