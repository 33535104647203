
import {
    XYChart,
    Tooltip,
    Axis,
    Grid,
    LineSeries,
    GlyphSeries,
    lightTheme,
    XYChartTheme
} from '@visx/xychart';
import { IProspect, NotesStatus, SWScreenerDataPoints, SWScreenerInsights, SessionTypes } from '../../models';
import { useSelector } from 'react-redux';
import { StateParams } from '../../store/reducers';
import React, { useCallback, useMemo } from 'react';
import NotCompleted from '../../assets/images/common/notCompleted.png'
import { DateTime } from 'luxon';
import _ from 'lodash';
import { FaCircle } from "react-icons/fa";
import {} from '@visx/responsive'
import { convertISODateToMMDDYYYY } from '../../shared/DateUtils';

type DataPoints = {
    x: string,
    y: number,
    z?: string
}

const DEFAULT_COLOR = '#F61717'
const negativeStatusValues = ["Negative", "Low Risk", "Minimal", "No Risk", "Mild"]
const COLOR_CODES: {[index: number]: string} = {
    0: '#F61717',
    1: '#60A5FA',
    2: 'orange',
    3: '#FFA200',
    4: 'navy',
    5: '#2F4F4F'
}

const SWScreeners: React.FC<{ patient: IProspect }> = ({ patient }) => {
    const { swScreenerInsights, patientJourney } = useSelector((state: StateParams) => state.patients)
    const latestTreatment = _.maxBy(patientJourney?.filter(x => x.sessionType === SessionTypes.TreatmentPlan), x => x?.createdAt)
    const latestTreatmentSubmitted = latestTreatment ? latestTreatment?.notesStatus !== NotesStatus.PENDING_SUBMIT_TO_PSYCH : false
    
    const accessors = useMemo(() => ({
        xAccessor: (d: any) => DateTime.fromISO(d?.x).toJSDate(),
        yAccessor: (d: any) => d.y
    }), [])

    const tooltipWithScore = useCallback((date: Date, score: number, interpretation?: string) => {
        const isNegative = interpretation && negativeStatusValues.some(value => interpretation.includes(value))
        return (
            <div>
                <p className='font-normal'>{DateTime.fromJSDate(date).toFormat("MMM!yy").replace('!', "'")}
                    <span> Score: <span className='font-semibold'>{score}</span></span>
                </p>
                <p className='mt-1 font-normal'>Interpretation: <span className={`font-semibold ${isNegative ? 'text-green-500' : 'text-red-500'}`}>{interpretation}</span></p>
            </div>
        )
    }, [])

    const tooltipWhenScreenerNotTaken = useCallback((date: Date) => {
        const isCurrentMonth = isDateInCurrentMonth(date)
        if(isCurrentMonth) {
            return (
                <div>
                    <span className='font-semibold'>Patient has not taken the screener for {DateTime.fromJSDate(date).toFormat("MMM!yy").replace('!', "'")}.</span>
                </div>
            )
        }
        return (
            <div>
                <span className='font-semibold'>Patient missed screener in {DateTime.fromJSDate(date).toFormat("MMM!yy").replace('!', "'")}.</span>
            </div>
        )
    }, [])

    const isDateInCurrentMonth = (date: Date) => {
        const currentDate = DateTime.now()
        const targetDate = DateTime.fromJSDate(date)
        return targetDate.month === currentDate.month && targetDate.year === currentDate.year;
    }

    const renderLineChart = (data: DataPoints[], maxScore: number, color: string = DEFAULT_COLOR) => {
        const lastScreenerAssignedOn = _.maxBy(data, v => v.x)?.x
        const xAxisDomainDates = lastScreenerAssignedOn ? [DateTime.fromISO(lastScreenerAssignedOn).minus({ months: 6 }).endOf('month').toJSDate(), DateTime.fromISO(lastScreenerAssignedOn).toJSDate()]
            : [DateTime.now().minus({ months: 6 }).toJSDate(), DateTime.now().toJSDate()]

        return (
            <XYChart height={300} width={500} margin={{ top: 20, right: 30, bottom: 50, left: 45 }}
                xScale={{ type: "time", domain: xAxisDomainDates, clamp: true}} 
                yScale={{ type: "linear", domain: [0, maxScore] }}>
                <Axis orientation="bottom"
                    numTicks={4}
                    hideAxisLine={true}
                    tickFormat={d => {
                        return DateTime.fromJSDate(d).toFormat("MMM!yy").replace('!', "'")
                    }}
                    label='Assigned On'
                    labelProps={{ fontSize: 11, letterSpacing: 1 }}
                />
                <Axis orientation="left"
                    numTicks={5}
                    tickFormat={d => {
                        return typeof d === 'number' ? Math.round(d) : d
                    }}
                    label='Score'
                    labelProps={{ dx: '-1em', fontSize: 11, letterSpacing: 1 }}
                    />
                <Grid columns={true} numTicks={5} lineStyle={{
                    stroke: "#87858d",
                    strokeWidth: "1px",
                    strokeOpacity: 0.2
                }} />
                <LineSeries dataKey={data[0].x} data={Object.values(data)} {...accessors} colorAccessor={(_) => color} />
                <Tooltip
                    showVerticalCrosshair
                    renderTooltip={({ tooltipData }) => {
                        const index = tooltipData?.nearestDatum?.index || 0
                        const interpretation = data?.[index]?.z;
                        const date = accessors.xAccessor(tooltipData?.nearestDatum?.datum)
                        const score = accessors.yAccessor(tooltipData?.nearestDatum?.datum)

                        if(!interpretation && !tooltipData?.nearestDatum) return null
                        if(interpretation) {
                            return tooltipWithScore(date, score, interpretation) 
                        } else if(!interpretation && date) {
                            return tooltipWhenScreenerNotTaken(date)
                        }
                        return null
                    }}
                />
                <GlyphSeries data={Object.values(data)} dataKey={data[0].x} {...accessors} size={7} colorAccessor={(_) => color}/>
            </XYChart>
        )
    }

    const renderChart = React.useCallback((data: SWScreenerDataPoints[], screenerName: string, maxScore: number, index: number) => {
        if (!data || !Object.values(data)) return null

        const dataPoints = Object.values(data).map((x: SWScreenerDataPoints) => ({ x: x.assignedOn, y: x.score, z: x.interpretation }))
        const color = COLOR_CODES[index]

        return (
            <div>
                <div className='flex flex-row px-10 items-center'>
                    <FaCircle className='text-sjLabel text-xs mr-2' style={{ color: color }} />
                    <p className='text-sm text-sjLabel'>{screenerName}</p>
                </div>
                {renderLineChart(dataPoints, maxScore, color)}
            </div>
        )
    }, [])

    const NoCompletedScreen = () => {
        return (
            <div className='flex flex-col py-24 justify-center items-center'>
                <img src={NotCompleted} className='w-40 h-40' />
                <p className='text-base text-sjLabel pt-4'>The patient has not completed any assigned screeners.</p>
            </div>
        )
    }

    const NoTreatmentPlan = () => {
        return (
            <div className='flex flex-col py-24 justify-center items-center'>
                <img src={NotCompleted} className='w-40 h-40' />
                <p className='text-base text-sjLabel pt-4'>The patient does not have a Treatment Plan available to display assigned screener insights.</p>
            </div>
        )
    }

    const renderScoresChart = (insights: SWScreenerInsights, index: number) => {
        return (
            <div className='pt-10'>
                {renderChart(insights.data, insights.screenerName, insights.maxScore, index)}
            </div>
        )
    }

    if(!latestTreatmentSubmitted) {
        return <NoTreatmentPlan/>
    }

    if(!swScreenerInsights || !swScreenerInsights.length) {
        return <NoCompletedScreen/>
    }

    return (
        <div>
            <div className='pt-5'>
                <p className='text-lg text-sjHeader'>Patient's Screener Score</p>
                <p className='text-xs text-sjGray'>The patient's screener scores varied across months during the Treatment Plan initiated on {latestTreatment && convertISODateToMMDDYYYY(latestTreatment.createdAt)}.</p>
            </div>
            <div className='grid grid-cols-2'>
                {
                    swScreenerInsights.map((insights, index) => renderScoresChart(insights, index))
                }
            </div>
        </div>
    )
}

export default SWScreeners