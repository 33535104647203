import React from "react";
import {Link} from "react-router-dom";
import { Button } from "./Buttons";

export interface Value {
    url: string
    practiceId: string
    sourcePatientId: string
}

const renderDetailsLink = (value: any) => {
    return (
        <Link to={`${value.url}?practiceId=${value.practiceId}&sourcePatientId=${value.sourcePatientId}`}>
            <Button className={"!bg-sjOrange !text-white !ml-2"}>View Details</Button>
        </Link>
    );
}
export const DetailsButton: React.FC<{value: Value}> = ({ value }) => {
    return renderDetailsLink(value)
}

export const DetailsButtonV2: React.FC<{getValue: any}> = ({ getValue }) => {
    const value = getValue()
    return renderDetailsLink(value)
}
