import {Radio, Textarea} from "@material-tailwind/react"
import {UseFormRegister, UseFormWatch} from "react-hook-form"
import mentalStatusExam from "../../../assets/data/mentalStatusExam"
import {Inputs} from "./ProgressNotes"
import React from "react";

const MentalExam: React.FC<{
    register: UseFormRegister<Inputs>, watch: UseFormWatch<Inputs>,
}> = ({ register, watch }) => {

    return (
        <div className="divide-y">
            <div className="mt-3">
                <label className="text-lg uppercase">MENTAL STATUS EXAM:</label>
                <label className="block text-lg mt-3 text-sjOrange uppercase">
                    General Appearance & Behavior:
                </label>
                <div className="mt-3">
                    <label className="font-semibold">Hygiene</label>
                    <div className="flex grid grid-flow-row grid-cols-4 ">
                        {mentalStatusExam[0].generalApearanceAndBehavior[0].hygiene.map((x) =>
                            <Radio {...register("hygiene")} className="text-sjOrange" label={x.label} value={x.value} />
                        )}
                    </div>
                </div>
                <div className="mt-3">
                    <label className="font-semibold">Grooming</label>
                    <div className="grid grid-flow-row grid-cols-4 gap-3">
                        {mentalStatusExam[0].generalApearanceAndBehavior[0].grooming.map((x) =>
                            <Radio {...register("grooming")} className="text-sjOrange" label={x.label} value={x.value} />
                        )}
                    </div>
                </div>
                <div className="mt-3">
                    <label className="font-semibold">Eye Contact</label>
                    <div className="grid grid-flow-row grid-cols-4 gap-3">
                        {mentalStatusExam[0].generalApearanceAndBehavior[0].eyeContact.map((x) =>
                            <Radio {...register("eyeContact")} className="text-sjOrange" label={x.label} value={x.value} />
                        )}
                    </div>
                </div>
                <div className="mt-3">
                    <label className="font-semibold">Behavior</label>
                    <div className="grid grid-flow-row grid-cols-4 gap-3 mt-2">
                        {mentalStatusExam[0].generalApearanceAndBehavior[0].behavior.map((x) =>
                            <div className="flex items-center">
                                <label className="text-sm">
                                    <input type="checkbox" {...register(`behavior`)}
                                        value={x.value} className={'text-sjOrange'} />
                                    <span className="ml-1">{x.label}</span>
                                </label>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <label className="block text-lg mt-3 text-sjOrange uppercase">Motor Activity:</label>
                <div className="grid grid-flow-row grid-cols-4 gap-3 mt-2">
                    {mentalStatusExam[0].motorActivity.map((x) =>
                        <div className="flex items-center">
                            <label className="text-sm">
                                <input type="checkbox" {...register(`motorActivity`)}
                                    value={x.value} className={'text-sjOrange'} />
                                <span className="ml-1">{x.label}</span>
                            </label>
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-3">
                <label className="block text-lg mt-3 text-sjOrange uppercase">Affect:</label>
                <div className="grid grid-flow-row grid-cols-4 gap-3 mt-2">
                    {mentalStatusExam[0].affect.map((x) =>
                        <div className="flex items-center">
                            <label className="text-sm">
                                <input type="checkbox" {...register(`affect`)}
                                    value={x.value} className={'text-sjOrange'} />
                                <span className="ml-1">{x.label}</span>
                            </label>
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-3">
                <label className="block text-lg mt-3 text-sjOrange uppercase">Speech:</label>
                <div className="mt-3">
                    <label className="font-semibold">Rate:</label>
                    <div className="grid grid-flow-row grid-cols-4 gap-3 mt-2">
                        {mentalStatusExam[0].speech[0].rate.map((x) =>
                            <div className="flex items-center">
                                <label className="text-sm">
                                    <input type="checkbox" {...register(`rate`)}
                                        value={x.value} className={'text-sjOrange'} />
                                    <span className="ml-1">{x.label}</span>
                                </label>
                            </div>
                        )}
                    </div>
                </div>
                <div className="mt-3">
                    <label className="font-semibold">Fluency/Rhythm:</label>
                    <div className="grid grid-flow-row grid-cols-4 gap-3 mt-2">
                        {mentalStatusExam[0].speech[0].fluencyOrRhythm.map((x) =>
                            <div className="flex items-center">
                                <label className="text-sm">
                                    <input type="checkbox" {...register(`fluencyOrRhythm`)}
                                        value={x.value} className={'text-sjOrange'} />
                                    <span className="ml-1">{x.label}</span>
                                </label>
                            </div>
                        )}
                    </div>
                </div>
                <div className="mt-3">
                    <label className="font-semibold">Relevance:</label>
                    <div className="grid grid-flow-row grid-cols-4 gap-3 mt-2">
                        {mentalStatusExam[0].speech[0].relevance.map((x) =>
                            <div className="flex items-center">
                                <label className="text-sm">
                                    <input type="checkbox" {...register(`relevance`)}
                                        value={x.value} className={'text-sjOrange'} />
                                    <span className="ml-1">{x.label}</span>
                                </label>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <label className="block text-lg mt-3 text-sjOrange uppercase">Thought Process:</label>
                <div className="grid grid-flow-row grid-cols-4 gap-3 mt-2">
                    {mentalStatusExam[0].thoughtProcess.map((x) =>
                        <div className="flex items-center">
                            <label className="text-sm">
                                <input type="checkbox" {...register(`thoughtProcess`)}
                                    value={x.value} className={'text-sjOrange'} />
                                <span className="ml-1">{x.label}</span>
                            </label>
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-3">
                <label className="block text-lg mt-3 text-sjOrange uppercase">Thought Content:</label>
                <div className="mt-3">
                    <label className="font-semibold">Delusions:</label>
                    <div className="grid grid-flow-row grid-cols-4 gap-3 mt-2">
                        {mentalStatusExam[0].thoughtContent[0].delusions.map((x) =>
                            <div className="flex items-center">
                                <label className="text-sm">
                                    <input type="checkbox" {...register(`delusions`)}
                                        value={x.value} className={'text-sjOrange'} />
                                    <span className="ml-1">{x.label}</span>
                                </label>
                            </div>
                        )}
                    </div>
                </div>
                <div className="mt-3">
                    <label className="font-semibold">Suicidal thought:</label>
                    <div className="grid grid-flow-row grid-cols-4 gap-3 mt-2">
                        {mentalStatusExam[0].thoughtContent[0].suicidalThought.map((x) =>
                            <div className="flex items-center">
                                <label className="text-sm">
                                    <input type="checkbox" {...register(`suicidalThought`)}
                                        value={x.value} className={'text-sjOrange'} />
                                    <span className="ml-1">{x.label}</span>
                                </label>
                            </div>
                        )}
                    </div>
                </div>
                <div className="mt-3">
                    <label className="font-semibold">Homicidal thought:</label>
                    <div className="grid grid-flow-row grid-cols-4 gap-3 mt-2">
                        {mentalStatusExam[0].thoughtContent[0].homicidalThought.map((x) =>
                            <div className="flex items-center">
                                <label className="text-sm">
                                    <input type="checkbox" {...register(`homicidalThought`)}
                                        value={x.value} className={'text-sjOrange'} />
                                    <span className="ml-1">{x.label}</span>
                                </label>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <label className="block text-lg mt-3 text-sjOrange uppercase">Other</label>
                <Textarea {...register("mentalStatusOther")} className="mt-3 outline-none rounded-md" />
            </div>
        </div>
    )
}

export default MentalExam