import {Link, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {StateParams} from "../../store/reducers";
import {fetchPatient} from "../../store/actions/counsellor/patients.action";
import PatientProfileDetails from "./PatientProfileDetails";
import {IoArrowBackCircleOutline} from "react-icons/io5";
import {IProspect} from "../../models";
import BackIcon from "../../assets/images/common/back.svg";
import * as _ from "lodash";

const PatientDetailsComponent = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const patient = useSelector((state: StateParams) => state.patients.patient)

    useEffect(() => {
        if (params.patientId) {
            dispatch(fetchPatient(String(params.patientId)))
        }
    }, [params.patientId]);

    function renderBackButton(patient: IProspect) {
        return <div className={"flex flex-row gap-2 items-center"}>
            <Link to={".."} onClick={(e) => {
                e.preventDefault();
                navigate(-1);
            }}>
                <img src={BackIcon} width={24} height={24} alt="Go Back"
                     className={"hover:cursor-pointer"}/>
            </Link>
            <div id={"link-section"} className={"flex flex-row gap-2 items-center"}>
                <span className="text-xl font-semibold text-sjGray"> Patient Profile {'|'} </span>
                <span className="text-xl font-semibold"> {_.capitalize(patient.firstName)} {_.capitalize(patient.lastName)} </span>
            </div>
        </div>;
    }

    return (
        <main className="relative min-h-screen mx-auto flex flex-row bg-[#F7F8FA]">
            <div className="mt-2 px-5 flex-1">
                {/* eslint-disable-next-line react/jsx-no-undef */}
                {patient ? renderBackButton(patient) : null}

                <div className="mt-4">
                {patient
                    ? <PatientProfileDetails patient={patient}/>
                    : null
                }
                </div>
            </div>
        </main>
    )
}
export default PatientDetailsComponent;
