import React, {useState} from "react";
import {IScreenerQuestionAnswer, ISummary} from "../../../models";
import * as _ from "lodash";
import {Accordion, AccordionBody, AccordionHeader} from "@material-tailwind/react";
import QuestionAnswer from "../../generic/QuestionAnswer";

enum Conditions {
    "panic-disorder",
    "generalized-anxiety-disorder",
    "separation-anxiety-disorder",
    "social-anxiety-disorder",
    "school-avoidance"
}

type ConditionType = keyof typeof Conditions

const CONDITION_MAP : {[key in ConditionType]: string} = {
    "panic-disorder": "Significant Somatic Symptoms",
    "generalized-anxiety-disorder": "Generalized Anxiety Disorder",
    "separation-anxiety-disorder": "Separation Anxiety Disorder",
    "social-anxiety-disorder": "Social Anxiety Disorder",
    "school-avoidance": "School Avoidance"
}

const AnxietySCAREDResults: React.FC<{screenerScoreMessage: string, questionAnswers: IScreenerQuestionAnswer[], summary: ISummary}> = ({questionAnswers, summary}) => {
    const screenerScores = summary
    const [open, setOpen] = useState(0);

    const handleOpen = (value: number) => {
        setOpen(open === value ? 0 : value);
    };
    const groupedByCondition : {[key: string]: IScreenerQuestionAnswer[]} = _.chain(questionAnswers)
        .groupBy((item: IScreenerQuestionAnswer) => {
            if(item.condition) {
                return item.condition
            } else {
                return "normal"
            }
        })
        .value()

    function renderGroupedQuestionAnswers(title: string | null, index: number, groupedQuestionAnswers: IScreenerQuestionAnswer[]) {
        
        return groupedQuestionAnswers && groupedQuestionAnswers.length > 0 ?
            (<Accordion className={"mb-2"} open={open === index} onClick={() => handleOpen(index)}>
                <AccordionHeader className={"bg-sjAnxiety text-black rounded text-sm px-2"}><span dangerouslySetInnerHTML={{__html: title || ""}}/></AccordionHeader>
                <AccordionBody>
                    <div className={"flex flex-col font-medium"}>
                        {groupedQuestionAnswers.map((questionAnswer, index) => {
                            return <QuestionAnswer key={questionAnswer.questionId} questionAnswer={questionAnswer}
                                                   index={index}/>
                        })}
                    </div>
                </AccordionBody>
            </Accordion>)
            : null;
    }

    function panicDisorderConditionQuestionAnswers(index: number) {
        if("panic-disorder" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition["panic-disorder"]
            const title = CONDITION_MAP["panic-disorder"]
            const styledTitle = `${screenerScores.anxietySCAREDComplete ? (screenerScores?.potentiallyPanicked === true ? `The patient indicates the <span class='text-sjTestPositive'>presence of</span> ${title}` : `The patient indicates <span class='text-sjTestNegative'>no presence of</span> ${title}`) : `${title}` }`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }

    function generalizedAnxietyConditionQuestionAnswers(index: number) {
        if("generalized-anxiety-disorder" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition["generalized-anxiety-disorder"]
            const title = CONDITION_MAP["generalized-anxiety-disorder"]
            const styledTitle = `${screenerScores.anxietySCAREDComplete ? (screenerScores?.potentiallyGeneralizedAnxietyDisorder === true ? `The patient indicates the <span class='text-sjTestPositive'>presence of</span> ${title}` : `The patient indicates <span class='text-sjTestNegative'>no presence of</span> ${title}`) : `${title}` }`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }
    function separationAnxietyConditionQuestionAnswers(index: number) {
        if("separation-anxiety-disorder" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition["separation-anxiety-disorder"]
            const title = CONDITION_MAP["separation-anxiety-disorder"]
            const styledTitle = `${screenerScores.anxietySCAREDComplete ? (screenerScores?.potentiallySeparationAnxietyDisorder === true ? `The patient indicates the <span class='text-sjTestPositive'>presence of</span> ${title}` : `The patient indicates <span class='text-sjTestNegative'>no presence of</span> ${title}`) : `${title}` }`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }
    function socialAnxietyDisorderConditionQuestionAnswers(index: number) {
        if("social-anxiety-disorder" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition["social-anxiety-disorder"]
            const title = CONDITION_MAP["social-anxiety-disorder"]
            const styledTitle = `${screenerScores.anxietySCAREDComplete ? (screenerScores?.potentiallySocialAnxietyDisorder === true ? `The patient indicates the <span class='text-sjTestPositive'>presence of</span> ${title}` : `The patient indicates <span class='text-sjTestNegative'>no presence of</span> ${title}`) : `${title}`}`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }
    function schoolAvoidanceConditionQuestionAnswers(index: number) {
        if("school-avoidance" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition["school-avoidance"]
            const title = CONDITION_MAP["school-avoidance"]
            const styledTitle = `${screenerScores.anxietySCAREDComplete ? (screenerScores?.potentiallySchoolAvoidance === true ? `The patient indicates the <span class='text-sjTestPositive'>presence of</span> ${title}` : `The patient indicates <span class='text-sjTestNegative'>no presence of</span> ${title}`) : `${title}`}`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }

    return questionAnswers && questionAnswers.length > 0
        ? (<>
            { panicDisorderConditionQuestionAnswers(1) }
            { generalizedAnxietyConditionQuestionAnswers(2) }
            { separationAnxietyConditionQuestionAnswers(3) }
            { socialAnxietyDisorderConditionQuestionAnswers(4) }
            { schoolAvoidanceConditionQuestionAnswers(5) }
        </>)
        : null
}

export default AnxietySCAREDResults