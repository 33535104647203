export type Inputs = {
    physicianNotes: string
    symptoms: { selected: string[], other: string }
    problems: { selected: string[], other: string }
    isConsentGiven: string
    presentChiefComplaint: string
    hygiene: string
    grooming: string
    eyeContact: string
    behavior: { selected: string[] }
    motorActivity: { selected: string[] }
    affect: { selected: string[] }
    rate: { selected: string[] }
    fluencyRhythm: { selected: string[] }
    relevance: { selected: string[] }
    thoughtProcess: { selected: string[] }
    delusion: { selected: string[] }
    suicidalThought: { selected: string[] }
    homicidalThought: { selected: string[] }
    mentalStatusOther: string
    religion: string
    religionOther: string
    ethnicity: string
    ethnicityOther: string
    sourceOfIncome: string
    retiredMilitary: string
    language: string
    noOfFamilyMembers: string
    noOfFamilyMembersOther: string
    housingSituationToday: string
    losingHousing: string
    address: { street: string, city: string, state: string, zipCode: string }
    education: string
    currentEmployment: string
    insurance: string
    lastYearFamilyIncomeOther: string
    lastYearFamilyIncome: string
    food: string
    clothing: string
    utilities: string
    childCare: string
    healthCare: string
    phone: string
    familyMembersOther: string
    transportation: string
    social: string
    emotional: string
    pastYearSpentInJail: string
    refugee: string
    physicallyAndEmotionallySafe: string
    afraidOfYourPartner: string
    psychiatricIllness: string
    psychiatricIllnessOther: string
    committedSuicide: string
    committedSuicideOther: string
    substanceAbuse: string
    substanceAbuseOther: string
    historyPresentProblem: string
    diagnosis: {selected: string[], other: string}
    priorCurrentPsychiatristInfo: string
    psychiatristInfo: {name: string, contactNo: string}
    priorCurrentCounselor: string
    counselorInfo: {name: string, contactNo: string}
    psychiatricHospitalization: string
    psychiatricHospitalizationTimeSpan: string
    psychiatricHospitalizationTimeSpanOther: string
    selfInjuriousBehavior: string
    selfInjuriousBehaviorOther: string
    violenceTowardsOthers: string
    violenceTowardsOthersOther: string
    currentMedications: { selected: string[] }
    patientMedicationsHistory: { selected: string[] }
    psychotropicMeds: { description: string }
    priorPhysician: { selected: string }
    comorbidities: {other: string, selected: string[]}
    comorbiditiesOther: string
    allergies: { other: string, selected: string }
    alcohol: string
    alcoholOther: string
    illicitDrugs: string
    illicitDrugsOther: string
    developmentalDelays: string
    developmentalDelaysOther: string
    behaviors: { home: string, social: string }
    educationHistory: string
    currentEducationalSupport: string
    other: string
    hoEducationalSupport: string
    hoEducationSupportOther: string
    fightsSuspensions: string
    fightsSuspensionsOther: string
    legalHistory: string
    legalHistoryOther: string
    strengths: { other: string, selected: string[] }
    limitations: { other: string, selected: string[] }
    participants: string
    socialDeterminants: any
    mentalBehavioral: any
    isConsentGivenCollaborativeCare: string
    recommendationNotes: string
    targetDate: string
    clinicalDuration: number
    documentationTime: number
    proposedMedication: string
    psychiatristAdditionalNotes: string 
    haveYouEverHadALifeThreateningIllness: { 
        selected: string, 
        whatAge: string,
        threateningOfIllness: string,
        specificIllness: string
    },
    wereYouEverInALifeThreateningAccident: {
        selected: string,
        whatAge: string,
        describeAccident: string
        didAnyoneDieSelected: string,
        didAnyoneDieWhatWasTheirRelationshipToYou: string,
        whatPhysicalInjuriesDidYouReceive: string,
        wereYouHospitalizedOvernightSelected: string,
    },
    wasPhysicalForceOrWeaponUsedInRobberyOrMugging: {
        selected: string,
        whatAge: string,
        perpetrators: string,
        physicalForce: string
        didAnyoneDie: string,
        weaponDidAnyOneDie: string,
        who: string,
        WhatInjuriesDidYouReceive: string,
        lifeInDanger: string,
    },
    hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied: {
        selected: string,
        howOldWereYou: string,
        howDidThisPersonDie: string
        relationshipToPersonLost: string,
        personDied: string,
        other: string,
        hadAMiscarriage: string,
        miscarriageAge: string
    },
    atAnyTimeHasAnyoneEverPhysicallyForced: {
        selected: string,
        whatAge: string,
        howManyTimes: string,
        overWhatPeriod: string,
        whoDidThis: string,
        everDoneThisToYou: string,
    },
    otherThanExperiencesMentionedInEarlierQuestions: {
        selected: string,
        whatAge: string,
        howManyTimes: string,
        overWhatPeriod: string,
        whoDidThis: string,
        personAge: string,
        everDoneThisToYou: string,
    },
    whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly: {
        selected: string,
        whatAge: string,
        howManyTimes: string,
        overWhatPeriod: string,
        forceUsedAgainstYou: string,
        wereYouEverInjured: string,
        describe: string,
        whoDidThis: string,
        repeatedlyWhoElse: string,
    },
    asAnAdultHaveYouEverBeenKickedBeatenSlappedAround: {
        selected: string,
        whatAge: string,
        howManyTimes: string,
        overWhatPeriod: string,
        forceUsedAgainstYou: string,
        wereYouEverInjured: string,
        describe: string,
        whoDidThis: string,
        siblingAge: string,
        whoElse: string,
    },
    hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou: {
        selected: string,
        whatAge: string,
        howManyTimes: string,
        overWhatPeriod: string,
        whoDidThis: string,
        siblingAge: string,
        whoElse: string,
    },
    hasAnyoneEverThreatenedYouWithAWeapon: {
        selected: string,
        whatAge: string,
        howManyTimes: string,
        overWhatPeriod: string,
        natureOfThreat: string,
        whoDidThis: string,
        whoElse: string
    },
    haveYourEverBeenPresentWhenAnotherPersonWasKilled: {
        selected: string,
        whatAge: string,
        natureOfThreat: string,
        killedLifeInDanger: string,
    },
    haveYouEverBeenInAnyOtherSituationWhereYouWereSeriouslyInjured: {
        selected: string,
        whatAge: string,
        describe: string,
    },
    haveYouEverBeenInAnyOtherSituationWhereYouWereExtremelyFrightened: {
        selected: string,
        whatAge: string,
        describe: string,
    },
    addendums: {addendum: string, by: string, on: string  | null}[]
    newAddendum: {addendum: string, by: string, on: string | null}[]
}

const keyMappings: { [K in keyof Partial<Inputs>]: string } = {
    limitations: 'limitations',
    strengths: 'strengths',
    behaviors: 'behaviors',
    allergies: 'allergies',
    comorbiditiesOther: 'comorbiditiesOther',
    comorbidities: 'comorbidities',
    priorPhysician: 'priorPhysician',
    psychotropicMeds: 'psychotropicMeds',
    patientMedicationsHistory: 'patientMedicationsHistory',
    currentMedications: 'currentMedications',
    violenceTowardsOthersOther: 'violenceTowardsOthersOther',
    violenceTowardsOthers: 'violenceTowardsOthers',
    selfInjuriousBehaviorOther: 'selfInjuriousBehaviorOther',
    selfInjuriousBehavior: 'selfInjuriousBehavior',
    psychiatricHospitalizationTimeSpanOther: 'psychiatricHospitalizationTimeSpanOther',
    psychiatricHospitalizationTimeSpan: 'psychiatricHospitalizationTimeSpan',
    psychiatricHospitalization: 'psychiatricHospitalization',
    counselorInfo: 'counselorInfo',
    psychiatristInfo: 'psychiatristInfo',
    diagnosis: 'diagnosis',
    substanceAbuseOther: 'substanceAbuseOther',
    substanceAbuse: 'substanceAbuse',
    committedSuicideOther: 'committedSuicideOther',
    committedSuicide: 'committedSuicide',
    psychiatricIllnessOther: 'psychiatricIllnessOther',
    psychiatricIllness: 'psychiatricIllness',
    lastYearFamilyIncomeOther: 'lastYearFamilyIncomeOther',
    lastYearFamilyIncome: 'lastYearFamilyIncome',
    address: 'address',
    noOfFamilyMembersOther: 'noOfFamilyMembersOther',
    noOfFamilyMembers: 'noOfFamilyMembers',
    ethnicityOther: 'ethnicityOther',
    ethnicity: 'ethnicity',
    religionOther: 'religionOther',
    religion: 'religion',
    behavior: 'behavior',
    motorActivity: 'motorActivity',
    affect: 'affect',
    rate: 'rate',
    fluencyRhythm: 'fluencyRhythm',
    relevance: 'relevance',
    thoughtProcess: 'thoughtProcess',
    delusion: 'delusion',
    suicidalThought: 'suicidalThought',
    homicidalThought: 'homicidalThought',
    isConsentGiven: 'isConsentGiven',
    isConsentGivenCollaborativeCare: 'isConsentGivenCollaborativeCare',
    symptoms: 'symptoms',
    problems: 'problems',
    haveYouEverHadALifeThreateningIllness: 'haveYouEverHadALifeThreateningIllness',
    wereYouEverInALifeThreateningAccident: 'wereYouEverInALifeThreateningAccident',
    wasPhysicalForceOrWeaponUsedInRobberyOrMugging: 'wasPhysicalForceOrWeaponUsedInRobberyOrMugging',
    hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied: 'hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied',
    atAnyTimeHasAnyoneEverPhysicallyForced: 'atAnyTimeHasAnyoneEverPhysicallyForced',
    otherThanExperiencesMentionedInEarlierQuestions: 'otherThanExperiencesMentionedInEarlierQuestions',
    whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly',
    asAnAdultHaveYouEverBeenKickedBeatenSlappedAround: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround',
    hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou: 'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou',
    hasAnyoneEverThreatenedYouWithAWeapon: 'hasAnyoneEverThreatenedYouWithAWeapon',
    haveYourEverBeenPresentWhenAnotherPersonWasKilled: 'haveYourEverBeenPresentWhenAnotherPersonWasKilled',
    haveYouEverBeenInAnyOtherSituationWhereYouWereSeriouslyInjured: 'haveYouEverBeenInAnyOtherSituationWhereYouWereSeriouslyInjured',
    haveYouEverBeenInAnyOtherSituationWhereYouWereExtremelyFrightened: 'haveYouEverBeenInAnyOtherSituationWhereYouWereExtremelyFrightened',
    addendums: 'addendums'
}

export const determineKey = (name: string): keyof typeof keyMappings => {
    return keyMappings[name as keyof typeof keyMappings] as keyof typeof keyMappings || name as keyof typeof keyMappings;
}

export type SubQuestions = {
    key: string
    question: string;
    type: 'radio' | 'text',
    placeholder?: string;
    options?: Options[]
}

export interface Options extends Pick<SubQuestions, 'key'> {
    label: string;
    value: string;
    defaultChecked?: boolean;
    subQuestions?: SubQuestions[]
}


export interface FollowUpQuestions extends SubQuestions {
    options: Options[]
    subQuestions?: SubQuestions
}

export interface TraumaAbove18 extends FollowUpQuestions {
    followUp: FollowUpQuestions[]
}