import React, {useMemo} from "react";
import {IUser} from "../../models";
import {MaskPhoneNumber} from "../../components/generic/MaskPhoneNumber";
import {IsActivePill} from "../../components/generic/IsActivePill";
import {UserMasterAction} from "../../components/generic/UserMasterAction";
import {UserRoles} from "../../services/common.service";
import TablePaginationV2 from "../../components/generic/TablePaginationV2";

interface IUserListComponentProps {
    users: IUser[];
    currentPageHandler: Function;
    searchInProgress: boolean | undefined;
    onToggleUser: (userId: string) => void;
    onEditUser: (userId: string) => void;
}

const UserListComponent = (props: IUserListComponentProps) => {

    const {users, onToggleUser, onEditUser, searchInProgress} = props;

    const data = users ? users.map((user) => {
        return {
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
            phone: user.phone || '-',
            email: user.email,
            status: user.isActive,
            role: UserRoles.find(role => role.value === user.role)?.label || '-',
            // location: user?.providerLocations && user?.providerLocations?.length > 0 ? user?.providerLocations[0]?.location?.name : '-',
            action: {
                userId: user.id,
                role: user.role,
                isActive: user.isActive
            }
        }
    }) : [];

    const columns = useMemo(
        () => [
            {header: "User Name", accessorKey: "name"},
            {
                header: "Phone Number", accessorKey: "phone", cell: (props: any) =>
                    <MaskPhoneNumber value={props.getValue()} disabled={true} className={'border-0 bg-transparent'}/>
            },
            {header: "Email ID", accessorKey: "email", cell: (props: any) => <span>{props.getValue()}</span>},
            // {header: "Location", accessorKey: "location"},
            {header: "Role", accessorKey: "role"},
            {header: "Status", accessorKey: "status", cell: (props: any) => <IsActivePill value={props.getValue()}/>},
            {
                header: '',
                accessorKey: 'action',
                cell: (props: any) => <UserMasterAction onEdit={onEditUser} onToggle={onToggleUser}
                                                        value={props.getValue()}/>
            }
        ],
        [onEditUser, onToggleUser]);

    return (
        <div className='mt-4 w-full'>
            <TablePaginationV2 columns={columns} data={data} searchInProgress={searchInProgress}/>
        </div>
    )
}

export default UserListComponent;
