import {UNAUTHORIZED_ACCESS} from "../../actions/counsellor/access.actions";
import {SIGN_OUT_ACCOUNT} from "../../actions/common";

export interface AccessParams {
    unauthorizedForRole?: boolean
}

const initialState: AccessParams = {
    unauthorizedForRole: undefined,
}

const AccessReducer = (state: AccessParams = initialState, action: any): AccessParams => {

    switch (action.type) {

        case UNAUTHORIZED_ACCESS:
            state = {...state, unauthorizedForRole: true}
            return state

        case SIGN_OUT_ACCOUNT:
            state = {...state, ...initialState}
            return state
        default:
            return state;
    }

}

export default AccessReducer