import React, {useEffect, useState} from 'react';
import CreatableSelect from 'react-select/creatable';
import _ from "lodash";

interface CreatableSelectProps {
    field: any;
    list: any;
    disabled?: boolean;
    isMulti?: boolean;
    onChange?: (value: any) => any;
}

const CreatableSelectOption = (props: CreatableSelectProps) => {
    const {
        field,
        list: initialList,
        disabled,
        isMulti = true,
        onChange
    } = props;

    const [list, setList] = useState(initialList);

    useEffect(() => {
        if (!isMulti) {
            const value = field?.value;
            setList(() => {
                const listCopy = _.cloneDeep(initialList);
                if (!listCopy?.find((item: any) => item.value === field?.value) && value) {
                    listCopy.push({
                        label: value,
                        value
                    })
                }
                return listCopy;
            });
        }
    }, [field?.value, isMulti, initialList]);

    return (
        <CreatableSelect
            isMulti={isMulti}
            classNamePrefix="react-select"
            value={
                isMulti
                    ? field.value?.map((item: any) => list.find((x: any) => x.value === item)) || [] // Handle multi-value or empty array
                    : field.value ? [list.find((x: any) => x.value === field.value)] : null // Handle single value or null
            }
            onChange={(selectedValue) => {
                if (selectedValue) {
                    const newValue = isMulti ? selectedValue.map((option: any) => option.value) : selectedValue?.value;

                    if (isMulti) {
                        // Update list for multi-select
                        const updatedList = [...list];
                        selectedValue.forEach((option: any) => {
                            if (!updatedList.some((item: any) => item.value === option.value)) {
                                const newItem = {label: option.value, value: option.value};
                                updatedList.push(newItem);
                            }
                        });
                        // Set the updated list using the state setter
                        setList(updatedList);
                    } else {
                        console.log(list);
                        // Update list for single-select
                        if (!list?.some((item: any) => item.value === selectedValue.value)) {
                            const updatedList = [...list, {label: selectedValue.value, value: selectedValue.value}];
                            // Set the updated list using the state setter
                            setList(updatedList);
                        }
                    }

                    field.onChange(newValue);
                    if (onChange) {
                        onChange(newValue);
                    }
                } else {
                    field.onChange(null);
                    if (onChange) {
                        onChange(null);
                    }
                }
            }}
            isClearable={true}
            isSearchable={true}
            isDisabled={disabled}
            options={list}
            menuPortalTarget={document.body}
            defaultValue={isMulti ? [] : null}
        />
    );
};

export default CreatableSelectOption;
