import {
    ACCOUNT_IF_EXISTS_FAILED,
    ACCOUNT_IF_EXISTS_SUCCESS,
    CLEAR_DOES_EMAIL_EXISTS,
    CLEAR_ZOOM_JWT_TOKEN,
    FAILED_GENERATING_ZOOM_TOKEN,
    GENERATED_ZOOM_TOKEN,
    IS_ZOOM_SESSION_ACTIVE,
} from "../../actions/counsellor/account.action";
import {SIGN_OUT_ACCOUNT} from "../../actions/common";

export interface PractitionerAccountParams {
    id?: string
    token?: string
    email?: string
    firstName?: string
    lastName?: string
    gender?: string
    role?: string
    doesEmailExists?: boolean
    checkIfEmailExistsError?: string
    zoomJwt?: string
    zoomJwtError?: string
    isZoomSessionActive?: boolean
    createdAt?: string
    updatedAt?: string
}

const initialState: PractitionerAccountParams = {
    id: undefined,
    token: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    gender: undefined,
    role: undefined,
    doesEmailExists: undefined,
    checkIfEmailExistsError: undefined,
    zoomJwt: undefined,
    zoomJwtError: undefined,
    isZoomSessionActive: undefined,
    createdAt: undefined,
    updatedAt: undefined
}

const AccountReducer = (state: PractitionerAccountParams = initialState, action: any): PractitionerAccountParams => {

    switch (action.type) {
        case SIGN_OUT_ACCOUNT:
            state = {...state, ...initialState}
            return state
        case ACCOUNT_IF_EXISTS_SUCCESS:
            state = {...state, ...action.payload}
            return state
        case ACCOUNT_IF_EXISTS_FAILED:
            state = {...state, checkIfEmailExistsError: action.payload.error}
            return state
        case CLEAR_DOES_EMAIL_EXISTS:
            state = { ...state, doesEmailExists: undefined, checkIfEmailExistsError: undefined }
            return state
        case GENERATED_ZOOM_TOKEN:
            state = { ...state, zoomJwt: action.payload.token }
            return state
        case FAILED_GENERATING_ZOOM_TOKEN:
            state = { ...state, zoomJwtError: action.payload.error }
            return state
        case CLEAR_ZOOM_JWT_TOKEN:
            state = { ...state, zoomJwt: undefined } 
            return state
        case IS_ZOOM_SESSION_ACTIVE:
            state = {...state, isZoomSessionActive: action.payload.canActivate}
            return state
        default:
            return state;
    }

}

export default AccountReducer