import { useEffect } from "react";
import { useForm } from "react-hook-form"
import { AiOutlineClose } from "react-icons/ai"
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"
import PhoneNumberVerificationComponent from "../../components/locations/PhoneNumberVerificationComponent";
import { fetchLocation, fetchLocations } from "../../store/actions/physician/admin-operations.action";
import withLocationForm from "./withLocationForm";

const LocationButtonComponent: React.FC = () => {
    return <input type={"submit"} value={"Save Changes"} className="inline-block bg-sjOrange text-white rounded shadow py-2 px-5 text-sm" />
}

const LocationEditComponent: React.FC = () => {

    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        if(params && params.locationId) {
            dispatch(fetchLocation(params.locationId))
        }
    }, [params])

    return (
        <>
            <div className="flex flex-row">
                <div className="grow"><span className="text-2xl font-bold text-[#242731]">Edit Location</span></div>
                <div
                    onClick={() => navigate('/locations')}
                    className="cursor-pointer grow-0"
                >
                    <AiOutlineClose
                        className="text-gray-500 hover:text-gray-700"
                        style={{ width: '25px', height: '25px' }}
                    />
                </div>
            </div>
            <div className="flex pt-5"><span className="text-base text-[#575F6E] font-light">Make changes to your branch location.</span></div>
        </>
    )
}
export default withLocationForm(LocationEditComponent, LocationButtonComponent, PhoneNumberVerificationComponent)
