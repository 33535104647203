import {AnyAction} from "redux";
import {IPhysicianAccount, ISendEmail, LocationIndexItem} from "../../../models";

export const ACCOUNT_SIGN_IN_REQUEST = "ACCOUNT_SIGN_IN_REQUEST"
export const ACCOUNT_SIGN_IN_IN_PROGRESS = "ACCOUNT_SIGN_IN_IN_PROGRESS"
export const ACCOUNT_SIGN_IN_SUCCESS = "ACCOUNT_SIGN_IN_SUCCESS"
export const ACCOUNT_SIGN_IN_FAILED = "ACCOUNT_SIGN_IN_FAILED"

export const ACCOUNT_SEND_EMAIL_REQUEST = "ACCOUNT_SEND_EMAIL_REQUEST"
export const ACCOUNT_SEND_EMAIL_PROGRESS = "ACCOUNT_SEND_EMAIL_PROGRESS"
export const ACCOUNT_SEND_EMAIL_SUCCESS = "ACCOUNT_SEND_EMAIL_SUCCESS"
export const ACCOUNT_SEND_EMAIL_FAILED = "ACCOUNT_SEND_EMAIL_FAILED"

export const ACCOUNT_VALIDATE_CODE_REQUEST = "ACCOUNT_VALIDATE_CODE_REQUEST"
export const ACCOUNT_VALIDATE_CODE_PROGRESS = "ACCOUNT_VALIDATE_CODE_PROGRESS"
export const ACCOUNT_VALIDATE_CODE_SUCCESS = "ACCOUNT_VALIDATE_CODE_SUCCESS"
export const ACCOUNT_VALIDATE_CODE_FAILED = "ACCOUNT_VALIDATE_CODE_FAILED"

export const ACCOUNT_RESET_PASSWORD_REQUEST = "ACCOUNT_RESET_PASSWORD_REQUEST"
export const ACCOUNT_RESET_PASSWORD_PROGRESS = "ACCOUNT_RESET_PASSWORD_PROGRESS"
export const ACCOUNT_RESET_PASSWORD_SUCCESS = "ACCOUNT_RESET_PASSWORD_SUCCESS"
export const ACCOUNT_RESET_PASSWORD_FAILED = "ACCOUNT_RESET_PASSWORD_FAILED"

export const CHANGE_PASSWORD_FROM_PROFILE_REQUEST = "CHANGE_PASSWORD_FROM_PROFILE_REQUEST"
export const CHANGE_PASSWORD_FROM_PROFILE_PROGRESS = "CHANGE_PASSWORD_FROM_PROFILE_PROGRESS"
export const CHANGE_PASSWORD_FROM_PROFILE_SUCCESS = "CHANGE_PASSWORD_FROM_PROFILE_SUCCESS"
export const CHANGE_PASSWORD_FROM_PROFILE_FAILED = "CHANGE_PASSWORD_FROM_PROFILE_FAILED"


export const CHANGE_DEFAULT_PASSWORD_REQUEST = "CHANGE_DEFAULT_PASSWORD_REQUEST"
export const CHANGE_DEFAULT_PASSWORD_PROGRESS = "CHANGE_DEFAULT_PASSWORD_PROGRESS"
export const CHANGE_DEFAULT_PASSWORD_SUCCESS = "CHANGE_DEFAULT_PASSWORD_SUCCESS"
export const CHANGE_DEFAULT_PASSWORD_FAILED = "CHANGE_DEFAULT_PASSWORD_FAILED"



export const GENERATE_QR_CODE = "GENERATE_QR_CODE"
export const GENERATE_QR_CODE_IN_PROGRESS = "GENERATE_QR_CODE_IN_PROGRESS"
export const GENERATE_QR_CODE_SUCCESS = "GENERATE_QR_CODE_SUCCESS"
export const GENERATE_QR_CODE_FAILED = "GENERATE_QR_CODE_FAILED"

export const UPDATE_PHONE_NUMBER = "UPDATE_PHONE_NUMBER"
export const UPDATE_PHONE_NUMBER_IN_PROGRESS = "UPDATE_PHONE_NUMBER_IN_PROGRESS"
export const UPDATE_PHONE_NUMBER_SUCCESS = "UPDATE_PHONE_NUMBER_SUCCESS"
export const UPDATE_PHONE_NUMBER_FAILED = "UPDATE_PHONE_NUMBER_FAILED"

export const VERIFY_OTP= "VERIFY_OTP"
export const VERIFY_OTP_IN_PROGRESS = "VERIFY_OTP_IN_PROGRESS"
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS"
export const VERIFY_OTP_FAILED = "VERIFY_OTP_FAILED"

export const SEND_OTP = "SEND_OTP"
export const SEND_OTP_IN_PROGRESS = "SEND_OTP_IN_PROGRESS"
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS"
export const SEND_OTP_FAILED = "SEND_OTP_FAILED"

export const CLEAR_ERROR_MESSAGES = "CLEAR_ERROR_MESSAGES"
export const CLEAR_IS_EMAIL_SENT = "CLEAR_IS_EMAIL_SENT"

export const SET_PHYSICIAN_DEFAULT_LOCATION = "SET_PHYSICIAN_DEFAULT_LOCATION"

export const SET_IS_TWO_FACTOR_ENABLED = "SET_IS_TWO_FACTOR_ENABLED"
export const CLEAR_IS_TWO_FACTOR_ENABLED = "CLEAR_IS_TWO_FACTOR_ENABLED"

export const login = (payload: {email: string, password: string}) => {
    return {type: ACCOUNT_SIGN_IN_REQUEST, payload}
}

export const signInInProgress = () => {
    return {type: ACCOUNT_SIGN_IN_IN_PROGRESS}
}
export const storeAccountDetails = (payload: IPhysicianAccount) => {
    return {type: ACCOUNT_SIGN_IN_SUCCESS, payload}
}

export const signInFailed = (payload: {error: string}) => {
    return {type: ACCOUNT_SIGN_IN_FAILED, payload}
}

export const sendEmail = (payload: {email: string}) => {
    return {type: ACCOUNT_SEND_EMAIL_REQUEST, payload}
}
export const sendEmailProgress = () => {
    return {type: ACCOUNT_SEND_EMAIL_PROGRESS}
}
export const sendMailSuccess = (payload: ISendEmail) => {
    return {type: ACCOUNT_SEND_EMAIL_SUCCESS, payload}
}
export const sendMailFailed = (payload: {error: string}) => {
    return {type: ACCOUNT_SEND_EMAIL_FAILED, payload}
}

export const validateCode = (payload: {email: string, code: string}) => {
    return {type: ACCOUNT_VALIDATE_CODE_REQUEST, payload}
}
export const validateCodeProgress = () => {
    return {type: ACCOUNT_VALIDATE_CODE_PROGRESS}
}
export const validateCodeSuccess = (payload: ISendEmail) => {
    return {type: ACCOUNT_VALIDATE_CODE_SUCCESS, payload}
}
export const validateCodeFailed = (payload: {error: string}) => {
    return {type: ACCOUNT_VALIDATE_CODE_FAILED, payload}
}

export const resetPassword = (payload: {email: string, code: string, providerId: string, password: string}) => {
    return {type: ACCOUNT_RESET_PASSWORD_REQUEST, payload}
}
export const resetPasswordProgress = () => {
    return {type: ACCOUNT_RESET_PASSWORD_PROGRESS}
}
export const resetPasswordSuccess = (payload: IPhysicianAccount) => {
    return {type: ACCOUNT_RESET_PASSWORD_SUCCESS, payload}
}
export const resetPasswordFailed = (payload: {error: string}) => {
    return {type: ACCOUNT_RESET_PASSWORD_FAILED, payload}
}

export const clearErrorMessages = () => {
    return {type: CLEAR_ERROR_MESSAGES}
}
export const clearCodeSent = () => {
    return {type: CLEAR_IS_EMAIL_SENT}
}

export const changePasswordFromProfile = (payload: {email:string, currentPassword: string, password: string}) => {
    return {type: CHANGE_PASSWORD_FROM_PROFILE_REQUEST, payload}
}

export const changePasswordFromProfileProgress = () => {
    return {type: CHANGE_PASSWORD_FROM_PROFILE_PROGRESS}
}
export const changePasswordFromProfileSuccess = (payload: IPhysicianAccount) => {
    return {type: CHANGE_PASSWORD_FROM_PROFILE_SUCCESS, payload}
}
export const changePasswordFromProfileFailed = (payload: {error: string}) => {
    return {type: CHANGE_PASSWORD_FROM_PROFILE_FAILED, payload}
}


export const changeDefaultPassword = (payload: {email:string, currentPassword: string, password: string}) => {
    return {type: CHANGE_DEFAULT_PASSWORD_REQUEST, payload}
}

export const changeDefaultPasswordProgress = () => {
    return {type: CHANGE_DEFAULT_PASSWORD_PROGRESS}
}
export const changeDefaultPasswordSuccess = (payload: IPhysicianAccount) => {
    return {type: CHANGE_DEFAULT_PASSWORD_SUCCESS, payload}
}
export const changeDefaultPasswordFailed = (payload: {error: string}) => {
    return {type: CHANGE_DEFAULT_PASSWORD_FAILED, payload}
}

export const generateQRCode = (payload: { slug: string }): AnyAction => {
    return { type: GENERATE_QR_CODE, payload }
}
export const generateQRCodeInProgress = (): AnyAction => {
    return { type: GENERATE_QR_CODE_IN_PROGRESS }
}
export const generatingQRCodeFailed = (payload: { error: string }): AnyAction => {
    return { type: GENERATE_QR_CODE_FAILED, payload }
}
export const generatedQRCode = (url: string ): AnyAction => {
    return { type: GENERATE_QR_CODE_SUCCESS, payload: { url } }
}

export const updatePhoneNumber = (payload: { phoneNumber: string }): AnyAction => {
    return { type: UPDATE_PHONE_NUMBER, payload }
}

export const updatePhoneNumberInProgress = (): AnyAction => {
    return { type: UPDATE_PHONE_NUMBER_IN_PROGRESS }
}

export const updatedPhoneNumber = (payload: { phoneNumber: string }): AnyAction => {
    return { type: UPDATE_PHONE_NUMBER_SUCCESS, payload }
}

export const updatePhoneNumberFailed = (payload: { error: string }): AnyAction => {
    return { type: UPDATE_PHONE_NUMBER_FAILED, payload }
}

export const verifyOTP = (payload: { otp: string, email: string, phoneNumber: string }): AnyAction => {
    return { type: VERIFY_OTP, payload }
}
export const verifyOTPInProgress = (): AnyAction => {
    return { type: VERIFY_OTP_IN_PROGRESS }
}
export const verificationOTPFailed = (payload: { error: string }) => {
    return { type: VERIFY_OTP_FAILED, payload }
}

export const sendOTP = (payload: { email: string }): AnyAction => {
    return { type: SEND_OTP, payload }
}
export const sendOTPInProgress = (): AnyAction => {
    return { type: SEND_OTP_IN_PROGRESS }
}
export const sendOTPSuccess = (payload: { isOTPSent: boolean }): AnyAction => {
    return { type: SEND_OTP_SUCCESS, payload }
}
export const sendOTPFailed = (payload: { error: string }): AnyAction => {
    return { type: SEND_OTP_FAILED, payload }
}

export const setPhysicianDefaultLocation = (defaultLocation: LocationIndexItem | null): AnyAction => {
    return {type: SET_PHYSICIAN_DEFAULT_LOCATION, payload: {defaultLocation: defaultLocation}}
}

export const setIsTwoFactorEnabled = (payload: IPhysicianAccount): AnyAction => {
    return { type: SET_IS_TWO_FACTOR_ENABLED, payload }
}
export const clearIsTwoFactorEnabled = (): AnyAction => {
    return {type: CLEAR_IS_TWO_FACTOR_ENABLED}
}
