import React from "react";
import {Link} from "react-router-dom";
import {DateTime} from "luxon";
import {AppointmentStatus} from "../../models";


function renderButton(value: { link: string; editLink: string; scheduledDate: string; status: string; canSchedule: boolean; canEdit: boolean }) {
  const scheduledDate = DateTime.fromISO(value.scheduledDate).toLocaleString(DateTime.DATE_MED)
  const scheduledTimeZone = DateTime.fromISO(value.scheduledDate).toFormat('hh:mm a ZZZZ')

  function renderEditScheduleButton() {
    return <Link to={`${value.editLink}`}>
      <span
          className="inline-block bg-sjOrange text-white rounded shadow py-2 px-5 text-sm">
        Edit <br/> {scheduledDate} <br/> {scheduledTimeZone}
      </span>
    </Link>;
  }

  function renderCreateScheduleButton() {
    return <Link to={`${value.link}`}
                 className="inline-block bg-orange-500 text-white rounded shadow py-2 px-5 text-sm">
      <span>
        Schedule
      </span>
    </Link>;
  }

  function renderInProgressMessage() {
    return <span
        className="inline-block bg-sjTestNegative text-white rounded shadow py-2 px-5 text-sm"> In progress <br/> {scheduledDate}
      <br/> {scheduledTimeZone} </span>
  }

  const renderButton = () => {
    if (value.canSchedule) {
      return renderCreateScheduleButton()
    }

    if (value.canEdit) {
      return renderEditScheduleButton()
    }

    if (AppointmentStatus.inProgress === value.status) {
      return renderInProgressMessage()
    }
    return <>{value.status}</>
  }

  return renderButton()
}

export const ScheduledButton: React.FC<{ value: {link: string, editLink: string, scheduledDate: string, status: string, canSchedule: boolean, canEdit: boolean} }> = ({ value }) => {
  return renderButton(value);
}

export const ScheduledButtonV2: React.FC<{getValue: any}> = ({getValue}) => {
  const value = getValue()

  return renderButton(value)
}
