import { NotesStatus, Roles } from "../../models";

function isPsychiatrist(role: string | undefined): boolean {
    return role === Roles.Psychiatrist
}

function isCounsellor(role: string | undefined): boolean {
    return role && [Roles.Counsellor, Roles.Admin].includes(role as Roles) ? true : false
}

function isCareCoordinator(role: string | undefined): boolean {
    return role === Roles.Care_Coordinator
}

function isPhysician(role: string | undefined): boolean {
    return role === Roles.Physician
}

function canEditAndMakeCallsToPatient(role: string| undefined) : boolean {
 return isCounsellor(role) || isCareCoordinator(role) || isPhysician(role)
}

function canCounsellorSubmit(notesStatus: NotesStatus | undefined, role: string | undefined, isZoomSessionActive: boolean | undefined): boolean {
    return isCounsellor(role) && !isZoomSessionActive && notesStatus && notesStatus === NotesStatus.PENDING_SUBMIT_TO_PSYCH ? true : false
}

function canEditCounsellorNotes(notesStatus: NotesStatus | undefined, role: string | undefined): boolean {
    if(notesStatus === NotesStatus.LOCKED || isPsychiatrist(role) || isPhysician(role)) return false
    if(isCounsellor(role) && notesStatus === NotesStatus.PENDING_PSYCH_REVIEW) return false
    return true
}

function canAddPsychiatristNotes(notesStatus: NotesStatus | undefined, role: string | undefined): boolean {
    return isPsychiatrist(role) && notesStatus && notesStatus === NotesStatus.PENDING_PSYCH_REVIEW ? true : false
}

function canAccessPsychiatristNotes(notesStatus: NotesStatus | undefined, isZoomSessionActive: boolean | undefined): boolean {
    return !isZoomSessionActive && notesStatus && notesStatus === NotesStatus.LOCKED ? true : false
}

function isNotesLocked(notesStatus?: NotesStatus): boolean {
    return notesStatus ? notesStatus === NotesStatus.LOCKED : false
}

function isCounsellorNotesLocked(notesStatus: NotesStatus | undefined, role: string | undefined): boolean {
    return isCounsellor(role) && notesStatus ? [NotesStatus.PENDING_PSYCH_REVIEW, NotesStatus.LOCKED].includes(notesStatus) : false
}

export {
    isPsychiatrist,
    isCounsellor,
    isPhysician,
    isCareCoordinator,
    canCounsellorSubmit,
    canEditCounsellorNotes,
    canAddPsychiatristNotes,
    canAccessPsychiatristNotes,
    isNotesLocked,
    isCounsellorNotesLocked,
    canEditAndMakeCallsToPatient
}
