import {classNames} from "../../shared/Utils";
import React from "react";

export const IsActivePill: React.FC<{value: boolean}> = ({ value }) => {
    const status = value ? 'Active' : "Inactive"

    return (
        <span
            className={classNames(
                "px-3 py-1 leading-wide font-semibold text-xs rounded-full shadow-sm tracking-wide",
                value ? "bg-sjLightGrayishBlue text-sjLink" : 'bg-sjLightGray text-sjGray'
            )}
        >
      {status}
    </span>
    );
}