import React, {useEffect, useState} from "react";
import {NavLink, Outlet, useNavigate, useParams} from "react-router-dom";
import {RoutePaths} from "../../../shared/Utils";
import { IPotentialReferralPatientBasicDetails } from "../../../models";

const PATIENT_SUB_MENU_LIST = [
    {
        key: "patient_information",
        title: "Patient Information",
        link: RoutePaths.careCoordinator.potentialPatients.patientDetails.patientInformation
    },
    {
        key: 'screeners',
        title: 'Screeners',
        link: RoutePaths.careCoordinator.potentialPatients.patientDetails.screenerSummary
    },
    // {
    //     key: 'comments',
    //     title: 'Comments',
    //     link: '#'
    // }
]

const PatientDetails: React.FC<{patient: IPotentialReferralPatientBasicDetails}> = ({patient}) => {
    return (
        <div className={"patient-details-screen"}>
            <div className="patient-sub-menu-wrapper">
                <div className="patient-sub-menu-navigation">
                    <div className="patient-sub-menu-list">
                        {PATIENT_SUB_MENU_LIST.map((item) => {
                            return (
                                <NavLink to={`${item.link}?practiceId=${patient.screenerUserPractices[0].providerPractice.id}`}
                                      className={`patient-sub-menu-item`}
                                      key={item?.key}>
                                    <span className="patient-sub-menu-item-title">{item.title}</span>
                                </NavLink>
                            )
                        })}
                    </div>
                </div>
                <div className="patient-sub-menu-route-outlet">
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}

export default PatientDetails
