import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

type Props = {
    scheduledTime: string
    setHours: (hours: number) => void
    setMinutes: (minutes: number) => void
    setSeconds: (seconds: number) => void
}

export const AppointmentTimer: React.FC<Props> = ({ scheduledTime, setHours, setMinutes, setSeconds }) => {
    const [pendingTime, setPendingTime] = useState(calculatePendingTime());

    useEffect(() => {
        const intervalId = setInterval(() => {
          setPendingTime(calculatePendingTime());
        }, 1000);
    
        return () => clearInterval(intervalId);
      }, []);

      function calculatePendingTime() {
        const now = DateTime.now().setZone('America/New_York')
        const scheduledDateTime = DateTime.fromISO(scheduledTime).setZone('America/New_York')
        const timeDifference = scheduledDateTime.diff(now)

        const hours = Math.floor(timeDifference.as('hours'));
        const minutes = Math.floor(timeDifference.as('minutes')) % 60;
        const seconds = Math.floor(timeDifference.as('seconds')) % 60;
    
        setHours(hours)
        setMinutes(minutes)
        setSeconds(seconds)
        return `${minutes} minutes ${seconds} seconds`;
      }

    return (
        <></>
    );
}
