import { batch } from 'react-redux';
import { useDispatch } from 'react-redux';
import { NotesStatus, NotesStatusRequest, SessionTypes } from '../models';
import { updateNotesStatus } from '../store/actions/counsellor/prospects.action';

export const useStatusChange = (sessionType: SessionTypes) => {
  const dispatch = useDispatch();

    const handleStatusChange = (status: NotesStatus, documentId: string, patientId: string, screeners?: string[], sourcePatientId?: string, practiceId?: string) => {
    if (documentId && patientId) {
        dispatch(
          updateNotesStatus({
            notesId: documentId,
            patientProspectId: patientId,
            sessionType: sessionType,
            status: status,
            screeners: screeners,
            sourcePatientId: sourcePatientId,
            practiceId: practiceId
          })
        )
    }
  };

  return handleStatusChange;
};
