import React, {useEffect, useState} from "react";
import {IPatientIntakeNotes, IProspect} from "../../models";
import {Input} from "@material-tailwind/react";
import {useDispatch, useSelector} from "react-redux";
import {intakeFormSave} from "../../store/actions/counsellor/prospects.action";
import {StateParams} from "../../store/reducers";

export const ProspectUserInfo: React.FC<{ prospect: IProspect }> = ({prospect}) => {
  const dispatch = useDispatch();
  const [prospectData, setProspectData] = useState<IProspect>(prospect);
  const {sourceRefData} = useSelector((state: StateParams) => state.prospects);
  const [sourceRef, setSourceRef] = useState(sourceRefData);
  useEffect(() => {
    setProspectData(prospect)
  }, [prospect.id]);

  useEffect(() => {
    setSourceRef(sourceRefData);
  }, [sourceRefData])

  const tiBlurHandler = (evt: any) => {
    const {id, value} = evt.target;
    const intakeFormData: any = {} as any;
    intakeFormData.patientIntakeNotes = {} as IPatientIntakeNotes;
    intakeFormData.patientIntakeNotes = {
      [id]: value?.trim()
    }
    dispatch(intakeFormSave(prospect.id, intakeFormData));
  }

  const emergencyContactHandler = (evt: any) => {
    const {id, value} = evt.target;
    dispatchCall('emergencyInfo', id, value);
  }

  const insuranceBlurHandler = (evt: any) => {
    const {id, value} = evt.target;
    dispatchCall('insuranceInfo', id, value);
  }

  const dispatchCall = (type: string, id: string, value: string) => {
    const intakeFormData: any = {} as any;
    intakeFormData.patientIntakeNotes = {} as IPatientIntakeNotes;
    intakeFormData.patientIntakeNotes[type] = {
      [id]: value?.trim()
    }
    dispatch(intakeFormSave(prospect.id, intakeFormData));
  }

  const convertFirstCharUpper = (str: string) => {
    return str?.substring(0, 1).toUpperCase() + str?.substring(1, str.length);
  }

  return (
    <form>
      <div className="mt-10">
        <label className="sub-header">USER INFORMATION</label>
      </div>
      <section id={"prospect-meeting-link"} style={{backgroundColor: '#FFFFFF'}}
               className={"w-full intake-section mt-4 p-10  min-h-[25vh] rounded-lg shadow-lg"}>
        <div className="mb-4">
          <label className="text-md text-sjOrange">Patient Information</label>
          <div className="w-full flex flex-wrap">
            <div className="form-group basis-1/2 pr-10">
              <label className="form-label inline-block mb-2 text-gray-700">First name</label>
              <Input type="text" className="intake-input form-control" id="pFirstname" disabled={true}
                     aria-describedby="pFirstnameHelp" placeholder="Patient first name"
                     value={prospectData?.firstName}/>
            </div>
            <div className="form-group basis-1/2">
              <label className="form-label inline-block mb-2 text-gray-700">Last name</label>
              <Input type="text" className="intake-input form-control" disabled={true}
                     aria-describedby="pLastnameHelp" placeholder="Patient last name" value={prospectData?.lastName}/>
            </div>
            <div className="form-group basis-1/2 pr-10 mt-3">
              <label className="form-label inline-block mb-2 text-gray-700">Mobile number</label>
              <Input type="text" className="intake-input form-control" disabled={true}
                     aria-describedby="pMobileNumberHelp" placeholder="Patient mobile number"
                     value={prospectData?.phoneNumber}/>
            </div>
            <div className="form-group basis-1/2 pr-10 mt-3">
              <label className="form-label inline-block mb-2 text-gray-700">Gender</label>
              <Input type="text" className="intake-input form-control" id={"gender"} disabled={true}
                     aria-describedby="pMobileNumberHelp" placeholder="Patient mobile number"
                     onBlur={tiBlurHandler}
                     defaultValue={convertFirstCharUpper(sourceRef?.additionalInfo?.gender || prospectData?.gender)}/>
            </div>
          </div>
        </div>

        <div className="mt-3">
          <label className="text-md text-sjOrange">Caregiver / Emergency information</label>
          <div className="w-full flex mt-2 flex-wrap">
            <div className="form-group basis-1/2 pr-10">
              <label className="form-label inline-block text-gray-700">First name</label>
              <Input type="text" className="intake-input form-control" id="firstName"
                     aria-describedby="eFirstnameHelp" placeholder="Patient first name"
                     onBlur={emergencyContactHandler}
                     defaultValue={sourceRef?.additionalInfo?.emergencyInfo?.firstName}/>
            </div>
            <div className="form-group basis-1/2">
              <label className="form-label inline-block text-gray-700">Last name</label>
              <Input type="text" className="intake-input form-control" id="lastName"
                     aria-describedby="eLastnameHelp" placeholder="Patient last name" onBlur={emergencyContactHandler}
                     defaultValue={sourceRef?.additionalInfo?.emergencyInfo?.lastName}/>
            </div>
            <div className="form-group basis-1/2 pr-10 mt-3">
              <label className="form-label inline-block text-gray-700">Mobile number</label>
              <Input type="text" className="intake-input form-control" id="phoneNumber"
                     onBlur={emergencyContactHandler}
                     aria-describedby="eMobileNumberHelp" placeholder="Emergency contact mobile number"
                     defaultValue={sourceRef?.additionalInfo?.emergencyInfo?.phoneNumber}/>
            </div>
            <div className="form-group basis-1/2 mt-3">
              <label className="form-label inline-block text-gray-700">Email address</label>
              <Input type="email" className="intake-input form-control" id="email" 
                     aria-describedby="emailHelp" placeholder="Email address" onBlur={emergencyContactHandler}
                     defaultValue={sourceRef?.additionalInfo?.emergencyInfo?.email}/>
            </div>
          </div>
        </div>

        <div className="mt-3">
          <label className="text-md text-sjOrange">Insurance Information</label>
          <div className="w-full flex mt-2 flex-wrap">
            <div className="form-group basis-1/2 pr-10">
              <label className="form-label inline-block text-gray-700">Insurance company</label>
              <Input type="text" className="intake-input form-control" id="insuranceCompany"
                     onBlur={insuranceBlurHandler}
                     aria-describedby="iCompanyHelp" placeholder="insurance company name"
                     defaultValue={sourceRef?.additionalInfo?.insuranceInfo?.insuranceCompany}/>
            </div>
            <div className="form-group basis-1/2">
              <label className="form-label inline-block text-gray-700">Type of plan</label>
              <Input type="text" className="intake-input form-control" id="typeOfPlan" onBlur={insuranceBlurHandler}
                     aria-describedby="tPlanHelp" placeholder="plan type"
                     defaultValue={sourceRef?.additionalInfo?.insuranceInfo?.typeOfPlan}/>
            </div>
            <div className="form-group basis-1/2 pr-10 mt-3">
              <label className="form-label inline-block text-gray-700">Insurance ID</label>
              <Input type="text" className="intake-input form-control" id="insuranceId" onBlur={insuranceBlurHandler}
                     aria-describedby="iIdHelp" placeholder="insurance id"
                     defaultValue={sourceRef?.additionalInfo?.insuranceInfo?.insuranceId}/>
            </div>
            <div className="form-group basis-1/2 mt-3">
              <label className="form-label inline-block text-gray-700">Name of the primary
                insured</label>
              <Input type="text" className="intake-input form-control" id="primaryHolderName"
                     onBlur={insuranceBlurHandler}
                     aria-describedby="iNameHelp" placeholder="Name of the primary insured"
                     defaultValue={sourceRef?.additionalInfo?.insuranceInfo?.primaryHolderName}/>
            </div>
          </div>
        </div>
      </section>
    </form>
  )
}