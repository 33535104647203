import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import VoipPhoneNumber from "../../../components/calls/VoipPhoneNumber";
import { IPatientInCall, IPotentialReferralPatientBasicDetails, PatientCallStatus } from "../../../models";
import { CALL_STATUS_DISPLAY_MAP } from "../../../components/generic/CallStatus";
import { updatePatientInfo } from "../../../store/actions/counsellor/patients.action";
import EditablePhoneNumber from "../../../components/calls/EditablePhoneNumber";
import CallPatientComponent from "../../../components/calls/CallPatientComponent";
import { useParams } from "react-router-dom";

const CoordinatorPatientProfile: React.FC<{patient: IPotentialReferralPatientBasicDetails}> = ({patient}) => {
    const dispatch = useDispatch();
    const practice = patient.screenerUserPractices[0].providerPractice;
    const [selectedStatus, setSelectedStatus] = useState<PatientCallStatus>(patient.callStatus)
    const potentialPatient: IPatientInCall = {
        id: patient.id,
        sourcePatientId: patient.id,
        referredByPracticeId: patient.screenerUserPractices[0].practiceId,
        firstName: patient.firstName,
        lastName: patient.lastName,
        dob: patient.dob as unknown as Date,
        phoneNumber: patient.contactPhoneNumber,
    }

    const sourcePatientId = useParams().patientId || patient.id;

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const status = event.target.value as unknown as PatientCallStatus
        setSelectedStatus(status);
        dispatch(updatePatientInfo(patient.id, patient.screenerUserPractices[0].practiceId, { callStatus: status }))
      };

    return (
        <section className={"patient-details-screen"}>
            <div className="patient-basic-details-card flex flex-row items-center justify-between">
                <div className="flex items-center">
                    <div className="patient-basic-details-meta">
                        {/* <div className="patient-name">{patient.firstName} {patient.lastName}</div> */}
                        <div className="flex mb-[17px]">
                            <div className="patient-practice self-center">
                                {practice.name}
                            </div>
                            <div className="ml-4">
                                <select value={selectedStatus as unknown as keyof PatientCallStatus} onChange={handleChange}
                                    className='w-[153px] px-[8px] py-[4px] border-none rounded-[3px] h-[30px] bg-sjLightOrange text-sjOrange'>
                                    {(Object.keys(CALL_STATUS_DISPLAY_MAP) as (keyof PatientCallStatus)[]).map((status) => (
                                        <option key={status} value={status}>
                                            {CALL_STATUS_DISPLAY_MAP[status].description}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="patient-phone-number"><EditablePhoneNumber patient={potentialPatient}/></div>
                    </div>
                </div>
                <div className="patient-basic-details-actions">
                    <CallPatientComponent patient={potentialPatient} sourcePatientId={sourcePatientId}/>
                </div>
            </div>
        </section>
    )
}

export default CoordinatorPatientProfile
