import { useDispatch, useSelector } from "react-redux"
import { IProspect, Roles, VisitTypeVerbiage } from "../../models"
import { formatISODate } from "../../shared/DateUtils"
import { Dropdown } from "../generic/Dropdown"
import { fetchScreeningSummary } from "../../store/actions/counsellor/patients.action"
import { StateParams } from "../../store/reducers"
import { fetchProspectWithSummary, fetchProspectWithSummaryBySessionId } from "../../store/actions/counsellor/prospects.action"
import { useLocation } from "react-router-dom"
import { fetchPatientLatestScreeningSummary } from "../../store/actions/care-coordinator/patients.action"
import { useFlags } from "flagsmith/react"
import { DropdownWithGroupHeading } from "../generic/DropdownWithGroupHeading"

interface Props {
    patientWithSummary: IProspect
    setSelectedIndex: (value: number) => void
    classNames?: string
}
export const DateOfVisit = ({ patientWithSummary, setSelectedIndex, classNames = 'w-1/6' }: Props) => {
    const dispatch = useDispatch()
    const { role } = useSelector((state: StateParams) => state.practitionerAccount)
    const {
        patientBasicInformation: patient,
    } = useSelector((state: StateParams) => state.coordinatorPatients);
    const paths = useLocation().pathname.split('/')
    const canView = useFlags(['can_view_historical_screeners'])?.can_view_historical_screeners?.enabled
    const canViewVisitType = useFlags(['cognitive_screening'])?.cognitive_screening?.enabled

    if(!canView) {
        return null
    }

    if (!patientWithSummary || !patientWithSummary.screenedDates || patientWithSummary.screenedDates.length <= 1) {
        return null
    }

    const screenedDates = patientWithSummary.screenedDates.map(x => ({
        value: x.sessionId,
        label: formatISODate(x.screenedOn),
        description: VisitTypeVerbiage[x.visitType]
    }))

    const fetchPatientSummary = (event: any) => {
        const sessionId = event.value
        if (patientWithSummary && sessionId) {
            setSelectedIndex(0)
            if(role === Roles.Care_Coordinator && paths.includes('potential-patients') && patient) {
                dispatch(fetchPatientLatestScreeningSummary(patient.id, patient.screenerUserPractices[0].practiceId, sessionId));
            } else if((role === Roles.Care_Coordinator && paths.includes('referred-patients')) || paths.includes('prospects')) {
                dispatch(fetchProspectWithSummaryBySessionId({
                    practiceId: patientWithSummary.referredByPracticeId,
                    sessionId,
                    sourcePatientId: patientWithSummary.sourcePatientId,
                    prospectId: patientWithSummary.id
                }))
            } else {
                dispatch(fetchScreeningSummary({
                    patientId: patientWithSummary.id,
                    sessionId,
                    practiceId: patientWithSummary.referredByPracticeId,
                    sourcePatientId: patientWithSummary.sourcePatientId
                }))
            }
        }
    }

    return (
        <div className={`${classNames} max-w-xs w-1/5 mt-2`}>
            <DropdownWithGroupHeading options={screenedDates} key={'dateOfVisit'} title={"Date of Visit"} onChange={fetchPatientSummary} />
        </div>
    )
}