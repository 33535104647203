import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { StateParams } from "../../store/reducers";
import { cancelAppointment } from "../../store/actions/counsellor/appointments.action";
import { IEditAppointment, ITherapistsResponse, PatientSessionStatus, patient_status } from "../../models";
import { useNavigate } from "react-router";
import ConfirmationModal from "../clinical-notes/ConfirmationModal";
import Select from "react-select";
import { transferIntake } from "../../store/actions/care-coordinator/patients.action";
import { ISelectedPatients } from "../../screens/patient-master/PatientMasterListComponent";
import { CallMetadata } from "../generic/CallStatus";

interface PatientMasterModalProp {
    openReAssignModal: boolean
    formError: string
    selectedPatient: ISelectedPatients
    therapists: ITherapistsResponse[]
    patientStatusMapping: Partial<Record<keyof typeof patient_status, CallMetadata>>
    closeReAssignModal: () => void
}

const PatientMasterModal: React.FC<PatientMasterModalProp> = ({openReAssignModal, formError, selectedPatient, therapists, patientStatusMapping, closeReAssignModal}) => {
    const dispatch = useDispatch()
    const [selectedSocialWorker, setSelectedSocialWorker] = useState<string | null>(null)
    const isSessionTypeIntake = selectedPatient.patientSession.sessionType === 'Intake'
    const isSessionScheduledOrInProgress = selectedPatient.patientSession.status === 'scheduled' || selectedPatient.patientSession.status === 'in_progress'
    const isPatientStatusActive = selectedPatient.patientStatus === 'active'
    const isSessionCanceledOrNoShow = selectedPatient.patientSession.status === 'canceled' || selectedPatient.patientSession.status === 'no_show'

    const handleReAssignSocialWorker = useCallback(() => {
        if (selectedPatient.id) {
            dispatch(transferIntake(selectedPatient.id, selectedSocialWorker, selectedPatient.patientStatus));
        }
    }, [selectedSocialWorker, selectedPatient, dispatch]);

    const disableConfirmButton = () => {
        if(isSessionTypeIntake && isSessionScheduledOrInProgress) return false
        if(isSessionTypeIntake && isPatientStatusActive && isSessionCanceledOrNoShow) return false
        if(isSessionTypeIntake && !isSessionScheduledOrInProgress && !selectedSocialWorker) return true
        if(!isSessionTypeIntake && !selectedSocialWorker) return true
    }

    return (
    <ConfirmationModal
        isOpen={openReAssignModal}
        onClose={closeReAssignModal}
        continueButtonName={isSessionTypeIntake && isSessionScheduledOrInProgress ? 'Dissociate Current Social Worker' : 'Assign New Social Worker'}
        onConfirm={handleReAssignSocialWorker}
        disableConfirmButton={disableConfirmButton()}
        modalClassNames="w-[634px]"
        shouldCloseOnOverlayClick={false}
    >
        <div>
            <p className="text-md text-left">Reassign Social Worker</p>
        </div>
        <div className={"flex flex-row gap-x-2 items-center pt-2"}>
            <p className="text-sm">Patient - {selectedPatient.name + ' ( ' + (selectedPatient.dob ? selectedPatient.dob : '') + ' )'}</p>
            <>
                <span className={`py-1 px-2 text-sm font-medium rounded-full shadow-sm ${patientStatusMapping[selectedPatient.patientStatus as keyof typeof patient_status]?.style?.backgroundColor} ${patientStatusMapping[selectedPatient.patientStatus as keyof typeof patient_status]?.style?.color}`} >
                    {patientStatusMapping[selectedPatient.patientStatus as keyof typeof patient_status]?.description}
                </span>
            </>
        </div>

        <div className="confiramtion-modal-info mt-4 mb-5 text-left">
            {isSessionTypeIntake && isSessionScheduledOrInProgress ? 
                <div className="flex pt-3 pl-3">Since the patient hasn't completed their Intake session, the current Social Worker, {selectedPatient.socialWorker}, will be dissociated from the patient. Please schedule a new Intake session to associate the patient with a new Social Worker.</div> : 
                <div className={"flex pt-3 pl-3"}>Current Social Worker - {selectedPatient.socialWorker}</div> 
            }
                <div className={"flex pt-3 pl-3 pb-3"}>Note - {selectedPatient.patientSession?.sessionType}</div>
            </div>

            {(isSessionTypeIntake && isSessionScheduledOrInProgress) || (isSessionTypeIntake && isPatientStatusActive && isSessionCanceledOrNoShow) ? <></> : 
            <>
                <div className="text-sm text-left text-gray-400 pt-2 pb-3">Select the new social worker for the patient below.</div>
                <Select
                    className={"w-100"}
                    options={therapists}
                    placeholder={"Select Social Worker"}
                    isClearable={true}
                    isSearchable={false}
                    isMulti={false}
                    getOptionLabel={(option: any) => `${option?.firstName} ${option?.lastName}`}
                    getOptionValue={(option: any) => option?.id}
                    onChange={(selectedOption) => setSelectedSocialWorker(selectedOption ? selectedOption?.id : undefined)}
                />
            </>
            }
        <div className="text-sjDarkRed mt-4 mb-1 pt-2 text-center">{formError}</div>
    </ConfirmationModal>
    )
}

export default PatientMasterModal
