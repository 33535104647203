import {AnyAction} from "redux";
import {
    AggregatePatientSummaryByLocation,
    PatientSummaryResponseType,
    ScreenerCompletionRate,
} from "../../../models";
import { DashboardFilters } from "../../../screens/dashboard/physician/PhysicianDashboardScreen";

export const FETCH_DASHBOARD_PATIENTS_SUMMARY="FETCH_DASHBOARD_PATIENTS_SUMMARY"
export const FETCH_DASHBOARD_PATIENTS_SUMMARY_IN_PROGRESS="FETCH_DASHBOARD_PATIENTS_SUMMARY_IN_PROGRESS"
export const FETCH_DASHBOARD_PATIENTS_SUMMARY_SUCCESS="FETCH_DASHBOARD_PATIENTS_SUMMARY_SUCCESS"
export const FETCH_DASHBOARD_PATIENTS_SUMMARY_FAILED="FETCH_DASHBOARD_PATIENTS_SUMMARY_FAILED"

export const FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION="FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION"
export const FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_IN_PROGRESS="FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_IN_PROGRESS"
export const FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_SUCCESS="FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_SUCCESS"
export const FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_FAILED="FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_FAILED"

export const FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE="FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE"
export const FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_IN_PROGRESS="FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_IN_PROGRESS"
export const FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_SUCCESS="FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_SUCCESS"
export const FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_FAILED="FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_FAILED"

export const fetchPatientsSummary = (filters: DashboardFilters) : AnyAction => {
    return {type: FETCH_DASHBOARD_PATIENTS_SUMMARY, payload: {filters}}
}
export const fetchPatientsSummaryFailed = (payload: {error: string}) : AnyAction => {
    return {type: FETCH_DASHBOARD_PATIENTS_SUMMARY_FAILED, payload}
}
export const fetchPatientsSummaryInProgress = () : AnyAction => {
    return {type: FETCH_DASHBOARD_PATIENTS_SUMMARY_IN_PROGRESS}
}
export const fetchedPatientsSummary = (patientsSummary: PatientSummaryResponseType) : AnyAction => {
    return {type: FETCH_DASHBOARD_PATIENTS_SUMMARY_SUCCESS, payload: {patientsSummary}}
}

export const fetchPatientsSummaryByLocation = (filters: DashboardFilters) : AnyAction => {
    return {type: FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION, payload: {filters}}
}
export const fetchPatientsSummaryByLocationFailed = (payload: {error: string}) : AnyAction => {
    return {type: FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_FAILED, payload}
}
export const fetchedPatientsSummaryByLocation = (patientsSummaryByLocation: AggregatePatientSummaryByLocation[]) : AnyAction => {
    return {type: FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION_SUCCESS, payload: {patientsSummaryByLocation}}
}

export const fetchPatientsScreenerCompletionRate = (filters: DashboardFilters) : AnyAction => {
    return {type: FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE, payload: {filters}}
}
export const fetchPatientsScreenerCompletionRateFailed = (payload: {error: string}) : AnyAction => {
    return {type: FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_FAILED, payload}
}
export const fetchedPatientsScreenerCompletionRate = (patientsScreenerCompletionRate: ScreenerCompletionRate[]) : AnyAction => {
    return {type: FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE_SUCCESS, payload: {patientsScreenerCompletionRate}}
}
