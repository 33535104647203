import { Checkbox, Radio, Textarea } from "@material-tailwind/react"
import { Control, Controller, FieldErrorsImpl, UseFormGetValues, UseFormRegister, UseFormReset, UseFormSetValue, UseFormTrigger, UseFormWatch } from "react-hook-form"
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { StateParams } from "../../../store/reducers";
import icdCodes from "../../../assets/data/icd-codes.json";
import CreatableSelect from "../../generic/CreatableSelect";
import { Inputs } from "./TreatmentPlan";
import { canAddPsychiatristNotes } from "../accessControl";
import ProposedMedication from "./ProposedMedication";
import { fetchTreatmentNotes } from "../../../store/actions/counsellor/patients.action";

type Notes = {
    register: UseFormRegister<Inputs>,
    watch: UseFormWatch<Inputs>,
    reset: UseFormReset<Inputs>,
    control: Control<Inputs, any>,
    errors: Partial<FieldErrorsImpl<Inputs>>,
    setValue: UseFormSetValue<Inputs>,
    renderErrorMessage: (message: string | null | undefined) => "" | JSX.Element | null | undefined,
    canEdit: boolean,
    children?: React.ReactNode,
    medications?: React.ReactNode, notesId: string | null,
    saveNotes: (key: keyof Inputs, answer: any) => void,
    getValues: UseFormGetValues<Inputs>
}
const PsychiatristNotes: React.FC<Notes> = ({ register, watch, reset, control, errors, renderErrorMessage, canEdit, children, notesId, saveNotes, getValues, setValue }) => {
    const dispatch = useDispatch()
    const { treatmentNotes, treatmentNotesCount, notesStatus, patient } = useSelector((state: StateParams) => state.patients, shallowEqual)
    const role = useSelector((state: StateParams) => state.account.role, shallowEqual)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [icdCodesSocial, setIcdCodesSocial] = useState<any>()
    const [icdCodesMentalBehavior, setIcdCodesMentalBehavior] = useState<any>()
    const [proposedMedication, setProposedMedication] = useState<any>()

    useEffect(() => {
        const id = patient?.id
        if (id && notesId) {
            dispatch(fetchTreatmentNotes(id, notesId || ''))
        }
    }, [patient])

    useEffect(() => {
        if (treatmentNotes?.treatmentNotes) {
            const mentalBehavior = treatmentNotes.treatmentNotes?.mentalBehavioral
            const socialDeterminants = treatmentNotes.treatmentNotes?.socialDeterminants
            const socialDeterminantsCreated = socialDeterminants && (socialDeterminants as string[] || []).map(saved => ({
                label: saved, value: saved
            })) || []
            const mentalBehaviorCreated = mentalBehavior && (mentalBehavior as string[] || []).map(saved => ({
                label: saved, value: saved
            })) || []
            const completeResultSetofIcdCodes = [...icdCodes.fSeries, ...icdCodes.zSeries, ...socialDeterminantsCreated]
            const completeResultSetofIcdCodesMentalBehavior = [...icdCodes.fSeries, ...icdCodes.zSeries, ...mentalBehaviorCreated]
            setIcdCodesSocial(completeResultSetofIcdCodes)
            setIcdCodesMentalBehavior(completeResultSetofIcdCodesMentalBehavior)

            setIsLoading(false)
            reset(treatmentNotes?.treatmentNotes)
        }
    }, [treatmentNotes])

    function renderIcdDiagnosis() {
        return <fieldset disabled={canAddPsychiatristNotes(notesStatus, role) ? false : true}>
            <div id="icd-diagnosis">
                <div className="mt-3">
                    <label className="text-lg uppercase">PRESENTING PROBLEM / CHIEF COMPLAINT:</label>
                    <Textarea {...register("presentChiefComplaint")} className="mt-3 outline-none rounded-md" data-testid="presentChiefComplaint"/>
                </div>
            </div>
        </fieldset>
    }

    function renderAdditionalNotes(){
        return <fieldset disabled={canAddPsychiatristNotes(notesStatus, role) ? false : true}>
        <div>
            <label className="block text-lg mt-6 uppercase">Proposed Plan:</label>
            <Textarea {...register("proposedPlan")} className="mt-3 outline-none rounded-md" />
        </div>
    </fieldset>
    }

    function renderIcdCodes() {
        return <div id="icd-codes" className="my-3">
            <label className="block text-lg mt-6 uppercase">DIAGNOSIS</label>
            <div className="mt-2 mb-2">
                <label className="block text-lg mt-2 text-sjOrange uppercase">
                    Mental, Behavioral and Neurodevelopmental disorders F01-F99
                </label>
                {!isLoading && <Controller
                    control={control}
                    name='mentalBehavioral'
                    render={({ field }) => (
                        <CreatableSelect list={icdCodesMentalBehavior} field={field} disabled={canAddPsychiatristNotes(notesStatus, role) ? false : true} />
                    )}
                />}
            </div>
            <div className="mt-2 mb-2">
                <label className="block text-lg mt-6 text-sjOrange uppercase">
                    Social Determinants of Health
                </label>
                {!isLoading && <Controller
                    control={control}
                    name='socialDeterminants'
                    render={({ field }) => (
                        <CreatableSelect list={icdCodesSocial} field={field} disabled={canAddPsychiatristNotes(notesStatus, role) ? false : true} />
                    )}
                />}
            </div>
        </div>
    }

    return (
       <div>
            <div className='flex flex-row justify-between'>
                <div><label className="text-lg mt-6 text-sjOrange uppercase" data-testid="psychiatristLbl">Psychiatrist section</label></div>
                {children ? children : <></>}
            </div>
            {renderIcdDiagnosis()}
            {renderIcdCodes()}
            <ProposedMedication {...{control, reset, register, getValues, watch, errors, setValue }} saveNotes={saveNotes}/>
            {renderAdditionalNotes()}
            <div className='pt-12 p-5'>
                <p className='text-sm font-medium text-[#707070]'>Disclaimer: The above treatment considerations and suggestions are based on consultations with the patient’s
                Care manager / Behavioral health specialist and a review of information available in the care management tracking system.
                I have not personally examined the patient. All recommendations should be implemented with consideration of the patient’s
                relevant prior history and current clinical status. Please feel free to contact me with any questions about the care of this patient.
                </p>
            </div>
       </div>
    )
}

export default React.memo(PsychiatristNotes)
