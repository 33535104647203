import { DateTime } from "luxon"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { IProspect, Roles } from "../../models"
import { RoutePaths } from "../../shared/Utils"
import { StateParams } from "../../store/reducers"
import { PageButtonV2 } from "../generic/Buttons"
import CalendarIcon from "../../assets/images/common/calendar.svg"

interface Props {
    prospect: IProspect
}

const ScheduleAppointmentButton = ({ prospect }: Props) => {
    const { role } = useSelector((state: StateParams) => state.practitionerAccount)

    if (role !== Roles.Care_Coordinator) return null

    const prospectDob = DateTime.fromJSDate(new Date(prospect.dob), {zone: "utc"}).toFormat('MM/dd/yyyy')

    return (
        <Link to={`${RoutePaths.careCoordinator.referredPatient.summary.appointments.replace(':prospectId', prospect.id)}`}
            state={{ sessionTypes: 'Intake', patientName: `${prospect.firstName} ${prospect.lastName}`, dob: prospectDob, practiceName: prospect.referredByPracticeName, patientId: prospect.id, sourcePatientId: prospect.sourcePatientId, practiceId: prospect.referredByPracticeId }}>
            <PageButtonV2
                className={`text-sjOrange ml-2 px-[16px] py-[8px] h-8 text-sm border-2 border-sjOrange`}
            >
                <div className={"flex flex-row gap-x-2"}>
                    <span>Schedule Appointment</span>
                    <img src={CalendarIcon} width={14} height={14} className={"hover:cursor-pointer"} />
                </div>
            </PageButtonV2>
        </Link>
    )
}

export default ScheduleAppointmentButton