import "./ComplianceStatisticsCardComponent.scss";
import React from "react";
// import {Link} from "react-router-dom";
import {ArrowLongRightIcon, ExclamationTriangleIcon} from "@heroicons/react/24/outline";
import {Tooltip} from "react-tooltip";
import {Link} from "react-router-dom";

interface ComplianceStatisticsCardComponentProps {
    isLoading?: boolean;
    isFailed?: boolean;
    mode: "notes" | "screeners";
    title: string;
    subtitle: string;
    pendingCount: number;
    totalCount: number;
    intakesCount?: number;
    treatmentPlansCount?: number;
    progressNotesCount?: number;
    catchUpNotesCount?: number;
    footerTitle?: string;
    actionUrl?: string;
}

const ComplianceStatisticsCardComponent = (props: ComplianceStatisticsCardComponentProps) => {

    const {
        isLoading,
        isFailed,
        mode,
        title,
        subtitle,
        pendingCount,
        totalCount,
        intakesCount,
        treatmentPlansCount,
        progressNotesCount,
        catchUpNotesCount,
        footerTitle,
        actionUrl
    } = props;

    const completedCount = totalCount - pendingCount;
    const pendingPercentage: number = Math.round((pendingCount / totalCount) * 100) || 0;
    const pendingPercentageClass = !isFailed ? (pendingPercentage > 70 ? "text-red-500" : (pendingPercentage > 30 ? "text-yellow-500" : "text-green-500")) : "";

    return (
        <div className="compliance-statistics-card bg-sjWhite px-4 py-5 rounded-md">
            {isLoading && <div className="card-loading"/>}
            {isFailed && <div className="flex gap-2 align-items-center text-red-500 text-lg">
                Failed to load data <ExclamationTriangleIcon height={24}/>
            </div>}
            <div className="flex justify-between">
                <div className="">
                    <div className="text-lg font-semibold">{title}</div>
                    <div className="text-sm font-extralight  text-gray-400">{subtitle}</div>
                </div>
                <div className="whitespace-nowrap ml-2">
                    ({pendingCount} / {totalCount})
                </div>
            </div>
            <div className="h-24">
                {
                    mode === 'notes' && <div className="grid grid-cols-2 gap-2 pb-1 pt-5">
                        <div className="border-r-2 mr-4 pr-4">
                            <div className="flex">
                                <div className="text-sm w-4/6">Intake Note:</div>
                                <div className="text-lg">{intakesCount}</div>
                            </div>
                            <div className="flex">
                                <div className="text-sm w-4/6">Treatment Plan Note:</div>
                                <div className="text-lg">{treatmentPlansCount}</div>
                            </div>
                        </div>
                        <div className="ml-4">
                            <div className="flex">
                                <div className="text-sm w-4/6">Progress Note:</div>
                                <div className="text-lg">{progressNotesCount}</div>
                            </div>
                            <div className="flex">
                                <div className="text-sm w-4/6">Catch up Note:</div>
                                <div className="text-lg">{catchUpNotesCount}</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="flex align-items-center justify-between border-t px-1 pt-5">
                <div className={`${pendingPercentageClass} mt-3`}>
                    <div>
                        {pendingPercentage} %
                    </div>
                    <div>
                        {footerTitle}
                    </div>
                </div>
                <Tooltip id="compliance-card-cta-tooltip">
                    Coming Soon
                </Tooltip>
                {
                    !actionUrl && <div data-tooltip-id="compliance-card-cta-tooltip"
                                      className="px-4 border border-sjOrange text-sjOrange py-2 rounded-md cursor-not-allowed">
                        View List <ArrowLongRightIcon height={24} width={24} className="inline-block ml-2.5"/>
                    </div>}
                {
                    actionUrl && <Link to={actionUrl}>
                        <div className="px-4 border border-sjOrange text-sjOrange py-2 rounded-md">
                            View List <ArrowLongRightIcon height={24} width={24} className="inline-block ml-2.5"/>
                        </div>
                    </Link>
                }
            </div>
        </div>
    )
}

export default ComplianceStatisticsCardComponent;
