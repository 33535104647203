import {NavigationComponentType} from "../../../navigation/NavigationComponentType";

export const HOME_CONTENT_READY = "HOME_CONTENT_READY"
export const RESET_NAVIGATION = "RESET_NAVIGATION"
export const SET_ACTIVE_NAVIGATION_COMPONENT = "SET_ACTIVE_NAVIGATION_COMPONENT"

export const homeContentReady = () => {
    return { type: HOME_CONTENT_READY }
}
export const resetNavigation = () => {
    return { type: RESET_NAVIGATION }
}
export const setActiveNavigationComponent = (component: NavigationComponentType) => {
    return { type: SET_ACTIVE_NAVIGATION_COMPONENT, payload: {activeComponent: component} }
}
