import { useEffect, useState } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom"
import { NotesStatus } from "../../../models";
import { fetchCatchupNotes, fetchPatient, fetchPsychiatristNotes, fetchTreatmentNotes, reviewAndSubmitTreatmentNotes } from "../../../store/actions/counsellor/patients.action";
import { StateParams } from "../../../store/reducers";
import { Button } from "../../generic/Buttons";
import { toast } from "react-toastify";
import ClinicalNotesComponent from "../ClinicalNotesComponent";
import { canAccessPsychiatristNotes, canAddPsychiatristNotes, canCounsellorSubmit, canEditCounsellorNotes, isCounsellorNotesLocked, isNotesLocked, isPsychiatrist } from "../accessControl";
import IndividualPsychiatristNotes from "./IndividualPsychiatristNotes";

const IndividualPsychiatristNotesComponent: React.FC<{ patientProspectId?: string }> = ({ patientProspectId: patientId }) => {
    const { notesStatus, sessionNotesId: catchupNotesId } = useSelector((state: StateParams) => state.patients)
    const patient = useSelector((state: StateParams) => state.patients.patient)
    const isZoomSessionActive = useSelector((state: StateParams) => state.practitionerAccount.isZoomSessionActive)
    const dispatch = useDispatch()
    const params = useParams()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)
    const [notesId, setNotesId] = useState<string | null>(searchParams.get('notesId'))
    const role = useSelector((state: StateParams) => state.account.role);
    const [canEdit, setCanEdit] = useState<boolean>(false)
    const [isAddendumAdded, setIsAddendumAdded] = useState<boolean>(false)
    const [hasSaved, setHasSaved] = useState<boolean>(false)
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const id = patientId || params.patientProspectId
        if (id) {
            batch(() => {
                dispatch(fetchPatient(id))
                dispatch(fetchPsychiatristNotes(id, notesId || ''))
            })
        }
    }, [patientId, params])

    useEffect(() => {
        if (notesStatus) {
            const canEditNotes = canAddPsychiatristNotes(notesStatus, role)
            setCanEdit(canEditNotes)
        }
    }, [notesStatus])

    const handleAddendum = () => setIsAddendumAdded(true)
    const handleSave = () => setHasSaved(true)
    const handleSubmit = () => setHasSubmitted(true)
    
    function renderAddAddendumButton() {
        const renderBtn = () => <Button className={"!bg-sjOrange !text-white !ml-2"} data-testid="addAddendumBtn" onClick={handleAddendum}>Add Addendum note</Button>
        if(isNotesLocked(notesStatus)) {
            return renderBtn()
        }
    }

    function renderSaveAndSubmit() {
        const btns = () => <div className="flex justify-end mt-4">
            <Button className={"!bg-sjOrange !text-white !ml-2"} onClick={handleSave} data-testid="savePsychiatristNotes">Save</Button>
            <Button className={"!bg-sjOrange !text-white !ml-2"} onClick={handleSubmit} data-testid="submitPsychiatrist">Submit</Button>
        </div>
        if(canAddPsychiatristNotes(notesStatus, role)) {
            return btns()
        }
    }

    function actionComponent() {
        return <>
            {renderAddAddendumButton()}
            {renderSaveAndSubmit()}
        </>
    }

    function renderNotesSection() {
        return (<>
            {patient && <IndividualPsychiatristNotes patientId={patient.id} canEdit={canEdit} notesId={notesId} 
            isAddendumAdded={isAddendumAdded} setIsAddendumAdded={setIsAddendumAdded}
            hasSaved={hasSaved} setHasSaved={setHasSaved}
            hasSubmitted={hasSubmitted} setHasSubmitted={setHasSubmitted}/>}
        </>)
    }

    return (<>
        {patient && patient.id && <ClinicalNotesComponent title="Psychiatrist’s Note" patient={patient} actionComponent={actionComponent()} isModalOpen={isModalOpen}>
            {renderNotesSection()}
        </ClinicalNotesComponent>}
        </>
    )
}

export default IndividualPsychiatristNotesComponent