import {AnyAction} from "redux";
import {CallStatus, IPatientInCall} from "../../../models";

export const PHYSICIAN_INITIATE_CALL = "PHYSICIAN_INITIATE_CALL"

export const initiatePhysicianCall = (patient: IPatientInCall, locationId?: string): AnyAction => {
  return { type: PHYSICIAN_INITIATE_CALL, payload: { 
    practiceId: patient.referredByPracticeId, 
    patientId: patient.sourcePatientId,
    locationId
  } }
}
