const ENV = {
    API_URL: process.env.REACT_APP_API_URL,
    PHYSICIAN_API_URL: process.env.REACT_APP_PHYSICIAN_API_URL,
    API_KEY: process.env.REACT_APP_API_KEY || "need_to_set",
    MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN || "need_to_set",
    API_CLIENT: process.env.REACT_APP_CLIENT_ID || "postman",
    JIRA_SERVICE_DESK_API_KEY: process.env.REACT_APP_JIRA_SERVICE_DESK_API_KEY || "need_to_set",
}

export default ENV;
