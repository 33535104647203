import React from "react";
import {useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import Patients from "../../../components/patients/Patients";

const PatientsScreen = () => {
    const {role} = useSelector((state: StateParams) => state.practitionerAccount)

    function renderPatientList() {
        if(!role) {
            return <h1>You dont have access to view this data</h1>
        }
        return <Patients role={role}/>

    }

    return (
        <>{ renderPatientList() } </>
    )
}

export default PatientsScreen
