import { Textarea } from "@material-tailwind/react"
import _ from "lodash"
import { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { IProspect, NotesStatus } from "../../../models"
import { saveSafetyPlanNotes } from "../../../store/actions/counsellor/patients.action"
import { StateParams } from "../../../store/reducers"
import ContactInformation from "./ContactInformation"
import CopingStrategies from "./CopingStrategies"
import PositiveAffirmation from "./PositiveAffirmation"

interface Props {
    patientId: string
    canEdit: boolean
    isPsychiatrist: boolean
}

export type Inputs = {
    feelingSuicidal: string
    reasonsForLiving: string
    worthLivingFor: string
    copingStrategiesAnswer: string
    copingStrategies: { strategy: string }[]
    positiveAffirmationAnswer: string
    positiveAffirmation: { statement: string }[]
    positiveDistraction: string
    makeEnvironmentSafe: string
    contacts: { name: string, relationship: string, contactNumber: string }[]
    proposedMedication: string
    psychiatristAdditionalNotes: string
    clinicalSessionTime: string
    documentationTime: string
}

const SafetyPlanNotes: React.FC<Props> = ({ patientId, canEdit, isPsychiatrist }) => {
    const dispatch = useDispatch()
    const { safetyPlanNotes, notesStatus } = useSelector((state: StateParams) => state.patients)
    const { register, control, handleSubmit, watch, formState: { errors }, getValues, setValue, reset } = useForm<Inputs>()

    const saveNotes = useCallback(
        _.debounce((key, answer) => dispatch(saveSafetyPlanNotes(patientId, { safetyPlanNotes: { [key]: answer } })), 500),
        [])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name && type === 'change') {
                let key
                if (name.includes('contacts') || name.includes('copingStrategies.') || name.includes('positiveAffirmation.')) {
                    key = name.split('.')[0] as 'contacts' | 'copingStrategies' | 'positiveAffirmation'
                } else {
                    key = name as keyof typeof value
                }
                const answer = value[key]
                saveNotes(key, answer)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    useEffect(() => {
        reset(safetyPlanNotes?.safetyPlanNotes)
    }, [safetyPlanNotes])

    return (
        <section style={{ backgroundColor: '#FFFFFF' }} className="divide-y mt-4 p-10">
            <fieldset disabled={!canEdit}>
                <div className="mt-3">
                    <label className="text-lg uppercase">What are some warning signs you exhibit when feeling suicidal:</label>
                    <div><label className="text-md">(Thoughts, feelings, behaviors or experiences that have been or could become dangerous)</label></div>
                    <Textarea {...register("feelingSuicidal")} className="mt-3 outline-none rounded-md" placeholder="Examples: feeling tense, thoughts of dying" />
                </div>
                <div className="mt-5">
                    <label className="text-lg uppercase">What reasons do you have for living:</label>
                    <div><label className="text-md">(Things to look forward to)</label></div>
                    <Textarea {...register("reasonsForLiving")} className="mt-3 outline-none rounded-md" placeholder="Example: Be with family/friends/pets, life goals" />
                </div>
                <div className="mt-5">
                    <label className="text-lg uppercase">What is the one thing that is most important to you and worth living for:</label>
                    <Textarea {...register("worthLivingFor")} className="mt-3 outline-none rounded-md" placeholder="Type here" />
                </div>
                <div className="mt-5">
                    <label className="text-lg uppercase">List some of the coping strategies that you can do on your own to safely feel better:</label>
                    <CopingStrategies {...{ control, register, getValues, setValue, watch, errors }} saveNotes={saveNotes} />
                </div>
                <div className="mt-5">
                    <label className="text-lg uppercase">Come up with some positive affirmations:</label>
                    <PositiveAffirmation {...{ control, register, getValues, setValue, watch, errors }} saveNotes={saveNotes} />
                </div>
                <div className="mt-5">
                    <label className="text-lg uppercase">List some people/places/activities that provide a positive distraction:</label>
                    <Textarea {...register("positiveDistraction")} className="mt-3 outline-none rounded-md" placeholder="Type here" />
                </div>
                <div className="mt-5">
                    <label className="text-lg uppercase">Think of ways to make your environment safe:</label>
                    <Textarea {...register("makeEnvironmentSafe")} className="mt-3 outline-none rounded-md"
                        placeholder="Example: Preventing access to sharp objects, weapons, medications and/or illegal substances, Listen to music, Take a bath, 
                    Take deep breaths, Positive affirmations"
                    />
                </div>
                <div className="mt-5">
                    <label className="text-lg uppercase">People that you can call for help and to feel safe:</label>
                    <ContactInformation {...{ control, register, getValues, setValue, watch, errors }} saveNotes={saveNotes} />
                </div>
                <div className="mt-5">
                    <label className="text-lg uppercase">Agencies that you can call for help and to feel safe:</label>
                    <ul className="list-disc list-inside text-md mt-2">
                        <li>The national suicide hotline at 1-800-273-8255</li>
                        <li>Suicide and Crisis Lifeline at 988</li>
                        <li>Chat at https://988lifeline.org/chat/</li>
                        <li>Or if all else fails 911 to bring to the hospital for emergency evaluation</li>
                    </ul>
                </div>
            </fieldset>
            <fieldset disabled={(isPsychiatrist && (notesStatus !== NotesStatus.LOCKED)) ? false : true}>
                <div>
                    <label className="block text-lg mt-6 uppercase">Proposed Medication:</label>
                    <Textarea {...register("proposedMedication")} className="mt-3 outline-none rounded-md" />
                </div>
                <div>
                    <label className="block text-lg mt-6 uppercase">Psychiatrist Additional Notes:</label>
                    <Textarea {...register("psychiatristAdditionalNotes")} className="mt-3 outline-none rounded-md" />
                </div>
            </fieldset>
            <fieldset disabled={!canEdit}>
                <div className="grid grid-rows-3 grid-flow-col gap-6">
                    <div className="row-span-3">
                        <label className="block text-lg mt-6 uppercase">Clinical session time:</label>
                        <select {...register("clinicalSessionTime")} defaultValue="30" className="mt-3 outline-none rounded-md" >
                            <option value="30">30 minutes</option>
                        </select>
                    </div>
                    <div id="documentationType" className="row-span-3">
                        <label className="block text-lg mt-6 uppercase">documentation time:</label>
                        <select {...register("documentationTime")} defaultValue="20" className="mt-3 outline-none rounded-md" >
                            <option value="20">20 minutes</option>
                        </select>
                    </div>
                </div>
            </fieldset>
        </section>
    )
}

export default SafetyPlanNotes