import {MdCallEnd} from "react-icons/md";

interface CameraButtonProps {
    onLeaveSession: () => void;
}
const CallDisconnectButton: React.FC<CameraButtonProps> = (props: CameraButtonProps) => {
    const { onLeaveSession } = props
    return (
        <button className="flex justify-center items-center mr-4 ml-3 h-10 w-10 rounded-full bg-rose-600 hover:bg-rose-600 hover:cursor-pointer" title="Leave session" >
            <MdCallEnd className="h-7 w-7 text-white" aria-hidden="true" onClick={onLeaveSession} />
        </button>
    )
}

export default CallDisconnectButton