import { DateTime } from "luxon"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { IProspect, PatientSessionStatus, PatientSessionStatusType, Roles, SessionTypes } from "../../models"
import { RoutePaths } from "../../shared/Utils"
import { StateParams } from "../../store/reducers"
import { PageButtonV2 } from "../generic/Buttons"
import CalendarIcon from "../../assets/images/common/calendar.svg"
import { useFlags } from "flagsmith/react";
import { isPhysician } from "./accessControl"

interface Props {
    prospect: IProspect
}

const ViewNotesButton = ({ prospect }: Props) => {
    const navigate = useNavigate()
    const { role } = useSelector((state: StateParams) => state.practitionerAccount)
    const { session } = useSelector((state: StateParams) => state.appointments)
    const flags = useFlags(['intake_view_v2'])
    const isPsychiatrist = Roles.Psychiatrist === role;

    const isIntakeViewV2Enabled = flags.intake_view_v2.enabled

    if (![Roles.Counsellor, Roles.Admin, Roles.Psychiatrist].includes(role as Roles)) return null

    const sessionCreatedOn = session?.createdAt && DateTime.fromISO(session?.createdAt.toString(), { zone: "utc" }).toFormat('MM/dd/yyyy')

    const sessionNavigationMap: { [key in PatientSessionStatusType]: { [key in SessionTypes]?: string; } } = {
        [PatientSessionStatus.inProgressUnderscored]: {
            [SessionTypes.Intake]: isIntakeViewV2Enabled ? `/prospects/${prospect.id}/view-intake` : `/prospects/${prospect.id}/start-intake`
        }
    }

    const navigateToSession = () => {
        const sessionType = session?.sessionType as SessionTypes
        const sessionNavigation = sessionNavigationMap[PatientSessionStatus.inProgressUnderscored]
        const navigationPath = isPsychiatrist ? prospect.patient && `/patients/${prospect.patient.id}/patient-details` : sessionNavigation ? sessionNavigation[sessionType] : null
        if (navigationPath) {
            navigate(navigationPath, { state: { source: 'referred' } })
        } else {
            console.log('No navigation path found for the session type ')
        }
    }

    return (
        <PageButtonV2
            className={`text-sjLink ml-2 px-[16px] py-[8px] h-8 text-sm border-2 border-sjLink`}
            onClick={navigateToSession}
        >
            <div className={"flex flex-row gap-x-2"}>
                <span>View Intake Notes - {sessionCreatedOn}</span>
            </div>
        </PageButtonV2>
    )
}

export default ViewNotesButton
