import React from "react";
import { ModalProvider } from "../../context/ModalContext";
import {IProspect} from "../../models";
import ProspectIntakeComponent from "../prospects/ProspectIntakeComponent";
import ProspectProfile from "../prospects/ProspectProfile";

interface PatientProps {
    patient: IProspect
}

const PatientProfileDetails : React.FC<PatientProps> = ({patient}) => {

    return <>
        <ProspectProfile prospect={patient}/>
        <ModalProvider><ProspectIntakeComponent prospectId={patient.id} screenName={"patientProfileDetails"}/></ModalProvider>
    </>
}

export default PatientProfileDetails
