import {call, put, takeLatest} from "@redux-saga/core/effects";
import {apiHttpClient} from "../../../lib";
import {Either} from "monet";
import {CheckedError} from "../../../types/ServiceError";
import {AuthState, IPatientWithIntake, IReferenceData,} from "../../../models";
import {AxiosResponse} from "axios";
import {callFinished, callInProgress} from "../../actions/loading.action";
import {catchErrorMessage} from "../../../lib/api-helpers";
import {FETCH_PATIENT_WITH_INTAKE} from "../../actions/counsellor";
import {
    CREATE_OR_FETCH_INTAKE, createdIntake, createIntakeFailed,
    fetchedPatientWithIntake,
    fetchingPatientWithIntakeFailed
} from "../../actions/counsellor/intake.action";

const apiFetchPatientWithIntake = (action: { type: string, authState: AuthState, payload: { patientId: string } }) => {
    const url = `/${action.authState.accountId}/prospects/${action.payload.patientId}/intake`;
    return apiHttpClient.get<Either<CheckedError, IPatientWithIntake | null>>(url, { headers: { "x-auth-token": action.authState.token } })
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as (IPatientWithIntake | null))
        }).catch(e => catchErrorMessage(e));
}

const apiCreateIntake = (action: { type: string, authState: AuthState, payload: { patientId: string } }) => {
    const url = `/${action.authState.accountId}/prospects/${action.payload.patientId}/intake`;
    return apiHttpClient.post<Either<CheckedError, IReferenceData>>(url, {},{ headers: { "x-auth-token": action.authState.token } })
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as (IReferenceData))
        }).catch(e => catchErrorMessage(e));
}

function* fetchPatientWithIntake(action: { type: string, authState: AuthState, payload: { patientId: string } }) {
    try {
        console.log(`IntakeSaga:fetchPatientWithIntake`)
        yield put(callInProgress())
        const apiFetchPatientResponse: Either<CheckedError, IPatientWithIntake | null> = yield call(apiFetchPatientWithIntake, action)
        if (apiFetchPatientResponse.isLeft()) {
            const error = apiFetchPatientResponse.left()
            if (error.isChecked) {
                console.log("IntakeSaga: Encountered a Checked Error", error.message)
                yield put(fetchingPatientWithIntakeFailed({ error: error.message }));
            } else {
                yield put(fetchingPatientWithIntakeFailed({ error: error.message }));
            }
        } else {
            const patient = apiFetchPatientResponse.right();
            yield put(fetchedPatientWithIntake(patient));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPatientWithIntakeFailed({ error: "Error fetching prospect!" }));
    } finally {
        yield put(callFinished())
    }
}

function* createIntake(action: { type: string, authState: AuthState, payload: { patientId: string } }) {
    try {
        console.log(`IntakeSaga:createIntake`)
        yield put(callInProgress())
        const apiCreateIntakeResponse: Either<CheckedError, IReferenceData> = yield call(apiCreateIntake, action)
        if (apiCreateIntakeResponse.isLeft()) {
            const error = apiCreateIntakeResponse.left()
            if (error.isChecked) {
                console.log("IntakeSaga:createIntake Encountered a Checked Error", error.message)
                yield put(createIntakeFailed({ error: error.message }));
            } else {
                yield put(createIntakeFailed({ error: error.message }));
            }
        } else {
            const intake = apiCreateIntakeResponse.right();
            yield put(createdIntake(intake));
        }
    } catch (e) {
        console.log(e)
        yield put(createIntakeFailed({ error: "Error creating intake!" }));
    } finally {
        yield put(callFinished())
    }
}


export default function* intakeSaga() {
    yield takeLatest(FETCH_PATIENT_WITH_INTAKE, fetchPatientWithIntake)
    yield takeLatest(CREATE_OR_FETCH_INTAKE, createIntake)
}
