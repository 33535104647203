import React, { useEffect, useState } from 'react'
import sjCircle from "../../assets/images/loginBackground/sj-circle.png";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordFromProfile, clearErrorMessages, login, resetPassword, sendEmail, validateCode }
    from "../../store/actions/physician/account.action";
import { StateParams } from "../../store/reducers";
import { useNavigate } from "react-router";
import PasswordInput from "../../components/generic/PasswordInput";

const ChangePassword: React.FC<{}> = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [formErrors, setFormErrors] = useState<string[]>([])
    const { email, changePasswordError, passwordChangeStatus } = useSelector((state: StateParams) => state.physicianAccount)
    const [currentPassword, setCurrentPassword] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    useEffect(() => {
        dispatch(clearErrorMessages())
    }, [])

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (email && currentPassword && confirmPassword && password && confirmPassword === password) {
            dispatch(changePasswordFromProfile({
                email,
                currentPassword,
                password
            }))
        } else if(email && !changePasswordError && confirmPassword && password && confirmPassword !== password) {
            setFormErrors(() => {
                return [`Passwords don't match!`]
            })
        }
    }

    useEffect(() => {
        if(changePasswordError) {
            setFormErrors((errors) => {
                return errors ? [...errors, changePasswordError] : [changePasswordError]
            })
        }
    }, [changePasswordError])

    useEffect(()=>{
        if(passwordChangeStatus === 'success'){
            dispatch(clearErrorMessages())
            navigate('/providerProfile')
        }
    },[passwordChangeStatus])


    return (
        <main className="xs:pt-28 md:pt-2 0 px-3 xs:h-auto md:h-screen">
            <div className="container mx-auto flex flex-col items-center">
                <div className='w-full h-32 flex items-center justify-center'>
                    <img src={sjCircle} className='h-[200px]' />
                </div>
                <div className="flex-col items-center text-2xl font-bold text-sjAnswer">Update your password</div>
            </div>
            <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-3 gap-6">
                <div></div>
                <div className="pt-10">
                    <label className="block w-90">
                        <span className="text-gray-700">Current Password*</span>
                        <PasswordInput
                            value={currentPassword}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setCurrentPassword(event.target.value)
                                setFormErrors([])
                            }}
                        />
                    </label>
                    <label className="block w-90 mt-1">
                        <span className="text-gray-700">New Password*</span>
                        <PasswordInput
                            value={password}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setPassword(event.target.value)
                                setFormErrors([])
                            }}
                        />
                    </label>
                    <label className="block w-90 mt-1">
                        <span className="text-gray-700">Re-type New Password*</span>
                        <PasswordInput
                            value={confirmPassword}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setConfirmPassword(event.target.value)
                                setFormErrors([])
                            }}
                        />
                    </label>
                </div>
                <div></div>
            </div>
            <div className="mt-10 grid grid-cols-3 gap-6">
                <div></div>
                <div className="flex justify-center items-center">
                    <button
                        className="bg-sj2 hover:bg-sjOrange text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type='submit' value="Submit">
                        Update password
                    </button>
                </div>
                <div></div>
            </div>
            </form>
            <div className="mt-10 grid grid-cols-3 gap-6">
                <div></div>
                <div className="flex flex-col justify-center items-center">
                    {formErrors?.map((error, index) => {
                        return <div className="text-red-500" key={"error-" + index}>{error}</div>
                    })}
                </div>
                <div></div>
            </div>
        </main>
    )
}

export default ChangePassword
