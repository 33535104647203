import {useState} from "react";
import {MediaDevice} from "../../types/VideoTypes";
import {AiOutlineAudio, AiOutlineAudioMuted} from "react-icons/ai";

interface MicrophoneButtonProps {
    isStartedAudio: boolean;
    isMuted: boolean;
    isSupportPhone?: boolean;
    disabled?: boolean;
    audio?: string;
    phoneCountryList?: any[];
    onMicrophoneClick: () => void;
    onMicrophoneMenuClick: (key: string) => void;
    onPhoneCallClick?: (code: string, phoneNumber: string, name: string, option: any) => void;
    onPhoneCallCancel?: (code: string, phoneNumber: string, option: any) => Promise<any>;
    className?: string;
    microphoneList?: MediaDevice[];
    speakerList?: MediaDevice[];
    activeMicrophone?: string;
    activeSpeaker?: string;
    phoneCallStatus?: { text: string; type: string };
}

const MicrophoneButton: React.FC<MicrophoneButtonProps>= (props: MicrophoneButtonProps) => {
    const {
        isStartedAudio,
        isSupportPhone,
        isMuted,
        audio,
        className,
        microphoneList,
        speakerList,
        phoneCountryList,
        activeMicrophone,
        activeSpeaker,
        phoneCallStatus,
        disabled,
        onMicrophoneClick,
        onMicrophoneMenuClick,
        onPhoneCallClick,
        onPhoneCallCancel
    } = props
    
    const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
    const menu = [];

    return (
        <div>
            <button>{!isStartedAudio || isMuted ? <AiOutlineAudioMuted className="h-7 w-7 text-white" aria-hidden="true" title="unmute" onClick={onMicrophoneClick}/> :
                <AiOutlineAudio className="h-7 w-7 text-white" aria-hidden="true" title="mute" onClick={onMicrophoneClick}/>}
            </button>
        </div>
    )
}

export default MicrophoneButton