import React, {PropsWithChildren} from "react";
import {useNavigate} from "react-router";
import {SideBarButtonProps, withActiveComponentTreatment, withIconStyling} from "./WithActiveComponentTreatment";

const PatientsButton: React.FC<PropsWithChildren<SideBarButtonProps>> = ({style, navigateTo, iconRenderer}) => {
    const navigate = useNavigate()

    const navigateToPatients = () => {
        navigate(navigateTo)
    }

    function render() {
        return <div className="flex">
            <button className={style} onClick={navigateToPatients}>
                {iconRenderer("Active Patients")}
            </button>
        </div>
    }

    return render()
}

export default withActiveComponentTreatment(withIconStyling(PatientsButton))
