import React, { useEffect, useState } from "react";
import { Button } from "./Buttons";
import axios from "axios";
import { MdOutlineFileDownload } from "react-icons/md";

interface DownloadProps {
    url: string | undefined;
    fileName: string | undefined;
    handleDownload : () => void;
    downloadButtonClicked: boolean;
    setDownloadButtonClicked: (value: boolean) => void;
    downloadComponent?: React.ReactNode;
}

const DownloadPDF: React.FC<DownloadProps> = ({ url, fileName, handleDownload, downloadButtonClicked, setDownloadButtonClicked, downloadComponent }) => {

    useEffect(() => {
        if (downloadButtonClicked && url && fileName) {
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            });
            setDownloadButtonClicked(false);
        }
    }, [url, fileName]);

    const downloadFile = () => {
        setDownloadButtonClicked(true);
        handleDownload()
    };

    return (
        <div>
            {!downloadComponent ? <Button className="rounded-l-md !border-sjOrange !bg-sjOrange !text-white" onClick={downloadFile} >
                Download
                <MdOutlineFileDownload className="h-5 w-5 ml-2 text-white" aria-hidden="true" onClick={downloadFile} />
            </Button>: downloadComponent}
        </div>
    );
}

export default DownloadPDF;
