import { SIGN_OUT_ACCOUNT } from "../../actions/common";
import { CREATE_OR_FETCH_INTAKE_SUCCESS, FETCH_PATIENT_WITH_INTAKE_SUCCESS } from "../../actions/counsellor/intake.action";
import { IPatientWithIntake, IReferenceData } from "../../../models";
import { RESET_PROSPECT } from "../../actions/counsellor";

export interface IntakeParams {
    intakeDetails?: IPatientWithIntake | null
    intake?: IReferenceData
    noIntakeFound: boolean
}

const initialState: IntakeParams = {
    intakeDetails: undefined,
    intake: undefined,
    noIntakeFound: false
}

const IntakeReducer = (state: IntakeParams = initialState, action: any): IntakeParams => {
    switch (action.type) {

        case FETCH_PATIENT_WITH_INTAKE_SUCCESS:
            state = { ...state, intakeDetails: action.payload.intakeDetails, noIntakeFound: action.payload.intakeDetails === null }
            return state

        case CREATE_OR_FETCH_INTAKE_SUCCESS:
            state = { ...state, intake: action.payload.intake, intakeDetails: undefined, noIntakeFound: false }
            return state

        case RESET_PROSPECT:
            state = { ...state, ...initialState }
            return state

        case SIGN_OUT_ACCOUNT:
            state = { ...state, ...initialState }
            return state
        default:
            return state;
    }

}

export default IntakeReducer
