import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { StateParams } from "../../store/reducers";
import PaginationV2 from "../../components/generic/PaginationV2";
import Select from "react-select";
import TableFilterPaginationV2 from "../../components/generic/TableFilterPaginationV2";
import PatientMasterListComponent from "./PatientMasterListComponent";
import { fetchPatientMasterList } from "../../store/actions/care-coordinator/patients.action";
import { fetchTherapists } from "../../store/actions/counsellor/appointments.action";
import { fetchPractices } from "../../store/actions/counsellor/prospects.action";


const PatientMasterScreen = () => {
    const {
        fetchPatientMasterListInProgress,
        patientMasterList,
        patientMasterCount,
    } = useSelector((state: StateParams) => state.coordinatorPatients);
    const dispatch = useDispatch();

    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageNumber, setPageNumber] = useState(Number(searchParams.get("page")) || 1);
    const [practiceIds, setPracticeIds] = useState<string[]>([]);
    const [socialWorkerIds, setSocialWorkerIds] = useState<string[]>([]);
    const { therapists } = useSelector((state: StateParams) => state.appointments);
    const { practices } = useSelector((state: StateParams) => state.prospects);
    const {
        intakeTransferInProgress,
        transferedPatient,
    } = useSelector((state: StateParams) => state.coordinatorPatients);

    useEffect(() => {
        dispatch(fetchTherapists());
        dispatch(fetchPractices());
    }, [dispatch]);


    useEffect(() => {
        if (searchParams) {
            if (searchParams.get("page")) {
                setPageNumber(Number(searchParams.get("page")));
            } else {
                setPageNumber(1);
            }
        }
    }, [searchParams]);

    const handleFiltersChange = useCallback(() => {
        setPageNumber(1);
    }, []);

    useEffect(() => {
        handleFiltersChange();
    }, [searchText, practiceIds, socialWorkerIds, handleFiltersChange]);

    useEffect(() => {
        getPatienrMasterList();
    }, [pageNumber, recordsPerPage, searchText, practiceIds, socialWorkerIds]);

    useEffect(() => {
        if (!intakeTransferInProgress && transferedPatient) {
            getPatienrMasterList();
        }
    }, [intakeTransferInProgress, transferedPatient]);

    const getPatienrMasterList = useCallback(() => {
        if (pageNumber || recordsPerPage || searchText || practiceIds.length > 0 || socialWorkerIds) {
            dispatch(fetchPatientMasterList({ pageNumber, recordsPerPage, searchText, practiceIds, socialWorkerIds }));
        }
    }, [dispatch, pageNumber, recordsPerPage, searchText, practiceIds, socialWorkerIds]);



    const renderFilters = useCallback(() => {
        return (
            <div className='flex flex-row gap-3'>
                <Select
                    className={"w-48"}
                    onChange={(selectedOptions) => setPracticeIds(selectedOptions ? selectedOptions.map(option => option.id) : [])}
                    placeholder={"Practice"}
                    isClearable={true}
                    isSearchable={false}
                    options={practices}
                    isMulti={true}
                    getOptionLabel={option => `${option?.name}`}
                    getOptionValue={option => option?.id}
                />
                <Select
                    className={"w-48"}
                    onChange={(selectedOptions) => setSocialWorkerIds(selectedOptions ? selectedOptions.map(option => option.id) : [])}
                    placeholder={"Social Worker"}
                    isClearable={true}
                    isSearchable={false}
                    options={therapists}
                    isMulti={true}
                    getOptionLabel={option => `${option?.firstName} ${option?.lastName}`}
                    getOptionValue={option => option?.id}
                />
                <TableFilterPaginationV2
                    setTableFilter={setSearchText}
                    totalRows={patientMasterCount || 0}
                    currentPageHandler={setPageNumber}
                />
            </div>
        );
    }, [practices, therapists, patientMasterCount, setSearchText, setPageNumber]);

    const renderPatients = useCallback(() => {
        return (
            <div>
                <div className="flex flex-row justify-between pb-4">
                    <div className='flex'>
                        <h1 className="text-xl"> Patient Master </h1>
                    </div>
                    <div className='flex flex-row gap-x-2 items-center'>
                        {renderFilters()}
                    </div>
                </div>
                <div className="mt-4 relative">
                    <PatientMasterListComponent
                        searchInProgress={fetchPatientMasterListInProgress}
                        patients={patientMasterList}
                    />
                    {patientMasterList && patientMasterList.length > 0 &&
                        <PaginationV2
                            totalRows={patientMasterCount || 0}
                            rowsPerPage={recordsPerPage}
                            recordsPerPageHandler={setRecordsPerPage}
                            currentPage={pageNumber}
                            currentPageHandler={setPageNumber}
                            showPages={false}
                        />
                    }
                </div>
            </div>
        );
    }, [
        patientMasterList, fetchPatientMasterListInProgress,
        patientMasterCount, renderFilters,
        recordsPerPage, pageNumber,
        setRecordsPerPage, setPageNumber,
        getPatienrMasterList
    ]);

    return (
            <div className={"w-full p-4 h-full bg-[#F7F8FA]"}>
                {renderPatients()}
            </div>
    );
};

export default PatientMasterScreen;
