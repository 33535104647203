import { AnyAction } from "redux";
import {
  FiltersTypes, IAppointmentSession,
  IIntakeFormData,
  ILockIntakeResponse,
  IPatientIndex,
  IProspect,
  IProspectScreenerResponse,
  IReferenceData,
  IReferredPatientsDetails,
  ITherapist,
  NotesStatus,
  NotesStatusRequest,
  Practice,
  ProspectScreeningSummary,
  ReferredPatientCallStatus,
  SessionTypes
} from "../../../models";
import { PATCH_PATIENT_INFO, UPDATE_PATIENT_INFO_SUCCESS } from "./patients.action";

export const SEARCH_PATIENT = "SEARCH_PATIENT"
export const SEARCH_PATIENT_IN_PROGRESS = "SEARCH_PATIENT_IN_PROGRESS"
export const SEARCH_PATIENT_SUCCESS = "SEARCH_PATIENT_SUCCESS"
export const SEARCH_PATIENT_FAILED = "SEARCH_PATIENT_FAILED"
export const CLEAR_PATIENT_SEARCH_RESULTS = "CLEAR_PATIENT_SEARCH_RESULTS"

export const RESET_PROSPECT = "RESET_PROSPECT"
export const FETCH_PROSPECTS = "FETCH_PROSPECTS"
export const FETCH_PROSPECTS_IN_PROGRESS = "FETCH_PROSPECTS_IN_PROGRESS"
export const FETCH_PROSPECTS_SUCCESS = "FETCH_PROSPECTS_SUCCESS"
export const FETCH_PROSPECTS_FAILED = "FETCH_PROSPECTS_FAILED"

export const FETCH_PROSPECT = "FETCH_PROSPECT"
export const FETCH_PROSPECT_IN_PROGRESS = "FETCH_PROSPECT_IN_PROGRESS"
export const FETCH_PROSPECT_SUCCESS = "FETCH_PROSPECT_SUCCESS"
export const FETCH_PROSPECT_FAILED = "FETCH_PROSPECT_FAILED"

export const FETCH_PROSPECT_WITH_SUMMARY = "FETCH_PROSPECT_WITH_SUMMARY"
export const FETCH_PROSPECT_WITH_SUMMARY_IN_PROGRESS = "FETCH_PROSPECT_WITH_SUMMARY_IN_PROGRESS"
export const FETCH_PROSPECT_WITH_SUMMARY_SUCCESS = "FETCH_PROSPECT_WITH_SUMMARY_SUCCESS"
export const FETCH_PROSPECT_WITH_SUMMARY_FAILED = "FETCH_PROSPECT_WITH_SUMMARY_FAILED"

export const FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID = "FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID"
export const FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID_IN_PROGRESS = "FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID_IN_PROGRESS"
export const FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID_SUCCESS = "FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID_SUCCESS"
export const FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID_FAILED = "FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID_FAILED"

export const FETCH_PROSPECT_SCREENER_RESPONSE = "FETCH_PROSPECT_SCREENER_RESPONSE"
export const FETCH_PROSPECT_SCREENER_RESPONSE_IN_PROGRESS = "FETCH_PROSPECT_SCREENER_RESPONSE_IN_PROGRESS"
export const FETCH_PROSPECT_SCREENER_RESPONSE_SUCCESS = "FETCH_PROSPECT_SCREENER_RESPONSE_SUCCESS"
export const FETCH_PROSPECT_SCREENER_RESPONSE_FAILED = "FETCH_PROSPECT_SCREENER_RESPONSE_FAILED"

export const COMPLETE_INTAKE_SESSION = "COMPLETE_INTAKE_SESSION"
export const COMPLETE_INTAKE_SESSION_IN_PROGRESS = "COMPLETE_INTAKE_SESSION_IN_PROGRESS"
export const COMPLETED_INTAKE_SESSION = "COMPLETED_INTAKE_SESSION"
export const COMPLETE_INTAKE_SESSION_FAILED = "COMPLETE_INTAKE_SESSION_FAILED"

export const FETCH_PROSPECT_REFERENCE_DATA = "FETCH_PROSPECT_REFERENCE_DATA"
export const FETCH_PROSPECT_REFERENCE_DATA_IN_PROGRESS = "FETCH_PROSPECT_REFERENCE_DATA_IN_PROGRESS"
export const ACCEPT_PROSPECT_REFERENCE_DATA_SUCCESS = "ACCEPT_PROSPECT_REFERENCE_DATA_SUCCESS"
export const ACCEPT_PROSPECT_REF_DATA_FAILED = "ACCEPT_PROSPECT_REF_DATA_FAILED"

export const FETCH_ACE_SCREENER = "FETCH_ACE_SCREENER"
export const FETCH_ACE_SCREENER_IN_PROGRESS = "FETCH_ACE_SCREENER_IN_PROGRESS"
export const ACCEPT_ACE_SCREENER_SUCCESS = "ACCEPT_ACE_SCREENER_SUCCESS"
export const ACCEPT_ACE_SCREENER_FAILED = "ACCEPT_ACE_SCREENER_FAILED"

export const INTAKE_FORM_SAVE = "INTAKE_FORM_SAVE"
export const INTAKE_FORM_SAVE_IN_PROGRESS = "INTAKE_FORM_SAVE_IN_PROGRESS"
export const INTAKE_FORM_SAVE_SUCCESS = "INTAKE_FORM_SAVE_SUCCESS"
export const INTAKE_FORM_SAVE_FAILED = "INTAKE_FORM_SAVE_FAILED"

export const FETCH_PROSPECT_SESSION_BY_SRC_PATIENT = "FETCH_PROSPECT_SESSION_BY_SRC_PATIENT"
export const FETCH_PROSPECT_SESSION_BY_SRC_PATIENT_SUCCESS = "FETCH_PROSPECT_SESSION_BY_SRC_PATIENT_SUCCESS"
export const FETCH_PROSPECT_SESSION_BY_SRC_PATIENT_FAILED = "FETCH_PROSPECT_SESSION_BY_SRC_PATIENT_FAILED"

export const LOCK_INTAKE_FORM = "LOCK_INTAKE_FORM"
export const LOCK_INTAKE_FORM_SUCCESS = "LOCK_INTAKE_FORM_SUCCESS"
export const LOCK_INTAKE_FORM_FAILED = "LOCK_INTAKE_FORM_FAILED"

export const FETCH_PRACTICES = "FETCH_PRACTICES"
export const FETCH_PRACTICES_IN_PROGRESS = "FETCH_PRACTICES_IN_PROGRESS"
export const FETCH_PRACTICES_SUCCESS = "FETCH_PRACTICES_SUCCESS"
export const FETCH_PRACTICES_FAILED = "FETCH_PRACTICES_FAILED"

export const UPDATE_NOTE_STATUS = "UPDATE_NOTE_STATUS"
export const UPDATE_NOTE_STATUS_IN_PROGRESS = "UPDATE_NOTE_STATUS_IN_PROGRESS"
export const UPDATE_NOTE_STATUS_SUCCESS = "UPDATE_NOTE_STATUS_SUCCESS"
export const UPDATE_NOTE_STATUS_FAILED = "UPDATE_NOTE_STATUS_FAILED"
export const UPDATE_NOTE_STATUS_INTAKE = "UPDATE_NOTE_STATUS_INTAKE"

export const SET_INTAKE_FORM_ERRORS = "SET_INTAKE_FORM_ERRORS"
export const CLEAR_INTAKE_FORM_ERRORS = "CLEAR_INTAKE_FORM_ERRORS"
export const CLEAR_NOTES_ERROR = "CLEAR_NOTES_ERROR"

export const FETCH_PSYCHIATRIST_SOCIAL_WORKERS = "FETCH_PSYCHIATRIST_SOCIAL_WORKERS"
export const FETCH_PSYCHIATRIST_SOCIAL_WORKERS_IN_PROGRESS = "FETCH_PSYCHIATRIST_SOCIAL_WORKERS_IN_PROGRESS"
export const FETCH_PSYCHIATRIST_SOCIAL_WORKERS_SUCCESS = "FETCH_PSYCHIATRIST_SOCIAL_WORKERS_SUCCESS"
export const FETCH_PSYCHIATRIST_SOCIAL_WORKERS_FAILED = "FETCH_PSYCHIATRIST_SOCIAL_WORKERS_FAILED"



export const fetchProspects = (currentPage: number, recordsPerPage: number, activeTab: string, filters?: FiltersTypes, searchText?: string): AnyAction => {
  return { type: FETCH_PROSPECTS, payload: { currentPage, recordsPerPage, filters, searchText, activeTab } }
}
export const fetchingProspectsFailed = (payload: { error: string }): AnyAction => {
  return { type: FETCH_PROSPECTS_FAILED, payload }
}
export const fetchedProspects = (prospects: IReferredPatientsDetails[], prospectsCount: number, tabCount: Record<string, number>): AnyAction => {
  return { type: FETCH_PROSPECTS_SUCCESS, payload: { prospects, prospectsCount, tabCount } }
}

export const resetProspectData = (): AnyAction => {
  return { type: RESET_PROSPECT }
}
export const fetchProspect = (prospectId: string): AnyAction => {
  return { type: FETCH_PROSPECT, payload: { prospectId } }
}
export const fetchingProspectFailed = (payload: { error: string }): AnyAction => {
  return { type: FETCH_PROSPECT_FAILED, payload }
}
export const fetchedProspect = (prospect: IProspect): AnyAction => {
  return { type: FETCH_PROSPECT_SUCCESS, payload: { prospect } }
}

export const fetchProspectWithSummary = (prospectId: string, practiceId: string, sourcePatientId: string): AnyAction => {
  return { type: FETCH_PROSPECT_WITH_SUMMARY, payload: { prospectId, practiceId, sourcePatientId } }
}
export const fetchingProspectWithSummaryFailed = (payload: { error: string }): AnyAction => {
  return { type: FETCH_PROSPECT_WITH_SUMMARY_FAILED, payload }
}
export const fetchedProspectWithSummary = (prospect: IProspect): AnyAction => {
  return { type: FETCH_PROSPECT_WITH_SUMMARY_SUCCESS, payload: { prospect } }
}

export const fetchProspectWithSummaryBySessionId = (request: ProspectScreeningSummary): AnyAction => {
  return { type: FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID, payload: request }
}
export const fetchingProspectWithSummaryBySessionIdFailed = (payload: { error: string }): AnyAction => {
  return { type: FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID_FAILED, payload }
}
export const fetchedProspectWithSummaryBySessionId = (prospect: IProspect): AnyAction => {
  return { type: FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID_SUCCESS, payload: { prospect } }
}

export const fetchProspectScreenerResponse = (prospectId: string, prospectScreenerId: string, sourcePatientId: string, practiceId: string): AnyAction => {
  return { type: FETCH_PROSPECT_SCREENER_RESPONSE, payload: { prospectId, prospectScreenerId, sourcePatientId, practiceId } }
}
export const fetchingProspectFailedScreenerResponse = (payload: { error: string }): AnyAction => {
  return { type: FETCH_PROSPECT_SCREENER_RESPONSE_FAILED, payload }
}
export const fetchedProspectScreenerResponse = (screenerResponses: IProspectScreenerResponse[], screenerId: string): AnyAction => {
  return { type: FETCH_PROSPECT_SCREENER_RESPONSE_SUCCESS, payload: { screenerResponses, screenerId } }
}

export const searchPatient = (searchKeyword: string, searchForSessionType: keyof typeof SessionTypes): AnyAction => {
  return { type: SEARCH_PATIENT, payload: { searchKeyword, searchForSessionType } }
}
export const fetchingSearchPatientFailed = (payload: { error: string }): AnyAction => {
  return { type: SEARCH_PATIENT_FAILED, payload }
}
export const searchedPatient = (patientIndex: IPatientIndex): AnyAction => {
  return { type: SEARCH_PATIENT_SUCCESS, payload: { patientIndex } }
}
export const clearPatientSearchResults = (): AnyAction => {
  return { type: CLEAR_PATIENT_SEARCH_RESULTS }
}

export const completeIntakeSession = (prospectId: string, sessionId: string, isConsentGivenToCoCM: boolean, sourcePatientId: string, noteId: string, notesStatus: NotesStatus): AnyAction => {
  return { type: COMPLETE_INTAKE_SESSION, payload: { prospectId, sessionId, isConsentGivenToCoCM, sourcePatientId, noteId, notesStatus } }
}
export const completeIntakeSessionInProgress = (): AnyAction => {
  return { type: COMPLETE_INTAKE_SESSION_IN_PROGRESS }
}
export const completeIntakeSessionFailed = (payload: { error: string }): AnyAction => {
  return { type: COMPLETE_INTAKE_SESSION_FAILED, payload }
}
export const completedIntakeSession = (isStatusUpdated: boolean): AnyAction => {
  return { type: COMPLETED_INTAKE_SESSION, payload: { isStatusUpdated } }
}

export const acceptingPatientRefDataFailed = (payload: { error: string }): AnyAction => {
  return { type: ACCEPT_PROSPECT_REF_DATA_FAILED, payload }
}

export const acceptedProspectRefData = (sourceRefData: IReferenceData): AnyAction => {
  return { type: ACCEPT_PROSPECT_REFERENCE_DATA_SUCCESS, payload: { sourceRefData } }
}

export const fetchProspectReferenceData = (patientId: string, practiceId: string): AnyAction => {
  return { type: FETCH_PROSPECT_REFERENCE_DATA, payload: { patientId, practiceId } }
}

export const fetchAceScreener = (patientId: string, practiceId: string): AnyAction => {
  return { type: FETCH_ACE_SCREENER, payload: { patientId, practiceId } }
}

export const acceptingAceScreenerFailed = (payload: { error: string }): AnyAction => {
  return { type: ACCEPT_ACE_SCREENER_FAILED, payload }
}

export const acceptedAceScreener = (aceScreener: any): AnyAction => {
  return { type: ACCEPT_ACE_SCREENER_SUCCESS, payload: { aceScreener } }
}


export const intakeFormSave = (patientId: string, body: any): AnyAction => {
  return { type: INTAKE_FORM_SAVE, payload: { patientId, body } }
}

export const intakeFormSaveFailed = (payload: { error: string }): AnyAction => {
  return { type: INTAKE_FORM_SAVE_FAILED, payload }
}

export const intakeFormSaveSuccess = (keys: string[], formData: IIntakeFormData): AnyAction => {
  return { type: INTAKE_FORM_SAVE_SUCCESS, payload: { keys, formData } }
}

export const fetchSessionBySourcePatientId = (sourcePatientId: string): AnyAction => {
  return { type: FETCH_PROSPECT_SESSION_BY_SRC_PATIENT, payload: { sourcePatientId } }
}
export const fetchSessionBySourcePatientIdSuccess = (session: IAppointmentSession): AnyAction => {
  return { type: FETCH_PROSPECT_SESSION_BY_SRC_PATIENT_SUCCESS, payload: { session } }
}
export const fetchSessionBySourcePatientIdFailed = (payload: { error: string }): AnyAction => {
  return { type: FETCH_PROSPECT_SESSION_BY_SRC_PATIENT_FAILED, payload }
}

export const lockIntakeForm = (prospectId: string): AnyAction => {
  return { type: LOCK_INTAKE_FORM, payload: { prospectId } }
}
export const lockIntakeSuccess = (lockIntake: ILockIntakeResponse): AnyAction => {
  return { type: LOCK_INTAKE_FORM_SUCCESS, payload: { lockIntake } }
}
export const lockIntakeFailed = (payload: { error: string }): AnyAction => {
  return { type: LOCK_INTAKE_FORM_FAILED, payload }
}

export const fetchPractices = (): AnyAction => {
  return { type: FETCH_PRACTICES }
}
export const fetchPracticesFailed = (payload: { error: string }): AnyAction => {
  return { type: FETCH_PRACTICES_FAILED, payload }
}
export const fetchedPractices = (practices: Practice[]): AnyAction => {
  return { type: FETCH_PRACTICES_SUCCESS, payload: { practices } }
}

export const updateNotesStatus = (notes: NotesStatusRequest): AnyAction => {
  return { type: UPDATE_NOTE_STATUS, payload: { notes } }
}
export const updateNotesStatusFailed = (payload: { error: string }): AnyAction => {
  return { type: UPDATE_NOTE_STATUS_FAILED, payload }
}
export const updatedNotesStatus = (isNotesStatusUpdated: boolean): AnyAction => {
  return { type: UPDATE_NOTE_STATUS_SUCCESS, payload: { isNotesStatusUpdated } }
}
export const updatedNotesStatusIntake = (notesStatus: NotesStatus): AnyAction => {
  return { type: UPDATE_NOTE_STATUS_INTAKE, payload: { updatedStatus: notesStatus } }
}

export const setIntakeFormErrors = (errors: any): AnyAction => {
  return { type: SET_INTAKE_FORM_ERRORS, payload: { errors } }
}
export const clearIntakeFormErrors = (): AnyAction => {
  return { type: CLEAR_INTAKE_FORM_ERRORS }
}
export const clearNotesError = (): AnyAction => {
  return { type: CLEAR_NOTES_ERROR }
}

export const updateContactPhoneNumber = (contactPhoneNumber: string | null | undefined) => {
  return { type: UPDATE_PATIENT_INFO_SUCCESS, payload: { contactPhoneNumber } }
}

export const patchPatientInfoStatus = (payload: { referredCallStatus: keyof ReferredPatientCallStatus }): AnyAction => {
  return { type: PATCH_PATIENT_INFO, payload }
}

export const fetchPsychiatristSocialWorkers= (): AnyAction => {
  return { type: FETCH_PSYCHIATRIST_SOCIAL_WORKERS }
}
export const fetchPsychiatristSocialWorkersFailed = (payload: { error: string }): AnyAction => {
  return { type: FETCH_PSYCHIATRIST_SOCIAL_WORKERS_FAILED, payload }
}
export const fetchedPsychiatristSocialWorkers = (therapists: ITherapist[]): AnyAction => {
  return { type: FETCH_PSYCHIATRIST_SOCIAL_WORKERS_SUCCESS, payload: { therapists } }
}

