import {call, put, takeLatest} from "@redux-saga/core/effects";
import {physicianHttpClient} from "../../../lib";
import {Either, Right} from "monet";
import {CheckedError, ServiceError} from "../../../types/ServiceError";
import {AggregatePatientSummaryByLocation, AuthState, PatientSummaryResponseType, ScreenerCompletionRate} from "../../../models";
import {AxiosError, AxiosResponse} from "axios";
import {callFinished, callInProgress} from "../../actions/loading.action";
import {
    FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE,
    FETCH_DASHBOARD_PATIENTS_SUMMARY,
    FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION,
    fetchPatientsScreenerCompletionRateFailed,
    fetchPatientsSummaryByLocationFailed,
    fetchPatientsSummaryFailed,
    fetchedPatientsScreenerCompletionRate,
    fetchedPatientsSummary,
    fetchedPatientsSummaryByLocation,
    fetchPatientsSummaryInProgress
} from "../../actions/physician/dashboard.action";
import { DashboardFilters } from "../../../screens/dashboard/physician/PhysicianDashboardScreen";

const apiFetchPatientsSummary = (action: { type: string, authState: AuthState, payload: {filters: DashboardFilters}}) => {
    const url = `/${action.authState.accountId}/dashboard?startDate=${action.payload.filters.startDate}&endDate=${action.payload.filters.endDate}`;
    const config = { headers: { "x-auth-token": action.authState.token }}
    return physicianHttpClient.get<Either<CheckedError, PatientSummaryResponseType>>(url, config)
        .then((response: AxiosResponse) => {
            return Either.right({ patientsSummary: response.data.data as PatientSummaryResponseType})
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchPatientsSummaryByLocation = (action: { type: string, authState: AuthState, payload: {filters: DashboardFilters}}) => {
    const url = `/${action.authState.accountId}/dashboard/summary-by-location?startDate=${action.payload.filters.startDate}&endDate=${action.payload.filters.endDate}`;
    const config = { headers: { "x-auth-token": action.authState.token }}
    return physicianHttpClient.get<Either<CheckedError, AggregatePatientSummaryByLocation[]>>(url, config)
        .then((response: AxiosResponse) => {
            return Either.right({ patientsSummaryByLocation: response.data.data as AggregatePatientSummaryByLocation})
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchPatientsScreenerCompletionRate = (action: { type: string, authState: AuthState, payload: {filters: DashboardFilters}}) => {
    const url = `/${action.authState.accountId}/dashboard/completion-rate-by-location?startDate=${action.payload.filters.startDate}&endDate=${action.payload.filters.endDate}`;
    const config = { headers: { "x-auth-token": action.authState.token }}
    return physicianHttpClient.get<Either<CheckedError, ScreenerCompletionRate[]>>(url, config)
        .then((response: AxiosResponse) => {
            return Either.right({ patientsScreenerCompletionRate: response.data.data as ScreenerCompletionRate})
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* fetchPatientsSummary(action: { type: string,  authState: AuthState, payload: {filters: DashboardFilters}}) {
    try {
        console.log(`DashboardSaga:fetchPatientsSummary`)
        yield put(callInProgress());
        yield put(fetchPatientsSummaryInProgress());
        const apiFetchPatientsSummaryResponse: Either<CheckedError, {patientsSummary: PatientSummaryResponseType}> = yield call(apiFetchPatientsSummary, action)
        if(apiFetchPatientsSummaryResponse.isLeft()) {
            const error = apiFetchPatientsSummaryResponse.left()
            if(error.isChecked) {
                console.log("DashboardSaga: Encountered a Checked Error", error.message)
                yield put(fetchPatientsSummaryFailed({error: error.message}));
            } else {
                yield put(fetchPatientsSummaryFailed({error: error.message}));
            }
        } else {
            const patientsSummary = apiFetchPatientsSummaryResponse.right().patientsSummary
            yield put(fetchedPatientsSummary(patientsSummary));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchPatientsSummaryFailed({error: "Error fetching patients summary!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchPatientsSummaryByLocation(action: { type: string,  authState: AuthState, payload: {filters: DashboardFilters}}) {
    try {
        console.log(`DashboardSaga:fetchPatientsSummaryByLocation`)
        yield put(callInProgress())
        const apiFetchPatientsSummaryByLocResponse: Either<CheckedError, {patientsSummaryByLocation: AggregatePatientSummaryByLocation[]}> = yield call(apiFetchPatientsSummaryByLocation, action)
        if(apiFetchPatientsSummaryByLocResponse.isLeft()) {
            const error = apiFetchPatientsSummaryByLocResponse.left()
            if(error.isChecked) {
                console.log("DashboardSaga: Encountered a Checked Error", error.message)
                yield put(fetchPatientsSummaryByLocationFailed({error: error.message}));
            } else {
                yield put(fetchPatientsSummaryByLocationFailed({error: error.message}));
            }
        } else {
            const patientsSummaryByLocation = apiFetchPatientsSummaryByLocResponse.right().patientsSummaryByLocation
            yield put(fetchedPatientsSummaryByLocation(patientsSummaryByLocation));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchPatientsSummaryByLocationFailed({error: "Error fetching patients summary by location!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchPatientsScreenerCompletionRate(action: { type: string,  authState: AuthState, payload: {filters: DashboardFilters}}) {
    try {
        console.log(`DashboardSaga:fetchPatientsScreenerCompletionRate`)
        yield put(callInProgress())
        const apiFetchPatientsScreenerCompletionRateResponse: Either<CheckedError, {patientsScreenerCompletionRate: ScreenerCompletionRate[]}> = yield call(apiFetchPatientsScreenerCompletionRate, action)
        if(apiFetchPatientsScreenerCompletionRateResponse.isLeft()) {
            const error = apiFetchPatientsScreenerCompletionRateResponse.left()
            if(error.isChecked) {
                console.log("DashboardSaga: Encountered a Checked Error", error.message)
                yield put(fetchPatientsScreenerCompletionRateFailed({error: error.message}));
            } else {
                yield put(fetchPatientsScreenerCompletionRateFailed({error: error.message}));
            }
        } else {
            const patientsScreenerCompletionRate = apiFetchPatientsScreenerCompletionRateResponse.right().patientsScreenerCompletionRate
            yield put(fetchedPatientsScreenerCompletionRate(patientsScreenerCompletionRate));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchPatientsScreenerCompletionRateFailed({error: "Error fetching patients screener completion rate!"}));
    } finally {
        yield put(callFinished())
    }
}

export default function* patientsDashboardSaga() {
    yield takeLatest(FETCH_DASHBOARD_PATIENTS_SUMMARY, fetchPatientsSummary)
    yield takeLatest(FETCH_DASHBOARD_PATIENTS_SUMMARY_BY_LOCATION, fetchPatientsSummaryByLocation)
    yield takeLatest(FETCH_DASHBOARD_PATIENTS_SCREENER_COMPLETEION_RATE, fetchPatientsScreenerCompletionRate)

}
