import React, {useEffect, useRef, useState} from 'react'
import {ChevronLeftIcon} from '@heroicons/react/24/outline'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {StateParams} from '../../store/reducers'
import {IBillingPatient} from '../../models'
import Pagination from '../../components/generic/Pagination'
import {
    downloadBillingReport,
    fetchBillingPatients,
    removeBillingReportDownloadedLink
} from '../../store/actions/physician/patients.action'
import BhScreening from '../../components/billing/BhScreening'
import {DateTime} from 'luxon'
import {RoutePaths} from "../../shared/Utils";
import PaginationV2 from "../../components/generic/PaginationV2";
import TableFilterPaginationV2 from "../../components/generic/TableFilterPaginationV2";
import {Button} from "../../components/generic/Buttons";
import {Tooltip} from 'react-tooltip'

const BhScreeningScreen = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const fetchedBillingPatients = useSelector((state: StateParams) => state.physicianPatients.billingPatients)
    const {fetchBillingPatientsInProgress} = useSelector((state: StateParams) => state.physicianPatients)
    const {
        billingReportExportLink,
        billingReportFileName
    } = useSelector((state: StateParams) => state.physicianPatients)
    const {billingRecordsCount} = useSelector((state: StateParams) => state.physicianPatients)
    const [billingPatients, setBillingPatients] = useState<IBillingPatient[]>([]);
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 1);
    const initialStartDate = DateTime.local().startOf('month').toISO({includeOffset: false});
    const [startDate, setStartDate] = useState<string | null>(initialStartDate)
    const initialEndDate = DateTime.local().endOf('day').toISO({includeOffset: false});
    const [endDate, setEndDate] = useState<string | null>(initialEndDate)
    const [downloadButtonClicked, setDownloadButtonClicked] = useState(false)

    useEffect(() => {
        if (searchParams) {
            setCurrentPage(Number(searchParams.get("page")))
        }
    }, [searchParams])

    useEffect(() => {
        if (fetchedBillingPatients) {
            setBillingPatients(fetchedBillingPatients)
        }
    }, [fetchedBillingPatients])

    useEffect(() => {
        if (currentPage || recordsPerPage || searchText || (startDate && endDate)) {
            dispatch(fetchBillingPatients(currentPage, recordsPerPage, searchText, startDate, endDate))
        }
    }, [currentPage, recordsPerPage, searchText]);

    useEffect(() => {
        navigate(`${RoutePaths.physicianCare.bhScreening}?page=${currentPage}`)
    }, [currentPage]);

    useEffect(() => {
        if (downloadButtonClicked && billingReportExportLink && billingReportFileName) {
            setDownloadButtonClicked(false)
            window.open(billingReportExportLink)
            dispatch(removeBillingReportDownloadedLink())
        }
    }, [downloadButtonClicked, billingReportExportLink])

    const onChangeValue = (e: any) => {
        if (e.target.id === 'startDate') {
            const isoStartDate = DateTime.fromISO(e.target.value).toLocal().toFormat('yyyy-MM-dd\'T\'HH:mm:ss.SSS');
            setStartDate(isoStartDate)
        } else {
            const isoEndDate = DateTime.fromISO(e.target.value).endOf('day').toLocal().toFormat('yyyy-MM-dd\'T\'HH:mm:ss.SSS');
            setEndDate(isoEndDate)
        }
    }

    const handleExport = (e: any) => {
        if (billingRecordsCount && billingRecordsCount > 0 && startDate && endDate) {
            setDownloadButtonClicked(true)
            dispatch(downloadBillingReport(currentPage, recordsPerPage, searchText, startDate, endDate))
        }
    }

    const handleApply = () => {
        if (startDate && endDate) {
            setCurrentPage(1)
            dispatch(fetchBillingPatients(currentPage, recordsPerPage, searchText, startDate, endDate))
        }
    }

    return (
        <div className={"w-full p-4 h-full bg-[#F7F8FA]"}>
            <div className="flex flex-row justify-between">
                <div className='flex'>
                    <h1 className="text-xl"> Screener Billing</h1>
                </div>
                <div className='flex flex-row gap-x-2 items-center'>
                    {startDate && endDate &&
                        <input id='startDate'
                               type='date'
                               required
                               max={DateTime.fromISO(endDate).toFormat('yyyy-MM-dd')}
                               value={DateTime.fromISO(startDate).toFormat('yyyy-MM-dd')}
                               onChange={(e) => onChangeValue(e)}
                               className={"w-full rounded-md h-9 border-gray-300 focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"}/>
                    }
                    <span>to</span>
                    {endDate && startDate &&
                        <input id='endDate'
                               type='date'
                               required
                               value={DateTime.fromISO(endDate).toFormat('yyyy-MM-dd')}
                               min={DateTime.fromISO(startDate).toFormat('yyyy-MM-dd')}
                               max={DateTime.now().toFormat('yyyy-MM-dd')}
                               onChange={(e) => onChangeValue(e)}
                               className={"w-full rounded-md h-9 border-gray-300 focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"}/>

                    }
                    <Tooltip style={{borderRadius: 5}} id="invalid-date-selection-tooltip"
                             hidden={!!(startDate && DateTime.fromISO(startDate).isValid) && !!(endDate && DateTime.fromISO(endDate).isValid)}
                    >
                        <div>
                            Please select
                            {startDate && !DateTime.fromISO(startDate).isValid && ' start date'} {startDate && !DateTime.fromISO(startDate).isValid && endDate && !DateTime.fromISO(endDate).isValid && 'and'} {endDate && !DateTime.fromISO(endDate).isValid && 'end date'}
                        </div>
                    </Tooltip>
                    <Button
                        data-tooltip-id="invalid-date-selection-tooltip"
                        className="!bg-sjOrange !text-white"
                        disabled={startDate && !DateTime.fromISO(startDate).isValid || endDate && !DateTime.fromISO(endDate).isValid}
                        onClick={handleApply}>Apply</Button>
                    <Button
                        data-tooltip-id="invalid-date-selection-tooltip"
                        className="!bg-sjOrange !text-white"
                        disabled={startDate && !DateTime.fromISO(startDate).isValid || endDate && !DateTime.fromISO(endDate).isValid}
                        onClick={handleExport}>Download</Button>
                </div>
                <div className="flex justify-end items-center">
                    <TableFilterPaginationV2
                        setTableFilter={setSearchText}
                        totalRows={billingRecordsCount || 0}
                        currentPageHandler={setCurrentPage}
                    />
                </div>
            </div>
            {<BhScreening patients={billingPatients} searchInProgress={fetchBillingPatientsInProgress}/>}
            {billingPatients && billingPatients.length > 0 &&
                <PaginationV2
                    totalRows={billingRecordsCount || 0}
                    rowsPerPage={recordsPerPage}
                    recordsPerPageHandler={setRecordsPerPage}
                    currentPage={currentPage}
                    currentPageHandler={setCurrentPage}
                    showPages={false}
                />}
        </div>
    )
}

export default BhScreeningScreen
