import React, {useContext, useEffect, useState} from "react";
import {AppointmentStatus, IAppointmentWithProspect, PatientSessionTypes} from "../../models";
import {activateZoomSession, clearZoomJwtToken} from "../../store/actions/counsellor/account.action";
import {useDispatch, useSelector} from "react-redux";
import {searchAppointments} from "../../store/actions/counsellor/appointments.action";
import {StateParams} from "../../store/reducers";
import {deduceActionStatus, RoutePaths} from "../../shared/Utils";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import AppointmentTable from "./AppointmentTable";
import TableFilterPaginationV2 from "../generic/TableFilterPaginationV2";
import PaginationV2 from "../generic/PaginationV2";
import {PageButtonV2} from "../generic/Buttons";
import ZoomContext from "../../context/ZoomContext";

const Appointments : React.FC = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 1);
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [statusFilters, setStatusFilters] = useState<string[]>([])
    const [clearFilters, setClearFilters] = useState<boolean>(false)
    const {appointments, appointmentsCount, fetchAppointmentsInProgress} = useSelector((state: StateParams) => state.appointments)
    const [filteredAppointments, setFilteredAppointments] = useState<IAppointmentWithProspect[]>([]);
    const isZoomSessionActive = useSelector((state: StateParams) => state.practitionerAccount.isZoomSessionActive)
    const zmClient = useContext(ZoomContext);
    const [sessionTypes, setSessionTypes] = useState([
        { key: "Follow_Up", value: "Follow Up ( 30m )" },
        { key: "Treatment_Plan", value: "Treatment Plan ( 30m )" },
        { key: PatientSessionTypes.Catchup, value: "Catchup ( 30m )" }
    ])

    useEffect(() => {
        if (isZoomSessionActive && currentPage) {
            setZoomSessionInActive()
        }
    }, [currentPage]);

    const setZoomSessionInActive = async () => {
        dispatch(activateZoomSession({ canActivate: false }))
        if (zmClient.getSessionInfo().isInMeeting) {
            console.log('You have left the session.')
            await zmClient.leave()
        }
    }


    useEffect(() => {
        dispatch(clearZoomJwtToken())
    }, [])


    useEffect(() => {
        if(currentPage || recordsPerPage) {
            dispatch(searchAppointments(currentPage, recordsPerPage, searchText, { status: statusFilters.length > 0 ? statusFilters : undefined}))
        }
    }, [currentPage, recordsPerPage, searchText, statusFilters]);

    useEffect(()=>{
        if(searchParams) {
            setCurrentPage(Number(searchParams.get("page")) || 1)
        }
    },[searchParams])

    useEffect(() => {
        if (appointments) {
            const filteredByStatusAppointments = statusFilters.includes("scheduled")
                ? appointments.filter((appointment) => deduceActionStatus(appointment) !== AppointmentStatus.expired)
                : appointments
            setFilteredAppointments(filteredByStatusAppointments)
        }
    }, [appointments])

    useEffect(() => {
        navigate(`/${RoutePaths.collaborativeCare.appointments.root}?page=${currentPage}`)
    }, [currentPage]);

    const handleSelectedFilters = (status: string) => {
        setCurrentPage(1)
        if(status === "all") {
            setStatusFilters([])
            return
        }
        setStatusFilters([status])
    }

    return <div className="w-full p-4 h-full bg-[#F7F8FA]">
            <div className={"flex justify-between pb-4 items-center"}>
                <h1 className="text-xl">Appointments</h1>
                <div className="flex gap-x-2 justify-end items-center">
                    <div className="inline-flex rounded-md shadow-sm">
                        <a href="#" aria-current="page"
                           onClick={() => handleSelectedFilters("all")}
                           className={`${statusFilters.length === 0 ? "bg-sjOrange text-white" : "bg-sjWhite text-black"} px-4 py-2 text-sm font-medium rounded-s-lg border border-gray-200`}>
                            All
                        </a>
                        <a href="#"
                           onClick={() => handleSelectedFilters("scheduled")}
                           className={`${statusFilters.includes("scheduled") ? "bg-sjOrange text-white" : "bg-sjWhite text-black"} px-4 py-2 text-sm font-medium border border-gray-200`}>
                            Scheduled
                        </a>
                        <a href="#"
                           onClick={() => handleSelectedFilters("no_show")}
                            className={`${statusFilters.includes("no_show") ? "bg-sjOrange text-white" : "bg-sjWhite text-black"} px-4 py-2 text-sm font-medium rounded-e-lg border border-gray-200`}>
                            No Show
                        </a>
                    </div>
                    <TableFilterPaginationV2
                        setTableFilter={setSearchText}
                        totalRows={appointmentsCount || 0}
                        currentPageHandler={setCurrentPage}
                    />
                    <Link to={"create"} state={{ screen: 'Appointments', sessionTypes, defaultSessionType: 'Treatment_Plan' }}>
                        <PageButtonV2
                            className={"!bg-sjOrange !text-white !ml-2 px-[16px] py-[8px] !h-8 text-sm"}
                        >
                            <div className={"flex flex-row gap-x-2 items-center"}>
                                <span>Create Session</span>
                            </div>
                        </PageButtonV2>
                    </Link>
                </div>
            </div>
            <AppointmentTable appointments={filteredAppointments} searchInProgress={fetchAppointmentsInProgress}/>
            {filteredAppointments && filteredAppointments.length > 0 &&
                <PaginationV2
                    totalRows={appointmentsCount || 0}
                    rowsPerPage={recordsPerPage}
                    recordsPerPageHandler={setRecordsPerPage}
                    currentPage={currentPage}
                    currentPageHandler={setCurrentPage}
                    showPages={false}
                />
            }
        </div>
}

    export default Appointments
