import {useNavigate} from "react-router";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {generateZoomToken} from "../../store/actions/counsellor/account.action";
import {StateParams} from "../../store/reducers";

const ZoomHomeScreen: React.FC<{}> = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [sessionName, setSessionName] = useState('')
    const [sessionPassword, setSessionPassword] = useState('')
    const [displayName, setDisplayName] = useState('')
    const [sessionIdleTimeoutMins, setSessionIdleTimeoutMins] = useState('40')
    const [roleType, setRoleType] = useState('1')
    const token = useSelector((state: StateParams) => state.practitionerAccount.token)
    
    const onCreateSession = () => {
        if (sessionName && token) {
            dispatch(generateZoomToken({ sessionName: sessionName, roleType: roleType, token }))
        }
        setTimeout(() => {
            navigate('/call', {
                state: {
                    sessionName,
                    sessionPassword,
                    displayName,
                    sessionIdleTimeoutMins,
                    roleType
                }
            })
        }, 2000)
    }
    return (
        <div className="flex h-screen flex-col">
            <main className="flex flex-1 justify-center items-center">
                {/* <Button className="rounded-l-md bg-orange-100" onClick={() => navigate('/join', {state: { isJoin: true }})}>
                    Join Session
                </Button> */}
                <div className="mt-8 w-full">
                    <div className="grid grid-cols-3 gap-6">
                        <div></div>
                        <div className="pt-10">
                            <label className="block">
                                <span className="text-gray-700">Session name</span>
                                <input
                                    type="text"
                                    autoComplete={"off"}
                                    autoCorrect={"off"}
                                    autoCapitalize={"off"}
                                    className="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { 
                                        setSessionName(event.target.value)
                                    }}
                                />
                            </label>
                            <label className="block">
                                <span className="text-gray-700">Display name</span>
                                <input
                                    type="text"
                                    autoComplete={"off"}
                                    autoCorrect={"off"}
                                    autoCapitalize={"off"}
                                    className="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { 
                                        setDisplayName(event.target.value)
                                    }}
                                />
                            </label>
                            <div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                    <div className="mt-10 grid grid-cols-3 gap-6">
                        <div></div>
                        <div className="flex justify-center items-center">
                            <button
                                className="bg-sj2 hover:bg-sjOrange text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="button"
                                onClick={onCreateSession}>
                                Create Session
                            </button>
                        </div>
                        <div></div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default ZoomHomeScreen