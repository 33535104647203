interface Props {
    message: string
    className: string
}

const Message: React.FC<Props> = ({
    message,
    className
}) => {
    return (
        <p className={`pt-2 ${className}`}>{message}</p>
    )
}

export default Message
