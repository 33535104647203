import { Card, CardHeader, Radio } from "@material-tailwind/react"
import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { StateParams } from "../../../store/reducers";
import { IAssignedScreeners, ScreenerProviderType } from '../../../models'
import {
    fetchAssignedScreeners,
    fetchScreenerQAndA,
    saveScreenerAnswer
} from "../../../store/actions/counsellor/patients.action";
import { DateTime } from "luxon";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { fetchPatientQAndA } from "../../../store/actions/physician/patients.action";
import { SLUG_TO_TAB_MAP, TYPE_SLUG } from "../../screeners/ScreenerResults";

const ProgressNotesScreeners: React.FC<{ sourcePatientId: string, treatmentPlanId: string }> = ({ sourcePatientId, treatmentPlanId }) => {
    const dispatch = useDispatch()
    const { assignedScreeners, screenerQAndA } = useSelector((state: StateParams) => state.patients, shallowEqual)
    const screenerQuestionAnswers = useSelector((state: StateParams) => state.physicianPatients.screenerQuestionAnswers)
    const [iconOpen, setIconOpen] = useState(0)
    const [answeredQuestionsCount, setAnsweredQuestionsCount] = useState({
        count: new Set()
    })

    useEffect(() => {
        const patientId = sourcePatientId
        const treatmentId = treatmentPlanId
        const providerType = ScreenerProviderType.therapist
        if (patientId && treatmentId) {
            dispatch(fetchAssignedScreeners(patientId, treatmentId, providerType))
        }
    }, [sourcePatientId, treatmentPlanId])

    const handleOpen = (index: number, userScreenerId?: string, screenerConditionId?: string|null) => {
        const patientId = sourcePatientId
        if (patientId && userScreenerId && screenerConditionId !== undefined) {
            dispatch(fetchScreenerQAndA(patientId, userScreenerId, screenerConditionId))
            dispatch(fetchPatientQAndA(patientId, userScreenerId))
        }
        setIconOpen(iconOpen === index ? 0 : index)
    }

    const onSave = (event: any, questionId: string, userScreenerId: string) => {
        const answerId = event.target.value
        const patientId = sourcePatientId

        const answeredQuestions = screenerQuestionAnswers?.[userScreenerId] || []
        for(const answeredQuestion of answeredQuestions) {
            setAnsweredQuestionsCount({
                count: answeredQuestionsCount.count.add(answeredQuestion.questionId)
            })
        }
        answeredQuestionsCount.count.add(questionId)
        const screenerAnsweredQuestions = answeredQuestionsCount.count.size || 0
        const totalQuestions = screenerQAndA?.allScreenerQuestions?.length || 0
        const screenerStatus = screenerAnsweredQuestions === totalQuestions ? 'finished' : screenerAnsweredQuestions === 1 ? 'in_progress' : undefined

        if (patientId && answerId && questionId && userScreenerId) {
            dispatch(saveScreenerAnswer({patientId, userScreenerId, questionId, answerId, screenerStatus}))
        }
    }

    const slugMap = (slug: string) => {
        const screenerContent = SLUG_TO_TAB_MAP[slug as TYPE_SLUG]
        return screenerContent
    }

    return (
        <div>
            {assignedScreeners && assignedScreeners.length > 0 &&
                <section style={{ backgroundColor: '#FFFFFF' }} className={`w-full intake-section mt-6 p-10  min-h-[25vh] rounded-lg shadow-lg`}>
                    {assignedScreeners.map((screener: IAssignedScreeners, index: number) => (
                        !screener.isComplete && !screener.isExpired && <div className="flex direction-row items-center">
                            <Card className="w-full mt-4 border border-[#E5E8EB]">
                                <CardHeader className="">
                                    <div className="p-4 flex flex-row justify-between">
                                        <div className="">
                                            <div className="text-slate-400 text-sm">Screener</div>
                                            <div className="text-md">{slugMap(screener.screener.slug)}</div>
                                            <div className="text-xs">{screener.screenerCondition ?  `(${screener.screenerCondition.conditionDisplayName})` : `(${screener.screener.content})`}</div>
                                        </div>
                                        <div className="">
                                            <div className="text-slate-400 text-sm">Assigned on</div>
                                            <div>{DateTime.fromISO(screener.createdAt.toString(), { zone: 'utc' }).toFormat('MMM-dd-yyyy')}</div>
                                        </div>
                                        <div className="flex self-center">
                                            <div className="text-sjLink">View all questions</div>
                                            {iconOpen === index + 1 ? <ChevronUpIcon className="h-5 w-5 m-1 cursor-pointer" aria-hidden="true"
                                                onClick={() => handleOpen(index + 1)} /> : <ChevronDownIcon className="h-5 w-5 m-1 cursor-pointer" aria-hidden="true"
                                                    onClick={() => handleOpen(index + 1, screener.id, screener.mentalConditionId)} />

                                            }
                                        </div>
                                    </div>
                                    {iconOpen === index + 1 && screenerQAndA && screenerQAndA.allScreenerQuestions?.map((question, ind) => (
                                        <div className="p-2">
                                            <hr className="border-y-sjGrey-100" />
                                            <div key={index}>
                                                <div>
                                                    {question.content}
                                                </div>
                                                <div>
                                                    {question.screenerAnswers.map((answer, i) => (
                                                        <Radio
                                                            id={answer.id}
                                                            key={answer.id}
                                                            name={question.id}
                                                            className="text-sjOrange"
                                                            label={answer.content}
                                                            value={answer.id}
                                                            data-userScreenerId={screener.id}
                                                            onClick={event => onSave(event, question.id, screener.id)}
                                                            defaultChecked={
                                                                screenerQuestionAnswers && screenerQuestionAnswers[screener.id].some(answeredQuestion => answeredQuestion.answerId === answer.id)
                                                            }
                                                        />
                                                    )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    )}
                                </CardHeader>
                            </Card>
                        </div>
                    ))}
                </section>
            }
        </div>
    )
}

export default ProgressNotesScreeners