import { call, put, takeLatest } from "@redux-saga/core/effects";
import { getAuthHeaderConfig, physicianHttpClient } from "../../../lib";
import { Either } from "monet";
import { CheckedError, ServiceError } from "../../../types/ServiceError";
import {
    AuthState, ISOAPNoteContentResponse, ISOAPNoteListItemResponse, ISOAPNotePatients, ISOAPNoteResponse, IUser,
    IUsers,
    LocationIndex,
    LocationIndexItem,
    LocationIndexRequest,
    SoapNotePatientsFilters,
    UserIndexRequest,
} from "../../../models";
import { AxiosError, AxiosResponse } from "axios";
import { asyncCallFinished, asyncCallInProgress, callFinished, callInProgress } from "../../actions/loading.action";
import {
    CANCEL_POLLING,
    EDIT_SOAP_NOTE_CONTENT,
    EDIT_SOAP_NOTE_REASON,
    editedSoapNoteContent,
    editedSoapNoteReason,
    editSoapNoteContentFailed,
    editSoapNoteContentInProgress,
    editSoapNoteReasonFailed,
    editSoapNoteReasonInProgress,
    FETCH_LOCATION,
    FETCH_LOCATIONS, FETCH_SOAP_NOTE_CONTENT, FETCH_SOAP_NOTE_PATIENTS, FETCH_SOAP_NOTES, FETCH_USERS,
    fetchedLocation,
    fetchedLocations, fetchedSoapNoteContent, fetchedSoapNotePatients, fetchedSoapNotes, fetchedUsers,
    fetchLocationFailed,
    fetchLocationInProgress,
    fetchLocationsFailed, fetchSoapNoteContentFailed, fetchSoapNoteContentInProgress, fetchSoapNotePatientsFailed, fetchSoapNotePatientsInProgress, fetchSoapNotesFailed, fetchSoapNotesInProgress, fetchUsersFailed,
    INITIATE_LOCATION_PHONE_VERIFICATION,
    initiatedLocationPhoneVerification,
    initiateLocationPhoneVerificationFailed, MARK_SOAP_NOTE_AS_READ, markedSoapNoteAsRead, markSoapNoteAsReadFailed, resetSaveUser,
    SAVE_LOCATION, SAVE_USER,
    savedLocation, savedUser,
    saveLocationFailed, saveUserFailed
} from "../../actions/physician/admin-operations.action";
import { delay, race, take } from "redux-saga/effects";
import { createQueryString } from "../../../lib/api-helpers";

const POLLING_INTERVAL = 10000 //in millseconds

const apiFetchLocations = (action: {
    type: string,
    payload: { request: LocationIndexRequest },
    authState: AuthState
}) => {
    const request = action.payload.request
    const url = `/${action.authState.accountId}/locations?${createQueryString(request)}${request.status === false ? "&status=false" : ""}`;
    return physicianHttpClient.get<Either<CheckedError, LocationIndex>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as LocationIndex)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}
const apiFetchLocation = (action: { type: string, payload: { locationId: string }, authState: AuthState }) => {
    const url = `/${action.authState.accountId}/locations/${action.payload.locationId}`;
    return physicianHttpClient.get<Either<CheckedError, LocationIndex>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as LocationIndex)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiCreateLocation = (action: {
    type: string,
    payload: { location: Partial<LocationIndexItem> },
    authState: AuthState
}) => {
    const url = `/${action.authState.accountId}/locations`;
    return { actionMethod: physicianHttpClient.post, url }
}
const apiUpdateLocation = (action: {
    type: string,
    payload: { location: Partial<LocationIndexItem> },
    authState: AuthState
}) => {
    const url = `/${action.authState.accountId}/locations/${action.payload.location.id}`;
    return { actionMethod: physicianHttpClient.put, url }
}
const apiSaveLocation = (action: {
    type: string,
    payload: { location: Partial<LocationIndexItem> },
    authState: AuthState
}) => {
    const { actionMethod, url } = action.payload.location.id ? apiUpdateLocation(action) : apiCreateLocation(action)
    return actionMethod<Either<CheckedError, LocationIndexItem>>(url, action.payload.location, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as LocationIndexItem)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiInitiateLocationPhoneVerification = (action: {
    type: string,
    payload: { locationId: string, phoneNumber: string },
    authState: AuthState
}) => {
    const url = `/${action.authState.accountId}/initiate-phone-verification/`;
    const body = {
        locationId: action.payload.locationId,
        phoneNumber: action.payload.phoneNumber
    }
    return physicianHttpClient.put<Either<CheckedError, {
        code: string
    }>>(url, body, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as LocationIndexItem)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || 'Error initiating phone verification!';
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during PUT process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        })
}

const apiFetchSOAPNotePatients = (action: { type: string, payload: { request: SoapNotePatientsFilters }, authState: AuthState }) => {
    const request = action.payload.request;
    const url = `/soap-note/patients?${createQueryString(request)}`;
    return physicianHttpClient.get<Either<CheckedError, ISOAPNotePatients>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data?.data as ISOAPNotePatients)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchSoapNotes = (action: { type: string, patientId: string, authState: AuthState }) => {
    const patientId = action.patientId;
    const url = `/soap-notes?patientId=${patientId}`;
    return physicianHttpClient.get<Either<CheckedError, ISOAPNoteListItemResponse>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data?.data as ISOAPNoteListItemResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiMarkSoapNoteAsRead = (action: { type: string, soapId: string, authState: AuthState }) => {
    const soapId = action.soapId;
    const url = `/soap-note/${soapId}/mark-as-read`;
    return physicianHttpClient.patch<Either<CheckedError, ISOAPNoteResponse>>(url, {}, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data?.data as ISOAPNoteResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiSOAPNoteContent = (action: { type: string, soapId: string, authState: AuthState }) => {
    const soapId = action.soapId;
    const url = `/soap-note/${soapId}`;
    return physicianHttpClient.get<Either<CheckedError, ISOAPNoteContentResponse>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data?.data as ISOAPNoteContentResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiEditSOAPNote = (action: { type: string, payload: { soapId: string, content: object }, authState: AuthState }) => {
    const soapId = action.payload.soapId;
    const content = action.payload.content;
    const url = `/soap-note/${soapId}`;
    return physicianHttpClient.patch<Either<CheckedError, ISOAPNoteContentResponse>>(url, content, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data?.data as ISOAPNoteContentResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* fetchLocationsByPractice(action: {
    type: string,
    payload: { request: LocationIndexRequest },
    authState: AuthState
}) {
    try {
        yield put(fetchLocationInProgress())
        const apiFetchLocationResponse: Either<CheckedError, LocationIndex> = yield call(apiFetchLocations, action)
        if (apiFetchLocationResponse.isLeft()) {
            const error = apiFetchLocationResponse.left()
            if (error.isChecked) {
                console.log("Physician:Locations: Encountered a Checked Error", error.message)
                yield put(fetchLocationsFailed({ error: error.message }));
            } else {
                yield put(fetchLocationsFailed({ error: error.message }));
            }
        } else {
            const { locations, count } = apiFetchLocationResponse.right();
            yield put(fetchedLocations(locations, count, 'locations'));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchLocationsFailed({ error: "Error fetching patients!" }));
    } finally {
        // yield put(callFinished())
    }
}

function* fetchLocationByPractice(action: { type: string, payload: { locationId: string }, authState: AuthState }) {
    try {
        console.log(`Physician:Locations:fetchLocation`)
        yield put(callInProgress())
        const apiFetchLocationResponse: Either<CheckedError, LocationIndex> = yield call(apiFetchLocation, action)
        if (apiFetchLocationResponse.isLeft()) {
            const error = apiFetchLocationResponse.left()
            if (error.isChecked) {
                console.log("Physician:Locations: Encountered a Checked Error", error.message)
                yield put(fetchLocationFailed({ error: error.message }));
            } else {
                yield put(fetchLocationFailed({ error: error.message }));
            }
        } else {
            const location = apiFetchLocationResponse.right();
            yield put(fetchedLocation(location.locations[0]));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchLocationFailed({ error: "Error fetching patients!" }));
    } finally {
        yield put(callFinished())
    }
}

function* saveLocationByPractice(action: {
    type: string,
    payload: { location: Partial<LocationIndexItem> },
    authState: AuthState
}) {
    try {
        console.log(`Physician:Locations:saveLocation`)
        yield put(callInProgress())
        const apiSaveLocationResponse: Either<CheckedError, LocationIndexItem> = yield call(apiSaveLocation, action)
        if (apiSaveLocationResponse.isLeft()) {
            const error = apiSaveLocationResponse.left()
            if (error.isChecked) {
                console.log("Physician:Locations: Encountered a Checked Error", error.message)
                yield put(saveLocationFailed({ error: error.message }));
            } else {
                yield put(saveLocationFailed({ error: error.message }));
            }
        } else {
            const location = apiSaveLocationResponse.right();
            yield put(savedLocation(location));
        }
    } catch (e) {
        console.log(e)
        yield put(saveLocationFailed({ error: "Error saving location!" }));
    } finally {
        yield put(callFinished())
    }
}

function* initiateLocationPhoneVerification(action: {
    type: string,
    payload: { locationId: string, phoneNumber: string },
    authState: AuthState
}) {
    try {
        console.log(`Physician:Locations:initiateLocationPhoneVerification`)
        yield put(asyncCallInProgress())
        const apiInitiateLocationPhoneVerificationResponse: Either<CheckedError, {
            code: string
        }> = yield call(apiInitiateLocationPhoneVerification, action)
        if (apiInitiateLocationPhoneVerificationResponse.isLeft()) {
            const error = apiInitiateLocationPhoneVerificationResponse.left()
            if (error.isChecked) {
                console.log("Physician: Initiate Location Phone Verification: Encountered a Checked Error", error.message)
                yield put(initiateLocationPhoneVerificationFailed({ error: error.message || 'Error initiating phone verification!' }));
            } else {
                yield put(initiateLocationPhoneVerificationFailed({ error: error.message || 'Error initiating phone verification!' }));
            }
        } else {
            const response = apiInitiateLocationPhoneVerificationResponse.right();
            yield put(initiatedLocationPhoneVerification(response.code));
            yield call(fetchPhoneVerificationWorker, action);
        }
    } catch (e) {
        console.log(e)
        yield put(initiateLocationPhoneVerificationFailed({ error: "Error initiating phone verification!" }));
    } finally {
        yield put(asyncCallFinished())
    }
}

export function* fetchPhoneVerificationWorker(action: {
    type: string,
    payload: { locationId: string },
    authState: AuthState
}) {
    yield race({
        task: call(fetchLocationPollingWorker, action),
        cancel: take(CANCEL_POLLING)
    })
}

export function* fetchLocationPollingWorker(action: {
    type: string,
    payload: { locationId: string },
    authState: AuthState
}) {
    while (true) {
        try {
            console.log('fetchLocationPollingWorker')
            const apiFetchLocationResponse: Either<CheckedError, LocationIndex> = yield call(apiFetchLocation, action)
            if (apiFetchLocationResponse.isLeft()) {
                const error = apiFetchLocationResponse.left()
                if (error.isChecked) {
                    console.log("Physician:Locations: Encountered a Checked Error", error.message)
                    yield put(fetchLocationFailed({ error: error.message }));
                } else {
                    yield put(fetchLocationFailed({ error: error.message }));
                }
            } else {
                const location = apiFetchLocationResponse.right();
                yield put(fetchedLocation(location.locations[0]));
            }
            yield delay(POLLING_INTERVAL);
        } catch (e) {
            console.error(e)
            yield put({ type: CANCEL_POLLING })
        }
    }
}

// apiFetchUsers
const apiFetchUsers = (action: { type: string, payload: { request: UserIndexRequest }, authState: AuthState }) => {
    const request = action.payload.request;
    const url = `/${action.authState.accountId}/users?${createQueryString(request)}`;
    return physicianHttpClient.get<Either<CheckedError, IUsers>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data?.data as IUsers)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiEditSOAPNoteReason = (action: { type: string, payload: { soapId: string, reason: object }, authState: AuthState }) => {
    const soapId = action.payload.soapId;
    const reason = action.payload.reason;
    const url = `/soap-note/${soapId}`;
    return physicianHttpClient.put<Either<CheckedError, ISOAPNoteResponse>>(url, reason, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data?.data as ISOAPNoteResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

// fetchUsersByPractice
function* fetchUsersByPractice(action: { type: string, payload: { request: UserIndexRequest }, authState: AuthState }) {
    console.log(`Physician:Users:fetchUsers`);
    try {
        console.log(`Physician:Users:fetchUsers`)
        yield put(callInProgress())
        const apiFetchUserResponse: Either<CheckedError, IUsers> = yield call(apiFetchUsers, action)
        if (apiFetchUserResponse.isLeft()) {
            const error = apiFetchUserResponse.left()
            if (error.isChecked) {
                console.log("Physician:Users: Encountered a Checked Error", error.message)
                yield put(fetchUsersFailed({ error: error.message }));
            } else {
                yield put(fetchUsersFailed({ error: error.message }));
            }
        } else {
            const { users, count } = apiFetchUserResponse.right();
            yield put(fetchedUsers(users, count, 'users'));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchUsersFailed({ error: "Error fetching providers!" }));
    } finally {
        yield put(callFinished())
    }
}

const apiSaveUser = (action: { type: string, payload: { user: Partial<IUser> }, authState: AuthState }) => {
    const isEdit = action.payload.user.id ? true : false;
    const url = isEdit ? `/${action.authState.accountId}/users/${action.payload.user.id}` : `/${action.authState.accountId}/users`;
    const method = isEdit ? 'put' : 'post';
    return physicianHttpClient[method]<Either<CheckedError, IUser>>(url, action.payload.user, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IUser)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* saveUserByPractice(action: { type: string, payload: { user: Partial<IUser> }, authState: AuthState }) {
    try {
        console.log(`Physician:Users:saveUser`)
        yield put(callInProgress())
        const apiSaveUserResponse: Either<CheckedError, IUser> = yield call(apiSaveUser, action)
        if (apiSaveUserResponse.isLeft()) {
            const error = apiSaveUserResponse.left()
            if (error.isChecked) {
                console.log("Physician:Users: Encountered a Checked Error", error.message)
                yield put(saveUserFailed({ error: error.message }));
            } else {
                yield put(saveUserFailed({ error: error.message }));
            }
        } else {
            const user = apiSaveUserResponse.right();
            yield put(savedUser(user));
            delay(2000);
            yield put(resetSaveUser());
        }
    } catch (e) {
        console.log(e)
        yield put(saveUserFailed({ error: "Error saving location!" }));
    } finally {
        yield put(callFinished())
    }
}

function* fetchSOAPNotePatientsList(action: { type: string, payload: { request: SoapNotePatientsFilters }, authState: AuthState }) {
    try {
        yield put(fetchSoapNotePatientsInProgress())
        const fetchSOAPNotePatientsAPI: Either<CheckedError, ISOAPNotePatients> = yield call(apiFetchSOAPNotePatients, action)
        if (fetchSOAPNotePatientsAPI.isLeft()) {
            const error = fetchSOAPNotePatientsAPI.left()
            if (error.isChecked) {
                yield put(fetchSoapNotePatientsFailed({ error: error.message }));
            } else {
                yield put(fetchSoapNotePatientsFailed({ error: error.message }));
            }
        } else {
            const { SOAPNotePatients, count } = fetchSOAPNotePatientsAPI.right();
            yield put(fetchedSoapNotePatients(SOAPNotePatients, count));
        }
    }
    catch (e) {
        yield put(fetchSoapNotePatientsFailed({ error: "Error fetching soap note patients" }));
    } finally {
        yield put(callFinished())
    }
}

function* fetchSoapNotesList(action: { type: string, patientId: string, authState: AuthState }) {
    try {
        yield put(fetchSoapNotesInProgress())
        const fetchSoapNotesAPI: Either<CheckedError, ISOAPNoteListItemResponse> = yield call(apiFetchSoapNotes, action)
        if (fetchSoapNotesAPI.isLeft()) {
            const error = fetchSoapNotesAPI.left()
            if (error.isChecked) {
                yield put(fetchSoapNotesFailed({ error: error.message }));
            } else {
                yield put(fetchSoapNotesFailed({ error: error.message }));
            }
        } else {
            const { soapNoteList } = fetchSoapNotesAPI.right();
            yield put(fetchedSoapNotes(soapNoteList));
        }
    }
    catch (e) {
        yield put(fetchSoapNotesFailed({ error: "Error fetching soap note patients" }));
    } finally {
        // yield put(callFinished())
    }
}

function* markSOAPNoteAsRead(action: { type: string, soapId: string, authState: AuthState }) {
    try {
        const markSoapNoteAsReadAPI: Either<CheckedError, ISOAPNoteResponse> = yield call(apiMarkSoapNoteAsRead, action)
        if (markSoapNoteAsReadAPI.isLeft()) {
            const error = markSoapNoteAsReadAPI.left()
            if (error.isChecked) {
                yield put(markSoapNoteAsReadFailed({ error: error.message }));
            } else {
                yield put(markSoapNoteAsReadFailed({ error: error.message }));
            }
        } else {
            const { soapNote } = markSoapNoteAsReadAPI.right();
            yield put(markedSoapNoteAsRead(soapNote));
        }
    }
    catch (e) {
        yield put(markSoapNoteAsReadFailed({ error: "Error fetching soap note patients" }));
    } finally {
        yield put(callFinished())
    }
}

function* fetchSOAPNoteContent(action: { type: string, soapId: string, authState: AuthState }) {
    try {
        yield put(fetchSoapNoteContentInProgress())
        const fetchSoapNoteContentAPI: Either<CheckedError, ISOAPNoteContentResponse> = yield call(apiSOAPNoteContent, action)
        if (fetchSoapNoteContentAPI.isLeft()) {
            const error = fetchSoapNoteContentAPI.left()
            if (error.isChecked) {
                yield put(fetchSoapNoteContentFailed({ error: error.message }));
            } else {
                yield put(fetchSoapNoteContentFailed({ error: error.message }));
            }
        } else {
            const { SOAPNoteContent } = fetchSoapNoteContentAPI.right();
            yield put(fetchedSoapNoteContent(SOAPNoteContent));
        }
    }
    catch (e) {
        yield put(fetchSoapNoteContentFailed({ error: "Error fetching soap note patients" }));
    } finally {
        yield put(callFinished())
    }
}

function* editSOAPNoteContent(action: { type: string, payload: { soapId: string, content: object }, authState: AuthState }) {
    try {
        yield put(editSoapNoteContentInProgress())
        const fetchSoapNoteContentAPI: Either<CheckedError, ISOAPNoteContentResponse> = yield call(apiEditSOAPNote, action)
        if (fetchSoapNoteContentAPI.isLeft()) {
            const error = fetchSoapNoteContentAPI.left()
            if (error.isChecked) {
                yield put(editSoapNoteContentFailed({ error: error.message }));
            } else {
                yield put(editSoapNoteContentFailed({ error: error.message }));
            }
        } else {
            const { SOAPNoteContent } = fetchSoapNoteContentAPI.right();
            yield put(editedSoapNoteContent(SOAPNoteContent));
        }
    }
    catch (e) {
        yield put(editSoapNoteContentFailed({ error: "Error fetching soap note patients" }));
    } finally {
        yield put(callFinished())
    }
}

function* editSOAPNoteReason(action: { type: string, payload: { soapId: string, reason: object }, authState: AuthState }) {
    try {
        yield put(editSoapNoteReasonInProgress())
        const fetchSoapNoteContentAPI: Either<CheckedError, ISOAPNoteResponse> = yield call(apiEditSOAPNoteReason, action)
        if (fetchSoapNoteContentAPI.isLeft()) {
            const error = fetchSoapNoteContentAPI.left()
            if (error.isChecked) {
                yield put(editSoapNoteReasonFailed({ error: error.message }));
            } else {
                yield put(editSoapNoteReasonFailed({ error: error.message }));
            }
        } else {
            const { soapNote } = fetchSoapNoteContentAPI.right();
            console.log(soapNote)
            yield put(editedSoapNoteReason(soapNote));
        }
    }
    catch (e) {
        yield put(editSoapNoteContentFailed({ error: "Error fetching soap note patients" }));
    } finally {
        yield put(callFinished())
    }
}

export default function* physicianAdminOperationsSaga() {
    yield takeLatest(FETCH_LOCATIONS, fetchLocationsByPractice)
    yield takeLatest(FETCH_LOCATION, fetchLocationByPractice)
    yield takeLatest(SAVE_LOCATION, saveLocationByPractice)
    yield takeLatest(INITIATE_LOCATION_PHONE_VERIFICATION, initiateLocationPhoneVerification)
    yield takeLatest(FETCH_USERS, fetchUsersByPractice)
    yield takeLatest(SAVE_USER, saveUserByPractice)
    yield takeLatest(FETCH_SOAP_NOTE_PATIENTS, fetchSOAPNotePatientsList)
    yield takeLatest(FETCH_SOAP_NOTES, fetchSoapNotesList)
    yield takeLatest(MARK_SOAP_NOTE_AS_READ, markSOAPNoteAsRead)
    yield takeLatest(FETCH_SOAP_NOTE_CONTENT, fetchSOAPNoteContent)
    yield takeLatest(EDIT_SOAP_NOTE_CONTENT, editSOAPNoteContent)
    yield takeLatest(EDIT_SOAP_NOTE_REASON, editSOAPNoteReason)
}
