import { Radio } from "@material-tailwind/react"
import React from "react";
import { Control, UseFormGetValues, UseFormRegister, UseFormWatch, useWatch } from "react-hook-form"
import { FollowUpQuestions, Inputs, Options, SubQuestions } from "."
import { traumaAbove18Questions } from "../../../assets/data/traumaAbove18";

const TraumaAbove18V2: React.FC<{
    register: UseFormRegister<Inputs>, watch: UseFormWatch<Inputs>, control: Control<Inputs, any>,
    canEdit: boolean,
}> = ({ register, watch, control, canEdit }) => {

    const watching = useWatch({control, name: ['haveYouEverHadALifeThreateningIllness', 'wereYouEverInALifeThreateningAccident', 'wasPhysicalForceOrWeaponUsedInRobberyOrMugging',
    'hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied', 'atAnyTimeHasAnyoneEverPhysicallyForced', 'otherThanExperiencesMentionedInEarlierQuestions',
    'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly', 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround',
    'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou', 'hasAnyoneEverThreatenedYouWithAWeapon', 'haveYourEverBeenPresentWhenAnotherPersonWasKilled', 'haveYouEverBeenInAnyOtherSituationWhereYouWereSeriouslyInjured',
    'haveYouEverBeenInAnyOtherSituationWhereYouWereExtremelyFrightened']})

    function renderTextbox(key: string, placeholder: string = 'Type here') {
        return <input {...register(key as any, { value: '' })} type="text" className="border-[#E0E0E0] my-1 w-full outline-none rounded-md block" placeholder={placeholder} />
    }

    function renderRadioOptions(option: Options) {
        return <Radio id={option.key+option.value} {...register(option.key as any, { value: option.defaultChecked ? option.value : '' })} label={option.label} value={option.value} />
    }

    function renderAnswerDependentQuestions(dependentQuestions?: SubQuestions[]) {
        const questions = dependentQuestions && dependentQuestions.map(subQuestion => (
            <div key={subQuestion.key} className="">
                <label className="block text-md">{subQuestion.question}</label>
                {subQuestion.type === 'text' && renderTextbox(subQuestion.key, subQuestion.placeholder)}
                {subQuestion.type === 'radio' && subQuestion.options?.length && subQuestion.options.map(option => renderRadioOptions(option))}
            </div>
        ))
        return questions
    }


    function renderFollowupQuestions(followUpQuestions: FollowUpQuestions[], index: number) {
        const _renderFollowUpQuestionRadioButton = (options: Options[]) => {
            return <div className="grid grid-rows-1 grid-flow-col">
                {options.map(option => renderRadioOptions(option))}
            </div>
        }

        const _questions = followUpQuestions.map(followUpQuestion => {
            const followQuestionsWithSubQuestions = followUpQuestion.options && followUpQuestion.options.filter(x => x.subQuestions && x.subQuestions?.length > 0)[0]
            const subQuestions = followQuestionsWithSubQuestions && followQuestionsWithSubQuestions?.subQuestions
            return <div key={followUpQuestion.key} className="py-2">
                <label className="block text-base">{followUpQuestion.question}</label>
                {followUpQuestion.type === 'text' && renderTextbox(followUpQuestion.key, followUpQuestion.placeholder)}
                {followUpQuestion.type === 'radio' && _renderFollowUpQuestionRadioButton(followUpQuestion.options)}
                {followUpQuestion.type === 'radio' && followQuestionsWithSubQuestions && watch(followQuestionsWithSubQuestions.key as any) === 'yes'
                    && subQuestions && subQuestions.map(subQuestion => (
                        <>
                            <label className="block text-md">{subQuestion.question}</label>
                            {subQuestion.type === 'text' && renderTextbox(subQuestion.key, subQuestion.placeholder)}
                        </>
                    ))
                }
            </div>
        })
        return _questions
    }

    function renderQuestions() {
        const _questions = traumaAbove18Questions.map((parentQuestion, index) => {
            const parentQuestionSelectedAnswer = watching[index] && watching[index].selected
            const radioButtonOptions = parentQuestion.type === 'radio' ? parentQuestion.options : null

            return <div key={parentQuestion.key}>
                <label className="block text-lg">{parentQuestion.question}</label>
                <div className="grid grid-rows-1 grid-flow-col">
                    {radioButtonOptions && radioButtonOptions.map(option => renderRadioOptions(option))}
                </div>
                <div className="ml-4">
                    {parentQuestion.type === 'radio' && parentQuestionSelectedAnswer === 'yes' && renderAnswerDependentQuestions(
                        parentQuestion.options.filter(x => x.value === parentQuestionSelectedAnswer)[0]?.subQuestions
                    ) && parentQuestion.followUp && renderFollowupQuestions(parentQuestion.followUp, index)}
                </div>
            </div>
        })
        return _questions
    }

    return (
        <fieldset className="py-3" disabled={!canEdit}>
            <label className="text-lg text-sjOrange">TRAUMA HISTORY:</label>
            <label className="text-lg block py-3">STRESSFUL LIFE EVENTS SCREENING QUESTIONNAIRE</label>
            {renderQuestions()}
        </fieldset>)
}

export default (TraumaAbove18V2)