import {AudioChangeAction, DialoutState, MutedSource, VideoCapturingState} from '@zoom/videosdk';
import classNames from "classnames";
import {MutableRefObject, useCallback, useContext, useEffect, useState} from "react";
import {MediaDevice} from '../../types/VideoTypes';
import MicrophoneButton from './MicrophoneButton';
import ZoomMediaContext from '../../context/MediaContext';
import ZoomContext from '../../context/ZoomContext';
import './VideoFooter.scss'
import CameraButton from './CameraButton';
import {isAndroidBrowser, isSupportOffscreenCanvas} from '../../screens/zoom/util/Platform';
import {SELF_VIDEO_ID} from '../../screens/zoom/util/VideoConstants';
import {useUnmount} from '../../hooks/useUnmount';
import CallDisconnectButton from './CallEndButton';
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from 'react-redux';
import {activateZoomSession} from '../../store/actions/counsellor/account.action';
import {StateParams} from '../../store/reducers';
import {SessionTypes} from '../../models';

interface VideoFooterProps {
    className?: string;
    shareRef?: MutableRefObject<HTMLCanvasElement | null>;
    sharing?: boolean;
}

const isAudioEnable = typeof AudioWorklet === 'function';
const VideoFooter: React.FC<VideoFooterProps> = (props: VideoFooterProps) => {
    const { className, shareRef, sharing } = props;
    const [isStartedAudio, setIsStartedAudio] = useState(false);
    const [isStartedVideo, setIsStartedVideo] = useState(false);
    const [audio, setAudio] = useState('');
    const [isSupportPhone, setIsSupportPhone] = useState(false);
    const [phoneCountryList, setPhoneCountryList] = useState<any[]>([]);
    const [phoneCallStatus, setPhoneCallStatus] = useState<DialoutState>();
    const [isStartedScreenShare, setIsStartedScreenShare] = useState(false);
    const [isMirrored, setIsMirrored] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [activeMicrophone, setActiveMicrophone] = useState('');
    const [activeSpeaker, setActiveSpeaker] = useState('');
    const [activeCamera, setActiveCamera] = useState('');
    const [micList, setMicList] = useState<MediaDevice[]>([]);
    const [speakerList, setSpeakerList] = useState<MediaDevice[]>([]);
    const [cameraList, setCameraList] = useState<MediaDevice[]>([]);
    const [statisticVisible, setStatisticVisible] = useState(false);
    const [selecetedStatisticTab, setSelectedStatisticTab] = useState('audio');
    const [isComputerAudioDisabled, setIsComputerAudioDisabled] = useState(false);
    const { mediaStream } = useContext(ZoomMediaContext);
    const { currentSessionType, currentSessionsPatientId } = useSelector((state: StateParams) => state.appointments)

    const zmClient = useContext(ZoomContext);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onMicrophoneClick = useCallback(async () => {
        if (isStartedAudio) {
            if (isMuted) {
                await mediaStream?.unmuteAudio();
                setIsMuted(false);
            } else {
                await mediaStream?.muteAudio();
                setIsMuted(true);
            }
        } else {
            await mediaStream?.startAudio();
            setIsStartedAudio(true);
        }
    }, [mediaStream, isStartedAudio, isMuted]);

    const onMicrophoneMenuClick = async (key: string) => {
        if (mediaStream) {
            const [type, deviceId] = key.split('|');
            if (type === 'microphone') {
                if (deviceId !== activeMicrophone) {
                    await mediaStream.switchMicrophone(deviceId);
                    setActiveMicrophone(mediaStream.getActiveMicrophone());
                }
            } else if (type === 'speaker') {
                if (deviceId !== activeSpeaker) {
                    await mediaStream.switchSpeaker(deviceId);
                    setActiveSpeaker(mediaStream.getActiveSpeaker());
                }
            } else if (type === 'leave audio') {
                if (audio === 'computer') {
                    await mediaStream.stopAudio();
                } else if (audio === 'phone') {
                    await mediaStream.hangup();
                    setPhoneCallStatus(undefined);
                }
                setIsStartedAudio(false);
            } else if (type === 'statistic') {
                setSelectedStatisticTab('audio');
                setStatisticVisible(true);
            }
        }
    };
    
    const onCameraClick = useCallback(async () => {
        if (isStartedVideo) {
            await mediaStream?.stopVideo();
            setIsStartedVideo(false);
        } else {
            if (isAndroidBrowser() || (isSupportOffscreenCanvas() && !mediaStream?.isSupportMultipleVideos())) {
                const videoElement = document.querySelector(`#${SELF_VIDEO_ID}`) as HTMLVideoElement;
                if (videoElement) {
                    await mediaStream?.startVideo({ videoElement });
                }
            } else {
                const startVideoOptions = { hd: true };
                if (mediaStream?.isSupportVirtualBackground()) {
                    Object.assign(startVideoOptions, { virtualBackground: { imageUrl: 'blur' } });
                }
                await mediaStream?.startVideo(startVideoOptions);
                if (!mediaStream?.isSupportMultipleVideos()) {
                    const canvasElement = document.querySelector(`#${SELF_VIDEO_ID}`) as HTMLCanvasElement;
                    mediaStream?.renderVideo(canvasElement, zmClient.getSessionInfo().userId, 254, 143, 0, 0, 3);
                }
            }

            setIsStartedVideo(true);
        }
    }, [mediaStream, isStartedVideo, zmClient]);

    const onSwitchCamera = async (key: string) => {
        if (mediaStream) {
            if (activeCamera !== key) {
                await mediaStream.switchCamera(key);
                setActiveCamera(mediaStream.getActiveCamera());
            }
        }
    };

    const onDeviceChange = useCallback(() => {
        if (mediaStream) {
          setMicList(mediaStream.getMicList());
          setSpeakerList(mediaStream.getSpeakerList());
          setCameraList(mediaStream.getCameraList());
          setActiveMicrophone(mediaStream.getActiveMicrophone());
          setActiveSpeaker(mediaStream.getActiveSpeaker());
          setActiveCamera(mediaStream.getActiveCamera());
        }
    }, [mediaStream]);

    const onVideoCaptureChange = useCallback((payload: any) => {
        if (payload.state === VideoCapturingState.Started) {
          setIsStartedVideo(true);
        } else {
          setIsStartedVideo(false);
        }
    }, []);

    const onHostAudioMuted = useCallback((payload: any) => {
        const { action, source, type } = payload;
        if (action === AudioChangeAction.Join) {
          setIsStartedAudio(true);
          setAudio(type);
        } else if (action === AudioChangeAction.Leave) {
          setIsStartedAudio(false);
        } else if (action === AudioChangeAction.Muted) {
          setIsMuted(true);
          if (source === MutedSource.PassiveByMuteOne) {
            console.info('Host muted you');
          }
        } else if (action === AudioChangeAction.Unmuted) {
          setIsMuted(false);
          if (source === 'passive') {
            console.info('Host unmuted you');
          }
        }
    }, []);

    const onHostAskToUnmute = useCallback((payload: any) => {
        const { reason } = payload;
        console.log(`Host ask to unmute the audio.`, reason);
    }, []);

    const onLeaveSession = useCallback(async () => {
        dispatch(activateZoomSession({canActivate: false}));
        await zmClient.leave(true);
        console.log('You have left the session.');
        if(currentSessionType === SessionTypes.Intake && currentSessionsPatientId) {
            navigate(`/prospects/${currentSessionsPatientId}/start-intake`)
        } else if(currentSessionType === SessionTypes.TreatmentPlan && currentSessionsPatientId) {
            navigate(`/patients/${currentSessionsPatientId}/treatment`)
        } else if(currentSessionType === SessionTypes.FollowUp && currentSessionsPatientId) {
            navigate(`/patients/${currentSessionsPatientId}/progress-notes`)
        } else if(currentSessionType === SessionTypes.Catchup && currentSessionsPatientId) {
            navigate(`/patients/${currentSessionsPatientId}/catchup-notes`)
        } else navigate('/appointments?page=1')
    }, []);

    useEffect(() => {
        zmClient.on('current-audio-change', onHostAudioMuted);
        // zmClient.on('passively-stop-share', onPassivelyStopShare);
        zmClient.on('device-change', onDeviceChange);
        zmClient.on('host-ask-unmute-audio', onHostAskToUnmute);
        zmClient.on('video-capturing-change', onVideoCaptureChange);
        return () => {
            zmClient.off('current-audio-change', onHostAudioMuted);
            zmClient.off('device-change', onDeviceChange);
            //   zmClient.off('passively-stop-share', onPassivelyStopShare);
            zmClient.off('host-ask-unmute-audio', onHostAskToUnmute);
            zmClient.off('video-capturing-change', onVideoCaptureChange);
        };
      }, [
        zmClient,
        onHostAudioMuted,
        onHostAskToUnmute,
        onVideoCaptureChange,
        onDeviceChange
    ]);
    
    useUnmount(() => {
        if (isStartedAudio) {
            mediaStream?.stopAudio();
        }
        if (isStartedVideo) {
            mediaStream?.stopVideo();
        }
        if (isStartedScreenShare) {
            mediaStream?.stopShareScreen();
        }
    });
    return (
        <div className={classNames('video-footer', className)}>
            {isAudioEnable && (
                <MicrophoneButton
                    isStartedAudio={isStartedAudio}
                    isMuted={isMuted}
                    isSupportPhone={isSupportPhone}
                    audio={audio}
                    phoneCountryList={phoneCountryList}
                    //   onPhoneCallClick={onPhoneCall}
                    //   onPhoneCallCancel={onPhoneCallCancel}
                    //   phoneCallStatus={getPhoneCallStatusDescription(phoneCallStatus)}
                    onMicrophoneClick={onMicrophoneClick}
                    onMicrophoneMenuClick={onMicrophoneMenuClick}
                    microphoneList={micList}
                    speakerList={speakerList}
                    activeMicrophone={activeMicrophone}
                    activeSpeaker={activeSpeaker}
                    disabled={isComputerAudioDisabled}
                />
            )}
            <CallDisconnectButton
                onLeaveSession={onLeaveSession}
            />
            <CameraButton
                isStartedVideo={isStartedVideo}
                onCameraClick={onCameraClick}
                onSwitchCamera={onSwitchCamera}
                // onMirrorVideo={onMirrorVideo}
                // onVideoStatistic={() => {
                //     setSelectedStatisticTab('video');
                //     setStatisticVisible(true);
                // }}
                cameraList={cameraList}
                activeCamera={activeCamera}
                isMirrored={isMirrored}
            />
        </div>
    )
}

export default VideoFooter