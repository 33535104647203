import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import screenerLinkImg from "../../../assets/images/qrPager/screener-link.png"
import {
    ChevronDownIcon,
    ChevronUpIcon,
    ClipboardDocumentListIcon,
    DocumentTextIcon,
    ArrowDownTrayIcon,
    Square2StackIcon,
    BuildingLibraryIcon,
    UsersIcon,
    Squares2X2Icon,
    Bars3BottomLeftIcon
} from '@heroicons/react/24/outline'
import 'react-toastify/dist/ReactToastify.css';
import { RoutePaths } from "../../../shared/Utils";
import ProspectsButton from "../common/PropsectsButton";
import PatientsButton from "../common/PatientsButton";
import BHScreeningButton from "../common/BHScreeningButton";
import { FaRegHospital } from "react-icons/fa"
import LocationsButton from "../common/LocationsButton";
import DashboardButton from "../common/DashboardButton";
import { CiMedicalClipboard } from "react-icons/ci";
import { GrGroup } from "react-icons/gr";
import DischargedPatientsButton from "../common/DischargedPatientsButton";
import UserMasterButton from "../common/UserMasterButton";
import SoapButton from "../common/SoapButton";
import {useFlags} from "flagsmith/react";


const PhysicianSideBar: React.FC = () => {
    const isPhysicianSoapNoteEnabled = useFlags(['physician_soap_side_menu'])?.physician_soap_side_menu?.enabled;
    // const {isSOAPEnabled} = useSelector((state: any) => state.account)


    return (
        <div className={"flex flex-col"}>
            <DashboardButton component={"dashboard"} navigateTo={`${RoutePaths.physicianCare.dashboard}`} Icon={Squares2X2Icon} />
            <ProspectsButton component={"prospects"} navigateTo={`${RoutePaths.physicianCare.prospects}?page=1`} Icon={Bars3BottomLeftIcon} />
            <PatientsButton component={"patients"} navigateTo={`${RoutePaths.physicianCare.patients}?page=1`} Icon={UsersIcon} />
            <DischargedPatientsButton component={"discharged-patients"} navigateTo={`/${RoutePaths.physicianCare.dischargedPatients.root}?page=1`} Icon={CiMedicalClipboard} />
            <BHScreeningButton component={"bhScreening"} navigateTo={`${RoutePaths.physicianCare.bhScreening}?page=1`} Icon={ClipboardDocumentListIcon} />
            <LocationsButton component={"locations"} navigateTo={`${RoutePaths.physicianCare.locations.root}?page=1`} Icon={FaRegHospital} />
            {(isPhysicianSoapNoteEnabled) &&
                <SoapButton component={"notes"} navigateTo={`${RoutePaths.physicianCare.soap.root}?page=1`} Icon={DocumentTextIcon} />
            }
            {<UserMasterButton component={"users"} navigateTo={`${RoutePaths.physicianCare.users.root}?page=1`} Icon={GrGroup} />}
        </div>
    )
}

export default PhysicianSideBar
