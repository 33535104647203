import classNames from "classnames"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IPatientScreenerIndex, IProspect, IProspectScreenerResponse, ISummary } from "../../models"
import { fetchProspectScreenerResponse } from "../../store/actions/counsellor/prospects.action"
import { StateParams } from "../../store/reducers"
import ScreenerQuestionAnswers from "./ScreenerQuestionAnswers"
import ScreenerSummary from "./ScreenerSummary"

type Props = {
    screeners: IPatientScreenerIndex[],
    summary: ISummary
    prospect: IProspect
    sourcePatientId: string
    selectedIndex: number
    practiceId: string
    summaryComponentRef: any
    updateSelectedIndex: Function
}
const ScreenerResultContent: React.FC<Props> = (props) => {
    const dispatch = useDispatch()
    const [selectedScreener, setSelectedScreener] = useState<string>()
    const [selectedScreenerSlug, setSelectedScreenerSlug] = useState<string>()
    const [questionAnswers, setQuestionAnswers] = useState<IProspectScreenerResponse[]>()
    const screenerQuestionAnswers = useSelector((state: StateParams) => state.prospects.screenerResponses)
    const fetchInProgress = useSelector((state: StateParams) => state.prospects.fetchProspectScreenerResponseInProgress)

    useEffect(() => {
        if (props.selectedIndex > 0) {
            const screener = props.screeners[props.selectedIndex - 1]
            setSelectedScreener(screener.id)
            setSelectedScreenerSlug(screener.slug)
            setQuestionAnswers([])
            dispatch(fetchProspectScreenerResponse(props.prospect.id, screener.id, props.sourcePatientId, props.practiceId))
        }
    }, [props.selectedIndex])

    useEffect(() => {
        console.log("Screener Id", selectedScreener, screenerQuestionAnswers)
        if (selectedScreener && screenerQuestionAnswers && screenerQuestionAnswers[selectedScreener]) {
            console.log("Q and As", screenerQuestionAnswers)
            setQuestionAnswers(screenerQuestionAnswers[selectedScreener])
        }
    }, [screenerQuestionAnswers, selectedScreener])

    return (
        <div
            className={classNames(
                'w-full rounded-xl p-3 h-full',
                'ring-offset-2 focus:outline-none'
            )}>
            {props.selectedIndex === 0
                ? <ScreenerSummary patientDetails={props.prospect} summary={props.summary} summaryComponentRef={props.summaryComponentRef} updateSelectedIndex={props.updateSelectedIndex} />
                : (questionAnswers && questionAnswers.length > 0 && selectedScreenerSlug
                    ? <ScreenerQuestionAnswers summary={props.summary} screenerSlug={selectedScreenerSlug} questionAnswers={questionAnswers} />
                    : <div>
                        {fetchInProgress === false ? <p>No questions have been answered yet</p> : null}
                    </div>)}
        </div>
    )
}

export default ScreenerResultContent