import React, {useState} from "react";
import {IScreenerQuestionAnswer, ISummary} from "../../../models";
import * as _ from "lodash";
import {Accordion, AccordionBody, AccordionHeader} from "@material-tailwind/react";
import QuestionAnswer from "../../generic/QuestionAnswer";

enum Conditions {
    "normal",
    "depression",
    "adhd",
    "conduct-disorder",
}

type ConditionType = keyof typeof Conditions

const CONDITION_MAP : {[key in ConditionType]: string} = {
    "normal": "Other",
    "depression": "Internalizing Problems (i.e., Depression or Anxiety) ",
    "adhd": "Attention Problems (i.e., ADHD)",
    "conduct-disorder": "Externalizing Problems (i.e., Conduct Disorder, Oppositional Defiant Disorder)",
}

const PSCResults: React.FC<{screenerScoreMessage: string, questionAnswers: IScreenerQuestionAnswer[], summary: ISummary}> = ({questionAnswers, summary}) => {
    const screenerScores = summary
    const [open, setOpen] = useState(0);

    const handleOpen = (value: number) => {
        setOpen(open === value ? 0 : value);
    };
    const groupedByCondition : {[key: string]: IScreenerQuestionAnswer[]} = _.chain(questionAnswers)
        .groupBy((item: IScreenerQuestionAnswer) => {
            if(item.condition) {
                return item.condition
            } else {
                return "normal"
            }
        })
        .value()

    function renderGroupedQuestionAnswers(title: string | null, index: number, groupedQuestionAnswers: IScreenerQuestionAnswer[]) {
        return groupedQuestionAnswers && groupedQuestionAnswers.length > 0 ?
            (<Accordion className={"mb-2"} open={open === index} onClick={() => handleOpen(index)}>
                <AccordionHeader className={"bg-sjPSCY text-black rounded text-sm px-2"}><span dangerouslySetInnerHTML={{__html: title || ""}}/></AccordionHeader>
                <AccordionBody>
                    <div className={"flex flex-col font-medium"}>
                        {groupedQuestionAnswers.map((questionAnswer, index) => {
                            return <QuestionAnswer key={questionAnswer.questionId} questionAnswer={questionAnswer}
                                                   index={index}/>
                        })}
                    </div>
                </AccordionBody>
            </Accordion>)
            : null;
    }

    function normalConditionQuestionAnswers(index: number) {
        const groupedQuestionAnswers = groupedByCondition.normal
        const title = CONDITION_MAP["normal"]
        return renderGroupedQuestionAnswers(title, index, groupedQuestionAnswers);
    }

    function depressionConditionQuestionAnswers(index: number) {
        if("depression" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition.depression
            const title = CONDITION_MAP["depression"]
            const styledTitle = `${screenerScores.pscComplete ? (screenerScores?.potentiallyPscDepressed === true ? `The patient has received a <span class='text-sjTestPositive'>Positive</span> score on the ${title}` : `The patient has received a <span class='text-sjTestNegative'>Negative</span> score on the ${title}`) : `${title}` }`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }
    function adhdConditionQuestionAnswers(index: number) {
        if("adhd" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition.adhd
            const title = CONDITION_MAP["adhd"]
            const styledTitle = `${screenerScores.pscComplete ? screenerScores?.potentiallyPscADHD === true ? `The patient has received a <span class='text-sjTestPositive'>Positive</span> score on the ${title}` : `The patient has received a <span class='text-sjTestNegative'>Negative</span> score on the ${title}` : `${title}` }`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }
    function conductDisorderConditionQuestionAnswers(index: number) {
        if("conduct-disorder" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition["conduct-disorder"]
            const title = CONDITION_MAP["conduct-disorder"]
            const styledTitle = `${screenerScores.pscComplete ? screenerScores?.potentiallyPscConductDisorder === true ? `The patient has received a <span class='text-sjTestPositive'>Positive</span> score on the ${title}` : `The patient has received a <span class='text-sjTestNegative'>Negative</span> score on the ${title}` : `${title}`}`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }
    

    return questionAnswers && questionAnswers.length > 0
        ? (<>
            { depressionConditionQuestionAnswers(1) }
            { adhdConditionQuestionAnswers(2) }
            { conductDisorderConditionQuestionAnswers(3) }
            { normalConditionQuestionAnswers(4) }
        </>)
        : null
}

export default PSCResults