import React, {useMemo} from "react";
import {IScreenerPendingPatient, LanguageCodeDescription} from "../../../models";
import TablePaginationV2 from "../../../components/generic/TablePaginationV2";
import {DateTime} from "luxon";
import {ProfileViewV2} from "../../../components/generic/ProfileView";
import { useSelector } from "react-redux";
import { StateParams } from "../../../store/reducers";

interface IScreeningPendingPatientListComponentProps {
    patients: IScreenerPendingPatient[] | undefined;
    currentPageHandler: Function;
    searchInProgress: boolean | undefined;
}

const ScreeningPendingPatientListComponent = (props: IScreeningPendingPatientListComponentProps) => {

    const {patients, searchInProgress} = props;
    const role = useSelector((state: StateParams) => state.account.role)
    const isCareCoordinator = role === 'Care_Coordinator'
    const isCounsellor = role === 'Counsellor' || role === 'Admin'

    const data = patients ? patients.map((patient) => {
        const dob = DateTime.fromISO(patient.dob.toString(), {zone: 'utc'}).toFormat('MM-dd-yyyy')
        return {
            name: `${patient.patientFirstName} ${patient.patientLastName}`,
            dateOfBirth: dob,
            preferredLanguage: LanguageCodeDescription[patient.preferredLanguage] || "English",
            profile: `/compliance/${patient.patientId}/patient-journey`,
            practice:  patient.referredByPracticeName || "N/A",
            socialWorker: `${patient.therapistFirstName} ${patient.therapistLastName}`
        }
    }) : [];

    const columns = useMemo(
        () => [
            {header: "Name", accessorKey: "name"},
            {header: "Date of birth", accessorKey: "dateOfBirth"},
            {header: "Preferred Language", accessorKey: "preferredLanguage"},
            {header: "Practice", accessorKey: "practice"},
            {header: "Social Worker", accessorKey: "socialWorker"},
            {header: "", accessorKey: "profile", cell: ProfileViewV2}
        ],
        []);

    return (
        <div className='mt-4 w-full'>
            <TablePaginationV2 
                columns={columns} 
                data={data} 
                searchInProgress={searchInProgress} 
                possibleColumnsToHide={{socialWorker: isCounsellor ? false : true, profile: isCareCoordinator ? false : true}}/>
        </div>
    )
}

export default ScreeningPendingPatientListComponent;
