import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import ActiveCallsSection from "../sidebar/common/ActiveCallsSection";
import CallManagerComponent from "../calls/CallManagerComponent";
import {CallStatus} from "../../models";

const CallNotificationBar: React.FC = () => {
    const {callStatus} = useSelector((state: StateParams )=> state.calls)
    const [callManagerActive, setCallManagerActive] = useState(false)

    useEffect(() => {
        const callManagerShouldBeInactive = [CallStatus.OFFLINE, CallStatus.REJECTED, CallStatus.FAILED_INITIATION].includes(callStatus)
        const canMakeCall = !callManagerShouldBeInactive
        console.log("CallNotificationBar, Can Make Call", canMakeCall)
        setCallManagerActive(canMakeCall)
    }, [callStatus])


    function renderCallNotificationBar() {
        return <div className="w-full overflow-auto justify-center items-center fixed top-0 left-0 z-[99999]">
            <ActiveCallsSection/>
            <CallManagerComponent/>
        </div>;
    }

    function renderNotificationsBar() {
        return callManagerActive ? renderCallNotificationBar() : null;
    }

    return <>
        { renderNotificationsBar() }
    </>
}

export default CallNotificationBar
