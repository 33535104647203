import {call, put, takeLatest} from "@redux-saga/core/effects";
import {apiHttpClient, getAuthHeaderConfig} from "../../../lib";
import {Either} from "monet";
import {CheckedError, ServiceError} from "../../../types/ServiceError";
import {
    AuthState,
    ComplianceSWListRequest,
    IComplianceSWList
} from "../../../models";
import {AxiosError, AxiosResponse} from "axios";
import {callFinished, callInProgress} from "../../actions/loading.action";
import {createQueryString} from "../../../lib/api-helpers";
import { FETCH_COMPLIANCE_SW_LIST, fetchComplianceSWListFailed, fetchComplianceSWListInProgress, fetchedComplianceSWList } from "../../actions/care-coordinator/compliance.action";


const apiFetchComplianceSWList = (action: {
    type: string,
    payload: {
        request: ComplianceSWListRequest
    },
    authState: AuthState
}) => {
    const filters = { practiceIds: action.payload.request.practiceIds, isPendingNotes: action.payload.request.isPendingNotes, startDate: action.payload.request.filters?.startDate, endDate: action.payload.request.filters?.endDate, searchText: action.payload.request.searchText || '' }
    const searchParams = new URLSearchParams()
    if (filters) {
        for (const [key, value] of Object.entries(filters)) {
            if (value) {
                searchParams.append(key, value.toString());
            }
        }
    }
    
    const url = `/compliance/care-coordinator-compliance-sw-list?${searchParams.toString()}&pageNumber=${action.payload.request.pageNumber}&recordsPerPage=${action.payload.request.recordsPerPage}`;
    
    return apiHttpClient.get<Either<CheckedError, IComplianceSWList>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IComplianceSWList);
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e);
            const statusCode = e.response?.status || 500;
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse);
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"));
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText);
            return Either.left(new Error(e.message));
        });
}

function* fetchComplianceSWList(action: {
    type: string,
    payload: {
       request: ComplianceSWListRequest
    },
    authState: AuthState
}) {
    try {
        console.log(`CCComplianceSaga: fetchComplianceSWList called with ${JSON.stringify(action)}`);
        yield put(callInProgress());
        yield put(fetchComplianceSWListInProgress());
        const apiFetchComplianceSWListResponse: Either<CheckedError, IComplianceSWList> = yield call(apiFetchComplianceSWList, action);
        if (apiFetchComplianceSWListResponse.isLeft()) {
            const error = apiFetchComplianceSWListResponse.left();
            if (error.isChecked) {
                console.log("CCComplianceSaga: Encountered a Checked Error", error.message);
                yield put(fetchComplianceSWListFailed({error: error.message}));
            } else {
                yield put(fetchComplianceSWListFailed({error: error.message}));
            }
        } else {
            const {swList, swListCount, tabCount} = apiFetchComplianceSWListResponse.right();
            yield put(fetchedComplianceSWList(swList, swListCount, tabCount));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchComplianceSWListFailed({error: "Error fetching screener pending patients"}));
    } finally {
        yield put(callFinished());
    }
}

export default function* careCoordinatorComplianceSaga() {
    yield takeLatest(FETCH_COMPLIANCE_SW_LIST, fetchComplianceSWList);
}
