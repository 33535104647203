import {IAccount} from "../../../models";

export const ACCOUNT_IF_EXISTS_REQUEST = "ACCOUNT_IF_EXISTS_REQUEST"
export const ACCOUNT_IF_EXISTS_IN_PROGRESS = "ACCOUNT_IF_EXISTS_IN_PROGRESS"
export const ACCOUNT_IF_EXISTS_SUCCESS = "ACCOUNT_IF_EXISTS_SUCCESS"
export const ACCOUNT_IF_EXISTS_FAILED = "ACCOUNT_IF_EXISTS_FAILED"
export const CLEAR_DOES_EMAIL_EXISTS = "CLEAR_DOES_EMAIL_EXISTS"
export const GENERATE_ZOOM_JWT_TOKEN = "GENERATE_ZOOM_JWT_TOKEN"
export const GENERATED_ZOOM_TOKEN = "GENERATED_ZOOM_TOKEN"
export const FAILED_GENERATING_ZOOM_TOKEN = "FAILED_GENERATING_ZOOM_TOKEN"
export const CLEAR_ZOOM_JWT_TOKEN = "CLEAR_ZOOM_JWT_TOKEN"
export const IS_ZOOM_SESSION_ACTIVE = "IS_ZOOM_SESSION_ACTIVE"

export const checkIfEmailExists = (payload: {email: string}) => {
    return {type: ACCOUNT_IF_EXISTS_REQUEST, payload}
}
export const checkIfEmailExistsInProgress = () => {
    return {type: ACCOUNT_IF_EXISTS_IN_PROGRESS}
}
export const storeAccountDetails = (payload: IAccount) => {
    return {type: ACCOUNT_IF_EXISTS_SUCCESS, payload}
}
export const checkIfEmailExistsFailed = (payload: {error: string}) => {
    return {type: ACCOUNT_IF_EXISTS_FAILED, payload}
}
export const clearEmailExists = () => {
    return {type: CLEAR_DOES_EMAIL_EXISTS}
}

export const generateZoomToken = (payload: { sessionName: string, roleType: string, token: string}) => {
    return {type: GENERATE_ZOOM_JWT_TOKEN, payload}
}
export const generatedZoomToken = (payload: { token: string;}) => {
    return {type: GENERATED_ZOOM_TOKEN, payload}
}
export const failedGeneratingZoomToken = (payload: { error: string;}) => {
    return {type: FAILED_GENERATING_ZOOM_TOKEN, payload}
}
export const clearZoomJwtToken = () => {
    return { type: CLEAR_ZOOM_JWT_TOKEN }
}

export const activateZoomSession = (payload: {canActivate: boolean}) => {
    return { type: IS_ZOOM_SESSION_ACTIVE, payload }
}

