import React, { useMemo } from "react";
import { IReferredPatientsDetails, LanguageCodeDescription } from "../../models";
import { DetailsButton } from "../generic/DetailsButton";
import { DateTime } from "luxon";
import { ProspectsDataView } from "./common/ProspectsDataView";
import { NoteStatus } from "../generic/NoteStatus";

export class PsychiatristProspectsDataView implements ProspectsDataView {
    private referredPatients: IReferredPatientsDetails[];

    constructor() {
        this.referredPatients = []
    }

    setProspects(referredPatients: IReferredPatientsDetails[]) {
        this.referredPatients = referredPatients
    }

    getColumns(): any {
        return () => [
            { header: "Patient Name", accessorKey: "name" },
            { header: "Date of birth", accessorKey: "dateOfBirth" },
            { header: "Referred Date", accessorKey: "referredDate" },
            { header: "Social Worker", accessorKey: "therapist" },
            { header: "Status", accessorKey: "noteStatus", cell: (props: any) => <NoteStatus value={props.getValue()} /> },
            { header: "", accessorKey: "summaryLink", cell: (props: any) => <DetailsButton value={props.getValue()} /> }
        ]
    };

    getData(): any {
        return this.referredPatients.map((referredPatient) => {
            const isoDateTime = referredPatient.referredDate ? DateTime.fromISO(referredPatient.referredDate.toString(), { zone: 'utc' }).toFormat('MM-dd-yyyy') : ''
            const dob = DateTime.fromISO(referredPatient.dob.toString(), { zone: 'utc' }).toFormat('MM-dd-yyyy')
            const practice = referredPatient.screenerUserPractices[0]
            const therapistDeatils = referredPatient.therapistProspect[0]


            return {
                id: referredPatient.id,
                name: `${referredPatient.lastName}, ${referredPatient.firstName}`,
                dateOfBirth: dob,
                referredDate: isoDateTime,
                therapist: `${therapistDeatils.therapist.firstName} ${therapistDeatils.therapist.lastName}`,
                noteStatus: therapistDeatils.intakeNotes[0]?.status,
                summaryLink: { url: `${referredPatient.id}/summary`, practiceId: practice.practiceId, sourcePatientId: referredPatient.id },
            }
        })
    }
}
