import React from "react";
import {useSelector} from "react-redux";
import DischargedPatients from "../../components/discharged-patients/DischargedPatients";
import DischargedPatientsPhysician from "../../components/discharged-patients/physician/DischargedPatients";
import { hasPermission } from "../../store";
import { SEARCH_DISCHARGED_PATIENTS } from "../../store/actions/counsellor/patients.action";
import { StateParams } from "../../store/reducers";

const DischargedPatientsScreen = () => {
    const {role: practitionerRole} = useSelector((state: StateParams) => state.practitionerAccount)
    const {role: physicianRole} = useSelector((state: StateParams) => state.physicianAccount)
    console.log(practitionerRole, physicianRole)
    function renderPatientList() {
        if(practitionerRole && hasPermission(SEARCH_DISCHARGED_PATIENTS)) {
            return <DischargedPatients role={practitionerRole}/>
        }
        if(physicianRole && hasPermission(SEARCH_DISCHARGED_PATIENTS)) {
            return <DischargedPatientsPhysician role={physicianRole}/>
        }
        if(!hasPermission(SEARCH_DISCHARGED_PATIENTS)) {
            return <h1>You dont have access to view this data</h1>
        }
    }

    return (
        <>{ renderPatientList() } </>
    )
}

export default DischargedPatientsScreen
