import React, {useEffect, useState} from "react";
import {Roles} from "../../models";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {searchDischargedPatients, searchPatients} from "../../store/actions/counsellor/patients.action";
import {RoutePaths} from "../../shared/Utils";
import TableFilterPaginationV2 from "../generic/TableFilterPaginationV2";
import PaginationV2 from "../generic/PaginationV2";
import { CounsellorDischargedPatientsDataView } from "./counsellor/CounsellorPatientsDataView";
import { DischargedPatientsDataView } from "./DischargedPatientsDataView";
import DischargedPatientTable from "./DischargedPatientTable";

const PatientsDataViewByRole : {[key: string]: DischargedPatientsDataView } = {
    Admin: new CounsellorDischargedPatientsDataView(),
    Counsellor: new CounsellorDischargedPatientsDataView(),
    Care_Coordinator: new CounsellorDischargedPatientsDataView(),
    Psychiatrist: new CounsellorDischargedPatientsDataView()
}

const DischargedPatients : React.FC<{role : string}> = ({role}) => {
    const patientsDataViewForRole = PatientsDataViewByRole[role];
    const dispatch = useDispatch()
    const patients = useSelector((state: StateParams) => state.patients.dischargedPatients)
    const patientSearchInProgress = useSelector((state: StateParams) => state.patients.fetchPatientsInProgress)
    const patientsCount = useSelector((state: StateParams) => state.patients.dischargedPatientsCount)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 1);
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [searchText, setSearchText] = useState('')
    console.log(searchParams.get("page"))
    useEffect(() => {
        if(currentPage && recordsPerPage) {
            dispatch(searchDischargedPatients(currentPage, recordsPerPage, searchText))
        }
    }, [currentPage, recordsPerPage, searchText]);

    useEffect(()=>{
        if(searchParams && searchParams.get("page")) {
            setCurrentPage(Number(searchParams.get("page")) || 1)
        }
    },[searchParams])

    useEffect(() => {
        if(currentPage) {
            navigate(`/${RoutePaths.collaborativeCare.dischargedPatients.root}?page=${currentPage}`)
        }
    }, [currentPage]);

    return <div className="w-full p-4 h-full bg-[#F7F8FA]">
        <div className={"flex justify-between pb-4 items-center"}>
            <h1 className="text-xl">CoCM Patients - Discharged</h1>
            <div className="flex gap-x-2 justify-end items-center">
                <TableFilterPaginationV2
                    setTableFilter={setSearchText}
                    totalRows={patientsCount || 0}
                    currentPageHandler={setCurrentPage}
                />
            </div>
        </div>
        <DischargedPatientTable dataView={patientsDataViewForRole} patients={patients} searchInProgress={patientSearchInProgress}/>
        {patients && patients.length > 0 &&
            <PaginationV2
                totalRows={patientsCount || 0}
                rowsPerPage={recordsPerPage}
                recordsPerPageHandler={setRecordsPerPage}
                currentPage={currentPage}
                currentPageHandler={setCurrentPage}
                showPages={false}
            />
        }
    </div>

}

export default DischargedPatients
