import { TraumaAbove18 } from "../../components/clinical-notes/intake";

export const traumaAbove18Questions: TraumaAbove18[] = [
    {
        key: 'haveYouEverHadALifeThreateningIllness',
        question: '1. Have you ever had a life-threatening illness?',
        type: 'radio',
        options: [
            {
                label: 'Yes', value: 'yes', key: 'haveYouEverHadALifeThreateningIllness.selected',
                subQuestions: [
                    {
                        key: 'haveYouEverHadALifeThreateningIllness.whatAge',
                        question: 'If yes, at what age? ', placeholder: 'Type age in number',
                        type: 'text',
                    },
                ]
            },
            {
                label: 'No', value: 'no', key: 'haveYouEverHadALifeThreateningIllness.selected',
                subQuestions: []
            }
        ],
        followUp: [
            {
                key: 'haveYouEverHadALifeThreateningIllness.threateningOfIllness',
                question: 'Duration of Illness',
                type: 'radio',
                options: [
                    {
                        label: 'Less than 3 months', value: '3Months', defaultChecked: true, key: 'haveYouEverHadALifeThreateningIllness.threateningOfIllness',
                    },
                    {
                        label: '4-6 months', value: '4to6Months', key: 'haveYouEverHadALifeThreateningIllness.threateningOfIllness',
                    },
                    {
                        label: '7-12 months', value: '7to12Months', key: 'haveYouEverHadALifeThreateningIllness.threateningOfIllness',
                    },
                    {
                        label: 'More than a year', value: 'MoreThanAYear', key: 'haveYouEverHadALifeThreateningIllness.threateningOfIllness',
                    }
                ]
            },
            {
                key: 'haveYouEverHadALifeThreateningIllness.specificIllness',
                question: 'Describe specific illness',
                type: 'text',
                options: []
            }
        ]
    },
    {
        key: 'wereYouEverInALifeThreateningAccident',
        question: '2.  Were you ever in a life-threatening accident?',
        type: 'radio',
        options: [
            {
                label: 'Yes', value: 'yes', key: 'wereYouEverInALifeThreateningAccident.selected',
                subQuestions: [
                    {
                        question: 'If yes, at what age? ', key: 'wereYouEverInALifeThreateningAccident.whatAge',
                        type: 'text', placeholder: 'Type age in number',
                    },
                ]
            },
            {
                label: 'No', value: 'no', key: 'wereYouEverInALifeThreateningAccident.selected',
                subQuestions: []
            }
        ],
        followUp: [
            {
                key: 'wereYouEverInALifeThreateningAccident.describeAccident',
                question: 'Describe accident',
                type: 'text',
                options: []
            },
            {
                key: 'wereYouEverInALifeThreateningAccident.didAnyoneDie',
                question: 'Did anyone die?',
                type: 'radio',
                options: [
                    {
                        label: 'Yes', value: 'yes', key: 'wereYouEverInALifeThreateningAccident.didAnyoneDieSelected',
                        subQuestions: [
                            {
                                key: 'wereYouEverInALifeThreateningAccident.didAnyoneDieWhatWasTheirRelationshipToYou',
                                question: 'Who? (Relationship to you)',
                                type: 'text',
                            }
                        ]
                    },
                    {
                        label: 'No', value: 'no', key: 'wereYouEverInALifeThreateningAccident.didAnyoneDieSelected',
                        subQuestions: [], defaultChecked: true
                    }
                ]
            },
            {
                key: 'wereYouEverInALifeThreateningAccident.whatPhysicalInjuriesDidYouReceive',
                question: 'What physical injuries did you receive?',
                type: 'text',
                options: []
            },
            {
                key: 'wereYouEverInALifeThreateningAccident.wereYouHospitalizedOvernight',
                question: 'Were you hospitalized overnight?',
                type: 'radio',
                options: [
                    {
                        label: 'Yes', value: 'yes', key: 'wereYouEverInALifeThreateningAccident.wereYouHospitalizedOvernightSelected',
                        subQuestions: []
                    },
                    {
                        label: 'No', value:'no', key: 'wereYouEverInALifeThreateningAccident.wereYouHospitalizedOvernightSelected',
                        subQuestions: [], defaultChecked: true
                    }
                ]
            }
        ]
    },
    {
        question: '3.  Was physical force or a weapon ever used against you in a robbery or mugging?',
        key: 'wasPhysicalForceOrWeaponUsedInRobberyOrMugging',
        type: 'radio',
        options: [
            {
                label: 'Yes', value: 'yes', key: 'wasPhysicalForceOrWeaponUsedInRobberyOrMugging.selected',
                subQuestions: [
                    {
                        question: 'If yes, at what age? ', key: 'wasPhysicalForceOrWeaponUsedInRobberyOrMugging.whatAge',
                        type: 'text',placeholder: 'Type age in number',
                    }
                ]
            },
            {
                label: 'No', value: 'no', key: 'wasPhysicalForceOrWeaponUsedInRobberyOrMugging.selected',
                subQuestions: []
            }
        ],
        followUp: [
            {
                question: 'How many perpetrators?',
                key: 'wasPhysicalForceOrWeaponUsedInRobberyOrMugging.perpetrators',
                type: 'radio',
                options: [
                    {
                        label: 'One', value: 'One', key: 'wasPhysicalForceOrWeaponUsedInRobberyOrMugging.perpetrators',
                    },
                    {
                        label: 'More than one', value: 'moreThanOne', key: 'wasPhysicalForceOrWeaponUsedInRobberyOrMugging.perpetrators',
                    }
                ]
            },
            {
                question: 'Describe physical force',
                key: 'wasPhysicalForceOrWeaponUsedInRobberyOrMugging.physicalForce',
                type: 'text',
                options: [], placeholder: '(e.g., restrained, shoved) or weapon used against you.)'
            },
            {
                key: 'wasPhysicalForceOrWeaponUsedInRobberyOrMugging.didAnyoneDie',
                question: 'Did anyone die?',
                type: 'radio',
                options: [
                    {
                        label: 'Yes', value: 'yes', key: 'wasPhysicalForceOrWeaponUsedInRobberyOrMugging.weaponDidAnyOneDie',
                        subQuestions: [
                            {
                                key: 'wasPhysicalForceOrWeaponUsedInRobberyOrMugging.who',
                                question: 'Who?',
                                type: 'text',
                            }
                        ]
                    },
                    {
                        label: 'No', value: 'no', key: 'wasPhysicalForceOrWeaponUsedInRobberyOrMugging.weaponDidAnyOneDie',
                        subQuestions: [], defaultChecked: true
                    }
                ]
            },
            {
                key: 'wasPhysicalForceOrWeaponUsedInRobberyOrMugging.WhatInjuriesDidYouReceive',
                question: 'What injuries did you receive?',
                type: 'text',
                options: []
            },
            {
                question: 'Was your life in danger?',
                key: 'wasPhysicalForceOrWeaponUsedInRobberyOrMugging.lifeInDanger',
                type: 'radio',
                options: [
                    {
                        label: 'Yes', value: 'yes', key: 'wasPhysicalForceOrWeaponUsedInRobberyOrMugging.lifeInDanger',
                    },
                    {
                        label: 'No', value: 'no', key: 'wasPhysicalForceOrWeaponUsedInRobberyOrMugging.lifeInDanger', defaultChecked: true
                    }
                ]
            }
        ]
    },
    {
        question: '4.  Has an immediate family member, romantic partner, or very close friend died because of accident, homicide, or suicide?',
        key: 'hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied',
        type: 'radio',
        options: [
            {
                label: 'Yes', value: 'yes', key: 'hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied.selected',
                subQuestions: [
                    {
                        question: 'If yes, how old were you?', key: 'hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied.howOldWereYou',
                        type: 'text',placeholder: 'Type age in number',
                    },
                ]
            },
            {
                label: 'No', value: 'no', key: 'hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied.selected',
                subQuestions: []
            }
        ],
        followUp: [
            {
                question: 'How did this person die?',
                key: 'hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied.howDidThisPersonDie',
                type: 'text',
                options: []
            },
            {
                question: 'Relationship to person lost',
                key: 'hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied.relationshipToPersonLost',
                type: 'text',
                options: []
            },
            {
                question: 'In the year before this person died, how often did you see/have contact with him/her?',
                key: 'hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied.personDied',
                type: 'radio',
                options: [
                    {
                        label: 'Daily', value: 'daily', key: 'hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied.personDied', defaultChecked: true
                    },
                    {
                        label: 'Weekly', value: 'weekly', key: 'hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied.personDied',
                    },
                    {
                        label: 'Monthly', value: 'monthly', key: 'hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied.personDied',
                    },
                    {
                        label: 'Quarterly', value: 'quarterly', key: 'hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied.personDied',
                    },
                    {
                        label: 'Not that often', value: 'notThatOften', key: 'hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied.personDied',
                    },
                    {
                        label: 'Other', value: 'other', key: 'hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied.personDied',
                        subQuestions: [{
                            question: '', key: 'hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied.other',
                            type: 'text',
                        }]
                    }
                ]
            },
            {
                question: 'Have you had a miscarriage? ',
                key: 'hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied.hadAMiscarriage',
                type: 'radio',
                options: [
                    {
                        label: 'Yes', value: 'yes', key: 'hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied.hadAMiscarriage',
                        subQuestions: [
                            {
                                question: 'If yes, at what age?', key: 'hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied.miscarriageAge',
                                type: 'text',
                            }
                        ]
                    },
                    {
                        label: 'No', value: 'no', key: 'hasAnImmediateFamilyMemberRomanticPartnerOrFriendDied.hadAMiscarriage', defaultChecked: true
                    }
                ]
            },
        ]
    },
    {
        question: '5.  At any time, has anyone (parent, other family member, romantic partner, stranger or someone else) ever physically forced you to have intercourse, or to have oral or anal sex against your wishes, or when you were helpless, such as being asleep or intoxicated?',
        key: 'atAnyTimeHasAnyoneEverPhysicallyForced',
        type: 'radio',
        options: [
            {
                label: 'Yes', value: 'yes', key: 'atAnyTimeHasAnyoneEverPhysicallyForced.selected',
                subQuestions: [
                    {
                        question: 'If yes, at what age? ', key: 'atAnyTimeHasAnyoneEverPhysicallyForced.whatAge',
                        type: 'text',placeholder: 'Type age in number',
                    },
                    {
                        question: 'If yes, how many times?', key: 'atAnyTimeHasAnyoneEverPhysicallyForced.howManyTimes',
                        type: 'radio',
                        options: [
                            {
                                label: '1', value: '1', key: 'atAnyTimeHasAnyoneEverPhysicallyForced.howManyTimes',
                            },
                            {
                                label: '2-4', value: '2-4', key: 'atAnyTimeHasAnyoneEverPhysicallyForced.howManyTimes',
                            },
                            {
                                label: '5-10', value: '5-10', key: 'atAnyTimeHasAnyoneEverPhysicallyForced.howManyTimes',
                            },
                            {
                                label: 'More than 10', value: 'moreThan10', key: 'atAnyTimeHasAnyoneEverPhysicallyForced.howManyTimes',
                            }
                        ]
                    },
                ]
            },
            {
                label: 'No', value: 'no', key: 'atAnyTimeHasAnyoneEverPhysicallyForced.selected',
                subQuestions: []
            }
        ],
        followUp: [
            {
                question: 'If repeated, over what period?',
                key: 'atAnyTimeHasAnyoneEverPhysicallyForced.overWhatPeriod',
                type: 'radio',
                options: [
                    {
                        label: "6 mo. or less", "value": "6MonthsOrLess",
                        key: 'atAnyTimeHasAnyoneEverPhysicallyForced.overWhatPeriod',
                    },
                    {
                        label: "7 mos. - 2 yrs", "value": "7MonthsTo2Yrs",
                        key: 'atAnyTimeHasAnyoneEverPhysicallyForced.overWhatPeriod',
                    },
                    {
                        label: "more than 2 yrs. but less than 5 yrs", "value": "between2to5Yrs",
                        key: 'atAnyTimeHasAnyoneEverPhysicallyForced.overWhatPeriod',
                    },
                    {
                        label: "5 yrs. or more", "value": "moreThan5Yrs",
                        key: 'atAnyTimeHasAnyoneEverPhysicallyForced.overWhatPeriod',
                    },
                ]
            },
            {
                question: 'Who did this?', options: [],
                key: 'atAnyTimeHasAnyoneEverPhysicallyForced.whoDidThis', type: 'text',
                placeholder: '(Specify stranger, parent, etc.) '
            },
            {
                question: 'Has anyone else ever done this to you?',
                key: 'atAnyTimeHasAnyoneEverPhysicallyForced.everDoneThisToYou',
                type: 'radio',
                options: [
                    {
                        label: 'Yes', value: 'yes', key: 'atAnyTimeHasAnyoneEverPhysicallyForced.everDoneThisToYou',
                    },
                    {
                        label: 'No', value: 'no', key: 'atAnyTimeHasAnyoneEverPhysicallyForced.everDoneThisToYou', defaultChecked: true
                    }
                ]
            }
        ],
    },
    {
        question: '6.  Other than experiences mentioned in earlier questions, has anyone ever touched private parts of your body, made you touch their body, or tried to make you to have sex against your wishes?',
        key: 'otherThanExperiencesMentionedInEarlierQuestions',
        type: 'radio',
        options: [
            {
                label: 'Yes', value: 'yes', key: 'otherThanExperiencesMentionedInEarlierQuestions.selected',
                subQuestions: [
                    {
                        question: 'If yes, at what age?', key: 'otherThanExperiencesMentionedInEarlierQuestions.whatAge',
                        type: 'text', options: [],placeholder: 'Type age in number',
                    },
                    {
                        question: 'If yes, how many times?', key: 'otherThanExperiencesMentionedInEarlierQuestions.howManyTimes',
                        type: 'radio',
                        options: [
                            {
                                label: '1', value: '1', key: 'otherThanExperiencesMentionedInEarlierQuestions.howManyTimes',
                            },
                            {
                                label: '2-4', value: '2-4', key: 'otherThanExperiencesMentionedInEarlierQuestions.howManyTimes',
                            },
                            {
                                label: '5-10', value: '5-10', key: 'otherThanExperiencesMentionedInEarlierQuestions.howManyTimes',
                            },
                            {
                                label: 'More than 10', value: 'moreThan10', key: 'otherThanExperiencesMentionedInEarlierQuestions.howManyTimes',
                            }
                        ]
                    },
                ]
            },
            {
                label: 'No', value: 'no', key: 'otherThanExperiencesMentionedInEarlierQuestions.selected',
            }
        ],
        followUp: [
            {
                question: 'If repeated, over what period?',
                key: 'otherThanExperiencesMentionedInEarlierQuestions.overWhatPeriod',
                type: 'radio',
                options: [
                    {
                        label: "6 mo. or less", "value": "6MonthsOrLess",
                        key: 'otherThanExperiencesMentionedInEarlierQuestions.overWhatPeriod',
                    },
                    {
                        label: "7 mos. - 2 yrs", "value": "7MonthsTo2Yrs",
                        key: 'otherThanExperiencesMentionedInEarlierQuestions.overWhatPeriod',
                    },
                    {
                        label: "more than 2 yrs. but less than 5 yrs", "value": "between2to5Yrs",
                        key: 'otherThanExperiencesMentionedInEarlierQuestions.overWhatPeriod',
                    },
                    {
                        label: "5 yrs. or more", "value": "moreThan5Yrs",
                        key: 'otherThanExperiencesMentionedInEarlierQuestions.overWhatPeriod',
                    },
                ]
            },
            {
                question: 'Who did this?', options: [],
                key: 'otherThanExperiencesMentionedInEarlierQuestions.whoDidThis', type: 'text',
                placeholder: '(Specify stranger, parent, etc.) '
            },
            {
                question: 'What age was this person?', options: [],
                key: 'otherThanExperiencesMentionedInEarlierQuestions.personAge', type: 'text',
                placeholder: '(Specify stranger, parent, etc.)'
            },
            {
                question: 'Has anyone else ever done this to you?',
                key: 'otherThanExperiencesMentionedInEarlierQuestions.everDoneThisToYou',
                type: 'radio',
                options: [
                    {
                        label: 'Yes', value: 'yes', key: 'otherThanExperiencesMentionedInEarlierQuestions.everDoneThisToYou',
                    },
                    {
                        label: 'No', value: 'no', key: 'otherThanExperiencesMentionedInEarlierQuestions.everDoneThisToYou',
                    }
                ]
            }
        ]
    },
    {
        question: '7.  When you were a child, did a parent, caregiver or other person ever slap you repeatedly, beat you, or otherwise attack or harm you?',
        key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly',
        type: 'radio',
        options: [
            {
                label: 'Yes', value: 'yes', key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.selected',
                subQuestions: [
                    {
                        question: 'If yes, at what age?', key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.whatAge',
                        type: 'text', options: [],placeholder: 'Type age in number',
                    },
                    {
                        question: 'If yes, how many times?', key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.howManyTimes',
                        type: 'radio',
                        options: [
                            {
                                label: '1', value: '1', key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.howManyTimes',
                            },
                            {
                                label: '2-4', value: '2-4', key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.howManyTimes',
                            },
                            {
                                label: '5-10', value: '5-10', key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.howManyTimes',
                            },
                            {
                                label: 'More than 10', value: 'moreThan10', key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.howManyTimes',
                            }
                        ]
                    }
                ]
            },
            {
                label: 'No', value: 'no', key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.selected',
            }
        ],
        followUp: [
            {
                question: 'If repeated, over what period?',
                key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.overWhatPeriod',
                type: 'radio',
                options: [
                    {
                        label: "6 mo. or less", "value": "6MonthsOrLess",
                        key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.overWhatPeriod',
                    },
                    {
                        label: "7 mos. - 2 yrs", "value": "7MonthsTo2Yrs",
                        key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.overWhatPeriod',
                    },
                    {
                        label: "more than 2 yrs. but less than 5 yrs", "value": "between2to5Yrs",
                        key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.overWhatPeriod',
                    },
                    {
                        label: "5 yrs. or more", "value": "moreThan5Yrs",
                        key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.overWhatPeriod',
                    },
                ]
            },
            {
                question: 'Describe force used against you',
                key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.forceUsedAgainstYou',
                type: 'text',   options: [], placeholder: '(e.g., fist, belt)'
            },
            {
                question: 'Were you ever injured?',
                key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.wereYouEverInjured',
                type: 'radio',
                options: [
                    {
                        label: 'Yes', value: 'yes', key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.wereYouEverInjured', 
                        subQuestions: [
                            {
                                question: 'If yes, describe', key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.describe',
                                type: 'text', options: [], placeholder: '(e.g., fist, belt)'
                            }
                        ]
                    },
                    {
                        label: 'No', value: 'no', key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.wereYouEverInjured',
                    }
                ]
            },
            {
                question: 'Who did this?',
                key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.whoDidThis',
                type: 'text',   options: [], placeholder: '(Relationship to you)'
            },
            {
                question: 'Has anyone else ever done this to you?',
                key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.repeatedlyWhoElse',
                type: 'radio',
                options: [
                    {
                        label: 'Yes', value: 'yes', key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.repeatedlyWhoElse', 
                    },
                    {
                        label: 'No', value: 'no', key: 'whenYouWereAChildDidAParentCaregiverOrOtherPersonEverSlapYouRepeatedly.repeatedlyWhoElse',
                    }
                ]
            },
        ]
    },
    {
        question: '8.  As an adult, have you ever been kicked, beaten, slapped around or otherwise physically harmed by a romantic partner, date, family member, stranger, or someone else?',
        key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround',
        type: 'radio',
        options: [
            {
                label: 'Yes', value: 'yes', key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.selected',
                subQuestions: [
                    {
                        question: 'If yes, at what age?', key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.whatAge',
                        type: 'text', options: [],placeholder: 'Type age in number',
                    },
                    {
                        question: 'If yes, how many times?', key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.howManyTimes',
                        type: 'radio',
                        options: [
                            {
                                label: '1', value: '1', key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.howManyTimes',
                            },
                            {
                                label: '2-4', value: '2-4', key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.howManyTimes',
                            },
                            {
                                label: '5-10', value: '5-10', key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.howManyTimes',
                            },
                            {
                                label: 'More than 10', value: 'moreThan10', key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.howManyTimes',
                            }
                        ]
                    },
                ]
            },
            {
                label: 'No', value: 'no', key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.selected',
            }
        ],
        followUp: [
            {
                question: 'If repeated, over what period?',
                key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.overWhatPeriod',
                type: 'radio',
                options: [
                    {
                        label: "6 mo. or less", "value": "6MonthsOrLess",
                        key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.overWhatPeriod',
                    },
                    {
                        label: "7 mos. - 2 yrs", "value": "7MonthsTo2Yrs",
                        key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.overWhatPeriod',
                    },
                    {
                        label: "more than 2 yrs. but less than 5 yrs", "value": "between2to5Yrs",
                        key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.overWhatPeriod',
                    },
                    {
                        label: "5 yrs. or more", "value": "moreThan5Yrs",
                        key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.overWhatPeriod',
                    },
                ]
            },
            {
                question: 'Describe force used against you',
                key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.forceUsedAgainstYou',
                type: 'text',   options: [], placeholder: '(e.g., fist, belt)'
            },
            {
                question: 'Were you ever injured?',
                key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.wereYouEverInjured',
                type: 'radio',
                options: [
                    {
                        label: 'Yes', value: 'yes', key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.wereYouEverInjured', 
                        subQuestions: [
                            {
                                question: 'If yes, describe', key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.describe',
                                type: 'text', options: [],
                            }
                        ]
                    },
                    {
                        label: 'No', value: 'no', key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.wereYouEverInjured',
                    }
                ]
            },
            {
                question: 'Who did this?',
                key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.whoDidThis',
                type: 'text',   options: [], placeholder: '(Relationship to you)'
            },
            {
                question: 'If sibling, what age was he/she',
                key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.siblingAge',
                type: 'text',   options: [],
            },
            {
                question: 'Has anyone else ever done this to you?',
                key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.whoElse',
                type: 'radio',
                options: [
                    {
                        label: 'Yes', value: 'yes', key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.whoElse', 
                    },
                    {
                        label: 'No', value: 'no', key: 'asAnAdultHaveYouEverBeenKickedBeatenSlappedAround.whoElse',
                    }
                ]
            },
        ]
    },
    {
        question: '9.  Has a parent, romantic partner, or family member repeatedly ridiculed you, put you down, ignored you, or told you were no good?',
        key: 'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou',
        type: 'radio',
        options: [
            {
                label: 'Yes', value: 'yes', key: 'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou.selected',
                subQuestions: [
                    {
                        question: 'If yes, at what age?', key: 'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou.whatAge',
                        type: 'text', options: [],placeholder: 'Type age in number',
                    },
                    {
                        question: 'If yes, how many times?', key: 'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou.howManyTimes',
                        type: 'radio',
                        options: [
                            {
                                label: '1', value: '1', key: 'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou.howManyTimes',
                            },
                            {
                                label: '2-4', value: '2-4', key: 'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou.howManyTimes',
                            },
                            {
                                label: '5-10', value: '5-10', key: 'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou.howManyTimes',
                            },
                            {
                                label: 'More than 10', value: 'moreThan10', key: 'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou.howManyTimes',
                            }
                        ]
                    },
                ]
            },
            {
                label: 'No', value: 'no', key: 'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou.selected',
            }
        ],
        followUp: [
            {
                question: 'If repeated, over what period?',
                key: 'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou.overWhatPeriod',
                type: 'radio',
                options: [
                    {
                        label: "6 mo. or less", "value": "6MonthsOrLess",
                        key: 'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou.overWhatPeriod',
                    },
                    {
                        label: "7 mos. - 2 yrs", "value": "7MonthsTo2Yrs",
                        key: 'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou.overWhatPeriod',
                    },
                    {
                        label: "more than 2 yrs. but less than 5 yrs", "value": "between2to5Yrs",
                        key: 'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou.overWhatPeriod',
                    },
                    {
                        label: "5 yrs. or more", "value": "moreThan5Yrs",
                        key: 'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou.overWhatPeriod',
                    },
                ]
            },
            {
                question: 'Who did this?',
                key: 'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou.whoDidThis',
                type: 'text',   options: [], placeholder: '(Relationship to you)'
            },
            {
                question: 'If sibling, what age was he/she',
                key: 'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou.siblingAge',
                type: 'text',   options: [],
            },
            {
                question: 'Has anyone else ever done this to you?',
                key: 'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou.whoElse',
                type: 'radio',
                options: [
                    {
                        label: 'Yes', value: 'yes', key: 'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou.whoElse', 
                    },
                    {
                        label: 'No', value: 'no', key: 'hasAParentRomanticPartnerOrFamilyMemberRepeatedlyRidiculedYou.whoElse',
                    }
                ]
            },
        ]
    },
    {
        question: '10.  Other than the experiences already covered, has anyone ever threatened you with a weapon like a knife or gun?',
        key: 'hasAnyoneEverThreatenedYouWithAWeapon',
        type: 'radio',
        options: [
            {
                label: 'Yes', value: 'yes', key: 'hasAnyoneEverThreatenedYouWithAWeapon.selected',
                subQuestions: [
                    {
                        question: 'If yes, at what age?', key: 'hasAnyoneEverThreatenedYouWithAWeapon.whatAge',
                        type: 'text', options: [],placeholder: 'Type age in number',
                    },
                    {
                        question: 'If yes, how many times?', key: 'hasAnyoneEverThreatenedYouWithAWeapon.howManyTimes',
                        type: 'radio',
                        options: [
                            {
                                label: '1', value: '1', key: 'hasAnyoneEverThreatenedYouWithAWeapon.howManyTimes',
                            },
                            {
                                label: '2-4', value: '2-4', key: 'hasAnyoneEverThreatenedYouWithAWeapon.howManyTimes',
                            },
                            {
                                label: '5-10', value: '5-10', key: 'hasAnyoneEverThreatenedYouWithAWeapon.howManyTimes',
                            },
                            {
                                label: 'More than 10', value: 'moreThan10', key: 'hasAnyoneEverThreatenedYouWithAWeapon.howManyTimes',
                            }
                        ]
                    },
                ]
            },
            {
                label: 'No', value: 'no', key: 'hasAnyoneEverThreatenedYouWithAWeapon.selected',
            }
        ],
        followUp: [
            {
                question: 'If repeated, over what period?',
                key: 'hasAnyoneEverThreatenedYouWithAWeapon.overWhatPeriod',
                type: 'radio',
                options: [
                    {
                        label: "6 mo. or less", "value": "6MonthsOrLess",
                        key: 'hasAnyoneEverThreatenedYouWithAWeapon.overWhatPeriod',
                    },
                    {
                        label: "7 mos. - 2 yrs", "value": "7MonthsTo2Yrs",
                        key: 'hasAnyoneEverThreatenedYouWithAWeapon.overWhatPeriod',
                    },
                    {
                        label: "more than 2 yrs. but less than 5 yrs", "value": "between2to5Yrs",
                        key: 'hasAnyoneEverThreatenedYouWithAWeapon.overWhatPeriod',
                    },
                    {
                        label: "5 yrs. or more", "value": "moreThan5Yrs",
                        key: 'hasAnyoneEverThreatenedYouWithAWeapon.overWhatPeriod',
                    },
                ]
            },
            {
                question: 'Describe nature of threat',
                key: 'hasAnyoneEverThreatenedYouWithAWeapon.natureOfThreat',
                type: 'text',   options: [],
            },
            {
                question: 'Who did this?',
                key: 'hasAnyoneEverThreatenedYouWithAWeapon.whoDidThis',
                type: 'text',   options: [], placeholder: '(Relationship to you)'
            },
            {
                question: 'Has anyone else ever done this to you?',
                key: 'hasAnyoneEverThreatenedYouWithAWeapon.whoElse',
                type: 'radio',
                options: [
                    {
                        label: 'Yes', value: 'yes', key: 'hasAnyoneEverThreatenedYouWithAWeapon.whoElse', 
                    },
                    {
                        label: 'No', value: 'no', key: 'hasAnyoneEverThreatenedYouWithAWeapon.whoElse',
                    }
                ]
            },
        ]
    },
    {
        question: '11.  Have you ever been present when another person was killed? Seriously injured? Sexually or physically assaulted?',
        key: 'haveYourEverBeenPresentWhenAnotherPersonWasKilled',
        type: 'radio',
        options: [
            {
                label: 'Yes', value: 'yes', key: 'haveYourEverBeenPresentWhenAnotherPersonWasKilled.selected',
                subQuestions: [
                    {
                        question: 'If yes, at what age?', key: 'haveYourEverBeenPresentWhenAnotherPersonWasKilled.whatAge',
                        type: 'text', options: [],placeholder: 'Type age in number',
                    }
                ]
            },
            {
                label: 'No', value: 'no', key: 'haveYourEverBeenPresentWhenAnotherPersonWasKilled.selected',
            }
        ],
        followUp: [
            {
                question: 'Please describe what you witnessed',
                key: 'haveYourEverBeenPresentWhenAnotherPersonWasKilled.natureOfThreat',
                type: 'text',   options: [],
            },
            {
                question: 'Was your own life in danger?',
                key: 'haveYourEverBeenPresentWhenAnotherPersonWasKilled.killedLifeInDanger',
                type: 'radio',
                options: [
                    {
                        label: 'Yes', value: 'yes', key: 'haveYourEverBeenPresentWhenAnotherPersonWasKilled.killedLifeInDanger', 
                    },
                    {
                        label: 'No', value: 'no', key: 'haveYourEverBeenPresentWhenAnotherPersonWasKilled.killedLifeInDanger',
                    }
                ]
            },
        ]
    },
    {
        question: '12.  Have you ever been in any other situation where you were seriously injured or your life was in danger (e.g., involved in military combat or living in a war zone)?',
        key: 'haveYouEverBeenInAnyOtherSituationWhereYouWereSeriouslyInjured',
        type: 'radio',
        options: [
            {
                label: 'Yes', value: 'yes', key: 'haveYouEverBeenInAnyOtherSituationWhereYouWereSeriouslyInjured.selected',
                subQuestions: [
                    {
                        question: 'If yes, at what age?', key: 'haveYouEverBeenInAnyOtherSituationWhereYouWereSeriouslyInjured.whatAge',
                        type: 'text', options: [],placeholder: 'Type age in number',
                    }
                ]
            },
            {
                label: 'No', value: 'no', key: 'haveYouEverBeenInAnyOtherSituationWhereYouWereSeriouslyInjured.selected',
            }
        ],
        followUp: [
            {
                question: 'Please describe',
                key: 'haveYouEverBeenInAnyOtherSituationWhereYouWereSeriouslyInjured.describe',
                type: 'text',   options: [],
            }
        ]
    },
    {
        question: '13.  Have you ever been in any other situation that was extremely frightening or horrifying, or one in which you felt extremely helpless, that you haven\'t reported?',
        key: 'haveYouEverBeenInAnyOtherSituationWhereYouWereExtremelyFrightened',
        type: 'radio',
        options: [
            {
                label: 'Yes', value: 'yes', key: 'haveYouEverBeenInAnyOtherSituationWhereYouWereExtremelyFrightened.selected',
                subQuestions: [
                    {
                        question: 'If yes, at what age?', key: 'haveYouEverBeenInAnyOtherSituationWhereYouWereExtremelyFrightened.whatAge',
                        type: 'text', options: [],placeholder: 'Type age in number',
                    }
                ]
            },
            {
                label: 'No', value: 'no', key: 'haveYouEverBeenInAnyOtherSituationWhereYouWereExtremelyFrightened.selected',
            }
        ],
        followUp: [
            {
                question: 'Please describe',
                key: 'haveYouEverBeenInAnyOtherSituationWhereYouWereExtremelyFrightened.describe',
                type: 'text',   options: [],
            }
        ]
    }
]