import React, {useEffect, useState} from "react";
import {
    ILockIntakeResponse,
    IProspect,
    IReferenceData,
    NotesStatus,
    Roles
} from "../../models";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {fetchAceScreener, intakeFormSave} from "../../store/actions/counsellor/prospects.action";
import {StateParams} from "../../store/reducers";
import IntakeComponentV2 from "../clinical-notes/intake/IntakeComponentV2";
import {
    useForm,
    Controller,
    UseFormRegister,
    Control,
    UseFormWatch,
    FormState,
    UseFormReset,
    UseFormTrigger,
    FieldErrorsImpl,
    FieldArrayWithId,
    UseFieldArrayRemove,
    UseFieldArrayAppend,
    UseFormSetValue,
    UseFormGetValues
} from "react-hook-form"
import {Inputs} from "../clinical-notes/intake";
import {canEditCounsellorNotes} from "../clinical-notes/accessControl";

type Props = {
    prospect: IProspect, sourceRefData: IReferenceData,
    intakeNotesStatus: NotesStatus | undefined,
    register: UseFormRegister<Inputs>
    control: Control<Inputs, any>
    watch: UseFormWatch<Inputs>
    errors: Partial<FieldErrorsImpl<Inputs>>
    reset: UseFormReset<Inputs>
    trigger: UseFormTrigger<Inputs>
    addendums: FieldArrayWithId<Inputs, "newAddendum", "id">[]
    append: UseFieldArrayAppend<Inputs, "newAddendum">
    remove: UseFieldArrayRemove
    getValues: UseFormGetValues<Inputs>
}

export const ProspectIntakeForm: React.FC<Props> = ({
                                                        register,
                                                        control,
                                                        watch,
                                                        errors,
                                                        reset,
                                                        trigger,
                                                        prospect,
                                                        sourceRefData,
                                                        intakeNotesStatus,
                                                        addendums,
                                                        append,
                                                        remove,
                                                        getValues
                                                    }) => {


    const dispatch = useDispatch();
    const role = useSelector((state: StateParams) => state.account.role)
    const [canEdit, setCanEdit] = useState<boolean>(false)

    useEffect(() => {
        const canEditNotes = canEditCounsellorNotes(intakeNotesStatus, role)
        setCanEdit(canEditNotes)
    }, [intakeNotesStatus])

    useEffect(() => {
        dispatch(fetchAceScreener(prospect.sourcePatientId, prospect.referredByPracticeId));
        const canEditNotes = canEditCounsellorNotes(intakeNotesStatus, role)
        setCanEdit(canEditNotes)
    }, [prospect.id]);

    return (
        <>
            <IntakeComponentV2 {...{register, control, watch, errors, reset, addendums, append, remove, getValues}}
                               notesStatus={intakeNotesStatus} sourceRefData={sourceRefData} prospect={prospect}
                               canEdit={canEdit}/>
        </>
    )
}
