import { Radio } from "@material-tailwind/react";
import {
    Control,
    FieldErrorsImpl,
    useFieldArray,
    UseFormGetValues,
    UseFormRegister,
    UseFormWatch
} from "react-hook-form";
import { Inputs } from ".";
import { socialDeterminantsOfHealth } from "../../../assets/data/socialDeterminantsOfHealth";

const SocialDeterminantsHealth: React.FC<{
    control: Control<Inputs, any>, register: UseFormRegister<Inputs>, 
    watch: UseFormWatch<Inputs>, errors: Partial<FieldErrorsImpl<Inputs>>, 
    renderErrorMessage: (message: string | null | undefined) => "" | JSX.Element | null | undefined, canEdit: boolean,
}> = ({ control, register, watch, errors, renderErrorMessage, canEdit }) => {

    const renderQuestionWithRadio = (question: string, data: {label: string, value: string}[], inputKey: keyof Inputs, classNames: string = 'grid grid-cols-4') => {
        return (<div className="py-1">
            <label className="text-md">{question}</label>
            <div className={classNames}>
                {data.map((x, i) => (<>
                    <Radio id={inputKey+i} {...register(inputKey)} label={x.label} value={x.value} />
                </>))}
            </div>
        </div>)
    }

    const renderLabelWithTextbox = (label: string, inputKey: keyof Inputs, placeholder?: string) => {
        return (<div>
            <label>{label}</label>
            <input type="text" className="mt-1 w-full outline-none rounded-md block" {...register(inputKey, {required: true})} placeholder="Type here" />
        </div>)
    }

    return (
        <fieldset className={'divide-y-4'} disabled={!canEdit}>
            <div className="py-4">
                <label className="text-lg uppercase">social determinants of health</label>
                <label className="block text-lg uppercase text-sjOrange">Personal Characteristics</label>
                <div className="py-4">
                    {socialDeterminantsOfHealth.religion && renderQuestionWithRadio(socialDeterminantsOfHealth.religion.question, socialDeterminantsOfHealth.religion.options, 'religion')}
                    <input type="text" className="mt-1 w-full outline-none rounded-md" hidden={watch('religion') !== 'religionOther'} {...register('religionOther')} 
                            placeholder="Type here"/>
                </div>
                <div className="py-4">
                    {socialDeterminantsOfHealth.ethnicity && renderQuestionWithRadio(socialDeterminantsOfHealth.ethnicity.question, socialDeterminantsOfHealth.ethnicity.options, 'ethnicity')}
                    <input type="text" className="mt-1 w-full outline-none rounded-md" hidden={watch('ethnicity') !== 'ethnicityOther'} {...register('ethnicityOther')} 
                            placeholder="Type here"/>
                </div>
                {socialDeterminantsOfHealth.sourceOfIncome && renderQuestionWithRadio(socialDeterminantsOfHealth.sourceOfIncome.question, socialDeterminantsOfHealth.sourceOfIncome.options, 'sourceOfIncome')}
                {socialDeterminantsOfHealth.retiredMilitary && renderQuestionWithRadio(socialDeterminantsOfHealth.retiredMilitary.question, socialDeterminantsOfHealth.retiredMilitary.options, 'retiredMilitary')}
                <div className="py-1">
                    <label className="text-md mt-6">5. What language are you most comfortable speaking?</label>
                    {errors.language && renderErrorMessage(errors.language.message)}
                    <input type="text" className="mt-1 w-1/2 outline-none rounded-md block" {...register('language')} 
                            placeholder="Type here"/>
                </div>
            </div>
            <div className="py-4">
                <label className="block text-lg mt-6 text-sjOrange uppercase">Family & Home</label>
                <div className="py-2">
                    <label className="text-md">6. How many family members, including yourself, do you currently live with?</label>
                    <div className="grid grid-cols-2">
                        <input type="text" className="mt-1 w-full outline-none rounded-md block" {...register('noOfFamilyMembersOther')} 
                            placeholder="Type here"/>
                        <Radio id="noOfFamilyMembers" {...register('noOfFamilyMembers')} label={'I choose not to answer this question'} value={'skip'} />
                    </div>
                </div>
                {socialDeterminantsOfHealth.housingSituationToday && renderQuestionWithRadio(socialDeterminantsOfHealth.housingSituationToday.question, socialDeterminantsOfHealth.housingSituationToday.options, 'housingSituationToday', 'grid grid-cols-3')}
                {socialDeterminantsOfHealth.losingHousing && renderQuestionWithRadio(socialDeterminantsOfHealth.losingHousing.question, socialDeterminantsOfHealth.losingHousing.options, 'losingHousing', 'grid grid-cols-3')}
                <div className="py-2">
                    <label className="text-md">9. What address do you live at?</label>
                    {(errors.address?.street || errors.address?.city || errors.address?.state ||
                        errors.address?.zipCode) && renderErrorMessage('This field is required.')}
                    <div className="grid grid-cols-3 gap-4 mt-2">
                        {renderLabelWithTextbox('Street', 'address.street' as any)}
                        {renderLabelWithTextbox('City', 'address.city' as any)}
                        {renderLabelWithTextbox('State', 'address.state' as any)}
                        {renderLabelWithTextbox('Zip code', 'address.zipCode' as any)}
                    </div>
                </div>
            </div>
            <div className="py-4">
                <label className="block text-lg mt-6 text-sjOrange uppercase">Money & Resources</label>
                {socialDeterminantsOfHealth.education && renderQuestionWithRadio(socialDeterminantsOfHealth.education.question, socialDeterminantsOfHealth.education.options, 'education')}
                {socialDeterminantsOfHealth.currentEmployment && renderQuestionWithRadio(socialDeterminantsOfHealth.currentEmployment.question, socialDeterminantsOfHealth.currentEmployment.options, 'currentEmployment')}
                {socialDeterminantsOfHealth.insurance && renderQuestionWithRadio(socialDeterminantsOfHealth.insurance.question, socialDeterminantsOfHealth.insurance.options, 'insurance')}
                <div className="py-2">
                    <label className="text-md">13. During the past year, what was the total combined income for you and the
                        family members you live with? This information will help us determine if you are eligible for any benefits.</label>
                    <div className="grid grid-cols-2">
                        <input type="text" className="mt-1 w-full outline-none rounded-md block" {...register('lastYearFamilyIncomeOther')}
                            placeholder="Type here" />
                        <Radio id="lastYearFamilyIncome" {...register('lastYearFamilyIncome')} label={'I choose not to answer this question'} value={'skip'} />
                    </div>
                </div>
                <div>
                    <label className="text-md">14. In the past year, have you or any family members you live with been unable to
                        get any of the following when it was really needed? Check all that apply</label>
                    {socialDeterminantsOfHealth.food && renderQuestionWithRadio(socialDeterminantsOfHealth.food.question, socialDeterminantsOfHealth.food.options, 'food')}
                    {socialDeterminantsOfHealth.clothing && renderQuestionWithRadio(socialDeterminantsOfHealth.clothing.question, socialDeterminantsOfHealth.clothing.options, 'clothing')}
                    {socialDeterminantsOfHealth.utilities && renderQuestionWithRadio(socialDeterminantsOfHealth.utilities.question, socialDeterminantsOfHealth.utilities.options, 'utilities')}
                    {socialDeterminantsOfHealth.childCare && renderQuestionWithRadio(socialDeterminantsOfHealth.childCare.question, socialDeterminantsOfHealth.childCare.options, 'childCare')}
                    {socialDeterminantsOfHealth.healthCare && renderQuestionWithRadio(socialDeterminantsOfHealth.healthCare.question, socialDeterminantsOfHealth.healthCare.options, 'healthCare')}
                    {socialDeterminantsOfHealth.phone && renderQuestionWithRadio(socialDeterminantsOfHealth.phone.question, socialDeterminantsOfHealth.phone.options, 'phone')}
                    <div className="py-2">
                        <label className="text-md">Other</label>
                        <input type="text" className="mt-1 w-full outline-none rounded-md block" {...register('familyMembersOther')}
                            placeholder="Type here" />
                    </div>
                </div>
                {socialDeterminantsOfHealth.transportation && renderQuestionWithRadio(socialDeterminantsOfHealth.transportation.question, socialDeterminantsOfHealth.transportation.options, 'transportation')}
            </div>
            <div className="py-4">
                <label className="text-lg mt-6 text-sjOrange uppercase">Social and Emotional Health</label>
                {socialDeterminantsOfHealth.social && renderQuestionWithRadio(socialDeterminantsOfHealth.social.question, socialDeterminantsOfHealth.social.options, 'social')}
                {socialDeterminantsOfHealth.emotional && renderQuestionWithRadio(socialDeterminantsOfHealth.emotional.question, socialDeterminantsOfHealth.emotional.options, 'emotional')}
            </div>
            <div className="py-4">
                <label className="text-lg mt-6 text-sjOrange uppercase">Optional Additional Questions</label>
                {socialDeterminantsOfHealth.pastYearSpentInJail && renderQuestionWithRadio(socialDeterminantsOfHealth.pastYearSpentInJail.question, socialDeterminantsOfHealth.pastYearSpentInJail.options, 'pastYearSpentInJail')}
                {socialDeterminantsOfHealth.refugee && renderQuestionWithRadio(socialDeterminantsOfHealth.refugee.question, socialDeterminantsOfHealth.refugee.options, 'refugee')}
                {socialDeterminantsOfHealth.physicallyAndEmotionallySafe && renderQuestionWithRadio(socialDeterminantsOfHealth.physicallyAndEmotionallySafe.question, socialDeterminantsOfHealth.physicallyAndEmotionallySafe.options, 'physicallyAndEmotionallySafe')}
                {socialDeterminantsOfHealth.afraidOfYourPartner && renderQuestionWithRadio(socialDeterminantsOfHealth.afraidOfYourPartner.question, socialDeterminantsOfHealth.afraidOfYourPartner.options, 'afraidOfYourPartner')}
            </div>
        </fieldset>
    )
}

export default SocialDeterminantsHealth