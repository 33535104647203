import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router";
import { SideBarButtonProps, withActiveComponentTreatment, withIconStyling } from "./WithActiveComponentTreatment";

const SoapButton: React.FC<PropsWithChildren<SideBarButtonProps> & {}> = ({ style, navigateTo, iconRenderer }) => {
    const navigate = useNavigate()

    const navigateToSoap = () => {
        navigate(navigateTo)
    }

    function render() {
        return <div className="flex">
            <button
                className={style}
                onClick={navigateToSoap}>
                {iconRenderer("S.O.A.P")}
            </button>
        </div>;
    }

    return render()
}

export default withActiveComponentTreatment(withIconStyling(SoapButton))
