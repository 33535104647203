import React, { FC, ReactNode, useState } from 'react';
import Modal from 'react-modal';
import { Button } from '../generic/Buttons';
import { useNavigate } from 'react-router-dom';

interface NotesErrorModalProps {
    isOpen: boolean
}

const NotesErrorModal: FC<NotesErrorModalProps> = ({
    isOpen,
}) => {
    const navigate = useNavigate()

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isOpen}
            key={'notesErrorModal'}
            shouldCloseOnOverlayClick={false}
            className={`relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white `}
            contentLabel="Notes Error Modal"
            shouldReturnFocusAfterClose={false}
        >
            <div className="mt-3 text-center">
                <div className="mt-2 px-4 py-3 text-sjTestPositive">
                    {`This page doesn't exist. To view notes of the patient, go to notes tab in patient details.`}
                </div>
                <div className="items-center px-4 py-3">
                    <Button onClick={(e: any) => {
                        e.preventDefault();
                        navigate(-2);
                    }}
                        className={"!bg-sjOrange !text-white !ml-2"}
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default NotesErrorModal
