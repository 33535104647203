import { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PatientSessionStatus, ReferredPatientCallStatusType, PatientSessionStatusType, ReferredPatientCallStatus, SessionTypes, IAppointmentWithTherapist } from '../models';
import { clearSessionStatus, patchAppointmentListWithStatus, patchSessionStatus, updateSessionStatus } from '../store/actions/counsellor/appointments.action';
import { clearUpdatePatientInfoStatus, updatePatientInfo } from '../store/actions/counsellor/patients.action';
import { patchPatientInfoStatus } from '../store/actions/counsellor/prospects.action';
import { StateParams } from '../store/reducers';

interface Props {
    sourcePatientId: string
    practiceId: string
}

const sessionToCallStatusMapping: {[key: PatientSessionStatusType]: ReferredPatientCallStatusType} = {
    [PatientSessionStatus.noShow]: 'no_show',
    [PatientSessionStatus.inProgressUnderscored]: 'in_progress'
}

const SESSION_TYPES_WHICH_CAN_BE_PATCHED_FROM_APPOINTMENTS = [SessionTypes.TreatmentPlan, SessionTypes.FollowUp, SessionTypes.Catchup]

export const useUpdateSessionStatus = ({sourcePatientId, practiceId}: Props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isAppointmentStatusUpdated } = useSelector((state: StateParams) => state.appointments)
    const { updatePatientInfoSuccess } = useSelector((state: StateParams) => state.patients)
    const [callStatus, setCallStatus] = useState<keyof ReferredPatientCallStatus>()
    const [appointmentStatus, setAppointmentStatus] = useState<PatientSessionStatusType>()
    const [navigationUrl, setNavigationUrl] = useState<string>()
    const [session, setSession] = useState<IAppointmentWithTherapist>()

    useEffect(() => {
        if (isAppointmentStatusUpdated) {
            if(callStatus){
                batch(() => {
                    dispatch(updatePatientInfo(sourcePatientId, practiceId, {
                        referredCallStatus: callStatus as unknown as ReferredPatientCallStatus
                    }))
                    dispatch(clearSessionStatus())
                })
            }
            if (navigationUrl) navigate(navigationUrl)
        }
    }, [isAppointmentStatusUpdated])

    useEffect(() => {
        const canMarkAsNoShow = isAppointmentStatusUpdated && session?.sessionType && appointmentStatus === 'no_show' && SESSION_TYPES_WHICH_CAN_BE_PATCHED_FROM_APPOINTMENTS.includes(session?.sessionType as any)
        if(canMarkAsNoShow) {
            dispatch(patchAppointmentListWithStatus({appointmentId: session.id, status: 'no_show'}))
        }
    }, [isAppointmentStatusUpdated, session?.sessionType])

    useEffect(() => {
        if(updatePatientInfoSuccess && callStatus) {
            batch(() => {
                dispatch(patchPatientInfoStatus({referredCallStatus: callStatus as keyof ReferredPatientCallStatus}))
                dispatch(patchSessionStatus({sessionStatus: appointmentStatus || ''}))
                dispatch(clearUpdatePatientInfoStatus())
            })
        }
    }, [updatePatientInfoSuccess])

    const handleUpdatingSessionStatus = (session: IAppointmentWithTherapist, sessionStatus: PatientSessionStatusType, sessionType: SessionTypes, navigationPath?: string) => {
        setNavigationUrl(navigationPath)
        dispatch(clearSessionStatus())
        sessionType===SessionTypes.Intake && setCallStatus(sessionToCallStatusMapping[sessionStatus])
        setAppointmentStatus(sessionStatus)
        if(session.id) {
            setSession(session)
            dispatch(updateSessionStatus({ sessionId: session.id, status: sessionStatus }))
        }
    }

    return handleUpdatingSessionStatus
}
