import React, { useEffect } from 'react'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import ProviderDetails from '../../components/provider/ProviderDetails'
import {RoutePaths} from "../../shared/Utils";

const ProviderProfile = () => {
    const navigate = useNavigate()

    return (
            <div className='mt-1 px-5 flex-1'>
                <div className="flex flex-row">
                    <ChevronLeftIcon className="h-5 w-5 m-1 cursor-pointer" aria-hidden="true" onClick={() => navigate(`${RoutePaths.physicianCare.prospects}?page=1`)} />
                    <h1 className="text-xl font-semibold"> Provider Details</h1>
                </div>
                <ProviderDetails />
            </div>
    )
}

export default ProviderProfile