import {
    HOME_CONTENT_READY,
    RESET_NAVIGATION,
    SET_ACTIVE_NAVIGATION_COMPONENT
} from "../../actions/counsellor/navigation.actions";
import {NavigationComponentType} from "../../../navigation/NavigationComponentType";
import {SIGN_OUT_ACCOUNT} from "../../actions/common";

export interface NavigationParams {
    readyWithContent?: string
    activeComponent?: NavigationComponentType
}

const initialState: NavigationParams = {
    readyWithContent: undefined,
    activeComponent: undefined
}

const NavigationReducer = (state: NavigationParams = initialState, action: any): NavigationParams => {

    switch (action.type) {

        case HOME_CONTENT_READY:
            state = {...state, readyWithContent: HOME_CONTENT_READY}
            return state

        case RESET_NAVIGATION:
            state = {...state, readyWithContent: undefined}
            return state

        case SET_ACTIVE_NAVIGATION_COMPONENT:
            state = {...state, activeComponent: action.payload.activeComponent}
            return state

        case SIGN_OUT_ACCOUNT:
            state = {...state, ...initialState}
            return state
        default:
            return state;
    }

}

export default NavigationReducer