import { Radio, Typography } from "@material-tailwind/react"

interface Props {
    value: any;
    label: React.ReactNode;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
    name: string
    isChecked?: boolean
}

const RadioOption: React.FC<Props> = ({
    value,
    label,
    onChange,
    name,
    isChecked = false,
}) => {
    return (
        <div className="flex items-center h-12 rounded-md">
            <Radio
                required
                className="text-md"
                onChange={onChange}
                name={name}
                value={value}
                checked={isChecked}
                label={<Typography variant="small" className="flex font-xs">{label}</Typography>}
            />
        </div>
    );
};

export default RadioOption