import {AnyAction} from "redux";
import {IPatientWithIntake, IProspect, IRefer, IReferenceData} from "../../../models";

export const FETCH_PATIENT_WITH_INTAKE = "FETCH_PATIENT_WITH_INTAKE"
export const FETCH_PATIENT_WITH_INTAKE_SUCCESS = "FETCH_PATIENT_WITH_INTAKE_SUCCESS"
export const FETCH_PATIENT_WITH_INTAKE_FAILED = "FETCH_PATIENT_WITH_INTAKE_FAILED"

export const CREATE_OR_FETCH_INTAKE = "CREATE_OR_FETCH_INTAKE"
export const CREATE_OR_FETCH_INTAKE_SUCCESS = "CREATE_OR_FETCH_INTAKE_SUCCESS"
export const CREATE_OR_FETCH_INTAKE_FAILED = "CREATE_INTAKE_FAILED"
export const fetchPatientWithIntake = (patientId: string) => {
    return {type: FETCH_PATIENT_WITH_INTAKE, payload: {patientId: patientId}}
}
export const fetchingPatientWithIntakeFailed = (payload: { error: string }): AnyAction => {
    return { type: FETCH_PATIENT_WITH_INTAKE_FAILED, payload }
}
export const fetchedPatientWithIntake = (intakeWithPatient: IPatientWithIntake | null): AnyAction => {
    return { type: FETCH_PATIENT_WITH_INTAKE_SUCCESS, payload: { intakeDetails: intakeWithPatient } }
}
export const createOrFetchExistingIntake = (patientId: string) => {
    return {type: CREATE_OR_FETCH_INTAKE, payload: {patientId: patientId}}
}
export const createIntakeFailed = (payload: { error: string }): AnyAction => {
    return { type: CREATE_OR_FETCH_INTAKE_FAILED, payload }
}
export const createdIntake = (intake: IReferenceData ): AnyAction => {
    return { type: CREATE_OR_FETCH_INTAKE_SUCCESS, payload: { intake: intake } }
}
