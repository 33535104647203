import {call, put, takeLatest} from "@redux-saga/core/effects";
import {callFinished, callInProgress} from "../../actions/loading.action";
import {apiHttpClient} from "../../../lib";
import {AuthState, IPracticeResponse} from "../../../models";
import {AxiosResponse} from "axios";
import {Either} from "monet";
import {CheckedError} from "../../../types/ServiceError";
import {catchErrorMessage} from "../../../lib/api-helpers";
import {
    FETCH_PRACTICE_DETAILS,
    fetchedPracticeDetails,
    fetchingPracticeDetailsFailed
} from "../../actions/counsellor/practice.action";


const apiFetchPracticeDetails = (action: { type: string, authState: AuthState, payload: { practiceId: string } }) => {
    const url = `/practice/${action.payload.practiceId}`;
    return apiHttpClient.get<Either<CheckedError, IPracticeResponse>>(url, { headers: { "x-auth-token": action.authState.token } })
        .then((response: AxiosResponse) => {
            return Either.right(response.data as IPracticeResponse)
        }).catch(e => catchErrorMessage(e));
}

function* fetchPracticeDetails(action: { type: string, authState: AuthState, payload: {practiceId: string}}) {
    try {
        console.log(`AccountSaga:fetchPracticeDetails`)
        yield put(callInProgress())
        const eitherIfExistsResponse: Either<CheckedError, IPracticeResponse> = yield call(apiFetchPracticeDetails, action)
        if(eitherIfExistsResponse.isLeft()) {
            const error = eitherIfExistsResponse.left()
            if(error.isChecked) {
                console.log("AccountSaga: fetchPracticeDetails: Encountered a Checked Error", error.message)
                yield put(fetchingPracticeDetailsFailed({error: error.message}));
            } else {
                yield put(fetchingPracticeDetailsFailed({error: error.message}));
            }
        } else {
            const practice = eitherIfExistsResponse.right().data;
            console.log(`AccountSaga:fetchPracticeDetails->\n fetched practice details:  `)
            yield put(fetchedPracticeDetails({practice}));
        }
    } catch (e) {
        console.log(`AccountSaga:fetchPracticeDetails->\n error ${e}`)
        yield put(fetchingPracticeDetailsFailed({error: "Error fetching practice details!"}));
    } finally {
        yield put(callFinished())
    }
}

export default function* practiceSaga() {
    yield takeLatest(FETCH_PRACTICE_DETAILS, fetchPracticeDetails)
}