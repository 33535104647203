import {Tab} from "@headlessui/react"
import classNames from "classnames"
import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {IProspect, PatientJourney, PatientJourneyType, Roles} from "../../../models"
import {StateParams} from "../../../store/reducers"
import PatientJourneyComponent from "./PatientJourneyComponent"
import PatientSummary from "./PatientSummary"
import {clearSWScreenerInsights, clearScreenerQuestions, fetchPatientJourney} from "../../../store/actions/counsellor/patients.action";
import _ from "lodash";
import {useFlags} from "flagsmith/react";
import { RoutePaths } from "../../../shared/Utils"
import { MenuAction } from "../../generic/MenuAction"
import PatientInsights from "./PatientInsights"
import { useLocation } from "react-router-dom"

interface PatientEngagementInterface {
    className?: string;
    label: string;
    value: string;
    roles?: Roles[];
    count?: number;
    canView: boolean;
}

const PatientEngagement: React.FC<{ patient: IProspect }> = ({patient}) => {

    const {id: patientProspectId, sourcePatientId} = patient;
    const dispatch = useDispatch();
    const location = useLocation()
    const {
        patientJourney
    } = useSelector((state: StateParams) => state.patients);
    const userRole = useSelector((state: StateParams) => state.account.role);

    const canViewSWScreenerInsights = useFlags(['can_view_sw_screener_insights'])?.can_view_sw_screener_insights?.enabled

    const TABS: PatientEngagementInterface[] = [
        {label: 'Summary', value: 'Summary', className: 'patient-summary', roles: [Roles.Physician], canView: true},
        {label: 'Insights', value: 'Insights', className: 'patient-insights', canView: canViewSWScreenerInsights, roles: [Roles.Counsellor, Roles.Physician]},
        {label: 'Notes', value: 'Notes', className: 'patient-notes', canView: true},
        {label: 'Screeners', value: 'Screeners', count: 0, className: 'patient-screeners', canView: true},
    ];

    const filteredTabs = TABS.filter((tab) => tab.roles ? tab.roles.some(role => userRole === role) : tab.canView ? true : false);
    const [tabs, setTabs] = useState(filteredTabs);
    const allowBackDatedSessions = useFlags(['allow_back_dated_sessions'])?.allow_back_dated_sessions?.enabled

    // Mapping patient journey to notes and screeners for better display
    const [patientJourneyMapping, setPatientJourneyMapping] = useState<{ [key in PatientJourneyType]: PatientJourney[] }>({
        notes: [],
        screeners: []
    });

    useEffect(() => {
        if (patientProspectId) {
            dispatch(clearScreenerQuestions());
            dispatch(fetchPatientJourney(patientProspectId, sourcePatientId));
        }
    }, [dispatch, patientProspectId, sourcePatientId]);

    // Mapping patient journey to notes and screeners for better display
    useEffect(() => {
        if (patientJourney) {
            const mapping: { [key in PatientJourneyType]: PatientJourney[] } = {
                notes: [],
                screeners: []
            };
            patientJourney.forEach((journey: PatientJourney) => {
                const type: 'notes' | 'screeners' = journey.screener ? 'screeners' : 'notes'
                mapping[type].push(journey);
            });
            setPatientJourneyMapping(mapping);
        }
    }, [patientJourney]);

    React.useEffect(() => {
        if(location.pathname) {
            dispatch(clearSWScreenerInsights())
        }
    }, [location])


    // Update the count of pending screeners in the Screeners tab
    useEffect(() => {
        const pendingScreenersCount = patientJourneyMapping.screeners?.filter((journey: PatientJourney) => !journey?.isComplete && !journey?.isExpired)?.length;
        setTabs(_.cloneDeep(filteredTabs).map(tab => {
            if (tab.value === 'Screeners') {
                return {
                    ...tab,
                    count: pendingScreenersCount
                }
            }
            return tab;
        }));
    }, [patientJourneyMapping]);

    return (
        <section className="bg-sjWhite" style={{paddingLeft: 25, paddingRight: 25, paddingBottom: 25}}>
            <div className="mt-5">
                <Tab.Group>
                    <Tab.List className="w-full grid grid-cols-6 border-b border-solid border-b-sjGrey-200">
                        {tabs.map((tab) => (
                            <Tab
                                key={tab.value}
                                className={({selected}) => {
                                    return classNames(
                                        tab.className,
                                        'col-span-1',
                                        'mr-5 mt-2 py-2.5 text-sm font-medium',
                                        'ring-offset-2 focus:outline-none border-b border-solid border-b-4 ',
                                        selected
                                            ? 'text-sjOrange border-b-sjOrange '
                                            : 'text-sjGrey hover:text-sjOrange border-b-transparent'
                                    )
                                }}
                            >
                                {tab.label} {tab.count ? `(${tab.count})` : ''}
                            </Tab>
                        ))}
                        {allowBackDatedSessions && <MenuAction
                            value="Add Backdated Notes"
                            classname="col-start-7 self-center mt-4"
                            navigationUrl={`${RoutePaths.collaborativeCare.patients.journey.scheduleBackDatedAppointment}`}
                        />}
                    </Tab.List>
                    {filteredTabs.map((tab, index) => (
                        <Tab.Panel key={index}>
                            {tab.value === 'Summary' && <PatientSummary patientProspectId={patient.id}
                                                                        sourcePatientId={patient.sourcePatientId}/>}
                            {tab.value === 'Insights' && <PatientInsights patient={patient} />}
                            {tab.value === 'Notes' && <PatientJourneyComponent
                                patientJourney={patientJourneyMapping.notes}
                                patientProspectId={patient.id} sourcePatientId={patient.sourcePatientId}/>}
                            {tab.value === 'Screeners' && <PatientJourneyComponent
                                patientJourney={patientJourneyMapping.screeners}
                                patientProspectId={patient.id} sourcePatientId={patient.sourcePatientId}/>}
                        </Tab.Panel>
                    ))}
                </Tab.Group>
            </div>
        </section>
    )
}

export default PatientEngagement;
