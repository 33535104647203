import React from "react";
import {Popover, PopoverContent, PopoverHandler,} from "@material-tailwind/react";
import {BsThreeDotsVertical} from "react-icons/bs";
import {useNavigate} from "react-router-dom";

export const LocationAction: React.FC<{ value: string }> = ({ value }) => {
    const navigate = useNavigate()

    return (
        <Popover placement="bottom-end" >
            <PopoverHandler>
                <button><BsThreeDotsVertical className='w-5 h-5 cursor-pointer' /></button>
            </PopoverHandler>
            <PopoverContent className="w-30">
                <button
                    onClick={() => navigate(`/locations/edit/${value}`)}
                    className="block text-sm text-gray-700 hover:bg-gray-100"
                >
                    Edit
                </button>
                {/* <button
                    onClick={() => {
                        console.log('Add clicked');
                    }}
                    className="block pt-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                    Deactivate
                </button> */}
            </PopoverContent>
        </Popover>
    );
}
