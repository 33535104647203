export class CheckedError extends Error {
    private readonly checked: boolean;

    constructor(message: string) {
        super(message);
        this.checked = true
    }

    get isChecked() {
        return this.checked
    }
}

export interface ServiceError {
    error: string
}