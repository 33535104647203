import React from "react";
import {IAceScreener, IAnsweredQuestions} from "../../models";

export const TraumaUnder18: React.FC<{ aceScreener: IAceScreener }> = ({ aceScreener }) => {

  const renderTrauma = () => {
    return (
      <div className="py-4">
        <label className="text-lg mt-12 uppercase">TRAUMA HISTORY:</label>
        <label className="block text-lg text-sjOrange mt-3 uppercase">Toxic Stress Screening Responses:</label>
        {aceScreener?.answeredQuestions?.map((aq: IAnsweredQuestions) => (
          <div key={aq.id} className="grid trauma-history p-10 mt-3">
            <span style={{ color: '#FFA200' }}>Question:
              <span style={{ color: '#000000', marginLeft: '5px' }}>{aq.screenerQuestion.content}</span>
            </span>
            <span className="block" style={{ color: '#0C80E3' }}>Answer:
              <span style={{ color: '#000000', marginLeft: '5px' }}>{aq.screenerAnswer.content}</span>
            </span>
          </div>
        ))}
      </div>
    )
  }

  return (
    renderTrauma()
  )
}
