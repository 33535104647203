import _ from 'lodash';
import React, {  } from 'react';
import Select, { components, GroupBase, GroupHeadingProps } from 'react-select';

interface Props {
    options: OptionProps[]
    title: string
    onChange: (e: any) => void
}

interface OptionProps {
    value: string;
    label: string | null;
    description?: string;
}

interface GroupOption extends GroupBase<OptionProps> {
    label: string
    options: OptionProps[]
}

export function DropdownWithGroupHeading({onChange, options, title}: Props) {
    const optionsWithDescription: GroupOption[] = _.chain(options).groupBy('description')
    .map((value, key) => {
        const options = value.map((option) => {
            return { value: option.value, label: option.label };
        });
        return { label: key, options };
    }).value()
    
    const GroupHeading: React.FC<GroupHeadingProps<OptionProps, false, GroupOption>> = (props) => (
        <components.GroupHeading {...props}>
            <strong>{props.children}</strong>
        </components.GroupHeading>
    );

    return (
        <div>
            <label className="text-sjLabel text-sm">{title}</label>
            <Select<OptionProps, false, GroupOption>
                onChange={onChange}
                defaultValue={options[0]}
                options={optionsWithDescription}
                components={{ GroupHeading }}
            />
        </div>
    );
};