import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchPatientWithIntake} from "../../store/actions/counsellor";
import {StateParams} from "../../store/reducers";
import ProspectProfile from "./ProspectProfile";

const PatientViewIntakeForm: React.FC = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const {intakeDetails, noIntakeFound} = useSelector((state: StateParams) => state.intake)

    useEffect(() => {
        console.log("PatientViewIntakeForm", params.prospectId)
        const id = String(params.prospectId)
        if(id) {
            dispatch(fetchPatientWithIntake(id))
        }

    }, [params.prospectId])

    return <>
        {/*{*/}
        {/*    intakeDetails && intakeDetails.patientProspect*/}
        {/*    ? <ProspectProfile prospect={intakeDetails.patientProspect} />*/}
        {/*    : null*/}
        {/*}*/}
    </>
}

export default PatientViewIntakeForm
