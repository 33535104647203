import React, {useCallback, useEffect, useState} from 'react'
import sjCircle from "../../assets/images/loginBackground/sj-circle.png";
import {useDispatch, useSelector} from "react-redux";
import {login, resetPassword, sendEmail, validateCode} from "../../store/actions/physician/account.action";
import {StateParams} from "../../store/reducers";
import {useNavigate} from "react-router";
import ForgotPassword from './ForgotPassword';
import { useSearchParams } from 'react-router-dom';
import {RoutePaths} from "../../shared/Utils";
import PasswordInput from '../../components/generic/PasswordInput';

const ResetPassword: React.FC<{}> = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [formErrors, setFormErrors] = useState<string[]>()
    const {code, validateCodeError, providerId, email, id} = useSelector((state: StateParams) => state.physicianAccount)

    const [searchParams, setSearchParams] = useSearchParams();
    const qCode = searchParams.get("code");
    const qEmail = searchParams.get("email");
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    useEffect(() => {
        if(qEmail && qCode) {
            dispatch(validateCode({email: qEmail, code: qCode}))
        }
    }, [qCode, qEmail])

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (code && email && providerId && !validateCodeError && confirmPassword && password && confirmPassword === password) {
            dispatch(resetPassword({
                email,
                code,
                providerId,
                password
            }))
        } else if(code && email && providerId && !validateCodeError && confirmPassword && password && confirmPassword !== password) {
            setFormErrors(() => {
                return [`Passwords don't match!`]
            })
        }
    }

    useEffect(() => {
        if(!id) return
        navigate(`${RoutePaths.physicianCare.prospects}?page=1`)
    }, [id]);

    return (
        <>
        {(code && !validateCodeError && qCode && qEmail) ? <main className="xs:pt-28 md:pt-2 0 px-3 xs:h-auto md:h-screen">
            <div className="container mx-auto flex flex-col items-center">
                <div className='w-full h-32 flex items-center justify-center'>
                    <img src={sjCircle} className='h-[200px]' />
                </div>
                <div className="flex-col items-center text-2xl font-bold text-sjAnswer">Reset password</div>
                <div className="text-xs text-[#667B8B]">Please enter a new password.</div>
            </div>
            <div className="grid grid-cols-3 gap-6">
                <div></div>
                <div className="pt-10">
                    <label className="block w-90">
                        <span className="text-gray-700">New Password</span>
                        <PasswordInput value={password} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value) } />
                    </label>
                    <label className="block w-90">
                        <span className="text-gray-700">Confirm Password</span>
                        <PasswordInput  value={confirmPassword} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value) } />
                    </label>
                </div>
                <div></div>
            </div>
            <div className="mt-10 grid grid-cols-3 gap-6">
                <div></div>
                <div className="flex justify-center items-center">
                    <button
                        className="bg-sj2 hover:bg-sjOrange text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={handleSubmit}>
                        Reset Password
                    </button>
                </div>
                <div></div>
            </div>
            <div className="mt-10 grid grid-cols-3 gap-6">
                <div></div>
                <div className="flex flex-col justify-center items-center">
                    {formErrors?.map((error, index) => {
                        return <div className="text-red-500" key={"error-" + index}>{error}</div>
                    })}
                </div>
                <div></div>
            </div>
        </main> : <ForgotPassword/>}
        </>
    )
}

export default ResetPassword
