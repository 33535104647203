import Joi from "joi";
import {Inputs} from './index'

const selectedArrayValidation = {
    selected: Joi.array().items().min(1).required().messages({
        'array.min': 'This field is required.',
        'any.required': 'This field is required.',
    })
}

export const schema: { [K in keyof Partial<Inputs>]: any } = {
    symptoms: selectedArrayValidation,
    problems: selectedArrayValidation,
    presentChiefComplaint: Joi.string().required().messages({'string.empty':'This field is required.'}),
    behavior: selectedArrayValidation,
    motorActivity: selectedArrayValidation,
    affect: selectedArrayValidation,
    rate: selectedArrayValidation,
    fluencyRhythm: selectedArrayValidation,
    relevance: selectedArrayValidation,
    thoughtProcess: selectedArrayValidation,
    delusion: selectedArrayValidation,
    suicidalThought: selectedArrayValidation,
    homicidalThought: selectedArrayValidation,
    language: Joi.string().required().messages({'string.empty':'This field is required.'}),
    address: {
        street: Joi.string().required().messages({'string.empty':'This field is required.'}),
        city: Joi.string().required().messages({'string.empty':'This field is required.'}),
        state: Joi.string().required().messages({'string.empty':'This field is required.'}),
        zipCode: Joi.string().required().messages({'string.empty':'This field is required.'}),
    },
    historyPresentProblem: Joi.string().required().messages({'string.empty':'This field is required.'}),
    diagnosis: selectedArrayValidation,
    priorCurrentPsychiatristInfo: Joi.string().valid('none', 'prior', 'current').optional(),
    priorCurrentCounselor: Joi.string().valid('none', 'prior', 'current').optional(),
    psychiatristInfo: Joi.object({
        name: Joi.string().when('priorCurrentPsychiatristInfo', {
            not: 'none',
            then: Joi.string().required().messages({ 'string.empty': 'This field is required.' }),
        }),
        contactNo: Joi.string().when('priorCurrentPsychiatristInfo', {
            not: 'none',
            then: Joi.string().required().messages({ 'string.empty': 'This field is required.' }),
        })
    }),
    counselorInfo: Joi.object({
        name: Joi.string().when('priorCurrentCounselor', {
            not: 'none',
            then: Joi.string().required().messages({ 'string.empty': 'This field is required.' }),
        }),
        contactNo: Joi.string().when('priorCurrentCounselor', {
            not: 'none',
            then: Joi.string().required().messages({ 'string.empty': 'This field is required.' }),
        })
    }),
    currentMedications: selectedArrayValidation,
    patientMedicationsHistory: selectedArrayValidation,
    comorbidities: selectedArrayValidation,
    behaviors: {
        home: Joi.string().required().messages({'string.empty':'This field is required.'}),
        social: Joi.string().required().messages({'string.empty':'This field is required.'}),
    },
    strengths: selectedArrayValidation,
    limitations: selectedArrayValidation,
    participants: Joi.string().required().messages({'string.empty':'This field is required.'}),
    recommendationNotes: Joi.string().required().messages({'string.empty':'This field is required.'}),
    isConsentGivenCollaborativeCare: Joi.string().required().messages({'string.base':'This field is required.'}),
};