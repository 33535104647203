import { PatientSessionStatus, PatientSessionStatusType, patient_status, StatusBuckets } from "../../models"

const dischargeStatuses: Partial<Record<patient_status, string>> = {
    // [patient_status.active]: "Active Patient",
    [patient_status.accepted_to_cocm]: "Change Status",
    [patient_status.discharge_from_cocm]: "Discharge Patient",
}

const dischargeReasons: Partial<Record<patient_status, string>> = {
    [patient_status.discharge_reason_patient_got_better]: "Patient got better in program",
    [patient_status.discharge_reason_patient_in_need_of_specialized_care]: "Patient in need of specialized care",
    [patient_status.discharge_reason_patient_inactive]: "Patient inactive",
    [patient_status.discharge_reason_patient_ineligible]: "Patient ineligible",
}

const rejectedStatuses: Partial<Record<patient_status, string>> = {
    [patient_status.rejected_from_cocm]: "Exited CoCM",
}

const dischargeStatus: Partial<Record<patient_status, string>> = {
    [patient_status.discharge_from_cocm]: "Discharge Patient",
}

const sessionStatusesToConfirm: Partial<Record<PatientSessionStatusType, string>> = {
    [PatientSessionStatus.inProgressUnderscored]: "Patient available, start appointment",
    [PatientSessionStatus.noShow]: "Patient no show"
}

export const physcReferredListStatus: Partial<any[]> = [
    {
        lable: 'Review Pending',
        value: 'pending_psych_review'
    },
    {
        lable: 'Submitted',
        value: 'locked'
    }
]


const statusBucket: Record<StatusBuckets, Partial<Record<patient_status, string>>> = {
    'discharge_patient': dischargeStatuses,
    'discharge_reason': dischargeReasons,
    'rejected_patient': rejectedStatuses,
    'discharge_status': dischargeStatus,
    'session_status_to_confirm': sessionStatusesToConfirm,
}

export default statusBucket