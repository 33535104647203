import React, { useEffect, useState } from 'react';
import SoapNoteFilters from './SoapNoteFilters';
import { FiltersTypes, ISOAPNotePatient } from '../../models';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StateParams } from '../../store/reducers';
import { DateTime } from 'luxon';
import noPatients from "../../assets/images/noPatients.png";
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchSoapNotePatients } from '../../store/actions/physician/admin-operations.action';
import LoadingComponent from '../../components/generic/LoadingComponent';
import _ from 'lodash';
import SoapNoteListView from './SoapNotesListView';
import { CaptilizeFirstLetter } from '../../services/common.service';

const SoapScreen = () => {
    const [filters, setFilters] = useState<FiltersTypes>();
    const [searchText, setSearchText] = useState('');
    const [searchParams] = useSearchParams();
    const [pageNumber, setPageNumber] = useState(Number(searchParams.get("page")) || 1);
    const [recordsPerPage] = useState(10);
    const [allSOAPPatients, setAllSOAPPatients] = useState<ISOAPNotePatient[]>([]);
    const dispatch = useDispatch();
    const [selectedPatient, setSelectedPatient] = useState<ISOAPNotePatient | null>(null);
    const { SOAPNotepatients, fetchSoapNotePatientsInProgress, fetchSoapNotePatientsError, SOAPNoteCount } = useSelector((state: StateParams) => state.physicianAdminOperations);
    const [hasMore, setHasMore] = useState(true);
    const [initialFetch, setInitialFetch] = useState(false);

    useEffect(() => {
        if (SOAPNotepatients) {
            if (pageNumber === 1) {
                constructInitialData();
                setAllSOAPPatients(SOAPNotepatients);
            } else {
                setAllSOAPPatients((prev: ISOAPNotePatient[]) => {
                    const existingPatientIds = new Set(prev.map((patient: ISOAPNotePatient) => patient.id));
                    const newPatients = SOAPNotepatients.filter((patient: ISOAPNotePatient) => !existingPatientIds.has(patient.id));
                    return [...prev, ...newPatients];
                });
            }
        }

        if (SOAPNotepatients && allSOAPPatients.length === SOAPNoteCount) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    }, [SOAPNotepatients, pageNumber]);


    const handlePatientClick = (patient: ISOAPNotePatient) => {
        setSelectedPatient(patient);
    };

    useEffect(() => {
        dispatch(fetchSoapNotePatients({ pageNumber, recordsPerPage, searchText, ...filters }));
    }, [pageNumber, recordsPerPage, filters, searchText]);

    const constructInitialData = () => {
        if (fetchSoapNotePatientsInProgress && !fetchSoapNotePatientsError) {
            setInitialFetch(true);
        } else {
            setInitialFetch(false);
        }
    }

    const handleFiltersSubmit = (data: any) => {
        setSelectedPatient(null);
        setFilters(data);
        constructInitialData();
        setAllSOAPPatients([]); // Clear previous data
        setPageNumber(1); // Reset to the first page on filter change
    };

    const fetchMoreData = () => {
        if (!fetchSoapNotePatientsInProgress) {
            setPageNumber((prev) => prev + 1);
        }
    };

    const handleSerachFilterChange = (searchText: string) => {
        setSelectedPatient(null);
        setSearchText(searchText);
        constructInitialData();
        setAllSOAPPatients([]); // Clear previous data
        setPageNumber(1); // Reset to the first page on filter change
    }

    return (
        <div className="w-full p-4 bg-[#F7F8FA] soap-physician-wrapper h-full flex flex-col">
            <div className="flex flex-row justify-between items-center">
                <h1 className="text-xl"> S.O.A.P </h1>
                <SoapNoteFilters onSubmit={handleFiltersSubmit} setCurrentPage={setPageNumber} setSearchText={handleSerachFilterChange} />
            </div>
            <LoadingComponent top={"45%"} left={"45%"} isLoading={initialFetch} />
            {allSOAPPatients.length > 0 ? <div className='soap-physician-content flex-1'>
                <div className="grid grid-cols-12 gap-4 pt-10 h-full">
                    <div className="col-span-3 bg-white mb-25 overflow-y-auto max-h-[88vh]" id="scrollableDiv">
                        <InfiniteScroll
                            dataLength={allSOAPPatients.length}
                            next={fetchMoreData}
                            hasMore={hasMore}
                            loader={hasMore &&
                                <div className="flex justify-center items-center h-20">
                                    <div className="infinite-loading"></div>
                                </div>
                                // <>
                                //     <div className="flex justify-center items-center h-20 infinite-loading">
                                //         Loading ....
                                //     </div>
                                // </>
                            }
                            scrollThreshold={0.9}
                            scrollableTarget="scrollableDiv"
                        >
                            {allSOAPPatients.map((soapPatient, index) => {
                                if (!soapPatient.patient) return null;
                                const fullName = CaptilizeFirstLetter(soapPatient.patient.firstName) + " " + CaptilizeFirstLetter(soapPatient.patient.lastName);
                                // const fullName = `${soapPatient.patient.firstName} ${soapPatient.patient.lastName}`;

                                return (
                                    <React.Fragment key={index}>
                                        <div
                                            className={`${selectedPatient?.patient?.id === soapPatient.patient.id ? 'bg-orange-50' : 'hover:bg-gray-100'
                                                } cursor-pointer pl-4`}
                                            onClick={() => handlePatientClick(soapPatient)}
                                            data-tooltip-id={`patient-name-tooltip-${index}`}
                                        >
                                            <div className="items-center pt-4">
                                                <div className="patient-name">
                                                    {fullName}
                                                </div>

                                                <div className="patient-dob">
                                                    {DateTime.fromISO(soapPatient?.patient.dob.toString(), { zone: 'utc' }).toFormat('MM/dd/yyyy')}
                                                </div>
                                            </div>

                                            <div className="flex flex-row items-center patient-location pt-4 pb-2">
                                                <div>{soapPatient?.location?.name}</div>
                                            </div>

                                        </div>

                                        <hr />
                                    </React.Fragment>
                                );
                            })}
                        </InfiniteScroll>
                    </div>
                    <div className="col-span-9">
                        {selectedPatient ?
                            <SoapNoteListView selectedPatient={selectedPatient.patient} />
                            :
                            <div className="flex flex-col align-items-center bg-white h-full pt-10">
                                <img src={noPatients} alt="No Patient Selected" />
                                Click on any Patient record to view the generated SOAP note for a patient.
                            </div>}

                    </div>
                </div>
            </div> : <div className="flex justify-center items-center h-96">
                {!fetchSoapNotePatientsInProgress && <h1>No Patients Found</h1>}
            </div>}
        </div>
    );
};

export default SoapScreen;
