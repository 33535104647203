import React from "react";
import {useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import Patients from "../../../components/patients/Patients";
import {Outlet} from "react-router-dom";

const PatientsScreen = () => {

    const {role} = useSelector((state: StateParams) => state.practitionerAccount);

    function renderPatientList() {
        if(!role) {
            return <></>
        }
        return <Patients role={role} />
    }

    return (
        <>
            {renderPatientList()}
            <div className={"absolute top-0 right-0 bg-white z-[2000] custom-sidebar"}>
                <Outlet/>
            </div>
        </>
    )
}

export default PatientsScreen;
