import React from "react";
import {useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";
import {Outlet} from "react-router-dom";
import Prospects from "../../../components/prospects/Prospects";

const ProspectsScreen = () => {
    const {role} = useSelector((state: StateParams) => state.practitionerAccount);

    function renderProspectList() {
        if(!role) {
            return <></>
        }
        return <Prospects role={role} />
    }

    return (
        <>
            {renderProspectList()}
            <div className={"absolute top-0 right-0 bg-white z-[2000] custom-sidebar"}>
                <Outlet/>
            </div>
        </>
    )
}

export default ProspectsScreen;
