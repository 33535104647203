import React, { PropsWithChildren } from "react";
import InputMask from "react-input-mask"

export const MaskPhoneNumber: React.FC<PropsWithChildren<any>> = ({ value, ...rest }) => {
    const renderFormattedPhoneNumber = () => {
        if (!value) return ''
        if (value.startsWith("+91")) return '+91 99999 99999'
        if (value.startsWith("+1")) return '+1 (999) 999-9999'
        return value
    }

    return (
        <InputMask
            mask={renderFormattedPhoneNumber()}
            maskChar={''}
            value={value}
            {...rest} 
            />
    );
}