import React from "react";
import {useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import {Lottie} from "../animations/Lottie";
import loadingAnimation from "../../assets/data/loadingAnimation.json"

interface LoadingComponentProps {
    top?: string
    left? : string
    isLoading?: boolean
}

const LoadingComponent: React.FC<LoadingComponentProps> = ({top, left, isLoading}) => {
    
    const loading = useSelector((state: StateParams) => state?.loading?.loading) || isLoading;

    return <>
        <section style={{display: loading ? "block": "none"}} className="loading-section w-full">
            <div style={{position: "relative", top: top || "50%", left: left || "50%" }}>
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <Lottie height={100} width={100} borderRadius={"50%"} animationData={loadingAnimation}/>
            </div>
        </section>
    </>
}

export default LoadingComponent
