import { useEffect, useState } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { NotesStatus } from "../../../models";
import { fetchPatient, fetchTreatmentNotes, reviewAndSubmitTreatmentNotes } from "../../../store/actions/counsellor/patients.action";
import { StateParams } from "../../../store/reducers";
import { Button } from "../../generic/Buttons";
import TreatmentPlan from "./TreatmentPlan";
import { toast } from "react-toastify";
import ClinicalNotesComponent from "../ClinicalNotesComponent";
import { canAddPsychiatristNotes, canCounsellorSubmit, canEditCounsellorNotes, isCounsellorNotesLocked, isNotesLocked, isPsychiatrist } from "../accessControl";
import NotesErrorModal from "../NotesErrorModal";

const PatientTreatmentPlanComponent: React.FC<{ patientProspectId?: string, notesSessionId?: string }> = ({ patientProspectId: patientId, notesSessionId }) => {
    const { notesStatus, sessionNotesId: treatmentId, screenersToBeAssigned } = useSelector((state: StateParams) => state.patients)
    const patient = useSelector((state: StateParams) => state.patients.patient)
    const isZoomSessionActive = useSelector((state: StateParams) => state.practitionerAccount.isZoomSessionActive)
    const dispatch = useDispatch()
    const params = useParams()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)
    const [notesId, setNotesId] = useState<string | null>(searchParams.get('notesId'))
    const role = useSelector((state: StateParams) => state.account.role);
    const [canEdit, setCanEdit] = useState<boolean>(false)
    const [isAddendumAdded, setIsAddendumAdded] = useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showSubmitConfirmationModal, setShowSubmitConfirmationModal] = useState(false)
    const [notesIdError, setNotesIdError] = useState<boolean>(false)

    useEffect(() => {
        const id = patientId || params.patientProspectId
        if (id && notesId) {
            batch(() => {
                dispatch(fetchPatient(id))
                dispatch(fetchTreatmentNotes(id, notesId))
            })
        } else if(!notesId) {
            setNotesIdError(true)
        }
    }, [patientId, params])

    useEffect(() => {
        if (notesStatus) {
            const canEditNotes = canEditCounsellorNotes(notesStatus, role)
            setCanEdit(canEditNotes)
        }
    }, [notesStatus])

    // const assignScreeners = (status: NotesStatus) => {
    //     const id = patientId || params.patientProspectId
    //     //when accessed from the session, the `treatmentId` should be used.
    //     //when accessed from the journey, the `notesid` should be used.
    //     const documentId = notesId || treatmentId
    //     if (id && documentId && status === NotesStatus.LOCKED && screenersToBeAssigned && screenersToBeAssigned.length && patient) {
    //         dispatch(reviewAndSubmitTreatmentNotes(id, screenersToBeAssigned, patient.sourcePatientId, documentId))
    //     }
    // }

    const getPsychiatristNotesLabel = () => {
        if(canAddPsychiatristNotes(notesStatus, role)) return 'Add'
    }

    const toggleModal = () => {
        setIsModalOpen(prevState => !prevState);
    };

    const handleAddendum = () => setIsAddendumAdded(true)
    const renderSaveButton = () => canCounsellorSubmit(notesStatus, role, isZoomSessionActive) && <Button className={"!bg-sjOrange !text-white !ml-2"} onClick={() => toast("Notes has been saved successfully.")}>Save</Button>

    const renderSubmitForReviewButton = () => <> {canCounsellorSubmit(notesStatus, role, isZoomSessionActive) &&
        <Button className={"!bg-sjOrange !text-white"} onClick={() => setShowSubmitConfirmationModal(true)} data-testid="submitForReview">Submit
        </Button>}
    </>

    const renderPsychiatristNotesButton = () => <Button className={"!bg-sjOrange !text-white !ml-2"} data-testid="addPsychiatristNotesBtn" onClick={toggleModal}>{`${getPsychiatristNotesLabel()} Psychiatrist Notes`}</Button>
    const renderAddViewPsychiatristNotesButton = () => canAddPsychiatristNotes(notesStatus, role) ? renderPsychiatristNotesButton() : <></>
    
    const renderAddAddendumButton = () => {
        const renderBtn = () => <Button className={"!bg-sjOrange !text-white !ml-2"} data-testid="addAddendumBtn" onClick={handleAddendum}>Add Addendum note</Button>
        if(isCounsellorNotesLocked(notesStatus, role)) {
            return renderBtn()
        } else if(isPsychiatrist(role) && isNotesLocked(notesStatus)) {
            return renderBtn()
        }
    }
    const actionComponent = () => {
        return <>
            {renderSaveButton()}
            {renderSubmitForReviewButton()}
            {renderAddViewPsychiatristNotesButton()}
            {renderAddAddendumButton()}
        </>
    }

    function renderNotesSection() {
        return (<>
            {patient && <TreatmentPlan patientId={patient.id} canEdit={canEdit} isModalOpen={isModalOpen} 
            toggleModal={toggleModal} notesId={notesId} setShowSubmitConfirmationModal={setShowSubmitConfirmationModal} 
            showSubmitConfirmationModal={showSubmitConfirmationModal}
            isAddendumAdded={isAddendumAdded} setIsAddendumAdded={setIsAddendumAdded}/>}
        </>)
    }

    return (<>
        {patient && patient.id && <ClinicalNotesComponent title="Treatment Plan" patient={patient} actionComponent={actionComponent()} notesSessionId={notesSessionId} isModalOpen={isModalOpen}>
            {renderNotesSection()}
        </ClinicalNotesComponent>}
        <NotesErrorModal isOpen={notesIdError}/>
        </>
    )
}

export default PatientTreatmentPlanComponent