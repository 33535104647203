import _ from "lodash"
import { useState, useRef } from "react"
import { IProspect, ISummary, Roles } from "../../models"
import { Tab } from '@headlessui/react'
import classNames from "classnames"
import ScreenerResultContent from "./ScreenerResultContent"
import { useSelector } from "react-redux"
import { StateParams } from "../../store/reducers"
import { use } from "chai"


export const SLUG_TO_TAB_MAP = {
    "psc-y": "PSC-Y",
    "anxiety": "Anxiety",
    "depression": "Depression",
    "suicidal": "Suicidal",
    "ace-above18": "ACE",
    "ace-12to18": "ACE",
    "ace-below6": "ACE",
    "ace-6to12": "ACE",
    "psc-6to12": "PSC",
    "depression-MFQ": "Depression(MFQ)",
    "anxiety-SCARED": "Anxiety(SCARED)",
    "adhd-6to12": "ADHD",
    "adhd-12to25": "ADHD",
    "phq-9": "Depression",
    "phq-9-50to150": "Depression",
    "anxiety-50to150": "Anxiety",
    "fp-ace-12to18": "ACE",
    "fp-ace-6to12": "ACE",
    "fp-ace-above18": "ACE",
    "fp-ace-below6": "ACE",
    "fp-adhd-12to18": "ADHD",
    "fp-adhd-6to12": "ADHD",
    "fp-anxiety": "Anxiety",
    "fp-anxiety-SCARED": "Anxiety(SCARED)",
    "fp-anxiety-above18": "Anxiety",
    "fp-depression-MFQ": "Depression(MFQ)",
    "fp-phq-9": "Depression",
    "fp-phq-9-above18": "Depression",
    "fp-psc-6to12": "PSC",
    "fp-psc-y": "PSC-Y",
    "fp-suicidal": "Suicidal",
    "fp-slesq-above18": "SLESQ",
    "slesq-50to150": "SLESQ",
    "slesq-above18": "SLESQ",
    "fp-two-month": "SWYC",
    "fp-four-month": "SWYC",
    "fp-six-month": "SWYC",
    "fp-nine-month": "SWYC",
    "fp-twelve-month": "SWYC",
    "fp-fifteen-month": "SWYC",
    "fp-eighteen-month": "SWYC",
    "fp-twentyfour-month": "SWYC",
    "fp-thirty-month": "SWYC",
    "fp-thirtysix-month": "SWYC",
    "fp-fortyeight-month": "SWYC",
    "fp-sixty-month": "SWYC",
    "fp-m-chat-r": "M-Chat-R/F",
    "fp-phq9-suicidal": "Suicidal",
    "fp-drug-above18": "Drug",
    "fp-alcohol-above18": "Alcohol",
    "fp-ptsd-above18": "PTSD",
    "developmental": "Developmental (SWYC)",
    "bpsc": "Baby Pediatric Symptom Checklist (SWYC)",
    "parent-concern": "Parent's Concern (SWYC)",
    "tobacco": "Tobacco (SWYC)",
    "substance-abuse": "Substance Abuse (SWYC)",
    "food-insecurity": "Food Insecurity (SWYC)",
    "domestic-violence": "Domestic Violence (SWYC)",
    "emotinal-change": "Emotional Change (SWYC)",
    "self-harm": "Self Harm (SWYC)",
    "ppsc": "Preschool Pediatric Symptom Checklist (SWYC)",
    "posi": "Parent's Observations of Social Interactions (SWYC)",
    "parental-depression": "Parental Depression (SWYC)",
    "psc-y-summary": "Pediatric Symptom Checklist (PSC-Y)",
    "fp-psc-6to12-summary": "Pediatric Symptom Checklist (PSC)",
    "fp-ace-6to12-summary": "Toxic Stress (ACE)",
    "fp-anxiety-above18-summary": "Anxiety (GAD7)",
    "fp-anxiety-SCARED-summary": "Anxiety (SCARED)",
    "depression-summary": "Depression (PHQ-9)",
    "fp-depression-MFQ-summary": "Depression (MFQ)",
    "fp-suicidal-summary": "Suicidal (COLUMBIA-SUICIDE SEVERITY)",
    "fp-alcohol-above18-summary": "Alcohol (AUDIT)",
    "fp-drug-above18-summary": "Substance Abuse (DAST)",
    "fp-ptsd-above18-summary": "Post-traumatic stress disorder (PTSD)",
    "fp-adhd-6to12-summary": "ADHD (NICHQ Vanderbilt)",
    "fp-m-chat-r-summary": "Autism (M-Chat-R/F)",
    "fp-sdoh-above18": "SDOH",
    "fp-mmse-cognitive": "MMSE",
    "fp-qdrs-cognitive": "QDRS"
}

const SCREENER_ORDER = [
    "psc-y",
    "psc-6to12",
    "fp-psc-6to12",
    "fp-psc-y",
    "fp-two-month",
    "fp-four-month",
    "fp-six-month",
    "fp-nine-month",
    "fp-twelve-month",
    "fp-fifteen-month",
    "fp-eighteen-month",
    "fp-twentyfour-month",
    "fp-thirty-month",
    "fp-thirtysix-month",
    "fp-fortyeight-month",
    "fp-sixty-month",
    "ace-above18",
    "ace-12to18",
    "ace-below6",
    "ace-6to12",
    "fp-ace-12to18",
    "fp-ace-6to12",
    "fp-ace-above18",
    "fp-ace-below6",
    "fp-m-chat-r",
    "fp-slesq-above18",
    "slesq-50to150",
    "slesq-above18",
    "anxiety-SCARED",
    "anxiety",
    "anxiety-50to150",
    "fp-anxiety",
    "fp-anxiety-SCARED",
    "fp-anxiety-above18",
    "depression-MFQ",
    "depression",
    "fp-depression-MFQ",
    "phq-9",
    "phq-9-50to150",
    "fp-phq-9",
    "fp-phq-9-above18",
    "suicidal",
    "fp-suicidal",
    "fp-phq9-suicidal",
    "adhd-6to12",
    "adhd-12to25",
    "fp-adhd-12to18",
    "fp-adhd-6to12",
    "fp-drug-above18",
    "fp-sdoh-above18",
    "fp-alcohol-above18",
    "fp-ptsd-above18",
]

export type TYPE_SLUG = (keyof typeof SLUG_TO_TAB_MAP);

type Props = {
    prospect: IProspect
    summary: ISummary
    selectedIndex: number
    setSelectedIndex: (selectedIndex: number) => void
}
const ScreenerResults: React.FC<Props> = (props) => {
    const {role} = useSelector((state: StateParams) => state.practitionerAccount)
    const { prospect } = useSelector((state: StateParams) => state.prospects)
    // const prospectScreeners = _
    //     .chain(props.prospect.patient?.screeners)
    //     .filter(screener => screener.slug !== 'fp-questionnaire-above18')
    //     .orderBy(screener => SCREENER_ORDER.indexOf(screener.slug))
    //     .value();

    const prospectScreeners = _.chain(props.prospect.patient?.screeners)
        .filter(screener => {
            return !['fp-questionnaire-above18', 'fp-questionnaire-above18-v2'].includes(screener.slug);
        })
        .orderBy(screener => SCREENER_ORDER.indexOf(screener.slug))
        .value();

    const screenerTabs = prospectScreeners.map(screener => SLUG_TO_TAB_MAP[screener.slug as TYPE_SLUG]);
    const tabs = ['Summary', ...screenerTabs]
    const summaryComponentRef = useRef();

    const updateSelectedIndex = (newTabIndex: number) => {
        props.setSelectedIndex(newTabIndex)
    }

    return (
        <div className="w-full mt-2 h-[50vh]">
            { role !== Roles.Care_Coordinator && <Tab.Group onChange={updateSelectedIndex} selectedIndex={props.selectedIndex === 0 ? 0 : undefined}>
                <Tab.List className="w-full flex border-b border-solid border-b-sjGrey-200">
                    {tabs.map((screener) => (
                        <Tab
                            key={screener}
                            className={({ selected }) => {
                                return classNames(
                                    'mr-5 py-2.5 text-sm font-medium',
                                    'ring-offset-2 focus:outline-none',
                                    selected
                                        ? 'text-sjOrange border-b border-solid border-b-sjOrange'
                                        : 'text-sjGrey hover:text-sjOrange'
                                )
                            }}
                        >
                            {screener}
                        </Tab>
                    ))}
                </Tab.List>
            </Tab.Group>}
            <ScreenerResultContent prospect={props.prospect} summary={props.summary} practiceId={props.prospect.referredByPracticeId}
                screeners={prospectScreeners} sourcePatientId={props.prospect.sourcePatientId}
                selectedIndex={props.selectedIndex} summaryComponentRef={summaryComponentRef} updateSelectedIndex={props.setSelectedIndex} />
        </div>
    )
}

export default ScreenerResults
