import React, {useCallback, useEffect, useState} from "react";
import TableFilterPaginationV2 from "../../../components/generic/TableFilterPaginationV2";
import Select from "react-select";
import { Practice } from "../../../models";
import DatePicker from "react-datepicker";
import {DateTime} from "luxon";
import { HiCalendarDays } from "react-icons/hi2";
import 'react-datepicker/dist/react-datepicker.css';

interface Filter {
    practices?: Practice[]
    setPracticeIds: (practiceId: string[]) => void
    setSearchText: (text: string) => void
    recordsCount?: number
    setCurrentPage: (page: number) => void
    onFilterChange: (startDate: string | undefined, endDate: string | undefined) => void;
    isPendingNotes: boolean
}

const SWComplianceListFilter = (props: Filter) => {
    const {practices, setPracticeIds, setSearchText, recordsCount, setCurrentPage, onFilterChange, isPendingNotes} = props
    const [showCustomDateFilter, setShowCustomDateFilter] = useState<boolean>(false);
    const [filterStartDate, setFilterStartDate] = useState<Date | undefined>(undefined);
    const [filterEndDate, setFilterEndDate] = useState<Date | undefined>(undefined);
    const startDateString = filterStartDate ? DateTime.fromJSDate(filterStartDate).startOf('day').toFormat('MM-dd-yyyy') : undefined
    const endDateString = filterEndDate ? DateTime.fromJSDate(filterEndDate).startOf('day').toFormat('MM-dd-yyyy') : undefined

    useEffect(()=>{
        setFilterStartDate(undefined)
        setFilterEndDate(undefined)
    },[isPendingNotes])

    const handleChange = useCallback((dates: [Date, Date]) => {
        const [start, end] = dates;
        setFilterStartDate(start);
        setFilterEndDate(end);
    }, []);

    const handleInputClick = () => {
        setShowCustomDateFilter(!showCustomDateFilter);
    };

    const handleCancel = useCallback(() => {
        setShowCustomDateFilter(false);
    }, []);

    const handleClear = useCallback(()=>{
        setFilterStartDate(undefined)
        setFilterEndDate(undefined)
        onFilterChange(undefined, undefined)
        setShowCustomDateFilter(false);
    }, []);

    const handleConfirm = useCallback(() => {
        if (onFilterChange && filterStartDate && filterEndDate) {
            onFilterChange(DateTime.fromJSDate(filterStartDate).startOf('day').toFormat('yyyy-MM-dd'), DateTime.fromJSDate(filterEndDate).startOf('day').toFormat('yyyy-MM-dd'));
        }
        setShowCustomDateFilter(false);
    }, [onFilterChange, filterStartDate, filterEndDate]);


    return (
        <div className="flex justify-end items-center gap-3">
            {isPendingNotes && 
            <div className="physician-dashboard-date-filter">
                <div className="relative ml-2">
                    <input
                        value={filterStartDate && filterEndDate ? `${startDateString} - ${endDateString}` : ''}
                        placeholder="Notes Date"
                        className={"p-2 w-[236px] border-[1.3px] rounded-md h-10 border-gray-300 focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"}
                        readOnly
                        onClick={handleInputClick}
                    />
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <HiCalendarDays className="w-5 h-5 text-sjLightGrey"/>
                    </span>
                </div>
                {showCustomDateFilter &&
                    <div className="absolute mt-2 ml-4 text-center">
                        <DatePicker
                            onChange={handleChange}
                            startDate={filterStartDate}
                            endDate={filterEndDate}
                            maxDate={new Date()}
                            selectsRange
                            inline
                        >
                            <div className="custom-date-filter-actions mb-2">
                                <button
                                    onClick={handleCancel}
                                    className="rounded-l-md text-sjDarkGray py-2 px-2"
                                >Cancel
                                </button>
                                <button
                                    onClick={handleClear}
                                    className="rounded-l-md border-sjOrange text-sjOrange py-2 px-4"
                                >Clear
                                </button>&nbsp;
                                <button
                                    onClick={handleConfirm}
                                    disabled={!filterStartDate || !filterEndDate}
                                    className="bg-sjOrange text-white py-2 px-4 rounded"
                                >Apply
                                </button>
                            </div>
                        </DatePicker>
                    </div>
                    }
                </div>
                }
            <Select
                className={"w-48"}
                options={practices}
                onChange={(selectedOptions) => setPracticeIds(selectedOptions ? selectedOptions.map(option => option.id) : [])}
                placeholder={"Practice"}
                isClearable={true}
                isSearchable={false}
                isMulti={true}
                getOptionLabel={option => `${option?.name}`}
                getOptionValue={option => option?.id}
            />
            <TableFilterPaginationV2
                setTableFilter={setSearchText}
                totalRows={recordsCount || 0}
                currentPageHandler={setCurrentPage}
            />
        </div>
    )

}

export default SWComplianceListFilter