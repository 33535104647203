import {classNames} from "../../shared/Utils";
import React from "react";

export const SuccessFailurePill: React.FC<{value: boolean}> = ({ value }) => {
    const status = value ? 'Yes' : 'No';

    return (
        <span
            className={classNames(
                "px-3 py-1 leading-wide font-bold text-xs rounded-full shadow-sm",
                value ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700" ,
            )}
        >
      {status}
    </span>
    );
}