import React, { useState } from "react";
import { toast } from 'react-toastify';
import CopyIcon from "../../assets/images/common/copyIcon.svg"

interface CopyButtonProps {
    content: string;
}

const CopyButton =  (props: CopyButtonProps) => {
    
    const { content } = props;

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(content);
        toast(`Copied to clipboard!`)

        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    return (
        <button onClick={copyToClipboard} className="text-white p-1 rounded-md">
            <img src={CopyIcon} width={20} height={20} data-tooltip-id='subjective-tooltip' />
        </button>
    );
}

export default CopyButton;