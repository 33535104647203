import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { fetchPractices, fetchPsychiatristSocialWorkers } from '../../store/actions/counsellor/prospects.action';
import { StateParams } from '../../store/reducers';
import { DateTime } from 'luxon';
import TableFilterPaginationV2 from './TableFilterPaginationV2';
import { Roles } from '../../models';
import { physcReferredListStatus } from "../../assets/data/statusBucket"

interface FilterProps {
    onSubmit: (data: any) => void;
    setCurrentPage: Function
    setSearchText: Function
    role?: string
}

const Filters: React.FC<FilterProps> = ({ onSubmit, setCurrentPage, setSearchText, role }) => {
    const dispatch = useDispatch()
    const { control, handleSubmit, register, watch, reset, getValues } = useForm();
    const { practices, therapists, isPsychiatristSocialWorkerListLoading } = useSelector((state: StateParams) => state.prospects)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [associatedPractices, setAssociatedPractices] = useState<{ label: string, value: string }[]>([])
    const calRef = useRef<DatePicker>(null);
    const isPsychiatrist = Roles.Psychiatrist === role;

    useEffect(() => {
        if (isPsychiatrist) {
            dispatch(fetchPsychiatristSocialWorkers());
        } else {
            dispatch(fetchPractices());
        }
    }, [])

    useEffect(() => {
        if (practices) {
            if (practices && Array.isArray(practices)) {
                setAssociatedPractices(practices.map(practice => ({
                    label: `${practice.name}`,
                    value: practice.id
                })))
            }
            setIsLoading(false)
        }
    }, [practices])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name && type === 'change') {
                if (value['dobMin']) {
                    const dobMinDate = DateTime.fromJSDate(value['dobMin']).startOf('day');
                    const dobMaxDate = dobMinDate.endOf('day');

                    value['dobMin'] = dobMinDate;
                    value['dobMax'] = dobMaxDate;
                }
                onSubmit(value)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    return (
        <div className="flex justify-end items-center gap-x-2">
            {isPsychiatrist ?
                <>
                    {!isPsychiatristSocialWorkerListLoading && <>
                        <div className="w-72">
                            <Controller
                                name="notesStatus"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <Select
                                        className="border-gray-400 rounded text-center"
                                        options={physcReferredListStatus}
                                        value={physcReferredListStatus && physcReferredListStatus.find((x: any) => x.value === field.value)}
                                        getOptionLabel={option => `${option?.lable}`}
                                        getOptionValue={option => option?.value}
                                        placeholder={'Select Status'}
                                        isSearchable={false}
                                        isClearable={true}
                                        onChange={(x: any) => {
                                            if (x?.value) {
                                                field.onChange(x.value)
                                            } else {
                                                field.onChange('')
                                            }
                                        }
                                        }
                                    />

                                )}
                            />
                        </div>
                        <div className="w-72">
                            <Controller
                                control={control}
                                name='socialWorkerId'
                                defaultValue=""
                                render={({ field }) => (
                                    <Select
                                        className="border-gray-400 rounded text-center"
                                        options={therapists}
                                        value={therapists && therapists.find((x: any) => x.id === field.value)}
                                        onChange={(x: any) => {
                                            if (x?.id) {
                                                field.onChange(x.id)
                                            } else {
                                                field.onChange('')
                                            }
                                        }
                                        }
                                        getOptionLabel={option => `${option?.firstName} ${option?.lastName}`}
                                        getOptionValue={option => option?.id}
                                        isSearchable={false}
                                        isClearable={true}
                                        placeholder={'Select Therapist'}

                                    />
                                )}
                            />
                        </div>
                    </>}
                </> :
                <>
                    <div>
                        <Controller
                            name="gender"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <select
                                    {...field}
                                    className="border border-gray-400 rounded h-8 pb-1 pt-1"
                                >
                                    <option value="">Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                            )}
                        />
                    </div>
                    <div className="custom-date-filter-wrapper">

                        <Controller
                            name="dobMin"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    className={`border border-gray-400 rounded h-8 pb-1 pt-1 text-center`}
                                    dateFormat="MM-dd-yyyy"
                                    maxDate={new Date()}
                                    todayButton="Today"
                                    dropdownMode="select"
                                    shouldCloseOnSelect
                                    onChange={(date) => field.onChange(date)}
                                    selected={field.value}
                                    timeIntervals={10}
                                    placeholderText="Date of Birth"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    isClearable
                                />
                            )}
                        />
                    </div>
                    {!isLoading && <div className="w-72">
                        <Controller
                            control={control}
                            name='practiceId'
                            render={({ field }) => (
                                <Select
                                    className="border-gray-400 rounded text-center"
                                    options={associatedPractices}
                                    value={associatedPractices.find(x => x.value === field.value)}
                                    onChange={x => field.onChange(x.map(y => y?.value))}
                                    isMulti={true}
                                    isSearchable={true}
                                    isClearable={true}
                                    placeholder={'Select Physician'}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: 'gray',
                                            height: 22, // adjust the height to your liking
                                            minHeight: 32
                                        }),
                                        placeholder: (style) => ({
                                            ...style,
                                            paddingBottom: 8,
                                            textAlign: 'center'
                                        }),
                                        multiValue: (style) => ({
                                            ...style,
                                            marginTop: -5
                                        }),

                                    }}
                                />
                            )}
                        />
                    </div>}
                </>
            }
            <div className="flex gap-x-2 justify-end">
                <TableFilterPaginationV2
                    setTableFilter={setSearchText}
                    totalRows={0}
                    currentPageHandler={setCurrentPage}
                />
            </div>
        </div>
    )
}

export default Filters
