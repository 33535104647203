import React from "react";
import {IProspectScreenerResponse, ScreenersSlugs} from "../../models";
import {classNames} from "../../shared/Utils";

const QuestionAnswer: React.FC<{ questionAnswer: IProspectScreenerResponse, index: number, screenerSlug?: string }> = ({ questionAnswer, index, screenerSlug }) => {
    return (
        <div className={`mt-2 flex flex-col ${questionAnswer.questionType === 'multiple_choice' ? 'sticky top-0 bg-white' : ''}`}>
            <p className={classNames(`px-4 py-1 leading-wide text-base text-sjGrey`)}>
                Q: {questionAnswer.question}
            </p>
            {screenerSlug && questionAnswer.answer === 'Yes'?
                <p className={classNames("px-4 py-1 leading-wide text-base text-sjTestPositive")}>
                    A: {questionAnswer.answer}
                </p> :
                <p className={classNames("px-4 py-1 leading-wide text-base text-sjAnswer")}>
                    A: {questionAnswer.answer}
                </p>
            }

            <hr className={"border-y-sjGrey-100"} />
        </div>
    );
}

export default QuestionAnswer