export const ASYNC_CALL_IN_PROGRESS = "ASYNC_CALL_IN_PROGRESS"
export const CALL_IN_PROGRESS = "CALL_IN_PROGRESS"
export const CALL_FINISHED = "CALL_FINISHED"
export const ASYNC_CALL_FINISHED = "ASYNC_CALL_FINISHED"

export const callInProgress = () => {
    return {type: CALL_IN_PROGRESS}
}

export const callFinished = () => {
    return {type: CALL_FINISHED}
}
export const asyncCallInProgress = () => {
    return {type: ASYNC_CALL_IN_PROGRESS}
}

export const asyncCallFinished = () => {
    return {type: ASYNC_CALL_FINISHED}
}
