import React from "react";
import {IProspect, ISummary} from "../../models";
import ProspectProfile from "./ProspectProfile";
import ScreenerResults from "../screeners/ScreenerResults";
import { DateOfVisit } from "../patients/DateOfVisit";

interface PatientProps {
    prospect: IProspect
    summary: ISummary
}

const Prospect : React.FC<PatientProps> = ({prospect, summary}) => {
    const [selectedIndex, setSelectedIndex] = React.useState<number>(0)

    return <>
        <ProspectProfile prospect={prospect} isReferredPatient={true}/>
        <DateOfVisit patientWithSummary={prospect} setSelectedIndex={setSelectedIndex} classNames="w-1/4"/>
        <ScreenerResults prospect={prospect} summary={summary} selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}/>
    </>
}

export default Prospect
