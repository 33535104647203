import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchPatient, fetchReferenceNotes } from '../../store/actions/physician/patients.action'
import { StateParams } from '../../store/reducers'
import ReferNotes from '../../components/patients/physician/refer/ReferNotes'
import {RoutePaths} from "../../shared/Utils";
import { fetchSourcePatientDetails } from '../../store/actions/counsellor/patients.action'

export const ReferScreen = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const { patient, physicianReportReference, physicianNotes } = useSelector((state: StateParams) => state.physicianPatients)
    const {role } = useSelector((state: StateParams) => state.account)

    useEffect(() => {
        if (params.patientId) {
            dispatch(fetchPatient(params.patientId))
            dispatch(fetchReferenceNotes(params.patientId, 'physician-reference-data'))
            dispatch(fetchSourcePatientDetails(params.patientId))
        }
    }, [params.patientId])

    return (
            <div className='mt-2 px-5 flex-1 bg-[#F7F8FA]'>
                <div className="flex flex-row floating-section pt-2 pb-2">
                    <ChevronLeftIcon className="h-5 w-5 m-1 cursor-pointer" aria-hidden="true"
                                     onClick={() => {
                                        role === 'Medical_Assistant'  ? navigate(`${RoutePaths.medicalAssistance.prospects}?page=1`) : navigate(`${RoutePaths.physicianCare.prospects}?page=1`)
                                     }
                                      
                                      } />
                    <h1 className="text-xl font-semibold"> {`Refer to Collaborative Care (CoCM)`}</h1>
                </div>
                <div className="mt-4">
                    {patient && physicianReportReference ? <ReferNotes patient={patient} physicianReportReference={physicianReportReference}/> : null}
                </div>
            </div>

    )
}
