import React, {useEffect, useState} from 'react'
import sjLogo from "../../assets/images/logos/logo.svg";
import {useNavigate} from "react-router";
import {Link, Outlet, useLocation} from "react-router-dom";

const enum LoginTypes {
    "physician", "non-physician", "home"
}

type LoginType = keyof typeof LoginTypes

const HomeScreen = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const [activeComponent, setActiveComponent] = useState<LoginType | null>(null)

    useEffect(() => {
        const pathComponents = location.pathname.split("/")
        console.log("path components", pathComponents)
        try {
            const activatePathComponent = pathComponents[pathComponents.length - 1] as LoginType
            console.log("activate path", activatePathComponent)
            setActiveComponent(activatePathComponent)
        } catch (e) {
            console.log("No matching Login component")
            setActiveComponent("home")
        }
    }, [location.pathname])

    const renderLoginButton = (expertise: string) => {
        return <div className="m-5 flex">
            <Link
                className={"text-white bg-sjOrange focus:ring-4 focus:ring-sjOrange font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none"}
                to={`${expertise.toLowerCase()}`}
            >{expertise.replaceAll("-", " ")}</Link>
        </div>
    }

    const renderOutlet = (className : string) => {
        return (
            <div className={className}>
                <Outlet/>
            </div>
        )
    }

    function renderHomeScreen() {
        return (
            <main className="xs:pt-28 md:pt-2 0 px-3 border-b xs:h-auto md:h-screen">
                <div className="container mx-auto flex flex-col items-center">
                    <div className='w-full h-32 flex items-center justify-center'>
                        <img src={sjLogo} />
                    </div>

                    <div className='w-full h-32 flex items-center justify-center flex-row'>
                        {renderLoginButton("Physician")}
                        {renderLoginButton("Non-Physician")}
                    </div>

                </div>
            </main>
        );
    }


    return <>
        { activeComponent && activeComponent !== "home" ? renderOutlet("") :  renderHomeScreen() }
    </>
}

export default HomeScreen
