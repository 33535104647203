import {physicianHttpClient} from "../../../lib";
import {AxiosResponse} from "axios";
import {AuthState} from "../../../models";
import {Either} from "monet";
import {CheckedError} from "../../../types/ServiceError";
import {catchErrorMessage} from "../../../lib/api-helpers";
import {call, put, takeLatest} from "@redux-saga/core/effects";
import {callFinished, callInProgress} from "../../actions/loading.action";
import {callInitiated, callInitiationFailed} from "../../actions/counsellor/calls.action";
import { PHYSICIAN_INITIATE_CALL } from "../../actions/physician/calls.action";

interface TokenResponse {
    token: string
}

const apiGetPhysicianToken = (action: { type: string, authState: AuthState, payload: { practiceId: string, patientId: string, locationId?: string } }) => {
    console.log(action.authState.accountId);
    const locationId = action.payload.locationId ? `locationId=${action.payload.locationId}` : '';
    const url = `/${action.authState.accountId}/comm/patient/${action.payload.patientId}?${locationId}`;
    return physicianHttpClient.get<Either<CheckedError, TokenResponse>>(url, { headers: { "x-auth-token": action.authState.token } })
        .then((response: AxiosResponse) => {
            return Either.right(response.data as TokenResponse)
        }).catch(e => catchErrorMessage(e));
}

function* fetchPhysicianCallToken(action: { type: string, authState: AuthState, payload: { practiceId: string, patientId: string, locationId?: string } }) {
    try {
        console.log(`CallsSaga:fetchPhysicianCallToken`)
        yield put(callInProgress())
        const apiTokenResponse: Either<CheckedError, TokenResponse> = yield call(apiGetPhysicianToken, action)
        if (apiTokenResponse.isLeft()) {
            const error = apiTokenResponse.left()
            if (error.isChecked) {
                console.log("CallsSaga:fetchPhysicianCallToken Encountered a Checked Error", error.message)
                yield put(callInitiationFailed());
            } else {
                yield put(callInitiationFailed());
            }
        } else {
            const response = apiTokenResponse.right();
            yield put(callInitiated(response));
        }
    } catch (e) {
        console.log(e)
        yield put(callInitiationFailed());
    } finally {
        yield put(callFinished())
    }
}

export default function* physicianCallsSaga() {
    yield takeLatest(PHYSICIAN_INITIATE_CALL, fetchPhysicianCallToken)
}
