import React, {useCallback, useEffect, useState} from "react";
import "../PhysicianDashboardScreen.scss";
import classNames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import {DateTime} from "luxon";

interface PhysicianDashboardDateFilterComponentProps {
    startDate: string;
    endDate: string;
    onFilterChange: (startDate: string, endDate: string) => void;
}

type TFilterOptions = {value: string, label: string};

const FilterOptions: TFilterOptions[] = [
    {value: "7", label: "7 Days"},
    {value: "30", label: "30 Days"},
    {value: "custom", label: "custom"}
];

const PhysicianDashboardDateFilterComponent = (props: PhysicianDashboardDateFilterComponentProps) => {

    const {startDate, endDate, onFilterChange} = props;
    const [showCustomDateFilter, setShowCustomDateFilter] = useState<boolean>(false);
    const [selectedFilter, setSelectedFilter] = useState<string>("7");
    const [filterStartDate, setFilterStartDate] = useState<Date | undefined>(undefined);
    const [filterEndDate, setFilterEndDate] = useState<Date | undefined>(undefined);

    useEffect(() => {
        if (props.startDate){
            setFilterStartDate(new Date(DateTime.fromISO(props.startDate).toJSDate()));
        }
        if (props.endDate){
            setFilterEndDate(new Date(DateTime.fromISO(props.endDate).toJSDate()));
        }
    }, [props.startDate, props.endDate]);

    const handleConfirm = useCallback(() => {
        if (onFilterChange && filterStartDate && filterEndDate) {
            onFilterChange(DateTime.fromJSDate(filterStartDate).startOf('day').toFormat('yyyy-MM-dd'), DateTime.fromJSDate(filterEndDate).startOf('day').toFormat('yyyy-MM-dd'));
        }
        setShowCustomDateFilter(false);
        setSelectedFilter("custom");
    }, [onFilterChange, filterStartDate, filterEndDate]);

    const handleCancel = useCallback(() => {
        setFilterStartDate(new Date(DateTime.fromISO(startDate).toJSDate()));
        setFilterEndDate(new Date(DateTime.fromISO(endDate).toJSDate()));
        setShowCustomDateFilter(false);
    }, [startDate, endDate]);

    const handleFilterClick = useCallback((filter: TFilterOptions) => {
        if (filter.value === "custom") {
            setShowCustomDateFilter(true);
        } else {
            if (filter.value === "7") {
                const newStartDate = DateTime.local().minus({days: 7}).toFormat('yyyy-MM-dd')
                const newEndDate = DateTime.local().endOf('day').toFormat('yyyy-MM-dd')
                onFilterChange(newStartDate, newEndDate);
            } else {
                const newStartDate = DateTime.local().minus({days: 30}).toFormat('yyyy-MM-dd')
                const newEndDate = DateTime.local().endOf('day').toFormat('yyyy-MM-dd')
                onFilterChange(newStartDate, newEndDate);
            }
            setShowCustomDateFilter( false);
            setSelectedFilter(filter.value);
        }
    }, [onFilterChange]);

    const handleChange = useCallback((dates: [Date, Date]) => {
        const [start, end] = dates;
        setFilterStartDate(start);
        setFilterEndDate(end);
    }, []);

    const handleClear = useCallback(()=>{
        setFilterStartDate(undefined);
        setFilterEndDate(undefined);
    }, []);

    return (
        <div className="physician-dashboard-date-filter filter-option-list">
            {FilterOptions.map((filter, index) => (
                <div
                    key={index}
                    onClick={() => handleFilterClick(filter)}
                    className={classNames("filter-option-item", selectedFilter === filter.value ? "active" : "")}
                >
                    {filter.value === "custom" ?
                        <>{selectedFilter === 'custom'  ? <> {DateTime.fromISO(startDate).toFormat('yyyy-MM-dd')} - {DateTime.fromISO(endDate).toFormat('yyyy-MM-dd')} </> : "Custom" }</>
                    : filter.label}
                </div>
            ))}
            {
                showCustomDateFilter && (
                    <div className="custom-date-filter-wrapper">
                        <DatePicker
                            onChange={handleChange}
                            startDate={filterStartDate}
                            endDate={filterEndDate}
                            maxDate={new Date()}
                            selectsRange
                            inline
                        >
                            <div className="custom-date-filter-actions">
                                <button
                                    onClick={handleCancel}
                                    className="rounded-l-md text-sjDarkGray py-2 px-2"
                                >Cancel
                                </button>
                                <button
                                onClick={handleClear}
                                className="rounded-l-md border-sjOrange text-sjOrange py-2 px-4"
                                >Clear
                            </button>&nbsp;
                                <button
                                    onClick={handleConfirm}
                                    disabled={!filterStartDate || !filterEndDate}
                                    className="bg-sj2 bg-sjOrange text-white py-2 px-4 rounded"
                                >Apply
                                </button>
                            </div>
                        </DatePicker>
                    </div>
                )
            }
        </div>
    );
};

export default PhysicianDashboardDateFilterComponent;
