import {ITherapistPatient} from "../../models";
import React, {useMemo} from "react";
import TablePaginationV2 from "../generic/TablePaginationV2";
import {PatientsDataView} from "./common/PatientsDataView";
import { useFlags } from "flagsmith/react";

interface PatientTableProps {
    dataView: PatientsDataView
    patients: ITherapistPatient[]
    searchInProgress: boolean | undefined
}

const PatientTable: React.FC<PatientTableProps> = ({dataView, patients, searchInProgress}) => {
    const flags = useFlags(['appointment_view_details_button'])
    const isAppointmentViewDetailEnabled = flags.appointment_view_details_button.enabled

    const columns = useMemo( dataView.getColumns(), [] );
    dataView.setPatients(patients)
    const data = dataView.getData()

    return <div>
        <TablePaginationV2 columns={columns} data={data} searchInProgress={searchInProgress} possibleColumnsToHide={{session: isAppointmentViewDetailEnabled ? false : true}}/>
    </div>
}

export default PatientTable
