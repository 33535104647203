import React, {useCallback, useEffect, useState} from 'react'
import {Link, useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {StateParams} from '../../../store/reducers'
import TableFilterPaginationV2 from '../../../components/generic/TableFilterPaginationV2'
import Select from "react-select";
import PaginationV2 from "../../../components/generic/PaginationV2";
import ScreeningPendingPatientListComponent from "./ScreenerPendingPatientsListComponent";
import {fetchScreenerPendingPatients} from "../../../store/actions/counsellor/non-physician-compliance.action";
import BackIcon from "../../../assets/images/common/back.svg";
import {PreferredLanguageFilterOptions} from "../../../services/common.service";
import {RoutePaths} from "../../../shared/Utils";
import { fetchPractices } from '../../../store/actions/counsellor/prospects.action'
import { BsFillInfoCircleFill } from 'react-icons/bs'
import { DateTime } from 'luxon'
import { useFlags } from 'flagsmith/react'
import SWComplianceListFilterV2 from '../care-coordinator/SWComplianceListFilterV2'
import { IoFilter } from 'react-icons/io5'

const month = DateTime.now().monthLong
const year = DateTime.now().year

const ScreenerPendingPatientsScreen = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams()
    const flags = useFlags(['cc_compliance_advanced_filters'])
    const [backLink, setBackLink] = useState<string>('');
    const {role} = useSelector((state: StateParams) => state.account);
    const isCareCoordinator = role === 'Care_Coordinator'
    const {
        isScreenerPendingPatientsInProgress,
        screenerPendingPatientList,
        screenerPendingPatientsCount,
    } = useSelector((state: StateParams) => state.nonPhysicianCompliance);
    const { practices } = useSelector((state: StateParams) => state.prospects);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [preferredLanguage, setPreferredLanguage] = useState<string>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [practiceIds, setPracticeIds] = useState<string[]>([]);
    const [pageNumber, setPageNumber] = useState(Number(searchParams.get("page")) || 1);
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const isAdvancedFilterEnabled = flags.cc_compliance_advanced_filters.enabled
    const dependencies = isAdvancedFilterEnabled ? [pageNumber, recordsPerPage, searchText] : [pageNumber, recordsPerPage, searchText, practiceIds, preferredLanguage]

    useEffect(() => {
        dispatch(fetchPractices());
    }, [dispatch]);
    
    useEffect(() => {
        const pathComponents = location.pathname.split("/");
        if (pathComponents.includes('coordinator')) {
            setBackLink(`${RoutePaths.careCoordinator.compliance.root}?page=${pageNumber}&tab=pendingMonthlyScreeners`);
        } else {
            setBackLink(`${RoutePaths.collaborativeCare.compliance.root}`);
        }
    }, [location]);

    useEffect(() => {
        if (searchParams) {
            if (searchParams.get("page")) {
                setPageNumber(Number(searchParams.get("page")))
            } else {
                setPageNumber(1);
            }
        }
    }, [searchParams])

    useEffect(() => {
        getUsers();
    }, dependencies);

    const getUsers = useCallback(() => {
        if (pageNumber || recordsPerPage || searchText || practiceIds.length > 0 || preferredLanguage) {
            dispatch(fetchScreenerPendingPatients({pageNumber, recordsPerPage, searchText, preferredLanguage, therapistId: params.therapistId, practiceIds}))
        }
    }, [pageNumber, recordsPerPage, searchText, practiceIds, preferredLanguage]);

    const onFiltersApply = useCallback(() => {
        setOpenFilterModal(false)
        dispatch(fetchScreenerPendingPatients({pageNumber, recordsPerPage, searchText, preferredLanguage, therapistId: params.therapistId, practiceIds}))
    },[dispatch, practiceIds, preferredLanguage])

    const renderFilters = useCallback(() => {
        return <div className='flex flex-row gap-3'>
            <Select
                className={"w-48"}
                options={PreferredLanguageFilterOptions}
                placeholder={"Language"}
                isClearable={true}
                isSearchable={false}
                onChange={(selectedOption: any) => {
                    setPageNumber(1);
                    setPreferredLanguage(selectedOption?.value);
                }}
            />
            <Select
                className={"w-48"}
                options={practices}
                onChange={(selectedOptions) => setPracticeIds(selectedOptions ? selectedOptions.map(option => option.id) : [])}
                placeholder={"Practice"}
                isClearable={true}
                isSearchable={false}
                isMulti={true}
                getOptionLabel={option => `${option?.name}`}
                getOptionValue={option => option?.id}
            />
            <TableFilterPaginationV2
                setTableFilter={setSearchText}
                totalRows={screenerPendingPatientsCount || 0}
                currentPageHandler={setPageNumber}
            />
        </div>
    }, [screenerPendingPatientsCount, searchText, pageNumber]);

    return (
        <div className={"w-full p-4 h-full bg-[#F7F8FA]"}>
            <div>
                <div className="flex flex-row justify-between items-center">
                    <div className={"flex flex-row gap-2 items-center"}>
                        <Link to={backLink}>
                            <img src={BackIcon} width={24} height={24} alt="Go Back"
                                 className={"hover:cursor-pointer"}/>
                        </Link>
                        <div id={"link-section"} className={"flex flex-row gap-2 items-center"}>
                            <span
                                className="text-xl"> Pending {`${month}`} Screeners ({screenerPendingPatientsCount || 0})  </span>
                        </div>
                    </div>
                    <div className='flex flex-row gap-x-2 mr-4 items-center'>
                        {isAdvancedFilterEnabled && <TableFilterPaginationV2
                            setTableFilter={setSearchText}
                            totalRows={screenerPendingPatientsCount || 0}
                            currentPageHandler={setPageNumber}
                        />}
                        {isAdvancedFilterEnabled && <IoFilter className="h-7 w-7 cursor-pointer" color="#575F6E" onClick={() => setOpenFilterModal(true)} />}
                        {!isAdvancedFilterEnabled && renderFilters()}
                        {openFilterModal && <SWComplianceListFilterV2
                            isOpen={openFilterModal}
                            setOpenFilterModal={setOpenFilterModal}
                            practices={practices}
                            practiceIds={practiceIds}
                            setPracticeIds={setPracticeIds}
                            searchText={searchText}
                            setSearchText={setSearchText}
                            recordsCount={screenerPendingPatientsCount}
                            setCurrentPage={setPageNumber}
                            onFilterChange={(startDate, endDate) =>{}}
                            isPendingNotes={false}
                            onFiltersApply={onFiltersApply}
                            preferredLanguage={preferredLanguage}
                            setPreferredLanguage={setPreferredLanguage}
                        />}
                    </div>
                </div>
                {isCareCoordinator ? <div className="flex flex-row mt-2 items-center gap-x-2 mb-2">
                    <BsFillInfoCircleFill color="#575F6E" width={16} height={16} />
                    <span className="text-base text-sjLightGrey font-light">Contact the social worker to ensure they complete the <span className='font-bold'>{`${month},${year}`}</span> Screeners.</span>
                </div> : null}
                <div className="mt-4 relative">
                    <ScreeningPendingPatientListComponent searchInProgress={isScreenerPendingPatientsInProgress}
                        patients={screenerPendingPatientList}
                        currentPageHandler={setPageNumber} />
                    {screenerPendingPatientList && screenerPendingPatientList.length > 0 &&
                        <PaginationV2
                            totalRows={screenerPendingPatientsCount || 0}
                            rowsPerPage={recordsPerPage}
                            recordsPerPageHandler={setRecordsPerPage}
                            currentPage={pageNumber}
                            currentPageHandler={setPageNumber}
                            showPages={false}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default ScreenerPendingPatientsScreen;
