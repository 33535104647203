import {
    CALL_INITIATED,
    CALL_INITIATION_FAILED,
    END_CALL,
    END_CALL_REQUESTED,
    REQUEST_CALL,
    RESET_CALL,
    SET_CALL_ERROR_REASON,
    SET_CALL_STATUS
} from "../../actions/counsellor/calls.action";
import {CallStatus, IPatientInCall} from "../../../models";

export interface CallParams {
    patient?: IPatientInCall
    token?: string
    errorReason?: string
    callStatus: CallStatus
}

const initialState = {
    patient: undefined,
    token: undefined,
    errorReason: undefined,
    callStatus: CallStatus.OFFLINE
}

const CallsReducer = (state: CallParams = initialState, action: any) : CallParams => {
    switch (action.type) {
        case REQUEST_CALL:
            state = {...state, ...{
                patient: action.payload.patient,
                callStatus: CallStatus.REQUESTED
            }}
            return state
        case CALL_INITIATED:
            state = {...state, ...{
                    callStatus: CallStatus.INITIATED,
                    token: action.payload.token
                }}
            return state
        case CALL_INITIATION_FAILED:
            state = {...state, ...{
                    callStatus: CallStatus.FAILED_INITIATION
                }}
            return state
        case END_CALL_REQUESTED:
            state = {...state, ...{
                    callStatus: CallStatus.END_CALL_REQUESTED
            }}
            return state
        case END_CALL:
            state = {...state, callStatus: CallStatus.ENDED }
            return state
        case RESET_CALL:
            state = {...state, ...initialState }
            return state
        case SET_CALL_STATUS:
            state = {...state, ...{
                    callStatus: action.payload.status
                }}
            return state
        case SET_CALL_ERROR_REASON:
            state = {...state, ...{
                    errorReason: action.payload.errorReason
                }}
            return state
        default:
            return state;
    }
}

export default CallsReducer
