import {AiOutlineClose} from "react-icons/ai";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import Message from "../../components/generic/Message";
import {Input} from "@material-tailwind/react";
import Select from "react-select";
import {UserRoles} from "../../services/common.service";
import PhoneNumberInputComponent from "../../components/generic/PhoneNumberInputComponent";
import SwitchComponent from "../../components/generic/SwitchComponent";
import {IUser, IUserForm} from "../../models";
import {resetSaveUser, saveUser} from "../../store/actions/physician/admin-operations.action";
import {Tooltip} from "react-tooltip";
import ConfirmationModal from "../../components/clinical-notes/ConfirmationModal";

interface IUserFormScreenProps {
    user?: IUser;
    onClose: (refreshList: boolean) => void;
}

const UserFormScreen = (props: IUserFormScreenProps) => {

    const {
        providerId,
        roleType: loggedInUserRole
    } = useSelector((state: StateParams) => state.physicianAccount);
    const dispatch = useDispatch();
    const {user, onClose} = props;
    const {
        // locations,
        savingUserInProgress,
        savedUser,
        saveUserError
    } = useSelector((state: StateParams) => state.physicianAdminOperations);

    const [formError, setFormError] = useState<string>('');
    const isEdit = useMemo(() => !!user, [user]);
    const mode: "Add" | "Edit" = isEdit ? 'Edit' : 'Add';

    const isLoggedInUserLead = loggedInUserRole === 'lead';
    const allowedUserRolesToBeCreatedOrModified = isLoggedInUserLead ? UserRoles : UserRoles.filter(userRole => userRole.value !== 'lead');

    const userRole = user?.role;
    const userId = user?.id;
    const isSelfAccount = isEdit && userId === providerId;
    const isLead = userRole === 'lead';
    const canEditRole = !isEdit || (isEdit && !isSelfAccount && !isLead);
    const canToggle = !isEdit || (isEdit && !isLead && !isSelfAccount);
    const editRoleDisableReason = !canEditRole ? (isSelfAccount ? "Self account role can't be changed" : "Admin role can't be changed") : "";
    const toggleDisableReason = !canToggle ? (isSelfAccount ? "Self account can't be deactivated" : "Admin can't be deactivated") : "";

    const {
        register,
        control,
        handleSubmit,
        getValues,
        formState: {errors},
        reset,
        watch,
        trigger,
        setValue
    } = useForm<IUserForm>({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            // locationId: '',
            role: '',
            isActive: undefined,
            canMARefer: undefined,
            npi:''
        }
    });

    console.log(getValues())

    const save = useCallback((shouldResetAndResendCredentials?: boolean) => {
        const formData = getValues();
        const payload = {
            ...formData,
            shouldResetAndResendCredentials
        }
        console.log("User Form Screen: Save User: ", payload);
        dispatch(saveUser(payload));
    }, [dispatch]);

    useEffect(() => {
        if (!savingUserInProgress && savedUser) {
            onClose(true);
        }
    }, [savingUserInProgress, savedUser, dispatch, onClose]);

    useEffect(() => {
        if (saveUserError) {
            setFormError(saveUserError);
        }
        return () => {
            dispatch(resetSaveUser());
        }
    }, [saveUserError, dispatch]);

    const validateNPI = (value:any) => {
        return /^\d{10}$/.test(value) || 'NPI number must be exactly 10 digits';
    };

    useEffect(() => {
        if (isEdit) {
            reset({
                id: user?.id,
                firstName: user?.firstName,
                lastName: user?.lastName,
                email: user?.email,
                phone: user?.phone,
                npi: user?.npi,
                // locationId: user?.providerLocations[0]?.locationId,
                role: user?.role,
                isActive: user?.isActive,
                canMARefer: user?.canMARefer
            });
        }
    }, [user, isEdit, reset]);

    const onFormChange = useCallback(() => {
        setFormError('');
    }, []);

    const handleDrawerClose = useCallback(() => {
        onClose(false);
        setFormError('');
        dispatch(resetSaveUser());
    }, [onClose, dispatch]);

    const [isResetAndSendCredentialsModalOpen, setIsResetAndSendCredentialsModalOpen] = useState<boolean>(false);

    const openResetAndSendCredentialsModal = useCallback(() => {
        setIsResetAndSendCredentialsModalOpen(true);
    }, []);

    const closeResetAndSendCredentialsModal = useCallback(() => {
        setIsResetAndSendCredentialsModalOpen(false);
    }, []);

    const submitResetAndSendCredentialsModal = useCallback((shouldAnnounce: boolean) => {
        closeResetAndSendCredentialsModal();
        save(shouldAnnounce);
    }, [closeResetAndSendCredentialsModal, save]);

    const onSubmit = useCallback(handleSubmit((data: IUserForm) => {
        if (isEdit) {
            if (data.email !== user?.email) {
                openResetAndSendCredentialsModal();
            } else {
                save();
            }
        } else {
            save();
        }
    }), [user, save, openResetAndSendCredentialsModal]);

    const renderResetAndSendCredentialsModal = useCallback(() => {
        return <ConfirmationModal
            isOpen={isResetAndSendCredentialsModalOpen}
            onClose={closeResetAndSendCredentialsModal}
            modalClassNames={`w-100 border-red-500`}
            actions={<div className={"flex gap-2 justify-between"}>
                <button className={`inline-block  rounded shadow py-2 px-5 text-sm`} onClick={
                    () => submitResetAndSendCredentialsModal(false)
                }>
                    No, Don't Reset
                </button>
                <button className={`inline-block text-white rounded shadow py-2 px-5 text-sm bg-sjDarkRed`}
                        onClick={
                            () => submitResetAndSendCredentialsModal(true)
                        }>
                    Yes, Reset & Send
                </button>
            </div>
            }
        >
            <div>
                <h2 className={"font-black text-xl"}> Confirmation </h2>
                <div className={"mt-3"}>
                    User email has been edited, do you want to reset credentials ( Email, Password ) and send email?
                </div>
            </div>
        </ConfirmationModal>
    }, [isResetAndSendCredentialsModalOpen, closeResetAndSendCredentialsModal, submitResetAndSendCredentialsModal]);

    return (
        <div className={"border-r p-5 h-screen"}>
            <div className="mb-5">
                <div className="flex justify-content-between">
                    <div className="text-2xl font-bold text-[#242731] flex-1 mb-4">{mode} User</div>
                    <div
                        onClick={handleDrawerClose}
                        className="cursor-pointer grow-0"
                    >
                        <AiOutlineClose
                            className="text-gray-300 hover:text-gray-600"
                            style={{width: '25px', height: '25px'}}
                        />
                    </div>
                </div>
                <div
                    className={"text-base text-[#575F6E] font-light"}>{isEdit ? "Make changes to a user profile." : "Add a user to your practice by filling the details below"}</div>
            </div>
            <form onSubmit={onSubmit} onChange={onFormChange}>
                <div>
                    <div className={"grid grid-cols-2 gap-10"}>
                        <div className="">
                            <label className="block text-sm mt-6 text-sjDarkGray">First Name</label>
                            <Input type={'text'}
                                   size="lg"
                                   placeholder="Enter First Name" {...register("firstName", {required: 'This field is required'})}
                                   className="mt-1 border-sjLighterGray"/>
                            {errors.firstName?.message &&
                                <Message message={errors.firstName.message} className={'error-msg text-sm'}/>}
                        </div>
                        <div className="">
                            <label className="block text-sm mt-6 text-sjDarkGray">Last Name</label>
                            <Input type={'text'}
                                   size="lg"
                                   placeholder="Enter Last Name" {...register("lastName", {required: 'This field is required'})}
                                   className="mt-1 border-sjLighterGray"/>
                            {errors.lastName?.message &&
                                <Message message={errors.lastName.message} className={'error-msg text-sm'}/>}
                        </div>
                    </div>
                    <div className={"grid grid-cols-2 gap-10"}>
                        <div className="">
                            <label className="block text-sm mt-6 text-sjDarkGray">Email</label>
                            <Input type={'text'}
                                   size="lg"
                                   placeholder="Enter Email" {...register("email", {required: 'This field is required'})}
                                   className="mt-1 border-sjLighterGray"/>
                            {errors.email?.message &&
                                <Message message={errors.email.message} className={'error-msg text-sm'}/>}
                        </div>
                        <div className="">
                            <label className="block text-sm mt-6 text-sjDarkGray">Phone Number</label>
                            <Controller
                                name="phone"
                                control={control}
                                rules={{required: 'This field is required'}}
                                render={({field}) => (
                                    <PhoneNumberInputComponent
                                        {...field}
                                        placeholder="Enter Phone Number"
                                        id="phoneUser"
                                        className="mt-1 border-sjLighterGray"
                                    />
                                )}
                            />
                            {errors.phone?.message &&
                                <Message message={errors.phone.message} className={'error-msg text-sm'}/>}
                        </div>
                    </div>
                    <div className={"grid grid-cols-2 gap-10"}>
                        <div className="">
                            <label className="block text-sm mt-6 text-sjDarkGray">Role</label>
                            <Controller
                                control={control}
                                name='role'
                                rules={{required: 'This field is required'}}
                                render={({field}) => (
                                    <>
                                        <Tooltip
                                            id="user-role-field"
                                            place="top"
                                            hidden={canEditRole}
                                        >
                                            {editRoleDisableReason}
                                        </Tooltip>
                                        <div data-tooltip-id="user-role-field">
                                            <Select
                                                placeholder={"Select Role"}
                                                className="mt-1 outline-none rounded-md"
                                                isDisabled={!canEditRole}
                                                options={allowedUserRolesToBeCreatedOrModified}
                                                getOptionValue={x => x.value}
                                                getOptionLabel={x => x.label}
                                                value={UserRoles?.find(x => x?.value === field?.value)}
                                                onChange={x => {
                                                    setValue('canMARefer',false);
                                                    setValue('npi', "");
                                                    field.onChange(x?.value)
                                                }}
                                                defaultValue={undefined}
                                                isSearchable={false}
                                            />
                                        </div>
                                    </>
                                )}
                            />
                            {errors.role?.message &&
                                <Message message={errors.role.message} className={'error-msg text-sm'}/>}
                        </div>
                        { watch('role') === 'medical_assistant' && <div className="">
                        <label className="block text-sm mt-6 text-sjDarkGray">Medical Assistant Referral Feature</label>
                        <Controller
                            control={control}
                            name='canMARefer'
                            render={({field}) => (
                                <SwitchComponent
                                    onChange={x => {
                                        field.onChange(x)
                                    }}
                                    isChecked={field.value}
                                    className="mt-1 outline-none rounded-md"
                                    disabled={!canToggle}
                                    disabledReason={toggleDisableReason}
                                />
                            )}
                        />
                        {errors.canMARefer?.message &&
                            <Message message={errors.canMARefer.message} className={'error-msg text-sm'}/>}
                    </div>
                    }

                        { watch('role') === "provider" &&
                         <div className="">
                            <label className="block text-sm mt-6 text-sjDarkGray">NPI Number</label>
                            <Input type={'text'}
                                   size="lg"
                                   {...register("npi", {
                                    required: 'This field is required',
                                    validate: validateNPI
                                })}
                                onChange={(e) => {
                                    const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
                                    setValue("npi", value, { shouldValidate: true });
                                }}
                                onBlur={(e) => trigger("npi")}
                                   className="mt-1 border-sjLighterGray"/>
                            {errors.npi?.message &&
                                <Message message={errors.npi.message} className={'error-msg text-sm'}/>}
                        </div>
                    }
                        {/*<div className="">*/}
                        {/*    <label className="block text-sm mt-6 text-sjDarkGray">Location</label>*/}
                        {/*    <Controller*/}
                        {/*        control={control}*/}
                        {/*        name='locationId'*/}
                        {/*        rules={{required: 'This field is required'}}*/}
                        {/*        render={({field}) => (*/}
                        {/*            <Select*/}
                        {/*                placeholder={"Select Location"}*/}
                        {/*                className="mt-1 outline-none rounded-md"*/}
                        {/*                options={locations}*/}
                        {/*                getOptionValue={x => x.id}*/}
                        {/*                getOptionLabel={x => x.name}*/}
                        {/*                value={locations?.find(x => x?.id === field?.value)}*/}
                        {/*                onChange={x => field.onChange(x?.id)}*/}
                        {/*                defaultValue={undefined}*/}
                        {/*            />*/}
                        {/*        )}*/}
                        {/*    />*/}
                        {/*    {errors.locationId?.message &&*/}
                        {/*        <Message message={errors.locationId.message} className={'error-msg text-sm'}/>}*/}
                        {/*</div>*/}
                    </div>
                    {isEdit && <div className="">
                        <label className="block text-sm mt-6 text-sjDarkGray">User Status</label>
                        <Controller
                            control={control}
                            name='isActive'
                            render={({field}) => (
                                <SwitchComponent
                                    onChange={x => field.onChange(x)}
                                    isChecked={field.value}
                                    className="mt-1 outline-none rounded-md"
                                    disabled={!canToggle}
                                    disabledReason={toggleDisableReason}
                                />
                            )}
                        />
                        {errors.isActive?.message &&
                            <Message message={errors.isActive.message} className={'error-msg text-sm'}/>}
                    </div>}
                </div>
                <div className="text-sjDarkRed mt-4 mb-1 pt-2 text-center">{formError}</div>
                <div className={"mt-10"}>
                    <button type={"submit"}
                            disabled={savingUserInProgress}
                            className={`inline-block text-white rounded shadow py-2 px-5 text-sm ${savingUserInProgress ? 'bg-sjLightOrange' : 'bg-sjOrange'}`}>
                        {savingUserInProgress ? "Saving" : (isEdit ? "Save Changes" : "Add User")}
                    </button>
                </div>
            </form>
            {renderResetAndSendCredentialsModal()}
        </div>
    );
}

export default UserFormScreen;
