import {DateTime} from "luxon";
import React from "react";
import {useSelector} from "react-redux";
import {IProspect} from "../../models";
import {StateParams} from "../../store/reducers";

interface PatientProps {
    prospect: IProspect
}

const PatientInformation: React.FC<PatientProps> = ({ prospect }) => {
    const sourcePatient = useSelector((state: StateParams) => state.patients.sourcePatient)
    const physicianAccount = useSelector((state: StateParams) => state.physicianAccount)
    const dob = sourcePatient?.dob
    const dobFormatted = dob && DateTime.fromISO(dob.toString(), { zone: 'utc' }).toFormat('MM/dd/yyyy')
    const gender = sourcePatient?.gender || 'N/A'
    const zipCode = sourcePatient?.zipCode || 'N/A'
    const referredOn = sourcePatient?.referredDate && DateTime.fromISO(sourcePatient?.referredDate.toString(), {zone: 'utc'}).toFormat('MM/dd/yyyy')  || 'N/A'
    const referringPhysician = prospect?.referredByPhysicianFirstName ? 'Dr. ' + (prospect?.referredByPhysicianFirstName ? prospect.referredByPhysicianFirstName + ' ' + prospect?.referredByPhysicianLastName : prospect?.referredByPracticeName) : 'N/A'
    const medicalAssistant = sourcePatient?.patientStatuses && sourcePatient?.patientStatuses[0]?.medicalAssistant
    const referringMA = medicalAssistant ? `${medicalAssistant.firstName} ${medicalAssistant.lastName}` : 'N/A'
    const psychiatrist = sourcePatient?.therapistProspect?.length ? sourcePatient?.therapistProspect[0].psychiatrist : null
    const psychiatristName = psychiatrist ? `Dr. ${psychiatrist.firstName} ${psychiatrist.lastName}` : 'N/A'
    const psychiatristNPI = psychiatrist?.npi || 'N/A'


    const renderSkeletonLoader = () => {
        return (
            <div className="animate-pulse">
                <div className="h-2 bg-gray-200 rounded"></div>
            </div>
        )
    }
    const renderPsychiatristInformationForPhysician = () => {
        return (
            <>
                <div className={"pl-6 text-left col-start-8"}>
                    <p className={"text-sjGrey text-sm tracking-wide"}>Psychiatrist</p>
                    <p className={"mt-1.5 text-black text-sm"}>{sourcePatient ? psychiatristName : renderSkeletonLoader()}</p>
                </div>
                <div className={"pl-6 text-left col-start-9"}>
                    <p className={"text-sjGrey text-sm tracking-wide"}>Psychiatrist NPI </p>
                    <p className={"mt-1.5 text-black text-sm"}>{sourcePatient ? psychiatristNPI : renderSkeletonLoader()}</p>
                </div>
            </>
        )
    }

    return (
        <>
            <div className={"text-left col-start-1"}>
                <p className={"text-sjGrey text-sm tracking-wide"}>Gender</p>
                <p className={"mt-1.5 text-black text-sm capitalize"}>{sourcePatient ? gender : renderSkeletonLoader()}</p>
            </div>
            <div className={"pl-6 text-left col-start-2"}>
                <p className={"text-sjGrey text-sm tracking-wide"}>Date of Birth</p>
                <p className={"mt-1.5 text-black text-sm"}>{sourcePatient ? dobFormatted : renderSkeletonLoader()}</p>
            </div>
            <div className={"pl-6 text-left col-start-3"}>
                <p className={"text-sjGrey text-sm tracking-wide"}>Zip code</p>
                <p className={"mt-1.5 text-black text-sm"}>{sourcePatient ? zipCode : renderSkeletonLoader()}</p>
            </div>
            {medicalAssistant &&
                <div className={"pl-6 text-left col-start-4"}>
                <p className={"text-sjGrey text-sm tracking-wide"}>Referring MA</p>
                <p className={"mt-1.5 text-black text-sm"}>{referringMA}</p>
                </div>
                }
            <div className={"pl-6 text-left col-start-6"}>
                <p className={"text-sjGrey text-sm tracking-wide"}>Referring Physician</p>
                <p className={"mt-1.5 text-black text-sm"}>{sourcePatient ? referringPhysician : renderSkeletonLoader()}</p>
            </div>
            <div className={"pl-6 text-left col-start-7"}>
                <p className={"text-sjGrey text-sm tracking-wide"}>Date of Referral </p>
                <p className={"mt-1.5 text-black text-sm"}>{sourcePatient ? referredOn : renderSkeletonLoader()}</p>
            </div>
            {physicianAccount?.id && renderPsychiatristInformationForPhysician()}
        </>
    )
}

export default PatientInformation
