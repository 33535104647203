import React from "react";
import {Link} from "react-router-dom";

function renderPill(value: { link: string; intakeSessionReadyToStart: boolean }) {
    return value.intakeSessionReadyToStart ?
        <Link to={value.link}>
            <span className="rounded-l-md border-sjOrange text-sjLink">
                Start
            </span>
        </Link> : <></>
}

export const IntakeActionPill: React.FC<{ value: {link: string, intakeSessionReadyToStart: boolean} }> = ({ value }) => {
    return renderPill(value);
}

export const IntakeActionPillV2: React.FC<{ getValue: any }> = ({ getValue }) => {
    const value = getValue()
    return renderPill(value);
}
