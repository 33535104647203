import {IAccount} from "../../models";

export const SIGN_OUT_ACCOUNT = "SIGN_OUT_ACCOUNT"
export const SIGN_INTO_ACCOUNT = "SIGN_INTO_ACCOUNT"

export const CLEAR_ACCOUNT = "CLEAR_ACCOUNT"

export const logout = () => {
    return {type:SIGN_OUT_ACCOUNT}
}

export const signinSuccessful = (payload: IAccount) => {
    return {type:SIGN_INTO_ACCOUNT, payload}
}

export const clearAccount = () => {
    return {type: CLEAR_ACCOUNT}
}
