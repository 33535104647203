import React from 'react'
import { useSelector } from 'react-redux';
import { StateParams } from '../../store/reducers';
import SjLogo from '../../assets/images/qrPager/SjLogo.png'
import CreateAccount from '../../assets/images/qrPager/CreateAccount.png'
import QA from '../../assets/images/qrPager/QA.png'
import ConsultDoctor from '../../assets/images/qrPager/ConsultDoctor.png'

const ComponentToPrint = React.forwardRef<HTMLDivElement>((props, ref) => {
    const { qrCodeUrl, name } = useSelector((state: StateParams) => state.physicianAccount)

    return (
        <div ref={ref} className='flex flex-col justify-center items-center'>
            <img src={SjLogo} />
            <p className='mt-2 text-sjPediatrician font-bold text-xl'>{name}</p>
            <p className='mt-2 flex flex-wrap w-[325px] text-center font-bold text-2xl'>Please scan the QR code to begin the questionnaires</p>
            {qrCodeUrl && <div className='mt-2 mb-4 h-[266px] w-[268px] bg-no-repeat bg-qrFrame'>
                <img src={qrCodeUrl} className='w-[248px] h-[248px] absolute mt-2.5 ml-2.5 rounded-xl' />
            </div>}
            <div className='h-[170px] w-[500px] border-2 rounded-2xl'>
                <p className='mt-2 text-center'>How it works</p>
                <div className='mt-2 flex justify-center'>
                    <div className='mx-8 w-[100px] flex flex-col items-center text-center'>
                        <img src={CreateAccount} />
                        <p>Create Account</p>
                    </div>
                    <div className='mx-8 w-[100px] flex flex-col items-center text-center'>
                        <img src={QA} />
                        <p>Answers the Questionnaires</p>
                    </div>
                    <div className='mx-8 w-[100px] flex flex-col items-center text-center'>
                        <img src={ConsultDoctor} />
                        <p>Consult your Doctor</p>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ComponentToPrint;