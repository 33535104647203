// PasswordInput.js

import React, { useState } from 'react';
import { GoEye, GoEyeClosed } from "react-icons/go";

interface Props {
    value: string;
    onChange: (ev: any) => void;
    onKeyDown?: (ev: any) => void;
}

const PasswordInput = ({ value, onChange, onKeyDown }: Props) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <div className="relative">
            <input
                value={value}
                data-test="login-password"
                type={showPassword ? 'text' : 'password'}
                autoComplete={"off"}
                autoCorrect={"off"}
                autoCapitalize={"off"}
                className="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                onChange={onChange}
                onKeyDown={onKeyDown}
            />
            <span className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" onClick={() => {setShowPassword(!showPassword)}}>
                {!showPassword ? <GoEye className="h-5 w-5 text-gray-400" /> : <GoEyeClosed className="h-5 w-5 text-gray-400" />}
            </span>
        </div>
    );
};

export default PasswordInput;
