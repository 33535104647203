import React, {useCallback, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import PatientAutocomplete from "../patients/PatientAutocomplete";
import {createAppointment, searchAppointments} from "../../store/actions/counsellor/appointments.action";
import {useDispatch, useSelector} from "react-redux";
import {DateTime} from "luxon";
import {StateParams} from "../../store/reducers";
import {useNavigate} from "react-router";
import "./Datepicker.scss"
import {SessionTypes} from "../../models";
import {PageButtonV2} from "../generic/Buttons";
import _ from "lodash";
import {useFlags} from "flagsmith/react";

type AppointmentFormData = {
    description: string
    patientId: string
    sessionType: keyof typeof SessionTypes
    selectedDate: Date
    sourcePatientId: string
    referredByPracticeId: string
};

const AppointmentCreateComponent : React.FC = () => {

    const dispatch = useDispatch()
    const router = useNavigate()
    const location = useLocation();
    const screen = location.state?.screen;
    const options = location.state?.sessionTypes
    const defaultSessionType = location.state?.defaultSessionType
    const { control, register, handleSubmit, formState: { errors }, reset, watch, getValues, setValue } = useForm<AppointmentFormData>();
    const appointmentCreated = useSelector((state: StateParams) => state.appointments.createdAppointment)
    const createAppointmentError = useSelector((state: StateParams) => state.appointments.createAppointmentError)
    const role = useSelector((state: StateParams) => state.account.role)
    const [appointmentRequest, setAppointmentRequest] = useState<AppointmentFormData>()
    const watchSessionType= watch("sessionType", defaultSessionType);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const debouncedSubmit = useCallback(_.debounce((data: AppointmentFormData) => {
        setAppointmentRequest(data)
        const scheduledDate = DateTime.fromJSDate(data.selectedDate).toUTC().toISO();
        if(scheduledDate) {
            const appointmentRequest = { ...data, scheduledDate: scheduledDate }
            dispatch(createAppointment(appointmentRequest, data.sourcePatientId, data.referredByPracticeId))
        }
    }, 1000, {trailing: true}), [watchSessionType])

    const onSubmit = handleSubmit(debouncedSubmit)

    useEffect(() => {
        if(appointmentRequest && appointmentCreated && appointmentCreated.patientId === appointmentRequest.patientId && !createAppointmentError) {
            if(role === 'Psychiatrist') {
                router(`/patients/${appointmentCreated.patientId}/psychiatrist-notes`)
            } else {
                const params = { currentPage: 1, recordsPerPage: 10 }
                dispatch(searchAppointments(params.currentPage, params.recordsPerPage, ''))
                router(-1)
            }
        }
    }, [appointmentRequest, appointmentCreated, createAppointmentError])

    function renderTopButtonPanel() {
        return <div>
            <Link to={'...'} onClick={(e) => { e.preventDefault(); router(-1); }}>
                <PageButtonV2
                    className={"!bg-sjOrange !text-white !ml-2 px-[16px] py-[8px] !h-8 text-sm"}
                >
                    <div className={"flex flex-row gap-x-2 items-center"}>
                        <span>Back to {screen}</span>
                    </div>
                </PageButtonV2>
            </Link>
        </div>;
    }

    const filterTimeToBeInFuture = useCallback((time: Date) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    }, []);

    const handleSessionTypeChange = (e: any) => {
        const selectedSessionType = e.target.value;
        reset({
            sessionType: selectedSessionType,
            patientId: undefined
        });

    };

    function renderFormForAppointment() {
        return (
                <div className={"mt-10"}>
                    <form onSubmit={onSubmit}>
                            <div className="mb-8">
                                <label
                                    htmlFor="sessionType"
                                    className={`block text-md mb-2 ${
                                        errors.sessionType ? "text-red-400" : "text-sjOrange"
                                    }`}
                                >
                                    Type
                                </label>
                                <select {...register("sessionType", {required: true})}
                                        className={`block w-full bg-transparent outline-none border-b-2 py-2 px-4  placeholder-gray-500 ${
                                            errors.sessionType
                                                ? "text-red-300 border-red-400"
                                                : "text-black border-sjOrange"
                                        }`}
                                        defaultValue={defaultSessionType}
                                        onChange={handleSessionTypeChange}>
                                        { options.map((item: {key: string, value: string}) => <option value={item.key}>{item.value}</option>) }
                                </select>
                            </div>
                            <div className="mb-8">
                                <label
                                    htmlFor="patientId"
                                    className={`block text-md mb-2 ${
                                        errors.patientId ? "text-sjOrange" : "text-sjOrange"
                                    }`}
                                >
                                    Select Patient
                                </label>

                                <Controller
                                    {...register("patientId", {required: true})}
                                    control={control}
                                    name='patientId'
                                    render={({field}) => (
                                        <PatientAutocomplete ref={field.ref}
                                                             sessionType={watchSessionType}
                                                             setValue={setValue}
                                                             onChange={field.onChange}
                                                             patientId={getValues("patientId")}
                                                             className={`block w-full bg-transparent outline-none border-b-2 py-2 px-4  placeholder-gray-500 ${
                                                                 errors.patientId
                                                                     ? "text-black border-sjOrange"
                                                                     : "text-black border-sjOrange"
                                                             }`}
                                        />
                                    )}
                                />
                                {errors.patientId && (
                                    <p className="error-msg">
                                        Patient selection is required.
                                    </p>
                                )}
                            </div>
                            <div className="mb-8 grid grid-cols-2">
                                <label
                                    htmlFor="sessionDate"
                                    className={`block text-md mb-2 self-center ${
                                        errors.selectedDate ? "text-sjOrange" : "text-sjOrange"
                                    }`}
                                >
                                    {`Date (${timeZone})`}
                                </label>
                                <Controller
                                    {...register("selectedDate", {required: true})}
                                    control={control}
                                    name='selectedDate'
                                    render={({ field }) => (
                                        <DatePicker
                                            className={`block w-full bg-transparent outline-none border-b-2 py-2 px-4 placeholder-gray-500 ${
                                                errors.selectedDate
                                                    ? "text-black border-sjOrange"
                                                    : "text-black border-sjOrange"
                                            }`}
                                            dateFormat="d MMM yyyy h:mm aa"
                                            minDate={new Date()}
                                            showTimeSelect={true}
                                            filterTime={filterTimeToBeInFuture}
                                            todayButton="Today"
                                            dropdownMode="select"
                                            isClearable
                                            shouldCloseOnSelect
                                            onChange={(date) => field.onChange(date)}
                                            selected={field.value}
                                            timeIntervals={10}
                                        />
                                    )}
                                />
                                {errors.selectedDate && (
                                    <p className="error-msg">
                                        A valid Session Date is required.
                                    </p>
                                )}
                            </div>
                            <div className="mb-8">
                                <label
                                    htmlFor="sessionDescription"
                                    className={`block text-md mb-2 ${
                                        errors.description ? "text-sjOrange" : "text-sjOrange"
                                    }`}
                                >
                                    Session Description
                                </label>
                                <textarea
                                    {...register("description")}
                                    className={`block w-full bg-transparent outline-none border-b-2 py-2 px-4  placeholder-gray-500 text-black border-sjOrange`}
                                />

                            </div>
                            <input type={"submit"} value={"Submit"}
                                   className="inline-block bg-orange-500 text-white rounded shadow py-2 px-5 text-sm"/>
                            {createAppointmentError && <p className="error-msg">{createAppointmentError}</p>}
                    </form>
                </div>
        )
    }

    return (<div className="py-10 px-10 bg-white-700 min-h-screen">
        {renderTopButtonPanel()}
        {renderFormForAppointment()}
    </div>)
}

export default AppointmentCreateComponent
