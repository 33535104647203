import { useCallback, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { IProspect, NotesStatus } from "../../../models"
import Select from "react-select";
import icdCodes from "../../../assets/data/icd-codes.json";
import medications from "../../../assets/data/medications";
import { Radio, Textarea } from "@material-tailwind/react";
import WeeklyGuidance from "./WeeklyGuidance";
import MentalExam from "./MentalExam";
import { useDispatch, useSelector } from "react-redux";
import { StateParams } from "../../../store/reducers";
import { saveProgressNotes } from "../../../store/actions/counsellor/patients.action";
import _ from "lodash";
import Screeners from "../screeners/Screeners";
import ProgressNotesScreeners from "../screeners/ProgressNotesScreeners";
import { useSearchParams } from "react-router-dom";

export type Inputs = {
    mentalBehavioral: any
    socialDeterminants: any
    changeInDiagnosis: string
    hygiene: string[], grooming: string[], eyeContact: string[], behavior: string[],
    motorActivity: string[],
    affect: string[],
    rate: string[], fluencyOrRhythm: string[], relevance: string[],
    thoughtProcess: string[],
    delusions: string[], suicidalThought: string[], homicidalThought: string[],
    mentalStatusOther: string,
    selfInjuriousBehaviorRadio: string, selfInjuriousBehaviorText: string,
    violenceTowardsOthersRadio: string, violenceTowardsOthersText: string,
    medicationsRadio: string
    medicationsText: string
    fromCurrentPhysician: any
    patientMedicationsHistory: any
    sessionNotes: string
    lastWeekGuidance: { weeksGuidance: string, completionStatus: 'not_completed' | 'partially_completed' | 'completed' }[]
    weeklyGuidance: { weeksGuidance: string, completionStatus: 'not_completed' | 'partially_completed' | 'completed' }[]
    proposedMedication: string
    psychiatristAdditionalNotes: string
    clinicalSessionType: string
    documentationTime: string
}

const ProgressNotes: React.FC<{ patientId: string, canEdit: boolean, isPsychiatrist: boolean, sourcePatientId: string }> = ({ patientId, canEdit, isPsychiatrist, sourcePatientId }) => {
    const dispatch = useDispatch()
    const { progressNotes, notesStatus } = useSelector((state: StateParams) => state.patients)
    const { register, control, handleSubmit, watch, formState: { errors }, getValues, setValue, reset } = useForm<Inputs>()
    const [mentalBehavioral, setMentalBehavioral] = useState<any>()
    const [socialDeterminants, setSocialDeterminants] = useState<any>()
    const [fromCurrentPhysician, setFromCurrentPhysician] = useState<any>()
    const [fromPriorPhysician, setFromPriorPhysician] = useState<any>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [treatmentPlanId, setTreatmentPlanId] = useState<unknown>()
    const [searchParams, setSearchParams] = useSearchParams();
    const [notesId, setNotesId] = useState<string | null>(searchParams.get('notesId'))

    const saveNotes = useCallback(
        _.debounce((key, answer) => dispatch(saveProgressNotes(patientId, { progressNotes: { [key]: answer } }, notesId)), 500),
    [])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name && type === 'change') {
                const key = name.includes('weeklyGuidance') ? 'weeklyGuidance' :
                    name.includes('lastWeekGuidance') ? 'lastWeekGuidance' : name as keyof typeof value
                const answer = value[key]
                saveNotes(key, answer)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    useEffect(() => {
        if (progressNotes?.progressNotes) {
            const notes = progressNotes.progressNotes
            const mentalBehavior = notes?.mentalBehavioral as string[]
            const socialDeterminants = notes?.socialDeterminants as string[]
            const current = notes?.fromCurrentPhysician as string[]
            const prior = notes?.patientMedicationsHistory as string[]

            if (mentalBehavior && Array.isArray(mentalBehavior)) {
                setMentalBehavioral(mentalBehavior.map(saved => {
                    return icdCodes.fSeries.find(x => x.value === saved)
                }))
            }
            if (socialDeterminants && Array.isArray(socialDeterminants)) {
                setSocialDeterminants(socialDeterminants.map((saved: any) => {
                    return icdCodes.fSeries.find(x => x.value === saved)
                }))
            }
            if (current && Array.isArray(current)) {
                setFromCurrentPhysician(current.map((saved: any) => {
                    return medications.find(x => x.value === saved)
                }))
            }
            if (prior && Array.isArray(prior)) {
                setFromPriorPhysician(prior.map((saved: any) => {
                    return medications.find(x => x.value === saved)
                }))
            }
            setIsLoading(false)
            reset(notes)
            setTreatmentPlanId(progressNotes.progressNotes.previousTreatmentPlanId as unknown)
        }
    }, [progressNotes])

    return (
        <>
            <section style={{ backgroundColor: '#FFFFFF' }} className="w-full divide-y progress-notes-element mt-4 p-5  min-h-[25vh] rounded-lg shadow-lg">
                <fieldset disabled={!canEdit}>
                    <div className="mt-3">
                        <label className="block text-lg mt-6 text-sjOrange uppercase">
                            Mental, Behavioral and Neurodevelopmental disorders F01-F99
                        </label>
                        {!isLoading && <Controller
                            control={control}
                            name='mentalBehavioral'
                            render={({ field }) => (
                                <Select
                                    className="mt-3 outline-none rounded-md"
                                    options={icdCodes.fSeries}
                                    value={icdCodes.fSeries.find(x => x.value === field.value)}
                                    onChange={x => field.onChange(x.map(y => y?.value))}
                                    defaultValue={mentalBehavioral}
                                    isMulti={true}
                                    isDisabled={true}
                                />
                            )}
                        />}
                    </div>
                    <div className="mt-3">
                        <label className="block text-lg mt-6 text-sjOrange uppercase">
                            Social Determinants of Health
                        </label>
                        {!isLoading && <Controller
                            control={control}
                            name='socialDeterminants'
                            render={({ field }) => (
                                <Select
                                    className="mt-3 outline-none rounded-md"
                                    options={icdCodes.fSeries}
                                    value={icdCodes.fSeries.find(x => x.value === field.value)}
                                    onChange={x => field.onChange(x.map(y => y?.value))}
                                    defaultValue={socialDeterminants}
                                    isMulti={true}
                                    isDisabled={true}
                                />
                            )}
                        />}
                    </div>
                    <div className="mt-3">
                        <label className="block text-lg mt-6 text-sjOrange uppercase">
                            Provide additional notes for the changes in diagnosis
                        </label>
                        <Textarea {...register("changeInDiagnosis")} className="mt-3 outline-none rounded-md" />
                    </div>

                    <MentalExam {...{ register, watch }} />

                    <div className="mt-3">
                        <label className="text-lg uppercase">Safety Assessment:</label>
                        <label className="block text-lg mt-3 text-sjOrange uppercase">
                            Self-Injurious Behaviors:
                        </label>
                        <div className="flex justify-start">
                            <Radio {...register("selfInjuriousBehaviorRadio")} className="text-sjOrange" label="Yes" value="Yes" />
                            <Radio {...register("selfInjuriousBehaviorRadio")} className="text-sjOrange" label="No" value="No" />
                        </div>
                        <div className="mt-3">
                            <Textarea {...register("selfInjuriousBehaviorText")} className="outline-none rounded-md" />
                        </div>
                    </div>
                    <div className="mt-3">
                        <label className="block text-lg mt-3 text-sjOrange uppercase">
                            Violence Towards Others:
                        </label>
                        <div className="flex justify-start">
                            <Radio {...register("violenceTowardsOthersRadio")} className="text-sjOrange" label="Yes" value="Yes" />
                            <Radio {...register("violenceTowardsOthersRadio")} className="text-sjOrange" label="No" value="No" />
                        </div>
                        <div className="mt-3">
                            <Textarea {...register("violenceTowardsOthersText")} className="outline-none rounded-md" />
                        </div>
                    </div>
                    <div className="mt-3">
                        <label className="text-lg uppercase">MEDICATIONS:</label>
                        <div className="flex grid-cols-2">
                            <Radio {...register("medicationsRadio")} className="text-sjOrange" label="None" value="none" disabled />
                            <Radio {...register("medicationsRadio")} className="text-sjOrange" label="Current" value="current" disabled />
                            <Radio {...register("medicationsRadio")} className="text-sjOrange" label="Prior" value="prior" disabled />
                        </div>
                        <div className="mt-3">
                            <Textarea {...register("medicationsText")} disabled className="outline-none rounded-md" />
                        </div>
                        <div className="mt-3">
                            <label className="block font-semibold">From Physician (current):</label>
                            {!isLoading && <Controller
                                control={control}
                                name='fromCurrentPhysician'
                                render={({ field }) => (
                                    <Select
                                        className="mt-3 outline-none rounded-md"
                                        options={medications}
                                        value={medications.find(x => x.value === field.value)}
                                        onChange={x => field.onChange(x.map(y => y?.value))}
                                        defaultValue={fromCurrentPhysician}
                                        isMulti={true}
                                        isDisabled={!canEdit}
                                    />
                                )}
                            />}
                        </div>
                        <div className="mt-3">
                            <label className="block font-semibold">From Physician (prior):</label>
                            {!isLoading && <Controller
                                control={control}
                                name='patientMedicationsHistory'
                                render={({ field }) => (
                                    <Select
                                        className="mt-3 outline-none rounded-md"
                                        options={medications}
                                        value={medications.find(x => x.value === field.value)}
                                        onChange={x => field.onChange(x.map(y => y?.value))}
                                        defaultValue={fromPriorPhysician}
                                        isMulti={true}
                                        isDisabled={!canEdit}
                                    />
                                )}
                            />}
                        </div>
                    </div>
                    <div className="mt-3">
                        <label className="block text-lg mt-3 uppercase">Session notes</label>
                        <Textarea {...register("sessionNotes")} className="mt-3 outline-none rounded-md" />
                    </div>
                    <div className="mt-3">
                        <label className="text-lg uppercase">WEEKLY GUIDANCE:</label>
                        <WeeklyGuidance {...{ control, register, getValues, setValue, watch }} />
                    </div>
                </fieldset>
                <fieldset disabled={!canEdit}>
                    <div className="grid grid-rows-3 grid-flow-col gap-6">
                        <div className="row-span-3">
                            <label className="block text-lg mt-6 uppercase">Clinical session time:</label>
                            <select {...register("clinicalSessionType")} defaultValue="30" className="mt-3 outline-none rounded-md" >
                                <option value="30">30 minutes</option>
                            </select>
                        </div>
                        <div id="documentationType" className="row-span-3">
                            <label className="block text-lg mt-6 uppercase">documentation time:</label>
                            <select {...register("documentationTime")} defaultValue="20" className="mt-3 outline-none rounded-md" >
                                <option value="20">20 minutes</option>
                            </select>
                        </div>
                    </div>
                </fieldset>
            </section>
            {/* <section style={{ backgroundColor: '#FFFFFF' }}
                className={`w-full intake-section mt-6 p-10  min-h-[25vh] rounded-lg shadow-lg`}> */}
                <ProgressNotesScreeners sourcePatientId={sourcePatientId} treatmentPlanId={treatmentPlanId as string} />
            {/* </section> */}
        </>
    )
}

export default ProgressNotes