import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import {useDispatch, useSelector} from "react-redux";
import {DateTime} from "luxon";
import {fetchProspect} from "../../store/actions/counsellor/prospects.action";
import {StateParams} from "../../store/reducers";
import {createAppointment} from "../../store/actions/counsellor/appointments.action";
import {ICreateAppointment} from "../../models";
import {useNavigate} from "react-router";

type AppointmentFormData = {
  selectedDate: Date;
};

const ProspectSchedule: React.FC = () => {
  const dispatch = useDispatch()
  const { control, register, handleSubmit, formState: { errors } } = useForm<AppointmentFormData>();
  const appointmentCreated = useSelector((state: StateParams) => state.appointments.createdAppointment)
  const [appointmentRequest, setAppointmentRequest] = useState<ICreateAppointment>()
  const router = useNavigate()
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const params = useParams()
  const { prospect } = useSelector((state: StateParams) => state.prospects)

  useEffect(() => {
    if (params.prospectId) {
      dispatch(fetchProspect(String(params.prospectId)))
    }
  }, [params.prospectId])

  const onSubmit = handleSubmit((data: AppointmentFormData) => {
    if(!prospect) {
      return
    }
    const scheduledDate = DateTime.fromJSDate(data.selectedDate).toUTC().toISO();
    if(scheduledDate && prospect.sourcePatientId) {
      const appointmentRequest : ICreateAppointment = {
        ...data,
        patientId: prospect.id,
        sessionType: "Intake",
        scheduledDate: scheduledDate
      }
      setAppointmentRequest(appointmentRequest)
      dispatch(createAppointment(appointmentRequest, prospect.sourcePatientId))
    }
  });

  useEffect(() => {
    if(appointmentRequest && appointmentCreated && appointmentCreated?.patientId === appointmentRequest?.patientId) {
      router("/prospects?page=1")
    }
  }, [appointmentRequest, appointmentCreated])

  function renderTopButtonPanel() {
    return <div>
      <Link to="/prospects">
        <span className="rounded-l-md border-sjOrange text-sjLink">
            Back to Patients
        </span>
      </Link>
    </div>;
  }

  const filterFutureTime = (time: Date) => {
    const selectedDate = new Date(time);
    const currentDate = new Date();
    return currentDate.getTime() < selectedDate.getTime();
  };

  function renderFormForAppointment() {
    return (
      <div className={"mt-10"}>
        <form onSubmit={onSubmit}>
          <div className="mb-8">
            <label
              htmlFor="sessionType"
              className={`block text-md mb-2 text-sjOrange`} >
              Session Type
            </label>
            <label>Intake</label>
          </div>
          <div className="mb-8">
            <label className={"block text-md mb-2 text-sjOrange"}>Patient Name and DOB</label>
            { prospect &&
                <label className="mb-8">
                  {prospect?.firstName} {prospect.lastName} ({prospect?.dob ? DateTime.fromISO(prospect?.dob?.toString(), {zone: 'utc'}).toFormat('MM/dd/yyyy') : ''})
                </label>
            }
          </div>
          <div className="mb-8 grid grid-cols-2">
            <label
              htmlFor="sessionDate"
              className={`block text-md mb-2 self-center ${
                errors.selectedDate ? "text-red-400" : "text-sjOrange"
              }`}
            >
              {`Date (${timeZone})`}
            </label>
            <Controller
              {...register("selectedDate", { required: true })}
              control={control}
              name='selectedDate'
              render={({ field }) => (
                <DatePicker
                  className={`block w-full bg-transparent outline-none border-b-2 py-2 px-4  placeholder-gray-500 ${
                    errors.selectedDate
                      ? "text-red-300 border-red-400"
                      : "text-black border-sjOrange"
                  }`}
                  dateFormat="d MMM yyyy h:mm aa"
                  minDate={new Date()}
                  showTimeSelect={true}
                  filterTime={filterFutureTime}
                  todayButton="Today"
                  dropdownMode="select"
                  isClearable
                  shouldCloseOnSelect
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                  timeIntervals={10}
                />
              )}
            />
            {errors.selectedDate && (
              <p className="text-red-500 text-sm mt-2">
                A valid Session Date is required.
              </p>
            )}
          </div>

          <input type={"submit"} value={"Submit"}
                 className="inline-block bg-orange-500 text-white rounded shadow py-2 px-5 text-sm"/>
        </form>
      </div>
    )
  }

  return (<div className="py-10 px-10 bg-white-700 min-h-screen">
    {renderTopButtonPanel()}
    {renderFormForAppointment()}
  </div>)
}

export default ProspectSchedule