import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import {CheckCircleIcon} from '@heroicons/react/20/solid'

interface SavedStatusLabelProps {
    labelKey: string
    labelText: string
    notesKey: "intakeNotesSavedKeys" | "treatmentNotesSavedKeys" | "safetyPlanNotesSavedKeys" | "catchupNotesSavedKeys" | "progressNotesSavedKeys" | "psychiatristNotesSavedKeys"
    classes: string
    reducer: "prospects" | "patients"
}

export const SavedStatusLabel: React.FC<SavedStatusLabelProps> = ({ classes, labelKey, labelText, reducer, notesKey }) => {
    // @ts-ignore
    const savedState = useSelector((state: StateParams) => state[reducer][notesKey].includes(labelKey))
    const [isSaved, setSaved] = useState<boolean>()

    useEffect(() => {
        console.log(`${labelKey} -> ${reducer} -> ${notesKey} -> ${savedState}`)
        if(savedState) {
            setSaved(true)
        }
    }, [savedState])

    return (
        <label style={{display: "flex", flexDirection: "row"}} className={classes}>
            <text>{labelText}</text>
            {/*{isSaved ? <CheckCircleIcon className="h-5 w-5 m-1" aria-hidden="true"/> : null}*/}
        </label>
    );
}