import React from "react";
import {flexRender, getCoreRowModel, getSortedRowModel, useReactTable,} from '@tanstack/react-table'

interface TableProps {
    columns: any
    data: any
    searchInProgress?: boolean | undefined
    size?: "small" | "medium"
    horizontalLines?: boolean
    possibleColumnsToHide?: { [key: string]: boolean}
}

const TablePaginationV2: React.FC<TableProps> = ({
                                                     columns,
                                                     data,
                                                     searchInProgress,
                                                     size = "medium",
                                                     horizontalLines = true,
                                                    possibleColumnsToHide
                                                 }) => {
    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            columnVisibility: {...possibleColumnsToHide}
        }
    });
    // Use the state and functions returned from useTable to build your UI

    return (
        <>
            <div className="mt-2 flex flex-col">
                <div className="align-middle inline-block min-w-full">
                    <div className="border-b overflow-x-auto border-gray-200 sm:rounded-lg">
                        <table className={`table-auto overflow-scroll w-full divide-y divide-gray-200 table-${size} table-${horizontalLines ? 'horizontal-lines' : 'no-horizontal-lines'}`}>
                            <thead className="bg-gray-200 font-bold">
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => (
                                        <th
                                            className="p-2 text-left text-md font-bold text-[#5D7285]"
                                            key={header.id}
                                        >
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                            {table.getRowModel().rows.map(row => (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map(cell => (
                                        <td key={cell.id} className="p-2 text-left leading-5 text-sm text-[#5D7285] ellipsis-text">
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {
                    table.getRowModel().rows.length === 0 && searchInProgress === false
                        ? <p className={"self-center py-4"}>No Results</p>
                        : null
                }

            </div>
        </>
    );
}

export default TablePaginationV2;
