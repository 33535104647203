import {AnyAction} from "redux";
import {
    IClinicalNotesComplianceStats,
    IScreenerComplianceStats, IScreenerPendingPatient
} from "../../../models";

import {
    FETCH_SCREENER_COMPLIANCE_STATS_FAILED,
    FETCH_SCREENER_COMPLIANCE_STATS_IN_PROGRESS, FETCH_SCREENER_COMPLIANCE_STATS_SUCCESS
} from "../../actions/counsellor";
import {
    FETCH_CLINICAL_NOTES_COMPLIANCE_STATS,
    FETCH_CLINICAL_NOTES_COMPLIANCE_STATS_FAILED,
    FETCH_CLINICAL_NOTES_COMPLIANCE_STATS_IN_PROGRESS,
    FETCH_CLINICAL_NOTES_COMPLIANCE_STATS_SUCCESS,
    FETCH_PENDING_CLINICAL_NOTES, FETCH_PENDING_CLINICAL_NOTES_FAILED,
    FETCH_PENDING_CLINICAL_NOTES_IN_PROGRESS, FETCH_PENDING_CLINICAL_NOTES_SUCCESS,
    FETCH_SCREENER_PENDING_PATIENTS,
    FETCH_SCREENER_PENDING_PATIENTS_FAILED,
    FETCH_SCREENER_PENDING_PATIENTS_IN_PROGRESS,
    FETCH_SCREENER_PENDING_PATIENTS_SUCCESS
} from "../../actions/counsellor/non-physician-compliance.action";

export interface NonPhysicianComplianceParams {
    screenerComplianceStats: IScreenerComplianceStats | undefined;
    isScreenerComplianceStatsSuccess?: boolean;
    isScreenerComplianceStatsInProgress?: boolean;
    isScreenerComplianceStatsFailed?: boolean;
    screenerComplianceStatsError?: string;
    screenerPendingPatientList?: IScreenerPendingPatient[];
    screenerPendingPatientsCount?: number;
    isScreenerPendingPatientsInProgress?: boolean;
    isScreenerPendingPatientsSuccess?: boolean;
    isScreenerPendingPatientsFailed?: boolean;
    screenerPendingPatientsError?: string;
    clinicalNotesComplianceStats?: IClinicalNotesComplianceStats | undefined;
    isClinicalNotesComplianceStatsSuccess?: boolean;
    isClinicalNotesComplianceStatsInProgress?: boolean;
    isClinicalNotesComplianceStatsFailed?: boolean;
    clinicalNotesComplianceStatsError?: string;
    pendingClinicalNoteList?: any; // TODO - Define the type
    pendingClinicalNoteCount?: number;
    isPendingClinicalNotesFetchInProgress?: boolean;
    isPendingClinicalNotesFetchSuccess?: boolean;
    isPendingClinicalNotesFetchFailed?: boolean;
    pendingClinicalNotesFetchError?: string;
}

const initialState: NonPhysicianComplianceParams = {
    screenerComplianceStats: undefined,
    isScreenerComplianceStatsSuccess: undefined,
    isScreenerComplianceStatsInProgress: undefined,
    isScreenerComplianceStatsFailed: undefined,
    screenerComplianceStatsError: undefined,
    screenerPendingPatientList: undefined,
    screenerPendingPatientsCount: undefined,
    isScreenerPendingPatientsInProgress: undefined,
    isScreenerPendingPatientsSuccess: undefined,
    isScreenerPendingPatientsFailed: undefined,
    screenerPendingPatientsError: undefined,
    clinicalNotesComplianceStats: undefined,
    isClinicalNotesComplianceStatsSuccess: undefined,
    isClinicalNotesComplianceStatsInProgress: undefined,
    isClinicalNotesComplianceStatsFailed: undefined,
    clinicalNotesComplianceStatsError: undefined,
    pendingClinicalNoteList: undefined,
    pendingClinicalNoteCount: undefined,
    isPendingClinicalNotesFetchInProgress: undefined,
    isPendingClinicalNotesFetchSuccess: undefined,
    isPendingClinicalNotesFetchFailed: undefined,
    pendingClinicalNotesFetchError: undefined
}

const NonPhysicianComplianceReducer = (state: NonPhysicianComplianceParams = initialState, action: AnyAction): NonPhysicianComplianceParams => {
    switch (action.type) {
        case FETCH_SCREENER_COMPLIANCE_STATS_IN_PROGRESS:
            state = {
                ...state,
                screenerComplianceStatsError: undefined,
                isScreenerComplianceStatsInProgress: true,
                isScreenerComplianceStatsSuccess: false,
                isScreenerComplianceStatsFailed: false
            }
            return state;
        case FETCH_SCREENER_COMPLIANCE_STATS_SUCCESS:
            state = {
                ...state,
                screenerComplianceStatsError: undefined,
                isScreenerComplianceStatsInProgress: false,
                isScreenerComplianceStatsSuccess: true,
                isScreenerComplianceStatsFailed: false,
                screenerComplianceStats: action.payload.stats
            }
            return state;
        case FETCH_SCREENER_COMPLIANCE_STATS_FAILED:
            state = {
                ...state,
                isScreenerComplianceStatsInProgress: false,
                isScreenerComplianceStatsSuccess: false,
                isScreenerComplianceStatsFailed: true,
                screenerComplianceStatsError: action.payload.error
            }
            return state;
        case FETCH_SCREENER_PENDING_PATIENTS:
            state = {
                ...state,
                screenerPendingPatientList: undefined,
                screenerPendingPatientsCount: undefined,
                screenerPendingPatientsError: undefined,
                isScreenerPendingPatientsInProgress: true,
                isScreenerPendingPatientsSuccess: false,
                isScreenerPendingPatientsFailed: false
            }
            return state;
        case FETCH_SCREENER_PENDING_PATIENTS_IN_PROGRESS:
            state = {
                ...state,
                screenerPendingPatientList: undefined,
                screenerPendingPatientsCount: undefined,
                screenerPendingPatientsError: undefined,
                isScreenerPendingPatientsInProgress: true,
                isScreenerPendingPatientsSuccess: false,
                isScreenerPendingPatientsFailed: false
            }
            return state;
        case FETCH_SCREENER_PENDING_PATIENTS_SUCCESS:
            state = {
                ...state,
                screenerPendingPatientsError: undefined,
                isScreenerPendingPatientsInProgress: false,
                isScreenerPendingPatientsSuccess: true,
                isScreenerPendingPatientsFailed: false,
                screenerPendingPatientList: action.payload.patients,
                screenerPendingPatientsCount: action.payload.patientsCount
            }
            return state;
        case FETCH_SCREENER_PENDING_PATIENTS_FAILED:
            state = {
                ...state,
                isScreenerPendingPatientsInProgress: false,
                isScreenerPendingPatientsSuccess: false,
                isScreenerPendingPatientsFailed: true,
                screenerPendingPatientList: undefined,
                screenerPendingPatientsCount: undefined,
                screenerPendingPatientsError: action.payload.error
            }
            return state;
        case FETCH_CLINICAL_NOTES_COMPLIANCE_STATS:
            state = {
                ...state,
                clinicalNotesComplianceStats: undefined,
                isClinicalNotesComplianceStatsSuccess: undefined,
                isClinicalNotesComplianceStatsInProgress: true,
                isClinicalNotesComplianceStatsFailed: false,
                clinicalNotesComplianceStatsError: undefined
            }
            return state;
        case FETCH_CLINICAL_NOTES_COMPLIANCE_STATS_FAILED:
            state = {
                ...state,
                clinicalNotesComplianceStats: undefined,
                isClinicalNotesComplianceStatsSuccess: false,
                isClinicalNotesComplianceStatsInProgress: false,
                isClinicalNotesComplianceStatsFailed: true,
                clinicalNotesComplianceStatsError: action.payload.error
            }
            return state;
        case FETCH_CLINICAL_NOTES_COMPLIANCE_STATS_IN_PROGRESS:
            state = {
                ...state,
                clinicalNotesComplianceStats: undefined,
                isClinicalNotesComplianceStatsSuccess: undefined,
                isClinicalNotesComplianceStatsInProgress: true,
                isClinicalNotesComplianceStatsFailed: false,
                clinicalNotesComplianceStatsError: undefined
            }
            return state;
        case FETCH_CLINICAL_NOTES_COMPLIANCE_STATS_SUCCESS:
            state = {
                ...state,
                clinicalNotesComplianceStats: action.payload.stats,
                isClinicalNotesComplianceStatsSuccess: true,
                isClinicalNotesComplianceStatsInProgress: false,
                isClinicalNotesComplianceStatsFailed: false,
                clinicalNotesComplianceStatsError: undefined
            }
            return state;
        case FETCH_PENDING_CLINICAL_NOTES:
            state = {
                ...state,
                pendingClinicalNoteList: undefined,
                pendingClinicalNoteCount: undefined,
                pendingClinicalNotesFetchError: undefined,
                isPendingClinicalNotesFetchInProgress: true,
                isPendingClinicalNotesFetchSuccess: false,
                isPendingClinicalNotesFetchFailed: false
            }
            return state;
        case FETCH_PENDING_CLINICAL_NOTES_IN_PROGRESS:
            state = {
                ...state,
                pendingClinicalNoteList: undefined,
                pendingClinicalNoteCount: undefined,
                pendingClinicalNotesFetchError: undefined,
                isPendingClinicalNotesFetchInProgress: true,
                isPendingClinicalNotesFetchSuccess: false,
                isPendingClinicalNotesFetchFailed: false
            }
            return state;
        case FETCH_PENDING_CLINICAL_NOTES_SUCCESS:
            state = {
                ...state,
                pendingClinicalNoteList: action.payload.notes,
                pendingClinicalNoteCount: action.payload.notesCount,
                pendingClinicalNotesFetchError: undefined,
                isPendingClinicalNotesFetchInProgress: false,
                isPendingClinicalNotesFetchSuccess: true,
                isPendingClinicalNotesFetchFailed: false
            }
            return state;
        case FETCH_PENDING_CLINICAL_NOTES_FAILED:
            state = {
                ...state,
                pendingClinicalNoteList: undefined,
                pendingClinicalNoteCount: undefined,
                pendingClinicalNotesFetchError: action.payload.error,
                isPendingClinicalNotesFetchInProgress: false,
                isPendingClinicalNotesFetchSuccess: false,
                isPendingClinicalNotesFetchFailed: true
            }
            return state;
        default:
            return state;
    }
}

export default NonPhysicianComplianceReducer
