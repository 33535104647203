import {FETCHED_PRACTICE_DETAILS, FETCHING_PRACTICE_DETAILS_FAILED} from "../../actions/counsellor";

export interface PracticeParams {
    id: string | undefined
    isPhoneVerified: boolean | null | undefined
    fetchError: string | undefined
}

const initialState = {
    id: undefined,
    isPhoneVerified: undefined,
    fetchError: undefined
}

const PracticeReducer = (state: PracticeParams = initialState, action: any) : PracticeParams => {
    switch (action.type) {
        case FETCHED_PRACTICE_DETAILS:
            state = {...state, ...action.payload.practice}
            return state
        case FETCHING_PRACTICE_DETAILS_FAILED:
            state = {...state, ...{
                    fetchError: action.payload.error
                }}
            return state
        default:
            return state;
    }
}

export default PracticeReducer