import React, {useMemo} from "react";
import "../PhysicianDashboardScreen.scss";
import TablePaginationV2 from "../../../../components/generic/TablePaginationV2";
import { ScreenerCompletionRate } from "../../../../models";
import { TableSkeletonLoader } from "../../../../components/generic/TableSkeletonLoader";

interface PhysicianDashboardScreenerCompletionRateComponentProps {
    patientSummary: ScreenerCompletionRate[]
    searchInProgress: boolean | undefined
}

const PhysicianDashboardScreenerCompletionRateComponent : React.FC<PhysicianDashboardScreenerCompletionRateComponentProps> = ({patientSummary, searchInProgress}) => {
    const data = patientSummary.map((patient) => {
        return {
            location: patient.location,
            totalScreeners: patient.totalScreenersCount,
            pending:  patient.totalPendingScreenersCount,
            completed: patient.totalScreenersCompletedCount,
            completionRate: patient.completionRate
        }
    });
    console.log({searchInProgress})
    const columns = useMemo( () => [
        { header: "Location", accessorKey: "location" },
        { header: "Total Screeners", accessorKey: "totalScreeners" },
        { header: "Pending", accessorKey: "pending" },
        { header: "Completed", accessorKey: "completed" },
        { header: "Completion Rate", accessorKey: "completionRate", cell: (props: any)=>  {
                const completionRate = props.getValue();
                return <>
                    {completionRate} %
                </>
            }
        }
    ], [] );

    return (
        <div className="physician-dashboard-location-based-screeners physician-dashboard-list-card">
            <div className="physician-dashboard-list-card-header">
                <div className="physician-dashboard-list-card-header-title">Screener Completion Rate</div>
                <div className="physician-dashboard-list-card-header-subtitle">Screeners in different Status across various Locations</div>
            </div>
            <div className="physician-dashboard-list-card-content">
                {searchInProgress === undefined ? <TableSkeletonLoader /> :
                    <TablePaginationV2 columns={columns} data={data} size={"small"} searchInProgress={searchInProgress} />
                }
            </div>
        </div>
    )
}

export default PhysicianDashboardScreenerCompletionRateComponent;