import {DischargedPatients} from "../../../models";
import {DateTime} from "luxon";
import {ProfileViewV2} from "../../generic/ProfileView";
import { DischargedPatientsDataView } from "../DischargedPatientsDataView";

export class CounsellorDischargedPatientsDataView implements DischargedPatientsDataView {
    private dischargedPatients: DischargedPatients[];

    constructor() {
        this.dischargedPatients = []
    }

    setPatients(dischargedPatients: DischargedPatients[]) {
        this.dischargedPatients = dischargedPatients
    }

    getColumns(): any {
        return () => [
            { header: "Name", accessorKey: "name" },
            { header: "Date of birth", accessorKey: "dateOfBirth" },
            {header: "Referring Physician", accessorKey: "referringPhysician"},
            { header: "Practice", accessorKey: "practice" },
            { header: "Enrolled On", accessorKey: "enrolledOnDate" },
            { header: "Discharge Date", accessorKey: "dischargedDate" },
            { header: "Action", accessorKey: "viewDetails", cell: ProfileViewV2 }
        ]
    }

    getData(): any {
        return this.dischargedPatients?.map((dischargedPatient) => {
            const dob = dischargedPatient.dob ? DateTime.fromISO(dischargedPatient.dob.toString(), {zone: 'utc'}).toFormat('MM-dd-yyyy') : null
            const enrolledOnDate = dischargedPatient.referredDate ? DateTime.fromISO(dischargedPatient.referredDate.toString(), {zone: 'utc'}).toFormat('MM-dd-yyyy') : null
            const dischargedDate = dischargedPatient.dischargedDate ? DateTime.fromISO(dischargedPatient.dischargedDate.toString(), {zone: 'utc'}).toFormat('MM-dd-yyyy') : null
            const referringPhysician = dischargedPatient.referringPhysician ? `Dr. ${dischargedPatient.referringPhysician}` : 'N/A'

            return {
                id: dischargedPatient.id,
                name: `${dischargedPatient.lastName}, ${dischargedPatient.firstName}`,
                dateOfBirth: dob,
                enrolledOnDate,
                dischargedDate,
                practice: `${dischargedPatient.practiceName}`,
                referringPhysician: referringPhysician,
                viewDetails: `${dischargedPatient.id}/journey`
            }
        })
    }

}
