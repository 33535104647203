import React from "react";
import {Popover, PopoverContent, PopoverHandler,} from "@material-tailwind/react";
import {BsThreeDotsVertical} from "react-icons/bs";
import {Tooltip} from "react-tooltip";
import {useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";

interface IUserMasterActionProps {
    value: {
        userId: string;
        role: string;
        isActive: boolean;
    },
    onToggle: (userId: string) => void;
    onEdit: (userId: string) => void;
}

export const UserMasterAction = (props: IUserMasterActionProps) => {

    const {
        providerId
    } = useSelector((state: StateParams) => state.physicianAccount);
    const {value, onToggle, onEdit} = props;
    const {userId, role, isActive} = value;
    const canToggle = role !== 'lead' && userId !== providerId;
    const canEdit = isActive;
    const toggleOptionClasses = `w-full text-sm text-gray-700 ${canToggle ? 'hover:text-sjOrange cursor-pointer' : 'opacity-50'}`;
    const toggleDisableReason = !canToggle ? (userId === providerId ? "Self account can't be deactivated" : "Admin can't be deactivated") : "";
    const editOptionClasses = `w-full text-sm text-gray-700 ${canEdit ? 'hover:text-sjOrange cursor-pointer' : 'opacity-50'}`;

    return (
        <Popover placement="bottom-end">
            <PopoverHandler>
                <button><BsThreeDotsVertical className='w-5 h-5 cursor-pointer'/></button>
            </PopoverHandler>
            <PopoverContent className="w-30">
                <ul className="flex flex-col items-start gap-3">
                    <Tooltip place={"top"}
                             id={"edit-disable-tooltip"}
                             hidden={isActive}
                    >
                        Inactive Account can't be edited, Kindly activate it before editing.
                    </Tooltip>
                    <li
                        onClick={() => {
                            if (isActive){
                                onEdit(userId);
                            }
                        }}
                        data-tooltip-id={"edit-disable-tooltip"}
                        className={editOptionClasses}
                    >
                        Edit
                    </li>
                    <Tooltip place={"top"}
                             id={"lead-toggle-tooltip"}
                             hidden={canToggle}
                    >
                        {toggleDisableReason}
                    </Tooltip>
                    <li
                        onClick={() => {
                            if (canToggle) {
                                onToggle(userId);
                            }
                        }}
                        data-tooltip-id={"lead-toggle-tooltip"}
                        className={toggleOptionClasses}
                    >
                        {isActive ? 'Deactivate' : 'Activate'}
                    </li>
                </ul>
            </PopoverContent>
        </Popover>
    );
}
