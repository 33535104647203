import {AnyAction} from "redux";
import {
    IAceScreener, IAppointmentSession,
    IIntakeFormData,
    ILockIntakeResponse,
    IPatientIndexItem,
    IProspect,
    IProspectScreenerResponse,
    IReferenceData,
    IReferredPatientsDetails,
    ISession,
    ITherapist,
    ITherapistPatient,
    Practice
} from "../../../models";
import {
    ACCEPT_ACE_SCREENER_FAILED,
    ACCEPT_ACE_SCREENER_SUCCESS,
    ACCEPT_PROSPECT_REF_DATA_FAILED,
    ACCEPT_PROSPECT_REFERENCE_DATA_SUCCESS,
    CLEAR_PATIENT_SEARCH_RESULTS,
    COMPLETE_INTAKE_SESSION,
    COMPLETE_INTAKE_SESSION_FAILED,
    COMPLETE_INTAKE_SESSION_IN_PROGRESS,
    COMPLETED_INTAKE_SESSION,
    FETCH_ACE_SCREENER,
    FETCH_ACE_SCREENER_IN_PROGRESS,
    FETCH_PRACTICES,
    FETCH_PRACTICES_FAILED,
    FETCH_PRACTICES_IN_PROGRESS,
    FETCH_PRACTICES_SUCCESS,
    FETCH_PROSPECT,
    FETCH_PROSPECT_FAILED,
    FETCH_PROSPECT_IN_PROGRESS,
    FETCH_PROSPECT_REFERENCE_DATA,
    FETCH_PROSPECT_REFERENCE_DATA_IN_PROGRESS,
    FETCH_PROSPECT_SCREENER_RESPONSE,
    FETCH_PROSPECT_SCREENER_RESPONSE_FAILED,
    FETCH_PROSPECT_SCREENER_RESPONSE_IN_PROGRESS,
    FETCH_PROSPECT_SCREENER_RESPONSE_SUCCESS,
    FETCH_PROSPECT_SESSION_BY_SRC_PATIENT,
    FETCH_PROSPECT_SESSION_BY_SRC_PATIENT_FAILED,
    FETCH_PROSPECT_SESSION_BY_SRC_PATIENT_SUCCESS,
    FETCH_PROSPECT_SUCCESS,
    FETCH_PROSPECTS,
    FETCH_PROSPECTS_FAILED,
    FETCH_PROSPECTS_IN_PROGRESS,
    FETCH_PROSPECTS_SUCCESS,
    INTAKE_FORM_SAVE,
    INTAKE_FORM_SAVE_FAILED,
    INTAKE_FORM_SAVE_IN_PROGRESS,
    INTAKE_FORM_SAVE_SUCCESS,
    LOCK_INTAKE_FORM,
    LOCK_INTAKE_FORM_FAILED,
    LOCK_INTAKE_FORM_SUCCESS,
    RESET_PROSPECT,
    SEARCH_PATIENT_FAILED,
    SEARCH_PATIENT_IN_PROGRESS,
    SEARCH_PATIENT_SUCCESS,
    UPDATE_NOTE_STATUS,
    UPDATE_NOTE_STATUS_FAILED,
    UPDATE_NOTE_STATUS_IN_PROGRESS,
    UPDATE_NOTE_STATUS_SUCCESS,
    SET_INTAKE_FORM_ERRORS,
    CLEAR_INTAKE_FORM_ERRORS,
    CLEAR_NOTES_ERROR,
    UPDATE_NOTE_STATUS_INTAKE,
    FETCH_PROSPECT_WITH_SUMMARY,
    FETCH_PROSPECT_WITH_SUMMARY_FAILED,
    FETCH_PROSPECT_WITH_SUMMARY_IN_PROGRESS,
    FETCH_PROSPECT_WITH_SUMMARY_SUCCESS,
    FETCH_PSYCHIATRIST_SOCIAL_WORKERS,
    FETCH_PSYCHIATRIST_SOCIAL_WORKERS_FAILED,
    FETCH_PSYCHIATRIST_SOCIAL_WORKERS_IN_PROGRESS,
    FETCH_PSYCHIATRIST_SOCIAL_WORKERS_SUCCESS,
    FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID,
    FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID_FAILED,
    FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID_SUCCESS
} from "../../actions/counsellor/prospects.action";
import {SIGN_OUT_ACCOUNT} from "../../actions/common";
import { PATCH_PATIENT_INFO, UPDATE_PATIENT_INFO_SUCCESS } from "../../actions/counsellor/patients.action";

export interface ProspectsParams {
  prospects: IReferredPatientsDetails[]
  prospect?: IProspect
  therapists?:ITherapist[]
  prospectsCount?: number
  tabCount: Record<string, number>
  completedIntakeProspect?: boolean
  screenerResponses?: { [key: string]: IProspectScreenerResponse[] }
  fetchProspectsInProgress?: boolean
  fetchProspectInProgress?: boolean
  completeIntakeSessionFailed?: boolean
  completeIntakeSessionInProgress?: boolean
  searchPatientInProgress?: boolean
  fetchProspectScreenerResponseInProgress?: boolean
  patientSearchResults: IPatientIndexItem[]
  sourceRefData?: IReferenceData
  aceScreener?: IAceScreener
  intakeForm?: IIntakeFormData
  session?: IAppointmentSession
  lockIntake?: ILockIntakeResponse
  lockIntakeInProgress?: boolean
  practices?: Practice[]
  fetchPracticesInProgress?: boolean
  isNotesStatusUpdated?: boolean
  updatingNotesStatusInProgress?: boolean 
  statusUpdateError?: string
  intakeFormErrors?: any
  intakeNotesSavedKeys: string[],
  isContactNumberSavedSuccessfully?: boolean
  isPsychiatristSocialWorkerListLoading?: boolean
}

const initialState: ProspectsParams = {
  prospects: [],
  therapists:[],
  prospect: undefined,
  prospectsCount: undefined,
  tabCount: {},
  completedIntakeProspect: undefined,
  completeIntakeSessionFailed: false,
  screenerResponses: undefined,
  fetchProspectsInProgress: undefined,
  fetchProspectInProgress: undefined,
  searchPatientInProgress: undefined,
  completeIntakeSessionInProgress: undefined,
  fetchProspectScreenerResponseInProgress: undefined,
  patientSearchResults: [],
  sourceRefData: undefined,
  aceScreener: undefined,
  intakeForm: undefined,
  session: undefined,
  lockIntake: undefined,
  lockIntakeInProgress: undefined,
  practices: [],
  fetchPracticesInProgress: undefined,
  isNotesStatusUpdated: undefined,
  updatingNotesStatusInProgress: undefined,
  statusUpdateError: undefined,
  intakeFormErrors: undefined,
  intakeNotesSavedKeys: [],
  isContactNumberSavedSuccessfully: undefined,
  isPsychiatristSocialWorkerListLoading: false,
}

const defaultProspectState = {
  prospect: undefined,
  completedIntakeProspect: undefined,
  acceptedProspect: undefined,
  acceptProspectFailed: false,
  screenerResponses: undefined,
  fetchProspectsInProgress: undefined,
  fetchProspectInProgress: undefined,
  searchPatientInProgress: undefined,
  acceptProspectInProgress: undefined,
  completeIntakeSessionInProgress: undefined,
  fetchProspectScreenerResponseInProgress: undefined,
  patientSearchResults: [],
  sourceRefData: undefined,
  aceScreener: undefined,
  intakeForm: undefined,
  session: undefined,
  lockIntake: undefined,
  lockIntakeInProgress: undefined,
  intakeNotesSavedKeys: [],
  isPsychiatristSocialWorkerListLoading: undefined,
}

const ProspectsReducer = (state: ProspectsParams = initialState, action: AnyAction): ProspectsParams => {
  switch (action.type) {
    case FETCH_PROSPECTS:
      state = { ...state, prospects: [] }
      return state
    case FETCH_PROSPECTS_FAILED:
      state = { ...state, fetchProspectsInProgress: false }
      return state
    case FETCH_PROSPECTS_IN_PROGRESS:
      state = { ...state, fetchProspectsInProgress: true }
      return state
    case FETCH_PROSPECTS_SUCCESS:
      state = { ...state,
        prospects: action.payload.prospects,
        prospectsCount: action.payload.prospectsCount,
        tabCount: action.payload.tabCount,
        fetchProspectsInProgress: false,
        ...{defaultProspectState}
      }
      return state

    case RESET_PROSPECT:
      state = { ...state, ...defaultProspectState}
      return state

    case FETCH_PROSPECT:
      state = { ...state, prospect: undefined }
      return state
    case FETCH_PROSPECT_FAILED:
      state = { ...state, fetchProspectInProgress: false }
      return state
    case FETCH_PROSPECT_IN_PROGRESS:
      state = { ...state, fetchProspectInProgress: true }
      return state
    case FETCH_PROSPECT_SUCCESS:
      state = { ...state, prospect: action.payload.prospect, fetchProspectInProgress: false }
      return state

    case FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID:
      state = { ...state }
      return state
    case FETCH_PROSPECT_WITH_SUMMARY_BY_SESSION_ID_SUCCESS:
      state = { ...state, prospect: state.prospect ? { 
        ...state.prospect,
        patient: state.prospect.patient ? {
          ...state.prospect.patient,
          screeners: action.payload.prospect.patient.screeners
        } : action.payload.prospect.patient,
        summary: action.payload.prospect.summary } : action.payload.prospect }
      return state

    case FETCH_PROSPECT_WITH_SUMMARY:
      state = { ...state, prospect: undefined }
      return state
    case FETCH_PROSPECT_WITH_SUMMARY_FAILED:
      state = { ...state, fetchProspectInProgress: false }
      return state
    case FETCH_PROSPECT_WITH_SUMMARY_IN_PROGRESS:
      state = { ...state, fetchProspectInProgress: true }
      return state
    case FETCH_PROSPECT_WITH_SUMMARY_SUCCESS:
      state = { ...state, prospect: action.payload.prospect, fetchProspectInProgress: false }
      return state

    case FETCH_PROSPECT_SCREENER_RESPONSE:
      state = { ...state, screenerResponses: undefined }
      return state
    case FETCH_PROSPECT_SCREENER_RESPONSE_FAILED:
      state = { ...state, fetchProspectScreenerResponseInProgress: false }
      return state
    case FETCH_PROSPECT_SCREENER_RESPONSE_IN_PROGRESS:
      state = { ...state, fetchProspectScreenerResponseInProgress: true }
      return state
    case FETCH_PROSPECT_SCREENER_RESPONSE_SUCCESS:
      state = {
        ...state,
        screenerResponses: {
          ...state.screenerResponses,
          [action.payload.screenerId]: action.payload.screenerResponses
        },
        fetchProspectScreenerResponseInProgress: false
      }
      return state

    case COMPLETE_INTAKE_SESSION:
      state = { ...state, completedIntakeProspect: undefined, completeIntakeSessionInProgress: false, completeIntakeSessionFailed: false }
      return state
    case COMPLETE_INTAKE_SESSION_FAILED:
      state = { ...state, completeIntakeSessionInProgress: false, completeIntakeSessionFailed: true }
      return state
    case COMPLETE_INTAKE_SESSION_IN_PROGRESS:
      state = { ...state, completeIntakeSessionInProgress: true }
      return state
    case COMPLETED_INTAKE_SESSION:
      state = { ...state, completedIntakeProspect: action.payload.isStatusUpdated, completeIntakeSessionInProgress: false, completeIntakeSessionFailed: false }
      return state

    case SEARCH_PATIENT_FAILED:
      state = { ...state }
      return state
    case SEARCH_PATIENT_IN_PROGRESS:
      state = { ...state, searchPatientInProgress: true }
      return state
    case SEARCH_PATIENT_SUCCESS:
      state = { ...state, patientSearchResults: action.payload.patientIndex.patients, searchPatientInProgress: false }
      return state

    case CLEAR_PATIENT_SEARCH_RESULTS:
      state = { ...state, patientSearchResults: [] }
      return state

    case FETCH_PROSPECT_REFERENCE_DATA:
      state = { ...state, sourceRefData: undefined }
      return state
    case FETCH_PROSPECT_REFERENCE_DATA_IN_PROGRESS:
      state = { ...state, fetchProspectsInProgress: true }
      return state

    case ACCEPT_PROSPECT_REFERENCE_DATA_SUCCESS:
      state = { ...state, sourceRefData: action.payload.sourceRefData, fetchProspectInProgress: false }
      return state
    case ACCEPT_PROSPECT_REF_DATA_FAILED:
      state = { ...state, fetchProspectInProgress: false }
      return state

    case FETCH_ACE_SCREENER:
      state = { ...state, aceScreener: undefined }
      return state
    case FETCH_ACE_SCREENER_IN_PROGRESS:
      state = { ...state, fetchProspectsInProgress: true }
      return state
    case ACCEPT_ACE_SCREENER_SUCCESS:
      state = { ...state, aceScreener: action.payload.aceScreener, fetchProspectInProgress: false }
      return state
    case ACCEPT_ACE_SCREENER_FAILED:
      state = { ...state, fetchProspectInProgress: false }
      return state

    case INTAKE_FORM_SAVE:
      state = { ...state, intakeForm: undefined }
      return state
    case INTAKE_FORM_SAVE_IN_PROGRESS:
      state = { ...state, fetchProspectsInProgress: true }
      return state
    case INTAKE_FORM_SAVE_SUCCESS:
      state = { ...state,
        intakeNotesSavedKeys: [...state.intakeNotesSavedKeys, ...action.payload.keys],
        intakeForm: action.payload.formData,
        fetchProspectInProgress: false
      }
      return state
    case INTAKE_FORM_SAVE_FAILED:
      state = { ...state, fetchProspectInProgress: false }
      return state

    case FETCH_PROSPECT_SESSION_BY_SRC_PATIENT:
      state = { ...state, session: undefined}
      return state
    case FETCH_PROSPECT_SESSION_BY_SRC_PATIENT_SUCCESS:
      state = { ...state, session: action.payload.session }
      return state
    case FETCH_PROSPECT_SESSION_BY_SRC_PATIENT_FAILED:
      state = { ...state, fetchProspectInProgress: false}
      return state

    case LOCK_INTAKE_FORM:
      state = { ...state, lockIntake: undefined }
      return state
    case LOCK_INTAKE_FORM_FAILED:
      state = { ...state, lockIntakeInProgress: false }
      return state
    case LOCK_INTAKE_FORM_SUCCESS:
      state = { ...state, lockIntake: action.payload.lockIntake }
      return state

    case FETCH_PRACTICES:
      state = { ...state, practices: [] }
      return state
    case FETCH_PRACTICES_FAILED:
      state = { ...state, fetchPracticesInProgress: false }
      return state
    case FETCH_PRACTICES_IN_PROGRESS:
      state = { ...state, fetchPracticesInProgress: true }
      return state
    case FETCH_PRACTICES_SUCCESS:
      state = { ...state, practices: action.payload.practices, fetchPracticesInProgress: false }
      return state

    case UPDATE_NOTE_STATUS:
      state = { ...state, isNotesStatusUpdated: undefined, statusUpdateError: undefined }
      return state
    case UPDATE_NOTE_STATUS_FAILED:
      state = { ...state, statusUpdateError: action.payload.error, updatingNotesStatusInProgress: false,
        isNotesStatusUpdated: undefined }
      return state
    case UPDATE_NOTE_STATUS_IN_PROGRESS:
      state = { ...state, updatingNotesStatusInProgress: true, statusUpdateError: undefined, isNotesStatusUpdated: undefined }
      return state
    case UPDATE_NOTE_STATUS_SUCCESS:
      state = { ...state, isNotesStatusUpdated: action.payload.isNotesStatusUpdated, 
        updatingNotesStatusInProgress: false, statusUpdateError: undefined }
      return state
    case UPDATE_NOTE_STATUS_INTAKE:
      state = {
        ...state,
        sourceRefData: state.sourceRefData ? {
          ...state.sourceRefData, notesStatus: action.payload.updatedStatus
        } : undefined
      }
      return state

    case SET_INTAKE_FORM_ERRORS:
      state = { ...state, intakeFormErrors: action.payload.errors }
      return state
    case CLEAR_INTAKE_FORM_ERRORS:
      state = { ...state, intakeFormErrors: undefined }
      return state
    case CLEAR_NOTES_ERROR:
      state = { ...state, statusUpdateError: undefined, isNotesStatusUpdated: undefined }
      return state

    case UPDATE_PATIENT_INFO_SUCCESS:
      state = {
        ...state,
        isContactNumberSavedSuccessfully: true,
        prospect: state.prospect ? {
          ...state.prospect, phoneNumber: action.payload.contactPhoneNumber
        } : undefined
      }
      return state

    case PATCH_PATIENT_INFO:
      state = {
        ...state, prospect: state.prospect ? {
          ...state.prospect, patient: state.prospect.patient ? { ...state.prospect.patient, referredCallStatus: action.payload.referredCallStatus } : undefined
        } : undefined
      }
      console.log({state: state.prospect})
      return state

      case FETCH_PSYCHIATRIST_SOCIAL_WORKERS:
        state = { ...state, therapists: undefined }
        return state
      case FETCH_PSYCHIATRIST_SOCIAL_WORKERS_FAILED:
        state = { ...state, isPsychiatristSocialWorkerListLoading: false }
        return state
      case FETCH_PSYCHIATRIST_SOCIAL_WORKERS_IN_PROGRESS:
        state = { ...state, isPsychiatristSocialWorkerListLoading: true }
        return state
      case FETCH_PSYCHIATRIST_SOCIAL_WORKERS_SUCCESS:
        state = { ...state, therapists: action.payload.therapists, isPsychiatristSocialWorkerListLoading: false }
        return state
  

    case SIGN_OUT_ACCOUNT:
      state = { ...state, ...initialState }
      return state
    default:
      return state;
  }
}

export default ProspectsReducer