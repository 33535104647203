import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IPatientMaster, IUser, SessionTypes, patient_status } from "../../models";
import TablePaginationV2 from "../../components/generic/TablePaginationV2";
import { DateTime } from 'luxon'
import ConfirmationModal from "../../components/clinical-notes/ConfirmationModal";
import { Button } from "@material-tailwind/react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { StateParams } from "../../store/reducers";
import { transferIntake, transferedIntake } from "../../store/actions/care-coordinator/patients.action";
import PatientMasterModal from "../../components/patient-master/PatientMasterModal";
import { CallMetadata } from "../../components/generic/CallStatus";
import { Tooltip } from "react-tooltip";


interface IPatientMasterListComponentProps {
    patients: IPatientMaster[];
    searchInProgress: boolean | undefined;
}

export interface ISelectedPatients {
    id: string;
    name: string;
    dob: string | null;
    referredDate: string | null;
    socialWorker: string;
    practice: {practiceName: string, practiceId: string};
    patientSession: {
        scheduledDate: Date;
        status: string;
        sessionType: keyof typeof SessionTypes
    }
    patientStatus: string;
    patient: IPatientMaster;
}

const patientStatusMapping: Partial<Record<keyof typeof patient_status, CallMetadata>> = {
    active: { description: "Active Patient", style: { backgroundColor: "bg-sjLightBlue", color: "text-sjBlue" } },
    accepted_to_cocm: { description: "Accepted To Cocm", style: { backgroundColor: "bg-sjLightOrange", color: "text-sjOrange" } },
}

const PatientMasterListComponent = (props: IPatientMasterListComponentProps) => {
    const dispatch = useDispatch();
    const { patients, searchInProgress } = props;
    const [openReAssignModal, setOpenReAssignModal] = useState<boolean>(false);
    const [selectedPatient, setSelectedPatient] = useState<ISelectedPatients>();
    const [selectedSocialWorker, setSelectedSocialWorker] = useState<string | undefined>('')
    const { therapists } = useSelector((state: StateParams) => state.appointments);
    const [formError, setFormError] = useState<string>('');
    const {
        intakeTransferInProgress,
        intakeTransferError,
        transferedPatient,
    } = useSelector((state: StateParams) => state.coordinatorPatients);

    useEffect(() => {
        if (intakeTransferError) {
            setFormError(intakeTransferError);
        }
    }, [intakeTransferError]);

    const closeReAssignModal = useCallback(() => {
        setOpenReAssignModal(false);
        setFormError('');
        setSelectedSocialWorker('');
    }, []);

    useEffect(() => {
        if (!intakeTransferInProgress && transferedPatient) {
            closeReAssignModal();
        }
    }, [intakeTransferInProgress, transferedPatient, closeReAssignModal]);

    const data = patients.map((patient) => {
        return {
            id: patient.id,
            name: `${patient?.firstName} ${patient?.lastName}`,
            dob: patient.dob ? DateTime.fromISO(patient.dob.toString(), { zone: 'utc' }).toFormat('MM-dd-yyyy') : null,
            referredDate: patient.referredDate ? DateTime.fromISO(patient?.referredDate?.toString(), { zone: 'utc' }).toFormat('MM-dd-yyyy') : null,
            socialWorker: `${patient.therapistProspect[0]?.therapist.firstName} ${patient.therapistProspect[0]?.therapist.lastName}`,
            practice: {practiceName: `${patient.screenerUserPractices[0]?.providerPractice?.name}`, practiceId: `${patient.screenerUserPractices[0]?.providerPractice?.id}`},
            patientSession: patient.patientSession[0],
            patientStatus: patient.patientStatus,
            patient: patient
        }
    })

    const handlePatient = useCallback((patient: any) => {
        const selectedPatientId = patient.getValue().id;
        const PatientDetails = data.find((patientObj: any) => patientObj.id === selectedPatientId);
        setSelectedPatient(PatientDetails);
        setOpenReAssignModal(true);
        setFormError('');
    }, [data]);

    const practiceWithToolTip = (props: any) => {
        const practice = props.getValue()
        const practiceTooltip = `practice-tooltip-${practice.practiceId}`
        return (
            <>
                <span data-tooltip-id={practiceTooltip} className="overflow-auto">
                    {practice.practiceName}
                </span>
                <Tooltip style={{ borderRadius: 5, width: 290 }} id={practiceTooltip}>
                    <div className="text-[14px] text-wrap text-center">
                        {practice.practiceName}
                    </div>
                </Tooltip>
            </>
        )
    }

    const columns = useMemo(
        () => [
            { header: "Patient Name", accessorKey: "name" },
            { header: "DOB", accessorKey: "dob" },
            { header: "Referred Date", accessorKey: "referredDate" },
            { header: "Practice", accessorKey: "practice", cell: (props: any) => practiceWithToolTip(props) },
            { header: "Social Worker", accessorKey: "socialWorker" },
            { header: "", accessorKey: "patient", cell: (props: any) => {
                const patient = props.getValue()
                const isSessionTypeIntake = patient.patientSession[0].sessionType === 'Intake'
                const isSessionScheduledOrInProgress = patient.patientSession[0].status === 'scheduled' || patient.patientSession[0].status === 'in_progress'
                    return (
                        <Button onClick={() => handlePatient(props)}
                            className={"!bg-sjOrange !text-white px-10 w-full max-w-xs whitespace-normal break-words"}>
                            {isSessionTypeIntake && isSessionScheduledOrInProgress ? 'Dissociate Social Worker' : 'Reassign Social Worker'}
                        </Button>
                    );
                }
            }
        ],
        [handlePatient]
    );

    const renderUserToggleConfirmationModal = useCallback(() => {
        return (
            selectedPatient && <PatientMasterModal 
                    openReAssignModal={openReAssignModal}
                    formError={formError}
                    selectedPatient={selectedPatient}
                    therapists={therapists}
                    patientStatusMapping={patientStatusMapping}
                    closeReAssignModal={closeReAssignModal}
            />
        );
    }, [openReAssignModal, selectedPatient, selectedSocialWorker, formError, therapists]);


    return (
        <div className='mt-4 w-full'>
            <TablePaginationV2 columns={columns} data={data} searchInProgress={searchInProgress} />
            {renderUserToggleConfirmationModal()}
            
        </div>
    )
}

export default PatientMasterListComponent;
