import {AxiosError, AxiosInstance, AxiosResponse} from "axios";
import {Either} from "monet";
import {apiHttpClient, getAuthHeaderConfig} from "../lib";
import {put} from "redux-saga/effects";
import {CheckedError, ServiceError} from "../types/ServiceError";
import {AuthState} from "../models";
import {callFinished} from "../store/actions/loading.action";
import _ from "lodash";

export const apiCallWithErrorHandler = <Res>(apiCall: () => Promise<AxiosResponse>) => {
    console.log(`API Call Started`)
    return apiCall()
        .then((response: AxiosResponse) => {
            console.log(`API Call Successful`)
            return Either.right(response.data.data as Res)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Call Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("API Call Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during api call"))
            }
            console.log("API Call Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

export const postAPI = <Res, Req>(url: string, request: Req, authState: AuthState | null, apiClient: AxiosInstance = apiHttpClient) => {
    console.log(`API Call POST to: `, url)
    const config = authState ? getAuthHeaderConfig(authState) : {};
    return apiCallWithErrorHandler<Res>(() => {
        return apiClient.post<Either<CheckedError, Res>>(url, request, config)
    })
}
export const putAPI = <Res, Req>(url: string, request: Req, authState: AuthState | null, apiClient: AxiosInstance = apiHttpClient) => {
    console.log(`API Call PUT to: `, url)
    const config = authState ? getAuthHeaderConfig(authState) : {};
    return apiCallWithErrorHandler<Res>(() => {
        return apiClient.put<Either<CheckedError, Res>>(url, request, config)
    })
}
export const getAPI = <Res>(url: string, authState: AuthState, apiClient: AxiosInstance = apiHttpClient) => {
    console.log(`API Call GET: `, url)
    const config = getAuthHeaderConfig(authState);
    return apiCallWithErrorHandler<Res>(() => {
        return apiClient.get<Either<CheckedError, Res>>(url, config)
    })
}

export const catchErrorMessage = (e: AxiosError<ServiceError>) => {
    console.log("API Error", e)
    const statusCode = e.response?.status || 500
    if(statusCode === 422) {
        const errorResponse = e.response?.data?.error || e.response?.statusText;
        console.log("Encountered a 422 Error", errorResponse)
        return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred"))
    } else if (statusCode >= 400 && statusCode < 500) {
        const errorResponse = e.response?.data?.error || e.response?.statusText;
        console.log("Encountered a 4XX Error", statusCode, errorResponse)
        return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
    }
    console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
    return Either.left(new Error(e.message))
}

export function* handleErrorOrResponse<Res>(either: Either<CheckedError, Res>,
                                            failedEvent: string,
                                            successEvent: string,
                                            responseKey: string | null,
                                            action: string) {
    try {
        console.log(`API Call HandleErrorOrResponse: ${action}`)
        if (either.isLeft()) {
            const error = either.left()
            if (error.isChecked) {
                console.log(`API Call HandleErrorOrResponse: ${action}: Checked Error`, failedEvent)
                yield put({type: failedEvent, error: error.message})
            } else {
                console.log(`API Call HandleErrorOrResponse: ${action}: Unknown Error`, failedEvent)
                yield put({type: failedEvent, error: error.message})
            }
        } else {
            console.log(`API Call HandleErrorOrResponse: ${action}: Success Event`, successEvent)
            const response: Res = either.right();
            console.log(`API Call HandleErrorOrResponse: ${action}: Success Response`, response)
            yield put({type: successEvent, payload: responseKey ? {[responseKey]: response}: response});
        }
    } catch (error) {
        console.log(`API Call HandleErrorOrResponse: ${action}: Error Response`, error)
        yield put({type: failedEvent});
    } finally {
        yield put(callFinished())
    }
}

function _buildValidQueryParam(item: any, queryKey: string) {
    if (_.isNull(item) || _.isUndefined(item)) {
        return null
    } else {
        return `${encodeURIComponent(queryKey)}=${encodeURIComponent(item)}`
    }
}

export function createQueryString(queryObject: any = {}, keyMap? : {[key: string]: string}) : string {
    const queryString = Object.keys(queryObject)
        .filter((key) => !_.isNil(queryObject[key]))
        .map((key) => {
            const queryKey = keyMap?.[key] || key
            if(Array.isArray(queryObject[key])) {
                return queryObject[key]
                    .map((item: any) => {
                        return _buildValidQueryParam(item, queryKey)
                    })
                    .filter((queryPair: string | null) => queryPair !== null)
                    .join('&')
            }
            else if (typeof queryObject[key] === 'object') {
                return createQueryString(queryObject[key])
            }
            else {
                const item = queryObject[key]
                return _buildValidQueryParam(item, queryKey);
            }
        })
        .filter((queryPair: string | null) => queryPair !== null)
        .join('&');

    return queryString ? `${queryString}` : "";
}
