import {IProspect, IReferredPatientsDetails, LanguageCodeDescription} from "../../../models";
import {DetailsButton} from "../../generic/DetailsButton";
import {DateTime} from "luxon";
import { ProspectsDataView } from "../common/ProspectsDataView";
import { CallStatus } from "../../generic/CallStatus";

export class CareCoordinatorProspectsDataView implements ProspectsDataView {
    private therapistProspects: IReferredPatientsDetails[];

    constructor() {
        this.therapistProspects = []
    }

    setProspects(therapistProspects: IReferredPatientsDetails[]) {
        this.therapistProspects = therapistProspects
    }

    getColumns(): any {
        return () => [
            {header: "Patient Name", accessorKey: "name"},
            {header: "Date of birth", accessorKey: "dateOfBirth"},
            {header: "Referring Physician", accessorKey: "referringPhysician"},
            {header: "Referred Date", accessorKey: "referredDate"},
            {header: "Preferred Language", accessorKey: "preferredLanguage"},
            {header: "Practice", accessorKey: "practice"},
            {header: "Status", accessorKey: "callStatus", cell: (props: any) => <CallStatus value={props.getValue()} isReferred={true}/> },
            // {header: "Appointment Time", accessorKey: "appointment"},
            {header: "", accessorKey: "summaryLink", cell: (props: any) => <DetailsButton value={props.getValue()} /> }
        ]
    };

    getData(): any {
        return this.therapistProspects.map((therapistProspect) => {
            const isoDateTime = therapistProspect.referredDate ? DateTime.fromISO(therapistProspect.referredDate.toString(), {zone: 'utc'}).toFormat('MM-dd-yyyy') : ""
            const dob = DateTime.fromISO(therapistProspect.dob.toString(), {zone: 'utc'}).toFormat('MM-dd-yyyy')
            const practice = therapistProspect.screenerUserPractices[0];
            const provider = therapistProspect?.patientStatuses?.[0]?.provider;
            const referringPhysician = provider ? `Dr. ${provider?.firstName} ${provider?.lastName}` : "N/A"
            return {
                id: therapistProspect.id,
                summaryLink: {url: `${therapistProspect.id}/summary`, practiceId: practice.practiceId, sourcePatientId: therapistProspect.id},
                name: `${therapistProspect.lastName}, ${therapistProspect.firstName}`,
                dateOfBirth: dob,
                preferredLanguage: LanguageCodeDescription[therapistProspect.preferredLanguage] || "English",
                practice: `${practice.providerPractice.name}`,
                referredDate: isoDateTime,
                referringPhysician,
                callStatus: therapistProspect.referredCallStatus,
                // appointment: appointment
            }
        })
     }
}
