import { useEffect, useState } from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import statusBucket from "../../../assets/data/statusBucket"
import { useUpdateSessionStatus } from "../../../hooks/useUpdateSessionStatus"
import { IAppointmentWithTherapist, IProspect, PatientSessionStatus, PatientSessionStatusType, ReferredPatientCallStatus, ReferredPatientCallStatusType, SessionTypes } from "../../../models"
import { clearSessionStatus } from "../../../store/actions/counsellor/appointments.action"
import { StateParams } from "../../../store/reducers"
import RadioOption from "../../generic/RadioOption"
import ConfirmationModal from "../ConfirmationModal"
import { fetchCatchupNotes, fetchProgressNotes, fetchTreatmentNotes } from "../../../store/actions/counsellor/patients.action"
import { AnyAction } from "redux"

interface Props {
    prospect: IProspect
    setCanShowAppointmentConfirmationModal: (canShowAppointmentConfirmationModal: boolean) => void
    canShowAppointmentConfirmationModal: boolean
    session: IAppointmentWithTherapist
}

const ConfirmAppointmentStatusModal = ({prospect, setCanShowAppointmentConfirmationModal, canShowAppointmentConfirmationModal, session}: Props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [confirmedStatus, setConfirmedStatus] = useState<PatientSessionStatusType>()
    const dispatchSessionStatusAndCallStatus = useUpdateSessionStatus({
        sourcePatientId: prospect.sourcePatientId,
        practiceId: prospect.referredByPracticeId
    })
    const [selectedSessionType, setSelectedSessionType] = useState<SessionTypes>()
    const { sessionNotesId } = useSelector((state: StateParams) => state.patients)
    const sessionStatuses = Object.entries(statusBucket.session_status_to_confirm).map(([key, value]) => ({ value: key, label: value }))

    const sessionNavigationMap:{ [key in PatientSessionStatusType]: { [key in SessionTypes]?: string; }} = {
        [PatientSessionStatus.inProgressUnderscored]: {
            [SessionTypes.Intake] : `/prospects/${prospect.id}/start-intake`,
            [SessionTypes.TreatmentPlan] : `/patients/${prospect.id}/treatment?notesId=${sessionNotesId}`,
            [SessionTypes.FollowUp] : `/patients/${prospect.id}/progress-notes?notesId=${sessionNotesId}`,
            [SessionTypes.Catchup] : `/patients/${prospect.id}/catchup-notes?notesId=${sessionNotesId}`,
        }
    }

    const handleConfirmAppointmentStatus = (event: React.ChangeEvent<HTMLInputElement> | undefined) => {
        if(event?.target.value) {
            const selected = event?.target.value as PatientSessionStatusType
            setConfirmedStatus(selected)
        }
    }

    const createClinicalNote = (sessionType: PatientSessionStatusType) => {
        const mapping: {[key in PatientSessionStatusType]: (patientId: string) => AnyAction} = {
            [SessionTypes.TreatmentPlan]: fetchTreatmentNotes,
            [SessionTypes.FollowUp]: fetchProgressNotes,
            [SessionTypes.Catchup]: fetchCatchupNotes
        }
        const createNote = mapping[sessionType]
        if(!createNote) {
            console.log('No mapping found for session type', sessionType)
            return
        }
        dispatch(createNote(prospect.id))
    }

    useEffect(() => {
        if(sessionNotesId && selectedSessionType) {
            const navigationPath = sessionNavigationMap[PatientSessionStatus.inProgressUnderscored][selectedSessionType]
            navigationPath && navigate(navigationPath)
        }
    }, [sessionNotesId])

    const handleContinue = () => {
        const sessionType = session.sessionType as SessionTypes
        setSelectedSessionType(sessionType)
        if(confirmedStatus === PatientSessionStatus.inProgressUnderscored) {
            const sessionNavigation = sessionNavigationMap[PatientSessionStatus.inProgressUnderscored]
            const navigationPath = sessionType === SessionTypes.Intake ? sessionNavigation[sessionType] : ''
            dispatchSessionStatusAndCallStatus(session, PatientSessionStatus.inProgressUnderscored, sessionType, navigationPath)
            sessionType !== SessionTypes.Intake && createClinicalNote(sessionType)
        } else if(confirmedStatus === PatientSessionStatus.noShow && session.id) {
            dispatchSessionStatusAndCallStatus(session, PatientSessionStatus.noShow, sessionType)
            setCanShowAppointmentConfirmationModal(false)
        }
    }

    const handleModalClose = () => {
        setCanShowAppointmentConfirmationModal(!canShowAppointmentConfirmationModal)
        setConfirmedStatus('')
    }
    
    return (
        <ConfirmationModal isOpen={canShowAppointmentConfirmationModal} onClose={handleModalClose}
                key={'confirmAppointmentModal'} onConfirm={handleContinue} modalClassNames={'!w-1/2'}
            >
                <p className="text-lg font-semibold text-sjHeader pb-2 text-left">{`Appointment Status`}</p>
                <p className="text-sjGray text-sm text-left pb-2">To proceed with the appointment, kindly confirm the appointment status.</p>
                <div>
                    {
                        sessionStatuses.map(status => 
                            <RadioOption 
                                name="sessionStatus" 
                                label={status.label} 
                                value={status.value} 
                                onChange={handleConfirmAppointmentStatus} 
                                isChecked={confirmedStatus === status.value} />
                        )
                    }
                </div>
                {/* {!dischargeReason && <p className="error-msg">Please select the reason for the patient's discharge.</p>} */}
            </ConfirmationModal>
    )
}

export default ConfirmAppointmentStatusModal