import React from "react";
import {useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import PhysicianLocationSelectionComponent from "./PhysicianLocationSelectionComponent";

export const LocationsByRole : {[key: string] : React.FC} = {
    "Physician": PhysicianLocationSelectionComponent,
    "Psychiatrist": () => (<></>),
    "Counsellor":  () => (<></>),
    "Admin": () => (<></>),
    "Care_Coordinator": () => (<></>),
    "Medical_Assistant" : () => (<></>)
}

const LocationsComponent: React.FC = () => {
    const { role } = useSelector((state: StateParams) => state.account)

    function renderLocationSelectionComponent(role: string) {
        const LocationSelectionComponentByRole = LocationsByRole[role];
        return <LocationSelectionComponentByRole />
    }

    return role ? renderLocationSelectionComponent(role) : <></>
}

export default LocationsComponent
