import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import VideoFooter from "../../components/video/VideoFooter";
import './Video.scss';
import classnames from "classnames";
import {useShare} from "./hooks/useShare";
import ZoomContext from "../../context/ZoomContext";
import ZoomMediaContext from "../../context/MediaContext";
import {useGalleryLayout} from "./hooks/useGalleryLayout";
import {useCanvasDimension} from "./hooks/useCanvasDimension";
import {useActiveVideo} from "./hooks/useActiveVideo";
import {usePagination} from "./hooks/usePagination";
import Avatar from "./Avatar";
import {useSizeCallback} from "../../hooks/useSizeCallback";
import {isShallowEqual} from "./util/Compare";
import _ from 'lodash';
import {isAndroidBrowser, isSupportOffscreenCanvas, isSupportWebCodecs} from "./util/Platform";
import {SELF_VIDEO_ID} from "./util/VideoConstants";
import {useParticipantsChange} from "./hooks/useParticipantsChange";
import {Participant} from "../../types/ZoomIndexTypes";

const isUseVideoElementToDrawSelfVideo = isAndroidBrowser() || isSupportOffscreenCanvas();
const VideoContainer: React.FC = () => {
    
    const zmClient = useContext(ZoomContext);
    const {
        mediaStream,
        video: { decode: isVideoDecodeReady }
    } = useContext(ZoomMediaContext);
    const videoRef = useRef<HTMLCanvasElement | null>(null);
    const shareRef = useRef<HTMLCanvasElement | null>(null);
    const selfShareRef = useRef<(HTMLCanvasElement & HTMLVideoElement) | null>(null);
    const shareContainerRef = useRef<HTMLDivElement | null>(null);
    const [participants, setParticipants] = useState<Participant[]>([]);
    const [containerDimension, setContainerDimension] = useState({
        width: 0,
        height: 0
    });
    const [shareViewDimension, setShareViewDimension] = useState({
        width: 0,
        height: 0
    });
    const canvasDimension = useCanvasDimension(mediaStream, videoRef);
    const activeVideo = useActiveVideo(zmClient);
    const { page, pageSize, totalPage, totalSize, setPage } = usePagination(zmClient, canvasDimension);
    const { visibleParticipants, layout: videoLayout } = useGalleryLayout(
        zmClient,
        mediaStream,
        isVideoDecodeReady,
        videoRef,
        canvasDimension,
        {
            page,
            pageSize,
            totalPage,
            totalSize
        }
    );
    const { isRecieveSharing, isStartedShare, sharedContentDimension } = useShare(zmClient, mediaStream, shareRef);
    const isSharing = isRecieveSharing || isStartedShare;
    /**
     * position for self video
     */
    const currentUserIndex = visibleParticipants.findIndex(
        (user) => user.userId === zmClient.getCurrentUserInfo()?.userId
    );
    let selfVideoLayout = null;
    if (currentUserIndex) {
        const item = videoLayout[currentUserIndex];
        if (item && canvasDimension) {
            selfVideoLayout = { ...item, y: canvasDimension.height - item.y - item.height };
        }
    }

    useParticipantsChange(zmClient, (payload: any) => {
        setParticipants(payload);
    });
    useEffect(() => {
        if (isSharing && shareContainerRef.current) {
            const { width, height } = sharedContentDimension;
            const { width: containerWidth, height: containerHeight } = containerDimension;
            const ratio = Math.min(containerWidth / width, containerHeight / height, 1);
            setShareViewDimension({
                width: Math.floor(width * ratio),
                height: Math.floor(height * ratio)
            });
        }
    }, [isSharing, sharedContentDimension, containerDimension]);

    const onShareContainerResize = useCallback(({ width, height }: { width: number, height: number }) => {
        _.throttle(() => {
            setContainerDimension({ width, height });
        }, 50)();
    }, []);
    useSizeCallback(shareContainerRef.current, onShareContainerResize);
    useEffect(() => {
        if (!isShallowEqual(shareViewDimension, sharedContentDimension)) {
            mediaStream?.updateSharingCanvasDimension(shareViewDimension.width, shareViewDimension.height);
        }
    }, [mediaStream, sharedContentDimension, shareViewDimension]);

    return (
        <div className="viewport">
            <div
                className={classnames('share-container', {
                    'in-sharing': isSharing
                })}
                ref={shareContainerRef}
            >
                <div
                    className="share-container-viewport"
                    style={{
                        width: `${shareViewDimension.width}px`,
                        height: `${shareViewDimension.height}px`
                    }}
                >
                    <canvas className={classnames('share-canvas', { hidden: isStartedShare })} ref={shareRef} />
                    {isSupportWebCodecs() ? (
                        <video
                            className={classnames('share-canvas', {
                                hidden: isRecieveSharing
                            })}
                            ref={selfShareRef}
                        />
                    ) : (
                        <canvas
                            className={classnames('share-canvas', {
                                hidden: isRecieveSharing
                            })}
                            ref={selfShareRef}
                        />
                    )}
                </div>
            </div>
            <div
                className={classnames('video-container', {
                    'in-sharing': isSharing
                })}
            >
                <canvas className="video-canvas" id="video-canvas" width="800" height="600" ref={videoRef} />
                {isUseVideoElementToDrawSelfVideo && (
                    <video
                        id={SELF_VIDEO_ID}
                        className={classnames('self-video-non-sab')}
                        style={
                            selfVideoLayout
                                ? {
                                    display: 'block',
                                    width: `${selfVideoLayout.width}px`,
                                    height: `${selfVideoLayout.height}px`,
                                    top: `${selfVideoLayout.y}px`,
                                    left: `${selfVideoLayout.x}px`,
                                    pointerEvents: 'none'
                                }
                                : undefined
                        }
                    />
                )}
                <ul className="avatar-list">
                    {visibleParticipants.map((user, index) => {
                        if (index > videoLayout.length - 1) {
                            return null;
                        }
                        const dimension = videoLayout[index];
                        const { width, height, x, y } = dimension;
                        const { height: canvasHeight } = canvasDimension;
                        return (
                            <Avatar
                                participant={user}
                                key={user.userId}
                                isActive={activeVideo === user.userId}
                                style={{
                                    width: `${width}px`,
                                    height: `${height}px`,
                                    top: `${canvasHeight - y - height}px`,
                                    left: `${x}px`
                                }}
                            />
                        );
                    })}
                </ul>
            </div>
            <VideoFooter className="video-operations" sharing shareRef={selfShareRef} />
        </div>
    )
}

export default VideoContainer