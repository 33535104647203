import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { StateParams } from "../../store/reducers";
import { createAppointment, createBackDatedAppointment } from "../../store/actions/counsellor/appointments.action";
import { ICreateAppointment, PatientSessionStatusType, PatientSessionTypes, SessionTypes } from "../../models";
import { useNavigate } from "react-router";
import withPatientSchedule from "../appointments/withPatientSchedule";
import { AiOutlineClose } from "react-icons/ai";
import { convertISODateToMMDDYYYY } from "../../shared/DateUtils";
import ConfirmBackdatedModal from "./ConfirmBackdatedModal";
import _ from "lodash";

type AppointmentFormData = {
  selectedDate: Date;
  sessionType: keyof typeof PatientSessionTypes
};

const SESSION_TYPES: { key: PatientSessionTypes; value: string }[] = [
  { key: PatientSessionTypes.TreatmentPlan, value: "Treatment Plan" },
  { key: PatientSessionTypes.FollowUp, value: "Follow Up" },
  { key: PatientSessionTypes.Catchup, value: "Catchup" }
]

const BackDatedSchedule: React.FC = () => {
  const dispatch = useDispatch()
  const { control, register, handleSubmit, formState: { errors }, watch, setError, clearErrors } = useForm<AppointmentFormData>();
  const { backDatedAppointment, createBackDatedAppointmentError } = useSelector((state: StateParams) => state.appointments)
  const { patientJourney } = useSelector((state: StateParams) => state.patients)
  const [appointmentRequest, setAppointmentRequest] = useState<ICreateAppointment>()
  const [canShowConfirmationModal, setCanShowConfirmationModal] = useState(false)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const navigate = useNavigate()
  const { patient } = useSelector((state: StateParams) => state.patients)
  const patientName = patient?.firstName ? `${_.upperFirst(patient.firstName)} ${_.upperFirst(patient.lastName)}` : ''
  const patientDob = patient?.dob ? convertISODateToMMDDYYYY(patient.dob.toString()) : ''
  const watchSessionType = watch('sessionType')
  const watchSelectedDate = watch('selectedDate')
  const treatments = patientJourney
    ?.filter(journey => journey.sessionType === PatientSessionTypes.TreatmentPlan)
    ?.map((journey) => ({
      id: journey.notesId || '',
      createdAt: journey.createdAt,
    })) || []
  const [selectedTreatmentPlan, setTelectedTreatmentPlan] = useState<string>(treatments?.[0]?.id)
  const selectedTreatmentDate = treatments.find(x => x.id === selectedTreatmentPlan)?.createdAt
  const isoDate = selectedTreatmentDate ? DateTime.fromISO(selectedTreatmentDate).toJSDate() : null

  const onSubmit = handleSubmit((data: AppointmentFormData) => {
    if (!patient) {
      return
    }
    if(!selectedTreatmentPlan && watchSessionType === PatientSessionTypes.FollowUp as any) {
      setErrors('sessionType', 'Please select a Treatment Plan before proceeding with a Progress Note.')
      return
    }
    const scheduledDate = DateTime.fromJSDate(data.selectedDate).toUTC().toISO();
    if (scheduledDate && patient.sourcePatientId) {
      const appointmentRequest: ICreateAppointment = {
        ...data,
        patientId: patient.id,
        sessionType: data.sessionType,
        scheduledDate: scheduledDate,
        treatmentPlanForFollowup: selectedTreatmentPlan,
      }
      setAppointmentRequest(appointmentRequest)
      dispatch(createBackDatedAppointment(appointmentRequest, patient.sourcePatientId))
    }
  });

  function setErrors(key: any, message: string) {
    setError(key, {
      type: 'manual',
      message
    })
  }

  useEffect(() => {
    if (watchSessionType === PatientSessionTypes.FollowUp as any) {
      if (!treatments.length) {
        setErrors('sessionType', 'Please create a Treatment Plan before proceeding with a Progress Note.')
      } else if (treatments.length === 1 && !selectedTreatmentPlan) {
        setErrors('sessionType', 'No Treatment Plan selected. Please select a Treatment Plan before proceeding with a Progress Note.')
      } else {
        setCanShowConfirmationModal(true)
      }
    } else if([PatientSessionTypes.TreatmentPlan, PatientSessionTypes.Catchup].includes(watchSessionType as any)) {
      clearErrors()
    }
  }, [watchSessionType])

  useEffect(() => {
    if (watchSelectedDate && watchSessionType === PatientSessionTypes.FollowUp as any) {
      const isProgressNotesDateBeforeTreatment = isoDate ? watchSelectedDate < isoDate : false
      if (isProgressNotesDateBeforeTreatment && !errors.selectedDate) {
        setErrors('selectedDate', 'Progress Notes cannot be created before the selected Treatment Plan date.')
      } else if(!isProgressNotesDateBeforeTreatment){
        clearErrors('selectedDate')
      }
    }
  }, [watchSelectedDate])

  useEffect(() => {
    if (appointmentRequest && backDatedAppointment?.notesId) {
      navigate(sessionNavigationMap[appointmentRequest.sessionType as SessionTypes] as string, {
        replace: true //to remove the url history from browser
      })
    }
  }, [backDatedAppointment?.notesId])

  const sessionNavigationMap: { [key in SessionTypes]?: string; } = {
    [SessionTypes.TreatmentPlan]: `/patients/${patient?.id}/treatment?notesId=${backDatedAppointment?.notesId}`,
    [SessionTypes.FollowUp]: `/patients/${patient?.id}/progress-notes?notesId=${backDatedAppointment?.notesId}`,
    [SessionTypes.Catchup]: `/patients/${patient?.id}/catchup-notes?notesId=${backDatedAppointment?.notesId}`,
  }

  function renderFormForAppointment() {
    return (
      <div className={"mt-10"}>
        <div className="mb-8">
          <label
            htmlFor="sessionType"
            className={`block text-md mb-2 text-sjOrange`} >
            Session Type
          </label>
          <select 
            {...register("sessionType", { required: true })}
            className={`block w-full bg-transparent outline-none rounded-lg py-2 px-4 placeholder-gray-500`}
            defaultValue={'Treatment_Plan'}>
            {SESSION_TYPES.map((item: { key: string, value: string }) => 
              <option value={item.key}>{item.value}</option>
            )}
          </select>
          {watchSessionType === PatientSessionTypes.FollowUp as any && isoDate && <p className="success-msg text-sm mt-1">
            {`Selected - Treatment Plan (${DateTime.fromJSDate(isoDate).toFormat('MM/dd/yyyy')})`}
          </p>}
          {errors?.sessionType && (
            <p className="error-msg">
              {errors?.sessionType?.message}
            </p>
          )}
        </div>
        <div className="mb-8">
          <div className="grid grid-cols-2">
            <label
              htmlFor="sessionDate"
              className={`block text-md mb-2 self-center text-sjOrange`}>
              {`Date (${timeZone})`}
            </label>
            <Controller
              {...register("selectedDate", { required: true })}
              control={control}
              name='selectedDate'
              render={({ field }) => (
                <DatePicker
                  className={`block w-full bg-transparent outline-none rounded-lg py-2 px-4 placeholder-gray-500 text-black border-sjOrange`}
                  dateFormat="d MMM yyyy h:mm aa"
                  minDate={undefined}
                  maxDate={new Date()}
                  showTimeSelect={true}
                  filterTime={undefined}
                  todayButton="Today"
                  dropdownMode="select"
                  isClearable
                  shouldCloseOnSelect
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                  timeIntervals={10}
                />
              )}
            />
          </div>
          {errors.selectedDate && (
            <p className="error-msg mt-2">
              {errors.selectedDate.message ? errors.selectedDate.message : `A valid Session Date is required.`}
            </p>
          )}
        </div>
      </div>
    )
  }

  function renderHeader() {
    return (
      <div>
        <div className="flex flex-row">
          <div className="grow"><span className="text-xl font-bold text-[#242731]">Add Backdated Note</span></div>
          <div
            onClick={() => navigate(-1)}
            className="cursor-pointer grow-0"
          >
            <AiOutlineClose
              className="text-gray-500 hover:text-gray-700"
              style={{ width: '25px', height: '25px' }}
            />
          </div>
        </div>
        <div className="flex pt-5">
          <span className="text-base text-[#575F6E] font-light">
            Add an earlier note that is not present for <span className="font-bold">{patientName} ({patientDob})</span> in the system. You can select a previous date & time using the date picker field.
          </span>
        </div>
      </div>
    )
  }

  return (
    <div>
      {renderHeader()}
      <form onSubmit={onSubmit}>
        {renderFormForAppointment()}
        <input type={"submit"} value={"Submit"} disabled={!!errors?.selectedDate || !!errors?.sessionType}
          className={`inline-block ${(!!errors?.selectedDate || !!errors?.sessionType) ? 'bg-orange-200' : 'bg-orange-500'} text-white rounded shadow py-2 px-5 text-sm`} />
        {createBackDatedAppointmentError && <p className="error-msg">{createBackDatedAppointmentError}</p>}
      </form>
      {<ConfirmBackdatedModal
        canShowConfirmationModal={canShowConfirmationModal}
        setCanConfirmationModal={setCanShowConfirmationModal}
        treatmentPlans={treatments}
        patientName={patientName}
        patientDob={patientDob}
        selectedTreatmentPlan={selectedTreatmentPlan}
        setSelectedTreatmentPlan={setTelectedTreatmentPlan}
      />}
    </div>
  )
}

export default withPatientSchedule(BackDatedSchedule)
