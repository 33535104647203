import React, {useState} from "react";
import * as _ from "lodash";
import {Accordion, AccordionBody, AccordionHeader} from "@material-tailwind/react";
import QuestionAnswer from "../generic/QuestionAnswer";
import { IScreenerQuestionAnswer, ISummary } from "../../models";

enum Conditions {
    normal
}

type ConditionType = keyof typeof Conditions

const CONDITION_MAP : {[key in ConditionType]: string} = {
    "normal": "Other",
}

const QDRSResults: React.FC<{screenerScoreMessage: string, questionAnswers: IScreenerQuestionAnswer[], summary: ISummary}> = ({questionAnswers, summary}) => {
    const screenerScores = summary
    const [open, setOpen] = useState(0);

    const handleOpen = (value: number) => {
        setOpen(open === value ? 0 : value);
    };
    const groupedByCondition : {[key: string]: IScreenerQuestionAnswer[]} = _.chain(questionAnswers)
        .groupBy((item: IScreenerQuestionAnswer) => {
            if(item.condition) {
                return item.condition
            } else {
                return "normal"
            }
        })
        .value()

    function renderGroupedQuestionAnswers(title: string | null, index: number, groupedQuestionAnswers: IScreenerQuestionAnswer[]) {
        return groupedQuestionAnswers && groupedQuestionAnswers.length > 0 ?
            (<Accordion className={"mb-2"} open={open === index} onClick={() => handleOpen(index)}>
                <AccordionHeader className={"bg-sjPSCY text-black rounded text-sm px-2"}><span dangerouslySetInnerHTML={{__html: title || ""}}/></AccordionHeader>
                <AccordionBody>
                    <div className={"flex flex-col font-medium"}>
                        {groupedQuestionAnswers.map((questionAnswer, index) => {
                            return <QuestionAnswer key={questionAnswer.questionId} questionAnswer={questionAnswer}
                                                   index={index}/>
                        })}
                    </div>
                </AccordionBody>
            </Accordion>)
            : null;
    }

    function normalConditionQuestionAnswers(index: number) {
        const groupedQuestionAnswers = groupedByCondition.normal
        const title = CONDITION_MAP["normal"]
        return renderGroupedQuestionAnswers(title, index, groupedQuestionAnswers);
    }

    function cognitiveConditionQuestionAnswers(index: number) {
        if("cognitive" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition.cognitive
            const styledTitle = `${`Cognitive Scale: The patient received a score of <span class="text-sjBlue text-base">${screenerScores.qdrsCognitiveConditionScore}</span> in the cognitive subscale questions`}`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }
    function behavioralConditionQuestionAnswers(index: number) {
        if("behavioral" in groupedByCondition) {
            const groupedQuestionAnswers = groupedByCondition.behavioral
            const styledTitle = `${`Behavioral Scale: The patient received a score of <span class="text-sjBlue text-base">${screenerScores.qdrsBehavioralConditionScore}</span> in the behavioral subscale questions`}`
            return renderGroupedQuestionAnswers(styledTitle, index, groupedQuestionAnswers);
        }
    }

    return questionAnswers && questionAnswers.length > 0
        ? (<>
            { cognitiveConditionQuestionAnswers(1) }
            { behavioralConditionQuestionAnswers(2) }
            { normalConditionQuestionAnswers(3) }
        </>)
        : null
}

export default QDRSResults