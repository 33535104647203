import React from "react";
import ProspectIntakeComponent from "./ProspectIntakeComponent";
import PatientStartIntakeComponent from "./PatientStartIntakeComponent";
import {useFlags} from "flagsmith/react";

const ProspectIntakeToggleOffComponent: React.FC = () => {
    return <ProspectIntakeComponent />
}

const PatientIntakeToggleOnComponent: React.FC = () => {
    return <PatientStartIntakeComponent />
}

const PatientIntakeToggleComponent: React.FC = () => {
    const flags = useFlags(['intake-api-v2'])
    const isIntakeV2APIEnabled = flags["intake-api-v2"].enabled

    return isIntakeV2APIEnabled
            ? <PatientIntakeToggleOnComponent />
            : <ProspectIntakeToggleOffComponent />

}

export default PatientIntakeToggleComponent
